import React from 'react';
import Modal from 'react-modal';


interface Props {
    visible: boolean,
    small?: boolean,
    onClose?: void,
}


export default (props: Props) => {
    const customStyles = {
        overlay: {
        },
        content: {
            maxWidth: props.small ? `35rem` : undefined,
        }
    };

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            onRequestClose={props.onClose ?? undefined}
        >
            {!!props.onClose && (
                <button className="modal__close" onClick={props.onClose}>
                    <img src={require('../../assets/img/icons/close.svg').default} alt="X"/>
                </button>
            )}


            {props.children}
        </Modal>
    );
}
