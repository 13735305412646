import React, {useEffect} from "react";
import * as mainReducer from "../redux/mainReducer";
import * as evReducer from "../redux/evReducer";
import * as rmsReducer from "../redux/rmsReducer";
import * as ofReducer from "../redux/ofReducer";
import * as stayHomeReducer from "../redux/stayHomeReducer";
import * as tpsReducer from "../redux/tpsReducer";
import {useDispatch} from "react-redux";
import {Routes} from "../utils/_index";
import {useNavigation} from "../misc/Hooks";
import {ClearStorage} from "../components/_index";


const ClearStorageContainer = (props) => {
    const dispatch = useDispatch();
    const navigation = useNavigation();


    useEffect(() => {
        dispatch(mainReducer.clearStore())
        dispatch(evReducer.clearStore())
        dispatch(rmsReducer.clearStore())
        dispatch(ofReducer.clearStore())
        dispatch(stayHomeReducer.clearStore())
        dispatch(tpsReducer.clearStore())

        setTimeout(() => {
            window.location.href = navigation(Routes.SHOP_MACHINE_REGISTRATION);
        }, 3000)
    }, [])


    return (
        <ClearStorage/>
    )
}


export default ClearStorageContainer;
