import React, {useEffect, useMemo, useState} from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyDatePicker, MyDropArea, MyInput, MySelect} from "./inputs/_index";
import {Button} from "../buttons/_index";
import {ProofOfPurchaseModal} from "../modals/_index";
import {useCountryConfig, useMarketConfig, useTranslation} from "../../misc/Hooks";
import {useDispatch, useSelector} from "react-redux";
import * as evReducer from "../../redux/evReducer";
import {regexFromString} from "../../misc/Helpers";
import Patterns from "../../utils/Patterns";
import {isEnabledByMarket} from "../../utils/MarketConfig";


export default (props) => {
    const t = useTranslation();
    const marketConfig = useMarketConfig();
    const countryConfig = useCountryConfig();
    const dispatch = useDispatch();
    const store = useSelector(({ev}) => ({
        ocrLogs: ev.ocrLogs,
        promoCode: ev.promoCode,
    }))

    const [popModalVisible, setPopModalVisible] = useState(false);
    const [shops, setShops] = useState([]);
    const [withCode, setWithCode] = useState(!!store.promoCode);
    const requiredField = props.user ? countryConfig?.required_field_reseller  : countryConfig?.required_field
    const promoCodeVisible = useMemo(() => requiredField ? (
        (
            (requiredField === 'proof_of_purchase_or_promo_code' && withCode)
            || requiredField === 'proof_of_purchase_and_promo_code'
            || requiredField === 'only_promo_code'
        )
        && requiredField !== 'only_proof_of_purchase'
    ) : false, [countryConfig, withCode])
    const popVisible = useMemo(() => requiredField ? (
        (
            (requiredField === 'proof_of_purchase_or_promo_code' && !withCode)
            || requiredField === 'proof_of_purchase_and_promo_code'
            || requiredField === 'only_proof_of_purchase'
        )
        && requiredField !== 'only_promo_code'
    ) : true, [countryConfig, withCode])

    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            code: props.code ?? '',
            file: props.data?.file ?? '',
            date: props.data?.date ?? '',
            tradePartner: props.data?.tradePartner ?? null,
            retailerStore: props.data?.retailerStore ?? null,
            invoiceNumber: props.data?.invoiceNumber ?? '',
            machinePrice: props.data?.machinePrice ?? '',
        }
    });
    const file = formMethods.watch('file');
    const tradePartner = formMethods.watch('tradePartner');

    const PROMO_CODE_PATTERN = regexFromString(countryConfig?.promo_code_regex) ?? /^.+/


    const rules = {
        code: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: PROMO_CODE_PATTERN, message: t('validation.invalid_promo_code') || t('validation.invalid')},
            maxLength: {value: countryConfig?.promo_code_regex_max_length, message: t('validation.max_length', countryConfig?.promo_code_regex_max_length)},
            // validate: v => {
            //     console.log(props.codeError)
            //     return props.codeError ? props.codeError : false
            // },
        },
        file: {
            required: {value: true, message: t('validation.pop_file_required')},
        },
        date: {
            required: {
                value: !!marketConfig.pop_validation_ocr_date_mandatory,
                message: t('validation.required')
            },
        },
        tradePartner: {
            required: {
                value: !!marketConfig.pop_validation_ocr_trade_partner_mandatory,
                message: t('validation.required')
            }
        },
        retailerStore: {
            required: {
                value: !!marketConfig.pop_validation_ocr_retailer_store_mandatory,
                message: t('validation.required')
            }
        },
        invoiceNumber: {
            required: {
                value: !!marketConfig.pop_validation_ocr_invoice_number_mandatory,
                message: t('validation.required')
            },
            // pattern: {value: Patterns.onlyNumbers, message: t('validation.invalid')},
        },
        machinePrice: {
            required: {
                value: !!marketConfig.pop_validation_ocr_machine_price_mandatory,
                message: t('validation.required')
            },
            pattern: {value: marketConfig.pop_validation_ocr_machine_price_mandatory ? Patterns.number : undefined, message: t('validation.invalid')}
        },
    }


    useEffect(() => {
        if(props.ocrData?.data.id_shop && tradePartner) {
            const shop = props.shops.find(g => g.id_shop_group === props.ocrData.data.id_shop_group)?.shops
                .find(s => s.id_shop === props.ocrData.data.id_shop);
            if(shop) {
                pushLog({manually: 0, field: 'id_shop', value: props.ocrData.data.id_shop});
                formMethods.setValue('retailerStore', {
                    label: shop.shop_title,
                    value: shop.id_shop,
                })
            }
        }
    }, [tradePartner])


    useEffect(() => {
        if(props.ocrData) {
            const ocrLogs = [];
            if(props.ocrData.image) {
                formMethods.setValue('file', {
                    base64: props.ocrData.image,
                });
                // ocrLogs.push({manually: 0, field: 'image', value: props.ocrData.image});
            }
            if(props.ocrData.data.date) {
                formMethods.setValue('date', new Date(props.ocrData.data.date).toISOString());
                ocrLogs.push({manually: 0, field: 'date', value: props.ocrData.data.date});
            }
            else {
                formMethods.setValue('date', null);
                if(rules.date.required.value) {
                    formMethods.setError('date', {type: 'validate', message: t('validation.pop_invalid')})
                }
            }
            if(props.ocrData?.data.id_shop_group) {
                const shopGroup = props.shops.find(s => s.id_shop_group === props.ocrData.data.id_shop_group);
                ocrLogs.push({manually: 0, field: 'id_shop_group', value: props.ocrData?.data.id_shop_group});
                if(shopGroup) {
                    setShops(mapShopsToOptions(shopGroup.shops));
                    formMethods.setValue('tradePartner', {
                        label: shopGroup.shop_group_title,
                        value: shopGroup.id_shop_group,
                    })
                }
            }
            else {
                formMethods.setValue('tradePartner', null);
                if(rules.tradePartner.required.value) {
                    formMethods.setError('tradePartner', {type: 'validate', message: t('validation.pop_invalid')})
                }
            }
            if(!props.ocrData?.data.id_shop) {
                formMethods.setValue('retailerStore', null);
                // pushLog({manually: 0, field: 'id_shop', value: props.ocrData?.data.id_shop});
                if(rules.retailerStore.required.value) {
                    formMethods.setError('retailerStore', {type: 'validate', message: t('validation.pop_invalid')})
                }
            }
            if(props.ocrData.data.invoice) {
                formMethods.setValue('invoiceNumber', props.ocrData.data.invoice)
                ocrLogs.push({manually: 0, field: 'invoice', value: props.ocrData.data.invoice});
            }
            else {
                formMethods.setValue('invoiceNumber', '');
                if(rules.invoiceNumber.required.value) {
                    formMethods.setError('invoiceNumber', {type: 'validate', message: t('validation.pop_invalid')})
                }
            }
            if(props.ocrData.data.total) {
                let value = props.ocrData.data.total
                if(marketConfig.pop_validation_ocr_machine_price_mandatory) {
                    const price = Number(
                        props.ocrData.data.total
                            ?.replace(/(,|\.)/, 'SEPARATOR')
                            .replace(/[,.]/g, '')
                            .replace('SEPARATOR', '.')
                            .replace(/^(,\.)|[^0-9.,]+/g, '')
                    )
                    if(typeof price === 'number' && !Number.isNaN(price) && Number.isFinite(price)) {
                        value = price
                    }
                }
                formMethods.setValue('machinePrice', value)
                ocrLogs.push({manually: 0, field: 'total', value});
            }
            else {
                formMethods.setValue('machinePrice', '');
                if(rules.machinePrice.required.value) {
                    formMethods.setError('machinePrice', {type: 'validate', message: t('validation.pop_invalid')})
                }
            }

            pushLog(ocrLogs)
        }
    }, [props.ocrData])


    useEffect(() => {
        if(props.error) {
            formMethods.setValue('file', null);
            formMethods.setError('file', {type: 'required', message: t('validation.pop_file_required')})
        }
    }, [props.error])

    useEffect(() => {
        if(props.codeError) {
            formMethods.setError('code', {value: true, message: props.codeError});
        }
        else {
            formMethods.clearErrors('code');
        }
    }, [props.codeError])


    const mapShopGroupsToOptions = () => {
        const options = [];
        props.shops?.map(shop => {
            options.push({
                label: shop.shop_group_title,
                value: shop.id_shop_group,
            })
        })
        return options;
    }

    const mapShopsToOptions = (shops: []) => {
        const options = [];
        shops?.map(shop => {
            let label = shop.shop_title;
            if(shop.shop_type === 'local') {
                if(isEnabledByMarket('shpNameAndCityLabel')) {
                    label += `, ${shop.shop_city}`
                } else {
                    label += ` ${shop.shop_address}, ${shop.shop_city}`
                }
            }
            options.push({
                label,
                value: shop.id_shop,
            })
        })
        return options;
    }


    const onSubmit = (data) => {
        props.onSubmit(data);
    }


    const handleChangeTradePartner = (value, onChange) => {
        formMethods.setValue('retailerStore', null);
        const shop = props.shops.find(s => s.id_shop_group === value.value);
        if(shop) {
            pushLog({manually: 1, field: 'id_shop_group', value: shop.id_shop_group});
            setShops(mapShopsToOptions(shop.shops))
        }
        onChange(value);
    }


    const handleUploadFile = (data, callback) => {
        if(data) {
            props.onFileUpload(data);
        }
        callback(data);
    }

    const pushLog = (l) => {
        const arr = [...store.ocrLogs.filter(l => l.type === 'proof_of_purchase')];
        if(Array.isArray(l)) {
            l.map(i => {
                arr.push({
                    type: 'proof_of_purchase',
                    date: new Date().toISOString(),
                    ...i,
                })
            })
        } else {
            arr.push({
                type: 'proof_of_purchase',
                date: new Date().toISOString(),
                ...l,
            })
        }
        dispatch(evReducer.setOcrLogs([
            ...store.ocrLogs.filter(l => l.type !== 'proof_of_purchase'),
            ...arr,
        ]))
    }

    const onChangePromoCode = async (e, callback) => {
        const value = e.target.value;
        if(value?.match(PROMO_CODE_PATTERN)) {
            props.onChangePromoCode(value)
        }
        callback(e);
    }

    return (
        <>
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>


                    {promoCodeVisible && (
                        <div className="--mb4">
                            <p
                                className="par-2 --semiBold"
                                dangerouslySetInnerHTML={{__html: t('ev_machine_registration.code_text')}}
                            />

                            <Controller
                                name="code"
                                control={formMethods.control}
                                rules={rules.code}
                                render={({onChange, value}) => (
                                    <MyInput
                                        type="text"
                                        label={t('promo_code')}
                                        value={value}
                                        error={formMethods.errors.code}
                                        loading={props.loadingPromoCode}
                                        inputProps={{
                                            maxLength: countryConfig?.promo_code_regex_max_length,
                                        }}
                                        onChange={e => onChangePromoCode(e, onChange)}
                                    />
                                )}
                            />

                            {requiredField === 'proof_of_purchase_or_promo_code' && (
                                <div className="serialNumber__footer">
                                    {!!t('ev_machine_registration.promo_code_info') && (
                                        <p className="par-4" dangerouslySetInnerHTML={{__html: t('ev_machine_registration.promo_code_info')}}/>
                                    )}

                                    <button
                                        type="button"
                                        className="link -primary"
                                        onClick={() => setWithCode(false)}
                                    >{t('ev_machine_registration.code_button')} <img src={require('../../assets/img/icons/arrow-right-brown.svg').default} alt=""/></button>
                                </div>
                            )}
                        </div>
                    )}


                    {popVisible && (
                        <div>
                            {requiredField === 'proof_of_purchase_or_promo_code' ? (
                                <div className="par-2">
                                    <span dangerouslySetInnerHTML={{__html: t('ev_machine_registration.pop_or_code1')}}/>
                                    <button
                                        className="link -parentSize -primary"
                                        onClick={() => setWithCode(!withCode)}
                                        type="button"
                                    >{t('ev_machine_registration.pop_or_code2')}</button>
                                    <span dangerouslySetInnerHTML={{__html: t('ev_machine_registration.pop_or_code3')}}/>
                                </div>
                            ) : (
                                <p
                                    className="par-2 --semiBold"
                                    dangerouslySetInnerHTML={{__html: t('ev_machine_registration.pop_text1')}}
                                />
                            )}

                            <Controller
                                name="file"
                                control={formMethods.control}
                                rules={rules.file}
                                render={({onChange, value}) => (
                                    <MyDropArea
                                        placeholder={t('upload_box')}
                                        value={value}
                                        loading={props.loading}
                                        error={formMethods.errors.file}
                                        acceptFiles={"image/png,image/jpeg,image/bmp,application/pdf"}
                                        onChange={v => handleUploadFile(v, onChange)}
                                    />
                                )}
                            />
                            {!file && (
                                <div className="--mb4">
                                    <p
                                        className="uploadBox__caption --mb1"
                                        dangerouslySetInnerHTML={{__html: t('ev_machine_registration.pop_text2')}}
                                    />

                                    <button
                                        type="button"
                                        className="link -primary"
                                        onClick={() => setPopModalVisible(true)}
                                    >{t('what_is_proof_of_purchase')}</button>
                                </div>
                            )}

                            {!!props.error && (
                                <p className="par-2 --error --mb4">{props.error.toString()}</p>
                            )}

                            {!!file && (
                                <div className="--mb4">
                                    {!!t('proof_of_purchase_details_info') && (
                                        <p className="par-2" dangerouslySetInnerHTML={{__html: t('proof_of_purchase_details_info')}} />
                                    )}
                                    {!!marketConfig.pop_validation_ocr_date_display && (
                                        <Controller
                                            name="date"
                                            control={formMethods.control}
                                            rules={rules.date}
                                            render={({onChange, value}) => (
                                                <MyDatePicker
                                                    name="date"
                                                    label={t('date')}
                                                    value={value}
                                                    max={new Date()}
                                                    loading={props.loading}
                                                    error={formMethods.errors.date}
                                                    info={t('pop.date.info')}
                                                    onChange={v => {
                                                        pushLog({manually: 1, field: 'date', value: v});
                                                        onChange(v);
                                                    }}
                                                />
                                            )}
                                        />
                                    )}

                                    {!!marketConfig.pop_validation_ocr_trade_partner_display && (
                                        <Controller
                                            name="tradePartner"
                                            control={formMethods.control}
                                            rules={rules.tradePartner}
                                            render={({onChange, value}) => (
                                                <MySelect
                                                    name="tradePartner"
                                                    label={t('trade_partner')}
                                                    value={value}
                                                    loading={props.loading}
                                                    options={mapShopGroupsToOptions()}
                                                    error={formMethods.errors.tradePartner}
                                                    onChange={v => handleChangeTradePartner(v, onChange)}
                                                    isSearchable
                                                />
                                            )}
                                        />
                                    )}

                                    {!!marketConfig.pop_validation_ocr_retailer_store_display && (
                                        <Controller
                                            name="retailerStore"
                                            control={formMethods.control}
                                            rules={rules.retailerStore}
                                            render={({onChange, value}) => (
                                                <MySelect
                                                    name="retailerStore"
                                                    label={t('retailer_store')}
                                                    value={value}
                                                    loading={props.loading}
                                                    options={shops}
                                                    error={formMethods.errors.retailerStore}
                                                    onChange={v => {
                                                        pushLog({manually: 1, field: 'id_shop', value: v.value});
                                                        onChange(v);
                                                    }}
                                                    isSearchable
                                                />
                                            )}
                                        />
                                    )}

                                    {!!marketConfig.pop_validation_ocr_invoice_number_display && (
                                        <Controller
                                            name="invoiceNumber"
                                            control={formMethods.control}
                                            rules={rules.invoiceNumber}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="invoiceNumber"
                                                    label={t('invoice_number')}
                                                    value={value}
                                                    loading={props.loading}
                                                    error={formMethods.errors.invoiceNumber}
                                                    onChange={v => {
                                                        pushLog({manually: 1, field: 'invoice', value: v.target.value});
                                                        onChange(v);
                                                    }}
                                                />
                                            )}
                                        />
                                    )}

                                    {!!marketConfig.pop_validation_ocr_machine_price_display && (
                                        <Controller
                                            name="machinePrice"
                                            control={formMethods.control}
                                            rules={rules.machinePrice}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="machinePrice"
                                                    label={t('machine_price')}
                                                    value={value}
                                                    loading={props.loading}
                                                    error={formMethods.errors.machinePrice}
                                                    onChange={v => {
                                                        pushLog({manually: 1, field: 'total', value: v.target.value});
                                                        onChange(v);
                                                    }}
                                                />
                                            )}
                                        />
                                    )}

                                    {!!t('proof_of_purchase_details_footer') && (
                                        <p className="par-2" dangerouslySetInnerHTML={{__html: t('proof_of_purchase_details_footer')}} />
                                    )}
                                </div>
                            )}
                        </div>

                    )}


                    <Button
                        btnType="submit"
                        type="primary"
                        label={t('continue')}
                        // disabled={!formMethods.formState.isValid}
                        onClick={formMethods.handleSubmit(onSubmit)}
                    />
                </form>
            </FormProvider>


            <ProofOfPurchaseModal
                visible={popModalVisible}
                onClose={() => setPopModalVisible(false)}
            />
        </>
    );
}
