import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import CustomerMS from "../../../components/reseller/CustomerMS";
import * as rmsReducer from "../../../redux/rmsReducer";
import {useNavigation, useRmsStepNavigation, useTranslation} from "../../../misc/Hooks";
import * as authActions from "../../../actions/AuthorizationActions";
import {useResellerActions} from "../../../actions/useResellerActions";


let MOUNTED = false;

export const CustomerContainer = (props) => {
    const actions = useResellerActions();
    const stepNavigation = useRmsStepNavigation();
    const t = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const store = useSelector(({main, rms}) => ({
        country: main.country,
        user: main.user,
        plans: rms.plans,
        machine: rms.machine,
        uuid: rms.uuid,
        serialNumber: rms.serialNumber,
        paymentMethod: rms.paymentMethod,
        customer: rms.customer,
        cart: rms.cart,
        products: rms.products,
        nespressoMember: rms.nespressoMember,
        banks: rms.banks,
        verificationCodeToken: rms.verificationCodeToken,
    }))
    const plan = useSelector(({rms}) => {
        return rms.products?.find(p => {
            const item = rms.cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(null);
    const [verificationCodeVisible, setVerificationCodeVisible] = useState(false);
    const [verificationCodeError, setVerificationCodeError] = useState(null);
    const [loadingBanks, setLoadingBanks] = useState(true);
    const [loadingBank, setLoadingBank] = useState(false);
    const [bank, setBank] = useState(null);
    const [bankError, setBankError] = useState(null);


    useEffect(() => {
        MOUNTED = true;

        getBanks();

        return () => {
            MOUNTED = false;
        }
    }, [])


    const getBanks = () => {
        actions.getBanks().then(r => {
            const res = r.data;
            if(res.status === 'success') {
                if(MOUNTED) {
                    dispatch(rmsReducer.setBanks(res.data))
                }
            }
            else {
                console.warn('Something gone wrong!', res)
            }
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            if(MOUNTED) {
                setLoadingBanks(false);
            }
        })
    }

    const handleSubmit = async (data) => {
        setSending(true);

        if(data.paymentMethod?.payment_method_need_code_verify && (!store.verificationCodeToken || !data.verificationCode)) {
            await resendCode(data.email)
            return;
        }
        else if(store.verificationCodeToken) {
            let error = null;
            setVerificationCodeError(null);
            await actions.verifyCode(data.verificationCode, store.verificationCodeToken).then(r => {
                const res = r.data;
                if(res.status === 'success') {
                    // ok
                }
                else {
                    if(res.messages?.length) {
                        if(MOUNTED) {
                            error = res.messages[0]
                        }
                    }
                }
            }).catch(err => {
                console.error(err)
            })
            if(error) {
                setVerificationCodeError(error);
                setSending(false)
                return;
            }
        }

        const formData = {
            ...data,
            recaptcha_token: data.recaptchaToken,
        }

        const params = {id_plan_machine: plan?.id_product ?? 0}


        setError(null)
        authActions.canRegister(formData, params).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                if(MOUNTED) {
                    dispatch(rmsReducer.setCanRegisterResponse(res.data))

                    switch (res.data.status) {
                        case 'ACCOUNT_EXISTS':
                            if(res.data.memberNumber) {
                                dispatch(rmsReducer.setCustomer(data));
                                history.push(stepNavigation.next());
                            }
                            else {
                                dispatch(rmsReducer.setCustomer(null))
                                setError('member_exists_error')
                            }
                            break;
                        case 'MEMBER_EXISTS':
                            dispatch(rmsReducer.setCustomer(data));
                            history.push(stepNavigation.next());
                            break;
                        case 'ALLOWED':
                            dispatch(rmsReducer.setCustomer(data));
                            history.push(stepNavigation.next());
                            break;
                        case 'NOT_ALLOWED':
                        default:
                            dispatch(rmsReducer.setCustomer(null))
                            setError('error.cannot_create_account')
                            break;
                    }
                }
            }
            else {
                if(['phone_to_many_times_used'].includes(res?.messages[0])) {
                    setError(res?.messages[0])
                }
                else if(store.user) {
                    dispatch(rmsReducer.setCustomer(data));
                    dispatch(rmsReducer.setNespressoMemberExist(true))
                    history.push(stepNavigation.next());
                }
                else if(res.data.json?.length) {
                    setError(res.data.json.reverse()[0].message)
                }
                else {
                    setError(res?.messages[0] ?? res?.data?.message ?? t('error.unknown_error'))
                    console.warn('Something gone wrong! ', res)
                }
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if(MOUNTED) {
                setSending(false);
            }
        })
    }


    const handleChangeAccountNumber = async (data) => {
    }

    const resendCode = async (email) => {
        const codeFormData = {
            email: email,
            id_country: store.country?.id_country,
        };
        setSending(true)
        await actions.generateCode(codeFormData, plan.id_product).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                if(MOUNTED) {
                    dispatch(rmsReducer.setVerificationCodeToken(res.data))
                    setVerificationCodeVisible(true);
                }
            }
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            if(MOUNTED) {
                setSending(false);
            }
        })
    }

    return (
        <CustomerMS
            country={store.country}
            customer={store.customer}
            nespressoMember={store.nespressoMember}
            banks={store.banks}
            plan={plan}
            error={error}
            sending={sending}
            loadingBanks={loadingBanks}
            loadingBank={loadingBank}
            bank={bank}
            bankError={bankError}
            verificationCodeVisible={verificationCodeVisible}
            verificationCodeError={verificationCodeError}
            onChangeAccountNumber={handleChangeAccountNumber}
            onResendCode={resendCode}
            onSubmit={handleSubmit}
        />
    )
}
