import Axios from "../utils/Axios";
import {queryParams} from "../misc/Helpers";


export const signIn = (data, paramObj = {}) => {
    const params = queryParams(paramObj)
    return Axios.post(`/sign-in${params}`, data);
}


export const canRegister = (data, paramObj = {}) => {
    const params = queryParams(paramObj)
    return Axios.post(`/can-register${params}`, data);
}


export const canAttempt = (data, paramObj = {}) => {
    const params = queryParams(paramObj)
    return Axios.post(`/can-attempt${params}`, data);
}


export const getAddresses = (data) => {
    return Axios.post(`/client-addresses`, data);
}


export const signInReseller = (data) => {
    return Axios.post(`/reseller/login`, data);
}

export const getReseller = () => {
    return Axios.get(`/reseller/user`);
}

export const registerReseller = (data) => {
    return Axios.post(`/reseller/register`, data);
}

export const verificationReseller = (status, token, parent_token) => {
    return Axios.get(`/reseller/verification/${status}/${token}/${parent_token}`);
}

export const setTemporaryPassword = (data) => {
    return Axios.post(`/reseller/update-password`, data);
}

