import React from 'react';
import {useTranslation} from "../../misc/Hooks";
import {Error404, Footer, Header} from "./_index";


interface Props {

}

export default (props: Props) => {
    return (
        <div className="app">
            <Header cartDisabled/>

            <div className="main">
                <div className="wrapper-bg">
                    <div className="wrapper">
                        <Error404/>
                    </div>
                </div>
            </div>

            <Footer simple/>
        </div>
    );
}
