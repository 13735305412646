import React, {useEffect, useState} from "react";
import UploadPop from "../components/UploadPop";
import {useHistory, useRouteMatch} from "react-router-dom";
import * as OrderActions from '../actions/OrderActions';
import * as mainActions from "../actions/MainActions";
import {useNavigation, useTranslation} from "../misc/Hooks";
import Routes from "../utils/Routes";
import Axios from "../utils/Axios";
import {useSelector} from "react-redux";
import {AppProvider} from "../misc/_index";
import {useLanguage} from "../hooks";


let MOUNTED = false;

const UploadPopContainer = (props) => {
    const t = useTranslation();
    const match = useRouteMatch();
    const [order, setOrder] = useState(null);
    const history = useHistory();
    const navigation = useNavigation();
    const currentLanguage = useLanguage();
    const [message, setMessage] = useState(null);
    const [processing, setProcessing] = useState(false);


    useEffect(() => {
        Axios.defaults.headers.common['Language'] = currentLanguage;

        MOUNTED = true;

        getOrderInfo();

        return () => {
            MOUNTED = false;
        }
    }, [])


    const getOrderInfo = () => {
        OrderActions.getOrderInfo(match.params.token).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                if(MOUNTED) {
                    // console.log('success!', res.data)
                    setOrder(res.data);
                }
            }
            else if(res.messages?.length) {
                if(res.messages[0] === 'order_not_exist') {
                    history.push(navigation(Routes.SHOP_MACHINE_REGISTRATION))
                }
            }
            else {
                console.warn('Something gone wrong!', res);
            }
        }).catch(err => {
            console.error('Error: ', err)
        })
    }


    const handleUploadFile = async (data) => {
        setProcessing(true);

        if(data.file?.type?.match(/^image/)) {
            await handleSubmit(data.file.base64);
        } if(data.file?.type?.match(/^application\/pdf/)) {
            const formData = {content: data.file.base64};
            formData.filename = data.file.base64;
            await mainActions.proofOfPurchasePdfOcr(formData).then(async r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (MOUNTED) {
                        await handleSubmit(res.data.image);
                        // console.log('success', res.data)
                    }
                }
            }).catch(err => {
                console.error('Error: ', err)
            })
        }

        setProcessing(false);
    }


    const handleSubmit = async (base64) => {
        const formData = {base64};
        await OrderActions.reUploadPop(match.params.token, formData).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                if(MOUNTED) {
                    // console.log('success!', res.data)
                    setMessage({type: 'success', text: t('pop_reupload_success')})
                }
            }
            else {
                if(MOUNTED) {
                    setMessage({type: 'error', text: t('pop_reupload_error')})
                }
                console.warn('Something gone wrong!', res);
            }
        }).catch(err => {
            console.error('Error: ', err)
        })
    }


    return (
        <AppProvider>
            <UploadPop
                order={order}
                message={message}
                processing={processing}
                onSubmit={handleUploadFile}
            />
        </AppProvider>
    )
}


export default UploadPopContainer;
