import React, {useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {Config, Routes} from "../../utils/_index";
import {Button} from "../buttons/_index";
import Table from "../Table";
import {useNavigation, useTranslation} from "../../misc/Hooks";
import * as rmsReducer from '../../redux/rmsReducer';
import {useDispatch} from "react-redux";
import {isoDateConverterWithTime} from "../../misc/Helpers";
import ResendEmailButton from "./ResendEmailButton";


const mapOrdersToOptions = (orders) => {
    return orders.map(order => ({
        id: order.id_order,
        tracking_code: order.tracking_code ?? '-',
        status_of_registration: order.status,
        status_of_payment: order.payment_status,
        date_time: isoDateConverterWithTime(order.created_at, '-', ':'),
        name_of_customer: `${order.delivery_firstname} ${order.delivery_lastname}`,
        email_address: order.delivery_email,
        machine_type: order.machine_coffee_type,
        serial_number: order.serial,
        options: order,
    }))
}

export default function MachineSubscription(props) {
    const t = useTranslation();
    const navigation = useNavigation();
    const history = useHistory();
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {Header: t('order.id'), accessor: 'id'},
        {Header: t('order.tracking_code'), accessor: 'tracking_code'},
        {
            Header: t('order.status_of_registration'),
            accessor: 'status_of_registration',
            Cell: props => <Button size="md" label={t(`subscription_status.${props.value}`)}/>
        },
        {
            Header: t('order.status_of_payment'),
            accessor: 'status_of_payment',
            Cell: props => <Button size="md" label={t(`payment_status.${props.value}`)}/>
        },
        {Header: t('order.date_time'), accessor: 'date_time'},
        {Header: t('order.name_of_customer'), accessor: 'name_of_customer'},
        {Header: t('order.email_address'), accessor: 'email_address'},
        {Header: t('order.machine_type'), accessor: 'machine_type'},
        {Header: t('order.serial_number'), accessor: 'serial_number'},
        {
            Header: t('order.options'),
            accessor: 'options',
            Cell: props => {
                const [processed, setProcessed] = useState(props.value.subscription_status === 1);

                useEffect(() => {
                    setProcessed(props.value.subscription_status === 1)
                }, [props.value])

                const handleClickProcess = () => {
                    setProcessed(true);
                    onProcessOrder(props.value.id_order);
                }

                return [1, 2].includes(props.value.payment_status) ? (
                    <Button
                        size="md"
                        label={t(processed ? 'table.processed' : 'table.process')}
                        disabled={processed}
                        onClick={handleClickProcess}
                    />
                ) : (
                    <ResendEmailButton
                        value={props.value}
                        onClick={onResendOrder}
                    />
                )
            }
        },
    ], [])
    const [showTodayPending, setShowTodayPending] = useState(false);

    const getTodayOptions = (orders) => {
        if(showTodayPending) {
            return orders;
        }
        else {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);
            return orders.filter(o => Date.parse(o.created_at) < today.getTime())
        }
    }

    const processingOrders = useMemo(() => mapOrdersToOptions(getTodayOptions(props.processingOrders)), [props.processingOrders, showTodayPending])
    const inTreatmentOrders = useMemo(() => mapOrdersToOptions(getTodayOptions(props.inTreatmentOrders)), [props.inTreatmentOrders, showTodayPending])


    const onResendOrder = (id) => {
        props.onResendOrder(id);
    }


    const onProcessOrder = (id) => {
        props.onProcessOrder(id);
    }


    const navToMachineSubscription = () => {
        dispatch(rmsReducer.clearStore())
        history.push(navigation(Routes.RESELLER_MACHINE_SUBSCRIPTION_MACHINE))
    }


    return (
        <>
            <div className="resMS">
                <div className="resMS__header">
                    <div className="--flex --spaceBetween">
                <Button
                    type="primary-outline"
                    label={t('register_new_customer')}
                    onClick={navToMachineSubscription}
                />
                <span style={{paddingLeft: '1rem'}}>
                    <Button
                        type="primary-outline"
                        label={t(showTodayPending ? 'hide_orders' : 'show_orders')}
                        onClick={() => setShowTodayPending(!showTodayPending)}
                    />
                </span>
            </div>
                </div>

                <div className="resMS__table">
                    <Table
                        title={t('subscription_status.processed')}
                        columns={columns}
                        data={inTreatmentOrders}
                        maxPage={props.inTreatmentOrdersMaxPage}
                        results={props.inTreatmentOrdersResults}
                        loading={props.inTreatmentOrdersLoading}
                        getData={(cp, ps, v = null) => props.getData(Config.SUBSCRIPTION_STATUS.PROCESSED, cp, ps, v)}
                        name="processed"
                        repeatTimeout={20000}
                        autoFetch
                        repeatFetching
                    />
                </div>

                <div className="resMS__table">
                    <Table
                        title={t('subscription_status.in_treatment')}
                        columns={columns}
                        data={processingOrders}
                        maxPage={props.processingOrdersMaxPage}
                        results={props.processingOrdersResults}
                        loading={props.processingOrdersLoading}
                        getData={(cp, ps, v = null) => props.getData(Config.SUBSCRIPTION_STATUS.PENDING, cp, ps, v)}
                        name="treatment"
                        repeatTimeout={20000}
                        autoFetch
                        repeatFetching
                    />
                </div>
            </div>
        </>
    )
}
