import SummaryScreen from "../screens/SummaryScreen";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigation, useTranslation} from "../../../misc/Hooks";
import Env from "../../../utils/Env";
import * as orderActions from "../../../actions/OrderActions";
import {Config, Routes} from "../../../utils/_index";
import {useHistory, useRouteMatch} from "react-router-dom";
import * as stayHomeReducer from "../../../redux/stayHomeReducer";
import {GTM} from "../../../misc/_index";


let MOUNTED = false

export default function SummaryContainer(props) {
    const dispatch = useDispatch()
    const navigation = useNavigation();
    const history = useHistory();
    const match = useRouteMatch();
    const t = useTranslation();
    const store = useSelector(({main, stayHome}) => ({
        country: main.country,
        uuid: stayHome.uuid,
        data: stayHome.data,
        token: stayHome.token,
        customer: stayHome.customer,
        order: stayHome.order,
        cart: stayHome.cart,
        planMachine: stayHome.planMachine,
        paymentMethod: stayHome.paymentMethod,
        nespressoMember: stayHome.nespressoMember,
    }))
    const plan = useSelector(({stayHome}) => {
        return stayHome.products?.find(p => {
            const item = stayHome.cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [orderState, setOrderState] = useState({
        loading: false,
        errorLog: store.order?.res?.error ? JSON.stringify(store.order) : null,
        error: store.order?.res?.error ? store.order.order ? 'order_saved_error_occurred' : 'order_save_error' : null,
    })


    useEffect(() => {
        MOUNTED = true
        document.title = t('stay_home.summary.title');
        GTM.showPage(t('stay_home.summary.title'))

        return () => {
            MOUNTED = false
        }
    }, [])


    const handleSubmit = async () => {
        let formData = {
            customer: {
                source: 'ev',
                test_order: store.testOrder ? 1 : 0,
                id_machine: store.planMachine.id_machine,
                id_country: store.country.id_country,
                promotion_type: Config.PROMO_TYPE.STAY_HOME,
                coffee_type: store.planMachine.machine_coffee_type,
                serial: null,
                uuid: store.uuid,
                lang: match.params.lang ?? Env.DEFAULT_LANG,
                currency_prefix: store.country.country_currency_prefix,
                currency_suffix: store.country.country_currency_suffix,
                currency_name: store.country.country_currency_name,

                address_id: store.customer?.memberAddress?.value ?? null,

                delivery_is_company: store.customer.companyAddress ? 1 : 0,
                delivery_company_name: store.customer.companyName ?? '',
                delivery_company_tax_id: store.customer.taxId ?? '',
                delivery_company_irs: store.customer.irs ?? '',
                delivery_civility: store.customer.title ? Number(store.customer.title.value) : 0,
                delivery_firstname: store.customer.name ?? '',
                delivery_lastname: store.customer.surname ?? '',
                delivery_zipcode: store.customer.postcode ?? '',
                delivery_city: store.customer.city ?? '',
                delivery_address1: store.customer.address ?? '',
                delivery_address2: store.customer.address2 ?? '',
                delivery_country: store.customer.country?.value ?? '',
                delivery_phone: store.customer.phone ?? '',
                delivery_phone_mobile: store.customer?.phoneMobile ?? '',
                delivery_phone_home: store.customer?.phoneHome ?? '',
                delivery_email: store.customer.email ?? '',

                other_delivery: store.customer.withCustomAddress ? 1 : 0,
                other_delivery_is_company: store.customer.customCompanyAddress ? 1 : 0,
                other_delivery_company_name: store.customer.customCompanyName ?? '',
                other_delivery_civility: store.customer.customTitle ? Number(store.customer.customTitle.value) : 0,
                other_delivery_firstname: store.customer.customName ?? '',
                other_delivery_lastname: store.customer.customSurname ?? '',
                other_delivery_zipcode: store.customer.customPostcode ?? '',
                other_delivery_city: store.customer.customCity ?? '',
                other_delivery_address1: store.customer.customAddress ?? '',
                other_delivery_address2: store.customer.customAddress2 ?? '',
                other_delivery_country: store.customer.customCountry?.value ?? '',
                other_delivery_phone: store.customer.customPhone ?? '',
                other_delivery_phone_mobile: store.customer?.customPhoneMobile ?? '',
                other_delivery_phone_home: store.customer?.customPhoneHome ?? '',
                other_delivery_email: store.customer.customEmail ?? '',

                marketing_email: store.customer.benefits?.email ? 1 : 0,
                marketing_mobile: store.customer.benefits?.mobile ? 1 : 0,
                marketing_phone: store.customer.benefits?.phone ? 1 : 0,
                marketing_post: store.customer.benefits?.post ? 1 : 0,

                regulations: store.customer.termsAndConditions ? 1 : 0,
                privacy_policy: store.customer.privacyPolicy ? 1 : 0,

                id_payment_method: store.paymentMethod?.id_payment_method ?? null,
                payment_method_id_nestle: store.paymentMethod?.id_nestle ?? '',

                member_number: store.nespressoMember?.member_id ?? '',
                member_token: store.nespressoMember?.token ?? '',
                member_password: store.customer.password ?? '',


                can_register_status: store.canRegisterResponse,
                webaccount_exist: store.nespressoMember ? 1 : 0,
            },

            cart: store.cart,
            recaptcha_token: recaptchaToken,
        };

        if (store.customer.companyAddress?.value) {
            formData = {
                ...formData,
                customer: {
                    ...formData.customer,
                    delivery_company_name: store.customer.companyName ?? '',
                    delivery_company_tax_id: store.customer.taxId ?? '',
                    delivery_company_irs: store.customer.irs ?? '',
                }
            }
        }

        if (store.canRegisterResponse?.status === 'MEMBER_EXISTS') {
            formData.customer.member_number = store.canRegisterResponse?.memberNumber;
        }

        setOrderState(old => ({...old, loading: true}))
        orderActions.finalizeStayHome(formData, store.token)
            .then(async r => {
                const res = r.data
                if (res.status === 'success') {
                    if (MOUNTED) {
                        dispatch(stayHomeReducer.setOrder(res.data));
                        if (res.data.type === 'TYP') {
                            history.push(navigation(Routes.STAY_HOME_ORDER_FINALIZED, res.data.order.uuid))
                        } else if (res.data.type === 'HOP') {
                            window.location.href = res.data.url
                        }
                    }
                } else {
                    setOrderState(old => ({...old, errorLog: JSON.stringify(res)}))
                    if (res.messages?.length) {

                        if (res.messages[0] === 'linking_error') {
                            const memberNumber = res.data.order?.member_number;
                            const memberLinked = await linkMember(memberNumber)
                            if (memberLinked) {
                                if (MOUNTED) {
                                    dispatch(stayHomeReducer.setOrder(memberLinked));
                                    if (memberLinked.type === 'TYP') {
                                        history.push(navigation(Routes.STAY_HOME_ORDER_FINALIZED, res.data.order.uuid))
                                    } else if (memberLinked.type === 'HOP') {
                                        window.location.href = memberLinked.url
                                    }
                                }
                            } else {
                                setOrderState(old => ({...old, error: 'linking_error'}))
                            }
                        } else if (res.messages.length > 1) {
                            setOrderState(old => ({...old, error: res.data?.order ? 'order_saved_error_occurred' : 'order_save_error'}))
                        } else {
                            if (res.data?.order) {
                                dispatch(stayHomeReducer.setOrder(res.data));
                            }

                            setOrderState(old => ({...old, error: res.data?.order ? 'order_saved_error_occurred' : 'order_save_error'}))
                        }
                    } else {
                        setOrderState(old => ({...old, error: res?.data?.message ?? t('error.unknown_error')}))
                        console.error('Something gone wrong! ', res)
                    }
                }
            }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if (MOUNTED) {
                setOrderState(old => ({...old, loading: false}))
            }
        })
    }

    const linkMember = async (memberNumber, attempt = 0) => {
        let result = null;
        attempt++;

        await orderActions.linkMember(memberNumber).then(async r => {
            const res = r.data;
            if (res.status === 'success') {
                result = res.data;
            } else {
                if (!res.data?.order && attempt < 30) {
                    await new Promise(resolve => setTimeout(resolve, 20000))
                    result = await linkMember(memberNumber, attempt)
                }
            }
        }).catch(err => {
            console.error(err)
        })

        return result;
    }

    const handleSendErrors = () => {
        // todo: implement functionality
    }

    const handlePressRestart = () => {
        dispatch(stayHomeReducer.clearStore())
    }

    const handleChangePaymentMethod = (data) => {
        const paymentMethod = store.data.payment_methods?.find(p => p.id_payment_method === data.paymentMethodId)
        if(paymentMethod) {
            dispatch(stayHomeReducer.setPaymentMethod(paymentMethod))
        }
    }

    return (
        <SummaryScreen
            data={store.data}
            plan={plan}
            customer={store.customer}
            order={store.order}
            paymentMethod={store.paymentMethod}
            orderState={orderState}
            recaptchaToken={recaptchaToken}
            onChangeRecaptchaToken={token => setRecaptchaToken(token)}
            onSendErrors={handleSendErrors}
            onPressRestart={handlePressRestart}
            onChangePaymentMethod={handleChangePaymentMethod}
            onSubmit={handleSubmit}
        />
    )
}
