import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import {MyInput, MySelect} from "./inputs/_index";
import Patterns from "../../utils/Patterns";
import {useTranslation} from "../../misc/Hooks";
import {Button} from "../buttons/_index";
import {Link} from "react-router-dom";
import {useCountryOptions} from "../../hooks";


export default (props) => {
    const t = useTranslation();
    const countryOptions = useCountryOptions()
    const {control, errors, watch, setValue, handleSubmit} = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            email: props.data?.email ?? '',
            lang: countryOptions.findLanguage(props.data?.lang),
        }
    });

    useEffect(() => {
        if(props.data?.lang)
            setValue('lang', countryOptions.findLanguage(props.data?.lang))
    }, [countryOptions.languageOptions])

    const rules = {
        email: {
            required: {
                value: true,
                message: t('validation.required'),
            },
            pattern: {
                value: Patterns.email,
                message: t('validation.type_email')
            },
        },
        lang: {
            required: {value: true, message: t('validation.required')},
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>

                <Controller
                    name="email"
                    control={control}
                    rules={rules.email}
                    render={({onChange, value}) => (
                        <MyInput
                            type="email"
                            label={t('email')}
                            value={value}
                            error={errors.email}
                            onChange={onChange}
                        />
                    )}
                />

                <Controller
                    name="lang"
                    control={control}
                    rules={rules.lang}
                    render={({onChange, value}) => (
                        <MySelect
                            name="lang"
                            label={t('lang')}
                            value={value}
                            options={countryOptions.languageOptions}
                            error={errors.lang}
                            onChange={onChange}
                        />
                    )}
                />

                <div className="step__nav">
                    <Link to={props.stepNavigation.prev()}>
                        <Button
                            type="primary-outline"
                            label={t('back')}
                        />
                    </Link>
                    <Button
                        btnType="submit"
                        type="primary"
                        label={props.submitText ?? t('continue')}
                        onClick={handleSubmit(props.onSubmit)}
                    />
                </div>
            </form>
        </>
    );
}
