import React from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyDropArea} from "./inputs/_index";
import {Button} from "../buttons/_index";
import {useTranslation} from "../../misc/Hooks";
import {Preloader} from "../../misc/_index";


export default (props) => {
    const t = useTranslation();
    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            file: '',
        }
    });
    const file = formMethods.watch('file');


    const rules = {
        file: {
            required: {value: true, message: t('validation.required')},
        },
    }


    return (
        <FormProvider {...formMethods}>
            <form onSubmit={!props.disabled ? formMethods.handleSubmit(props.onSubmit) : undefined}>

                <p className="par-2">{props.text ?? t('upload_pop.heading')}</p>
                <Controller
                    name="file"
                    control={formMethods.control}
                    rules={rules.file}
                    render={({onChange, value}) => (
                        <MyDropArea
                            value={value}
                            placeholder={t('upload_pop.drop_area_text')}
                            acceptFiles={"image/png,image/jpeg,image/bmp,application/pdf"}
                            error={formMethods.errors.file}
                            onChange={onChange}
                        />
                    )}
                />
                <div className="--mb4">
                    <p
                        className="uploadBox__caption"
                        dangerouslySetInnerHTML={{__html: t('ev_machine_registration.pop_text2')}}
                    />
                </div>


                <div className="--mb4">
                    <Button
                        btnType="submit"
                        type="primary"
                        label={t('upload')}
                        disabled={!formMethods.formState.isValid || props.loading || props.disabled}
                        onClick={formMethods.handleSubmit(props.onSubmit)}
                    />
                </div>

                {!!props.loading && (
                   <Preloader/>
                )}
            </form>
        </FormProvider>
    );
}
