import {getImage} from "../../../misc/Helpers";
import {Config} from "../../../utils/_index";
import React from "react";


/**
 *
 * @param gallery
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function Banner({gallery}) {
    const url = getImage(gallery, Config.GALLERY_TYPE.BACKGROUND)

    if(!url)
        return null

    return (
        <div className="eTrade__banner">
            <img src={url} alt="Nespresso" />
        </div>
    )
}
