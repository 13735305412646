import React from "react";
import {useSelector} from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import Env from "../../../utils/Env";
import {Config} from "../../../utils/_index";
import {useLanguage} from "../../../hooks";


export const RecaptchaV2 = (props) => {
    const currentLanguage = useLanguage();
    const store = useSelector(({main}) => ({
        country: main.country,
        recaptchaKey: main.recaptchaKey,
    }))




    const onChange = (token) => {
        if (typeof props.onChange === 'function') {
            props.onChange(token)
        }
    }

    const onExpired = () => {
        if (typeof props.onChange === 'function') {
            props.onChange('')
        }
    }


    if (!store.recaptchaKey && Env.ENV !== 'dev') {
        return (
            <p className="--error">Recaptcha key not found!</p>
        );
    }

    let formGroupClassNames = ''
    if (props.error) {
        formGroupClassNames += ' -invalid';
    }

    return (
        <div className={`formGroup ${formGroupClassNames}`}>
            <ReCAPTCHA
                sitekey={Env.ENV === 'dev' ? Config.RECAPTCHA_DEV : store.recaptchaKey}
                size={document.body.offsetWidth < 768 ? 'compact' : 'normal'}
                hl={getCountryCode(currentLanguage)}
                onChange={onChange}
                onExpired={onExpired}
            />

            {!!props.error && (
                <span className="formGroup__invalidFeedback">{props.error.message}</span>
            )}
        </div>
    )
}


const codes = {
    'cz': 'cs',
    'gr': 'el',
    'dk': 'da',
};

function getCountryCode(code) {
    return codes[code] ?? code;
}

