import {Routes} from "../../../utils/_index";
import {useNavigation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";


/**
 * Validates Stay Home module route / step
 * @param route
 * @type route: string
 * @returns {{lastValidRoute: (*), value: boolean}}
 */
export default function useStepValidation(route) {
    const navigation = useNavigation()
    const store = useSelector(({stayHome}) => ({
        token: stayHome.token,
        data: stayHome.data,
        nespressoMember: stayHome.nespressoMember,
        userHasPlan: stayHome.userHasPlan,
        customer: stayHome.customer,
        order: stayHome.order,
    }))
    const plan = useSelector(({stayHome}) => {
        return stayHome.products?.find(p => {
            const item = stayHome.cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })

    const response = {
        canShowStep: true,
        lastValidStep: Routes.STAY_HOME,
    }

    if(store.order) {
        response.canShowStep = route === Routes.STAY_HOME_SUMMARY
        response.lastValidStep = Routes.STAY_HOME_SUMMARY
    } else {
        switch (route) {
            case Routes.STAY_HOME_SUMMARY:
                if(!store.customer) {
                    response.canShowStep = false
                    response.lastValidStep = Routes.STAY_HOME_CUSTOMER
                }
            case Routes.STAY_HOME_CUSTOMER:
                if(store.userHasPlan === false ? !plan : false) {
                    response.canShowStep = false
                    response.lastValidStep = Routes.STAY_HOME_PLAN_SELECTION
                }
            case Routes.STAY_HOME_PLAN_SELECTION:
                if(store.nespressoMember === undefined || store.nespressoMember === null) {
                    response.canShowStep = false
                    response.lastValidStep = Routes.STAY_HOME_AUTHORIZATION
                }
            case Routes.STAY_HOME_AUTHORIZATION:
                if(!store.token || !store.data) {
                    response.canShowStep = false
                    response.lastValidStep = Routes.STAY_HOME
                }
            case Routes.STAY_HOME:
        }
    }


    return {
        value: response.canShowStep,
        lastValidRoute: navigation(response.lastValidStep),
    }
}
