import React, {useEffect} from "react";
import {OrderFinishSignInForm, PasswordForm} from "../../components/forms/_index";
import {useNavigation, useOfStepNavigation, useTranslation} from "../../misc/Hooks";
import {Button} from "../buttons/_index";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {Preloader} from "../../misc/_index";


export default (props) => {
  const t = useTranslation();
  const stepNavigation = useOfStepNavigation();
  const navigation = useNavigation();
  const store = useSelector(state => ({}))


  useEffect(() => {
  }, [])


  return (
    <div>
      <div className="grid">
        <div>
          {!props.nespressoMember ? (props.order?.webaccount_exist ? (
            <>
              <div className="--mb4">
                <OrderFinishSignInForm
                  email={props.order.delivery_email}
                  error={props.error}
                  loading={props.loading}
                  onSubmit={props.onSubmit}
                />
              </div>
              {!!props.loading && <Preloader/>}
            </>
          ) : (
            <>
              <PasswordForm
                error={props.error}
                loading={props.loading}
                onSubmit={props.onSubmitPassword}
              />
              {!!props.loading && <Preloader/>}
            </>
          )) : (
            <>
              <p className="par-2 --mb4">{t('finish_order_account.you_are_signed_in')}</p>
              <div className="step__nav -bottom">
                {/*<Button*/}
                {/*  type="primary-outline"*/}
                {/*  size="sm"*/}
                {/*  label={t('logout')}*/}
                {/*  onClick={props.signOut}*/}
                {/*/>*/}
                <Link to={stepNavigation.next()}>
                  <Button
                    label={t('continue')}
                  />
                </Link>
              </div>
            </>
          )}
        </div>
        <div/>
      </div>
    </div>
  )
}

