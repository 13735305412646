import React, {useEffect} from 'react';
import BaseModal from "../../modals/BaseModal";
import {useTranslation} from "../../../misc/Hooks";
import {Button} from "../../buttons/_index";
import {Preloader} from "../../../misc/_index";


interface Props {
    visible: boolean,
    item: any,
    itemInCart: any,
    onClose: void,
    config?: any
}


export default (props: Props) => {
    const t = useTranslation();

    const onClose = () => {
        props.onClose()
    }

    return (
        <BaseModal
            visible={props.visible}
            onClose={onClose}
        >
            <div className="raportMessage">
                           <span
                               className={`--dBlock --alignCenter --mb3 --bold ${!props.config.loading ? '--success' : ''}`}>
                               {props.config.loading ? t('reports_users.modal.in_the_process') : t('reports_users.modal.ready')}
                          </span>
                        {((!props.config.loading) && props.config.url) ? (
                            <a href={props.config.url} download>
                                <Button label={t('reports_users.modal.download')}/>
                            </a>
                        ) : (
                            <Preloader/>
                        )
                        }
                </div>
        </BaseModal>
    );
}
