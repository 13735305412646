import React, {useEffect, useState} from 'react';
import {useTranslation} from "./Hooks";


interface Props {
    value: number,
    step: number,
    packageQty: number,
    max: number,
    onSubmit: void,
    onClose: void,
}


export default (props: Props) => {
    const t = useTranslation();
    const [value, setValue] = useState(props.value);

    useEffect(() => {
    }, [])

    const handleChange = (v = value) => {
        if(v % props.step === 0 && v <= props.max && v >= 0) {
            props.onSubmit(v);
        }
    }


    const handleSubmit = (e) => {
        e?.preventDefault();
        const v = Number(value) / props.packageQty;
        if(v % props.step === 0 && v <= props.max && v >= 0) {
            handleChange(v)
        }
    }

    const values = new Array(15).fill(0)
        .map((_, idx) => {
            if(idx > 10)
                return (props.packageQty * 10) + ((props.packageQty * 10) / 2) * (idx - 10)
            return props.packageQty * idx
        })
        .filter(v => v <= props.max * props.packageQty)

    return (
        <>
            <div className="valuesTooltipOverlay" onClick={props.onClose}/>
            <div className="valuesTooltip">
                {!!t('values_tooltip.text') && (
                    <div
                        className="valuesTooltip__header"
                        dangerouslySetInnerHTML={{
                            __html: t(
                                'values_tooltip.text',
                                {name: 'value', value: props.packageQty * props.step},
                                {name: 'packageQty', value: props.packageQty * props.step},
                                {name: 'step', value: props.step},
                            )
                        }}
                    />
                )}
                <div className="valuesTooltip__body" style={{paddingTop: t('values_tooltip.text') ? 0 : undefined}}>
                    <div className="valuesTooltip__items">
                        {values.map((v, idx) => (
                            <button
                                key={idx}
                                onClick={() => handleChange(v / props.packageQty)}
                            >{v}</button>
                        ))}
                    </div>
                    <form className="valuesTooltip__form" onSubmit={handleSubmit}>
                        <label>{t('value')}</label>
                        <input
                            type="number"
                            value={value}
                            min={0}
                            max={props.max * props.packageQty}
                            step={props.packageQty}
                            onChange={e => setValue(e.target.value)}
                        />
                        <button type="submit">{t('ok')}</button>
                    </form>
                </div>
            </div>
        </>
    );
}
