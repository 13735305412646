import React from "react";
import {Header} from "../components/misc/_index";
import {useTranslation} from "../misc/Hooks";


const PaymentRejected = (props) => {
    const t = useTranslation();


    return (
        <div className="app">
            <Header cartDisabled/>

            <div className="main">
                <div className="wrapper-bg formContainer">

                    <div className="wrapper">
                        <div className="errorPage" style={{maxWidth: '650px', margin: '0 auto'}}>
                            <h1 className="par-1">{t('payment_rejected.title')}</h1>
                            <h3 className="par-1" dangerouslySetInnerHTML={{__html: t('payment_rejected.text')}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PaymentRejected;
