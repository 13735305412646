import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {useRmsSteps, useTranslation} from "../../../misc/Hooks";
import RMSStep from "../../../misc/RMSStep";
import {RmsAppConfig} from "../../../misc/_index";
import {useIdleTimer} from "react-idle-timer";
import {Config} from "../../../utils/_index";
import * as rmsReducer from "../../../redux/rmsReducer";


let MOUNTED = false;

export const MachineSubscriptionFunnel = (props) => {
    const t = useTranslation();
    const match = useRouteMatch();
    const steps = useRmsSteps();
    const dispatch = useDispatch();
    const store = useSelector(({rms}) => ({
        cart: rms.cart,
        uuid: rms.uuid,
        lastVisit: rms.lastVisit,
    }));
    const [step, setStep] = useState(0);

    const {getLastActiveTime} = useIdleTimer({
        timeout: Config.RESELLER_MACHINE_SUBSCRIPTION_SESSION_TIME,
        onIdle: e => dispatch(rmsReducer.clearStore()),
        debounce: 500,
    })

    useEffect(() => {
        MOUNTED = true;

        if (store.lastVisit) {
            if ((store.lastVisit + Config.RESELLER_MACHINE_SUBSCRIPTION_SESSION_TIME) <= Date.now()) {
                dispatch(rmsReducer.clearStore());
            }
        }

        window.addEventListener("beforeunload", function (e) {
            dispatch(rmsReducer.setLastVisit(getLastActiveTime()))
        });

        return () => {
            MOUNTED = false;
        }
    }, [])

    return (
       <div className="wrapper -xxl">
       <RmsAppConfig>
            <Switch>

                <Route
                    exact path={[
                    `${match.path}`,
                    ...steps.map(s => s.route)
                ]}
                >
                    {steps.map((s, idx) => (
                        <RMSStep
                            key={idx}
                            index={idx}
                            currentStep={step}
                            steps={steps}
                            route={s.route}
                            title={t(s.title)}
                            setCurrentStep={() => setStep(idx)}
                        >
                            {s.component}
                        </RMSStep>
                    ))}
                </Route>

            </Switch>
        </RmsAppConfig>
       </div>
    )
}
