import React, {useEffect, useMemo} from 'react';
import {Config, Routes} from "../../utils/_index";
import {Alert, CartSummary} from "../_index";
import {
    useCart,
    useEvStepNavigation,
    useEvSteps,
    useLocalizeObject,
    useMarketConfig,
    useNavigation,
    usePriceFormatter,
    useTranslation
} from "../../misc/Hooks";
import {Button} from "../buttons/_index";
import Env from "../../utils/Env";
import {PaymentTypeForm} from "../forms/_index";
import {
    calcMemberCredit, calcQuotationCart,
    getAllStepsHeaderHeight,
   getCartCapsulesValue, getCartValueByCustomVat,
    getStepHeaderHeight
} from "../../misc/Helpers";
import {MyLink, Preloader} from "../../misc/_index";
import {useSelector} from "react-redux";
import {RecaptchaV2} from "../forms/inputs/_index";
import {useLanguage} from "../../hooks";
import CartSummarySimple from "../CartSummarySimple";
import {isEnabledByMarket} from "../../utils/MarketConfig";
import MemberCreditInfo from "../MemberCreditInfo";

interface Props {
    cart: any,
    categories: any,
    products: any,
    customer: any,
}

const DeliveryPaymentStep = (props) => {
    const t = useTranslation();
    const localizeObject = useLocalizeObject();
    const {formatPrice} = usePriceFormatter();
    const {
        capsulesValue,
        capsulesNetValue,
        capsulesVatValue,
        giftValue,
        giftNetValue,
        deliveryValue,
        deliveryNetValue,
        cartValue,
        isCartValid,
        giftValueWithoutPromo,
    } = useCart('ev', props.order ? {
        cart: props.cart,
        products: props.products,
        categories: props.categories,
        promotion: props.promotion,
    } : null, false);
    const steps = useEvSteps();
    const stepNavigation = useEvStepNavigation();
    const navigation = useNavigation()
    const marketConfig = useMarketConfig();
    const currentLanguage = useLanguage()
    const store = useSelector(({main, ev}) => ({
        marketConfig: main.marketConfig,
        vatModifiers: main.vatModifiers,
        country: main.country,
        cart: ev.cart,
        products: ev.products,
        user: main.user,
    }))

    const cartValueByCustomVat = getCartCapsulesValue(
        props.order ? props.cart : store.cart,
        props.order ? props.products : store.products,
        store.vatModifiers?.find(v => v.vat_modifier_type === 'postcode'
            && v.vat_modifier_value === props.customer?.postcode)?.vat_modifier_vat
    )
    const showNetPrice = useSelector(({main}) => main.marketConfig?.market_price_type_display) === 'net';
    const quotationCartValues = useMemo(() => calcQuotationCart(null && props.cartQuotationData?.lines), [props.cartQuotationData])


    useEffect(() => {
        const cartWrapperRef = document.querySelector('.evDP__cart')
        if (cartWrapperRef) {
            cartWrapperRef.style.marginTop = `-${getAllStepsHeaderHeight()}px`
        }
    })

    const getErrorFile = () => {
        return "data:text/json;charset=utf-8," + encodeURIComponent(props.errorLog);
    }

    const InfoComponent = ({label, value}) => !!value && (
        <div className="info__info">
            <h5 className="info__name">{label}</h5>
            <p className="info__value">{value}</p>
        </div>
    )

    const CustomAddressComponent = () => (
        <div>
            <h3 className="evDP__title">{t('ev_delivery_payment.custom_delivery_address')}</h3>
            <div className="info">
                {props.customer.customCompanyAddress ? (
                    <>
                        <InfoComponent label={t('company_name')} value={props.customer.customCompanyName}/>
                        <InfoComponent label={t('tax_id')} value={props.customer.customTaxId}/>
                    </>
                ) : (
                    <InfoComponent label={t('title')} value={props.customer.customTitle?.label}/>
                )}
                <div className="info__row">
                    <InfoComponent label={t('name')} value={props.customer.customName}/>
                    <InfoComponent label={t('surname')} value={props.customer.customSurname}/>
                </div>
                <div className="info__row">
                    <InfoComponent label={t('postcode')} value={props.customer.customPostcode}/>
                    <InfoComponent label={t('city')} value={props.customer.customCity}/>
                </div>
                <InfoComponent label={t('address_line_1')} value={props.customer.customAddress}/>
                <InfoComponent label={t('address_line_2')} value={props.customer.customAddress2}/>
                <InfoComponent label={t('region')} value={props.customer.customRegion?.label}/>
                <InfoComponent label={t('phone')} value={props.customer.customPhone}/>
                <InfoComponent label={t('phone_mobile')} value={props.customer.customPhoneMobile}/>
                <InfoComponent label={t('phone_home')} value={props.customer.customPhoneHome}/>
            </div>
        </div>
    )

    const SummaryComponent = () => {
        let subtotal = formatPrice(cartValueByCustomVat?.gross)
        let benefit = formatPrice(showNetPrice ? Math.abs(giftNetValue) : Math.abs(giftValue + giftValueWithoutPromo.gross))
        let tax = formatPrice(cartValueByCustomVat?.vat)
        let total = formatPrice(cartValueByCustomVat?.gross + deliveryValue + giftValue + giftValueWithoutPromo.gross)

        if (![Config.PROMO_TYPE.EASY_ORDER].includes(props.promotion.promotion_type) && quotationCartValues) {
            subtotal = formatPrice(quotationCartValues.subtotal)
            benefit = formatPrice(Math.abs(quotationCartValues.benefit))
            tax = formatPrice(quotationCartValues.tax)
            total = formatPrice(quotationCartValues.subtotal + quotationCartValues.benefit)
        }
        return (
            <div className="summary">
                {!marketConfig.hide_summary_subtotal_value && (
                    <div className="summary__row">
                        <h5 className="summary__name">{t('ev_delivery_payment.subtotal')}</h5>
                        <p className="summary__value">{subtotal}</p>
                    </div>
                )}
                {!marketConfig.hide_summary_benefit_value && (
                    <div className="summary__row">
                        <h5 className="summary__name --bold">{t('ev_delivery_payment.benefit')}</h5>
                        <p
                            className="summary__value --bold">{benefit}</p>
                    </div>
                )}
                {!marketConfig.hide_summary_tax_value && (
                    <div className="summary__row">
                        <h5 className="summary__name">{t('ev_delivery_payment.tax')}</h5>
                        <p className="summary__value">{tax}</p>
                    </div>
                )}
                {!marketConfig.hide_summary_delivery_value && (
                    <div className="summary__row --mb4">
                        <h5 className="summary__name">{t('ev_delivery_payment.delivery')}</h5>
                        <p className="summary__value">{deliveryValue > 0 ? formatPrice(showNetPrice ? deliveryNetValue : deliveryValue) : t('free')}</p>
                    </div>
                )}
                {!!t('ev_delivery_payment.delivery_info') && (
                    <p className="par-4 --mb4" style={{textAlign: 'right'}}>{t('ev_delivery_payment.delivery_info')}</p>
                )}
                <div className="summary__row">
                    <h5 className="summary__name">{t('ev_delivery_payment.total')}</h5>
                    <p className="summary__value --success --bold">{total}</p>
                </div>
            </div>
        )
    }


    const withCustomAddress = !!props.customer?.withCustomAddress;

    return (
        <div className="evDP">


            <div className="evDP__info">
                <div className="grid">
                    <div style={{}}/>
                    <div className="evDP__cart">
                        {props.order ? (
                            <CartSummarySimple
                                storeName="ev"
                                cart={props.cart}
                                categories={props.categories}
                                products={props.products}
                                disableFooter
                            />
                        ) : (
                            <CartSummary
                                storeName="ev"
                                cart={props.cart}
                                categories={props.categories}
                                products={props.products}
                                disableEdit={props.loading}
                                disableFooter/>
                        )}
                    </div>
                </div>
            </div>


            <div className="evDP__info">
                <div className="grid">
                    <div>
                        <h3 className="evDP__title">{t('ev_delivery_payment.delivery_address')}</h3>
                        <div className="info">
                            {props.customer.companyAddress ? (
                                <>
                                    <InfoComponent label={t('company_name')} value={props.customer.companyName}/>
                                    <InfoComponent label={t('tax_id')} value={props.customer.taxId}/>
                                    <InfoComponent label={t('profession')} value={props.customer.profession?.label}/>
                                </>
                            ) : (
                                <InfoComponent label={t('title')} value={props.customer.title?.label}/>
                            )}
                            <div className="info__row">
                                <InfoComponent label={t('name')} value={props.customer.name}/>
                                <InfoComponent label={t('surname')} value={props.customer.surname}/>
                            </div>
                            <div className="info__row">
                                <InfoComponent label={t('postcode')} value={props.customer.postcode}/>
                                <InfoComponent label={t('city')} value={props.customer.city}/>
                            </div>
                            <InfoComponent label={t('address_line_1')} value={props.customer.address}/>
                            <InfoComponent label={t('address_line_2')} value={props.customer.address2}/>
                            <InfoComponent label={t('region')} value={props.customer.region?.label}/>
                            <InfoComponent label={t('phone')} value={props.customer.phone}/>
                            <InfoComponent label={t('phone_mobile')} value={props.customer.phoneMobile}/>
                            <InfoComponent label={t('phone_home')} value={props.customer.phoneHome}/>
                            <InfoComponent label={t('email')} value={props.customer.email}/>
                        </div>
                    </div>
                    {withCustomAddress ? (
                        <CustomAddressComponent/>
                    ) : (
                        <SummaryComponent/>
                    )}
                </div>
                {withCustomAddress && (
                    <>
                        <hr className="-light --mb4"/>
                        <div className="grid">
                            <div/>
                            <SummaryComponent/>
                        </div>
                    </>
                )}
                <div className="grid">
                    <div/>
                    <div className="evDP__summaryInfo">
                        <p
                            className="par-4">{!!props.promotion.promotion_coffee_required && t('ev_delivery_payment.total_info')}</p>
                        <p className="par-4">{t('ev_delivery_payment.summary_info')}</p>
                    </div>
                </div>
            </div>


            <div className="grid">
                <div>
                    {(props.order?.quotation_response === 'PAYMENT') && !props.user && (
                        <iframe
                            id="paymentIframe" width="100%"
                            height="600px"
                            style={{border: 'none'}}
                            src={`/payment.html?hash=${props.order.uuid}&token=${props.order.member_token}&lang=${currentLanguage}&api_url=${Env.API_URL}&order_finalized_url=${navigation(Routes.ORDER_FINALIZED, props.order.uuid)}`}
                        />
                    )}
                </div>
                <div>
                    {!props.order ? (
                        <>
                            <div className="evDP__info">
                                <h3 className="par-1">{t('choose_payment_type')}</h3>
                                <PaymentTypeForm
                                    types={props.paymentMethods}
                                    type={props.paymentMethod}
                                    onSubmit={props.onChangePaymentMethod}
                                />
                            </div>
                            <MemberCreditInfo memberCredit={props.memberCredit}/>
                            <div className="step__nav -right -bottom">
                                <RecaptchaV2 onChange={props.onChangeRecaptchaToken}/>
                            </div>
                            <div className="step__nav -right -bottom --mb4">
                                <MyLink route={stepNavigation.prev()} disabled={props.loading}>
                                    <Button
                                        type="primary-outline"
                                        size="sm"
                                        label={t('back')}
                                        disabled={props.loading}
                                    />
                                </MyLink>
                                <Button
                                    type="primary"
                                    label={props.user ? t('confirm') : t('buy')}
                                    size={isEnabledByMarket('smallFormSubmitButton') ? 'sm' : undefined}
                                    disabled={!isCartValid || !props.paymentMethod || props.loading || !props.recaptchaToken}
                                    onClick={props.onSubmit}
                                />
                            </div>
                        </>
                    ) : !!props.paymentMethod && (
                        <>
                            <div className="summary__row --mb4">
                                <div className="summary__name --bold">{t('payment_type')}</div>
                                <div
                                    className="summary__value -bold">{localizeObject(props.paymentMethod?.payment_method_title)}</div>
                            </div>
                            <div className="summary__row --mb4">
                                <div className="par-5"
                                     dangerouslySetInnerHTML={{__html: localizeObject(props.paymentMethod?.payment_method_text)}}/>
                            </div>
                        </>
                    )}

                    {!!props.error && (
                        <div className="step__nav -right">
                            <Alert type="error">
                        <span dangerouslySetInnerHTML={{__html: t(`error.${props.error}`)}}
                              style={{marginRight: '3px'}}/>
                                {Env.ENV === 'prod' ? (
                                    <button type="button" className="link -parentSize" onClick={props.onSendErrors}>
                                        <u>{t('report_error')}</u>
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            type="button"
                                            className="link -parentSize"
                                            style={{marginRight: '3px'}}
                                            onClick={props.onSendErrors}
                                        >
                                            <u>{t('report_error')}</u>
                                        </button>
                                        <a
                                            href={getErrorFile()}
                                            className="link -parentSize"
                                            download={`send_order_${new Date().toISOString()}.json`}
                                        >
                                            <u>{t('download_error')}</u>
                                        </a>
                                    </>
                                )}
                            </Alert>
                        </div>
                    )}
                    {!!props.loading && (
                        <div style={{textAlign: 'center'}}>
                            <Preloader classes="--mb3"/>
                            <p className="par-2"
                               dangerouslySetInnerHTML={{__html: t('ev_delivery_payment.loading_text')}}/>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
}


export default DeliveryPaymentStep;
