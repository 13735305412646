import {useTranslation} from "../../../misc/Hooks";
import {RegistrationResellerForm} from "../components";
import React from "react";
import {Preloader} from "../../../misc/_index";

export default function RegistrationScreen(props) {
    const t = useTranslation();

    return (
       <>
           {props.loading ? (
               <div className="resAuth">

                   <div className="resAuth__content">
                       <h1 className="par-1">{t('reseller_registration.reseller_auth.title') || t('reseller_auth.title')}</h1>
                       <h2 className="par-2 --mb3">{t('reseller_registration.register_new_reseller')}</h2>

                       <div className="--mb4">
                           <RegistrationResellerForm
                               shops={props.shops}
                               onSubmit={props.onSubmit}
                               sendingStatus={props.sendingStatus}
                               blockButton={props.blockButton}
                               shopFromToken={props.shopFromToken}
                           />
                       </div>

                       {props.sendingStatus.status && (
                           <span className={`${props.sendingStatus.status === 'error' ? '--error' : '--success'} --alignCenter --dBlock --bold`}>
                               {props.sendingStatus.message}
                           </span>
                       )}

                   </div>

                   <div className="resAuth__thumb">
                       <img
                           src={require('../../../assets/img/sn-help-machine.png').default}
                           alt={t('reseller_registration.reseller_auth.title') || t('reseller_auth.title')}
                           className="img-fluid"
                       />
                   </div>
               </div>
           ) : (
               <Preloader/>
           )}
       </>
    )
}
