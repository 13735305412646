import React, {useEffect, useState} from "react";
import {usePagination, useTable} from "react-table";
import {MyDatePicker, MySelect} from "../forms/inputs/_index";
import {SearchForm} from "../forms/_index";
import {useTranslation} from "../../misc/Hooks";
import {Preloader} from "../../misc/_index";
import {Button} from "../buttons/_index";
import {useHistory, useLocation} from "react-router-dom";
import MyMultiSelect from "../forms/inputs/MyMultiSelect";
import {useSelector} from "react-redux";


interface Props {
   title: string,
   columns: any,
   data: any,
   maxPage: number,
   getData: void,
   search: void,
   actions?: boolean,
   actionOptions?: any,
   actionVoid?: void,
   results?: any,
   noSearchForm?: boolean,
   name?: string,
}


export default (props: Props) => {
   const t = useTranslation();
   const location = useLocation();
   const history = useHistory();
   const urlSearchParams = new URLSearchParams(location.search)
   const paramsName = props.name ?? 'page'
   const store = useSelector(({main, ev}) => ({
      user: main.user,
   }))

   const [currentPage, setCurrentPage] = useState(urlSearchParams.get(paramsName+'Current') ?? 1);
   const [pageSize, setPageSizeApi] = useState(urlSearchParams.get(paramsName+'Limit') ?? 10);
   const [searchText, setSearchText] = useState('');
   const [actionValue, setActionValue] = useState('')
   const [filtersSetting, setFiltersSettings] = useState({
      date_from: null,
      date_to: null,
      shops: [],
      resellers: [],
      disableShops: false,
      disableResellers: false,
   })

   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      pageCount,
      setPageSize
   } = useTable({columns: props.columns, data: props.data, initialState: {pageSize: 5}}, usePagination);

   useEffect(() => {
      setPageSize(pageSize)
      props.getData(currentPage, pageSize, null, filtersSetting)
      urlSearchParams.set(paramsName+'Current', currentPage)
      urlSearchParams.set(paramsName+'Limit', pageSize)
      history.push(location.pathname+ '?' + urlSearchParams.toString())
   }, [currentPage, pageSize])

   useEffect(()=> {
      props.getData(currentPage, pageSize, null, filtersSetting)
   }, [filtersSetting.resellers, filtersSetting.date_from, filtersSetting.date_to, filtersSetting.shops])

   useEffect(() => {
      if ((currentPage > props.maxPage) && !(props.loading) ) {
         setCurrentPage(1)
         urlSearchParams.set(paramsName+'Current', 1)
      }
   }, [props.maxPage])

   const onBlurShop = () => {
      if(filtersSetting.shops.length > 0){
        setFiltersSettings((old) => ({
           ...old,
           resellers: [],
           disableResellers: true,
        }))
      } else {
         setFiltersSettings((old) => ({
            ...old,
            disableResellers: false,
         }))
      }
   }
   const onBlurReseller = () => {
      if(filtersSetting.resellers.length > 0){
         setFiltersSettings((old) => ({
            ...old,
            shops: [],
            disableShops: true,
         }))
      } else {
         setFiltersSettings((old) => ({
            ...old,
            disableShops: false,
         }))
      }
   }

   const setLimit = (value) => {
      setPageSizeApi(value)
      urlSearchParams.set(paramsName+'Limit', value)
   }

   const resellersFilterOptions = props.ordersFiltersOptions.resellers.map((reseller) => ({
      label: `${reseller.reseller_firstname} ${reseller.reseller_lastname} ${reseller.shop_title ? "(" + reseller.shop_title + ")" : ""}`,
      value: reseller.id_reseller
   }))

   const shopFilterOptions = props.ordersFiltersOptions.shops.map((shop) => ({
      label: shop.shop_title,
      value: shop.id_shop
   }))

   const clearFilters = () => {
      setFiltersSettings({
         date_from: null,
         date_to: null,
         shops: [],
         resellers: [],
         disableShops: false,
         disableResellers: false,
      })
   }



   return (
      <>
         <div className="boxTable">
            <div className="boxTable__header">
               <h2 className="par-1">{props.title}</h2>
            </div>
            <div className="boxTable__overflow">
               <div className={`boxTable__bar`}>
                  <div className="boxTable__barLeft">
                     <div style={{width: '125px', minWidth: '90px'}}>
                        <MySelect
                           value={{label: pageSize, value: pageSize}}
                           label={t('table.results')}
                           options={[
                              {label: '5', value: 5},
                              {label: '10', value: 10},
                              {label: '20', value: 20},
                              {label: '50', value: 50},
                              {label: '100', value: 100},
                           ]}
                           onChange={v => setLimit(v?.value)}
                           raw
                        />
                     </div>
                     {props.filters && (
                           <>
                              <div style={{width: '210px', minWidth: '210px', marginBottom: '.5rem'}}>
                                 <MyDatePicker
                                    name="date_from"
                                    label={t('assisted_registration.filters.date_from')}
                                    value={filtersSetting.date_from}
                                    max={new Date()}
                                    loading={false}
                                    onChange={v => {
                                       setFiltersSettings((old) => ({
                                          ...old,
                                          date_from: v
                                       }))
                                    }}
                                 />
                              </div>
                              <div style={{width: '210px', minWidth: '210px', marginBottom: '.5rem'}}>
                                 <MyDatePicker
                                    name="date_to"
                                    label={t('assisted_registration.filters.date_to')}
                                    value={filtersSetting.date_to}
                                    max={new Date()}
                                    loading={false}
                                    onChange={v => {
                                       setFiltersSettings((old) => ({
                                          ...old,
                                          date_to: v
                                       }))
                                    }}
                                 />
                              </div>
                              {store.user.reseller_role === 'ka_super_user' && (
                                 <div style={{width: '210px', minWidth: '210px'}}>
                                    <MyMultiSelect
                                       value={filtersSetting.shops}
                                       label={t('assisted_registration.filters.shop')}
                                       options={shopFilterOptions}
                                       onChange={v => setFiltersSettings((old) => ({
                                          ...old,
                                          shops: v
                                       }))}
                                       loading={props.ordersFiltersOptions.loading}
                                       raw
                                       onBlur={onBlurShop}
                                       selectedColor
                                       noBottomOptions
                                       isSearchable
                                       disabled={filtersSetting.disableShops}
                                    />
                                 </div>
                              )}
                              {store.user.reseller_role !== 'pos_user' && (
                                 <div style={{width: '210px', minWidth: '210px'}}>
                                    <MyMultiSelect
                                       value={filtersSetting.resellers}
                                       label={t('assisted_registration.filters.reseller')}
                                       options={resellersFilterOptions}
                                       onChange={v => setFiltersSettings((old) => ({
                                          ...old,
                                          resellers: v
                                       }))}
                                       loading={props.ordersFiltersOptions.loading}
                                       raw
                                       onBlur={onBlurReseller}
                                       selectedColor
                                       noBottomOptions
                                       isSearchable
                                       disabled={filtersSetting.disableResellers}
                                    />
                                 </div>
                              )}
                              <Button label={t('clear_filters')} onClick={clearFilters}/>
                           </>
                     )}
                  </div>


                  {!props.noSearchForm && (
                     <div className="boxTable__barRight">
                        <div style={{width: '300px', minWidth: '150px'}}>
                           <SearchForm onSubmit={v => props.getData(currentPage, pageSize, v)}/>
                        </div>
                     </div>
                  )}
               </div>

               <div className="boxTable__table">
                  <table {...getTableProps()}>
                     <thead>
                     {headerGroups.map(headerGroup => (
                        <tr {...headerGroup}>
                           {headerGroup.headers.map(column => {
                              if(typeof column.render('Header') === 'object'){
                                 return (
                                    <th>{column.render('Header')}</th>
                                 )
                              } else {
                                 return (
                                    <th {...column.getHeaderProps()}
                                        dangerouslySetInnerHTML={{__html: column.render('Header')}}/>
                                 )
                              }
                           })}
                        </tr>
                     ))}
                     </thead>
                     <tbody {...getTableBodyProps()}>
                     {page.map(row => {
                        prepareRow(row)
                        return (
                           <tr {...row.getRowProps()}>
                              {row.cells.map(cell => (
                                 <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                 </td>
                              ))}
                           </tr>
                        )
                     })}
                     {props.loading ? (
                        <tr>
                           <td colSpan="100" className="-noResults">
                              <Preloader classes="--mb0"/>
                           </td>
                        </tr>
                     ) : !page.length && (
                        <tr>
                           <td colSpan="100" className="-noResults">{t('table.no_results')}</td>
                        </tr>
                     )}
                     </tbody>
                  </table>
               </div>
            </div>
            <div className="boxTable__footer">
               <span>{t('table.page_status', currentPage, pageSize, props.results)}</span>

               <div style={{display: 'flex', alignItems: 'center'}}>

                  {currentPage > 3 && (
                     <button onClick={() => setCurrentPage(1)}>
                        <img style={{height: '12px'}}
                             src={require('../../assets/img/icons/arrow-left-double.svg').default} alt=""/>
                     </button>
                  )}

                  <button type="button"
                          onClick={() => currentPage > 1 ? setCurrentPage(currentPage - 1) : undefined}>
                     <img style={{height: '12px'}} src={require('../../assets/img/icons/arrow-left.svg').default}
                          alt=""/>
                  </button>
                  <div className="boxTable__pagination">
                     {new Array(props.maxPage).fill().map((_, idx) => idx + 1)
                        .map(i => !!(i >= currentPage - 2 && i <= currentPage + 2) && (
                           <button key={i} style={{fontWeight: i === Number(currentPage) ? '700' : '400'}}
                                   onClick={() => setCurrentPage(i)}>{i}</button>
                        ))}
                  </div>
                  <button type="button"
                          onClick={() => currentPage + 1 <= props.maxPage ? setCurrentPage(currentPage + 1) : undefined}>
                     <img
                        style={{height: '12px'}}
                        src={require('../../assets/img/icons/arrow-right.svg').default}
                        alt=""
                     />
                  </button>
                  {currentPage < props.maxPage - 2 && (
                     <button onClick={() => setCurrentPage(props.maxPage)}>
                        <img style={{height: '12px'}}
                             src={require('../../assets/img/icons/arrow-right-double.svg').default} alt=""/>
                     </button>
                  )}
               </div>
            </div>
         </div>
         {props.actions && (
            <>
               <div className="actionBar">
                  {props.selected.length > 0 && (
                     <>
                        <span className="actionBar__label">{t('for_selected')} ({props.selected.length}):</span>
                        <MySelect
                           name="action"
                           label={t('action')}
                           value={actionValue}
                           options={props.actionOptions}
                           onChange={(val) => setActionValue(val)}
                           noMarginBottom
                        />
                        <Button label={t('take_the_action')} onClick={() => props.actionVoid(actionValue.value)}/>
                     </>
                  )}
               </div>
            </>
         )}
      </>
   );
}
