
const SET_UUID = 'rms/setUuid';
const SET_RECAPTCHA_TOKEN = 'rms/setRecaptchaToken';

const SET_MACHINE = 'rms/setMachine';
const SET_PROOF_OF_PURCHASE = 'rms/setProofOfPurchase';
const SET_PROOF_OF_PURCHASE_LOGS = 'rms/setProofOfPurchaseLogs';
const SET_NESPRESSO_MEMBER = 'rms/setNespressoMember';
const SET_NESPRESSO_MEMBER_EXIST = 'rms/setNespressoMemberExist';
const SET_PROMOTION = 'rms/setPromotion';
const SET_CUSTOMER = 'rms/setCustomer';

const SET_PRODUCTS = 'rms/setProducts';
const SET_CATEGORIES = 'rms/setCategories';
const UPDATE_CART = 'rms/updateCart';
const SET_PLANS = 'rms/setPlans';

const SET_PAID = 'rms/setPaid';
const SET_OCR_LOGS = 'rms/setOcrLogs';
const SET_ORDER = 'rms/setOrder';

const SET_PAYMENT_METHOD = 'rms/setPaymentMethod';
const SET_BANK = 'rms/setBank';
const SET_BANKS = 'rms/setBanks';
const SET_VERIFICATION_CODE_TOKEN = 'rms/setVerificationCodeToken';
const SET_CAN_REGISTER_RESPONSE = 'ev/setCanRegisterResponse';
const SET_LAST_VISIT = 'ev/setLastVisit';

const CLEAR_STORE = 'rms/clearStore';


const initialState = {
    uuid: '',

    testOrder: false,
    serialNumber: null,

    machine: null,
    proofOfPurchase: null,
    proofOfPurchaseLogs: null,
    proofOfPurchaseHistory: null,
    nespressoMember: undefined,
    nespressoMemberExist: false,
    promotion: null,
    customer: null,

    categories: [],
    products: [],

    cart: {
        items: [],
        sum: '',
    },

    paymentMethod: null,
    order: null,
    paid: false,

    recaptchaToken: null,

    ocrLogs: [],
    bank: null,
    banks: [],
    verificationCodeToken: '',

    canRegisterResponse: null,

    lastVisit: null,

};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_UUID: return {...state, uuid: action.uuid}
        case SET_RECAPTCHA_TOKEN: return {...state, recaptchaToken: action.recaptchaToken}

        case SET_MACHINE: {
            const res = {
                ...state,
                machine: action.machine,
            }
            if(action.testOrder) {
                res.testOrder = action.testOrder;
            }
            if(action.serialNumber) {
                res.serialNumber = action.serialNumber;
            }
            return res;
        }
        case SET_PROOF_OF_PURCHASE: return {...state, proofOfPurchase: action.proofOfPurchase}
        case SET_PROOF_OF_PURCHASE_LOGS: return {...state, proofOfPurchaseLogs: action.proofOfPurchaseLogs}
        case SET_NESPRESSO_MEMBER: return {...state, nespressoMember: action.nespressoMember}
        case SET_NESPRESSO_MEMBER_EXIST: return {...state, nespressoMemberExist: action.nespressoMemberExist}
        case SET_PROMOTION:
            return {
                ...state,
                promotion: action.promotion,
                products: initialState.products,
                categories: initialState.categories,
                cart: initialState.cart,
            }
        case SET_CUSTOMER: return {...state, customer: action.customer}

        case SET_PRODUCTS: return {...state, products: action.products}
        case SET_CATEGORIES: return {...state, categories: action.categories}
        case UPDATE_CART: return {...state, cart: action.cart}
        case SET_PLANS: return {...state, plans: action.plans}

        case SET_PAYMENT_METHOD: return {...state, paymentMethod: action.paymentMethod}
        case SET_PAID: return {...state, paid: action.paid}
        case SET_OCR_LOGS: return {...state, ocrLogs: action.ocrLogs}
        case SET_ORDER: return {...state, order: action.order}
        case SET_BANK: return {...state, bank: action.bank}
        case SET_BANKS: return {...state, banks: action.banks}
        case SET_VERIFICATION_CODE_TOKEN: return {...state, verificationCodeToken: action.verificationCodeToken}
        case SET_CAN_REGISTER_RESPONSE: return {...state, canRegisterResponse: action.canRegisterResponse}
        case SET_LAST_VISIT: return {...state, lastVisit: action.lastVisit}

        case CLEAR_STORE: return {...initialState};

        default: return state;

    }
}


export const setUuid = (uuid) => ({type: SET_UUID, uuid})
export const setRecaptchaToken = (recaptchaToken) => ({type: SET_RECAPTCHA_TOKEN, recaptchaToken})

export const setMachine = (machine, testOrder = null, serialNumber = null) => ({
    type: SET_MACHINE,
    machine,
    testOrder,
    serialNumber
})
export const setNespressoMember = (nespressoMember) => ({type: SET_NESPRESSO_MEMBER, nespressoMember})
export const setNespressoMemberExist = (nespressoMemberExist) => ({type: SET_NESPRESSO_MEMBER_EXIST, nespressoMemberExist})
export const setProofOfPurchase = (proofOfPurchase) => ({type: SET_PROOF_OF_PURCHASE, proofOfPurchase})
export const setProofOfPurchaseLogs = (proofOfPurchaseLogs) => ({type: SET_PROOF_OF_PURCHASE_LOGS, proofOfPurchaseLogs})
export const setPromotion = (promotion) => ({type: SET_PROMOTION, promotion})
export const setCustomer = (customer) => ({type: SET_CUSTOMER, customer})

export const setProducts = (products) => ({type: SET_PRODUCTS, products})
export const setCategories = (categories) => ({type: SET_CATEGORIES, categories})
export const updateCart = (cart) => ({type: UPDATE_CART, cart})
export const setPlans = (plans) => ({type: SET_PLANS, plans})

export const setPaymentMethod = (paymentMethod) => ({type: SET_PAYMENT_METHOD, paymentMethod})

export const setPaid = (paid) => ({type: SET_PAID, paid})
export const setOrder = (order) => ({type: SET_ORDER, order})
export const setOcrLogs = (ocrLogs) => ({type: SET_OCR_LOGS, ocrLogs})
export const setBank = (bank) => ({type: SET_BANK, bank})
export const setBanks = (banks) => ({type: SET_BANKS, banks})
export const setVerificationCodeToken = (verificationCodeToken) => ({type: SET_VERIFICATION_CODE_TOKEN, verificationCodeToken})
export const setCanRegisterResponse = (canRegisterResponse) => ({type: SET_CAN_REGISTER_RESPONSE, canRegisterResponse})
export const setLastVisit = (lastVisit) => ({type: SET_LAST_VISIT, lastVisit})

export const clearStore = () => ({type: CLEAR_STORE})



export default reducer;
