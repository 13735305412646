import Provider from "./Provider";
import {Redirect, Route, Switch, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {useSteps} from "../../hooks/modules/etrade";
import React, {useEffect, useState} from "react";
import {useNavigation, useTranslation} from "../../misc/Hooks";
import {Banner, ShopLogo, Step} from "./components";
import {Footer, Header} from "../../components/misc/_index";
import {Config, Routes} from "../../utils/_index";
import {useDispatch, useSelector} from "react-redux";
import * as eTradeReducer from "../../redux/eTradeReducer";
import InvalidTokenScreen from "./screens/InvalidTokenScreen";
import TokenExpiredScreen from "./screens/TokenExpiredScreen";
import {OrderFinalizedContainer} from "./containers";
import {GetInTouchModal} from "../../components/modals/_index";
import {useIdleTimer} from "react-idle-timer";


export default function Funnel(props) {
    const navigation = useNavigation()
    const t = useTranslation()
    const match = useRouteMatch()
    const params = useParams()
    const steps = useSteps()
    const dispatch = useDispatch();
    const store = useSelector(({eTrade}) => ({
        data: eTrade.data,
        order: eTrade.order,
        orderFinalizedData: eTrade.orderFinalizedData,
        lastVisit: eTrade.lastVisit,
    }))
    const [step, setStep] = useState(0)
    const [gitModalVisible, setGitModalVisible] = useState(false);

    const {getLastActiveTime} = useIdleTimer({
        timeout: Config.ETRADE_SESSION_TIME,
        onIdle: e => dispatch(eTradeReducer.clearStore()),
        debounce: 500,
    })

    useEffect(() => {
        if (store.lastVisit) {
            if ((store.lastVisit + Config.ETRADE_SESSION_TIME) <= Date.now()) {
                dispatch(eTradeReducer.clearStore());
            }
        }

        window.addEventListener("beforeunload", function (e) {
            dispatch(eTradeReducer.setLastVisit(getLastActiveTime()))
        });
    }, [])

    const shopLayoutRoutes = steps.map(s => navigation(s.route))

    return (
        <Provider>
            <div className="app">
                <Route render={({location}) => (
                    shopLayoutRoutes.includes(location.pathname) ? (
                        <Header
                            storeName="eTrade"
                            homeRoute={navigation(Routes.ETRADE)}
                            secondLogo={() => (
                                <ShopLogo
                                    gallery={store.data?.etrade_gallery}
                                    url={store.data?.etrade_shop_url}
                                />
                            )}
                            summaryRoute={Routes.ETRADE_SUMMARY}
                            disableEdit
                            alertsDisabled
                        />
                    ) : location.pathname === navigation(Routes.ETRADE_ORDER_FINALIZED, params.uuid) ? (
                        <Header
                            storeName="eTrade"
                            homeRoute={navigation(Routes.ETRADE_ORDER_FINALIZED, params.uuid)}
                            secondLogo={() => (
                                <ShopLogo
                                    gallery={store.orderFinalizedData?.order.etrade.etrade_gallery}
                                    url={store.orderFinalizedData?.order.etrade.etrade_shop_url}
                                />
                            )}
                            cartDisabled
                            alertsDisabled
                        />
                    ) : (
                        <Header
                            storeName="eTrade"
                            homeRoute={navigation(Routes.SHOP)}
                            cartDisabled
                            alertsDisabled
                        />
                    )
                )}/>


                <div className="main">

                    <div className="wrapper-bg formContainer --pt0">
                        <Route render={({location}) => (
                            shopLayoutRoutes.includes(location.pathname) ? (
                                <div className="wrapper -xxl --mb4">
                                    <Banner gallery={store.data?.etrade_gallery}/>
                                </div>
                            ) : location.pathname === navigation(Routes.ETRADE_ORDER_FINALIZED, params.uuid) ? (
                                <div className="wrapper -xxl --mb4">
                                    <Banner gallery={store.orderFinalizedData?.order.etrade.etrade_gallery}/>
                                </div>
                            ) : <div className="--pt4"/>
                        )}/>

                        <div className="steps">
                            <Switch>

                                <Route exact path={[...steps.map(s => s.route)]}>
                                    {steps.map((s, idx) => (
                                        <Step
                                            key={idx}
                                            index={idx}
                                            currentStep={step}
                                            route={s.route}
                                            title={t(s.title)}
                                            steps={steps}
                                            setCurrentStep={() => setStep(idx)}
                                        >
                                            {s.component}
                                        </Step>
                                    ))}
                                </Route>


                                <Route exact path={Routes.ETRADE_INVALID_TOKEN} component={InvalidTokenScreen}/>
                                <Route exact path={Routes.ETRADE_TOKEN_EXPIRED} component={TokenExpiredScreen}/>
                                <Route exact path={Routes.ETRADE_ORDER_FINALIZED} component={OrderFinalizedContainer}/>

                                <Route path={Routes.ETRADE}>
                                    <Redirect to={navigation(Routes.ETRADE_MACHINE_PLAN)}/>
                                </Route>

                            </Switch>
                        </div>

                        {shopLayoutRoutes.includes(navigation(match.path)) && (
                            <div className="wrapper">
                                <div className="steps__footer par-4">
                                    <b>{t('need_help')} </b>
                                    <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                                        <b>{t('get_in_touch')}</b>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <Footer module="etrade"/>
            </div>


            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />
        </Provider>
    )
}
