import Axios from "../utils/Axios";


export const getAll = () => {
    return Axios.get(`/machine`);
}


export const validateSerialNumber = (formData) => {
    return Axios.post(`/validate-serial-number`, formData);
}


export const validateSerialNumberTps = (formData) => {
    return Axios.post(`/validate-serial-number-tps`, formData);
}


export const getSerialNumberFromImage = (data) => {
    return Axios.post(`/validate-machine`, data);
}
