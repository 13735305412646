import {useTranslation} from "../../../misc/Hooks";
import SetNewPasswordResellerForm from "../../forms/reseller/SetNewPasswordResellerForm";
import {Preloader} from "../../../misc/_index";


export default function SetNewPasswordReseller(props) {
    const t = useTranslation();


    if(props.checkingToken)
        return <Preloader/>

    return (
        <div>

            <div className="--mb4">
                <h1 className="par-1">{t('set_new_password_reseller.title')}</h1>
                <p className="par-2" dangerouslySetInnerHTML={{__html: t('set_new_password_reseller.text')}}/>
            </div>

            <div className="--mb4">
                <SetNewPasswordResellerForm
                    loading={props.processing}
                    error={props.error}
                    onSubmit={props.onSubmit}
                />
            </div>

            <div className="--mb4">
                <p className="par-4" dangerouslySetInnerHTML={{__html: t('set_new_password_reseller.bottom_text')}}/>
            </div>

        </div>
    )
}
