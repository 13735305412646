import {Controller, useForm} from "react-hook-form";
import {MyInput} from "../inputs/_index";
import {useTranslation} from "../../../misc/Hooks";
import {Button} from "../../buttons/_index";
import {useFormRules} from "../../../hooks";
import Patterns from "../../../utils/Patterns";
import {Preloader} from "../../../misc/_index";
import {useEffect} from "react";


export default function TemporaryPasswordResellerForm(props) {
    const t = useTranslation();
    const formRules = useFormRules();
    const {control, errors, watch, setError, clearErrors, handleSubmit} = useForm({
        mode: 'onChange',
    })


    useEffect(() => {
        if(props.error) {
            setError('password', {type: 'invalid', message: t(`validation.${props.error}`)})
            setError('rePassword', {type: 'invalid', message: t(`validation.${props.error}`)})
        } else {
            clearErrors(['password', 'rePassword'])
        }
    },[props.error])


    const rules = {
        password: formRules.make({
            required: true,
            pattern: Patterns.password,
            patternMessage: t('validation.password') || t('validation.invalid'),
        }),
        rePassword: formRules.make({
            required: true,
            sameAs: watch('password'),
            sameAsMessage: t('validation.same_as', t('password'))
        }),
    }

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>



                    <Controller
                        control={control}
                        name="password"
                        defaultValue=""
                        rules={rules.password}
                        render={({value, onChange, onBlur}) => (
                            <MyInput
                                type="password"
                                value={value}
                                label={t('password')}
                                error={errors.password}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="rePassword"
                        defaultValue=""
                        rules={rules.rePassword}
                        render={({value, onChange, onBlur}) => (
                            <MyInput
                                type="password"
                                value={value}
                                label={t('re_password')}
                                error={errors.rePassword}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />

            <Button
                btnType="submit"
                label={t('continue')}
                disabled={props.loading}
            />

        </form>
    )
}
