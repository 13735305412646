import {useEffect} from "react";
import {useDispatch} from "react-redux";
import Axios from "../utils/Axios";
import * as machineActions from "../actions/MachineActions";
import * as tpsReducer from "../redux/tpsReducer";
import * as MainActions from "../actions/MainActions";
import {useLanguage} from "../hooks";


let MOUNTED = false;

const TpsConfig = (props) => {
    const dispatch = useDispatch();
    const currentLanguage = useLanguage();


    useEffect(() => {
        MOUNTED = true;

        fetchMachines();
        getStacks();

        Axios.defaults.headers.common['Language'] = currentLanguage;

        return () => {
            MOUNTED = false;
        }
    }, [])


    const fetchMachines = () => {
        machineActions.getAll()
            .then(r => {
                const res = r.data;
                if(res.status === 'success') {
                    dispatch(tpsReducer.setMachines(res.data))
                }
            })
            .catch(err => {
                console.error('Cannot fetch machines', err)
            })
    }


    const getStacks = async () => {
        const machineModels = [];
        const machineColors = [];
        const machinePartners = [];
        const companyTitles = [];

        await Promise.all([
            MainActions.getStackByType('machine_model').then(r => {
                const res = r.data;
                if(res.status === 'success') {
                    machineModels.push(...res.data);
                }
                else {
                    console.log('Something gone wrong! ', res);
                }
            }),
            MainActions.getStackByType('machine_color').then(r => {
                const res = r.data;
                if(res.status === 'success') {
                    machineColors.push(...res.data);
                }
                else {
                    console.log('Something gone wrong! ', res);
                }
            }),
            MainActions.getStackByType('machine_partner').then(r => {
                const res = r.data;
                if(res.status === 'success') {
                    machinePartners.push(...res.data);
                }
                else {
                    console.log('Something gone wrong! ', res);
                }
            }),
            MainActions.getStackByType('tps_company_title').then(r => {
                const res = r.data;
                if(res.status === 'success') {
                    companyTitles.push(...res.data);
                }
                else {
                    console.log('Something gone wrong! ', res);
                }
            }),
        ])

        if(MOUNTED) {
            dispatch(tpsReducer.setStacks(machineModels, machineColors, machinePartners, companyTitles));
        }
    }



    return props.children;
}


export default TpsConfig;
