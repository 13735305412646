import {useTranslation} from "../../../misc/Hooks";
import {CustomerInformationForm, SignInForm} from "../../../components/forms/_index";
import {Preloader} from "../../../misc/_index";
import {useStepsNavigation} from "../../../hooks/modules/order_finish";

interface Props {
    promotion: any,
    addresses: [],
    customer: any,
    onSubmit: void,
}

export default function CustomerScreen(props: Props) {
    const t = useTranslation();
    const stepsNavigation = useStepsNavigation()

    return (
        <div className="evCIN">

            <p className="par-1 --bold">{t('ev_customer_information.your_details')}</p>

            <div className="--mb4">
                <CustomerInformationForm
                    stepNavigation={stepsNavigation}
                    user={props.user}
                    promotion={props.promotion}
                    plan={props.plan}
                    country={props.country}
                    nespressoMember={props.nespressoMember}
                    addresses={props.addresses}
                    loadingAddresses={props.loadingAddresses}
                    loading={props.loading}
                    customer={props.customer}
                    onSubmit={props.onSubmit}
                />
            </div>

            {!!props.loading && (
                <div className="grid">
                    <Preloader/>
                    <div/>
                </div>
            )}

            {!!props.error && !props.nespressoMemberExist && (
                <div className="grid --mb4">
                    {props.error === 'email_in_use' ? (
                        <div>
                            <p className="par-2 --mb3 --error">{t(`error.${props.error}`)}</p>
                            <SignInForm onSubmit={props.onSubmitSignIn} error={props.signInError}/>
                        </div>
                    ) : (
                        <div>
                            <p className="par-2 --error">unknown error</p>
                        </div>
                    )}
                    <div/>
                </div>
            )}


        </div>
    );
}