import {useMarketConfig} from "../misc/Hooks";
import {linkAccount} from "../services/OrderService";
import {useMemo} from "react";


export default function useLinkAccount() {
    const marketConfig = useMarketConfig()
    const seconds = useMemo(() => marketConfig?.api_linking_error_retry_seconds, [marketConfig])
    const config = useMemo(() => ({
        timeout: !isNaN(seconds) && seconds ? seconds * 1000 : 20000,
        attempts: marketConfig?.api_linking_error_retry ?? 10,
    }), [marketConfig])

    return async (data, memberNumber) => linkAccount(data, memberNumber, config)
}
