import PlanSelection from "../screens/PlanSelection";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useTranslation} from "../../../misc/Hooks";
import * as stayHomeReducer from "../../../redux/stayHomeReducer";
import {useShActions} from "../../../actions/_index";


let MOUNTED = false

export default function PlanSelectionController(props) {
    const t = useTranslation()
    const dispatch = useDispatch()
    const store = useSelector(({stayHome}) => ({
        token: stayHome.token,
        userHasPlan: stayHome.userHasPlan,
        products: stayHome.products,
        categories: stayHome.categories,
        cart: stayHome.cart,
    }))
    const actions = useShActions()
    const [loading, setLoading] = useState(false);
    const plan = useSelector(({stayHome}) => {
        return stayHome.products?.find(p => {
            const item = stayHome.cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })

    useEffect(() => {
        MOUNTED = true
        document.title = t('stay_home.plan_selection.title')

        getPlans()

        return () => {
            MOUNTED = false
        }
    }, [])

    const getPlans = () => {
        setLoading(true);
        actions.plans(store.token).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                if(MOUNTED) {
                    dispatch(stayHomeReducer.setProducts(res.data.products))
                    dispatch(stayHomeReducer.setCategories(res.data.categories))
                }
            }
            else {
                console.log('Something gone wrong! ', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if(MOUNTED) {
                setLoading(false);
            }
        })
    }

    const handleChangeUserHasPlan = (value) => {
        dispatch(stayHomeReducer.setUserHasPlan(value))
        if(value) {
            dispatch(stayHomeReducer.clearCart())
        }
    }

    return (
        <PlanSelection
            plans={store.products}
            cart={store.cart}
            userHasPlan={store.userHasPlan}
            plan={plan}
            loading={loading}
            onChangeUserHasPlan={handleChangeUserHasPlan}
        />
    )
}
