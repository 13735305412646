import Provider from "./Provider"
import {Redirect, Route, Switch, useHistory, useParams} from "react-router-dom"
import React, {useEffect, useState} from "react"
import {useNavigation, useTranslation} from "../../misc/Hooks"
import {Footer, Header} from "../../components/misc/_index"
import {Routes} from "../../utils/_index"
import {useSteps} from "../../hooks/modules/order_finish"
import {Step} from "./components"
import InvalidTokenScreen from "./screens/InvalidTokenScreen"
import TokenExpiredScreen from "./screens/TokenExpiredScreen"
import {OrderFinalizedContainer} from "./containers"


export default function Funnel(props) {
    const t = useTranslation()
    const navigation = useNavigation()
    const params = useParams()
    const steps = useSteps()
    const [step, setStep] = useState(0)


    return (
        <Provider>
            <div className="app">
                <Switch>
                    <Route exact path={[...steps.map(s => s.route)]}>
                        <Header
                            homeRoute={navigation(Routes.ORDER_FINISH, params.uuid)}
                            storeName="of"
                            summaryRoute={Routes.ORDER_FINISH_SUMMARY}
                            disableEdit
                            alertsDisabled
                        />
                    </Route>

                    <Route>
                        <Header
                            homeRoute={navigation(Routes.ORDER_FINISH, params.uuid)}
                            cartDisabled
                        />
                    </Route>
                </Switch>

                <div className="main">
                    <div className="wrapper-bg formContainer">

                        <div className="wrapper">
                            <Switch>

                                <Route exact path={[...steps.map(s => s.route)]}>
                                    {steps.map((s, idx) => (
                                        <Step
                                            key={idx}
                                            index={idx}
                                            currentStep={step}
                                            route={s.route}
                                            title={t(s.title)}
                                            steps={steps}
                                            setCurrentStep={() => setStep(idx)}
                                        >
                                            {s.component}
                                        </Step>
                                    ))}
                                </Route>

                                <Route exact path={Routes.ORDER_FINISH_INVALID_TOKEN} component={InvalidTokenScreen}/>
                                <Route exact path={Routes.ORDER_FINISH_TOKEN_EXPIRED} component={TokenExpiredScreen}/>
                                <Route exact path={Routes.ORDER_FINISH_ORDER_FINALIZED}
                                       component={OrderFinalizedContainer}/>

                                <Route path={[Routes.ORDER_FINISH, Routes.FINISH_ORDER]}>
                                    <Redirect to={navigation(steps[0]?.route)}/>
                                </Route>

                            </Switch>
                        </div>

                    </div>
                </div>


                <Footer/>
            </div>
        </Provider>
    )
}
