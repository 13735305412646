import React, {useEffect, useState} from 'react';
import MachineSubscription from "../../components/reseller/MachineSubscription";
import {Config} from "../../utils/_index";
import {useResellerActions} from "../../actions/useResellerActions";


let MOUNTED = false;

export default function MachineSubscriptionContainer(props) {
    const resellerActions = useResellerActions()

    const [dataProcessingState, setDataProcessingState] = useState({
        data: [],
        maxPage: 1,
        results: 0,
        loading: true,
    })
    const [dataTreatmentState, setDataTreatmentState] = useState({
        data: [],
        maxPage: 1,
        results: 0,
        loading: true,
    })

    useEffect(() => {
        MOUNTED = true;
        return () => {
            MOUNTED = false;
        }
    }, [])


    const getOrders = async (type, page, pageSize, searchData = null) => {
        const params = `limit=${pageSize}&page=${page}`;
        const payload = {
            sort: null,
            slug: searchData?.slug,
            order_type: ['subscription_machine'],
            date_from: null,
            date_to: null,
            id_shop: [],
            status_type: type,
        }

        switch (type) {
            case Config.SUBSCRIPTION_STATUS.PENDING:
                setDataProcessingState((old) => ({
                    ...old,
                    loading: true,
                }))
                break;
            case Config.SUBSCRIPTION_STATUS.PROCESSED:
                setDataTreatmentState((old) => ({
                    ...old,
                    loading: true,
                }))
                break;
        }

        await resellerActions.getOrders(payload, params)
            .then(r => {
                const res = r.data
            if (res.status === 'success') {
               switch (type) {
                  case Config.SUBSCRIPTION_STATUS.PENDING:
                     setDataProcessingState({
                        data: res.data.data,
                        maxPage: res.data.last_page,
                        results: res.data.total,
                        loading: false,
                     })
                     break;
                  case Config.SUBSCRIPTION_STATUS.PROCESSED:
                     setDataTreatmentState({
                        data: res.data.data,
                        maxPage: res.data.last_page,
                        results: res.data.total,
                        loading: false,
                     })
                     break;
               }

                } else {

                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    const handleResendOrder = (id) => {
        resellerActions.resendOrder(id)
            .then(() => {
            })
            .catch(err => console.error(err))
    }

    const handleProcessOrder = (id) => {
        resellerActions.processOrder(id)
            .then(() => {
            })
            .catch(err => console.error(err))
    }


    return (
        <div className="wrapper -xxl">
            <MachineSubscription
                processingOrders={dataProcessingState.data}
                processingOrdersMaxPage={dataProcessingState.maxPage}
                processingOrdersResults={dataProcessingState.results}
                processingOrdersLoading={dataProcessingState.loading}

                inTreatmentOrders={dataTreatmentState.data}
                inTreatmentOrdersMaxPage={dataTreatmentState.maxPage}
                inTreatmentOrdersResults={dataTreatmentState.results}
                inTreatmentOrdersLoading={dataTreatmentState.loading}

                getData={getOrders}
                onResendOrder={handleResendOrder}
                onProcessOrder={handleProcessOrder}
            />
        </div>
    )
}
