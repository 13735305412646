import Axios from "../utils/Axios";


export const get = (id_country) => {
    return Axios.get(`/country/${id_country}`);
}


export const getAll = () => {
    return Axios.get(`/countries`);
}


export const getByCode = (code) => {
    return Axios.get(`/countries/code/${code}`);
}


export const getByDomain = () => {
    return Axios.get(`/countries/by-domain`);
}
