import {Config} from "../utils/_index";
import Env from "../utils/Env";
import * as OrderActions from "../actions/OrderActions";
import * as promotionActions from "../actions/PromotionActions";
import * as reducer from "../redux/ofReducer";


export const getBase64FromFile = (file, accept) => new Promise((resolve, reject) => {
    if (file && accept.includes(file.type)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }
})


export const changeCartItem = (categories, cart, products, id, value, machine = null, machinePrice = null) => {
    const product = products.find(p => p.id_product === id);
    if (product) {
        const items = [...(cart?.items ?? [])];
        const item = items.find(i => i.id === id);
        if (item) {
            const idx = items.indexOf(item);
            if (value > 0) {
                if (value <= product.product_max_qty || value < item.value) {
                    item.value = value;
                    items[idx] = item;
                }
            } else {
                items.splice(idx, 1);
            }
        } else {
            items.push({
                id,
                value,
            })
        }

        const sum = JSON.stringify(items);
        if (sum !== cart?.sum) {
            return checkAutoAdd(categories, products, {
                items,
                sum,
            }, machine, machinePrice)
        }
    }

    return checkAutoAdd(categories, products, cart, machine, machinePrice);
}


export const checkAutoAdd = (categories: [], products, cart, machine = null, machinePrice = null) => {
    if (categories.length) {
        let cartValue = getCartCapsulesValue(cart, products).gross;
        let capsulesCount = getCartCapsulesCountAutoAdd(cart, products, categories);

        categories.map(c => {
            c.products?.map(p => {
                const itemInCart = cart.items.find(i => i.id === p.id_product);

                const promotionProduct = p.promotion_product;
                if (promotionProduct?.promotion_product_unlock && itemInCart) {
                    if (
                        cartValue <= Number(promotionProduct.promotion_product_unlock_min_price)
                        || capsulesCount < promotionProduct.promotion_product_unlock_min_products
                        || (promotionProduct.promotion_product_unlock_max_price !== null ? cartValue > Number(promotionProduct.promotion_product_unlock_min_price) : false)
                        || (promotionProduct.promotion_product_unlock_max_products !== null ? capsulesCount > Number(promotionProduct.promotion_product_unlock_max_products) : false)
                    ) {
                        const idx = cart.items.findIndex(i => i.id === itemInCart.id);
                        cart.items.splice(idx, 1);
                    }
                }

                if (promotionProduct?.promotion_product_add_auto) {
                    if(
                        cartValue >= Number(promotionProduct.promotion_product_add_auto_min_price)
                        && capsulesCount >= Number(promotionProduct.promotion_product_add_auto_min_products)
                        && (promotionProduct.promotion_product_add_auto_max_price === null ? true : cartValue <= Number(promotionProduct.promotion_product_add_auto_max_price))
                        && (promotionProduct.promotion_product_add_auto_max_products === null ? true : capsulesCount <= Number(promotionProduct.promotion_product_add_auto_max_products))
                        && (promotionProduct.promotion_product_add_auto_min_price_machine === null || !machinePrice ? true : Number(machinePrice) >= promotionProduct.promotion_product_add_auto_min_price_machine)
                        && (promotionProduct.promotion_product_add_auto_max_price_machine === null || !machinePrice ? true : Number(machinePrice) < promotionProduct.promotion_product_add_auto_max_price_machine)
                        && (promotionProduct.promotion_product_machines?.length ? promotionProduct.promotion_product_machines.includes(String(machine?.id_machine)) : true)
                    ) {
                        if(!itemInCart) {
                            cart.items.push({id: p.id_product, value: p.product_step});
                        }
                    }
                    else if(itemInCart) {
                        const idx = cart.items.findIndex(i => i.id === itemInCart.id);
                        cart.items.splice(idx, 1);
                    }
                } else if(c.promotion_category_add_auto){
                    if (
                        cartValue >= Number(c.promotion_category_add_auto_min_price)
                        && capsulesCount >= Number(c.promotion_category_add_auto_min_products)
                        && (c.promotion_category_add_auto_max_price === null ? true : cartValue <= Number(c.promotion_category_add_auto_max_price))
                        && (c.promotion_category_add_auto_max_products === null ? true : capsulesCount <= Number(c.promotion_category_add_auto_max_products))
                        && (c.promotion_category_add_auto_min_price_machine === null || !machinePrice ? true : Number(machinePrice) >= c.promotion_category_add_auto_min_price_machine)
                        && (c.promotion_category_add_auto_max_price_machine === null || !machinePrice ? true : Number(machinePrice) < c.promotion_category_add_auto_max_price_machine)
                    ) {
                        if (!itemInCart) {
                            cart.items.push({id: p.id_product, value: p.product_step});
                        }
                    } else if (itemInCart) {
                        const idx = cart.items.findIndex(i => i.id === itemInCart.id);
                        cart.items.splice(idx, 1);
                    }
                }
            })
        })
    } else {
        cart = {
            items: [],
            sum: '',
        }
    }
    return cart;
}


export const getPackageCapsulesCount = (product) => {
    let count = 0;
    if (product.product_coffees_in_set) {
        Object.keys(product.product_coffees_in_set).map(id => count += Number(product.product_coffees_in_set[id]))
    }
    return count;
}


export const getPackageCapsulesLength = (product) => {
    let value = 0;
    if (product.product_coffees_in_set) {
        Object.keys(product.product_coffees_in_set).map(id => {
            const v = Number(product.product_coffees_in_set[id]);
            if (v > 0) {
                value += v;
            }
        })
    }
    return value;
}


export const getCartCapsulesCount = (cart, products) => {
    let count = 0;
    cart?.items.map(i => {
        const item = products.find(p => p.id_product === i.id);
        if (item) {
            switch (item.product_type) {
                case Config.COFFEE_TYPE.PACKAGE:
                    if (item.product_coffees_in_set) {
                        let c = 0;
                        Object.keys(item.product_coffees_in_set).map(id => c += Number(item.product_coffees_in_set[id]))
                        count += i.value * c;
                    }
                    break;
                case Config.COFFEE_TYPE.CAPSULE:
                case Config.COFFEE_TYPE.REBATE:
                case Config.COFFEE_TYPE.PLAN_COFFEE:
                case Config.COFFEE_TYPE.PLAN_WORK_HOME:
                case Config.COFFEE_TYPE.GIFT:
                default:
                    count += i.value * item.product_package_qty;
                    break;
            }
        }
    })
    return count;
}


export const getCartCapsulesCountAutoAdd = (cart, products, categories) => {
    let count = 0;
    cart?.items.map(i => {
        const item = products.find(p => p.id_product === i.id);
        if (item) {
            let promotionProduct = null;
            categories.find(c => {
                return promotionProduct = c.products?.find(p => p.id_product === item.id_product)?.promotion_product;
            })

            switch (item.product_type) {
                case Config.COFFEE_TYPE.PACKAGE:
                    if (promotionProduct?.promotion_product_unlock)
                        break;

                    if (item.product_coffees_in_set) {
                        let c = 0;
                        Object.keys(item.product_coffees_in_set).map(id => c += Number(item.product_coffees_in_set[id]))
                        count += i.value * c;
                    }
                    break;
                case Config.COFFEE_TYPE.CAPSULE:
                case Config.COFFEE_TYPE.REBATE:
                case Config.COFFEE_TYPE.PLAN_COFFEE:
                case Config.COFFEE_TYPE.PLAN_WORK_HOME:
                case Config.COFFEE_TYPE.GIFT:
                default:
                    count += i.value * item.product_package_qty;
                    break;
            }
        }
    })

    return count;
}


export const getCartCapsulesValue = (cart, products, vatValue = null) => {
    if (typeof vatValue !== "number")
        vatValue = Number(vatValue);

    let gross = 0;
    let vat = 0;
    let net = 0;
    cart?.items.map(i => {
        const item = products.find(p => p.id_product === i.id);
        if (item && item.product_type !== Config.COFFEE_TYPE.GIFT
            && item.product_type !== Config.COFFEE_TYPE.EASY_ORDER_GIFT
            && item.product_type !== Config.COFFEE_TYPE.REBATE
            && item.product_type !== Config.COFFEE_TYPE.DELIVERY
        ) {
            const hasPromoPrice = checkPromoPrice(
                parseInt(item.product_price_promo?.status),
                item.product_price_promo?.date_from,
                item.product_price_promo?.date_to,
            );

            const price = hasPromoPrice ? item.product_price_promo
                : item.product_type.startsWith('plan_') ? item.product_plan_price : item.product_price;

            // console.log(hasPromoPrice, price)
            price.gross = Number(price.gross);
            price.vat = Number(price.vat);
            price.net = Number(price.net);

            let productGross = 0

            if(item.product_type === Config.COFFEE_TYPE.PLAN_WORK_HOME) {
                productGross += Number(item.product_plan_machine_refund) - Number(item.product_plan_machine_price)
            }

            if(vatValue) {
                productGross += i.value * ((price.net * (1 + vatValue)).toFixed(2) * item.product_package_qty)
            } else {
                productGross += i.value * (price.gross.toFixed(2) * item.product_package_qty);
            }
            const productNet = i.value * (Number(price.net) * item.product_package_qty)
            vat += Number((productGross - productNet).toFixed(2));

            gross += productGross;
            net += productNet;
        }
    })

    return {gross, vat, net};
}


export const getCartValueByCustomVat = (cart, products, vatValue = null) => {
    if (typeof vatValue !== "number")
        vatValue = Number(vatValue);

    let gross = 0;
    let vat = 0;
    let net = 0;
    cart?.items.map(i => {
        const item = products.find(p => p.id_product === i.id);
        if (item && item.product_type !== Config.COFFEE_TYPE.DELIVERY) {
            const hasPromoPrice = checkPromoPrice(
                parseInt(item.product_price_promo?.status),
                item.product_price_promo?.date_from,
                item.product_price_promo?.date_to,
            );

            const price = hasPromoPrice ? item.product_price_promo
                : item.product_type.startsWith('plan_') ? item.product_plan_price : item.product_price;

            price.gross = Number(price.gross);
            price.vat = Number(price.vat);
            price.net = Number(price.net);

            let productGross = 0

            if(item.product_type === Config.COFFEE_TYPE.PLAN_WORK_HOME) {
                productGross += Number(item.product_plan_machine_refund) - Number(item.product_plan_machine_price)
            }

            if(vatValue) {
                productGross += i.value * ((price.net * (1 + vatValue)).toFixed(2) * item.product_package_qty)
            } else {
                productGross += i.value * (price.gross.toFixed(2) * item.product_package_qty);
            }
            const productNet = i.value * (Number(price.net) * item.product_package_qty)
            vat += Number((productGross - productNet).toFixed(2));

            gross += productGross;
            net += productNet;
        }
    })

    return {gross, vat, net};
}


export const getStayHomePlanMachineValue = (cart, products) => {
    return cart.reduce((value, item) => {
        const product = products.find(p => p.id_product === item.id)
        if(!product) return value
        return Number(product.product_plan_machine_refund) - Number(product.product_plan_machine_price)
    }, 0)
}


export const getCartGiftValue = (cart, products, capsulesValue = null) => {
    let gross = 0;
    let vat = 0;
    let net = 0;
    cart?.items
        .map(i => products.find(p => p.id_product === i.id))
        .filter(p => [Config.COFFEE_TYPE.GIFT,Config.COFFEE_TYPE.EASY_ORDER_GIFT, Config.COFFEE_TYPE.REBATE].includes(p?.product_type))
        .sort(a => a.product_type === Config.COFFEE_TYPE.REBATE ? 1 : -1)
        .map(item => {
            if(item.product_percentage_rebate) {
                const percentageRebate = Number(item.product_percentage_rebate) / 100
                if(!capsulesValue)
                    capsulesValue = getCartCapsulesValue(cart, products)
                gross -= capsulesValue.gross * percentageRebate
                net -= capsulesValue.net * percentageRebate
            } else {
                const cartItem = cart.items.find(i => i.id === item.id_product)

                const hasPromoPrice = checkPromoPrice(
                    parseInt(item.product_price_promo?.status),
                    item.product_price_promo?.date_from,
                    item.product_price_promo?.date_to,
                );

                const price = item.product_price;
                if(price && cartItem) {
                    if(!hasPromoPrice) {
                        gross += cartItem.value * Number(price.gross);
                        net += cartItem.value * Number(price.net);
                    }
                }
                else {
                    console.error(`Fill price for [product=${cartItem.id}]`)
                }
            }
        })
    return {gross, vat, net};
}

export const getCartGiftValueWithoutPromo = (cart, products) => {
    let gross = 0;
    let vat = 0;
    let net = 0;
    cart?.items
        .map(i => products.find(p => p.id_product === i.id))
        .filter(p => [Config.COFFEE_TYPE.GIFT,Config.COFFEE_TYPE.EASY_ORDER_GIFT, Config.COFFEE_TYPE.REBATE, Config.COFFEE_TYPE.ACCESSORY].includes(p?.product_type))
        .sort(a => a.product_type === Config.COFFEE_TYPE.REBATE ? 1 : -1)
        .map(item => {
            const cartItem = cart.items.find(i => i.id === item.id_product)

            const hasPromoPrice = checkPromoPrice(
                parseInt(item.product_price_promo?.status),
                item.product_price_promo?.date_from,
                item.product_price_promo?.date_to,
            );

            const price = item.product_price;
            if(hasPromoPrice && cartItem) {
                gross += cartItem.value * Number(price.gross);
                net += cartItem.value * Number(price.net);
            }
        })
    return {gross, vat, net};
}


export const getCartDeliveryValue = (cart, products) => {
    let gross = 0;
    let vat = 0;
    let net = 0;
    cart?.items.map(i => {
        const item = products.find(p => p.id_product === i.id);
        if (item && item.product_type === Config.COFFEE_TYPE.DELIVERY) {
            const price = item.product_price;
            if (price) {
                gross += i.value * Number(price.gross);
                net += i.value * Number(price.net);
            } else {
                console.error(`Fill price for [product=${i.id}]`)
            }
        }
    })
    return {gross, vat, net};
}


export const getCategoriesWithProductsFromCart = (cats, products, cart) => {
    const res = [];

    cart.items.map(i => {
        const prod = products.find(p => p.id === i.id);
        if (prod) {
            const category = cats.find(c => c.id === prod.category_id)
                || cats.find(c => c.categories?.find(c => c.id === prod.category_id));


            if (res[category.id]) {
                res[category.id].products.push(prod);
            } else {
                res[category.id] = {
                    category,
                    products: [],
                }
            }
        }
    })

    return res;
}


export const getCategories = (categories = []) => {
    categories = Object.values(categories)
        .sort((a, b) => a.promotion_category_position < b.promotion_category_position ? -1 : 0)
        .filter(c =>
            c.category.category_type === Config.COFFEE_TYPE.CAPSULE
            || c.category.category_type === Config.COFFEE_TYPE.PACKAGE
            || c.category.category_type === Config.COFFEE_TYPE.PLAN_COFFEE
            || c.category.category_type === Config.COFFEE_TYPE.EASY_ORDER_GIFT
            || c.category.category_type === Config.COFFEE_TYPE.ACCESSORY
        )
    // .sort((a,b) => a.category.category_position < b.category.category_position ? -1 : 0);

    const cats = [];

    categories.map(c => {
        const {category, products, subcategories, ...promotionCategory} = c;
        if (c.category.subcategories.length) {
            cats.push(...c.category.subcategories
                .sort((a, b) => a.category_position < b.category_position ? -1 : 0)
                .map(c => ({
                    ...c,
                    promotionCategory
                })));
        } else {
            cats.push({
                ...c.category,
                products: c.products,
                promotionCategory,
            });
        }
    })

    return cats;
}


export const getProducts = (categories = []) => {
    const products = [];

    categories.map(c => {
        if (c.categories.length) {
            c.categories.map(c => products.push(...c.products));
        } else {
            products.push(...c.products);
        }
    })

    return products;
}


export const snakeToCamel = str => str.replace(/([-_]\w)/g, g => g[1].toUpperCase())


export const regexFromString = (regex: string) => {
    if (!regex)
        return undefined;

    let re = null;

    regex = regex.replace(/^\//, '');

    let flag = regex.match(/\/[a-zA-Z0-9]+$/);
    if (flag) {
        regex = regex.replace(flag[0], '');
        flag = flag[0]?.replace('/', '')
        re = new RegExp(regex, flag);
    } else {
        regex = regex.replace(/\/$/, '');
        re = new RegExp(regex);
    }
    return re;
}


export const getImage = (json: string, type = Config.GALLERY_TYPE.COVER) => {
    if (!json) {
        return '';
    }


    if (typeof json !== 'object') {
        json = JSON.parse(json);
    }


    const item = Object.values(json).find(i => i.type?.toString().toLowerCase() === type)
    if (item) {
        return `${Env.APP_STORAGE}/${item.path}`;
    }
    return '';
}


export const getFileUrl = (file) => {
    if (!file)
        return '';

    if (file.path?.startsWith('http'))
        return file.path;

    return `${Env.APP_STORAGE}/${file.path}`
}


export const getStepHeaderHeight = () => {
    return document.querySelector('.step')?.scrollHeight ?? 0;
}

export const getAllStepsHeaderHeight = () => {
    let height = 0
    const steps = Array.from(document.querySelectorAll('.step'))
    for (const idx in steps) {
        const step = steps[idx]
        if (!step) continue
        if (step.classList.contains('-active')) {
            const heightModifier = Number(idx) + 1 < steps.length ? 0 : 20
            height += step.querySelector('.step__header').scrollHeight + heightModifier ?? 0
            break
        } else {
            height += step.scrollHeight ?? 0
        }
    }
    return height
}


export const isCheckboxChecked = (values, name) => {
    return !!values.find(a => a === name);
}


export const handleCheckboxChange = (values, name) => {
    let res = [...values];
    const idx = res.findIndex(a => a === name);
    if (idx !== -1) {
        res.splice(idx, 1);
    } else {
        res.push(name);
    }
    return res;
}


export const getCivilityByTitle = (title) => {
    switch (title) {
        case 'mr':
            return 1;
        case 'mrs':
            return 2;
        default:
            return null;
    }
}


export const scrollVertically = (element, customOffset = 0, behavior: 'smooth' | 'auto' = undefined) => {
    if (element) {
        const headerHeight = document.querySelector('.header')?.clientHeight ?? 0;

        let offset = element.getBoundingClientRect().top + document.documentElement.scrollTop;
        offset -= headerHeight + customOffset;

        window.scrollTo({top: offset, behavior})
    }
}


export const checkPromoPrice = (status, dateFrom, dateTo) => {
    if (!status)
        return false;
    const from = new Date(dateFrom).getTime();
    const to = new Date(dateTo).getTime();
    const now = Date.now();
    return now > from && now < to;
}


export const mapStackToOptions = (stack) => {
    const arr = [];
    stack?.map(s => {
        arr.push({
            value: s.stack_value,
            label: s.stack_title,
        })
    })
    return arr;
}


export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function isoDateConverter(iso, separator = '/') {
    const date = new Date(iso);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear()

    return year + separator + (month < 10 ? '0' + month : month) + separator + (day < 10 ? '0' + day : day);
}

export function isoDateConverterWithTime(iso, separatorDate = '/', separatorTime = ":") {
    const date = new Date(iso);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds()

    return year + separatorDate + (month < 10 ? '0' + month : month) + separatorDate + (day < 10 ? '0' + day : day) + ' ' + hours + separatorTime + (minutes < 10 ? '0' + minutes : minutes) + separatorTime + (seconds < 10 ? '0' + seconds : seconds);
}


export function getFieldFormat(object) {
    if (!object)
        object = {}

    const pattern = regexFromString(object.pattern) ?? undefined
    return {
        ...object,
        pattern,
        inputMask: object.mask ?? null,
        max_length: object.max_length ? parseInt(object.max_length) : null,
        required: object.required ? parseInt(object.required) : 0,
        mask_status: object.mask_status ? parseInt(object.mask_status) : 0,
        reseller_required: object.reseller_required ? parseInt(object.reseller_required) : 0,
        reseller_status: object.reseller_status ? parseInt(object.reseller_status) : 0,
        status: object.status ? parseInt(object.status) : 0,
    }
}

export function getURLSearchParams(object) {
    if(!object) return ''

    return Object.keys(object).reduce((prev, key) => {
        const value = object[key]
        if(!value) return prev
        return `${prev}&${key}=${value}`.replace(/\?&/, '?')
    }, '?')
}

export function getDate(date) {
    if(!date) return null
    if(typeof date === 'string')
        date = new Date(date)

    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear().toString()
    return `${year}-${month}-${day}`
}


export function shortText(text, count = 70) {
    let shortText = '';
    const arr = text.split(' ');

    if(text.length > count) {
        text = text.replace(/(<.+?>)|(<\/.+?>)/g, '')
        const specialChars = text.substr(0, count).match(/(<.+?>)|(<\/.+?>)|(&.+?;)/g)
        const specialCharsLength = specialChars?.reduce((length, string) => length + string.length, 0) ?? 0

        shortText = text.substr(0, count + specialCharsLength);
        const idx = shortText.lastIndexOf(' ');
        shortText = shortText.substr(0, idx);

        shortText += ' ...';
        return {
            text: shortText,
            hasMore: true,
        };
    }

    return {
        text,
        hasMore: false,
    };
}


/**
 * Calculate member credit
 * @param {object} data Member credit data
 * @param {number} orderValue Order value
 * @returns {{charge: number, available: number, remaining: number}}
 */
export function calcMemberCredit(data, orderValue) {
    if(!data)
        return null

    const available = data.available.total
    let remaining = data.available.total - data.used.total
    if(remaining < 0) {
        remaining = 0
    }
    let charge = data.available.total - orderValue
    if(charge > 0) {
        charge = 0
    } else {
        charge = Math.abs(charge)
    }

    return {
        available,
        remaining,
        charge,
    }
}


export async function getOrder(token) {
    const categories = [];
    const products = [];
    const cart = {items: [], sum: ''}
    let order = null;
    let promotion = null
    await OrderActions.getOrderByUuid(token).then(async r => {
        const res = r.data;
        if (res.status === 'success') {
            order = res.data;
            res.data.items.map(p => {
                products.push({
                    ...p,
                    id_product: p.id_product,
                    id_category: p.id_category,
                    product_code: p.product_code,
                    product_type: p.product_type,
                    product_name: p.product_name,
                    product_title: p.product_title,
                    product_price: {
                        net: p.order_item_price_net,
                        gross: p.order_item_price_gross,
                        vat: p.order_item_price_vat,
                    },
                    product_percentage_rebate: p.order_item_percentage_rebate,
                    product_plan_price: {
                        net: p.order_item_plan_price_net,
                        gross: p.order_item_plan_price_gross,
                        vat: p.order_item_plan_price_vat,
                    },
                    product_plan_marketing_email_visible: p.product_plan_marketing_email_visible,
                    product_plan_marketing_mobile_visible: p.product_plan_marketing_mobile_visible,
                    product_plan_marketing_phone_visible: p.product_plan_marketing_phone_visible,
                    product_plan_marketing_post_visible: p.product_plan_marketing_post_visible,
                    product_plan_marketing_required: p.product_plan_marketing_required,
                    product_plan_marketing_select_all_benefits_enabled: p.product_plan_marketing_select_all_benefits_enabled,
                    product_step: p.product_step,
                    product_package_qty: p.product_package_qty,
                    product_gallery: p.product_gallery,
                    product_files: p.product_files,
                })

                if (!categories.find(c => c.id_category === p.id_category)) {
                    categories.push({
                        id_category: p.id_category,
                        category_name: p.category_name,
                        category_title: p.category_title,
                        category_type: p.category_type,
                    })
                }


                cart.items.push({
                    id: p.id_product,
                    value: Number(p.order_item_qty) > 0 ? p.order_item_qty / p.product_package_qty : p.product_step,
                })
            })

            if(order.id_promotion) {
                if(order.promotion) {
                    promotion = order.promotion
                } else {
                    await promotionActions.getById(order.id_promotion)
                        .then(r => {
                            const res = r.data;
                            if(res.status === 'success') {
                                promotion = res.data
                            } else {
                                console.error('Cannot get promotion')
                            }
                        })
                        .catch(err => {
                            console.error('Error: ', err)
                        })
                }
            }
        } else {
            if (res.messages?.length) {
                if (res.messages[0] === 'order_not_exist') {
                    // history.push(navigation(Routes.SHOP))
                }
            }
        }
    }).catch(err => {
        console.error('Error: ', err);
    })
    return {cart, products, categories, promotion, order};
}


export function mapOrderToCustomer(
    order,
    civilityOptions,
    professionOptions = [],
    regionOptions = [],
) {
    if (!order)
        return {};
    return {
        companyAddress: order.delivery_is_company,
        companyName: order.delivery_company_name,
        profession: professionOptions.find(o => o.value == order.delivery_company_profession),
        title: civilityOptions.find(o => Number(o.value) === Number(order.delivery_civility)),
        taxId: order.delivery_company_tax_id,
        name: order.delivery_firstname,
        surname: order.delivery_lastname,
        address: order.delivery_address1,
        address2: order.delivery_address2,
        postcode: order.delivery_zipcode,
        city: order.delivery_city,
        country: order.delivery_country,
        phone: order.delivery_phone,
        phoneMobile: order.delivery_phone_mobile,
        phoneHome: order.delivery_phone_home,
        email: order.delivery_email,

        withCustomAddress: order.other_delivery,
        customCompanyAddress: order.other_delivery_is_company,
        customCompanyName: order.other_delivery_company_name,
        customTitle: civilityOptions.find(o => Number(o.value) === Number(order.other_delivery_civility)),
        customTaxId: order.other_delivery_company_tax_id,
        customName: order.other_delivery_firstname,
        customSurname: order.other_delivery_lastname,
        customAddress: order.other_delivery_address1,
        customAddress2: order.other_delivery_address2,
        customPostcode: order.other_delivery_zipcode,
        customCity: order.other_delivery_city,
        customCountry: order.other_delivery_country,
        customPhone: order.other_delivery_phone,
        customPhoneMobile: order.other_delivery_phone_mobile,
        customPhoneHome: order.other_delivery_phone_home,
        benefits: {
            email: !!order.marketing_email,
            mobile: !!order.marketing_mobile,
            phone: !!order.marketing_phone,
            post: !!order.marketing_post,
        },
        termsAndConditions: order.regulations,
        privacyPolicy: order.privacy_policy,
        pdBsbNumber: order.payment_bsb_number,
        pdAccountNumber: order.payment_account_number,
        pdDataConfirmed: order.payment_data_confirmed,
    };
}


export function queryParams(obj) {
    return obj && Object.keys(obj).length ? `?${new URLSearchParams(obj).toString()}` : ''
}


/**
 *
 * @param quotationCart
 * @returns {{subtotal: number, tax: number, benefit: number}|null}
 */
export function calcQuotationCart(quotationCart) {
    if(!quotationCart)
        return null
    return quotationCart.reduce(({subtotal, tax, benefit}, item) => {
        if(item.finance.amount.productPrice < 0) {
            benefit += item.product.unitPrice * item.product.quantity
        } else {
            subtotal += item.product.unitPrice * item.product.quantity
        }
        if(item.finance.amount.tax) {
            tax += item.finance.amount.tax
        }

        return {subtotal, tax, benefit}
    }, {subtotal: 0, tax: 0, benefit: 0})
}

