import {getImage} from "../misc/Helpers";
import React from "react";
import {useCart, usePriceFormatter, useTranslation} from "../misc/Hooks";
import {Button, PlusMinusButton} from "./buttons/_index";


interface Props {
    item: any,
}


export default (props: Props) => {
    const {formatPrice, formatPriceObj, checkPromoPrice} = usePriceFormatter();
    const {updateItem, isItemLocked} = useCart();
    const t = useTranslation();

    const itemInCart = props.cart?.items.find(i => i.id === props.item.id_product);

    const hasPromoPrice = checkPromoPrice(
        parseInt(props.item.product_price_promo?.status),
        props.item.product_price_promo?.date_from,
        props.item.product_price_promo?.date_to,
    );


    const handleChange = () => {
        updateItem(props.item.id_product, props.item.product_step)
        props.hideModal();
    }


    return (
        <div className="coffeeItem">
            <div className="coffeeItem__header">
                <div className="coffeeItem__thumb">
                    <img src={getImage(props.item.product_gallery)} alt=""/>
                </div>
                <h3 className="coffeeItem__title">{props.item.product_title}</h3>
                {!!props.item.product_lead && (
                    <p className="coffeeItem__subtitle">{props.item.product_lead}</p>
                )}
            </div>
            <div className="coffeeItem__body">

                <div className="coffeeItem__price">
                    {!hasPromoPrice ? (
                        <span>{formatPriceObj(props.item.product_price)}</span>
                    ) : (
                        <>
                            <small><s className="--textDark" style={{marginRight: '6px'}}>{formatPriceObj(props.item.product_price)}</s></small>
                            <span>{formatPriceObj(props.item.product_price_promo)}</span>
                        </>
                    )}
                </div>

                <div className="coffeeItem__button">
                    {itemInCart ? (
                        <PlusMinusButton
                            type="primary"
                            size="sm"
                            plusDisabled={itemInCart.value >= props.item.product_max_qty || isItemLocked(props.item.id_product)}
                            value={itemInCart.value * props.item.product_package_qty}
                            step={props.item.product_step}
                            packageQty={props.item.product_package_qty}
                            max={props.item.product_max_qty ?? undefined}
                            onClickMinus={() => updateItem(props.item.id_product, (itemInCart?.value ?? 0) - props.item.product_step)}
                            onClickPlus={() => updateItem(props.item.id_product, (itemInCart?.value ?? 0) + props.item.product_step)}
                            onChange={v => updateItem(props.item.id_product, v)}
                        />
                    ) : (
                        <Button
                            type="primary"
                            size="sm"
                            label={t('buy_now')}
                            disabled={isItemLocked(props.item.id_product)}
                            onClick={handleChange}
                        />
                    )}

                </div>
            </div>
        </div>
    )
}
