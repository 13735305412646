import {Button} from "../buttons/_index";
import React, {useEffect, useState} from "react";
import {useTranslation} from "../../misc/Hooks";

export default function ResendEmailButton(props){
    const t = useTranslation();
    const [sent, setSent] = useState(false);

    useEffect(() => {
        setSent(false)
    }, [props.value])

    const handleClick = () => {
        setSent(true);
        props.onClick(props.value.id_order);
    }

    return (
        <Button
            size="md"
            label={t(sent ? 'table.sent' : 'table.send_again')}
            disabled={sent}
            onClick={handleClick}
        />
    )
}