import React, {useState} from 'react';
import AssistedRegistration from "../../components/reseller/AssistedRegistration";
import {useResellerActions} from "../../actions/useResellerActions";


export default function AssistedRegistrationContainer(props) {
    const resellerActions = useResellerActions()
    const [dataState, setDataState] = useState({
        data: [],
        maxPage: 1,
        results: 0,
        loading: true,
    })


    const getOrders = async (page, pageSize, searchData = null) => {
        const params = `limit=${pageSize}&page=${page}`;
        const payload = {
            sort: null,
            slug: searchData?.slug,
            order_type: ['register_machine', 'subscription_coffee', 'easy_order'],
            date_from: null,
            date_to: null,
            id_shop: [],
            status: [],
            id_reseller: [-1] // -1 = logged user
        }


        await resellerActions.getOrders(payload, params)
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    setDataState({
                        data: res.data.data,
                        results: res.data.total,
                        maxPage: res.data.last_page,
                        loading: false,
                    })
                } else {

                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    const handleResendOrder = (id) => {
        resellerActions.resendOrder(id)
            .then(() => {
            })
            .catch(err => console.error(err))
    }

    return (
        <div className="wrapper -xxl">
            <AssistedRegistration
                orders={dataState.data}
                ordersMaxPage={dataState.maxPage}
                ordersResults={dataState.results}
                ordersLoading={dataState.loading}
                getData={getOrders}
                resendOrder={handleResendOrder}
            />
        </div>
    )
}
