import BaseModal from "../BaseModal";
import {useTranslation} from "../../../misc/Hooks";
import React from "react";


interface Props {
    visible: boolean,
    onClose: void,
}

export default function ForgotPasswordPasswordChangedResellerModal(props: Props) {
    const t = useTranslation();

    return (
        <BaseModal visible={props.visible} onClose={props.onClose}>
            <div className="modal__header">
                <h3 className="modal__title">{t('modal.forgot_password_password_changed_reseller.title')}</h3>
            </div>

            <div className="modal__body" style={{textAlign: 'center'}}>
                <p className="par-2" dangerouslySetInnerHTML={{__html: (t('modal.forgot_password_password_changed_reseller.text'))}}/>
            </div>
        </BaseModal>
    )
}
