import {useDispatch, useSelector} from "react-redux";
import {useLocation, useRouteMatch} from 'react-router-dom';
import {
    changeCartItem,
    checkPromoPrice,
    getCartCapsulesCount,
    getCartCapsulesValue,
    getCartDeliveryValue,
    getCartGiftValue, getCartGiftValueWithoutPromo,
    getFileUrl
} from "./Helpers";
import {Config, Routes} from "../utils/_index";
import * as mainReducer from "../redux/mainReducer";
import * as evReducer from "../redux/evReducer";
import * as stayHomeReducer from "../redux/stayHomeReducer";
import * as resellerReducer from "../redux/resellerReducer";
import * as rmsReducer from "../redux/rmsReducer";
import * as ofReducer from "../redux/ofReducer";
import Decimal from "decimal.js";
import objectMerge from 'object-merge';
import {useLanguage} from "../hooks";
import {useMemo} from "react";
import {getPriceFormat} from "../utils/MarketConfig";


const reducers = {
    main: mainReducer,
    ev: evReducer,
    stayHome: stayHomeReducer,
    reseller: resellerReducer,
    rms: rmsReducer,
    of: ofReducer,
}


/*
* EV - e-Voucher - step validation
* */
export const useEvStepValidation = (route) => {
    const navigation = useNavigation();
    const match = useRouteMatch();
    const location = useLocation()
    const stepNavigation = useEvStepNavigation();
    const store = useSelector(({main, ev}) => ({
        user: main.user,
        country: main.country,
        sent: ev.sent,
        machine: ev.machine,
        proofOfPurchase: ev.proofOfPurchase,
        proofOfPurchaseLogs: ev.proofOfPurchaseLogs,
        promoCode: ev.promoCode,
        nespressoMember: ev.nespressoMember,
        promotion: ev.promotion,
        cart: ev.cart,
        customer: ev.customer,
        paymentMethod: ev.paymentMethod,
        order: ev.order,
        orderAccount: ev.orderAccount,
        orderAccountLinked: ev.orderAccountLinked,
    }))
    const {isCartValid} = useCart();

    let canShowStep = false;
    let lastValidStep = Routes.SHOP_MACHINE_REGISTRATION;


    switch (route) {
        case Routes.SHOP_MACHINE_REGISTRATION: {
            if (!store.order) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.SHOP_DELIVERY_PAYMENT;
            }
            break;
        }
        case Routes.SHOP_CUSTOMER_IDENTIFICATION: {
            if (store.machine && !store.order) {
                const requiredField = store.user ? store.country?.country_required_field_reseller : store.country?.country_required_field
                switch (requiredField) {
                    case 'proof_of_purchase_and_promo_code':
                        if((store.proofOfPurchase && store.proofOfPurchaseLogs) && store.promoCode)
                            canShowStep = true
                        break
                    case 'proof_of_purchase_or_promo_code':
                        if((store.proofOfPurchase && store.proofOfPurchaseLogs) || store.promoCode)
                            canShowStep = true
                        break
                    case 'only_proof_of_purchase':
                        if(store.proofOfPurchase && store.proofOfPurchaseLogs)
                            canShowStep = true
                        break
                    case 'only_promo_code':
                        if(store.promoCode)
                            canShowStep = true
                        break
                    default:
                        canShowStep = true
                }
            } else {
                lastValidStep = stepNavigation.prev();
            }
            break;
        }
        case Routes.SHOP_PROMO_SELECTION: {
            if (((store.nespressoMember !== undefined && store.nespressoMember !== null) || (store.user && store.customer)) && !store.order) {
                canShowStep = true;
            } else {
                lastValidStep = stepNavigation.prev();
            }
            break;
        }
        case Routes.SHOP_COFFEE_SELECTION: {
            if (store.promotion && !store.order) {
                canShowStep = true;
            } else {
                lastValidStep = stepNavigation.prev();
            }
            break;
        }
        case Routes.SHOP_CUSTOMER_INFORMATION: {
            if (store.promotion && isCartValid && !store.order || (store.promotion && !store.promotion?.promotion_show_coffees)) {
                canShowStep = true;
            } else {
                lastValidStep = stepNavigation.prev();
            }
            break;
        }
        case Routes.SHOP_DELIVERY_PAYMENT: {
            const orderUuid = new URLSearchParams(location?.search).get('uuid')
            if(orderUuid) {
                canShowStep = true
                break
            }
            if(store.customer && isCartValid) {
            // if (store.customer && isCartValid && (store.nespressoMember ? true : store.orderAccountLinked)) {
                canShowStep = true;
            } else {
                lastValidStep = stepNavigation.prev();
            }
            break;
        }
    }

    return {
        value: canShowStep,
        lastValidRoute: navigation(lastValidStep),
    };
}

/*
* OF - Order Finish - step validation
* */
export const useOfStepValidation = (route) => {
    const navigation = useNavigation();
    const store = useSelector(({of}) => ({
        finishOrder: of.finishOrder,
        finishOrderUser: of.finishOrderUser,
        customer: of.customer,
        order: of.order,
        nespressoMember: of.nespressoMember,
    }))


    let canShowStep = false;
    let lastValidStep = Routes.FINISH_ORDER_ACCOUNT;


    switch (route) {
        case Routes.FINISH_ORDER_ACCOUNT: {
            if (!store.order) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.FINISH_ORDER_ACCOUNT;
            }
            break;
        }
        case Routes.FINISH_ORDER_CUSTOMER: {
            if (store.nespressoMember && !store.order) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.FINISH_ORDER_ACCOUNT;
            }
            break;
        }
        case Routes.FINISH_ORDER_SUMMARY: {
            if (store.nespressoMember && (store.finishOrder.webaccount_exist ? store.customer : true)) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.FINISH_ORDER_CUSTOMER;
            }
            break;
        }
        case Routes.FINISH_ORDER_PAYMENT: {
            if (store.order) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.FINISH_ORDER_SUMMARY;
            }
            break;
        }
    }

    return {
        value: canShowStep,
        lastValidRoute: navigation(lastValidStep),
    };
}


/*
* RMS - Reseller Machine Subscription - step validation
* */
export const useRmsStepValidation = (route) => {
    const navigation = useNavigation();
    const store = useSelector(({rms}) => ({
        customer: rms.customer,
        order: rms.order,
        machine: rms.machine,
        nespressoMember: rms.nespressoMember,
        cart: rms.cart,
    }))


    let canShowStep = false;
    let lastValidStep = Routes.RESELLER_MACHINE_SUBSCRIPTION_MACHINE;


    switch (route) {
        case Routes.RESELLER_MACHINE_SUBSCRIPTION_MACHINE: {
            canShowStep = true;
            break;
        }
        case Routes.RESELLER_MACHINE_SUBSCRIPTION_IDENTIFICATION: {
            if (store.machine) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.RESELLER_MACHINE_SUBSCRIPTION_MACHINE;
            }
            break;
        }
        case Routes.RESELLER_MACHINE_SUBSCRIPTION_PLAN: {
            if (store.nespressoMember !== undefined) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.RESELLER_MACHINE_SUBSCRIPTION_CUSTOMER;
            }
            break;
        }
        case Routes.RESELLER_MACHINE_SUBSCRIPTION_CUSTOMER: {
            if (store.cart.items.length) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.RESELLER_MACHINE_SUBSCRIPTION_IDENTIFICATION;
            }
            break;
        }
        case Routes.RESELLER_MACHINE_SUBSCRIPTION_SUMMARY: {
            if (store.cart.items.length && store.customer) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.RESELLER_MACHINE_SUBSCRIPTION_CUSTOMER;
            }
            break;
        }
    }

    return {
        value: canShowStep,
        lastValidRoute: navigation(lastValidStep),
    };
}


/*
* Sh - Stay Home - step validation
* */
export const useShStepValidation = (route) => {
    const navigation = useNavigation();
    const store = useSelector(({stayHome}) => ({
        token: stayHome.token,
        email: stayHome.email,
        code: stayHome.code,
        // plan: stayHome.plan,
        customer: stayHome.customer,
        cart: stayHome.cart,
        products: stayHome.products,
    }))

    const plan = store.cart.items.find(i => store.products.find(p => p.id_product === i.id))

    let canShowStep = false;
    let lastValidStep = Routes.STAY_HOME_AUTHORIZATION;


    switch (route) {
        case Routes.STAY_HOME_AUTHORIZATION: {
            canShowStep = true;
            break;
        }
        case Routes.STAY_HOME_PLAN_SELECTION: {
            if (store.code) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.STAY_HOME_AUTHORIZATION;
            }
            break;
        }
        case Routes.STAY_HOME_COFFEE_SELECTION: {
            if (plan) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.STAY_HOME_PLAN_SELECTION;
            }
            break;
        }
        case Routes.STAY_HOME_CUSTOMER: {
            if (plan) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.STAY_HOME_PLAN_SELECTION;
            }
            break;
        }
        case Routes.STAY_HOME_SUMMARY: {
            if (plan && store.customer) {
                canShowStep = true;
            } else {
                lastValidStep = Routes.STAY_HOME_CUSTOMER;
            }
            break;
        }
    }

    return {
        value: canShowStep,
        lastValidRoute: navigation(lastValidStep),
    };
}


/*
* EV - e-Voucher - steps
*
* returns steps from config, depends on global state (store)
* */
export const useEvSteps = () => {
    const store = useSelector(({main, ev}) => ({
        user: main.user,
        promotion: ev.promotion,
        paymentMethod: ev.paymentMethod,
        nespressoMember: ev.nespressoMember,
    }))

    const steps = [];
    for (let s in Config.EV_STEPS) {
        const step = Config.EV_STEPS[s];
        if (store.promotion && !store.promotion?.promotion_show_coffees && step.route === Routes.SHOP_COFFEE_SELECTION) {
            continue;
        }
        if (store.user && store.nespressoMember === null && step.route === Routes.SHOP_CUSTOMER_INFORMATION)
            continue;
        if (store.user && step.route === Routes.SHOP_DELIVERY_PAYMENT)
            step.title = 'ev_delivery_payment.title2'
        steps.push(step);
    }
    return steps;
}

/*
* OF - Order Finish - steps
*
* returns steps from config, depends on global state (store)
* */
export const useOfSteps = () => {
    const store = useSelector(({main, of}) => ({
        user: main.user,
        promotion: of.promotion,
        paymentMethod: of.paymentMethod,
        nespressoMember: of.nespressoMember,
        finishOrder: of.finishOrder,
    }))

    const steps = [];
    for (let s in Config.OF_STEPS) {
        const step = Config.OF_STEPS[s];
        if ((store.finishOrder && !store.finishOrder.webaccount_exist) && step.route === Routes.FINISH_ORDER_ACCOUNT)
            step.title = 'finish_order_account.title2'
        if (!store.finishOrder?.webaccount_exist && step.route === Routes.FINISH_ORDER_CUSTOMER)
            continue
        steps.push(step);
    }
    return steps;
}

/*
* RMS - Reseller Machine Subscription - steps
*
* returns steps from config, depends on global state (store)
* */
export const useRmsSteps = () => {
    const store = useSelector(({main, rms}) => ({
        user: main.user,
        promotion: rms.promotion,
        paymentMethod: rms.paymentMethod,
        nespressoMember: rms.nespressoMember,
    }))

    const steps = [];
    for (let s in Config.RMS_STEPS) {
        const step = Config.RMS_STEPS[s];
        if (store.nespressoMember === true && step.route === Routes.RESELLER_MACHINE_SUBSCRIPTION_CUSTOMER)
            continue;
        steps.push(step);
    }
    return steps;
}


/*
* RMS - Reseller Machine Subscription - steps
*
* returns steps from config, depends on global state (store)
* */
export const useShSteps = () => {
    const store = useSelector(state => ({
        promotion: state.promotion,
        user: state.user,
        paymentMethod: state.paymentMethod,
        nespressoMember: state.nespressoMember,
        finishOrder: state.finishOrder,
    }))

    const steps = [];
    for (let s in Config.SH_STEPS) {
        const step = Config.SH_STEPS[s];
        // if (store.nespressoMember === true && step.route === Routes.RESELLER_MACHINE_SUBSCRIPTION_CUSTOMER)
        //     continue;
        // if(!store.finishOrder?.webaccount_exist && step.route === Routes.FINISH_ORDER_ACCOUNT)
        //     step.title = 'finish_order_account.title2'
        // if(!store.finishOrder?.webaccount_exist && step.route === Routes.FINISH_ORDER_CUSTOMER)
        //     continue
        steps.push(step);
    }
    return steps;
}


/*
* EV - e-Voucher - steps
*
* returns next and prev route for current step
* uses filtered steps from use__Steps
* */
export const useEvStepNavigation = () => {
    const navigation = useNavigation();
    const steps = useEvSteps();
    const match = useRouteMatch();
    const location = useLocation();

    const arr = location.pathname.split('/');
    const route = arr[arr.length - 1] ?? '';
    const currentStepIndex = steps.findIndex(s => s.route === match.path);

    const prev = () => {
        const prev = steps[currentStepIndex - 1];
        if (prev) {
            return navigation(prev.route);
        }
        return navigation(steps[currentStepIndex].route);
    }

    const next = () => {
        const next = steps[currentStepIndex + 1];
        if (next) {
            return navigation(next.route);
        }
        return navigation(steps[currentStepIndex].route);
    }


    return {
        prev,
        next,
    }
}

/*
* OF - Order Finish - steps
*
* returns next and prev route for current step
* uses filtered steps from use__Steps
* */
export const useOfStepNavigation = () => {
    const navigation = useNavigation();
    const steps = useOfSteps();
    const match = useRouteMatch();
    const location = useLocation();

    const arr = location.pathname.split('/');
    const route = arr[arr.length - 1] ?? '';
    const currentStepIndex = steps.findIndex(s => s.route === match.path);

    const prev = () => {
        const prev = steps[currentStepIndex - 1];
        if (prev) {
            return navigation(prev.route);
        }
        return navigation(steps[currentStepIndex].route);
    }

    const next = () => {
        const next = steps[currentStepIndex + 1];
        if (next) {
            return navigation(next.route);
        }
        return navigation(steps[currentStepIndex].route);
    }


    return {
        prev,
        next,
    }
}

/*
* RMS - Reseller Machine Subscription - steps
*
* returns next and prev route for current step
* uses filtered steps from use__Steps
* */
export const useRmsStepNavigation = () => {
    const navigation = useNavigation();
    const steps = useRmsSteps();
    const match = useRouteMatch();
    const location = useLocation();

    const arr = location.pathname.split('/');
    const route = arr[arr.length - 1] ?? '';
    const currentStepIndex = steps.findIndex(s => s.route === match.path);

    const prev = () => {
        const prev = steps[currentStepIndex - 1];
        if (prev) {
            return navigation(prev.route);
        }
        return navigation(steps[currentStepIndex].route);
    }

    const next = () => {
        const next = steps[currentStepIndex + 1];
        if (next) {
            return navigation(next.route);
        }
        return navigation(steps[currentStepIndex].route);
    }


    return {
        prev,
        next,
    }
}

/*
* RMS - Reseller Machine Subscription - steps
*
* returns next and prev route for current step
* uses filtered steps from use__Steps
* */
export const useShStepNavigation = () => {
    const navigation = useNavigation();
    const steps = useShSteps();
    const match = useRouteMatch();
    const location = useLocation();

    const arr = location.pathname.split('/');
    const route = arr[arr.length - 1] ?? '';
    const currentStepIndex = steps.findIndex(s => s.route === match.path);

    const prev = () => {
        const prev = steps[currentStepIndex - 1];
        if (prev) {
            return navigation(prev.route);
        }
        return navigation(steps[currentStepIndex].route);
    }

    const next = () => {
        const next = steps[currentStepIndex + 1];
        if (next) {
            return navigation(next.route);
        }
        return navigation(steps[currentStepIndex].route);
    }


    return {
        prev,
        next,
    }
}


/*
* returns market config from global state (store)
* */
export const useMarketConfig = () => {
    const {marketConfig} = useSelector(({main}) => ({
        marketConfig: main.marketConfig,
    }))
    const config = {};
    if (marketConfig) {
        Object.keys(marketConfig).map(k => {
            try {
                let value = parseInt(marketConfig[k]);
                if(!isNaN(value))
                    config[k.replace(/market_/, '')] = value;
                else {
                    value = JSON.parse(marketConfig[k])
                    if(typeof value === 'object')
                        config[k.replace(/market_/, '')] = value;
                    else
                        config[k.replace(/market_/, '')] = marketConfig[k];
                }
            } catch (err) {
                config[k.replace(/market_/, '')] = marketConfig[k];
            }
        })
    }
    return config;
}


/*
* returns country config from global state (store)
* */
export const useCountryConfig = () => {
    const {country} = useSelector(({main}) => ({
        country: main.country,
    }))
    const config = {};
    if(country) {
        Object.keys(country).map(k => {
            try {
                config[k.replace(/country_/, '')] = country[k];
            } catch (err) {
                //
            }
        })
    }
    return config;
}


/*
* Price formatter
* */
export const usePriceFormatter = () => {
    const {country, marketConfig} = useSelector(({main}) => ({
        country: main.country,
        marketConfig: main.marketConfig,
    }))
    const prefix = country?.country_currency_prefix?.replace(/&nbsp;/, ' ') ?? '';
    const suffix = country?.country_currency_suffix?.replace(/&nbsp;/, ' ') ?? '';


    const formatPrice = (price, toFixed = null) => {
        // if(typeof price === 'string') {
        //     price = Number(price);
        // }
        if(!price && typeof price !== 'number') {
            price = 0;
        }

        price = price.toString().replace(/,/g, '.')

        if(isNaN(price)) {
            price = 0;
        }

        price = Number(price).toFixed(7)

        toFixed = toFixed !== null ? toFixed : country?.country_currency_to_fixed
        let value = 0;
        const decimalPlaces = new Decimal(price).decimalPlaces();

        if(decimalPlaces > 0) {
            if(toFixed > decimalPlaces) {
                value = new Decimal(price).toFixed(toFixed)
            }
            else {
                const [int, decimals] = Number(price).toString().split('.');
                if(toFixed > 0) {
                    value = int + '.' + decimals.substr(0, toFixed)
                }
                else {
                    value = int;
                }
                // value = new Decimal(price).toDecimalPlaces(toFixed !== null ? toFixed : country.country_currency_to_fixed)
            }
        }
        else {
            value = new Decimal(price).toFixed(toFixed !== null ? toFixed : country.country_currency_to_fixed);
        }

        const localeCode = getPriceFormat()
        if(localeCode) {
           value = Number(value).toLocaleString(localeCode)
        }

        return `${prefix}${value}${suffix}`;
    }


    const formatPriceObj = (obj, toFixed = null) => {
        if(!obj)
            return null;

        const price = marketConfig?.market_price_type_display === 'net' ? obj.net : obj.gross;
        return formatPrice(price, toFixed);
    };

    const withCurrency = (text) => {
        if(!text)
            return ''

        return `${prefix}${text}${suffix}`
    }


    return {
        formatPrice,
        formatPriceObj,
        checkPromoPrice,
        withCurrency,
    }
}


/*
* Cart managing
* */
export const useCart = (storeName = 'ev', parentStore = null, validateCart = true) => {
    const store = useSelector(state => ({
        display: state.main.display,
        promoCode: state[storeName].promoCode,
        cart: parentStore ? parentStore.cart : state[storeName].cart,
        products: parentStore ? parentStore.products : state[storeName].products,
        promotion: parentStore ? parentStore.promotion : state[storeName].promotion,
        categories: parentStore ? parentStore.categories : state[storeName].categories,
        order: state[storeName].order,
        easyOrderSetup: state[storeName].easyOrderSetup,
        proofOfPurchase: state[storeName].proofOfPurchase,
        machine: state[storeName].machine
    }))
    const dispatch = useDispatch();

    // const cartCapsulesCount = getCartCapsulesCount(store.cart, store.products);
    const cartValue = getCartCapsulesValue(store.cart, store.products);
    const giftValue = getCartGiftValue(store.cart, store.products);
    const giftValueWithoutPromo = getCartGiftValueWithoutPromo(store.cart, store.products);
    const deliveryValue = getCartDeliveryValue(store.cart, store.products);


    const updateItem = (id, value) => {
        if (store.order)
            return;

        if (value > 0) {
            if (isItemLocked(id))
                return;
        }


        // const categories = store.categories
            // .filter(c => c.category?.category_type === Config.COFFEE_TYPE.GIFT
            //     || c.category?.category_type === Config.COFFEE_TYPE.DELIVERY);
        let c = changeCartItem(store.categories, store.cart, store.products, id, value, store.machine, store.proofOfPurchase?.machinePrice);

        const productsToAdd = store.products.filter(p => p.product_additional?.find(a => parseInt(a) === id));
        productsToAdd.map(a => {
            c = changeCartItem(store.categories, c, store.products, a.id_product, value > 0 ? a.product_step : 0, store.machine, store.proofOfPurchase?.machinePrice);
        })

        if (value === 0) {
            const product = store.products.find(p => p.id_product === id);
            product.product_additional?.map(a => {
                if (c.items.find(i => i.id === parseInt(a))) {
                    c = changeCartItem(store.categories, c, store.products, parseInt(a), 0, store.machine, store.proofOfPurchase?.machinePrice);
                }
            })
        }


        if (c.sum !== store.cart?.sum) {
            dispatch(reducers[storeName].updateCart(c));
        }
    }

    const clearCartAndAddItem = (id, value) => {
        if(value > 0) {
            const cart = changeCartItem(store.categories, {items: [], sum: ''}, store.products, id, value, store.machine, store.proofOfPurchase?.machinePrice);
            dispatch(reducers[storeName].updateCart(cart));
        } else {
            updateItem(id, value);
        }
    }

    const isCartValid = () => {
        let valid = true;

        if(!validateCart || ['of', 'rms', 'eTrade', 'stayHome'].includes(storeName))
            return true;


        /*
        * promotion config
        * */
        if(store.promotion) {
            valid = (
                getCartCapsulesCount(store.cart, store.products) >= Number(store.promotion.promotion_minimal_capsules)
                && cartValue.gross >= Number(store.promotion.promotion_minimal_order_value)
            )
        }

        if(!valid)
            return false;


        /*
        * minimal products count / interval of products-
        * */
        store.categories.map(c => {
            if(!valid)
                return;

            valid = isCategoryValid(c.id_category);
            // if(!c.category?.category_type?.startsWith('plan_')) {
            //     valid = isCategoryValid(c.id_category);
            // }
        })

        if(store.promotion?.promotion_type === Config.PROMO_TYPE.EASY_ORDER) {
            if(!store.easyOrderSetup)
                return false;
        }

        // if(store.display?.promo_code && store.promoCode) {
        //     store.promoCode.products?.map(p => {
        //         const prod = store.cart?.items?.find(i => i.id === p.id_product);
        //         if(!prod) {
        //             // console.log(p)
        //             updateItem(p.id_product, p.product_step * p.product_package_qty);
        //         }
        //     })
        // }

        // console.log(store.cart)

        return valid;
    }

    const isCategoryValid = (id) => {
        let valid = true;

        const cat = store.categories.find(c => c.id_category === id)
            || store.categories.find(c => c.category.subcategories?.find(s => s.id_category === id));
        if(cat) {
            let productsIds = cat.products.map(p => p.id_product)
            cat.category.subcategories?.map(s => s.products.map(p => productsIds.push(p.id_product)))

            if(productsIds.length) {
                const cartItems = store.cart.items.filter(i => productsIds.includes(i.id))
                let count = 0;
                cartItems.map(i => {
                    const prod = store.products.find(p => p.id_product === i.id);
                    if(prod) {
                        switch(prod.product_type) {
                            case Config.COFFEE_TYPE.PACKAGE:
                                if (prod.product_coffees_in_set?.length) {
                                    let c = 0;
                                    Object.keys(prod.product_coffees_in_set).map(id => c += Number(prod.product_coffees_in_set[id]))
                                    count += i.value * c;
                                } else {
                                    count += i.value * prod.product_package_qty
                                }
                                break;
                            default:
                                count += i.value * prod.product_package_qty
                        }
                    }
                })
                if(count < cat.promotion_category_minimal_products
                    || count % cat.promotion_category_interval_products !== 0) {
                    valid = false;
                }
            }
        }

        return valid;
    }

    const isItemLocked = (id) => {
        let isLocked = false;
        if (store.promotion?.promotion_rules) {
            const _rIds = Object.keys(store.promotion.promotion_rules)
                ?.filter(rId => store.cart.items.find(i => i.id === parseInt(rId)))
            isLocked = !!_rIds.find(rId => store.promotion.promotion_rules[rId]?.deny?.find(dId => parseInt(dId) === id))
        }

        if(isLocked)
            return true;

        const prod = store.products.find(p => p.id_product === id);
        if (prod) {
            const promoCategory = store.categories.find(c => c.category?.id_category === prod.id_category)
                || store.categories.find(c => c.category?.subcategories.find(s => s.id_category === prod.id_category));
            const prodCategory = promoCategory?.category
                || promoCategory?.category?.subcategories?.find(s => s.id_category === prod.id_category);

            const promotionProduct = promoCategory?.products?.find(p => p.id_product === id)?.promotion_product;
            if(promotionProduct) {
                if(promotionProduct.promotion_product_unlock) {
                    const capsulesCount = getCartCapsulesCount(store.cart, store.products);
                    isLocked = (
                        cartValue.gross <= Number(promotionProduct.promotion_product_unlock_min_price)
                        || capsulesCount < promotionProduct.promotion_product_unlock_min_products
                        || (promotionProduct.promotion_product_unlock_max_price !== null ? cartValue.gross > Number(promotionProduct.promotion_product_unlock_min_price) : false)
                        || (promotionProduct.promotion_product_unlock_max_products !== null ? capsulesCount > Number(promotionProduct.promotion_product_unlock_max_products) : false)
                    )
                }
            }

            if(isLocked)
                return true;


            /*
            * allow only one plan in cart
            * */
            if(prod.product_type === Config.COFFEE_TYPE.PLAN_COFFEE
                || prod.product_type === Config.COFFEE_TYPE.PLAN_MACHINE) {
                store.cart.items.map(i => {
                    if(isLocked)
                        return;
                    const prod = store.products.find(p => p.id_product === i.id);
                    if(prod && (prod.product_type === Config.COFFEE_TYPE.PLAN_COFFEE
                        || prod.product_type === Config.COFFEE_TYPE.PLAN_MACHINE)) {
                        isLocked = true;
                    }
                })
            }

            /*
            * allow only one easy order gift in cart
            * */
            if(prodCategory?.category_type === Config.COFFEE_TYPE.EASY_ORDER_GIFT) {
                let ids = [];
                if(promoCategory.products?.length)
                    ids = promoCategory.products.map(p => p.id_product);
                else
                    ids = prodCategory.products?.map(p => p.id_product);

                const cartItem = store.cart.items.find(i => ids.includes(i.id))
                if(cartItem) {
                    return !(cartItem.id_product === id);
                }
            }

            /*
            * allow only one plan stay home in cart
            * */
            if(prodCategory?.category_type === Config.COFFEE_TYPE.PLAN_WORK_HOME) {
                let ids = [];
                if(promoCategory.products?.length)
                    ids = promoCategory.products.map(p => p.id_product);
                else
                    ids = prodCategory.products?.map(p => p.id_product);

                const cartItem = store.cart.items.find(i => ids.includes(i.id))
                if(cartItem) {
                    return !(cartItem.id_product === id);
                }
            }
        }

        return isLocked;
    }

    const capsulesCount = () => {
        const products = store.promotion?.promotion_show_coffees ? (
            store.products.filter(p => (
                p.product_type !== Config.COFFEE_TYPE.GIFT
                && p.product_type !== Config.COFFEE_TYPE.EASY_ORDER_GIFT
                && p.product_type !== Config.COFFEE_TYPE.DELIVERY
                && p.product_type !== Config.COFFEE_TYPE.REBATE
            ))
        ) : store.products;
        return getCartCapsulesCount(store.cart, products);
    }

    return {
        capsulesCount: capsulesCount(),
        capsulesValue: cartValue.gross,
        capsulesVatValue: cartValue.vat,
        capsulesNetValue: cartValue.net,
        giftValue: giftValue?.gross,
        giftNetValue: giftValue?.net,
        deliveryValue: deliveryValue?.gross,
        deliveryNetValue: deliveryValue?.net,
        cartValue: cartValue.gross + giftValue?.gross,
        isCartValid: isCartValid(),
        giftValueWithoutPromo,
        updateItem,
        clearCartAndAddItem,
        isCartEditable: !store.order,
        isItemLocked,
        isCategoryValid,
    }
}


/*
* returns browser friendly route with params
* */
export const useNavigation = () => {
    const match = useRouteMatch();

    return (route: string, ...params) => {
        let r = route;
        r = r.replace(/\/:lang\([a-zA-Z0-9\|]+\)\?/, match.params.lang ? `/${match.params.lang}` : '')
        params.map(param => {
            if (r.match(/:[a-zA-Z0-9(\|)?]+\//)) {
                r = r.replace(/:[a-zA-Z0-9(\|)?]+\//, param ? `/${param}` : '')
            } else if (r.match(/:[a-zA-Z0-9(\|)?]+$/)) {
                r = r.replace(/:[a-zA-Z0-9(\|)?]+$/, param ?? '')
            }
        })
        r = r.replace(/\/:[a-zA-Z0-9?]+/g, '')
        return r;
    };
}


/*
* returns translated slug, depends on current language (route param)
* */
export const useTranslation = () => {
    const cstore = useSelector(({main}) => ({
        country: main.country,
    }))
    const currentLanguage = useLanguage();

    const match = useRouteMatch();

    const market = process.env.REACT_APP_MARKET;


    const baseFile = require(`../assets/translations/_master/_base.json`);
    let customFile = {};
    let countryFile = {};
    try {
        countryFile = require(`../assets/translations/${market}/${cstore.country.country_shortcode}/${currentLanguage}.json`);
    } catch (e) {}
    try {
        customFile = require(`../assets/translations/${market}/${currentLanguage}.json`);
    } catch (e) {
        // console.error(`Translation file for lang ${market}/${currentLanguage} not found!`)
    }

    const file = objectMerge(baseFile, objectMerge(customFile, countryFile));

    return (name, ...args) => {
        let slug = {...file};
        const params = name.toString().split('.');

        params.forEach(s => {
            if (typeof slug !== "undefined")
                slug = slug[s];
            else
                slug = name;
        })

        if (!slug) {
            // if (process.env.REACT_APP_ENV.toLowerCase() === 'dev') {
            //     if (typeof slug === 'string')
            //         slug = `/E ${name} E/`; // translation slug empty
            //     if (typeof slug === 'undefined')
            //         slug = `/U ${name} U/`; // translation slug undefined
            // } else {
                slug = '';
            // }
        }

        args.map(arg => {
            if (typeof arg === 'object' && arg !== null) {
                slug = slug.toString().replace(`{${arg.name}}`, arg.value);
            } else {
                slug = slug.toString().replace('{arg}', arg);
            }
        })
        slug = slug.toString().replace(/\{arg}/g, '');


        return slug;
    }
}



export function useLocalizeObject() {
    const currentLanguage = useLanguage();

    return (object) => {
        if(!object)
            return null;
        return object[currentLanguage];
    }
}



export function useLocalizeFile() {
    const currentLanguage = useLanguage();

    return (files, type) => {
        if(!files)
            return null;

        if(typeof files === 'string')
            files = JSON.parse(files);

        const file = files.find(f => f.lang === currentLanguage && f.type === type);
        if(file)
            return getFileUrl(file);
        return null;
    }
}




