import {useState} from "react";


export default function useStateObject(initialState = undefined) {
    const [value, setValue] = useState(initialState)

    const reset = () => {
        setValue(initialState)
    }

    const setState = (newState) => {
        if(typeof newState === 'object') {
            setValue(old => ({
                ...old,
                ...newState,
            }))
        } else {
            setValue(newState)
        }
    }

    return [value, setState, reset]
}