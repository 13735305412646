import React from 'react';
import {Button, PlusMinusButton} from "./buttons/_index";
import {
    useCart,
    useLocalizeObject,
    useMarketConfig,
    useNavigation,
    usePriceFormatter,
    useTranslation
} from "../misc/Hooks";
import {Link} from "react-router-dom";
import {Config} from "../utils/_index";
import {checkPromoPrice, getImage, getPackageCapsulesLength} from "../misc/Helpers";
import {useSelector} from "react-redux";


interface Props {
    storeName: string,
    cart: any,
    categories: [],
    products: [],
    scrollable?: boolean,
    disableFooter?: boolean,
    disableEdit?: boolean,
    onContinue?: void,
}


const CartSummary = (props: Props) => {
    const navigation = useNavigation();
    const t = useTranslation();
    const marketConfig = useMarketConfig();
    const localizeObject = useLocalizeObject();
    const {formatPrice, formatPriceObj} = usePriceFormatter();
    const {
        capsulesValue,
        capsulesNetValue,
        giftValue,
        giftNetValue,
        isCartValid,
        isCartEditable,
        updateItem,
    } = useCart(props.storeName ?? 'ev');
    const store = useSelector(({main, ev}) => ({
        marketConfig: main.marketConfig,
        user: main.user,
        machine: ev.machine,
        promotion: ev.promotion,
    }))


    const getCategories = (_categories) => {
        // const categories = [];
        // _categories.map(promoCat => {
        //     if(!categories.find(c => c.id_category === promoCat.id_category)) {
        //         categories.push(promoCat);
        //     }
        //     else {
        //         const cat = categories.find(c => c.id_category && promoCat.id_category);
        //         cat.products.push(...promoCat.products);
        //     }
        // })
        return Object.values(_categories)
            .sort((a, b) => a.promotion_category_position < b.promotion_category_position ? -1 : 0);
    }


    const categorySubtitleVisible = (cat) => {
        return (cat.category.category_type !== Config.COFFEE_TYPE.GIFT
            && cat.category.category_type !== Config.COFFEE_TYPE.EASY_ORDER_GIFT
            && cat.category.category_type !== Config.COFFEE_TYPE.DELIVERY
            && cat.category.category_type !== Config.COFFEE_TYPE.PLAN_COFFEE
            && cat.category.category_type !== Config.COFFEE_TYPE.PLAN_MACHINE
            && cat.category.category_type !== Config.COFFEE_TYPE.PLAN_WORK_HOME
            && cat.category.category_type !== Config.COFFEE_TYPE.REBATE);
    }


    const promotionSummaryText = localizeObject(store.promotion?.promotion_summary_text);
    const summaryText = promotionSummaryText ?? localizeObject(marketConfig?.summary_text);

    return (
        <div className={`cartSummary ${props.scrollable ? ' -scrollable' : ''}`}>
            {getCategories(props.categories).map(parentCat => {
                let capsulesCount = 0;
                let products = [];
                props.cart.items.map(i => {
                    // const prod = props.products.find(p => p.id_product === i.id
                    //     && (parentCat.id_category === p.id_category
                    //         || parentCat.category.subcategories?.find(c => c.id_category === p.id_category)));

                    const prod = props.products.find(p => p.id_product === i.id
                        && (
                            parentCat.products.find(p => p.id_product === i.id)
                            || parentCat.category.subcategories?.find(c => c.id_category === p.id_category)
                        )
                    )

                    if (prod) {
                        if (parentCat.category.category_type === Config.COFFEE_TYPE.PACKAGE) {
                            capsulesCount += i.value * getPackageCapsulesLength(prod);
                        } else {
                            capsulesCount += i.value * prod.product_package_qty;
                        }

                        products.push(prod);
                    }
                })

                // console.log(parentCat.category.id_category, products)

                products = products.sort((a, b) => a.product_position < b.product_position ? -1 : 0)

                if (parentCat.category.category_type === Config.COFFEE_TYPE.DELIVERY) {
                    return null;
                }

                return !!products.length && (
                    <div key={parentCat.id_category}>
                        <div className="cartSummary__header">
                            <div className="cartSummary__title">{parentCat.category.category_title}</div>
                            {categorySubtitleVisible(parentCat) && (
                                <div className="cartSummary__subtitle">{t('cart.capsules_total', capsulesCount)}</div>
                            )}
                        </div>
                        <div className="cartSummary__items">
                            {products.map(product => {
                                const itemInCart = props.cart.items.find(i => i.id === product.id_product);
                                const hasPromoPrice = checkPromoPrice(
                                    parseInt(product.product_price_promo?.status),
                                    product.product_price_promo?.date_from,
                                    product.product_price_promo?.date_to,
                                );

                                let price = product.product_price;
                                if (parentCat.category.category_type.startsWith('plan_')) {
                                    price = product.product_plan_price;
                                }
                                if (hasPromoPrice) {
                                    price = product.product_price_promo
                                }

                                return (
                                    <div key={product.id_product} className="summaryItem">
                                        <div className="summaryItem__thumb">
                                            <img src={getImage(product.product_gallery)} alt={product.product_title}/>
                                        </div>
                                        <div className="summaryItem__content">
                                            <div className="summaryItem__header">
                                                <h4 className="summaryItem__title">{product.product_title}</h4>
                                                {(parentCat.category.category_type !== Config.COFFEE_TYPE.GIFT
                                                    && parentCat.category.category_type !== Config.COFFEE_TYPE.EASY_ORDER_GIFT
                                                    && parentCat.category.category_type !== Config.COFFEE_TYPE.DELIVERY
                                                    && parentCat.category.category_type !== Config.COFFEE_TYPE.REBATE)
                                                && isCartEditable && !props.disableEdit && (
                                                    <button className="summaryItem__remove"
                                                            onClick={() => updateItem(product.id_product, 0)}>
                                                        <img src={require('../assets/img/icons/close.svg').default}
                                                             alt="X"/>
                                                    </button>
                                                )}
                                            </div>
                                            <div className="summaryItem__footer">
                                                <div className="summaryItem__value">
                                                    {(parentCat.category.category_type !== Config.COFFEE_TYPE.GIFT
                                                        && parentCat.category.category_type !== Config.COFFEE_TYPE.EASY_ORDER_GIFT
                                                        && parentCat.category.category_type !== Config.COFFEE_TYPE.DELIVERY
                                                        && parentCat.category.category_type !== Config.COFFEE_TYPE.PLAN_MACHINE
                                                        && parentCat.category.category_type !== Config.COFFEE_TYPE.PLAN_WORK_HOME
                                                        && parentCat.category.category_type !== Config.COFFEE_TYPE.REBATE) && (
                                                        <PlusMinusButton
                                                            value={itemInCart.value * product.product_package_qty}
                                                            step={product.product_step}
                                                            packageQty={product.product_package_qty}
                                                            max={product.product_max_qty}
                                                            minusDisabled={!isCartEditable || props.disableEdit}
                                                            plusDisabled={itemInCart.value >= product.product_max_qty || !isCartEditable || props.disableEdit}
                                                            onClickMinus={() => updateItem(product.id_product, (itemInCart.value ?? 0) - product.product_step)}
                                                            onClickPlus={() => updateItem(product.id_product, (itemInCart.value ?? 0) + product.product_step)}
                                                            onChange={v => !props.disableEdit ? updateItem(product.id_product, v) : undefined}
                                                        />
                                                    )}
                                                </div>
                                                <div className="summaryItem__price">
                                                    {!!hasPromoPrice && (
                                                        <span className="summaryItem__oldPrice">{formatPriceObj(product.product_price)}</span>
                                                    )}
                                                    {product.product_percentage_rebate ? (
                                                        `-${Number(product.product_percentage_rebate).toFixed(0)}%`
                                                    ) : formatPriceObj(price)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}


            {!!(props.cart.items.length && summaryText) && (
                <p className="cartSummary__info" dangerouslySetInnerHTML={{__html: summaryText}}/>
            )}


            {!props.disableFooter && (props.cart.items.length ? (
                <>

                    <div className="cartBox__total">
                        <span className="cartBox__name">{t('cart.total')}</span>
                        {store.marketConfig?.market_price_type_display === 'net' ? (
                            <span className="cartBox__price">{formatPrice(capsulesNetValue + giftNetValue)}</span>
                        ) : (
                            <span className="cartBox__price">{formatPrice(capsulesValue + giftValue)}</span>
                        )}
                    </div>

                    <div className="cartSummary__submit">
                        <Link to={navigation(props.summaryRoute)}>
                            <Button
                                type="primary"
                                size="sm"
                                label={t('continue')}
                                disabled={!isCartValid}
                                onClick={() => typeof props.onContinue === 'function' ? props.onContinue() : undefined}
                            />
                        </Link>
                    </div>
                </>
            ) : (
                <div className="cartSummary__empty">
                    <h4 className="cartSummary__head">{t('cart.empty.title')}</h4>
                    <p
                        className="cartSummary__text"
                        dangerouslySetInnerHTML={{__html: t(`cart.empty.${store.machine ? 'text2' : 'text'}`)}}
                    />
                </div>
            ))}


        </div>
    );
}


export default CartSummary;
