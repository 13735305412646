import {Redirect, Route, Switch} from "react-router-dom";
import Routes from "../../utils/Routes";
import ResellerUsersFunnel from "./users/Funnel";
import React from "react";
import {useSelector} from "react-redux";
import {useNavigation} from "../../misc/Hooks";

export default function ResellerFunnel(props) {

    const navigation = useNavigation();
    const store = useSelector(({main, reseller}) => ({
        user: main.user,
    }));

    return (
        <Switch>
            <Route
                exact path={[
                Routes.RESELLER_USERS,
                Routes.RESELLER_USERS_USER,
            ]}
            >
                {store.user && store.user.reseller_role !== null ? (
                    <ResellerUsersFunnel/>
                ) : (
                    <Redirect to={navigation(Routes.RESELLER_AUTH)}/>
                )}
            </Route>
        </Switch>
    )
}