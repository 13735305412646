import React from 'react';
import {Link} from "react-router-dom";

interface Props {
    btnType?: string,
    type: 'primary' | 'primary-outline' | 'grey' | 'secondary' | 'error',
    size?: 'sm' | 'md' | 'lg',
    active?: boolean,
    style?: any,
    children?: any,
    label?: string,
    disabled?: boolean,
    onClick: void,
    href?: any,
}


export default (props: Props) => {
    let className = '';
    switch (props.type) {
        case "primary-outline":
            className = '-primary -outline';
            break;
        case "grey":
            className = '-grey';
            break;
        case "secondary":
            className = '-secondary';
            break;
        case "error":
            className = '-error';
            break;
        default:
            className = '-primary';
    }

    switch (props.size) {
        case "sm": {
            className += ' -sm';
            break;
        }
        case "md": {
            className += ' -md';
            break;
        }
        case "lg": {
            className += ' -lg';
            break;
        }
    }


    if(props.active) {
        className += ' -active';
    }

    const ButtonComponent = () => (
        <button
            type={props.btnType ?? 'button'}
            className={`btn ${className}`}
            style={props.style}
            disabled={!!props.disabled}
            onClick={props.onClick}
        >
            {props.label ? (
                <span dangerouslySetInnerHTML={{__html: props.label}}/>
            ) : props.children}
        </button>
    )

    return props.href && !props.disabled ? (
        <Link to={props.href}>
            <ButtonComponent/>
        </Link>
    ) : <ButtonComponent/>
}
