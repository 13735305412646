import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import {CustomCheckbox} from "./inputs/_index";
import {handleCheckboxChange, isCheckboxChecked} from "../../misc/Helpers";
import {Config} from "../../utils/_index";
import {Button} from "../buttons/_index";
import {useTranslation} from "../../misc/Hooks";
import {useSelector} from "react-redux";


export default (props) => {
    const t = useTranslation();
    const {control, errors, setValue, handleSubmit} = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            intensities: props.filter?.intensities ?? [],
            sizes: props.filter?.sizes ?? [],
            aromaticProfiles: props.filter?.aromaticProfiles ?? [],
            coffeeTypes: props.filter?.coffeeTypes ?? [],
        }
    });
    const store = useSelector(({main}) => ({
        coffeeSizes: main.coffeeSizes,
        aromaticProfiles: main.aromaticProfiles,
        coffeeTypes: main.coffeeTypes,
    }))


    const rules = {
        intensities: {},
        sizes: {},
        aromaticProfiles: {},
        coffeeTypes: {},
    }


    useEffect(() => {
        setValue('intensities', props.filter?.intensities ?? []);
        setValue('sizes', props.filter?.sizes ?? []);
        setValue('aromaticProfiles', props.filter?.aromaticProfiles ?? []);
        setValue('coffeeTypes', props.filter?.coffeeTypes ?? []);
    }, [props.filter])


    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>

                <div className="filterOption --mb4">
                    <h3 className="filterOption__title par-2">{t('filter.intensity')}</h3>
                    <Controller
                        name="intensities"
                        control={control}
                        rules={rules.intensities}
                        render={({onChange, value}) => (
                            <div className="filterOption__items">
                                {Config.FILTER.INTENSITY.map(key => (
                                    <CustomCheckbox
                                        key={key}
                                        value={isCheckboxChecked(value, key)}
                                        label={key}
                                        onChange={() => {
                                            onChange(handleCheckboxChange(value, key))
                                            handleSubmit(props.onSubmit)()
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    />
                </div>

                {!!props.cupSizes?.length && (
                    <div className="filterOption --mb4">
                        <h3 className="filterOption__title par-2">{t('filter.size')}</h3>
                        <Controller
                            name="sizes"
                            control={control}
                            rules={rules.sizes}
                            render={({onChange, value}) => (
                                <div className="filterOption__items">
                                    {props.cupSizes.map(key => (
                                        <CustomCheckbox
                                            key={key}
                                            value={isCheckboxChecked(value, key)}
                                            label={store.coffeeSizes?.find(i => i.stack_value === key)?.stack_title ?? key}
                                            onChange={() => {
                                                onChange(handleCheckboxChange(value, key))
                                                handleSubmit(props.onSubmit)()
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                        />
                    </div>
                )}

                {!!props.aromaticProfiles?.length && (
                    <div className="filterOption --mb4">
                        <h3 className="filterOption__title par-2">{t('filter.aromatic_profile')}</h3>
                        <Controller
                            name="aromaticProfiles"
                            control={control}
                            rules={rules.aromaticProfiles}
                            render={({onChange, value}) => (
                                <div className="filterOption__items">
                                    {props.aromaticProfiles.map(key => (
                                        <CustomCheckbox
                                            key={key}
                                            value={isCheckboxChecked(value, key)}
                                            label={store.aromaticProfiles?.find(i => i.stack_value === key)?.stack_title ?? key}
                                            onChange={() => {
                                                onChange(handleCheckboxChange(value, key))
                                                handleSubmit(props.onSubmit)()
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                        />
                    </div>
                )}


                {!!props.coffeeTypes?.length && (
                    <div className="filterOption --mb4">
                        <h3 className="filterOption__title par-2">{t('filter.coffee_type')}</h3>
                        <Controller
                            name="coffeeTypes"
                            control={control}
                            rules={rules.coffeeTypes}
                            render={({onChange, value}) => (
                                <div className="filterOption__items">
                                    {props.coffeeTypes.map(key => (
                                        <CustomCheckbox
                                            key={key}
                                            value={isCheckboxChecked(value, key)}
                                            label={store.coffeeTypes?.find(i => i.stack_value === key)?.stack_title ?? key}
                                            onChange={() => {
                                                onChange(handleCheckboxChange(value, key))
                                                handleSubmit(props.onSubmit)()
                                            }}
                                        />
                                    ))}
                                </div>
                            )}
                        />
                    </div>
                )}



                <div style={{textAlign: 'center'}}>
                    <Button
                        type="secondary"
                        label={t('filter.submit', props.productsCount)}
                        onClick={props.hidePopup}
                    />
                </div>
            </form>
        </>
    );
}
