import {useDispatch} from 'react-redux';
import {Preloader} from "../../misc/_index";
import {CustomerInformationForm} from "../forms/_index";
import {useEvStepNavigation, useTranslation} from "../../misc/Hooks";
import {Button} from "../buttons/_index";
import React from "react";
import * as evReducer from "../../redux/evReducer";


interface Props {
    promotion: any,
    addresses: [],
    customer: any,
    onSubmit: void,
}


const CustomerInformationStep = (props: Props) => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const stepNavigation = useEvStepNavigation();


    const onClickNavToLogin = () => {
        // history.push(navigation(Routes.SHOP_CUSTOMER_IDENTIFICATION))
        dispatch(evReducer.setNespressoMember(undefined));
        dispatch(evReducer.setPromotion(null));
    }

    return (
        <div className="evCIN">


            <p className="par-1 --bold">{t('ev_customer_information.your_details')}</p>


            <div className="--mb4">
                <CustomerInformationForm
                    stepNavigation={stepNavigation}
                    user={props.user}
                    promotion={props.promotion}
                    country={props.country}
                    nespressoMember={props.nespressoMember}
                    addresses={props.addresses}
                    customer={props.customer}
                    error={props.error}
                    loading={props.loading || props.loadingSignIn}
                    loadingAddresses={props.loadingAddresses}
                    onSubmit={props.onSubmit}
                />
            </div>

            {(!!props.loading && !props.loadingSignIn) && (
                <div className="grid">
                    <Preloader/>
                    <div/>
                </div>
            )}

            {!!props.loginFormVisible && (
                <div className="grid --mb4 --pt4">
                    <div>
                        <p className="par-2 --mb3 --error">{t(`error.account_exists_please_log_in`)}</p>
                        {/*<Link to={navigation(Routes.SHOP_CUSTOMER_IDENTIFICATION)}>*/}
                            <Button
                                type="primary"
                                label={t('error.account_exists_please_log_in_button')}
                                onClick={onClickNavToLogin}
                            />
                        {/*</Link>*/}

                        {/*<div className="--mb3">*/}
                        {/*    <SignInForm*/}
                        {/*        error={props.signInError}*/}
                        {/*        loading={props.loading || props.loadingSignIn}*/}
                        {/*        onSubmit={props.onSubmitSignIn}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*{(!!props.loadingSignIn && !props.loading) && <Preloader/>}*/}
                    </div>
                    <div/>
                </div>
            )}

            {!!props.error && (
                <p className="par-2 --mb3 --error">{t(`error.${props.error}`) || props.error}</p>
            )}

        </div>
    );
}


export default CustomerInformationStep;
