import React, {useEffect, useMemo, useState} from "react";
import {usePagination, useTable} from "react-table";
import {MySelect} from "./forms/inputs/_index";
import {SearchForm} from "./forms/_index";
import {useTranslation} from "../misc/Hooks";
import {Preloader} from "../misc/_index";
import {Button} from "./buttons/_index";
import {useHistory, useLocation} from "react-router-dom";


interface Props {
    title: string,
    columns: any,
    data: any,
    maxPage: number,
    getData: void,
    search: void,
    actions?: boolean,
    actionOptions?: any,
    actionVoid?: void,
    results?: any,
    noSearchForm?: boolean,
    name?: string,
    autoFetch?: boolean,
    repeatFetching?: boolean,
    repeatTimeout?: number,
}

let MOUNTED = false

export default (props: Props) => {
    const t = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const urlSearchParams = new URLSearchParams(location.search)
    const paramsName = props.name ?? 'page'
    const paramPage = useMemo(() => urlSearchParams.get(paramsName+'Current') ?? 1, [location])
    const paramPageSize = useMemo(() => urlSearchParams.get(paramsName+'Limit') ?? 10, [location])
    const paramSlug = useMemo(() => urlSearchParams.get(paramsName+'Slug') ?? '', [location])

    const [actionValue, setActionValue] = useState('')

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        pageCount,
        setPageSize
    } = useTable({columns: props.columns, data: props.data, initialState: {pageSize: paramPageSize}}, usePagination);

    useEffect(() => {
        MOUNTED = true
        if(props.autoFetch) {
            autoFetch()
        }

        return () => {
            MOUNTED = false
        }
    }, [])

    const autoFetch = () => {
        const urlSearchParams = new URLSearchParams(window.location.search)
        props.getData(
            urlSearchParams.get(paramsName+'Current') ?? 1,
            urlSearchParams.get(paramsName+'Limit') ?? 10,
            urlSearchParams.get(paramsName+'Slug') ?? ''
        )
            .finally(async () => {
                await new Promise(resolve => setTimeout(resolve, props.repeatTimeout ?? 5000))
                if(props.repeatFetching && MOUNTED) {
                    autoFetch()
                }
            })
    }

    const setParams = (config) => {
        config = {
            pageSize: urlSearchParams.get(paramsName+'Limit') ?? 10,
            page: urlSearchParams.get(paramsName+'Current') ?? 1,
            slug: urlSearchParams.get(paramsName+'Slug') ?? '',
            ...config,
        }

        if(config.page === 1) {
            urlSearchParams.delete(paramsName+'Current')
        } else {
            urlSearchParams.set(paramsName+'Current', config.page)
        }

        if(config.pageSize === 10) {
            urlSearchParams.delete(paramsName+'Limit')
        } else {
            urlSearchParams.set(paramsName+'Limit', config.pageSize)
        }

        if(!config.slug) {
            urlSearchParams.delete(paramsName+'Slug')
        } else {
            urlSearchParams.set(paramsName+'Slug', config.slug)
        }

        setPageSize(config.pageSize)

        history.replace({search: urlSearchParams.toString()})
        props.getData(config.page, config.pageSize)
    }

    return (
        <>
            <div className="boxTable">
                <div className="boxTable__header">
                    <h2 className="par-1">{props.title}</h2>
                </div>
                <div className="boxTable__overflow">
                    <div className={`boxTable__bar`}>
                        <div className="boxTable__barLeft">
                            <div style={{width: '125px', minWidth: '90px'}}>
                                <MySelect
                                    value={{label: paramPageSize, value: paramPageSize}}
                                    label={t('table.results')}
                                    options={[
                                        // {label: '1', value: 1},
                                        {label: '5', value: 5},
                                        {label: '10', value: 10},
                                        {label: '20', value: 20},
                                        {label: '50', value: 50},
                                        {label: '100', value: 100},
                                    ]}
                                    onChange={v => setParams({pageSize: v?.value})}
                                    raw
                                />
                            </div>
                        </div>
                        {!props.noSearchForm && (
                            <div className="boxTable__barRight">
                                <div style={{width: '300px', minWidth: '150px'}}>
                                    <SearchForm
                                        data={paramSlug}
                                        onSubmit={data => setParams({slug: data.slug})}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="boxTable__table">
                        <table {...getTableProps()}>
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => {
                                        if(typeof column.render('Header') === 'object'){
                                            return (
                                                <th>{column.render('Header')}</th>
                                            )
                                        } else {
                                            return (
                                                <th {...column.getHeaderProps()}
                                                    dangerouslySetInnerHTML={{__html: column.render('Header')}}/>
                                            )
                                        }
                                    })}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                )
                            })}
                            {props.loading ? (
                                <tr>
                                    <td colSpan="100" className="-noResults">
                                        <Preloader classes="--mb0"/>
                                    </td>
                                </tr>
                            ) : !page.length && (
                                <tr>
                                    <td colSpan="100" className="-noResults">{t('table.no_results')}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="boxTable__footer">
                    <span>{t('table.page_status', paramPage, paramPageSize, props.results)}</span>

                    <div style={{display: 'flex', alignItems: 'center'}}>

                        {paramPage > 3 && (
                            <button onClick={() => setParams({page: 1})}>
                                <img style={{height: '12px'}}
                                     src={require('../assets/img/icons/arrow-left-double.svg').default} alt=""/>
                            </button>
                        )}

                        <button type="button"
                                onClick={() => paramPage > 1 ? setParams({page: paramPage - 1}) : undefined}>
                            <img style={{height: '12px'}} src={require('../assets/img/icons/arrow-left.svg').default}
                                 alt=""/>
                        </button>
                        <div className="boxTable__pagination">
                            {new Array(props.maxPage).fill().map((_, idx) => idx + 1)
                                .map(i => !!(i >= paramPage - 2 && i <= paramPage + 2) && (
                                    <button type="button" key={i} style={{fontWeight: i === Number(paramPage) ? '700' : '400'}}
                                            onClick={() => setParams({page: i})}>{i}</button>
                                ))}
                        </div>
                        <button type="button"
                                onClick={() => paramPage + 1 <= props.maxPage ? setParams({page: paramPage +1}) : undefined}>
                            <img
                               style={{height: '12px'}}
                               src={require('../assets/img/icons/arrow-right.svg').default}
                               alt=""
                            />
                        </button>
                        {paramPage < props.maxPage - 2 && (
                            <button onClick={() => setParams({page: props.maxPage})}>
                                <img style={{height: '12px'}}
                                     src={require('../assets/img/icons/arrow-right-double.svg').default} alt=""/>
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {props.actions && (
                <>
                    <div className="actionBar">
                        {props.selected.length > 0 && (
                            <>
                                <span className="actionBar__label">{t('for_selected')} ({props.selected.length}):</span>
                                <MySelect
                                    name="action"
                                    label={t('action')}
                                    value={actionValue}
                                    options={props.actionOptions}
                                    onChange={(val) => setActionValue(val)}
                                    noMarginBottom
                                />
                                <Button label={t('take_the_action')} onClick={() => props.actionVoid(actionValue.value)}/>
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
