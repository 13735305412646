
const env = process.env;

export default {
    ENV: env.REACT_APP_ENV,
    PUBLIC_URL: env.REACT_APP_PUBLIC_URL,
    APP_URL: env.REACT_APP_URL,
    API_URL: env.REACT_APP_API_URL,
    APP_STORAGE: env.REACT_APP_STORAGE_URL,
    DEFAULT_LANG: env.REACT_APP_DEFAULT_LANG,
    LANGUAGES: env.REACT_APP_LANGUAGES?.split(' '),
    LANGUAGES_FROM_BACKOFFICE: env.REACT_APP_LANGUAGES_FROM_BACKOFFICE === 'true',
    RESELLER_COUNTRY: env.REACT_APP_RESELLER_COUNTRY,
    GTM_ID: env.REACT_APP_GTM_ID ?? null,
}
