import {AppProvider} from "../../misc/_index";
import {useEffect, useState} from "react";
import * as eTradeReducer from "../../redux/eTradeReducer";
import {useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import PagePreloader from "../../components/PagePreloader";
import {useLocalizeObject, useNavigation} from "../../misc/Hooks";
import {Routes} from "../../utils/_index";
import * as MainActions from "../../actions/MainActions"
import {checkAutoAdd, uuidv4} from "../../misc/Helpers";
import {useSteps} from "../../hooks/modules/etrade";
import {CookieConsent} from "../../components/_index";


export default function Provider(props) {
    const navigation = useNavigation()
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const params = useParams()
    const routeMatch = useRouteMatch()
    const localizeObject = useLocalizeObject()
    const steps = useSteps()
    const store = useSelector(({eTrade}) => ({
        token: eTrade.token,
        data: eTrade.data,
        callbackParam: eTrade.callbackParam,
        machine: eTrade.machine
    }))
    const isStepRoute = steps.map(s => s.route).includes(routeMatch.path) || routeMatch.path === Routes.ETRADE
    const [loading, setLoading] = useState(isStepRoute && (!store.token || params.token))


    useEffect(() => {
        const callbackParam = new URLSearchParams(location.search).get('b')
        if(isStepRoute) {
            init(store.token, params.token, callbackParam, store.callbackParam)
        }

        const root = document.documentElement
        root.style.setProperty('--green', '#8F7247')

        return () => {
            root.style.setProperty('--green', '#2E8561')
        }
    }, [])

    const init = (storedToken, paramToken, callbackParam, storedCallbackParam) => {
        if (callbackParam && paramToken) {
            // console.log('settoken', paramToken)
            setToken(paramToken, callbackParam)
                .then(() => {
                    setLoading(false)
                })
        } else if((paramToken && paramToken === storedToken) || (!paramToken && storedToken)) {
            // console.log('checktoken', storedToken)
            checkToken(storedToken)
                .then(isValid => {
                    setLoading(false)
                    if(isValid) {
                        // stored token valid
                        // console.log('stored token valid')
                    } else {
                        // stored token invalid, redirect to info page
                        // console.log('stored token invalid, redirect to info page')
                        dispatch(eTradeReducer.clearStore())
                        history.push(navigation(Routes.ETRADE_TOKEN_EXPIRED))
                    }
                })
        } else {
            // no param or store token - redirect to info page
            // console.log('no param or store token - redirect to info page', store.token, params.token)
            setLoading(false)
            dispatch(eTradeReducer.clearStore())
            history.push(navigation(Routes.ETRADE_INVALID_TOKEN))
        }
    }

    const setToken = async (token, callbackParam) => {
        await MainActions.getETradePlan(token)
            .then(r => {
                const res = r.data
                if(res.status === 'success') {
                    dispatch(eTradeReducer.clearStore())
                    dispatch(eTradeReducer.setUuid(uuidv4()))
                    dispatch(eTradeReducer.setCallbackParam(callbackParam))
                    updatePlan(token, res.data)
                } else {
                    dispatch(eTradeReducer.clearStore())
                    history.push(navigation(Routes.ETRADE_INVALID_TOKEN))
                }
            })
    }

    const checkToken = async (token) => {
        return await MainActions.getETradePlan(token)
            .then(res => {
                if(res.data.status === 'success') {
                    updatePlan(token, res.data.data)
                    return true
                }
                return false
            })
            .catch(() => false)
    }

    const updatePlan = (token, data) => {
        const products = data.products.reduce((prev, next) => {
            prev.push(...next.products)
            return prev
        }, [data.plan])

        const categories = [
            {
                ...data.plan.category,
                category: data.plan.category,
                products: [data.plan],
                subcategories: []
            },
            ...data.products.map(c => ({
                ...c.category,
                promotion_category_add_auto: true,
                promotion_category_add_auto_min_price: 0,
                promotion_category_add_auto_min_products: 0,
                promotion_category_add_auto_max_price: null,
                promotion_category_add_auto_max_products: null,
                ...c,
            }))
        ]

        const cart = {items: [{id: data.plan.id_product, value: 1}], sum: ''}

        dispatch(eTradeReducer.setPlan(
            token,
            data,
            products,
            categories,
            checkAutoAdd(categories, products, cart, store.machine),
        ))
    }

    return (
        <AppProvider>
            {loading ? <PagePreloader/> : (
              <>
                  {props.children}

                  {!!store.data?.cookie_consent && (
                    <CookieConsent
                      module="eTrade"
                      text={localizeObject(store.data?.cookie_consent?.cookie_consent_text)}
                      url={localizeObject(store.data?.cookie_consent?.cookie_consent_redirect)}
                    />
                  )}
              </>
            )}
        </AppProvider>
    )
}
