import React, {useRef, useState} from 'react';
import Select from "react-select";
import {useTranslation} from "../../../misc/Hooks";


interface Props {
    name: string,
    type: string,
    label: string,
    value: string,
    placeholder?: string,
    options: [],
    error: any,
    nullable?: boolean,
    raw?: boolean,
    onChange: void,
    noMarginBottom?: boolean,
    parseValueToString?: boolean,
}


export default (props: Props) => {
    const t = useTranslation();
    const inputRef = useRef(null);
    const [isFocused, setFocused] = useState(false);


    const handleChange = (e) => {
        if(!e?.value) {
            setFocused(false);
        }

        // setFocused(false);

        if(props.parseValueToString) {
          props.onChange(e.value);
        } else {
          props.onChange(e);
        }
    }

    const options = [];

    if(props.nullable) {
      options.push({
        label: t('none'),
        value: null,
      })
    }

    if(props.options?.length) {
      options.push(...props.options);
    }

    const value = props.parseValueToString ? options.find(o => o.value === props.value) : props.value

    const style = getComputedStyle(document.body)
    const selectStyles = {
        control: (styles) => (
            {
                display: 'flex',
                paddingLeft: props.disabled ? 0 : '0.93rem',
                paddingRight: props.disabled ? 0 : '0.93rem',
                paddingTop: '1.115rem',
                paddingBottom: '0.7rem',
            }
        ),
        singleValue: (styles, {isDisabled}) => ({
            ...styles,
            color: (!props.error && value?.value && !props.raw) ? style.getPropertyValue('--green') :'#000000',
            margin: 0,
        }),
        valueContainer: (provider, state) => (
            {
                ...provider,
                padding: '0',
            }
        ),
        indicatorsContainer: (provider, state) => (
            {
                ...provider,
                padding: 0,
            }
        ),
        indicatorSeparator: (provider, state) => (
            {
                display: 'none'
            }
        ),
        dropdownIndicator: (provider, state) => (
            {
                ...provider,
                padding: 0,
            }
        ),
        placeholder: (provider, state) => (
            {
                ...provider,
                fontSize: '0.875em',
                lineHeight: 1,
                color: '#000000',
            }
        ),
        input: (provider, state) => (
            {
                ...provider,
                padding: 0,
                lineHeight: 1,
            }
        ),
        menu: (provider, state) => (
            {
                ...provider,
                top: 'calc(100% - 0.3rem)',
                fontSize: '0.875em',
                color: '#000000',
                borderColor: 'blue',
                borderRadius: '8px',
            }
        ),
        option: (provider, state) => ({
            ...provider,
            backgroundColor: state.isSelected ? style.getPropertyValue('--green') : undefined,
            '&:hover': {
                backgroundColor: style.getPropertyValue('--green'),
                color: '#ffffff',
            },
        }),
    };

    let formGroupClassNames = '';
    if (value?.value || props.error) {
        formGroupClassNames += ' -active';
    }
    if (isFocused) {
        formGroupClassNames += ' -focused';
    }
    if (props.error) {
        formGroupClassNames += ' -invalid';
    }
    if (!props.error && value?.value && !props.raw) {
        formGroupClassNames += ' -valid';
    }
    if(props.disabled) {
        formGroupClassNames += ' -disabled';
    }
    if(props.noMarginBottom){
        formGroupClassNames += ' --mb0';
    }

    return (
        <div className={`formGroup -select ${formGroupClassNames}`}>
            <label className="formGroup__field">
                {!!props.label && (
                    <span className="formGroup__label">{props.label}</span>
                )}

                <Select
                    ref={inputRef}
                    styles={selectStyles}
                    name={props.name ?? ''}
                    placeholder={props.placeholder ?? null}
                    // openMenuOnFocus={true}
                    isSearchable={typeof props.isSearchable !== 'undefined' ? props.isSearchable : false}
                    value={value?.value ? value : null}
                    options={options}
                    noOptionsMessage={v => t('no_options')}
                    isDisabled={!!props.loading || props.disabled}
                    onChange={handleChange}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    // menuIsOpen={isFocused}
                />

                {!!props.loading && (
                    <span className="formGroup__loading">
                        <img src={require('../../../assets/img/icons/preloader2.gif').default} alt=""/>
                    </span>
                )}

            </label>

            {!!props.error && (
                <span className="formGroup__invalidFeedback">{props.error.message}</span>
            )}
        </div>
    );
}
