import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import * as rmsReducer from "../../../redux/rmsReducer";
import {useNavigation, useRmsStepNavigation} from "../../../misc/Hooks";
import PlanSelectionMS from "../../../components/reseller/PlanSelectionMS";
import {useResellerActions} from "../../../actions/useResellerActions";

let MOUNTED = false;

export const PlanSelectionContainer = (props) => {
    const resellerActions = useResellerActions();
    const dispatch = useDispatch();
    const store = useSelector(({main, rms}) => ({
        country: main.country,
        cart: rms.cart,
        plans: rms.plans,
        machine: rms.machine,
        uuid: rms.uuid,
        serialNumber: rms.serialNumber,
        customer: rms.customer,
        paymentMethod: rms.paymentMethod,
    }))
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        MOUNTED = true;

        if (store.machine) {
            getPlan(store.machine.id_machine);
        }

        return () => {
            MOUNTED = false;
        }
    }, [])


    const getPlan = (machineId) => {
        resellerActions.getPlanByMachineId(machineId, store.country?.id_country).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    setPlans(res.data.plans)
                    dispatch(rmsReducer.setProducts(res.data.plans))
                    dispatch(rmsReducer.setCategories(res.data.categories))
                }
            }
        }).catch(error => {
            console.error('Error', error);
        }).finally(() => {
            if (MOUNTED) {
                setLoading(false);
            }
        })
    }


    return (
        <PlanSelectionMS
            plans={plans}
            loading={loading}
            cart={store.cart}
        />
    )
}
