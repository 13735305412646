import Provider from "./Provider";
import {Route, Switch, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {useSteps} from "../../hooks/modules/stay_home";
import React, {useEffect, useState} from "react";
import {useNavigation, useTranslation} from "../../misc/Hooks";
import {Step} from "./components";
import {Footer, Header} from "../../components/misc/_index";
import {Routes} from "../../utils/_index";
import {useSelector} from "react-redux";
import {OrderFinalizedContainer} from "./containers";
import {GetInTouchModal} from "../../components/modals/_index";
import TokenExpiredScreen from "./screens/TokenExpiredScreen";


export default function Funnel(props) {
    const history = useHistory()
    const navigation = useNavigation()
    const t = useTranslation()
    const match = useRouteMatch()
    const params = useParams()
    const steps = useSteps()
    const store = useSelector(({stayHome}) => ({
        userHasPlan: stayHome.userHasPlan,
    }))
    const [step, setStep] = useState(0)
    const [gitModalVisible, setGitModalVisible] = useState(false);

    useEffect(() => {
        const unlisten = history.listen(p => {
            const idx = steps.findIndex(s => navigation(s.route) === p.pathname);
            if (idx !== -1) {
                window.scrollTo(0, 0)
            }
        })

        return () => {
            unlisten();
        }
    }, [])

    const shopLayoutRoutes = steps.map(s => navigation(s.route))

    return (
        <Provider>
            <div className="app">
                <Route render={({location}) => (
                    shopLayoutRoutes.includes(location.pathname) ? (
                        <Header
                            storeName="stayHome"
                            homeRoute={navigation(Routes.STAY_HOME)}
                            summaryRoute={Routes.STAY_HOME_SUMMARY}
                            cartDisabled={store.userHasPlan}
                            alertsDisabled
                        />
                    ) : (
                        <Header
                            storeName="stayHome"
                            homeRoute={navigation(Routes.STAY_HOME)}
                            cartDisabled
                            alertsDisabled
                        />
                    )
                )}/>


                <div className="main">

                    <div className="wrapper-bg formContainer">

                        <div className="steps">
                            <Switch>

                                <Route exact path={[...steps.map(s => s.route)]}>
                                    {steps.map((s, idx) => (
                                        <Step
                                            key={idx}
                                            index={idx}
                                            currentStep={step}
                                            route={s.route}
                                            title={t(s.title)}
                                            steps={steps}
                                            setCurrentStep={() => setStep(idx)}
                                        >
                                            {s.component}
                                        </Step>
                                    ))}
                                </Route>


                                <Route exact path={Routes.STAY_HOME_TOKEN_EXPIRED} component={TokenExpiredScreen}/>
                                <Route exact path={Routes.STAY_HOME_ORDER_FINALIZED} component={OrderFinalizedContainer}/>

                            </Switch>
                        </div>

                        {shopLayoutRoutes.includes(navigation(match.path)) && (
                            <div className="wrapper">
                                <div className="steps__footer par-4">
                                    <b>{t('need_help')} </b>
                                    <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                                        <b>{t('get_in_touch')}</b>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <Footer/>
            </div>


            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />
        </Provider>
    )
}
