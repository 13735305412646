import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {getThankYouPage} from '../../../actions/MainActions'
import OrderFinalizedScreen from "../screens/OrderFinalizedScreen"
import {GTM} from "../../../misc/_index";
import {useTranslation} from "../../../misc/Hooks";


export default function OrderFinalizedContainer(props) {
    const params = useParams()
    const t = useTranslation()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)


    useEffect(() => {
        document.title = t('order_finalized.title')
        GTM.showPage(t('order_finalized.title'))

        fetchTyp(params.uuid)
            .then(() => setLoading(false))
    }, [])


    const fetchTyp = async (uuid) => {
        await getThankYouPage(uuid)
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    setData(res.data)
                } else {
                    console.warn(res)
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    return (
        <OrderFinalizedScreen
            loading={loading}
            data={data}
        />
    )
}
