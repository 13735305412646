import {useTranslation} from "../../../misc/Hooks";


export default function TokenExpiredScreen(props) {
    const t = useTranslation()

    return (
        <div className="wrapper">
            <div className="--mb4" style={{textAlign: 'center'}}>
                <h1 className="par-1">{t('etrade.token_expired.title')}</h1>
                <p className="par-2">{t('etrade.token_expired.text')}</p>
            </div>
        </div>
    )
}
