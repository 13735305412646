import React, {useMemo, useState} from "react";
import {useNavigation, useTranslation} from "../../../../misc/Hooks";
import {Button} from "../../../../components/buttons/_index";
import {Link} from "react-router-dom";
import {Routes} from "../../../../utils/_index";
import {MyCheckbox} from "../../../../components/forms/inputs/_index";
import {CustomTable} from "./index";

const mapChildrenToOptions = (children) => {
    return children.map(child => ({
        select: child.id_reseller,
        name: `${child.reseller_firstname} <br/> ${child.reseller_lastname}`,
        email: child.reseller_email,
        number: child.reseller_number,
        role: child.reseller_role,
        edit: child.id_reseller,
        subordinates: {
            count_children: child.childs_count,
            id_reseller: child.id_reseller
        },
        shop: child.reseller_shops.shop_title,
        certificate_expiration_date: {
            date: new Date(child.certificate_expired_at).toLocaleString().split(',')[0],
            canRenewal: child.can_recertificate,
            id_reseller: child.id_reseller,
            is_certificate_expired: child.is_certificate_expired
        },
        password_reset: child.id_reseller,
        status: {
            status: child.reseller_status,
            id_reseller: child.id_reseller,
            is_certificate_expired: child.is_certificate_expired
        },
    }))
}

export default function UserTable(props) {
    const t = useTranslation();
    const navigation = useNavigation();

    const [selectedUsers, setSelectedUsers] = useState([])

    const actionsOptions = [
        {label: t('reseller_client.renew_certificates'), value: 'renew_certificates'},
        {label: t('reseller_client.set_the_status.active'), value: 'set_status_active'},
        {label: t('reseller_client.set_the_status.not_active'), value: 'set_status_not_active'}
    ]

    const actionVoid = (type) => {
        if (type === 'set_status_active')
            props.changeStatus(selectedUsers, 1)
        else if (type === 'set_status_not_active')
            props.changeStatus(selectedUsers, 0)
        else if (type === 'renew_certificates')
            props.certificateRenewal(selectedUsers)
        else {
            alert('error')
            return null;
        }
        setSelectedUsers([])
    }

    const onChangeValue = (value) => {
        const arr = [...selectedUsers]
        if (arr.includes(value)) {
            const index = arr.indexOf(value)
            if (index > -1) {
                arr.splice(index, 1)
            }
        } else {
            arr.push(value)
        }
        setSelectedUsers(arr)
    }

    const columns = useMemo(() => [
        {
            Header: () => {
                const selectAll = (val) => {
                    const arr = [];
                    if (val) {
                        props.reseller.childs.data.map((r) => {
                            arr.push(r.id_reseller)
                        })
                        setSelectedUsers(arr)
                    } else {
                        setSelectedUsers([])
                    }
                }
                return (
                    <>
                        <span>{t('reseller_client.select') || t('select')}</span>
                        <MyCheckbox
                            value={selectedUsers?.length === props?.reseller?.childs?.data?.length}
                            onChange={selectAll}
                        />
                    </>
                )
            },
            accessor: 'select',
            Cell: propsItem => {
                return (
                    <MyCheckbox
                        value={selectedUsers.includes(propsItem.value)}
                        onChange={() => onChangeValue(propsItem.value)}
                    />
                )
            }
        },
        {
            Header: t('reseller_client.full_name'),
            accessor: 'name',
            Cell: propsItem => {
                return (
                    <span dangerouslySetInnerHTML={{__html: propsItem.value}}/>
                )
            }
        },
        {Header: t('reseller_client.reseller_number'), accessor: 'number'},
        {Header: t('reseller_client.shop') || t('shop'), accessor: 'shop'},
        {
            Header: t('reseller_client.role') || t('role'),
            accessor: 'role',
            Cell: propsItem => {
                return (
                    <span>{t(`reseller_client.reseller_role.${propsItem.value}`)}</span>
                )
            }
        },
        {Header: t('email'), accessor: 'email'},
        {
            Header: t('reseller_client.certificate_expiration_date'),
            accessor: 'certificate_expiration_date',
            Cell: propsItem => {

                const [renewalStatus, setRenewalStatus] = useState(false)

                const onClickRenewal = () => {
                    setRenewalStatus(true)
                    props.certificateRenewal(propsItem.value.id_reseller)
                }
                return (
                    <>
                        <span
                            className={`--dBlock ${propsItem.value.is_certificate_expired && '--error'}`}>
                            {propsItem.value.date}
                        </span>
                        <Button
                            size="sm"
                            label={renewalStatus ? '...' : t('reseller_client.renewal')}
                            onClick={onClickRenewal}
                            disabled={renewalStatus || !propsItem.value.canRenewal}
                        />
                    </>
                )
            }
        },
        {
            Header: t('reseller_client.password_reset'),
            accessor: 'password_reset',
            Cell: propsItem => {
                const [resetSent, setResetSent] = useState(false)
                const onClickReset = () => {
                    props.resetPassword(propsItem.value)
                    setResetSent(true)
                }
                return (
                    <Button
                        size="sm"
                        label={resetSent ? t('reseller_client.email_sent') || t('table.sent') : t('reset')}
                        onClick={onClickReset}
                        disabled={resetSent}
                    />
                )
            }
        },
        {
            Header: t('reseller_client.subordinates'),
            accessor: 'subordinates',
            Cell: props => {
                return (
                    <Link to={navigation(Routes.RESELLER_USERS, props.value.id_reseller)}>
                        <Button
                            size="sm"
                            label={t('reseller_client.show') || t('show')}
                            disabled={!props.value.count_children}
                        />
                    </Link>
                )
            }
        },
        {
            Header: t('reseller_client.edit') || t('edit'),
            accessor: 'edit',
            Cell: props => {
                return (
                    <Link to={navigation(Routes.RESELLER_USERS_USER, props.value)}>
                        <Button
                            size="sm"
                            label={t('reseller_client.edit') || t('edit')}
                        />
                    </Link>
                )
            }
        },
        {
            Header: t('reseller_client.status') || t('status'),
            accessor: 'status',
            Cell: propsItem => {
                const onChangeStatus = () => {
                    console.log('test')
                    props.changeStatus(propsItem.value.id_reseller, !!Number(propsItem.value.status) ? 0 : 1)
                }
                return (
                    <MyCheckbox
                        green
                        value={!!Number(propsItem.value.status)}
                        onChange={propsItem.value.is_certificate_expired ? () => {} : onChangeStatus}
                    />
                )
            }
        },

    ], [props.reseller, selectedUsers, setSelectedUsers])

    const resellerChildren = useMemo(() => mapChildrenToOptions(props.children ?? []), [props.children ?? []])

    return (
        <CustomTable
            title={""}
            columns={columns}
            data={resellerChildren}
            maxPage={props.maxPage}
            results={props.results}
            loading={props.resellerLoading}
            getData={(cp, ps, s = '-1', c = '-1', v = null) => props.getData(cp, ps, s, c, v)}
            statusFilter
            certificateFilter
            actions
            selected={selectedUsers}
            setSelected={setSelectedUsers}
            changeStatus={props.changeStatus}
            certificateRenewal={props.certificateRenewal}
            actionVoid={actionVoid}
            actionsOptions={actionsOptions}
        />
    )
}
