import {Link} from "react-router-dom";


interface Props {
    route: string,
    disabled?: boolean,
}


export const MyLink = (props: Props) => {
    if(props.disabled) {
        return <span>{props.children}</span>
    }
    return (
        <Link to={props.route}>
            {props.children}
        </Link>
    )
}
