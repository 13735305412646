import Axios from "../utils/Axios";
import {queryParams} from "../misc/Helpers";


export const getById = (id) => {
    return Axios.get(`/promotion/${id}`);
}

export const getAll = (id_country, id_machine, id_shop, purchaseDate, user_logged = 0, promotionId = 0, paramObj = {}) => {
    const params = queryParams(paramObj)
    return Axios.get(`/promotions/${id_country}/${id_machine}/${id_shop}/${user_logged}/${purchaseDate}/${promotionId}${params}`);
}

export const getAllReseller = (id_country, id_machine, id_shop, purchaseDate, user_logged = 0, promotionId = 0, paramObj = {}) => {
    const params = queryParams(paramObj)
    return Axios.get(`/reseller/promotions/${id_country}/${id_machine}/${id_shop}/${user_logged}/${purchaseDate}/${promotionId}${params}`);
}

export const tps = (countryId) => {
    return Axios.get(`/promotions-tps/${countryId}`);
}
