import MachinePlanScreen from "../screens/MachinePlanScreen";
import {useEffect} from "react";
import {GTM} from "../../../misc/_index";
import {useTranslation} from "../../../misc/Hooks";


export default function MachinePlanContainer(props) {
    const t = useTranslation()

    useEffect(() => {
        document.title = t('etrade.machine_plan.title');
        GTM.showPage(t('etrade.machine_plan.title'))
    }, [])

    return (
        <MachinePlanScreen/>
    )
}
