import {useTranslation} from "../../misc/Hooks";
import {SHCodeForm, SHEmailForm} from "../forms/_index";
import {Preloader} from "../../misc/_index";


export const Authorization = (props) => {
    const t = useTranslation();

    return (
        <div className="shAuthorization">

            <div className="grid --mb4">
                <div style={{position: 'relative', height: '100%'}}>
                    <img src={require('../../assets/img/stay-home-img.jpg').default} alt=""
                         className="shAuthorization__img"/>
                </div>
                <div>
                    <p
                        className="par-5"
                        dangerouslySetInnerHTML={{__html: t('sh_authorization.text')}}
                    />
                </div>
            </div>

            {!props.email ? (
                <div className="grid">
                    <div>
                        <p
                            className="par-2"
                            dangerouslySetInnerHTML={{__html: t('sh_authorization.email_info')}}
                        />
                        <div className="--mb4">
                            <SHEmailForm
                                email={props.email}
                                error={props.error}
                                loading={props.loading}
                                onSubmit={props.onSubmit}
                            />
                        </div>
                        {!!props.loading && <Preloader/>}
                    </div>
                    <div/>
                </div>
            ) : (
                <div className="grid">
                    <div>
                        <p
                            className="par-2"
                            dangerouslySetInnerHTML={{__html: t('sh_authorization.code_info')}}
                        />
                        <div className="--mb4">
                            <SHCodeForm
                                code={props.code}
                                error={props.error}
                                loading={props.loading}
                                onSubmit={props.onSubmitCode}
                                onGoBack={props.onGoBack}
                            />
                        </div>
                        {!!props.loading && <Preloader/>}
                    </div>
                    <div/>
                </div>
            )}


        </div>
    )
}
