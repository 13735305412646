import Axios from "../utils/Axios";

export const getAll = (id_country) => {
    return Axios.get(`/shops/${id_country}`);
}

export const getByPosId = (posId) => {
    return Axios.get(`/shop/${posId}`);
}

export const getShopByToken = (token) => {
    return Axios.get(`/shop/token/${token}`);
}
