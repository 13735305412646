import axios from 'axios';
import Env from "./Env";


const instance = axios.create()

instance.defaults.baseURL = Env.API_URL;

instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


export default instance;
