
const SET_UUID = 'of/setUuid';
const SET_RECAPTCHA_TOKEN = 'of/setRecaptchaToken';

const SET_MACHINE = 'of/setMachine';
const SET_PROOF_OF_PURCHASE = 'of/setProofOfPurchase';
const SET_PROOF_OF_PURCHASE_LOGS = 'of/setProofOfPurchaseLogs';
const SET_NESPRESSO_MEMBER = 'of/setNespressoMember';
const SET_NESPRESSO_MEMBER_EXIST = 'of/setNespressoMemberExist';
const SET_PROMOTION = 'of/setPromotion';
const SET_CUSTOMER = 'of/setCustomer';

const SET_PRODUCTS = 'of/setProducts';
const SET_CATEGORIES = 'of/setCategories';
const UPDATE_CART = 'of/updateCart';

const SET_PAID = 'of/setPaid';
const SET_OCR_LOGS = 'of/setOcrLogs';
const SET_ORDER = 'of/setOrder';

const SET_PAYMENT_METHOD = 'of/setPaymentMethod';
const SET_ADDRESSES = 'of/setAddresses';

const SET_FINISH_ORDER = 'of/setFinishOrder';
const SET_FINISH_ORDER_USER = 'of/setFinishOrderUser';
const SET_ORDER_ACCOUNT = 'ev/setOrderAccount';
const SET_CART_QUOTATION_DATA = 'ev/setCartQuotationData';

const CLEAR_STORE = 'of/clearStore';


const initialState = {
    uuid: '',

    testOrder: false,
    serialNumber: null,

    machine: null,
    proofOfPurchase: null,
    proofOfPurchaseLogs: null,
    proofOfPurchaseHistory: null,
    nespressoMember: null,
    nespressoMemberExist: false,
    promotion: null,
    customer: null,

    categories: [],
    products: [],

    cart: {
        items: [],
        sum: '',
    },

    paymentMethod: null,
    addresses: [],
    order: null,
    paid: false,

    recaptchaToken: null,

    ocrLogs: [],

    finishOrder: null,
    finishOrderToken: null,
    finishOrderUser: null,

    orderAccount: null,
    orderAccountLinked: false,

    cartQuotationData: null,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_UUID: return {...state, uuid: action.uuid}
        case SET_RECAPTCHA_TOKEN: return {...state, recaptchaToken: action.recaptchaToken}

        case SET_MACHINE: {
            const res = {
                ...state,
                machine: action.machine,
            }
            if(action.testOrder) {
                res.testOrder = action.testOrder;
            }
            if(action.serialNumber) {
                res.serialNumber = action.serialNumber;
            }
            return res;
        }
        case SET_PROOF_OF_PURCHASE: return {...state, proofOfPurchase: action.proofOfPurchase}
        case SET_PROOF_OF_PURCHASE_LOGS: return {...state, proofOfPurchaseLogs: action.proofOfPurchaseLogs}
        case SET_NESPRESSO_MEMBER: return {...state, nespressoMember: action.nespressoMember}
        case SET_PROMOTION:
            return {
                ...state,
                promotion: action.promotion,
            }
        case SET_CUSTOMER: return {...state, customer: action.customer}

        case SET_PRODUCTS: return {...state, products: action.products}
        case SET_CATEGORIES: return {...state, categories: action.categories}
        case UPDATE_CART: return {...state, cart: action.cart}

        case SET_PAYMENT_METHOD: return {...state, paymentMethod: action.paymentMethod}
        case SET_ADDRESSES: return {...state, addresses: action.addresses}
        case SET_PAID: return {...state, paid: action.paid}
        case SET_OCR_LOGS: return {...state, ocrLogs: action.ocrLogs}
        case SET_ORDER: return {...state, order: action.order}

        case SET_FINISH_ORDER:
            return {
                ...state,
                finishOrderToken: action.finishOrderToken,
                products: action.products,
                categories: action.categories,
                cart: action.cart,
                finishOrder: action.finishOrder,
                // order: null,
                // finishOrderUser: null,
                // uuid: null,
            }
        case SET_FINISH_ORDER_USER: return {...state, finishOrderUser: action.finishOrderUser}
        case SET_ORDER_ACCOUNT:
            return {
                ...state,
                orderAccount: action.orderAccount,
                orderAccountLinked: action.orderAccountLinked,
            }
        case SET_CART_QUOTATION_DATA:
            return {...state, cartQuotationData: action.cartQuotationData}

        case CLEAR_STORE: return {...initialState}

        default: return state;

    }
}


export const setUuid = (uuid) => ({type: SET_UUID, uuid})
export const setRecaptchaToken = (recaptchaToken) => ({type: SET_RECAPTCHA_TOKEN, recaptchaToken})

export const setMachine = (machine, testOrder = null, serialNumber = null) => ({
    type: SET_MACHINE,
    machine,
    testOrder,
    serialNumber
})
export const setNespressoMember = (nespressoMember) => ({type: SET_NESPRESSO_MEMBER, nespressoMember})
export const setNespressoMemberExist = (nespressoMemberExist) => ({type: SET_NESPRESSO_MEMBER_EXIST, nespressoMemberExist})
export const setProofOfPurchase = (proofOfPurchase) => ({type: SET_PROOF_OF_PURCHASE, proofOfPurchase})
export const setProofOfPurchaseLogs = (proofOfPurchaseLogs) => ({type: SET_PROOF_OF_PURCHASE_LOGS, proofOfPurchaseLogs})
export const setPromotion = (promotion) => ({type: SET_PROMOTION, promotion})
export const setCustomer = (customer) => ({type: SET_CUSTOMER, customer})

export const setProducts = (products) => ({type: SET_PRODUCTS, products})
export const setCategories = (categories) => ({type: SET_CATEGORIES, categories})
export const updateCart = (cart) => ({type: UPDATE_CART, cart})

export const setPaymentMethod = (paymentMethod) => ({type: SET_PAYMENT_METHOD, paymentMethod})
export const setAddresses = (addresses) => ({type: SET_ADDRESSES, addresses})

export const setPaid = (paid) => ({type: SET_PAID, paid})
export const setOrder = (order) => ({type: SET_ORDER, order})
export const setOcrLogs = (ocrLogs) => ({type: SET_OCR_LOGS, ocrLogs})
export const setFinishOrder = (finishOrderToken, cart, products, categories, finishOrder) => ({
    type: SET_FINISH_ORDER,
    finishOrderToken,
    cart,
    products,
    categories,
    finishOrder,
})
export const setFinishOrderUser = (finishOrderUser) => ({type: SET_FINISH_ORDER_USER, finishOrderUser})
export const setOrderAccount = (orderAccount, orderAccountLinked) => ({type: SET_ORDER_ACCOUNT, orderAccount, orderAccountLinked})
export const setCartQuotationData = (cartQuotationData) => ({type: SET_CART_QUOTATION_DATA, cartQuotationData})

export const clearStore = () => ({type: CLEAR_STORE})



export default reducer;
