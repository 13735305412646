import React, {useEffect, useMemo, useState} from "react";
import {ButtonIcon} from "../../buttons/_index";
import Table from "../../Table";
import {useTranslation} from "../../../misc/Hooks";
import {Controller, useForm} from "react-hook-form";
import {MyCheckbox, MyDatePicker, MySelect} from "../../forms/inputs/_index";
import {useFormRules} from "../../../hooks";
import {useLocation} from "react-router-dom";
import {GenerateReportModal} from "../../modals/reseller";
import {useSelector} from "react-redux";

const mapResellerToOptions = (resellers) => {
    return resellers.map(reseller => ({
        select: reseller.id_reseller,
        name: `${reseller.reseller_firstname} ${reseller.reseller_lastname}`,
        email: reseller.reseller_email,
        role: reseller.reseller_role,
        shop: reseller.shop_title,
        number: reseller.reseller_number
    }))
}

export default (props) => {
    const t = useTranslation()
    const formRules = useFormRules();
    const location = useLocation();

    const [supervisorOptions, setSupervisorOptions] = useState(null)
    const [keyAccountOptions, setKeyAccountOptions] = useState(null)
    const [shopOptions, setShopOptions] = useState(null)
    const [disableInputs, setDisableInputs] = useState({
        supervisor: false,
        keyAccount: false,
        shop: false,
        role: false,
    })
    const [selectedUsers, setSelectedUsers] = useState([])
    const [currentFilters, setCurrentFilters] = useState([])
    const store = useSelector(({main}) => ({
        user: main.user
    }));
    const urlSearchParams = new URLSearchParams(location.search)


    const {control, errors, setError, setValue, clearErrors, handleSubmit, watch} = useForm({
        mode: 'onChange',
        defaultValues: {
            role: {
                value: 'all',
                label: t('reports_users.filters.all')
            },
            supervisor: {
                value: 'all',
                label: t('reports_users.filters.all')
            },
            key_account: {
                value: 'all',
                label: t('reports_users.filters.all')
            },
            shop: {
                value: 'all',
                label: t('reports_users.filters.all')
            },
            select_all: false,
            with_me: false,
        }
    })

    const onChangeValueUser = (value) => {
        const arr = [...selectedUsers]

        if (arr.includes(value)) {
            const index = arr.indexOf(value)
            if (index > -1) {
                arr.splice(index, 1)
            }
        } else {
            arr.push(value)
        }
        setSelectedUsers(arr)
    }

    const columns = useMemo(() => [
        {
            Header: () => {
                return (
                    <>
                        <span>{t('reseller_client.select') || t('select')}</span>
                    </>
                )
            },
            accessor: 'select',
            Cell: propsItem => {
                return (
                    <MyCheckbox
                        value={selectedUsers.includes(propsItem.value)}
                        onChange={() => onChangeValueUser(propsItem.value)}
                    />
                )
            }
        },
        {
            Header: t('reseller_client.full_name'),
            accessor: 'name',
            Cell: propsItem => {
                return (
                    <span dangerouslySetInnerHTML={{__html: propsItem.value}}/>
                )
            }
        },
        {Header: t('reseller_client.reseller_number'), accessor: 'number'},
        {Header: t('reseller_client.shop') || t('shop'), accessor: 'shop'},
        {
            Header: t('reseller_client.role') || t('role'),
            accessor: 'role',
            Cell: propsItem => {
                return (
                    <span>{t(`reseller_client.reseller_role.${propsItem.value}`)}</span>
                )
            }
        },
        {Header: t('email'), accessor: 'email'},
    ], [props.data.users.data, selectedUsers])

    const resellers = useMemo(() => mapResellerToOptions(props.data.users.data ?? []), [props.data.users.data ?? []])

    const rules = {
        date_from: formRules.make({required: true, dateLessThan: watch('date_to')}),
        date_to: formRules.make({required: true, dateBiggerThan: watch('date_from')}),
    }

    const roleOptions = [
        {
            label: t('reports_users.filters.all'),
            value: 'all'
        },
        {
            label: t('reseller_client.reseller_role.pos_super_user'),
            value: "pos_super_user"
        },
        {
            label: t('reseller_client.reseller_role.pos_user'),
            value: "pos_user"
        },
    ]

    useEffect(() => {
        if (!props.data.filters.loading) {

            if (props.data.filters.data.resellers) {
                const resellers = props.data.filters.data.resellers.map((reseller) => ({
                    value: reseller.id_reseller,
                    label: `${reseller.reseller_firstname} ${reseller.reseller_lastname} [${reseller.shop_title}]`
                }))
                setSupervisorOptions([
                    {
                        value: 'all',
                        label: t('reports_users.filters.all')
                    },
                    ...resellers
                ])
            }

            if (props.data.filters.data.shop_groups) {
                const shopGroups = props.data.filters.data.shop_groups.map((group) => ({
                    value: group.id_shop_group,
                    label: group.shop_group_title
                }));
                setKeyAccountOptions([
                    {
                        value: 'all',
                        label: t('reports_users.filters.all')
                    },
                    ...shopGroups
                ])
            }

            if (props.data.filters.data.shops) {
                const shops = props.data.filters.data.shops.map((shop) => ({
                    value: shop.id_shop,
                    label: shop.shop_title
                }))

                setShopOptions([
                    {
                        value: 'all',
                        label: t('reports_users.filters.all')
                    },
                    ...shops
                ])
            }
        }
    }, [props.data.filters])

    useEffect(() => {
        if (watch('supervisor').value !== 'all') {
            setValue('role', roleOptions.find(r => r.value === 'all'))
            setDisableInputs((old) => ({
                ...old,
                role: true
            }))
        } else {
            setDisableInputs((old) => ({
                ...old,
                role: false
            }))
        }
    }, [watch('supervisor')])

    useEffect(() => {
        if (watch('role').value === 'pos_user') {
            setValue('supervisor', supervisorOptions.find(s => s.value === 'all'))
            setDisableInputs((old) => ({
                ...old,
                supervisor: true
            }))
        } else {
            setDisableInputs((old) => ({
                ...old,
                supervisor: false
            }))
        }
    }, [watch('role')])

    useEffect(() => {
        if (!props.data.filters.loading) {
            const filters = {
                id_reseller: watch('supervisor')?.value !== 'all' ? watch('supervisor')?.value : null,
                id_shop_group: watch('key_account')?.value !== 'all' ? watch('key_account')?.value : null,
                id_shop: watch('shop')?.value !== 'all' ? watch('shop')?.value : null,
            }

            props.data.getFilters(filters)
            // props.data.getUsers(1, )
        }
    }, [watch('supervisor'), watch('key_account'), watch('shop')])

    useEffect(() => {
        const params = {
            id_reseller: watch('supervisor').value !== 'all' ? watch('supervisor').value : null,
            id_shop: watch('shop').value !== 'all' ? watch('shop').value : null,
            id_shop_group: watch('key_account').value !== 'all' ? watch('key_account').value : null,
            role: watch('role').value !== 'all' ? watch('role').value : null,
        }
        setCurrentFilters(params);

        props.data.getUsers(1, urlSearchParams.get('usersLimit') ?? 10, params)
        setSelectedUsers([])
    }, [watch('supervisor'), watch('key_account'), watch('shop'), watch('role')])


    const selectAll = (value) => {
        if (value) {
            setSelectedUsers(props.data.users.allResellers)
        } else {
            setSelectedUsers([])
        }
    }

    const onSubmit = (data) => {
        const postData = {
            dateFrom: data.date_from,
            dateTo: data.date_to,
            withMe: data.with_me,
            selectedUsers,
        }

        props.data.onSubmitReport(postData)
    }

    return (
        <>
            <div className="generatingReport">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h2 className="par-1">{store.user.reseller_role === 'pos_user' ? t('reports_users.filters.title_normal_user') : t('reports_users.filters.title')}</h2>
                    <div className="generatingReport__filters">
                        <div className="generatingReport__grid">
                            <Controller
                                name="date_from"
                                control={control}
                                rules={rules.date_from}
                                render={({onChange, value}) => (
                                    <MyDatePicker
                                        name="date_from"
                                        label={t('reports_users.filters.date_from')}
                                        value={value}
                                        max={new Date()}
                                        loading={props.data.filters.loading}
                                        error={errors.date_from}
                                        onChange={v => {
                                            onChange(v);
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="date_to"
                                control={control}
                                rules={rules.date_to}
                                render={({onChange, value}) => (
                                    <MyDatePicker
                                        name="date_to"
                                        label={t('reports_users.filters.date_to')}
                                        value={value}
                                        min={new Date(watch('date_from'))}
                                        max={new Date()}
                                        loading={props.data.filters.loading}
                                        error={errors.date_to}
                                        onChange={v => {
                                            onChange(v);
                                        }}
                                    />
                                )}
                            />
                            {store.user.reseller_role === 'ka_super_user' && (
                               <>
                                   <Controller
                                       name="supervisor"
                                       control={control}
                                       rules={rules.supervisor}
                                       render={({onChange, value}) => (
                                           <MySelect
                                               name="supervisor"
                                               label={t('reports_users.filters.supervisor')}
                                               value={value}
                                               loading={props.data.filters.loading}
                                               error={errors.supervisor}
                                               options={supervisorOptions}
                                               disabled={disableInputs.supervisor}
                                               onChange={v => {
                                                   onChange(v);
                                               }}
                                           />
                                       )}
                                   />
                                   <Controller
                                       name="key_account"
                                       control={control}
                                       rules={rules.key_account}
                                       render={({onChange, value}) => (
                                           <MySelect
                                               name="key_account"
                                               label={t('reports_users.filters.key_account')}
                                               value={value}
                                               loading={props.data.filters.loading}
                                               error={errors.key_account}
                                               options={keyAccountOptions}
                                               onChange={v => {
                                                   onChange(v);
                                               }}
                                           />
                                       )}
                                   />
                                   <Controller
                                       name="shop"
                                       control={control}
                                       rules={rules.shop}
                                       render={({onChange, value}) => (
                                           <MySelect
                                               name="shop"
                                               label={t('reports_users.filters.shop')}
                                               value={value}
                                               loading={props.data.filters.loading}
                                               error={errors.shop}
                                               options={shopOptions}
                                               onChange={v => {
                                                   onChange(v);
                                               }}
                                           />
                                       )}
                                   />
                                   <Controller
                                       name="role"
                                       control={control}
                                       rules={rules.role}
                                       render={({onChange, value}) => (
                                           <MySelect
                                               name="role"
                                               label={t('reports_users.filters.role')}
                                               value={value}
                                               loading={props.data.filters.loading}
                                               error={errors.role}
                                               options={roleOptions}
                                               disabled={disableInputs.role}
                                               onChange={v => {
                                                   onChange(v);
                                               }}
                                           />
                                       )}
                                   />
                               </>
                            )}



                        </div>
                    </div>
                    {store.user.reseller_role !== 'pos_user' ? (
                        <div className="resMS__table">
                            <Table
                                title={t('reports_users.table.title')}
                                columns={columns}
                                data={resellers}
                                maxPage={props.data.users.maxPage}
                                results={props.data.users.results}
                                loading={props.data.users.loading}
                                getData={(cp, ps, data = currentFilters) => props.data.getUsers(cp, ps, data)}
                                name="users"
                                noSearchForm
                                autoFetch
                                // repeatFetching
                            />
                            <div className="generatingReport__footer">
                                <Controller
                                    name="select_all"
                                    control={control}
                                    rules={rules.select_all}
                                    render={({onChange, value}) => (
                                        <MyCheckbox
                                            name="select_all"
                                            label={t('reports_users.filters.select_all')}
                                            value={props.data.users.allResellers.length === selectedUsers.length}
                                            error={errors.select_all}
                                            onChange={v => {
                                                onChange(v);
                                                selectAll(v);
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="with_me"
                                    control={control}
                                    rules={rules.with_me}
                                    render={({onChange, value}) => (
                                        <MyCheckbox
                                            name="with_me"
                                            label={t('reports_users.filters.generate_with_me')}
                                            value={value}
                                            error={errors.with_me}
                                            onChange={v => {
                                                onChange(v);
                                            }}
                                        />
                                    )}
                                />
                                <ButtonIcon
                                    label={`${t('reports_users.generate')} (${selectedUsers.length + (watch('with_me') ? 1 : 0)})`}
                                    btnType="submit"
                                    style={{marginBottom: '.5rem'}}
                                    icon={require('../../../assets/img/icons/report.svg').default}
                                    disabled={!(selectedUsers.length > 0 || watch('with_me'))}
                                />
                            </div>


                        </div>
                    ) : (
                        <div className="--mb3">
                            <ButtonIcon
                                label={`${t('reports_users.generate_normal_user')}`}
                                btnType="submit"
                                style={{marginBottom: '.5rem'}}
                                icon={require('../../../assets/img/icons/report.svg').default}
                            />
                        </div>
                    )}
                </form>
            </div>
            <GenerateReportModal
                visible={props.data.generateStatus.modalVisible}
                onClose={() => {
                    props.data.setGenerateStatus((old) => ({
                        ...old,
                        modalVisible: false
                    }))
                    setSelectedUsers([])
                    setValue('with_me', false)
                }
                }
                config={props.data.generateStatus}
            />
        </>
    )
}
