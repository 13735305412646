import {Config} from "../../../utils/_index";


/**
 * Returns Stay Home module steps
 * @returns {{component, route: string, title: string}[]}
 */
export default function useSteps() {
    return Config.STAY_HOME_STEPS.filter(step => true)
}
