import React, {useEffect, useState} from "react"
import {useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import * as authorizationActions from "../../../actions/AuthorizationActions"
import * as ofReducer from "../../../redux/ofReducer"
import AuthorizationScreen from "../screens/AuthorizationScreen"
import {useStepsNavigation} from "../../../hooks/modules/order_finish"
import {GTM} from "../../../misc/_index";
import {useTranslation} from "../../../misc/Hooks";
import {useCivilityOptions, useLinkAccount, useOrderData, useStackOptions} from "../../../hooks";
import * as OrderService from "../../../services/OrderService";
import {mapOrderToCustomer} from "../../../misc/Helpers";

let MOUNTED = false

export default function AuthorizationContainer(props) {
    const stepsNavigation = useStepsNavigation()
    const history = useHistory()
    const dispatch = useDispatch()
    const t = useTranslation()
    const store = useSelector(({of}) => ({
        finishOrder: of.finishOrder,
        finishOrderUser: of.finishOrderUser,
        nespressoMember: of.nespressoMember,
        orderAccount: of.orderAccount,
        orderAccountLinked: of.orderAccountLinked,
        customer: of.customer,
    }))
    const linkAccountAction = useLinkAccount()
    const civilityOptions = useCivilityOptions()
    const regionOptions = useStackOptions('regions')
    const professionOptions = useStackOptions('professions')
    const orderData = useOrderData('of')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        MOUNTED = true

        document.title = t('finish_order_account.title')
        GTM.showPage(t('finish_order_account.title'))

        return () => {
            MOUNTED = false
        }
    }, [])


    const handleSubmit = (data) => {
        setLoading(true)
        authorizationActions.signIn(data)
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    if (store.finishOrder?.promotion_type?.startsWith('subscription_') && res.data?.has_subscription) {
                        if (MOUNTED) {
                            setError('customer_already_has_subscription')
                        }
                    } else {
                        if (MOUNTED) {
                            dispatch(ofReducer.setNespressoMember({
                                email: data.login,
                                ...res.data,
                            }))
                            history.push(stepsNavigation.next())
                        }

                    }
                } else {
                    if (res.messages?.length) {
                        if (MOUNTED) {
                            setError(res.messages[0])
                        }
                    }
                    console.warn('Something gone wrong! ', res)
                }
            })
            .catch(err => {
                console.error('Error: ', err)
            })
            .finally(() => {
                if (MOUNTED) {
                    setError(null)
                    setLoading(false)
                }
            })
    }

    const createAccount = async (data) => {
        const customerData = orderData.getCustomer(data)

        let account = store.orderAccount
        let linked = store.orderAccountLinked

        if (!account) {
            const res = await OrderService.createAccount(customerData, data.recaptchaToken)
                .catch(async err => {
                    setError('cannot_create_account')
                })

            if (res) {
                account = res.account
                linked = res.linked
                dispatch(ofReducer.setOrderAccount(account, linked))
            }
        }

        if (account && !linked) {
            linked = !!(await linkAccount(customerData, account))
        }

        if (account && linked) {
            dispatch(ofReducer.setNespressoMember({
                token: account.jwtToken,
                member_id: account.member_number,
            }))
            return account
        }

        return null
    }

    const linkAccount = async (data, account) => {
        const payload = {
            customer: data,
            member_token: account.jwtToken,
        }
        const linkedAccount = await linkAccountAction(payload, account.res.memberNumber)
        if (linkedAccount) {
            dispatch(ofReducer.setOrderAccount(linkedAccount, true))
            return linkedAccount
        }
        dispatch(ofReducer.setOrderAccount(linkedAccount, true))
        return account
    }

    const handleSubmitPassword = async (data) => {
        setLoading(true)
        setError(null)

        const customer = mapOrderToCustomer(store.finishOrder, civilityOptions.options, professionOptions.options, regionOptions.options)
        const account = await createAccount({...customer, ...data})
        if (account) {
            dispatch(ofReducer.setCustomer(customer))
            history.push(stepsNavigation.next());
        } else {
            setLoading(false)
        }
    }

    return (
        <AuthorizationScreen
            error={error}
            loading={loading}
            order={store.finishOrder}
            nespressoMember={store.nespressoMember}
            onSubmit={handleSubmit}
            onSubmitPassword={handleSubmitPassword}
        />
    )
}
