import React from 'react';
import {CustomerRMSForm} from "../forms/_index";
import {Alert} from "../_index";
import {Preloader} from "../../misc/_index";
import {useTranslation} from "../../misc/Hooks";


export default (props) => {
    const t = useTranslation();
    return (
        <div>
            <div className="gridSm -full">
                <div>
                    <div className="--mb4">
                        <CustomerRMSForm
                            country={props.country}
                            customer={props.customer}
                            sending={props.sending}
                            plan={props.plan}
                            nespressoMember={props.nespressoMember}
                            banks={props.banks}
                            verificationCodeVisible={props.verificationCodeVisible}
                            verificationCodeError={props.verificationCodeError}
                            // paymentMethod={props.paymentMethod}
                            loadingBanks={props.loadingBanks}
                            bank={props.bank}
                            error={props.error}
                            bankError={props.bankError}
                            loadingBank={props.loadingBank}
                            onChangeAccountNumber={props.onChangeAccountNumber}
                            onResendCode={props.onResendCode}
                            onSubmit={props.onSubmit}
                        />
                    </div>
                    {!!props.sending && (
                      <div className="grid">
                          <Preloader/>
                          <div/>
                      </div>
                    )}
                    {!!props.error && (
                      <div className="step__nav">
                          <Alert type="error" text={t(`error.${props.error}`)}/>
                      </div>
                    )}
                </div>
                <div/>
            </div>
        </div>
    )
}
