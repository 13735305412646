

const SET_MARKET_CONFIG = 'main/setMarketConfig';
const SET_COUNTRY = 'main/setCountry';

const SET_USER = 'main/setUser';
const SET_USER_TOKEN = 'main/setUserToken';
const CLEAR_USER = 'main/clearUser';

const SET_STACKS = 'main/setStacks';
const SET_CIVILITIES = 'main/setCivilities';

const CLEAR_STORE = 'main/clearStore';



const initialState = {
    country: null,
    marketConfig: null,
    vatModifiers: null,
    display: null,

    recaptchaKey: '',

    user: null,
    userToken: null,

    coffeeTypes: [],
    coffeeSizes: [],
    aromaticProfiles: [],
    civilities: [],
    coffeeLines: [],
    professions: [],
    regions: [],
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_MARKET_CONFIG:
            return {
                ...state,
                marketConfig: action.marketConfig,
                vatModifiers: action.vatModifiers,
                recaptchaKey: action.recaptchaKey,
                display: action.display,
            }
        case SET_COUNTRY: return {...state, country: action.country}

        case SET_USER: return {...state, user: action.user}
        case SET_USER_TOKEN: return {...state, userToken: action.userToken}
        case CLEAR_USER: return {...state, userToken: null, user: null}

        case SET_STACKS:
            const stacks = {}
            Object.keys(action.stacks).map(stackName => {
                let storeStackName = ''
                switch (stackName) {
                    case 'coffee_type': storeStackName = 'coffeeTypes'; break;
                    case 'cup_size': storeStackName = 'coffeeSizes'; break;
                    case 'aromatic_profile': storeStackName = 'aromaticProfiles'; break;
                    case 'civility': storeStackName = 'civilities'; break;
                    case 'coffee_line': storeStackName = 'coffeeLines'; break;
                    case 'profession': storeStackName = 'professions'; break;
                    case 'region': storeStackName = 'regions'; break;
                }
                stacks[storeStackName] = action.stacks[stackName]
            })
            return {...state, ...stacks}

        case SET_CIVILITIES:
            return {
                ...state,
                civilities: action.civilities,
            }

        case CLEAR_STORE: return {...initialState}

        default: return state;

    }
}



export const setMarketConfig = (marketConfig, vatModifiers, recaptchaKey, display) => ({
    type: SET_MARKET_CONFIG,
    marketConfig,
    vatModifiers,
    recaptchaKey,
    display,
})
export const setCountry = (country) => ({type: SET_COUNTRY, country})

export const setUser = (user) => ({type: SET_USER, user})
export const setUserToken = (userToken) => ({type: SET_USER_TOKEN, userToken})
export const clearUser = () => ({type: CLEAR_USER})

export const setStacks = (stacks) => ({type: SET_STACKS, stacks})
export const setCivilities = (civilities) => ({type: SET_CIVILITIES, civilities});

export const clearStore = () => ({type: CLEAR_STORE})


export default reducer;
