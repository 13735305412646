import {useShStepNavigation, useTranslation} from "../../misc/Hooks";
import {Preloader} from "../../misc/_index";
import {Link} from "react-router-dom";
import {Button} from "../buttons/_index";
import React from "react";
import {PlanMachineItem} from "../_index";


export const PlanSelection = (props) => {
    const t = useTranslation();
    const stepNavigation = useShStepNavigation();

    return (
        <div className="shPS">

            {props.loading ? (
                <Preloader/>
            ) : props.plans.length ? (
                <div className="evPS__items">
                    {props.plans.map(p => (
                        <PlanMachineItem
                            key={p.id_product}
                            storeName="stayHome"
                            item={p}
                            selectedPlan={props.plan}
                            cart={props.cart}
                            onChange={props.onChange}
                        />
                    ))}
                </div>
            ) : (
                <div className="--mb4">
                    <p className="par-5 --bold" dangerouslySetInnerHTML={{__html: t('no_plans')}}/>
                </div>
            )}


            <div className="step__nav">
                <Link to={stepNavigation.prev()}>
                    <Button
                        type="primary-outline"
                        label={t('back')}
                    />
                </Link>
                <Link to={stepNavigation.next()}>
                    <Button
                        type="primary"
                        label={t('continue')}
                        disabled={!props.planSelected}
                    />
                </Link>
            </div>

        </div>
    )
}
