
const SET_UUID = 'stayHome/setUuid';
const SET_DATA = 'stayHome/setData';
const SET_TOKEN_AND_CLEAR_STORE = 'stayHome/setTokenAndClearStore';
const SET_TOKEN_AND_EMAIL = 'stayHome/setTokenAndEmail';
const SET_EMAIL = 'stayHome/setEmail';
const SET_CODE = 'stayHome/setCode';
const SET_NESPRESSO_MEMBER = 'stayHome/setNespressoMember';
const SET_NESPRESSO_MEMBER_EXIST = 'stayHome/setNespressoMemberExist';
const SET_USER_HAS_PLAN = 'stayHome/setUserHasPlan';
const SET_CUSTOMER = 'stayHome/setCustomer';
const SET_PRODUCTS = 'stayHome/setProducts';
const SET_CATEGORIES = 'stayHome/setCategories';
const UPDATE_CART = 'stayHome/updateCart';
const SET_PLAN_MACHINE = 'stayHome/setPlanMachine';
const SET_CAN_REGISTER_RESPONSE = 'stayHome/setCanRegisterResponse';
const SET_ORDER = 'stayHome/setOrder';
const SET_ORDER_FINALIZED_DATA = 'stayHome/setOrderFinalizedData';
const SET_PAYMENT_METHOD = 'rms/setPaymentMethod'
const SET_ADDRESSES = 'ev/setAddresses';
const CLEAR_CART = 'stayHome/clearCart';
const CLEAR_STORE = 'stayHome/clearStore';

const initialState = {
    uuid: '',
    data: null,
    token: null,
    email: null,
    code: null,
    nespressoMember: undefined,
    nespressoMemberExist: false,
    userHasPlan: undefined,
    customer: null,
    categories: [],
    products: [],
    cart: {
        items: [],
        sum: '',
    },
    canRegisterResponse: null,
    order: null,
    orderFinalizedData: null,
    paymentMethod: null,
    addresses: [],
}


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_UUID:
            return {...state, uuid: action.uuid}
        case SET_DATA:
            return {...state, data: action.data}
        case SET_EMAIL:
            return {...state, email: action.email}
        case SET_TOKEN_AND_CLEAR_STORE:
            return {...initialState, email: state.email, token: action.token}
        case SET_TOKEN_AND_EMAIL:
            return {...state, token: action.token, email: action.email}
        case SET_CODE:
            if(action.code)
                return {...state, code: action.code}
            return {...initialState, email: state.email, token: state.token, code: action.code}
        case SET_NESPRESSO_MEMBER:
            return {...state, nespressoMember: action.nespressoMember}
        case SET_NESPRESSO_MEMBER_EXIST:
            return {...state, nespressoMemberExist: action.nespressoMemberExist}
        case SET_USER_HAS_PLAN:
            return {...state, userHasPlan: action.userHasPlan}
        case SET_CUSTOMER:
            return {...state, customer: action.customer}
        case SET_PRODUCTS:
            return {...state, products: action.products}
        case SET_CATEGORIES:
            return {...state, categories: action.categories}
        case UPDATE_CART:
            return {...state, cart: action.cart}
        case SET_PLAN_MACHINE:
            return {...state, planMachine: action.planMachine}
        case SET_CAN_REGISTER_RESPONSE:
            return {...state, canRegisterResponse: action.canRegisterResponse}
        case SET_ORDER:
            return {...state, order: action.order}
        case SET_ORDER_FINALIZED_DATA:
            return {...state, orderFinalizedData: action.orderFinalizedData}
        case SET_PAYMENT_METHOD:
            return {...state, paymentMethod: action.paymentMethod}
        case SET_ADDRESSES:
            return {...state, addresses: action.addresses}
        case CLEAR_CART:
            return {...state, cart: {...initialState.cart}}
        case CLEAR_STORE:
            return {...initialState}

        default: return state;
    }
}


export const setUuid = (uuid) => ({type: SET_UUID, uuid})
export const setData = (data) => ({type: SET_DATA, data})
export const setTokenAndClearStore = (token) => ({type: SET_TOKEN_AND_CLEAR_STORE, token});
export const setTokenAndEmail = (token, email) => ({type: SET_TOKEN_AND_EMAIL, token, email});
export const setEmail = email => ({type: SET_EMAIL, email});
export const setCode = code => ({type: SET_CODE, code});
export const setNespressoMember = (nespressoMember) => ({type: SET_NESPRESSO_MEMBER, nespressoMember})
export const setNespressoMemberExist = (nespressoMemberExist) => ({type: SET_NESPRESSO_MEMBER_EXIST, nespressoMemberExist})
export const setUserHasPlan = (userHasPlan) => ({type: SET_USER_HAS_PLAN, userHasPlan})
export const setCustomer = (customer) => ({type: SET_CUSTOMER, customer})
export const setProducts = (products) => ({type: SET_PRODUCTS, products})
export const setCategories = (categories) => ({type: SET_CATEGORIES, categories})
export const updateCart = (cart) => ({type: UPDATE_CART, cart})
export const setPlanMachine = (planMachine) => ({type: SET_PLAN_MACHINE, planMachine})
export const setCanRegisterResponse = (canRegisterResponse) => ({type: SET_CAN_REGISTER_RESPONSE, canRegisterResponse})
export const setOrder = (order) => ({type: SET_ORDER, order})
export const setOrderFinalizedData = (orderFinalizedData) => ({type: SET_ORDER_FINALIZED_DATA, orderFinalizedData})
export const setPaymentMethod = (paymentMethod) => ({type: SET_PAYMENT_METHOD, paymentMethod})
export const setAddresses = (addresses) => ({type: SET_ADDRESSES, addresses})
export const clearStore = () => ({type: CLEAR_STORE})
export const clearCart = () => ({type: CLEAR_CART})


export default reducer;
