import React from 'react';
import BaseModal from "./BaseModal";
import {useTranslation} from "../../misc/Hooks";


interface Props {
    visible: boolean,
    onClose: void,
}

export default (props: Props) => {
    const t = useTranslation();

    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="modal__header">
                {/*<h3 className="modal__title">{t('modal.link_sent.title')}</h3>*/}
            </div>

            <div className="modal__body">
                <p className="par-2 --bold --alignCenter">{t('modal.link_sent.text')}</p>
            </div>
        </BaseModal>
    );
}
