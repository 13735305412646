import {ResellerOptions} from "../_index";
import {useTranslation} from "../../misc/Hooks";


export default (props) => {
    const t = useTranslation();

    return (
        <div className="resHome">
            <ResellerOptions/>
        </div>
    )
}
