import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import MachineMS from "../../../components/reseller/MachineMS";
import * as machineActions from "../../../actions/MachineActions";
import * as rmsReducer from "../../../redux/rmsReducer";
import {useRmsStepNavigation} from "../../../misc/Hooks";
import {uuidv4} from "../../../misc/Helpers";

let MOUNTED = false;

export const MachineContainer = (props) => {
    const history = useHistory();
    const stepNavigation = useRmsStepNavigation();
    const dispatch = useDispatch();
    const store = useSelector(({rms}) => ({
        serialNumber: rms.serialNumber,
        machine: rms.machine,
        testOrder: rms.testOrder,
    }))
    const [serialNumberLoading, setSerialNumberLoading] = useState(null);
    const [serialNumberError, setSerialNumberError] = useState(null);

    useEffect(() => {
        MOUNTED = true;

        return () => {
            MOUNTED = false;
        }
    }, [])


    const validateSerialNumber = (serialNumber) => {
        const formData = {
            serial_number: serialNumber,
        }
        setSerialNumberLoading(true);
        setSerialNumberError(null);
        machineActions.validateSerialNumber(formData).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(rmsReducer.setMachine(res.data.machine, res.data.test_order, {
                        ...store.serialNumber,
                        serial_number: serialNumber,
                    }))
                    setSerialNumberError(null);
                    // setMachine(res.data, null, {serial_number: serialNumber});
                }
            } else {
                if (MOUNTED) {
                    dispatch(rmsReducer.setMachine(null, null, null))
                }
                if (res.messages?.length) {
                    setSerialNumberError(res.messages[0]);
                } else {
                    console.log('Something gone wrong! ', res)
                }
            }
        }).catch(error => {
            console.error('Error', error.message);
        }).finally(() => {
            if (MOUNTED) {
                setSerialNumberLoading(false);
            }
        })
    }


    const handleUploadSerialNumberFile = (base64) => {
        const formData = {base64};
        setSerialNumberLoading(true);
        machineActions.getSerialNumberFromImage(formData).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    if(!res.data.serial_number) {
                        setSerialNumberError('serialnumber_no_readable');
                    }
                    else {
                        setSerialNumberError(null);
                    }
                    dispatch(rmsReducer.setMachine(null, false, res.data))
                }
            } else {
                if (res.messages?.length) {
                    if (MOUNTED) {
                        setSerialNumberError(res.messages[0]);
                    }
                } else {
                    console.log('Something gone wrong! ', res)
                }
            }
        }).catch(error => {
            console.error('Error', error.message);
        }).finally(() => {
            if (MOUNTED) {
                setSerialNumberLoading(false);
            }
        })
    }


    const handleSubmit = (data) => {
        dispatch(rmsReducer.setUuid(uuidv4()))
        history.push(stepNavigation.next())
    }


    const handleRemoveSerialNumberFile = () => {
        dispatch(rmsReducer.setMachine(
            store.machine ?? null,
            store.testOrder ?? null,
            {
                serial_number: store.serialNumber?.serial_number,
            }
        ));
    }


    return (
        <MachineMS
            serialNumber={store.serialNumber}
            plans={store.plans}
            serialNumberError={serialNumberError}
            serialNumberLoading={serialNumberLoading}
            validateSerialNumber={validateSerialNumber}
            onSubmit={handleSubmit}
            onUploadFile={handleUploadSerialNumberFile}
            onRemoveFile={handleRemoveSerialNumberFile}
        />
    )
}
