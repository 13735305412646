import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CoffeeSelectionStep from "../../components/ev/CoffeeSelectionStep";
import {changeCartItem, getCategories} from "../../misc/Helpers";
import {useTranslation} from "../../misc/Hooks";
import * as MainActions from '../../actions/MainActions';
import * as OrderActions from '../../actions/OrderActions';
import * as evReducer from '../../redux/evReducer';
import {Config} from "../../utils/_index";
import {GTM} from "../../misc/_index";
import {easyOrderFrequencies} from "../../actions/OrderActions";
import {useRouteMatch} from "react-router-dom";
import Env from "../../utils/Env";
import {useLanguage, useProducts} from "../../hooks";


interface Props {
}


let MOUNTED = false;


const CoffeeSelectionStepContainer = (props: Props) => {
    const t = useTranslation();
    const match = useRouteMatch()
    const currentLanguage = useLanguage()
    const dispatch = useDispatch();
    const store = useSelector(({ev}) => ({
        promotion: ev.promotion,
        categories: ev.categories,
        products: ev.products,
        cart: ev.cart,
        easyOrderSetup: ev.easyOrderSetup,
        loadingCategories: ev.loadingCategories,
        proofOfPurchase: ev.proofOfPurchase,
        frequencies: ev.frequencies,
        machine: ev.machine,
    }))
    const products = useProducts('ev')
    const [categories, setCategories] = useState([]);
    const [filter, setFilter] = useState(null);
    const [cupSizes, setCupSizes] = useState([]);
    const [aromaticProfiles, setAromaticProfiles] = useState([]);
    const [coffeeTypes, setCoffeeTypes] = useState([]);


    useEffect(() => {
        if (store.categories?.length) {
            setCategories(getCategories(store.categories));
        }
    }, [store.categories])


    useEffect(() => {
        const arrCS = [];
        const arrAP = [];
        const arrCT = [];

        products.getAvailable().map(p => {
            p.product_cup_size?.map(s => {
                s = s.replace(/_image$/, '')
                if (s && !arrCS.find(o => o === s)) {
                    arrCS.push(s)
                }
            })
            if (p.product_aromatic_profile && !arrAP.includes(p.product_aromatic_profile)) {
                arrAP.push(p.product_aromatic_profile);
            }
            if (p.product_coffee_type && !arrCT.includes(p.product_coffee_type)) {
                arrCT.push(p.product_coffee_type);
            }
        })


        const collator = new Intl.Collator(Math, {numeric: true, sensitivity: 'base'})

        setCupSizes(arrCS.sort(collator.compare));
        setAromaticProfiles(arrAP);
        setCoffeeTypes(arrCT);

    }, [store.products, store.categories])


    useEffect(() => {
        MOUNTED = true;
        document.title = t('ev_coffee_selection.title');
        GTM.showPage(t('ev_coffee_selection.title'))

        if (store.promotion?.promotion_type === Config.PROMO_TYPE.EASY_ORDER) {
            getFrequencies();
        }

        return () => {
            MOUNTED = false;
        }
    }, [])


    const getFrequencies = () => {
        OrderActions.easyOrderFrequencies(currentLanguage).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(evReducer.setFrequencies(res.data.data))
                }
            } else {
                console.log('Somethig gone wrong! ', res);
            }
        })
    }


    const handleChange = (id, value) => {
        const cart = changeCartItem(store.promotion?.auto_add, store.cart, store.products, id, value, store.machine, store.proofOfPurchase?.machinePrice);

        if (cart.sum !== store.cart?.sum) {
            dispatch(evReducer.updateCart(cart))
        }
    }


    const handleFilterSubmit = (data) => {
        // const originalCategories = getCategories(store.categories);
        const arr = [...getCategories(store.categories)];
        const intensities = [];
        data.intensities.map(value => {
            if (typeof value === 'string') {
                const [from, to] = value.split('-');
                for (let i = parseInt(from); i <= parseInt(to); i++) {
                    intensities.push(i.toString());
                }
            } else {
                intensities.push(value.toString());
            }
        })


        for (const catIdx in arr) {
            const cat = arr[catIdx];
            let catProducts = cat.products;
            if (intensities?.length) {
                catProducts = catProducts.filter(p => {
                    return p && intensities.includes(p.product_intensity.toString())
                })
            }
            if (data.sizes?.length) {
                catProducts = catProducts.filter(p => {
                    return p?.product_cup_size?.some(s => {
                        return data.sizes.indexOf(s.replace(/_image$/, '')) >= 0
                    })
                })
            }
            if (data.aromaticProfiles?.length) {
                catProducts = catProducts.filter(p => {
                    return p && data.aromaticProfiles.includes(p.product_aromatic_profile)
                })
            }
            if (data.coffeeTypes?.length) {
                catProducts = catProducts.filter(p => {
                    return p && data.coffeeTypes.includes(p.product_coffee_type)
                })
            }
            arr[catIdx] = {
                ...cat,
                products: catProducts,
            }
        }

        setFilter(data);
        setCategories(arr);
    }


    const handleFilterReset = () => {
        setFilter({});
        setCategories(getCategories(store.categories))
    }


    const handleSubmitEasyOrderSetup = (data) => {
        dispatch(evReducer.setEasyOrderSetup(data))
    }


    return (
        <CoffeeSelectionStep
            loading={store.loadingCategories}
            promotion={store.promotion}
            categories={categories}
            cart={store.cart}
            easyOrderSetup={store.easyOrderSetup}
            filter={filter}
            cupSizes={cupSizes}
            aromaticProfiles={aromaticProfiles}
            coffeeTypes={coffeeTypes}
            frequencies={store.frequencies}
            onChange={handleChange}
            onFilterSubmit={handleFilterSubmit}
            onFilterReset={handleFilterReset}
            onSubmitEasyOrderSetup={handleSubmitEasyOrderSetup}
        />
    );
}


export default CoffeeSelectionStepContainer;

