import React from 'react';
import {SerialNumberForm} from "../forms/_index";
import {useTranslation} from "../../misc/Hooks";


export default (props) => {
    const t = useTranslation();
    return (
        <div>
            <SerialNumberForm
                data={props.serialNumber}
                error={props.serialNumberError}
                loading={props.serialNumberLoading}
                validateSerialNumber={props.validateSerialNumber}
                onUploadFile={props.onUploadFile}
                onSubmit={props.onSubmit}
                onRemoveFile={props.onRemoveSerialNumberFile}
            />
            <div className="grid">
                <div>
                    <div className="--mb4">
                        {/*<SerialNumberSimpleForm*/}
                        {/*    data={props.serialNumber}*/}
                        {/*    serialNumberError={props.serialNumberError}*/}
                        {/*    serialNumberLoading={props.serialNumberLoading}*/}
                        {/*    validateSerialNumber={props.validateSerialNumber}*/}
                        {/*    onSubmit={props.onSubmit}*/}
                        {/*/>*/}

                    </div>
                </div>
                <div/>
            </div>
        </div>
    )
}
