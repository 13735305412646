import {Controller, useForm} from "react-hook-form";
import {useCountryConfig, useMarketConfig, useTranslation} from "../../../misc/Hooks";
import {MyCheckbox, MyDatePicker, MyDropArea, MyInput, MyInputMask, MySelect} from "../inputs/_index";
import React, {useEffect, useState} from "react";
import {mapStackToOptions, regexFromString} from "../../../misc/Helpers";
import {useSelector} from "react-redux";
import Patterns from "../../../utils/Patterns";
import {Button} from "../../buttons/_index";
import TpsCartForm from "./TpsCartForm";
import {Preloader} from "../../../misc/_index";
import {PaymentTypeForm} from "../_index";
import {useCivilityOptions, useCountryOptions} from "../../../hooks";
import {isEnabledByMarket} from "../../../utils/MarketConfig";


const TpsForm = (props) => {
    const t = useTranslation();
    const countryConfig = useCountryConfig();
    const marketConfig = useMarketConfig();
    const store = useSelector(({main, tps}) => ({
        civilities: main.civilities,
        companyTitles: tps.companyTitles,
        country: tps.country,
        countries: tps.countries,
    }))

    const countryOptions = useCountryOptions(true);
    const civilityOptions = useCivilityOptions();
    const companyTitlesOptions = mapStackToOptions(store.companyTitles?.length ? store.companyTitles : store.civilities).sort((a,b) => Number(a.value) < Number(b.value) ? -1 : 1)
    const machinePartnerOptions = props.machinePartners.map(s => ({
        label: s.stack_title,
        value: s.stack_value,
    }))
    const [titleOptions, setTitleOptions] = useState(props.data?.companyAddress ? companyTitlesOptions : civilityOptions.options)
    const [customTitleOptions, setCustomTitleOptions] = useState(props.data?.customCompanyAddress ? companyTitlesOptions : civilityOptions.options)
    const [machineModelOptions, setMachineModelOptions] = useState([])
    const [machineColorOptions, setMachineColorOptions] = useState([])
    const [machineOptions, setMachineOptions] = useState([])

    const {control, errors, watch, setError, setValue, clearErrors, trigger, handleSubmit} = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            country: props.data?.country ?? countryOptions.findCountry(store.country?.id_country) ?? null,

            promotion: props.data?.promotion ?? null,
            voucherNumber: props.data?.voucherNumber ?? '',
            notNespressoMachine: !!props.data?.notNespressoMachine,
            serialNumber: props.data?.serialNumber ?? '',
            machinePartner: null,
            machineModel: null,
            machineColor: null,
            machine: null,
            machineSku: props.data?.machineSku ?? '',
            posId: props.data?.posId ?? '',
            machinePrice: props.data?.machinePrice ?? '',
            purchaseDate: props.data?.purchaseDate ?? null,
            receipt: props.data?.receipt ?? null,

            customerNumber: props.data?.customerNumber ?? '',
            companyAddress: !!props.data?.companyAddress,
            title: props.data?.title ? titleOptions.find(o => o.value === props.data.title.value) : null,
            companyName: props.data?.companyName ?? '',
            taxId: props.customer?.taxId ?? '',
            name: props.data?.name ?? '',
            surname: props.data?.surname ?? '',
            postcode: props.data?.postcode ?? '',
            city: props.data?.city ?? '',
            address: props.data?.address ?? '',
            address2: props.data?.address2 ?? '',
            phone: props.customer?.phone ?? '',
            phoneMobile: props.customer?.phoneMobile ?? '',
            phoneHome: props.customer?.phoneHome ?? '',
            email: props.data?.email ?? '',
            reEmail: props.data?.reEmail ?? '',
            lang: props.data?.lang ?? '',

            withCustomAddress: !!props.data?.withCustomAddress,
            customCompanyAddress: !!props.data?.customCompanyAddress,
            customTitle: props.data?.customTitle ? customTitleOptions.find(o => o.value === props.data.customTitle.value) : null,
            customCompanyName: props.data?.customCompanyName ?? '',
            customTaxId: props.customer?.customTaxId ?? '',
            customName: props.data?.customName ?? '',
            customSurname: props.data?.customSurname ?? '',
            customPostcode: props.data?.customPostcode ?? '',
            customCity: props.data?.customCity ?? '',
            customAddress: props.data?.customAddress ?? '',
            customAddress2: props.data?.customAddress2 ?? '',
            customCountry: props.data?.customCountry ?? null,
            customPhone: props.customer?.customPhone ?? '',
            customPhoneMobile: props.customer?.customPhoneMobile ?? '',
            customPhoneHome: props.customer?.customPhoneHome ?? '',

            cart: props.data?.cart ?? [],
        }
    })
    // const promotion = watch('promotion');
    const notNespressoMachine = watch('notNespressoMachine');
    const machinePartner = watch('machinePartner');
    const posId = watch('posId');
    const receipt = watch('receipt');

    const companyAddress = watch('companyAddress');
    const withCustomAddress = watch('withCustomAddress');
    const customCompanyAddress = watch('customCompanyAddress');



    const SERIAL_NUMBER_MASK = {
        pattern: /^[a-zA-Z0-9]{19}|[_]{19}$/,
        inputMask: new Array(19).fill('*').join(''),
    }
    const POST_CODE_MASK = {
        pattern: regexFromString(store.country.country_custom_format?.custom_postcode_format?.pattern) ?? /[0-9]{4}-[a-zA-Z]{2}/,
        inputMask: store.country.country_custom_format?.custom_postcode_format?.mask ?? '9999-aa',
    }
    const PHONE_MASK = {
        pattern: regexFromString(store.country.country_custom_format?.custom_phone_format?.pattern) ?? /\+48 [29][0-9]{2} [0-9]{3} [0-9]{3}/,
        inputMask: store.country.country_custom_format?.custom_phone_format?.mask ?? '+48 999 999 999',
    }
    const PHONE_MOBILE_MASK = {
        pattern: regexFromString(store.country.country_custom_format?.custom_phone_mobile_format?.pattern) ?? /\+48 [29][0-9]{2} [0-9]{3} [0-9]{3}/,
        inputMask: store.country.country_custom_format?.custom_phone_mobile_format?.mask ?? '+48 999 999 999',
    }
    const PHONE_HOME_MASK = {
        pattern: regexFromString(store.country.country_custom_format?.custom_phone_home_format?.pattern) ?? /\+48 [29][0-9]{2} [0-9]{3} [0-9]{3}/,
        inputMask: store.country.country_custom_format?.custom_phone_home_format?.mask ?? '+48 999 999 999',
    }
    const TAX_ID_MASK = {
        pattern: regexFromString(store.country.country_custom_format?.custom_tax_id_format?.pattern) ?? /^./,
        inputMask: store.country.country_custom_format?.custom_tax_id_format?.mask ?? '',
    }


    const rules = {
        required: {required: {value: true, message: t('validation.required')}},

        serialNumber: {
            required: {value: !watch('machine'), message: t('validation.required')},
            pattern: {value: SERIAL_NUMBER_MASK.pattern, message: t('error.serialnumber_to_short')},
        },
        machine: {
            required: {value: !watch('serialNumber'), message: t('validation.required')},
        },
        machineSku: {},

        taxId: {
            pattern: {value: TAX_ID_MASK.pattern, message: t('validation.invalid')}
        },
        title: {required: {value: !companyAddress, message: t('validation.required')}},
        name: {required: {value: !companyAddress, message: t('validation.required')}},
        surname: {required: {value: !companyAddress, message: t('validation.required')}},
        address: {
            required: {value: true, message: t('validation.required')},
            // pattern: {value: Patterns.address, message: t('validation.invalid')},
            maxLength: {
                value: marketConfig.address_max_length,
                message: t('validation.max_length', marketConfig.address_max_length)
            }
        },
        address2: {
            maxLength: {
                value: marketConfig.address_max_length,
                message: t('validation.max_length', marketConfig.address_max_length),
            }
        },
        customerNumber: {
            minLength: {value: 3, message: t('validation.min_length', 3)},
            maxLength: {value: 8, message: t('validation.min_length', 8)},
        },
        postcode: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: POST_CODE_MASK.pattern, message: t('validation.invalid')},
        },
        phone: {
            required: {value: false, message: t('validation.required')},
            pattern: {value: PHONE_MASK.pattern, message: t('validation.invalid')},
        },
        phoneMobile: {
            required: {value: false, message: t('validation.required')},
            pattern: {value: PHONE_MOBILE_MASK.pattern, message: t('validation.invalid')}
        },
        phoneHome: {
            required: {value: false, message: t('validation.required')},
            pattern: {value: PHONE_HOME_MASK.pattern, message: t('validation.invalid')}
        },
        email: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: Patterns.email, message: t('validation.invalid')}
        },
        reEmail: {
            required: {value: true, message: t('validation.required')},
            validate: v => v === watch('email') ? true : t('validation.same_as', t('email')),
        },
        cart: {
            validate: v => v?.length ? true : t('validation.required')
        },
    };


    useEffect(() => {
        if(props.machine) {
            setValue('machineSku', props.machine.machine_sku_number);
            if(watch('machine')?.value !== props.machine.id_machine) {
                setValue('machine', {value: props.machine.id_machine, label: props.machine.machine_title})
            }
            setValue('machinePartner', null)
            setValue('machineModel', null)
            setValue('machineColor', null)
        }
    }, [props.machine])

    useEffect(() => {
        if(props.errorPos) {
            setError('posId', {value: true, message: props.errorPos});
        }
        else {
            clearErrors('posId');
        }
    }, [props.errorPos])

    useEffect(() => {
        if(props.errorPop) {
            setValue('receipt', null);
            setError('receipt', {value: true, message: props.errorPop});
        }
        else {
            clearErrors('receipt');
        }
    }, [props.errorPop])

    useEffect(() => {
        if(props.errorSerialNumber) {
            setError('serialNumber', {value: true, message: t(`error.${props.errorSerialNumber}`)});
        } else {
            clearErrors('serialNumber');
        }
    }, [props.errorSerialNumber])

    useEffect(() => {
        setValue('title', null);
        setTitleOptions(companyAddress ? companyTitlesOptions : civilityOptions.options)
    }, [companyAddress])

    useEffect(() => {
        setValue('customTitle', null);
        setCustomTitleOptions(customCompanyAddress ? companyTitlesOptions : civilityOptions.options)
    }, [customCompanyAddress])


    const onBlurPosId = (e) => {
        const value = e.target.value;
        if(value && !props.loadingPos) {
            props.getShop(value)
        }
    }

    const onChangeCountry = (v, callback) => {
        props.onChangeCountry(v.value)
        callback(v);
    }

    const handleChangePromotion = (v, callback) => {
        props.onChangePromotion(v.value);
        setValue('cart', [])
        callback(v);
    }

    const handleChangeSerialNumber = (e, callback) => {
        const value = e?.target?.value
        if(value?.match(SERIAL_NUMBER_MASK.pattern) && value.replace(/_/g, '')) {
            props.onChangeSerialNumber(value);
        }
        callback(e);
    }

    const handleChangeReceipt = (v, callback) => {
        if(v) {
            props.onUploadReceipt(v);
        }
        callback(v)
    }

    const handleChangeMachinePartner = (v, callback) => {
        if(v) {
            const machines = props.machines.filter(m => m.machine_partner === v.value);

            const modelOptions = props.machineModels
                .filter(s => machines.find(m => m.machine_model === s.stack_value))
                .map(s => ({label: s.stack_title, value: s.stack_value}))

            setMachineModelOptions(modelOptions);
            setValue('machineModel', null)
            setValue('machineColor', null)
            setValue('machine', null)
            setValue('machineSku', '')
        }
        callback(v)
    }

    const handleChangeMachineModel = (v, callback) => {
        if(v) {
            const machines = props.machines
                .filter(m => m.machine_partner === watch('machinePartner')?.value)
                .filter(m => m.machine_model === v.value);

            const colorOptions = props.machineColors
                .filter(s => machines.find(m => m.machine_color === s.stack_value))
                .map(s => ({label: s.stack_title, value: s.stack_value}))

            setMachineColorOptions(colorOptions);
            setValue('machineColor', null)
            setValue('machine', null)
            setValue('machineSku', '')
        }
        callback(v)
    }

    const handleChangeMachineColor = (v, callback) => {
        if(v) {
            const machines = props.machines
                .filter(m => m.machine_partner === watch('machinePartner')?.value)
                .filter(m => m.machine_model === watch('machineModel')?.value)
                .filter(m => m.machine_color === v.value)
                .map(m => ({
                    label: `${m.machine_title} - ${m.machine_sku_number}`,
                    value: m.id_machine,
                }))

            setMachineOptions(machines);
            setValue('machine', null)
            setValue('machineSku', '')
        }
        callback(v)
    }

    const handleChangeMachine = async (v, callback) => {
        if(v) {
            const machine = props.machines.find(m => m.id_machine === v.value);
            if(machine) {
                setValue('serialNumber', '');
                setValue('machineSku', machine.machine_sku_number)
                if(watch('serialNumber')) {
                    await trigger('serialNumber')
                }
            }
        }
        callback(v)
    }


    return (
        <>
            <form className="tpsForm" onSubmit={handleSubmit(props.onSubmit)}>

                <div className="tpsForm__grid --mb4">
                    <div>
                        <h2 className="par-1 --bold">{t('tps.select_country')}</h2>
                        <Controller
                            name="country"
                            control={control}
                            rules={rules.country}
                            render={({onChange, value}) => (
                                <MySelect
                                    name="country"
                                    label={t('country')}
                                    value={value}
                                    options={countryOptions.countryOptions}
                                    error={errors.country}
                                    onChange={v => onChangeCountry(v, onChange)}
                                />
                            )}
                        />
                    </div>
                </div>



                {!props.loading ? (
                    <>
                        <div className="tpsForm__grid">

                            <div>
                                <h2 className="par-1 --bold">{t('tps.receipt_info')}</h2>
                                <Controller
                                    name="promotion"
                                    control={control}
                                    rules={rules.required}
                                    render={({onChange, value}) => (
                                        <MySelect
                                            name="promotion"
                                            label={t('tps.promotion')}
                                            value={value}
                                            options={mapPromotionsToOptions(props.promotions)}
                                            error={errors.promotion}
                                            onChange={v => handleChangePromotion(v, onChange)}
                                            loading={props.loadingCategories}
                                        />
                                    )}
                                />
                                <Controller
                                    name="voucherNumber"
                                    control={control}
                                    render={({onChange, value}) => (
                                        <MyInput
                                            name="voucherNumber"
                                            label={t('tps.voucher_number')}
                                            value={value}
                                            error={errors.voucherNumber}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                                <Controller
                                    name="notNespressoMachine"
                                    control={control}
                                    render={({onChange, value}) => (
                                        <div className="--mb2">
                                            <MyCheckbox
                                                name="notNespressoMachine"
                                                label={t('tps.not_nespresso_machine')}
                                                value={value}
                                                error={errors.notNespressoMachine}
                                                onChange={onChange}
                                            />
                                        </div>
                                    )}
                                />

                                {!notNespressoMachine && (
                                    <>
                                        {!machinePartner && (
                                            <Controller
                                                name="serialNumber"
                                                control={control}
                                                rules={rules.serialNumber}
                                                render={({onChange, value}) => (
                                                    <MyInputMask
                                                        name="serialNumber"
                                                        label={t('tps.serial_number')}
                                                        mask={SERIAL_NUMBER_MASK.inputMask}
                                                        value={value}
                                                        loading={props.loadingSerialNumber}
                                                        error={errors.serialNumber}
                                                        onChange={e => handleChangeSerialNumber(e, onChange)}
                                                    />
                                                )}
                                            />
                                        )}
                                        <Controller
                                            name="machinePartner"
                                            control={control}
                                            render={({onChange, value}) => (
                                                <MySelect
                                                    name="machinePartner"
                                                    label={t('tps.machine_partner')}
                                                    value={value}
                                                    options={machinePartnerOptions}
                                                    // loading={props.loadingSerialNumber}
                                                    error={errors.machinePartner}
                                                    onChange={v => handleChangeMachinePartner(v, onChange)}
                                                    isSearchable
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="machineModel"
                                            control={control}
                                            render={({onChange, value}) => (
                                                <MySelect
                                                    name="machineModel"
                                                    label={t('tps.machine_model')}
                                                    value={value}
                                                    options={machineModelOptions}
                                                    // loading={props.loadingSerialNumber}
                                                    error={errors.machineModel}
                                                    onChange={v => handleChangeMachineModel(v, onChange)}
                                                    isSearchable
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="machineColor"
                                            control={control}
                                            render={({onChange, value}) => (
                                                <MySelect
                                                    name="machineColor"
                                                    label={t('tps.machine_color')}
                                                    value={value}
                                                    options={machineColorOptions}
                                                    // loading={props.loadingSerialNumber}
                                                    error={errors.machineColor}
                                                    onChange={v => handleChangeMachineColor(v, onChange)}
                                                    isSearchable
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="machine"
                                            control={control}
                                            rules={rules.machine}
                                            render={({onChange, value}) => (
                                                <MySelect
                                                    name="machine"
                                                    label={t('tps.machine')}
                                                    value={value}
                                                    options={machineOptions}
                                                    // loading={props.loadingSerialNumber}
                                                    error={errors.machine}
                                                    onChange={v => handleChangeMachine(v, onChange)}
                                                    isSearchable
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="machineSku"
                                            control={control}
                                            rules={rules.machineSku}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="machineSku"
                                                    label={t('tps.machine_sku')}
                                                    value={value}
                                                    error={errors.machineSku}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="posId"
                                            control={control}
                                            rules={rules.required}
                                            render={({onChange, onBlur, value}) => (
                                                <MyInput
                                                    name="posId"
                                                    label={t('tps.pos_id')}
                                                    value={value}
                                                    error={errors.posId}
                                                    loading={props.loadingPos}
                                                    onChange={onChange}
                                                    onBlur={onBlurPosId}
                                                />
                                            )}
                                        />
                                        {!!props.shop && props.shop.shop_pos === posId && (
                                            <p className="par-2 --mb3" style={{paddingLeft: '0.75rem'}}>
                                                <strong>{props.shop.shop_group_title} - {props.shop.shop_title}</strong> <br/>
                                                {props.shop.shop_address} <br/>
                                                {props.shop.shop_postcode} {props.shop.shop_city}
                                            </p>
                                        )}

                                        <Controller
                                            name="machinePrice"
                                            control={control}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="machinePrice"
                                                    label={t('tps.machine_price')}
                                                    value={value}
                                                    error={errors.machinePrice}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </>
                                )}

                                <div className="--mb3">
                                    <Controller
                                        name="purchaseDate"
                                        control={control}
                                        rules={rules.required}
                                        render={({onChange, value}) => (
                                            <MyDatePicker
                                                name="purchaseDate"
                                                label={t('tps.purchase_date')}
                                                value={value}
                                                max={new Date()}
                                                error={errors.purchaseDate}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </div>

                                <div style={{paddingRight: '1rem'}}>
                                    <Controller
                                        name="receipt"
                                        control={control}
                                        rules={rules.required}
                                        render={({onChange, value}) => (
                                            <MyDropArea
                                                placeholder={t('tps.receipt')}
                                                acceptFiles={"image/png,image/jpeg,image/bmp,application/pdf"}
                                                value={value}
                                                error={errors.receipt}
                                                onChange={v => handleChangeReceipt(v, onChange)}
                                            />
                                        )}
                                    />
                                </div>
                                {!receipt && (
                                    <p
                                        className="uploadBox__caption --mb1"
                                        dangerouslySetInnerHTML={{__html: t('ev_machine_registration.pop_text2')}}
                                    />
                                )}

                            </div>


                            <div>
                                <h2 className="par-1 --bold">{t('tps.delivery_info')}</h2>
                                <p className="par-2" dangerouslySetInnerHTML={{__html: t('tps.delivery_info_text')}}/>
                                <Controller
                                    name="customerNumber"
                                    control={control}
                                    rules={rules.customerNumber}
                                    render={({onChange, value}) => (
                                        <MyInput
                                            name="customerNumber"
                                            label={t('tps.customer_number')}
                                            value={value}
                                            error={errors.customerNumber}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="companyAddress"
                                    control={control}
                                    render={({onChange, value}) => (
                                        <div className="--mb2">
                                            <MyCheckbox
                                                name="companyAddress"
                                                label={t('tps.company_address')}
                                                value={value}
                                                error={errors.companyAddress}
                                                onChange={onChange}
                                            />
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={rules.title}
                                    render={({onChange, value}) => (
                                        <MySelect
                                            name="title"
                                            label={t('title')}
                                            value={value}
                                            options={titleOptions}
                                            error={errors.title}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                {companyAddress && (
                                    <>
                                        <Controller
                                            name="companyName"
                                            control={control}
                                            rules={rules.required}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="companyName"
                                                    label={t('tps.company_name')}
                                                    value={value}
                                                    error={errors.companyName}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                        {!!marketConfig?.tax_id && (
                                            <Controller
                                                name="taxId"
                                                control={control}
                                                rules={rules.taxId}
                                                render={({onChange, value}) => (
                                                    <MyInputMask
                                                        name="taxId"
                                                        label={t('tax_id')}
                                                        mask={TAX_ID_MASK.inputMask}
                                                        value={value}
                                                        error={errors.taxId}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        )}
                                    </>
                                )}


                                <div className="grid">
                                    <div className="col">
                                        <Controller
                                            name="name"
                                            control={control}
                                            rules={rules.name}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="name"
                                                    label={t('name')}
                                                    value={value}
                                                    error={errors.name}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col">
                                        <Controller
                                            name="surname"
                                            control={control}
                                            rules={rules.surname}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="surname"
                                                    label={t('surname')}
                                                    value={value}
                                                    error={errors.surname}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="grid">
                                    <div className="col">
                                        <Controller
                                            name="postcode"
                                            control={control}
                                            rules={rules.postcode}
                                            render={({onChange, value}) => (
                                                <MyInputMask
                                                    name="postcode"
                                                    label={t('postcode')}
                                                    mask={POST_CODE_MASK.inputMask}
                                                    value={value}
                                                    error={errors.postcode}
                                                    onChange={onChange}
                                                    forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col">
                                        <Controller
                                            name="city"
                                            control={control}
                                            rules={rules.required}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="city"
                                                    label={t('city')}
                                                    value={value}
                                                    error={errors.city}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>


                                <Controller
                                    name="address"
                                    control={control}
                                    rules={rules.address}
                                    render={({onChange, value}) => (
                                        <MyInput
                                            name="address"
                                            label={t('address_line_1')}
                                            value={value}
                                            error={errors.address}
                                            onChange={onChange}
                                            maxLength={rules.address.maxLength?.value}
                                        />
                                    )}
                                />

                                {!!marketConfig?.address_line2_enabled && (
                                    <Controller
                                        name="address2"
                                        control={control}
                                        rules={rules.address2}
                                        render={({onChange, value}) => (
                                            <MyInput
                                                name="address2"
                                                label={t('address_line_2')}
                                                value={value}
                                                error={errors.address2}
                                                onChange={onChange}
                                                maxLength={rules.address2.maxLength?.value}
                                            />
                                        )}
                                    />
                                )}

                                <Controller
                                    name="phone"
                                    control={control}
                                    rules={rules.phone}
                                    render={({onChange, value}) => Number(countryConfig.custom_format?.custom_phone_format?.mask_status) ? (
                                        <MyInputMask
                                            name="phone"
                                            label={t('tps.phone')}
                                            mask={PHONE_MASK.inputMask}
                                            formatChars={PHONE_MASK.formatChars}
                                            value={value}
                                            error={errors.phone}
                                            onChange={onChange}
                                        />
                                    ) : (
                                        <MyInput
                                            name="phone"
                                            label={t('tps.phone')}
                                            value={value}
                                            error={errors.phone}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="phoneHome"
                                    control={control}
                                    rules={rules.phoneHome}
                                    render={({onChange, value}) => Number(countryConfig.custom_format?.custom_phone_home_format?.mask_status) ? (
                                        <MyInputMask
                                            name="phoneHome"
                                            label={t('tps.phone_home')}
                                            mask={PHONE_HOME_MASK.inputMask}
                                            formatChars={PHONE_HOME_MASK.formatChars}
                                            value={value}
                                            error={errors.phoneHome}
                                            onChange={onChange}
                                        />
                                    ) : (
                                        <MyInput
                                            name="phoneHome"
                                            label={t('tps.phone_home')}
                                            value={value}
                                            error={errors.phoneHome}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="phoneMobile"
                                    control={control}
                                    rules={rules.phoneMobile}
                                    render={({onChange, value}) => Number(countryConfig.custom_format?.custom_phone_mobile_format?.mask_status) ? (
                                        <MyInputMask
                                            name="phoneMobile"
                                            label={t('tps.phone_mobile')}
                                            mask={PHONE_MOBILE_MASK.inputMask}
                                            formatChars={PHONE_MOBILE_MASK.formatChars}
                                            value={value}
                                            error={errors.phoneMobile}
                                            onChange={onChange}
                                        />
                                    ) : (
                                        <MyInput
                                            name="phoneMobile"
                                            label={t('tps.phone_mobile')}
                                            value={value}
                                            error={errors.phoneMobile}
                                            onChange={onChange}
                                        />
                                    )}
                                />


                                <div className="grid">
                                    <div className="col">
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={rules.email}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="email"
                                                    label={t('email')}
                                                    value={value}
                                                    error={errors.email}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col">
                                        <Controller
                                            name="reEmail"
                                            control={control}
                                            rules={rules.reEmail}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="reEmail"
                                                    label={t('re_email')}
                                                    value={value}
                                                    error={errors.reEmail}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>


                                <p className="par-4">{t('tps.lang_info')}</p>
                                <Controller
                                    name="lang"
                                    control={control}
                                    rules={rules.required}
                                    render={({onChange, value}) => (
                                        <MySelect
                                            name="lang"
                                            label={t('lang')}
                                            value={value}
                                            options={countryOptions.languageOptions}
                                            error={errors.lang}
                                            onChange={onChange}
                                        />
                                    )}
                                />


                                <Controller
                                    name="withCustomAddress"
                                    control={control}
                                    render={({onChange, value}) => (
                                        <div className="--mb2">
                                            <MyCheckbox
                                                name="withCustomAddress"
                                                label={t('tps.with_custom_address')}
                                                value={value}
                                                error={errors.withCustomAddress}
                                                onChange={onChange}
                                            />
                                        </div>
                                    )}
                                />


                                {withCustomAddress && (
                                    <div className="--mb4">
                                        <Controller
                                            name="customCompanyAddress"
                                            control={control}
                                            render={({onChange, value}) => (
                                                <div className="--mb2">
                                                    <MyCheckbox
                                                        name="customCompanyAddress"
                                                        label={t('tps.company_address')}
                                                        value={value}
                                                        error={errors.customCompanyAddress}
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            )}
                                        />

                                        <Controller
                                            name="customTitle"
                                            control={control}
                                            rules={rules.required}
                                            render={({onChange, value}) => (
                                                <MySelect
                                                    name="customTitle"
                                                    label={t('title')}
                                                    value={value}
                                                    options={customCompanyAddress ? customTitleOptions : civilityOptions.options}
                                                    error={errors.customTitle}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                        {customCompanyAddress && (
                                            <>
                                                <Controller
                                                    name="customCompanyName"
                                                    control={control}
                                                    rules={rules.required}
                                                    render={({onChange, value}) => (
                                                        <MyInput
                                                            name="customCompanyName"
                                                            label={t('tps.company_name')}
                                                            value={value}
                                                            error={errors.customCompanyName}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />

                                                {!!marketConfig?.company_tax_id && (
                                                    <Controller
                                                        name="customTaxId"
                                                        control={control}
                                                        rules={rules.taxId}
                                                        render={({onChange, value}) => (
                                                            <MyInputMask
                                                                name="customTaxId"
                                                                label={t('tax_id')}
                                                                mask={TAX_ID_MASK.inputMask}
                                                                value={value}
                                                                error={errors.customTaxId}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            </>
                                        )}


                                        <div className="grid">
                                            <div className="col">
                                                <Controller
                                                    name="customName"
                                                    control={control}
                                                    rules={rules.required}
                                                    render={({onChange, value}) => (
                                                        <MyInput
                                                            name="customName"
                                                            label={t('name')}
                                                            value={value}
                                                            error={errors.customName}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col">
                                                <Controller
                                                    name="customSurname"
                                                    control={control}
                                                    rules={rules.required}
                                                    render={({onChange, value}) => (
                                                        <MyInput
                                                            name="customSurname"
                                                            label={t('surname')}
                                                            value={value}
                                                            error={errors.customSurname}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="grid">
                                            <div className="col">
                                                <Controller
                                                    name="customPostcode"
                                                    control={control}
                                                    rules={rules.required}
                                                    render={({onChange, value}) => (
                                                        <MyInputMask
                                                            name="customPostcode"
                                                            label={t('postcode')}
                                                            mask={POST_CODE_MASK.inputMask}
                                                            value={value}
                                                            error={errors.customPostcode}
                                                            onChange={onChange}
                                                            forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col">
                                                <Controller
                                                    name="customCity"
                                                    control={control}
                                                    rules={rules.required}
                                                    render={({onChange, value}) => (
                                                        <MyInput
                                                            name="customCity"
                                                            label={t('city')}
                                                            value={value}
                                                            error={errors.customCity}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>


                                        <Controller
                                            name="customAddress"
                                            control={control}
                                            rules={rules.address}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="customAddress"
                                                    label={t('address_line_1')}
                                                    value={value}
                                                    error={errors.customAddress}
                                                    onChange={onChange}
                                                    maxLength={rules.address.maxLength?.value}
                                                />
                                            )}
                                        />

                                        {!!marketConfig?.address_line2_enabled && (
                                            <Controller
                                                name="customAddress2"
                                                control={control}
                                                rules={rules.address2}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="customAddress2"
                                                        label={t('address_line_2')}
                                                        value={value}
                                                        error={errors.customAddress2}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        )}

                                        {!!marketConfig?.other_delivery_phone_enabled && (
                                            <>
                                                <Controller
                                                    name="customPhone"
                                                    control={control}
                                                    rules={rules.phone}
                                                    render={({onChange, value}) => (
                                                        <MyInputMask
                                                            name="customPhone"
                                                            label={t('phone')}
                                                            mask={PHONE_MASK.inputMask}
                                                            formatChars={PHONE_MASK.formatChars}
                                                            value={value}
                                                            error={errors.customPhone}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />

                                                {!!Number(countryConfig.custom_format?.custom_phone_mobile_format?.status) && (
                                                    <Controller
                                                        name="customPhoneMobile"
                                                        control={control}
                                                        rules={rules.phoneMobile}
                                                        render={({onChange, value}) => (
                                                            <MyInputMask
                                                                name="customPhoneMobile"
                                                                label={t('phone_mobile')}
                                                                mask={PHONE_MOBILE_MASK.inputMask}
                                                                formatChars={PHONE_MOBILE_MASK.formatChars}
                                                                value={value}
                                                                error={errors.customPhoneMobile}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                )}

                                                {!!Number(countryConfig.custom_format?.custom_phone_home_format?.status) && (
                                                    <Controller
                                                        name="customPhoneHome"
                                                        control={control}
                                                        rules={rules.phoneHome}
                                                        render={({onChange, value}) => (
                                                            <MyInputMask
                                                                name="customPhoneHome"
                                                                label={t('phone_home')}
                                                                mask={PHONE_HOME_MASK.inputMask}
                                                                formatChars={PHONE_HOME_MASK.formatChars}
                                                                value={value}
                                                                error={errors.customPhoneHome}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            </>
                                        )}

                                        <Controller
                                            name="customCountry"
                                            control={control}
                                            rules={rules.required}
                                            render={({onChange, value}) => (
                                                <MySelect
                                                    name="customCountry"
                                                    label={t('country')}
                                                    value={value}
                                                    options={countryOptions.countryOptions}
                                                    error={errors.customCountry}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                )}
                            </div>


                        </div>


                        {!!props.categories?.length ? (
                            <>
                                <div className="tpsForm__cart">
                                    <h2 className="par-1 --bold">{t('tps.cart')}</h2>
                                    <Controller
                                        name="cart"
                                        control={control}
                                        rules={rules.cart}
                                        render={({onChange, value}) => (
                                            <TpsCartForm
                                                value={value}
                                                categories={props.categories}
                                                sending={props.sending}
                                                error={errors.cart}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="tpsForm__items">

                                </div>
                            </>
                        ) : props.loadingCategories && (
                            <Preloader/>
                        )}


                    </>
                ) : (
                    <Preloader/>
                )}


            </form>

            <div className="--mb4">
                <PaymentTypeForm
                    type={props.paymentMethod}
                    types={props.promotion?.payment_methods}
                    onSubmit={props.onChangePaymentMethod}
                />
            </div>

            <div className="tpsForm__footer --mb4">
                <Button
                    type="button"
                    label={t('tps.submit_form')}
                    disabled={props.sending}
                    onClick={handleSubmit(props.onSubmit)}
                />
            </div>
        </>
    )
}


function mapPromotionsToOptions(promotions) {
    const arr = [];
    promotions?.map(p => {
        arr.push({
            value: p.id_promotion,
            label: `${p.promotion_title} - ${p.promotion_name}`,
        })
    })
    return arr;
}


export default TpsForm;
