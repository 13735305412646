import React, {useMemo, useState} from "react";
import Table from "../../../../components/Table";
import {useTranslation} from "../../../../misc/Hooks";
import {Button} from "../../../../components/buttons/_index";
import {MyCheckbox} from "../../../../components/forms/inputs/_index";

const mapItemsToOptions = (items) => {
    return items.map(item => ({
        select: item.id_reseller,
        name: `${item.reseller_firstname} <br/> ${item.reseller_lastname}`,
        email: item.reseller_email,
        number: item.reseller_number,
        shop: item.reseller_shops[0].shop_title,
        verification: item.id_reseller
    }))
}

export default function NewUsersTable(props) {
    const t = useTranslation();
    const [selectedRequest, setSelectedRequest] = useState([])

    const actionOptions = [
        {label: t('reseller_client.accept') || t('accept'), value: 'accept'},
        {label: t('reseller_client.reject') || t('reject'), value: 'reject'},
    ]

    const actionVoid = (type) => {
        if (type === 'accept')
            props.resellerVerify(selectedRequest, 1)
        else if (type === 'reject')
            props.resellerVerify(selectedRequest, -1)
        else
            alert('error')
    }

    const onChangeValue = (value) => {
        const arr = [...selectedRequest]
        if (arr.includes(value)) {
            const index = arr.indexOf(value)
            if (index > -1)
                arr.splice(index, 1)
        } else {
            arr.push(value)
        }
        setSelectedRequest(arr)
    }

    const columns = useMemo(() => [
        {
            Header: () => {
                const selectAll = (val) => {
                    const arr = [];
                    if (val) {
                        props.items.map((r) => {
                            arr.push(r.id_reseller)
                        })
                        setSelectedRequest(arr)
                    } else {
                        setSelectedRequest([])
                    }
                }
                return (
                    <>
                        <span>{t('reseller_client.select') || t('select')}</span>
                        <MyCheckbox value={selectedRequest?.length === props?.items?.length} onChange={selectAll}/>
                    </>
                )
            },
            accessor: 'select',
            Cell: propsItem => {
                return (
                    <MyCheckbox
                        value={selectedRequest.includes(propsItem.value)}
                        onChange={() => onChangeValue(propsItem.value)}
                    />
                )
            }
        },
        {
            Header: t('reseller_client.full_name'),
            accessor: 'name',
            Cell: propsItem => {
                return (<span dangerouslySetInnerHTML={{__html: propsItem.value}}/>)
            }
        },
        {Header: t('reseller_client.reseller_number'), accessor: 'number'},
        {Header: t('reseller_client.shop') || t('shop'), accessor: 'shop'},
        {Header: t('email'), accessor: 'email'},
        {
            Header: t('reseller_client.verification') || t('verification'),
            accessor: 'verification',
            Cell: propsItem => {
                return (
                    <div style={{display: 'flex', gap: ".3rem"}}>
                        <Button
                            size="sm"
                            label={t('reseller_client.accept') || t('accept')}
                            onClick={() => props.resellerVerify(propsItem.value, 1)}
                        />
                        <Button
                            size="sm"
                            label={t('reseller_client.reject') || t('reject')}
                            onClick={() => props.resellerVerify(propsItem.value, -1)}
                            type={'secondary'}
                        />
                    </div>
                )
            }
        }
    ], [props.items, selectedRequest])

    const items = useMemo(() => {
        return mapItemsToOptions(props.items ?? [])
    }, [props.items ?? []])


    return (
        <Table
            title={""}
            columns={columns}
            data={items}
            maxPage={props.maxPage}
            results={props.results}
            loading={props.newRequestLoading}
            getData={(cp, ps, v = null) => props.getData(cp, ps, v)}
            actions
            selected={selectedRequest}
            setSelected={setSelectedRequest}
            actionVoid={actionVoid}
            actionOptions={actionOptions}
        />
    )
}
