import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Axios from "../utils/Axios";
import * as configActions from "../actions/ConfigActions";
import * as rmsReducer from "../redux/rmsReducer";
import * as mainReducer from "../redux/mainReducer";
import {useResellerActions} from "../actions/useResellerActions";
import {useLanguage} from "../hooks";


let MOUNTED = false;
let LOADING_CONFIG = false;
let LOADING_UUID = false;

export const RmsAppConfig = (props) => {
    const dispatch = useDispatch();
    const currentLanguage = useLanguage();
    const resellerActions = useResellerActions();
    const store = useSelector(({main, rms}) => ({
        marketConfig: main.marketConfig,
        country: main.country,
        uuid: rms.uuid,
        machine: rms.machine,
    }))

    useEffect(() => {
        MOUNTED = true;
        Axios.defaults.headers.common['Language'] = currentLanguage;

        if(store.machine) {
            getPlan(store.machine.id_machine)
        }

        return () => {
            MOUNTED = false;
        }
    }, [])


    const getConfig = () => {
        LOADING_CONFIG = true;
        configActions.get().then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(mainReducer.setMarketConfig(
                        res.data.config,
                        res.data.vat_modifiers,
                        res.data.recaptcha_key,
                        res.data.display,
                    ));
                }
            } else {
                console.log('Something gone wrong! ', res)
            }
        }).catch(err => {
            console.warn('Error: ', err)
        }).finally(() => {
            LOADING_CONFIG = false;
        })
    }


    const getUuid = () => {
        LOADING_UUID = true;
        configActions.getUuid().then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(rmsReducer.setUuid(res.data));
                }
            } else {
                console.warn('Cannot get UUID! ', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            LOADING_UUID = false;
        })
    }


    const getPlan = (machineId) => {
        resellerActions.getPlanByMachineId(machineId, store.country?.id_country).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(rmsReducer.setProducts(res.data.plans))
                    dispatch(rmsReducer.setCategories(res.data.categories))
                }
            }
        }).catch(error => {
            console.error('Error', error);
        })
    }


    return props.children;
}
