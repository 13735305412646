import UserScreen from "../screens/UserScreen";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {useNavigation, useTranslation} from "../../../../misc/Hooks";
import Routes from "../../../../utils/Routes";

let MOUNTED = false;

export default function UserContainer(props) {
    const params = useParams();
    const actions = useResellerActions();
    const history = useHistory();
    const t = useTranslation();
    const navigation = useNavigation();
    const [formData, setFormData] = useState(null);
    const [sending, setSending] = useState({
        status: false,
        finishStatus: '',
        modalVisible: false,
    })

    useEffect(() => {
        getFormData(params.id)
    }, [params.id])


    const getFormData = (id) => {
        actions.getResellerEditData(id).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                    setFormData(res.data)
            } else {
                console.warn('Something gone wrong!', res)
                if (res.messages[0] === 'no_relation') {
                    history.push(navigation(Routes.RESELLER_USERS))
                }
            }
        }).catch(err => {
            console.error('Error: ', err)
        })
    }

    const onSubmitFrom = (data) => {
        const postData = {
            id_reseller: params.id ?? 0,
            reseller_firstname: data.firstname ?? '',
            reseller_lastname: data.lastname ?? '',
            reseller_email: data.email ?? '',
            reseller_role: data.role.value ?? '',
            reseller_number: data.number ?? '',
            id_parent: data.supervisor.value ?? 0,
            id_shop: data.shop.value ?? 0,
            //temporarily
            id_child_shop: 0,
            id_child_parent: 0,
            reseller_status: data.status ? 1 : 0
        }

        setSending({
            ...sending,
            status: true
        })

        actions.resellerUpdate(postData).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                    setSending({
                        status: false,
                        finishStatus: 'success',
                        modalVisible: true,
                    })
            } else {
                setSending({
                    status: false,
                    finishStatus: 'error',
                    modalVisible: true,
                })
                console.warn('Something gone wrong!', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
            setSending({
                status: false,
                finishStatus: 'error',
                modalVisible: true,
            })
        })
    }

    return (
        <UserScreen
           formData={formData}
           loading={!formData}
           onSubmitFrom={onSubmitFrom}
           sending={sending}
           setSending={setSending}
        />
    )
}
