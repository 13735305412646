import Axios from "../utils/Axios";


export const getAll = (promotionId, machineId, shopId, userLogged) => {
    return Axios.get(`/categories/${promotionId}/${machineId}/${shopId}/${userLogged}`);
}


export const getTpsCategories = (promotionId) => {
    return Axios.get(`/categories-tps/${promotionId}`)
}
