import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {CustomerInformation} from "../../components/stay_home/_index";
import {useShStepNavigation, useTranslation} from "../../misc/Hooks";
import * as stayHomeReducer from "../../redux/stayHomeReducer";


export const CustomerRegistrationController = (props) => {
    const dispatch = useDispatch();
    const store = useSelector(({main, stayHome}) => ({
        country: main.country,
        customer: stayHome.customer,
        email: stayHome.email,
    }))
    const t = useTranslation();
    const stepNavigation = useShStepNavigation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        document.title = t('sh_customer_registration.title')
    }, [])


    const handleSubmit = (data) => {
        dispatch(stayHomeReducer.setCustomer(data));
        history.push(stepNavigation.next());
    }


    return (
        <CustomerInformation
            country={store.country}
            customer={store.customer}
            loading={loading}
            onSubmit={handleSubmit}
        />
    )
}
