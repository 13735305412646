import React, {useEffect, useState} from "react";
import Account from "../../components/order/Account";
import * as authorizationActions from "../../actions/AuthorizationActions";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as ofReducer from '../../redux/ofReducer';
import {useNavigation, useOfStepNavigation} from "../../misc/Hooks";
import * as OrderService from "../../services/OrderService";
import * as evReducer from "../../redux/evReducer";
import {mapOrderToCustomer, mapStackToOptions} from "../../misc/Helpers";
import Env from "../../utils/Env";


let MOUNTED = false;

export const AccountContainer = (props) => {

  const stepNavigation = useOfStepNavigation();
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch()
  const store = useSelector(({main, of}) => ({
    civilities: main.civilities,
    finishOrder: of.finishOrder,
    finishOrderUser: of.finishOrderUser,
    orderAccount: of.orderAccount,
    orderAccountLinked: of.orderAccountLinked,
    nespressoMember: of.nespressoMember,
  }));
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const civilityOptions = mapStackToOptions(store.civilities).sort((a, b) => Number(a.value) < Number(b.value) ? -1 : 1)


  useEffect(() => {
    MOUNTED = true;

    return () => {
      MOUNTED = false;
    }
  }, [])


  const handleSubmit = (data) => {
    setLoading(true);
    const params = {id_order: store.finishOrder?.order?.id_order ?? 0}
        authorizationActions.signIn(data, params).then(r => {
      const res = r.data;
      if (res.status === 'success') {
        if (store.finishOrder?.promotion_type?.startsWith('subscription_') && res.data?.has_subscription) {
          if (MOUNTED) {
            setError('customer_already_has_subscription');
          }
        } else {
          if (MOUNTED) {
            dispatch(ofReducer.setNespressoMember({
              email: data.login,
              ...res.data,
            }))
            history.push(stepNavigation.next())
          }

        }
      } else {
        if(['member_number_to_many_times_used'].includes(res.messages[0])) {
                    setError(res.messages[0])
                }
                else if (res.messages?.length) {
          if (MOUNTED) {
            setError(res.messages[0]);
          }
        }
        console.warn('Something gone wrong! ', res);
      }
    }).catch(err => {
      console.error('Error: ', err)
    }).finally(() => {
      if (MOUNTED) {
        setError(null);
        setLoading(false);
      }
    })
  }


  const handleSubmitPassword = async (data) => {
    const {account, linked} = await createAccount(data)
    if (account && linked) {
      dispatch(ofReducer.setNespressoMember({
        token: account.jwtToken,
        member_id: account.member_number,
      }));
      history.push(stepNavigation.next())
    }
  }

  const createAccount = async (data) => {
    const customer = mapOrderToCustomer(store.finishOrder, civilityOptions)
    const customerData = {
      id_country: store.finishOrder.id_country,
      currency_prefix: store.finishOrder.currency_prefix,
      currency_suffix: store.finishOrder.currency_suffix,
      currency_name: store.finishOrder.currency_name,
      lang: match.params.lang ?? Env.DEFAULT_LANG,

      address_id: customer?.memberAddress?.value,
      delivery_is_company: customer.companyAddress ? 1 : 0,
      delivery_company_name: customer.companyName ?? '',
      delivery_company_tax_id: customer.taxId ?? '',
      delivery_company_irs: customer.irs ?? '',
      delivery_civility: customer.title ? Number(customer.title.value) : 0,
      delivery_firstname: customer.name ?? '',
      delivery_lastname: customer.surname ?? '',
      delivery_zipcode: customer.postcode ?? '',
      delivery_city: customer.city ?? '',
      delivery_address1: customer.address ?? '',
      delivery_address2: customer.address2 ?? '',
      delivery_country: customer.country?.value ?? '',
      delivery_phone: customer.phone ?? '',
      delivery_phone_mobile: customer?.phoneMobile ?? '',
      delivery_phone_home: customer?.phoneHome ?? '',
      delivery_email: customer.email ?? '',

      other_delivery: customer.withCustomAddress ? 1 : 0,
      other_delivery_is_company: customer.customCompanyAddress ? 1 : 0,
      other_delivery_company_name: customer.customCompanyName ?? '',
      other_delivery_civility: customer.customTitle ? Number(customer.customTitle.value) : 0,
      other_delivery_firstname: customer.customName ?? '',
      other_delivery_lastname: customer.customSurname ?? '',
      other_delivery_zipcode: customer.customPostcode ?? '',
      other_delivery_city: customer.customCity ?? '',
      other_delivery_address1: customer.customAddress ?? '',
      other_delivery_address2: customer.customAddress2 ?? '',
      other_delivery_country: customer.customCountry?.value ?? '',
      other_delivery_phone: customer.customPhone ?? '',
      other_delivery_phone_mobile: customer?.customPhoneMobile ?? '',
      other_delivery_phone_home: customer?.customPhoneHome ?? '',
      other_delivery_email: customer.customEmail ?? '',

      marketing_email: customer.benefits?.email ? 1 : 0,
      marketing_mobile: customer.benefits?.mobile ? 1 : 0,
      marketing_phone: customer.benefits?.phone ? 1 : 0,
      marketing_post: customer.benefits?.post ? 1 : 0,

      regulations: customer.termsAndConditions ? 1 : 0,
      privacy_policy: customer.privacyPolicy ? 1 : 0,

      member_password: data.password,
    }

    let account = store.orderAccount
    let linked = store.orderAccountLinked

    setError(null);
    setLoading(true);
    if (!account) {
      const res = await OrderService.createAccount(customerData, data.recaptchaToken)
        .catch(async err => {
          setError('cannot_create_account')
          // setErrorLog(JSON.stringify(err.data))
        })

      if (res) {
        account = res.account
        linked = res.linked
        dispatch(ofReducer.setOrderAccount(account, linked))
      }
    }

    if (account && !linked) {
      const payload = {
        customer: customerData,
        member_token: account.jwtToken,
      }
      const account = await OrderService.linkAccount(payload, account.memberNumber)
      if (account) {
        dispatch(evReducer.setOrderAccount(account, true))
      } else {
        setError('linking_error')
      }
    }

    setLoading(false);

    return {account, linked}
  }

  const handleSignOut = () => {
    dispatch(ofReducer.setFinishOrderUser(null));
  }

  return (
    <Account
      error={error}
      loading={loading}
      order={store.finishOrder}
      nespressoMember={store.nespressoMember}
      onSubmit={handleSubmit}
      onSubmitPassword={handleSubmitPassword}
      signOut={handleSignOut}
    />
  )
}

