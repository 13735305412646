import Env from "./Env";

const languages = Env.LANGUAGES.join('|');

const LANG_PARAM = `/:lang(${languages})?`;

const Routes = {
    LANG_PARAM,

    CLEAR_STORAGE: '/vst/clear-storage',
    MAINTENANCE: `${LANG_PARAM}/maintenance`,
    PAYMENT_REJECTED: `${LANG_PARAM}/payment-rejected`,

    ORDER_FINALIZED: `${LANG_PARAM}/order-finalized/:token?`,
    UPLOAD_PROOF_OF_PURCHASE: `${LANG_PARAM}/upload-proof-of-purchase/:token`,
    FINISH_ORDER_TOKEN: `${LANG_PARAM}/finish-order/:token`,
    ETRADE_SHOP: `${LANG_PARAM}/etrade-shop/:token`,
    TPS: `${LANG_PARAM}/tps`,

    SHOP: `${LANG_PARAM}`,
    SHOP_MACHINE_REGISTRATION: `${LANG_PARAM}/machine-registration`,
    SHOP_CUSTOMER_IDENTIFICATION: `${LANG_PARAM}/customer-identification`,
    SHOP_PROMO_SELECTION: `${LANG_PARAM}/promo-selection`,
    SHOP_COFFEE_SELECTION: `${LANG_PARAM}/coffee-selection`,
    SHOP_CUSTOMER_INFORMATION: `${LANG_PARAM}/customer-information`,
    SHOP_DELIVERY_PAYMENT: `${LANG_PARAM}/delivery-and-payment`,

    RESELLER: `${LANG_PARAM}/reseller`,
    RESELLER_AUTH: `${LANG_PARAM}/reseller/sign-in`,
    RESELLER_FORGOT_PASSWORD: `${LANG_PARAM}/reseller/forgot-password`,
    RESELLER_FORGOT_PASSWORD_SET_NEW: `${LANG_PARAM}/reseller/forgot-password/:token`,
    RESELLER_MACHINE_SUBSCRIPTIONS: `${LANG_PARAM}/reseller/machine-subscriptions`,
    RESELLER_MACHINE_SUBSCRIPTION_MACHINE: `${LANG_PARAM}/reseller/machine-subscription/machine`,
    RESELLER_MACHINE_SUBSCRIPTION_IDENTIFICATION: `${LANG_PARAM}/reseller/machine-subscription/identification`,
    RESELLER_MACHINE_SUBSCRIPTION_PLAN: `${LANG_PARAM}/reseller/machine-subscription/plan`,
    RESELLER_MACHINE_SUBSCRIPTION_CUSTOMER: `${LANG_PARAM}/reseller/machine-subscription/customer`,
    RESELLER_MACHINE_SUBSCRIPTION_SUMMARY: `${LANG_PARAM}/reseller/machine-subscription/summary`,
    RESELLER_ASSISTED_REGISTRATION: `${LANG_PARAM}/reseller/assisted-registration`,

    RESELLER_ADMIN_REPORTS: `${LANG_PARAM}/reseller/admin-and-reports`,
    RESELLER_REPORTS: `${LANG_PARAM}/reseller/reports`,
    RESELLER_REPORTS_NEW: `${LANG_PARAM}/reseller/reports-new`,
    RESELLER_USERS: `${LANG_PARAM}/reseller/users/:id?`,
    RESELLER_USERS_USER: `${LANG_PARAM}/reseller/users/user/:id`,

    AUTH_RESELLER_REGISTRATION: `${LANG_PARAM}/reseller/registration/:token?`,
    AUTH_RESELLER_VERIFICATION: `${LANG_PARAM}/reseller/verification/:status/:token/:parent_token`,

    // FINISH_ORDER: `${LANG_PARAM}/finish`,
    FINISH_ORDER_ACCOUNT: `${LANG_PARAM}/finish/account`,
    FINISH_ORDER_CUSTOMER: `${LANG_PARAM}/finish/customer`,
    FINISH_ORDER_SUMMARY: `${LANG_PARAM}/finish/summary`,
    FINISH_ORDER_PAYMENT: `${LANG_PARAM}/finish/payment`,

    FINISH_ORDER: `${LANG_PARAM}/finish-order/:token`,
    ORDER_FINISH: `${LANG_PARAM}/order-finish/:token?`,
    ORDER_FINISH_AUTHORIZATION: `${LANG_PARAM}/order-finish/account`,
    ORDER_FINISH_CUSTOMER: `${LANG_PARAM}/order-finish/customer`,
    ORDER_FINISH_SUMMARY: `${LANG_PARAM}/order-finish/summary`,
    ORDER_FINISH_PAYMENT: `${LANG_PARAM}/order-finish/payment`,
    ORDER_FINISH_TOKEN_EXPIRED: `${LANG_PARAM}/order-finish/token-expired`,
    ORDER_FINISH_INVALID_TOKEN: `${LANG_PARAM}/order-finish/invalid-token`,
    ORDER_FINISH_ORDER_FINALIZED: `${LANG_PARAM}/order-finish/order-finalized/:uuid`,

    STAY_HOME: `${LANG_PARAM}/stay-home`,
    STAY_HOME_AUTHORIZATION: `${LANG_PARAM}/stay-home/authorization`,
    STAY_HOME_PLAN_SELECTION: `${LANG_PARAM}/stay-home/plan-selection`,
    STAY_HOME_COFFEE_SELECTION: `${LANG_PARAM}/stay-home/coffee-selection`,
    STAY_HOME_CUSTOMER: `${LANG_PARAM}/stay-home/customer`,
    STAY_HOME_SUMMARY: `${LANG_PARAM}/stay-home/summary`,
    STAY_HOME_TOKEN_EXPIRED: `${LANG_PARAM}/stay-home/token-expired`,
    STAY_HOME_ORDER_FINALIZED: `${LANG_PARAM}/stay-home/order-finalized/:uuid`,

    ETRADE: `${LANG_PARAM}/etrade/:token?`,
    ETRADE_MACHINE_PLAN: `${LANG_PARAM}/etrade/machine-plan`,
    ETRADE_AUTHORIZATION: `${LANG_PARAM}/etrade/authorization`,
    ETRADE_CUSTOMER: `${LANG_PARAM}/etrade/customer`,
    ETRADE_SUMMARY: `${LANG_PARAM}/etrade/summary`,
    ETRADE_TOKEN_EXPIRED: `${LANG_PARAM}/etrade/token-expired`,
    ETRADE_INVALID_TOKEN: `${LANG_PARAM}/etrade/invalid-token`,
    ETRADE_ORDER_FINALIZED: `${LANG_PARAM}/etrade/order-finalized/:uuid`,
}


export default Routes;
