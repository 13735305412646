import React, {useEffect, useState} from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyCheckbox, MyInput, MyInputMask, MySelect, RecaptchaV2} from "./inputs/_index";
import Patterns from "../../utils/Patterns";
import {Button} from "../buttons/_index";
import {useCountryConfig, useMarketConfig, useTranslation} from "../../misc/Hooks";
import {regexFromString} from "../../misc/Helpers";
import {Config} from "../../utils/_index";
import {BenefitsInfo, TermsInfo} from "../misc/_index";
import {MyLink} from "../../misc/_index";
import {useCountryOptions} from "../../hooks";
import {isEnabledByMarket} from "../../utils/MarketConfig";


const CustomerShForm = (props) => {
    const t = useTranslation();
    const countryConfig = useCountryConfig();
    const marketConfig = useMarketConfig();
    const countryOptions = useCountryOptions();
    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            // memberAddress: props.customer?.memberAddress ?? null,
            anotherAddress: !props.nespressoMember ? true : !!props.customer?.anotherAddress,

            companyAddress: !!props.customer?.companyAddress,
            companyName: props.customer?.companyName ?? '',
            taxId: props.customer?.taxId ?? '',
            title: props.customer?.title ?? null,
            name: props.customer?.name ?? '',
            surname: props.customer?.surname ?? '',
            postcode: props.customer?.postcode ?? '',
            city: props.customer?.city ?? '',
            address: props.customer?.address ?? '',
            address2: props.customer?.address2 ?? '',
            country: props.customer?.country ?? null,
            phone: props.customer?.phone ?? '',
            email: props.customer?.email ?? '',
            reEmail: props.customer?.reEmail ?? '',
            // password: props.customer?.password ?? '',
            // rePassword: props.customer?.rePassword ?? '',

            withCustomAddress: !!props.customer?.withCustomAddress,
            customCompanyAddress: !!props.customer?.customCompanyAddress,
            customCompanyName: props.customer?.customCompanyName ?? '',
            customTaxId: props.customer?.customTaxId ?? '',
            customTitle: props.customer?.customTitle ?? null,
            customName: props.customer?.customName ?? '',
            customSurname: props.customer?.customSurname ?? '',
            customPostcode: props.customer?.customPostcode ?? '',
            customCity: props.customer?.customCity ?? '',
            customAddress: props.customer?.customAddress ?? '',
            customAddress2: props.customer?.customAddress2 ?? '',

            benefitsEmail: !!props.customer?.benefitsEmail,
            benefitsPhone: !!props.customer?.benefitsPhone,

            termsAndConditions: !!props.customer?.termsAndConditions,
            privacyPolicy: !!props.customer?.privacyPolicy,

            recaptchaToken: '',
        }
    });
    const memberAddress = formMethods.watch('memberAddress');
    const anotherAddress = formMethods.watch('anotherAddress');
    const companyAddress = formMethods.watch('companyAddress');
    const withCustomAddress = formMethods.watch('withCustomAddress');
    const customCompanyAddress = formMethods.watch('customCompanyAddress');
    const email = formMethods.watch('email');
    const reEmail = formMethods.watch('reEmail');
    const password = formMethods.watch('password');


    const [deliveryOptions, setDeliveryOptions] = useState([]);

    const POST_CODE_MASK = {
        pattern: regexFromString(countryConfig.custom_format?.custom_postcode_format?.pattern) ?? /[0-9]{4}-[a-zA-Z]{2}/,
        inputMask: countryConfig.custom_format?.custom_postcode_format?.mask ?? '9999-aa',
    }
    const PHONE_MASK = {
        pattern: regexFromString(countryConfig.custom_format?.custom_phone_format?.pattern) ?? /\+48 [29][0-9]{2} [0-9]{3} [0-9]{3}/,
        inputMask: countryConfig.custom_format?.custom_phone_format?.mask ?? '+48 999 999 999',
    }


    const rules = {
        // memberAddress: {
        //     validate: v => {
        //         if (v?.value || anotherAddress) {
        //             return true;
        //         }
        //         return t('validation.this_or', t('deliver_to_another_address'));
        //     },
        // },
        anotherAddress: {
            validate: v => {
                if (v || memberAddress?.value) {
                    return true;
                }
                return t('validation.this_or', t('choose_your_delivery_address'));
            },
        },

        companyAddress: {},
        companyName: {
            required: {value: !!companyAddress, message: t('validation.required')},
        },
        taxId: {},
        title: {
            required: {value: !companyAddress && true, message: t('validation.required')},
        },
        name: {
            required: {value: anotherAddress && true, message: t('validation.required')},
            pattern: {value: Patterns.onlyText, message: t('validation.invalid')},
            maxLength: {
                value: marketConfig.name_max_length,
                message: t('validation.max_length', marketConfig.name_max_length)
            }
        },
        surname: {
            required: {value: anotherAddress && true, message: t('validation.required')},
            pattern: {value: Patterns.onlyText, message: t('validation.invalid')},
            maxLength: {
                value: marketConfig.surname_max_length,
                message: t('validation.max_length', marketConfig.surname_max_length)
            }
        },
        postcode: {
            required: {value: anotherAddress && true, message: t('validation.required')},
            pattern: {value: POST_CODE_MASK.pattern, message: t('validation.invalid')}
        },
        city: {
            required: {value: anotherAddress && true, message: t('validation.required')},
            maxLength: {
                value: marketConfig.city_max_length,
                message: t('validation.max_length', marketConfig.city_max_length)
            }
        },
        address: {
            required: {value: anotherAddress && true, message: t('validation.required')},
            maxLength: {
                value: marketConfig.address_max_length,
                message: t('validation.max_length', marketConfig.address_max_length)
            }
        },
        address2: {
            maxLength: {
                value: marketConfig.address_max_length,
                message: t('validation.max_length', marketConfig.address_max_length)
            }
        },
        country: {
            required: {value: anotherAddress && true, message: t('validation.required')}
        },
        phone: {
            required: {value: anotherAddress && true, message: t('validation.required')},
            pattern: {value: PHONE_MASK.pattern, message: t('validation.invalid')}
        },
        email: {
            required: {value: anotherAddress && true, message: t('validation.required')},
            pattern: {value: Patterns.email, message: t('validation.invalid')}
        },
        reEmail: {
            required: {value: anotherAddress && true, message: t('validation.required')},
            validate: v => v === email ? true : t('validation.same_as', t('email')),
        },
        // password: {
        //     required: {value: anotherAddress && true, message: t('validation.required')},
        //     pattern: {value: Patterns.password, message: t('validation.password')}
        // },
        // rePassword: {
        //     required: {value: anotherAddress && true, message: t('validation.required')},
        //     validate: v => v === password ? true : t('validation.same_as', t('password')),
        // },

        customAddress: {},
        customCompanyName: {
            required: {value: !!customCompanyAddress, message: t('validation.required')},
        },

        benefitsEmail: {},
        benefitsPhone: {},
        termsAndConditions: {
            required: {value: true, message: t('validation.are_required', t('terms_and_conditions'))}
        },
        privacyPolicy: {
            required: {value: true, message: t('validation.is_required', t('privacy_policy'))}
        },

        recaptchaToken: {
            required: {value: false, message: t('validation.required')}
        },
    }


    useEffect(() => {
        if (email && reEmail) {
            if (email !== reEmail) {
                formMethods.setError('reEmail', {
                    type: 'validate',
                    message: t('validation.same_as', t('email'))
                })
            } else {
                formMethods.clearErrors('reEmail')
            }

        }
    }, [email]);


    useEffect(() => {
        if (memberAddress) {
            formMethods.setValue('anotherAddress', false)
        }
    }, [memberAddress]);


    useEffect(() => {
        if (anotherAddress) {
            formMethods.setValue('memberAddress', null)
        }
    }, [anotherAddress]);



    const onSubmit = (data) => {
        props.onSubmit(data);
    }

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>

                <div className="--mb4">
                    {/*{!!props.nespressoMember && (*/}
                    {/*    <div className="gridSm" hidden={!props.nespressoMember}>*/}
                    {/*        <div className="col">*/}

                    {/*            <Controller*/}
                    {/*                name="memberAddress"*/}
                    {/*                control={formMethods.control}*/}
                    {/*                rules={rules.memberAddress}*/}
                    {/*                render={({onChange, value}) => (*/}
                    {/*                    <MySelect*/}
                    {/*                        name="memberAddress"*/}
                    {/*                        label={t('choose_your_delivery_address')}*/}
                    {/*                        value={value}*/}
                    {/*                        options={deliveryOptions}*/}
                    {/*                        error={formMethods.errors.memberAddress}*/}
                    {/*                        onChange={onChange}*/}
                    {/*                        nullable*/}
                    {/*                    />*/}
                    {/*                )}*/}
                    {/*            />*/}


                    {/*            <Controller*/}
                    {/*                name="anotherAddress"*/}
                    {/*                control={formMethods.control}*/}
                    {/*                rules={rules.anotherAddress}*/}
                    {/*                render={({onChange, value}) => (*/}
                    {/*                    <MyCheckbox*/}
                    {/*                        name="anotherAddress"*/}
                    {/*                        label={t('deliver_to_another_address')}*/}
                    {/*                        value={value}*/}
                    {/*                        error={formMethods.errors.anotherAddress}*/}
                    {/*                        onChange={onChange}*/}
                    {/*                    />*/}
                    {/*                )}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}


                    {!!anotherAddress && (
                        <div className="--mb2">
                            <div className="--mb2">
                                <Controller
                                    name="companyAddress"
                                    control={formMethods.control}
                                    rules={rules.companyAddress}
                                    render={({onChange, value}) => (
                                        <div className="--mb2">
                                            <MyCheckbox
                                                name="companyAddress"
                                                label={t('company_address')}
                                                value={value}
                                                error={formMethods.errors.companyAddress}
                                                onChange={onChange}
                                            />
                                        </div>
                                    )}
                                />

                                <div className="gridSm -full">
                                    <div className="col">
                                        <div hidden={!companyAddress}>
                                            <Controller
                                                name="companyName"
                                                control={formMethods.control}
                                                rules={rules.companyName}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="companyName"
                                                        label={t('company_name')}
                                                        value={value}
                                                        error={formMethods.errors.companyName}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                        {!!marketConfig?.tax_id && (
                                            <div hidden={!companyAddress}>
                                                <Controller
                                                    name="taxId"
                                                    control={formMethods.control}
                                                    rules={rules.taxId}
                                                    render={({onChange, value}) => (
                                                        <MyInput
                                                            name="taxId"
                                                            label={t('tax_id')}
                                                            value={value}
                                                            error={formMethods.errors.taxId}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}
                                        <div hidden={companyAddress}>
                                            <Controller
                                                name="title"
                                                control={formMethods.control}
                                                rules={rules.title}
                                                render={({onChange, value}) => (
                                                    <MySelect
                                                        name="title"
                                                        label={t('title')}
                                                        value={value}
                                                        options={Config.TITLES.map(o => ({
                                                            label: t(o.label),
                                                            value: o.value
                                                        }))}
                                                        // nullable={true}
                                                        error={formMethods.errors.title}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="gridSm">
                                    <div className="col">
                                        <Controller
                                            name="name"
                                            control={formMethods.control}
                                            rules={rules.name}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="name"
                                                    label={t('name')}
                                                    value={value}
                                                    error={formMethods.errors.name}
                                                    onChange={onChange}
                                                    maxLength={rules.name.maxLength?.value}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col">
                                        <Controller
                                            name="surname"
                                            control={formMethods.control}
                                            rules={rules.surname}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="surname"
                                                    label={t('surname')}
                                                    value={value}
                                                    error={formMethods.errors.surname}
                                                    onChange={onChange}
                                                    maxLength={rules.surname.maxLength?.value}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="gridSm">
                                    <div className="col">
                                        <Controller
                                            name="postcode"
                                            control={formMethods.control}
                                            rules={rules.postcode}
                                            render={({onChange, value}) => (
                                                <MyInputMask
                                                    name="postcode"
                                                    label={t('postcode')}
                                                    mask={POST_CODE_MASK.inputMask}
                                                    value={value}
                                                    error={formMethods.errors.postcode}
                                                    onChange={onChange}
                                                    forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col">
                                        <Controller
                                            name="city"
                                            control={formMethods.control}
                                            rules={rules.city}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="city"
                                                    label={t('city')}
                                                    value={value}
                                                    error={formMethods.errors.city}
                                                    onChange={onChange}
                                                    maxLength={rules.city.maxLength?.value}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="gridSm -full">
                                    <div className="col">
                                        <Controller
                                            name="address"
                                            control={formMethods.control}
                                            rules={rules.address}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="address"
                                                    label={t('address_line_1')}
                                                    value={value}
                                                    error={formMethods.errors.address}
                                                    onChange={onChange}
                                                    maxLength={rules.address.maxLength?.value}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="address2"
                                            control={formMethods.control}
                                            rules={rules.address2}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="address2"
                                                    label={t('address_line_2')}
                                                    value={value}
                                                    error={formMethods.errors.address2}
                                                    onChange={onChange}
                                                    maxLength={rules.address2.maxLength?.value}
                                                />
                                            )}
                                        />


                                        <Controller
                                            name="country"
                                            control={formMethods.control}
                                            rules={rules.country}
                                            render={({onChange, value}) => (
                                                <MySelect
                                                    name="country"
                                                    label={t('country')}
                                                    value={value}
                                                    options={countryOptions.countryOptions}
                                                    error={formMethods.errors.country}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="phone"
                                            control={formMethods.control}
                                            rules={rules.phone}
                                            render={({onChange, value}) => (
                                                <MyInputMask
                                                    name="phone"
                                                    label={t('phone')}
                                                    mask={PHONE_MASK.inputMask}
                                                    formatChars={PHONE_MASK.formatChars}
                                                    value={value}
                                                    error={formMethods.errors.phone}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="gridSm">
                                    <div className="col">
                                        <Controller
                                            name="email"
                                            control={formMethods.control}
                                            rules={rules.email}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="email"
                                                    label={t('email')}
                                                    value={value}
                                                    error={formMethods.errors.email}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col">
                                        <Controller
                                            name="reEmail"
                                            control={formMethods.control}
                                            rules={rules.reEmail}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="reEmail"
                                                    label={t('re_email')}
                                                    value={value}
                                                    error={formMethods.errors.reEmail}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <Controller
                                name="withCustomAddress"
                                control={formMethods.control}
                                rules={rules.withCustomAddress}
                                render={({onChange, value}) => (
                                    <div className="--mb2">
                                        <MyCheckbox
                                            name="withCustomAddress"
                                            label={t('deliver_to_another_address')}
                                            value={value}
                                            error={formMethods.errors.withCustomAddress}
                                            onChange={onChange}
                                        />
                                    </div>
                                )}
                            />

                            {!!withCustomAddress && (
                                <div>
                                    <Controller
                                        name="customCompanyAddress"
                                        control={formMethods.control}
                                        rules={rules.customCompanyAddress}
                                        render={({onChange, value}) => (
                                            <div className="--mb2">
                                                <MyCheckbox
                                                    name="customCompanyAddress"
                                                    label={t('company_address')}
                                                    value={value}
                                                    error={formMethods.errors.customCompanyAddress}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        )}
                                    />

                                    <div className="gridSm -full">
                                        <div className="col">
                                            <div hidden={!customCompanyAddress}>
                                                <Controller
                                                    name="customCompanyName"
                                                    control={formMethods.control}
                                                    rules={rules.customCompanyName}
                                                    render={({onChange, value}) => (
                                                        <MyInput
                                                            name="customCompanyName"
                                                            label={t('company_name')}
                                                            value={value}
                                                            error={formMethods.errors.customCompanyName}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div hidden={customCompanyAddress}>
                                                <Controller
                                                    name="customTitle"
                                                    control={formMethods.control}
                                                    rules={rules.title}
                                                    render={({onChange, value}) => (
                                                        <MySelect
                                                            name="customTitle"
                                                            label={t('title')}
                                                            value={value}
                                                            options={Config.TITLES.map(o => ({
                                                                label: t(o.label),
                                                                value: o.value
                                                            }))}
                                                            nullable={true}
                                                            error={formMethods.errors.customTitle}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="gridSm">
                                        <div className="col">
                                            <Controller
                                                name="customName"
                                                control={formMethods.control}
                                                rules={rules.name}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="customName"
                                                        label={t('name')}
                                                        value={value}
                                                        error={formMethods.errors.customName}
                                                        onChange={onChange}
                                                        maxLength={rules.name.maxLength?.value}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col">
                                            <Controller
                                                name="customSurname"
                                                control={formMethods.control}
                                                rules={rules.surname}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="customSurname"
                                                        label={t('surname')}
                                                        value={value}
                                                        error={formMethods.errors.customSurname}
                                                        onChange={onChange}
                                                        maxLength={rules.surname.maxLength?.value}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="gridSm">
                                        <div className="col">
                                            <Controller
                                                name="customPostcode"
                                                control={formMethods.control}
                                                rules={rules.postcode}
                                                render={({onChange, value}) => (
                                                    <MyInputMask
                                                        name="customPostcode"
                                                        label={t('postcode')}
                                                        mask={POST_CODE_MASK.inputMask}
                                                        value={value}
                                                        error={formMethods.errors.customPostcode}
                                                        onChange={onChange}
                                                        forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col">
                                            <Controller
                                                name="customCity"
                                                control={formMethods.control}
                                                rules={rules.city}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="customCity"
                                                        label={t('city')}
                                                        value={value}
                                                        error={formMethods.errors.customCity}
                                                        onChange={onChange}
                                                        maxLength={rules.city.maxLength?.value}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="gridSm -full">
                                        <div className="col">
                                            <Controller
                                                name="customAddress"
                                                control={formMethods.control}
                                                rules={rules.address}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="customAddress"
                                                        label={t('address_line_1')}
                                                        value={value}
                                                        error={formMethods.errors.customAddress}
                                                        onChange={onChange}
                                                        maxLength={rules.address.maxLength?.value}
                                                    />
                                                )}
                                            />

                                            {/*<Controller*/}
                                                name="customAddress2"
                                                control={formMethods.control}
                                                rules={rules.address2}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="customAddress2"
                                                        label={t('address_line_2')}
                                                        value={value}
                                                        error={formMethods.errors.customAddress2}
                                                        onChange={onChange}
                                                        maxLength={rules.address2.maxLength?.value}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>


                <BenefitsInfo/>


                <div className="--mb4">
                    <Controller
                        name="benefitsEmail"
                        control={formMethods.control}
                        rules={rules.benefitsEmail}
                        render={({onChange, value}) => (
                            <MyCheckbox
                                name="benefitsEmail"
                                value={value}
                                error={formMethods.errors.benefitsEmail}
                                onChange={onChange}
                                green
                            >
                                <b>{t('receive_benefits_email')}</b>
                            </MyCheckbox>
                        )}
                    />

                    <Controller
                        name="benefitsPhone"
                        control={formMethods.control}
                        rules={rules.benefitsPhone}
                        render={({onChange, value}) => (
                            <MyCheckbox
                                name="benefitsPhone"
                                value={value}
                                error={formMethods.errors.benefitsPhone}
                                onChange={onChange}
                                green
                            >
                                <b>{t('receive_benefits_phone')}</b>
                            </MyCheckbox>
                        )}
                    />
                </div>


                <TermsInfo/>


                <div className="--mb4">
                    <Controller
                        name="termsAndConditions"
                        control={formMethods.control}
                        rules={rules.termsAndConditions}
                        render={({onChange, value}) => (
                            <MyCheckbox
                                name="termsAndConditions"
                                value={value}
                                error={formMethods.errors.termsAndConditions}
                                onChange={onChange}
                                disableLabelClick
                            >
                            <span
                                className="--bold"
                                dangerouslySetInnerHTML={{__html: t('terms_and_conditions_confirmation', `<a href="https://www.nespresso.com/pl/en/legal" target="_blank">${t('terms_and_conditions')}<a/>`)}}
                            />
                            </MyCheckbox>
                        )}
                    />

                    <Controller
                        name="privacyPolicy"
                        control={formMethods.control}
                        rules={rules.privacyPolicy}
                        render={({onChange, value}) => (
                            <MyCheckbox
                                name="privacyPolicy"
                                value={value}
                                error={formMethods.errors.privacyPolicy}
                                onChange={onChange}
                                disableLabelClick
                            >
                            <span
                                className="--bold"
                                dangerouslySetInnerHTML={{__html: t('privacy_policy_confirmation', `<a href="https://www.nespresso.com/pl/en/legal" target="_blank">${t('privacy_policy')}<a/>`)}}
                            />
                            </MyCheckbox>
                        )}
                    />
                </div>


                {!props.nespressoMember && (
                    <div className="--mb4">
                        <Controller
                            name="recaptchaToken"
                            control={formMethods.control}
                            rules={rules.recaptchaToken}
                            render={({onChange, value}) => (
                                <RecaptchaV2
                                    value={value}
                                    onChange={onChange}
                                    error={formMethods.errors.recaptchaToken}
                                />
                            )}
                        />
                    </div>
                )}


                <div className="step__nav">
                    <MyLink route={props.stepNavigation.prev()} disabled={props.loading}>
                        <Button
                            type="primary-outline"
                            label={t('back')}
                            disabled={props.loading}
                        />
                    </MyLink>
                    <Button
                        btnType="submit"
                        type="primary"
                        label={t('continue')}
                        disabled={props.loading}
                        onClick={formMethods.handleSubmit(onSubmit)}
                    />
                </div>
            </form>
        </FormProvider>
    );
}


export default CustomerShForm;
