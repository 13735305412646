import Provider from "./Provider";
import Header from "../../../components/misc/Header";
import {Link, Route, Switch} from "react-router-dom";
import Routes from "../../../utils/Routes";
import {UserContainer, UsersContainer} from "./containers";
import {useNavigation, useTranslation} from "../../../misc/Hooks";
import {Breadcrumb} from "../../../components/_index";

const ROUTE_LIST = [
    {
        route: Routes.RESELLER_USERS,
        component: UsersContainer,
        size: "-xxl",
        trace: [
            {
                route: Routes.RESELLER,
                name: 'reseller'
            },
            {
                route: Routes.RESELLER_ADMIN_REPORTS,
                name: 'reseller_options.admin_and_reports'
            },
            {
                route: null,
                name: 'reseller_options.users'
            }
        ]
    },
    {
        route: Routes.RESELLER_USERS_USER,
        component: UserContainer,
        name: 'users.edit',
        trace: [
            {
                route: Routes.RESELLER,
                name: 'reseller'
            },
            {
                route: Routes.RESELLER_ADMIN_REPORTS,
                name: 'reseller_options.admin_and_reports'
            },
            {
                route: Routes.RESELLER_USERS,
                name: 'reseller_options.users'
            },
            {
                route: null,
                name: 'reseller_client.edit_user'
            }
        ]
    }
]

export default function Funnel(props) {
    const navigation = useNavigation()
    const t = useTranslation();


    return (
        <Provider>
            <div className="app">
                <Header
                    cartDisabled
                    alertsDisabled
                />

                <div className="main">
                    <div className="wrapper-bg formContainer">

                            <Switch>
                                {ROUTE_LIST.map((item, idx) => (
                                    <Route key={idx} exact path={item.route} render={() => <Breadcrumb item={item}/>}/>
                                ))}
                            </Switch>
                            <Switch>
                                {ROUTE_LIST.map((item, idx) => (
                                    <Route key={idx} exact path={item.route} component={item.component}/>
                                ))}
                            </Switch>
                    </div>
                </div>
            </div>
        </Provider>
    )
}
