import {AppProvider} from "../../misc/_index";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {Routes} from "../../utils/_index";
import React from "react";
import {useNavigation} from "../../misc/Hooks";

export default function ResellerProvider(props) {
    const navigation = useNavigation();
    const store = useSelector(({main}) => ({
        user: main.user,
    }));

    return (
        <AppProvider>
            {store.user ? props.children : <Redirect to={navigation(Routes.RESELLER_AUTH)}/>}
        </AppProvider>
    )
}
