import React, {useEffect, useState} from 'react';
import {
    Route,
    Switch,
    useRouteMatch,
    Link, Redirect, useLocation,
} from "react-router-dom";
import {useNavigation} from "../../../misc/Hooks";
import {useStepValidation} from "../../../hooks/modules/order_finish";
import {Routes} from "../../../utils/_index";


export default function Step(props) {
    const navigation = useNavigation();
    const match = useRouteMatch();
    const location = useLocation()
    const stepValidation = useStepValidation(props.route);
    const [wrapTitle, setWrapTitle] = useState(false)

    const isActive = match.path === props.route;

    useEffect(() => {
        if(stepValidation.value && isActive && props.currentStep !== props.index) {
            props.setCurrentStep();
        }
    })

    useEffect(() => {
        const isSummaryStep = props.steps[props.currentStep]?.route === Routes.ORDER_FINISH_SUMMARY
        const wrap = isSummaryStep && props.index <= props.steps.findIndex(s => s.route === Routes.ORDER_FINISH_SUMMARY)
        setWrapTitle(wrap)
    }, [props.index, props.currentStep])


    let circleContent = props.index + 1;
    if(props.currentStep > props.index) {
        circleContent = <img src={require('../../../assets/img/icons/checked.svg').default} alt="-"/>
    }

    let circleActive = props.currentStep >= props.index;

    // const isStepValid = canShowEVStep(props.route, match.params, props.store);
    // if(!isStepValid.value && isActive) {
    //     return <Redirect to={isStepValid.lastValidRoute}/>
    // }

    if(!stepValidation.value && isActive) {
        return <Redirect to={stepValidation.lastValidRoute}/>
    }

    return (
        <div className={`step ${isActive ? '-active' : ''}`}>
            <div className="step__header">
                <div className="wrapper-bg">
                    <div className="wrapper">
                        {stepValidation.value ? (
                            <div className="step__headerContent">
                                <div className={`step__circle ${circleActive ? '-active' : ''}`}>
                                    {circleContent}
                                </div>
                                <Link to={`${navigation(props.route)}${location.search || ''}`} style={{textDecoration: 'none'}}>
                                    <h1
                                        className={`step__title${wrapTitle ? ' -wrap' : ''}`}
                                        dangerouslySetInnerHTML={{__html: props.title}}
                                    />
                                </Link>
                            </div>
                        ) : (
                            <div className="step__headerContent">
                                <div className={`step__circle ${circleActive ? '-active' : ''}`}>
                                    {circleContent}
                                </div>
                                <h1 className={`step__title${wrapTitle ? ' -wrap' : ''}`} dangerouslySetInnerHTML={{__html: props.title}}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>

                <div className="step__body">

                    <div className="wrapper-bg">
                        <div className="wrapper">
                            <div className="step__bodyContent">
                                {stepValidation.value && (
                                <Switch>
                                    <Route
                                        exact path={props.route}
                                    >
                                        {props.children}
                                    </Route>
                                </Switch>
                                )}
                            </div>
                        </div>
                    </div>

                </div>

        </div>
    );
}
