import React from 'react';
import BaseModal from "./BaseModal";
import {Button} from "../buttons/_index";
import {useCountryConfig, useTranslation} from "../../misc/Hooks";


interface Props {
    visible: boolean,
    onClose: void,
}

export default function TermsInfoModal(props: Props) {
    const t = useTranslation();


    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="modal__body">
                {props.children}
            </div>

        </BaseModal>
    );
}
