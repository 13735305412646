import React from "react";
import Env from "../../utils/Env";
import {useNavigation} from "../../misc/Hooks";
import {Routes} from "../../utils/_index";
import {useLanguage} from "../../hooks";
import {useLocalizeObject} from "../../misc/Hooks";


export default (props) => {
    const navigation = useNavigation()
    const currentLanguage = useLanguage()
    const localizeObject = useLocalizeObject()

    return (
        <div>
            <div className="grid">
                <div>
                    <iframe
                        id="paymentIframe"
                        width="100%"
                        height="600px"
                        style={{border: 'none'}}
                        src={`/payment.html?hash=${props.order?.order.uuid}&token=${props.order?.jwtToken}&lang=${currentLanguage}&api_url=${Env.API_URL}&order_finalized_url=${navigation(Routes.ORDER_FINALIZED, props.order?.order.uuid)}`}
                    />
                </div>
                <div>
                    <div className="par-5"
                         dangerouslySetInnerHTML={{__html: localizeObject(props.finishOrder?.payment_method_text)}}/>
                </div>
            </div>
        </div>
    )
}

