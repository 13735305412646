import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Authorization} from "../../components/stay_home/_index";
import {useShStepNavigation, useTranslation} from "../../misc/Hooks";
import * as stayHomeReducer from "../../redux/stayHomeReducer";
import {useHistory} from "react-router-dom";
import {useShActions} from "../../actions/_index";


let MOUNTED = false;

export const AuthorizationController = (props) => {
    const dispatch = useDispatch();
    const store = useSelector(({stayHome}) => ({
        token: stayHome.token,
        email: stayHome.email,
        code: stayHome.code,
    }))
    const t = useTranslation();
    const history = useHistory();
    const stepNavigation = useShStepNavigation();
    const shActions = useShActions();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        MOUNTED = true;
        document.title = t('sh_authorization.title')

        return () => {
            MOUNTED = false;
        }
    }, [])


    const handleSubmit = (data) => {
        setLoading(true);
        setError(null);
        shActions.checkEmail(data.email).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                dispatch(stayHomeReducer.setTokenAndEmail(res.data, data.email))
            } else {
                if (res.messages?.length) {
                    if (MOUNTED) {
                        setError(res.messages[0])
                    }
                }
                else {
                    console.log('Something gone wrong!', r)
                }
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if (MOUNTED) {
                setLoading(false);
            }
        })
    }


    const handleSubmitCode = (data) => {
        setLoading(true);
        setError(null);
        shActions.checkCode(data.code, store.token).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                dispatch(stayHomeReducer.setCode(data.code))
                history.push(stepNavigation.next())
            } else {
                if (res.messages?.length) {
                    if (MOUNTED) {
                        setError(res.messages[0])
                    }
                }
                console.warn('Something gone wrong!', r)
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if (MOUNTED) {
                setLoading(false);
            }
        })
    }


    const handleGoBack = () => {
        dispatch(stayHomeReducer.clearStore())
        setLoading(false);
        setError(false);
    }


    return (
        <Authorization
            error={error}
            loading={loading}
            email={store.email}
            code={store.code}
            onSubmit={handleSubmit}
            onSubmitCode={handleSubmitCode}
            onGoBack={handleGoBack}
        />
    )
}
