import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useNavigation} from "../../misc/Hooks";
import Payment from "../../components/order/Payment";


let MOUNTED = false;

export const PaymentContainer = (props) => {
    const store = useSelector(({of}) => ({
        uuid: of.uuid,
        order: of.order,
        finishOrder: of.finishOrder,
    }));

    useEffect(() => {
        MOUNTED = true;

        return () => {
            MOUNTED = false;
        }
    }, [])


    return (
        <Payment
            uuid={store.uuid}
            order={store.order}
            finishOrder={store.finishOrder}
        />
    )
}

