import React, {useEffect} from "react";
import {Alert} from "../_index";
import {useSelector} from "react-redux";
import CartSummarySimple from "../CartSummarySimple";
import {Link, useHistory} from "react-router-dom";
import {Button} from "../buttons/_index";
import {
    useCart,
    useLocalizeObject,
    useMarketConfig,
    useNavigation,
    useOfStepNavigation,
    useOfSteps,
    usePriceFormatter,
    useTranslation
} from "../../misc/Hooks";
import {Preloader} from "../../misc/_index";
import {calcMemberCredit, getStepHeaderHeight} from "../../misc/Helpers";
import {RecaptchaV2} from "../forms/inputs/RecaptchaV2";
import {Config} from "../../utils/_index";


export default (props) => {
    const t = useTranslation();
    const localizeObject = useLocalizeObject();
    const navigation = useNavigation();
    const stepNavigation = useOfStepNavigation();
    const history = useHistory();
    const steps = useOfSteps();
    const marketConfig = useMarketConfig();
    const store = useSelector(({of}) => ({
        cart: of.cart,
        products: of.products,
        categories: of.categories,
        finishOrder: of.finishOrder,
        order: of.order,
    }))
    const {formatPrice} = usePriceFormatter();
    const {
        capsulesValue,
        capsulesVatValue,
        giftValue,
        deliveryValue,
        cartValue,
    } = useCart('of');


    useEffect(() => {
    }, [])


    const InfoComponent = ({label, value}) => !!value && (
        <div className="info__info">
            <h5 className="info__name">{label}</h5>
            <p className="info__value">{value}</p>
        </div>
    )

    const CustomAddressComponent = () => (
        <div>
            <h3 className="evDP__title">{t('ev_delivery_payment.custom_delivery_address')}</h3>
            <div className="info">
                {props.customer.customCompanyAddress ? (
                    <>
                        <InfoComponent label={t('company_name')} value={props.customer.customCompanyName}/>
                        <InfoComponent label={t('tax_id')} value={props.customer.customTaxId}/>
                    </>
                ) : (
                    <InfoComponent label={t('title')} value={props.customer.customTitle?.label}/>
                )}
                <div className="info__row">
                    <InfoComponent label={t('name')} value={props.customer.customName}/>
                    <InfoComponent label={t('surname')} value={props.customer.customSurname}/>
                </div>
                <div className="info__row">
                    <InfoComponent label={t('postcode')} value={props.customer.customPostcode}/>
                    <InfoComponent label={t('city')} value={props.customer.customCity}/>
                </div>
                <InfoComponent label={t('address_line_1')} value={props.customer.customAddress}/>
                <InfoComponent label={t('address_line_2')} value={props.customer.customAddress2}/>
                <InfoComponent label={t('phone')} value={props.customer.customPhone}/>
                <InfoComponent label={t('phone_mobile')} value={props.customer.customPhoneMobile}/>
                <InfoComponent label={t('phone_home')} value={props.customer.customPhoneHome}/>
            </div>
        </div>
    )

    const SummaryComponent = () => {
        let subtotal = formatPrice(capsulesValue)
        let tax = formatPrice(capsulesVatValue)
        let total = formatPrice(cartValue + deliveryValue)

        if(![Config.PROMO_TYPE.EASY_ORDER].includes(props.promotion.promotion_type) && props.cartQuotationData) {
            subtotal = formatPrice(props.cartQuotationData.finance.amount.total)
            tax = formatPrice(props.cartQuotationData.finance.amount.totalTax)
            total = formatPrice(props.cartQuotationData.finance.amount.toPay)
        }
        return (
            <div className="summary">
                {!marketConfig.hide_summary_subtotal_value && (
                    <div className="summary__row">
                        <h5 className="summary__name">{t('ev_delivery_payment.subtotal')}</h5>
                        <p className="summary__value">{subtotal}</p>
                    </div>
                )}
                {!marketConfig.hide_summary_benefit_value && (
                    <div className="summary__row">
                        <h5 className="summary__name --bold">{t('ev_delivery_payment.benefit')}</h5>
                        <p className="summary__value --bold">{formatPrice(giftValue)}</p>
                    </div>
                )}
                {!marketConfig.hide_summary_tax_value && (
                    <div className="summary__row">
                        <h5 className="summary__name">{t('ev_delivery_payment.tax')}</h5>
                        <p className="summary__value">{tax}</p>
                    </div>
                )}
                {!marketConfig.hide_summary_delivery_value && (
                    <div className="summary__row --mb4">
                        <h5 className="summary__name">{t('ev_delivery_payment.delivery')}</h5>
                        <p className="summary__value">{deliveryValue > 0 ? formatPrice(deliveryValue) : t('free')}</p>
                    </div>
                )}
                {/*{!!t('ev_delivery_payment.delivery_info') && (*/}
            {/*    <p className="par-4 --mb4" style={{textAlign: 'right'}}>{t('ev_delivery_payment.delivery_info')}</p>*/}
            {/*)}*/}<div className="summary__row">
                    <h5 className="summary__name">{t('ev_delivery_payment.total')}</h5>
                    <p className="summary__value --success --bold">{total}</p>
                </div>
            </div>
        )
    }

    const withCustomAddress = !!props.customer?.withCustomAddress;
    const memberCredit = calcMemberCredit(props.memberCredit, props.cartQuotationData ? props.cartQuotationData.finance.amount.toPay : cartValue + deliveryValue)


    return (
        <div>

            <div className="evDP__info">
                <div className="grid">
                    <div style={{}}/>
                    <div className="evDP__cart" style={{marginTop: `-${getStepHeaderHeight() * (steps.length - 2)}px`}}>
                        <CartSummarySimple
                            storeName="of"
                            cart={store.cart}
                            categories={store.categories}
                            products={store.products}
                            disableFooter
                        />
                    </div>
                </div>
            </div>



            <div className="evDP__info">
                <div className="grid">
                    <div>
                        <h3 className="evDP__title">{t('ev_delivery_payment.delivery_address')}</h3>
                        <div className="info">
                            {props.customer.companyAddress ? (
                                <>
                                    <InfoComponent label={t('company_name')} value={props.customer.companyName}/>
                                    <InfoComponent label={t('tax_id')} value={props.customer.taxId}/>
                                </>
                            ) : (
                                <InfoComponent label={t('title')} value={props.customer.title?.label}/>
                            )}
                            <div className="info__row">
                                <InfoComponent label={t('name')} value={props.customer.name}/>
                                <InfoComponent label={t('surname')} value={props.customer.surname}/>
                            </div>
                            <div className="info__row">
                                <InfoComponent label={t('postcode')} value={props.customer.postcode}/>
                                <InfoComponent label={t('city')} value={props.customer.city}/>
                            </div>
                            <InfoComponent label={t('address_line_1')} value={props.customer.address}/>
                            <InfoComponent label={t('address_line_2')} value={props.customer.address2}/>
                            <InfoComponent label={t('phone')} value={props.customer.phone}/>
                            <InfoComponent label={t('phone_mobile')} value={props.customer.phoneMobile}/>
                            <InfoComponent label={t('phone_home')} value={props.customer.phoneHome}/>
                            <InfoComponent label={t('email')} value={props.customer.email}/>
                        </div>
                    </div>
                    {withCustomAddress ? (
                        <CustomAddressComponent/>
                    ) : (
                        <SummaryComponent/>
                    )}
                </div>
                {withCustomAddress && (
                    <>
                        <hr className="-light --mb4"/>
                        <div className="grid">
                            <div/>
                            <SummaryComponent/>
                        </div>
                    </>
                )}
                <div className="grid">
                    <div/>
                    <div className="evDP__summaryInfo">
                        <p className="par-4">{t('ev_delivery_payment.total_info')}</p>
                        <p className="par-4">{t('ev_delivery_payment.summary_info')}</p>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div>
                </div>
                <div>
                    <div className="summary__row --mb4">
                        <div className="summary__name --bold">{t('payment_type')}</div>
                        <div className="summary__value -bold">{localizeObject(store.finishOrder?.payment_method_title)}</div>
                    </div>

                    {!!props.memberCredit && (memberCredit?.available > 0) && (
                      <div className="evDP__info">
                          <p className="par-2">{t('member_credit.text')}</p>
                          <p className="par-2">
                              {t('member_credit.available_credit', formatPrice(memberCredit.available))} <br/>
                              {t('member_credit.remaining_after', formatPrice(memberCredit.remaining))} <br/>
                              {t('member_credit.charge', formatPrice(memberCredit.charge))} <br/>
                          </p>
                      </div>
                    )}
                    <div className="step__nav -right -bottom">
                        <RecaptchaV2 onChange={props.onChangeRecaptchaToken}/>
                    </div>
                    <div className="step__nav -right -bottom --mb4">
                        <Link to={stepNavigation.prev()}>
                            <Button
                                type="primary-outline"
                                size="sm"
                                disabled={props.loading}
                                label={t('back')}
                            />
                        </Link>
                        <Button
                            type="primary"
                            label={store.order ? t('continue') : t('buy')}
                            disabled={props.loading || !props.recaptchaToken || props.cartQuotationState.loading || !props.cartQuotationData}
                            onClick={props.onSubmit}
                        />
                    </div>
                    {!!props.error && (
                        <div className="step__nav -right">
                            <Alert type="error" text={t(`error.${props.error}`) ?? props.error}/>
                        </div>
                    )}
                    {!!(props.loading || props.cartQuotationState.loading) && <Preloader/>}
                </div>
            </div>
        </div>
    )
}

