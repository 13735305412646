import '../../../assets/style/vendors/skeleton.css';
import {Preloader} from "../../../misc/_index";
import {useState} from "react";
import {useTranslation} from "../../../misc/Hooks";
import {GetInTouchModal} from "../../../components/modals/_index";


export default function OrderFinalizedScreen(props) {
    const t = useTranslation()
    const [gitModalVisible, setGitModalVisible] = useState(false)


    return (
        <div className="wrapper">
            {props.loading ? (
                <Preloader/>
            ) : props.data ? (
                <div className="par-2">
                    <div dangerouslySetInnerHTML={{__html: props.data.thank_you_page_text}}/>
                </div>
            ) : (
                <div className="--mb4" style={{textAlign: 'center'}}>
                    <h1 className="par-1">{t('order_finish.order_not_found.title')}</h1>
                    <p className="par-2">{t('order_finish.order_not_found.text')}</p>
                </div>
            )}


            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />
        </div>
    )
}
