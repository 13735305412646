import RegistrationScreen from "../screens/RegistrationScreen";
import * as shopActions from '../../../actions/ShopActions';
import * as authActions from '../../../actions/AuthorizationActions';
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "../../../misc/Hooks";
import {Error404} from "../../../components/misc/_index";

let MOUNTED = false;

export default function RegistrationContainer(props) {
    const params = useParams()
    const t = useTranslation();
    const store = useSelector(({main}) => ({
        country: main.country,
    }));

    const [shops, setShops] = useState(null)
    const [shopFromToken, setShopFromToken] = useState(null)
    const [sendingStatus, setSendingStatus] = useState({
        status: '',
        errors: {},
        message: '',
    })
    const [blockButton, setBlockButton] = useState(false)
    const [tokenNotExist, setTokenNotExist] = useState(false)

    useEffect(() => {
        getShops()
        if (params.token) {
            getShopByToken(params.token)
        }
    }, [params.token])

    const getShopByToken = (token) => {
        shopActions.getShopByToken(token).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                setShopFromToken(res.data)
            } else {
                console.warn('Something went wrong!', res)
                setShopFromToken(null)

                if (res.messages[0] === 'shop_not_exist') {
                    setTokenNotExist(true)
                }
            }
        }).catch(e => {
            console.error('Error: ', e)
        })
    }

    const getShops = () => {
        shopActions.getAll(store.country.id_country).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                setShops(res.data)
            } else {
                console.log('Somethig gone wrong! ', res);
            }
        })
    }

    const onSubmit = (data) => {
        const formData = {
            id_shop: data.shop.value ?? 0,
            reseller_email: data.email ?? '',
            reseller_firstname: data.firstname ?? '',
            reseller_lastname: data.lastname ?? '',
            reseller_login: data.login ?? '',
            reseller_number: data.number ?? 0,
        }

        setBlockButton(true)

        authActions.registerReseller(formData).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                setSendingStatus({
                    status: 'success',
                    errors: {},
                    message: t('reseller_registration.register_success')
                })
                setBlockButton(false)

            } else {
                console.log('Somethig gone wrong! ', res)
                setSendingStatus({
                    status: 'error',
                    errors: res.messages[0],
                    message: ''
                })
                setBlockButton(false)
            }
        }).catch(e => {
            console.warn('Error: ', e)
            setSendingStatus({
                status: 'error',
                errors: {},
                message: t('reseller_registration.register_error.global')
            })
            setBlockButton(false)

        })

    }

    if(params.token && tokenNotExist){
        return (
            <Error404/>
        )
    }

    return (
        <RegistrationScreen
            shops={shops}
            onSubmit={onSubmit}
            shopFromToken={shopFromToken}
            loading={(!!shops) && (params.token ? !!(shopFromToken) : !(shopFromToken))}
            sendingStatus={sendingStatus}
            blockButton={blockButton}
        />
    )
}
