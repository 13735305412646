import React, {useState} from 'react';
import {ProofOfPurchaseForm, SerialNumberForm} from "../forms/_index";
import {getImage} from "../../misc/Helpers";
import {MyInput} from "../forms/inputs/_index";
import BaseModal from "../modals/BaseModal";
import {useTranslation} from "../../misc/Hooks";
import {useSelector} from "react-redux";


interface Props {
    promotion: any,
    machine: any,
    serialNumber: any,
    serialNumberError: any,
    proofOfPurchaseData: any,
    proofOfPurchase: any,
    shops: [],
    validateSerialNumber: void,
    onUploadSerialNumberFile: void,
    onUploadPopFile: void,
    onSubmitSerialNumber: void,
    onSubmitProofOfPurchase: void,
}


const MachineRegistrationStep = (props: Props) => {
    const t = useTranslation();
    const store = useSelector(({main, ev}) => ({
        display: main.display,
        promoCode: ev.promoCode,
    }))
    const [editSNPopup, setEditSNPopup] = useState(false);


    return (
        <div className="evMR">

            <div className="--mb4">
                {!!t('ev_machine_registration.text1') && (
                    <div className="grid --mb3">
                        <div>
                            <p
                                className="par-1 --semiBold"
                                dangerouslySetInnerHTML={{__html: t('ev_machine_registration.text1')}}
                            />
                        </div>
                        <div/>
                    </div>
                )}
                {!props.machine ? (
                    <SerialNumberForm
                        data={props.serialNumber}
                        machine={props.validatedMachine}
                        error={props.serialNumberError}
                        loading={props.serialNumberLoading}
                        validateSerialNumber={props.validateSerialNumber}
                        onUploadFile={props.onUploadSerialNumberFile}
                        onSubmit={props.onSubmitSerialNumber}
                        onRemoveFile={props.onRemoveSerialNumberFile}
                    />
                ) : (
                    <div className="grid">
                        <div>
                            <h5 className="par-2 --semiBold">{t('my_machine')}</h5>
                            <div className="evMR__machine">
                                <div className="evMR__image">
                                    <img src={getImage(props.machine.machine_gallery)} alt=""/>
                                </div>
                                <div className="evMR__content">
                                    <h3 className="par-2">{props.machine.machine_title}</h3>
                                    <MyInput
                                        label={t('serial_number')}
                                        value={props.serialNumber?.serial_number}
                                        readonly
                                    />
                                    <div className="--mb4">
                                        <div className="--mb2">
                                            <button
                                                className="link -primary"
                                                onClick={() => setEditSNPopup(true)}
                                            >{t('edit_serial_number')} <img src={require('../../assets/img/icons/edit.svg').default} alt="" style={{width: '12px'}}/></button>
                                        </div>

                                        <p
                                            className="par-4"
                                            dangerouslySetInnerHTML={{__html: t('ev_machine_registration.text3')}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div/>
                    </div>
                )}
            </div>

            {!!props.machine && (
                <div className="grid">
                    <div>

                        <ProofOfPurchaseForm
                            user={props.user}
                            code={store.promoCode?.promo_code_name}
                            data={props.proofOfPurchase}
                            ocrData={props.proofOfPurchaseData}
                            error={props.proofOfPurchaseError}
                            codeError={props.promoCodeError}
                            loading={props.proofOfPurchaseLoading}
                            loadingPromoCode={props.promoCodeLoading}
                            shops={props.shops}
                            onFileUpload={props.onUploadPopFile}
                            onSubmit={props.onSubmitProofOfPurchase}
                            onChangePromoCode={props.onChangePromoCode}
                        />

                    </div>
                    <div/>
                </div>
            )}


            <BaseModal
                visible={editSNPopup}
                onClose={() => setEditSNPopup(false)}
                small
            >
                <SerialNumberForm
                    data={props.serialNumber}
                    machine={props.validatedMachine}
                    error={props.serialNumberError}
                    loading={props.serialNumberLoading}
                    validateSerialNumber={props.validateSerialNumber}
                    onUploadFile={props.onUploadSerialNumberFile}
                    onSubmit={data => {
                        props.onSubmitSerialNumber(data);
                        setEditSNPopup(false);
                    }}
                    onRemoveFile={props.onRemoveSerialNumberFile}
                />
            </BaseModal>

        </div>
    );
}


export default MachineRegistrationStep;
