import {useMarketConfig, useNavigation, useTranslation} from "../../misc/Hooks";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Routes} from "../../utils/_index";
import {Button} from "../buttons/_index";


export default (props) => {
    const history = useHistory();
    const navigation = useNavigation();
    const marketConfig = useMarketConfig()
    const dispatch = useDispatch();
    const t = useTranslation();
    const store = useSelector(({main, ev}) => ({
        user: main.user,
    }))


    return (
        <div className="resHome">
            <div className={`resellerOptions`}>
                <div className="resellerOptions__header">
                    <h2 className="par-1">{t('reseller_options.title')}</h2>
                </div>
                <div className="resellerOptions__items">
                    {!(store.user.reseller_role === 'pos_user') && (
                        <div className="resellerOptions__item">
                            <img
                                src={require('../../assets/img/icons/assisted-ic-light.svg').default}
                                alt=""
                                className="resellerOptions__bg"
                            />

                            <div className="resellerOptions__thumb">
                                <img src={require('../../assets/img/icons/assisted-ic.svg').default} alt="" className="img-fluid"/>
                            </div>
                            <h4 className="resellerOptions__label">{t('reseller_options.users')}</h4>
                            <div className="resellerOptions__button">
                                <Link to={navigation(Routes.RESELLER_USERS)}>
                                    <Button label={t('select')}/>
                                </Link>
                            </div>
                        </div>
                    )}
                    <div className="resellerOptions__item">
                        <img
                            src={require('../../assets/img/icons/report-ic-light.svg').default}
                            alt=""
                            className="resellerOptions__bg"
                        />

                        <div className="resellerOptions__thumb">
                            <img src={require('../../assets/img/icons/report-ic.svg').default} alt="" className="img-fluid"/>
                        </div>
                        <h4 className="resellerOptions__label">{t('reseller_options.reports')}</h4>
                        <div className="resellerOptions__button">
                            <Link to={navigation(Routes.RESELLER_REPORTS)}>
                                <Button label={t('select')}/>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
