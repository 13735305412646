import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import {MyInput, RecaptchaV2} from "./inputs/_index";
import Patterns from "../../utils/Patterns";
import {useEvStepNavigation, useTranslation} from "../../misc/Hooks";
import {Button} from "../buttons/_index";


export default (props) => {
    const t = useTranslation();
    const {control, errors, watch, setError, handleSubmit} = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            password: '',
            rePassword: '',
            recaptchaToken: '',
        }
    });
    const password = watch('password');

    useEffect(() => {
        if(props.error) {
            setError('password', {
                type: 'incorrect',
                message: t(`error.${props.error}`)
            })
            setError('rePassword', {
                type: 'incorrect',
                message: t(`error.${props.error}`)
            })
        }
    }, [props.error])

    const rules = {
        password: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: Patterns.password, message: t('validation.password')},
        },
        rePassword: {
            required: {value: true, message: t('validation.required')},
            validate: v => v === password ? true : t('validation.same_as', t('password')),
        },
        recaptchaToken: {
            required: {value: true, message: t('validation.required')}
        },
    }


    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>

                <div className="--mb4">
                    <Controller
                        name="password"
                        control={control}
                        rules={rules.password}
                        render={({onChange, value}) => (
                            <MyInput
                                name="password"
                                type="password"
                                label={t('password')}
                                value={value}
                                error={errors.password}
                                onChange={onChange}
                            />
                        )}
                    />

                    <Controller
                        name="rePassword"
                        control={control}
                        rules={rules.rePassword}
                        render={({onChange, value}) => (
                            <MyInput
                                name="rePassword"
                                type="password"
                                label={t('re_password')}
                                value={value}
                                error={errors.rePassword}
                                onChange={onChange}
                            />
                        )}
                    />


                    <Controller
                        name="recaptchaToken"
                        control={control}
                        rules={rules.recaptchaToken}
                        render={({onChange, value}) => (
                            <RecaptchaV2
                                value={value}
                                onChange={onChange}
                                error={errors.recaptchaToken}
                            />
                        )}
                    />
                </div>


                <div className="step__nav">
                    {/*<Link to={stepNavigation.prev()}>*/}
                    {/*    <Button*/}
                    {/*        type="primary-outline"*/}
                    {/*        label={t('back')}*/}
                    {/*    />*/}
                    {/*</Link>*/}
                    <Button
                        btnType="submit"
                        type="primary"
                        label={props.submitText ?? t('continue')}
                        disabled={props.loading}
                        onClick={handleSubmit(props.onSubmit)}
                    />
                </div>
            </form>
        </>
    );
}
