import {ForgotPasswordResellerForm} from "../../forms/reseller";
import {useTranslation} from "../../../misc/Hooks";


export default function ForgotPasswordReseller(props) {
    const t = useTranslation();

    return (
        <div>

            <div className="--mb4">
                <h1 className="par-1">{t('forgot_password_reseller.title')}</h1>
                <p className="par-2" dangerouslySetInnerHTML={{__html: t('forgot_password_reseller.text')}}/>
            </div>

            <div className="--mb4">
                <ForgotPasswordResellerForm
                    loading={props.processing}
                    emailNotSent={props.emailNotSent}
                    onSubmit={props.onSubmit}
                />
            </div>

            <p className="par-4" dangerouslySetInnerHTML={{__html: t('forgot_password_reseller.bottom_text')}}/>

        </div>
    )
}
