import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import OrderFinalized from "../components/OrderFinalized";
import * as Actions from '../actions/MainActions';
import Axios from "../utils/Axios";
import {useParams, useRouteMatch} from "react-router-dom";
import {AppProvider, GTM} from "../misc/_index";
import {useLanguage} from "../hooks";
import {getThankYouPage} from "../actions/MainActions";
import {useTranslation} from "../misc/Hooks";


export default function OrderFinalizedContainer(props) {
    const params = useParams()
    const t = useTranslation()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)


    useEffect(() => {
        document.title = t('order_finalized.title')
        GTM.showPage(t('order_finalized.title'))

        fetchTyp(params.token)
            .then(() => setLoading(false))
    }, [])


    const fetchTyp = async (uuid) => {
        await getThankYouPage(uuid)
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    setData(res.data)
                } else {
                    console.warn(res)
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    return (
        <AppProvider>
            <OrderFinalized loading={loading} data={data}/>
        </AppProvider>
    )
}
