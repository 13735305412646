import React from 'react';
import {Controller, useForm} from "react-hook-form";
import {MyInput} from "./inputs/_index";
import {useTranslation} from "../../misc/Hooks";


export default (props) => {
    const t = useTranslation();
    const {control, errors, handleSubmit} = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            slug: props.data ?? '',
        }
    });


    const rules = {
        slug: {

        },
    }

    return (
        <div className="searchForm">
            <form onSubmit={handleSubmit(props.onSubmit)}>

                <Controller
                    name="slug"
                    control={control}
                    rules={rules.slug}
                    render={({onChange, value}) => (
                        <MyInput
                            name="search"
                            value={value}
                            label={t('table.search')}
                            onChange={onChange}
                            raw
                        />
                    )}
                />

                <button type="submit">
                    <img src={require('../../assets/img/icons/search.svg').default} alt="search"/>
                </button>
            </form>
        </div>
    );
}
