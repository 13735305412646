import {Routes} from "./_index";
import * as EV from "../containers/ev/_index";
import * as OF from "../containers/order/_index";
import * as SH from "../containers/stay_home/_index";
import * as RMS from "../containers/reseller/machine_subscription/_index";
import * as ETRADE from "../modules/etrade/containers"
import * as STAY_HOME from "../modules/stay_home/containers"
import * as ORDER_FINISH from "../modules/order_finish/containers"
import Env from "./Env";


export default {
    VERSION: '22.2.4.4',
    EV_SESSION_TIME: 1000 * 60 * 10,
    RESELLER_SESSION_TIME: 1000 * 60 * 20,
    ETRADE_SESSION_TIME: 1000 * 60 * 20,
    RESELLER_MACHINE_SUBSCRIPTION_SESSION_TIME: 1000 * 60 * 20,
    EV_STEPS: [
        {
            route: Routes.SHOP_MACHINE_REGISTRATION,
            title: 'ev_machine_registration.title',
            component: <EV.MachineRegistrationStepContainer/>
        },
        {
            route: Routes.SHOP_CUSTOMER_IDENTIFICATION,
            title: 'ev_customer_identification.title',
            component: <EV.CustomerIdentificationStepContainer/>
        },
        {
            route: Routes.SHOP_PROMO_SELECTION,
            title: 'ev_promo_selection.title',
            component: <EV.PromoSelectionStepContainer/>
        },
        {
            route: Routes.SHOP_COFFEE_SELECTION,
            title: 'ev_coffee_selection.title',
            component: <EV.CoffeeSelectionStepContainer/>
        },
        {
            route: Routes.SHOP_CUSTOMER_INFORMATION,
            title: 'ev_customer_information.title',
            component: <EV.CustomerInformationStepContainer/>
        },
        {
            route: Routes.SHOP_DELIVERY_PAYMENT,
            title: 'ev_delivery_payment.title',
            component: <EV.DeliveryPaymentStepContainer/>
        },
    ],
    ORDER_FINISH_STEPS: [
        {
            route: Routes.ORDER_FINISH_AUTHORIZATION,
            title: 'finish_order_account.title',
            component: <ORDER_FINISH.AuthorizationContainer/>
        },
        {
            route: Routes.ORDER_FINISH_CUSTOMER,
            title: 'finish_order_customer.title',
            component: <ORDER_FINISH.CustomerContainer/>
        },
        {
            route: Routes.ORDER_FINISH_SUMMARY,
            title: 'finish_order_summary.title',
            component: <ORDER_FINISH.SummaryContainer/>
        },
        // {
        //     route: Routes.ORDER_FINISH_PAYMENT,
        //     title: 'finish_order_payment.title',
        //     component: <ORDER_FINISH.PaymentContainer/>
        // },
    ],
    OF_STEPS: [
        {
            route: Routes.FINISH_ORDER_ACCOUNT,
            title: 'finish_order_account.title',
            component: <OF.AccountContainer/>
        },
        {
            route: Routes.FINISH_ORDER_CUSTOMER,
            title: 'finish_order_customer.title',
            component: <OF.CustomerContainer/>
        },
        {
            route: Routes.FINISH_ORDER_SUMMARY,
            title: 'finish_order_summary.title',
            component: <OF.SummaryContainer/>
        },
        {
            route: Routes.FINISH_ORDER_PAYMENT,
            title: 'finish_order_payment.title',
            component: <OF.PaymentContainer/>
        },
    ],
    RMS_STEPS: [
        {
            route: Routes.RESELLER_MACHINE_SUBSCRIPTION_MACHINE,
            title: 'r_ms_machine.title',
            component: <RMS.MachineContainer/>
        },
        {
            route: Routes.RESELLER_MACHINE_SUBSCRIPTION_IDENTIFICATION,
            title: 'r_ms_identification.title',
            component: <RMS.IdentificationContainer/>
        },
        {
            route: Routes.RESELLER_MACHINE_SUBSCRIPTION_PLAN,
            title: 'r_ms_plan.title',
            component: <RMS.PlanSelectionContainer/>
        },
        {
            route: Routes.RESELLER_MACHINE_SUBSCRIPTION_CUSTOMER,
            title: 'r_ms_customer.title',
            component: <RMS.CustomerContainer/>
        },
        {
            route: Routes.RESELLER_MACHINE_SUBSCRIPTION_SUMMARY,
            title: 'r_ms_summary.title',
            component: <RMS.SummaryContainer/>
        },
    ],
    SH_STEPS: [
        {
            route: Routes.STAY_HOME_AUTHORIZATION,
            title: 'sh_authorization.title',
            component: <SH.AuthorizationController/>
        },
        {
            route: Routes.STAY_HOME_PLAN_SELECTION,
            title: 'sh_plan_selection.title',
            component: <SH.PlanSelectionController/>
        },
        // {
        //     route: Routes.STAY_HOME_COFFEE_SELECTION,
        //     title: 'sh_coffee_selection.title',
        //     component: <SH.CoffeeSelectionController/>
        // },
        {
            route: Routes.STAY_HOME_CUSTOMER,
            title: 'sh_customer_registration.title',
            component: <SH.CustomerRegistrationController/>
        },
        {
            route: Routes.STAY_HOME_SUMMARY,
            title: 'sh_summary.title',
            component: <SH.SummaryController/>
        },
    ],
    STAY_HOME_STEPS: [
        {
            route: Routes.STAY_HOME,
            title: 'stay_home.identification.title',
            component: <STAY_HOME.IdentificationContainer/>
        },
        {
            route: Routes.STAY_HOME_AUTHORIZATION,
            title: 'stay_home.authorization.title',
            component: <STAY_HOME.AuthorizationContainer/>
        },
        {
            route: Routes.STAY_HOME_PLAN_SELECTION,
            title: 'stay_home.plan_selection.title',
            component: <STAY_HOME.PlanSelectionController/>
        },
        {
            route: Routes.STAY_HOME_CUSTOMER,
            title: 'stay_home.customer.title',
            component: <STAY_HOME.CustomerContainer/>
        },
        {
            route: Routes.STAY_HOME_SUMMARY,
            title: 'stay_home.summary.title',
            component: <STAY_HOME.SummaryContainer/>
        },
    ],
    ETRADE_STEPS: [
        {
            route: Routes.ETRADE_MACHINE_PLAN,
            title: 'etrade.machine_plan.title',
            component: <ETRADE.MachinePlanContainer/>
        },
        {
            route: Routes.ETRADE_AUTHORIZATION,
            title: 'etrade.authorization.title',
            component: <ETRADE.AuthorizationContainer/>
        },
        {
            route: Routes.ETRADE_CUSTOMER,
            title: 'etrade.customer.title',
            component: <ETRADE.CustomerContainer/>
        },
        {
            route: Routes.ETRADE_SUMMARY,
            title: 'etrade.summary.title',
            component: <ETRADE.SummaryContainer/>
        },
    ],
    GALLERY_TYPE: {
        COVER: 'cover',
        BACKGROUND: 'background',
    },
    COFFEE_TYPE: {
        CAPSULE: 'capsule',
        PACKAGE: 'package',
        GIFT: 'gift',
        PLAN_MACHINE: 'plan_machine',
        PLAN_COFFEE: 'plan_coffee',
        PLAN_WORK_HOME: 'plan_work_home',
        DELIVERY: 'delivery',
        REBATE: 'rebate',
        EASY_ORDER_GIFT: 'easy_order_gift',
        ACCESSORY: 'accessory',
    },
    PROMO_TYPE: {
        REGISTER_MACHINE: 'register_machine',
        SUBSCRIPTION_COFFEE: 'subscription_coffee',
        SUBSCRIPTION_MACHINE: 'subscription_machine',
        EASY_ORDER: 'easy_order',
        STAY_HOME: 'stay_home',
    },
    FILTER: {
        INTENSITY: ['1-4', '5-8', '9-13'],
    },
    TITLES: [
        {label: 'person_title.mr', value: 'mr'},
        {label: 'person_title.mrs', value: 'mrs'},
    ],
    RESELLER_COUNTRY: Env.RESELLER_COUNTRY,
    SUBSCRIPTION_STATUS: {
        READY: 'ready',
        PENDING: 'pending',
        // PROCESSED: 'processed',
        PROCESSED: 'ready',
        REJECTED: 'rejected',
    },
    STORE_ACTION: {
        EV_CLIENT: 'ev_client',
        EV_RESELLER: 'ev_reseller',
        FINISH_ORDER: 'finish_order',
        RESELLER_MACHINE_SUBSCRIPTION: 'reseller_machine_subscription',
        STAY_HOME: 'stay_home',
    },
    RECAPTCHA_DEV: '6LeHdckUAAAAADYG64VxOlGN3EQWCLCA0Ato986l',
    RTL_LANGUAGES: ['he'],
}
