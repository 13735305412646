import Env from "../utils/Env";


class GTM {
    static marketCode = undefined;
    static currency = undefined;
    static version = process.version;
    static isProduction = Env.ENV === 'prod';

    static configure(marketCode: string, currency: string) {
        this.marketCode = marketCode;
        this.currency = currency;
        this.currency = currency;
    }

    static async isConfiguredAsync(count = 30) {
        if(Env.GTM_ID) {
            if(this.isConfigured()) {
                return true;
            } else if(count > 0) {
                await new Promise(resolve => setTimeout(resolve, 500))
                return this.isConfiguredAsync(count - 1);
            }
            console.error('GTM configuration error.')
        }
        return false;
    }

    static isConfigured() {
        return Env.GTM_ID && this.marketCode && this.currency;
    }

    static async showPageAsync(pageName) {
        const isConfigured = await this.isConfiguredAsync();
        if(!isConfigured)
            return;

        const obj = {
            dataLayer: {
                isEnvironmentProd: this.isProduction,
                pageName,
                pageType: '',
                pageTechnology: '',
                market: this.marketCode,
                version: this.version,
                landscape: 'Meatnet',
                segmentBusiness: 'B2C',
                currency: `[[${this.currency}]]`,
                clubMemberID: null,
                clubMemberStatus: false,
                clubMemberLevel: '',
                clubMemberLevelID: '',
                clubMemberTitle: '',
                clubMemberLoginStatus: false,
                machineOwner: '',
                machineOwned: '',
                preferredTechnology: '',

                event: 'event_pageView',
            },
            dataLayerName: 'PageDataLayer',
        }


        // console.log(window.dataLayer)
        // console.log('push page', pageName)
        // TagManager.dataLayer(obj);
        window.dataLayer.push(obj);
    }

    static showPage(pageName) {
        this.showPageAsync(pageName).then(() => undefined).catch(() => undefined)
    }
}


export default GTM;
