import React, {useEffect, useState} from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyCheckbox, MyInput, MyInputMask, MySelect, RecaptchaV2} from "../inputs/_index";
import Patterns from "../../../utils/Patterns";
import {Button} from "../../buttons/_index";
import {getFieldFormat, scrollVertically} from "../../../misc/Helpers";
import {
    useCountryConfig,
    useLocalizeFile,
    useLocalizeObject,
    useMarketConfig,
    useTranslation
} from "../../../misc/Hooks";
import {BenefitsInfo, TermsInfo} from "../../misc/_index";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {FormFragmentBenefits} from "../fragments";
import {useStepsNavigation} from "../../../hooks/modules/etrade";
import {useCivilityOptions, useCountryOptions, useMemberPhoneMask} from "../../../hooks";
import {isEnabledByMarket} from "../../../utils/MarketConfig";


export default function CustomerForm(props) {
    const t = useTranslation();
    const localizeFile = useLocalizeFile();
    const countryConfig = useCountryConfig();
    const marketConfig = useMarketConfig();
    const stepNavigation = useStepsNavigation();
    const store = useSelector(({main}) => ({
        civilities: main.civilities,
        country: main.country,
    }))

    const countryOptions = useCountryOptions();
    const civilityOptions = useCivilityOptions();


    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            memberAddress: props.customer?.memberAddress ?? null,
            memberPhone: props.customer?.phone ?? '',
            anotherAddress: props.nespressoMember ? props.customer?.anotherAddress ? true : false : true,

            companyAddress: !!props.customer?.companyAddress,
            companyName: props.customer?.companyName ?? '',
            taxId: props.customer?.taxId ?? '',
            title: props.customer?.title ? civilityOptions.find(props.customer.title.value) : null,
            name: props.customer?.name ?? '',
            surname: props.customer?.surname ?? '',
            postcode: props.customer?.postcode ?? '',
            city: props.customer?.city ?? '',
            address: props.customer?.address ?? '',
            address2: props.customer?.address2 ?? '',
            lang: props.customer?.lang ? countryOptions.findLanguage(o => o.value === props.customer.lang.value) : null,
            country: props.customer?.country ? countryOptions.findCountry(props.customer.country.value) : null,
            phone: props.customer?.phone ?? '',
            phoneMobile: props.customer?.phoneMobile ?? '',
            phoneHome: props.customer?.phoneHome ?? '',
            email: props.customer?.email ?? '',
            reEmail: props.customer?.reEmail ?? '',
            password: props.customer?.password ?? '',
            rePassword: props.customer?.rePassword ?? '',

            withCustomAddress: !!props.customer?.withCustomAddress,
            customCompanyAddress: !!props.customer?.customCompanyAddress,
            customCompanyName: props.customer?.customCompanyName ?? '',
            customTaxId: props.customer?.customTaxId ?? '',
            customTitle: props.customer?.customTitle ? civilityOptions.find(props.customer.customTitle.value) : null,
            customName: props.customer?.customName ?? '',
            customSurname: props.customer?.customSurname ?? '',
            customPostcode: props.customer?.customPostcode ?? '',
            customCity: props.customer?.customCity ?? '',
            customAddress: props.customer?.customAddress ?? '',
            customAddress2: props.customer?.customAddress2 ?? '',
            customPhone: props.customer?.customPhone ?? '',
            customPhoneMobile: props.customer?.customPhoneMobile ?? '',
            customPhoneHome: props.customer?.customPhoneHome ?? '',

            benefitsEmail: !!props.customer?.benefitsEmail,
            benefitsPhone: !!props.customer?.benefitsPhone,
            benefits: {
                email: [3,4].includes(props.plan.product_plan_marketing_email_visible),
                mobile: [3,4].includes(props.plan.product_plan_marketing_mobile_visible),
                phone: [3,4].includes(props.plan.product_plan_marketing_phone_visible),
                post: [3,4].includes(props.plan.product_plan_marketing_post_visible),
                ...props.customer?.benefits
            },

            termsAndConditions: !!props.customer?.termsAndConditions,
            privacyPolicy: !!props.customer?.privacyPolicy,

            paymentMethod: props.customer?.paymentMethod ?? null,

            accountNumber: props.customer?.accountNumber ?? '',
            bank: props.customer?.bank ?? null,
            verificationCode: '',

            recaptchaToken: '',
        }
    });

    const memberPhoneMask = useMemberPhoneMask(formMethods)

    const memberAddress = formMethods.watch('memberAddress');
    const anotherAddress = formMethods.watch('anotherAddress');
    const companyAddress = formMethods.watch('companyAddress');
    const withCustomAddress = formMethods.watch('withCustomAddress');
    const customCompanyAddress = formMethods.watch('customCompanyAddress');
    const paymentMethod = formMethods.watch('paymentMethod');
    const accountNumber = formMethods.watch('accountNumber');

    const [deliveryOptions, setDeliveryOptions] = useState([]);

    const fieldsConfigs = {
        taxId: getFieldFormat(countryConfig.custom_format?.custom_tax_id_format),
        companyTaxId: getFieldFormat(countryConfig.custom_format?.custom_company_tax_id_format),
        name: getFieldFormat(countryConfig.custom_format?.custom_firstname_format),
        surname: getFieldFormat(countryConfig.custom_format?.custom_lastname_format),
        postcode: getFieldFormat(countryConfig.custom_format?.custom_postcode_format),
        city: getFieldFormat(countryConfig.custom_format?.custom_city_format),
        address: getFieldFormat(countryConfig.custom_format?.custom_address1_format),
        address2: getFieldFormat(countryConfig.custom_format?.custom_address2_format),
        phone: getFieldFormat(countryConfig.custom_format?.custom_phone_format),
        phoneMobile: getFieldFormat(countryConfig.custom_format?.custom_phone_mobile_format),
        phoneHome: getFieldFormat(countryConfig.custom_format?.custom_phone_home_format),
        accountNumber: getFieldFormat(countryConfig.custom_format?.custom_bank_account_format),
    }

    const rules = {
        companyAddress: {},
        companyName: {
            required: {value: !!companyAddress, message: t('validation.required')},
        },
        taxId: {
            required: {value: !!fieldsConfigs.taxId.required, message: t('validation.required')},
            pattern: {value: fieldsConfigs.taxId.pattern, message: t('validation.invalid_tax_id') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.taxId.max_length,
                message: t('validation.max_length', fieldsConfigs.taxId.max_length)
            }
        },
        companyTaxId: {
            required: {value: !!fieldsConfigs.companyTaxId.required, message: t('validation.required')},
            pattern: {value: fieldsConfigs.companyTaxId.pattern, message: t('validation.invalid_tax_id') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.companyTaxId.max_length,
                message: t('validation.max_length', fieldsConfigs.companyTaxId.max_length)
            }
        },
        title: {
            required: {value: !companyAddress, message: t('validation.required')},
        },
        name: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.name.pattern ?? Patterns.onlyText, message: t('validation.invalid_name') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.name.max_length,
                message: t('validation.max_length', fieldsConfigs.name.max_length)
            },
        },
        surname: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.surname.pattern ?? Patterns.onlyText, message: t('validation.invalid_surname') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.surname.max_length,
                message: t('validation.max_length', fieldsConfigs.surname.max_length)
            },
        },
        postcode: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.postcode.pattern, message: t('validation.invalid_postcode') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.postcode.max_length,
                message: t('validation.max_length', fieldsConfigs.postcode.max_length)
            }
        },
        city: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.city.pattern, message: t('validation.invalid_city') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.city.max_length,
                message: t('validation.max_length', fieldsConfigs.city.max_length)
            }
        },
        address: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.address.pattern ?? Patterns.address, message: t('validation.invalid_address') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.address.max_length,
                message: t('validation.max_length', fieldsConfigs.address.max_length)
            }
        },
        address2: {
            required: {value: fieldsConfigs.address2.required, message: t('validation.required')},
            pattern: {value: fieldsConfigs.address2.pattern, message: t('validation.invalid_address') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.address2.max_length,
                message: t('validation.max_length', fieldsConfigs.address2.max_length),
            }
        },
        country: {
            required: {value: true, message: t('validation.required')}
        },
        lang: {
            required: {value: true, message: t('validation.required')}
        },
        phone: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.phone.pattern, message: t('validation.invalid_phone') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.phone.max_length,
                message: t('validation.max_length', fieldsConfigs.phone.max_length)
            }
        },
        phoneMobile: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.phoneMobile.pattern, message: t('validation.invalid_phone_mobile') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.phoneMobile.max_length,
                message: t('validation.max_length', fieldsConfigs.phoneMobile.max_length)
            }
        },
        phoneHome: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.phoneHome.pattern, message: t('validation.invalid_phone_home') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.phoneHome.max_length,
                message: t('validation.max_length', fieldsConfigs.phoneHome.max_length)
            }
        },
        email: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: Patterns.email, message: t('validation.invalid_email') || t('validation.invalid')},
            maxLength: {
                value: 50,
                message: t('validation.max_length', 50),
            }
        },
        reEmail: {
            required: {value: true, message: t('validation.required')},
            validate: v => v === formMethods.watch('email') ? true : t('validation.same_as', t('email')),
            maxLength: {
                value: 50,
                message: t('validation.max_length', 50),
            }
        },
        password: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: Patterns.password, message: t('validation.password')},
        },
        rePassword: {
            required: {value: true, message: t('validation.required')},
            validate: v => v === formMethods.watch('password') ? true : t('validation.same_as', t('password')),
        },

        customAddress: {},
        customCompanyName: {
            required: {value: !!customCompanyAddress, message: t('validation.required')},
        },
        customTitle: {
            required: {value: !customCompanyAddress, message: t('validation.required')},
        },

        benefitsEmail: {},
        benefitsPhone: {},
        termsAndConditions: {
            required: {value: true, message: t('validation.are_required', t('terms_and_conditions'))}
        },
        privacyPolicy: {
            required: {value: true, message: t('validation.is_required', t('privacy_policy'))}
        },
        paymentMethod: {
            required: {value: true, message: t('validation.required')},
        },

        accountNumber: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.accountNumber.pattern, message: t('validation.invalid')},
        },
        bank: {required: {value: true, message: t('validation.required')}},
        verificationCode: {required: {value: true, message: t('validation.required')}},

        recaptchaToken: {
            required: {value: true, message: t('validation.required')}
        },
    }


    const termsFileUrl = localizeFile(props.data?.etrade_files, 'regulations');
    const privacyFileUrl = localizeFile(props.data?.product_files, 'privacy_policy')
        ?? localizeFile(marketConfig.files, 'privacy_policy');


    useEffect(() => {
        if (props.customer?.title) {
            formMethods.setValue('title', civilityOptions.find(props.customer.title.value))
        }
        if (props.customer?.customTitle) {
            formMethods.setValue('customTitle', civilityOptions.find(props.customer.customTitle.value))
        }
    }, [store.civilities])

    useEffect(() => {
        if (props.customer?.country) {
            formMethods.setValue('country', countryOptions.findCountry(props.customer.country.value))
        }
    }, [store.country])

    useEffect(async () => {
        if (memberAddress) {
            formMethods.setValue('anotherAddress', false)
            if (!props.customer || (props.customer?.phone !== props.customer?.memberPhone)) {
                await memberPhoneMask.checkMemberPhone(memberAddress.value, props.addresses, props.customer)
            }
        }
    }, [memberAddress]);


    useEffect(() => {
        if (anotherAddress) {
            formMethods.setValue('memberAddress', null)
        }
    }, [anotherAddress]);


    useEffect(() => {
        const options = [];
        props.addresses.map(a => {
            options.push({
                label: `${a.address} ${a.address2}, ${a.postcode} ${a.city}`,
                value: a.id,
            })
        })
        setDeliveryOptions(options);
    }, [props.addresses]);

    useEffect(() => {
        if (formMethods.formState.submitCount && Object.keys(formMethods.formState.errors).length) {
            const el = document.querySelector('#customerDetailsForm .formGroup.-invalid');
            if (el) {
                scrollVertically(el, 15, 'smooth');
            }
        }
    }, [formMethods.formState.submitCount])


    useEffect(() => {
        if (props.verificationCodeError) {
            formMethods.setError('verificationCode', {
                type: 'validate',
                message: t(`validation.${props.verificationCodeError}`)
            })
        } else {
            formMethods.clearErrors('verificationCode')
        }
    }, [props.verificationCodeError])


    useEffect(() => {
        if (props.bankError) {
            formMethods.setError('accountNumber', {type: 'invalid', message: props.bankError});
        } else {
            formMethods.clearErrors('accountNumber');
        }
    }, [props.bankError])


    const handleChangePaymentType = (data) => {
        formMethods.setValue('paymentMethod', data);

        if (data) {
            formMethods.clearErrors('paymentMethod')
        }
    }

    const handleChangeAccountNumber = (data) => {
        const value = data?.target?.value;
        formMethods.setValue('accountNumber', value);
        if (value?.match(fieldsConfigs.accountNumber.pattern) && accountNumber !== value) {
            props.onChangeAccountNumber(value);
        }
    }

    const handleChangeAddress = async (v) => {
        if(v) {
            formMethods.clearErrors('memberAddress');
            formMethods.clearErrors('anotherAddress');
        } else {
            // formMethods.setError('memberAddress', {type: 'required', message: t('validation.this_or', t('deliver_to_another_address'))});
            formMethods.setError('anotherAddress', {type: 'required', message: t('validation.this_or', t('choose_your_delivery_address'))});
        }

        formMethods.setValue('memberAddress', v);
    }


    const handleChangeAnotherAddress = (v) => {
        if(v) {
            formMethods.clearErrors('memberAddress');
            formMethods.clearErrors('anotherAddress');
        } else {
            formMethods.setError('memberAddress', {type: 'required', message: t('validation.this_or', t('deliver_to_another_address'))});
            formMethods.setError('anotherAddress', {type: 'required', message: t('validation.this_or', t('choose_your_delivery_address'))});
        }
        formMethods.setValue('anotherAddress', v);
    }

    const onSubmit = (data) => {
        if (false && data.paymentMethod.payment_method_need_code_verify && !data.bank && !props.bank) {
            formMethods.setError('accountNumber', {type: 'invalid', message: t('validation.invalid')})
        } else {
            props.onSubmit(data);
        }
    }


    return (
        <FormProvider {...formMethods}>
            <form id="customerDetailsForm" onSubmit={formMethods.handleSubmit(onSubmit)}>


                <div className="--mb4">
                    {!!props.nespressoMember && (
                        <div className="gridSm -full">
                            <div className="col">

                                <Controller
                                    name="memberAddress"
                                    control={formMethods.control}
                                    rules={rules.memberAddress}
                                    render={({onChange, value}) => (
                                        <MySelect
                                            name="memberAddress"
                                            label={t('choose_your_delivery_address')}
                                            value={value}
                                            options={deliveryOptions}
                                            error={formMethods.errors.memberAddress}
                                            loading={props.loadingAddresses}
                                            onChange={handleChangeAddress}
                                            // nullable
                                        />
                                    )}
                                />


                                {!!memberAddress && (
                                    <Controller
                                        name="memberPhone"
                                        control={formMethods.control}
                                        rules={rules.phone}
                                        render={({onChange, value}) => Number(countryConfig.custom_format?.custom_phone_format?.mask_status) ? (
                                            <MyInputMask
                                                name="memberPhone"
                                                label={t('phone')}
                                                mask={fieldsConfigs.phone.inputMask}
                                                formatChars={fieldsConfigs.phone.formatChars}
                                                value={value}
                                                error={formMethods.errors.memberPhone}
                                                onChange={onChange}
                                                inputRef={memberPhoneMask.ref}
                                            />
                                        ) : (
                                            <MyInput
                                                name="memberPhone"
                                                label={t('phone')}
                                                value={value}
                                                error={formMethods.errors.memberPhone}
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                )}


                                <Controller
                                    name="anotherAddress"
                                    control={formMethods.control}
                                    rules={rules.anotherAddress}
                                    render={({onChange, value}) => (
                                        <MyCheckbox
                                            name="anotherAddress"
                                            label={t('deliver_to_another_address')}
                                            value={value}
                                            error={formMethods.errors.anotherAddress}
                                            onChange={handleChangeAnotherAddress}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )}

                    {!!anotherAddress && (
                        <div className="--mb2">
                            <Controller
                                name="companyAddress"
                                control={formMethods.control}
                                rules={rules.companyAddress}
                                render={({onChange, value}) => (
                                    <div className="--mb2">
                                        <MyCheckbox
                                            name="companyAddress"
                                            label={t('company_address')}
                                            value={value}
                                            error={formMethods.errors.companyAddress}
                                            onChange={onChange}
                                        />
                                    </div>
                                )}
                            />


                            <div hidden={!companyAddress}>
                                <Controller
                                    name="companyName"
                                    control={formMethods.control}
                                    rules={rules.companyName}
                                    render={({onChange, value}) => (
                                        <MyInput
                                            name="companyName"
                                            label={t('company_name')}
                                            value={value}
                                            error={formMethods.errors.companyName}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>

                            <div hidden={companyAddress}>
                                <Controller
                                    name="title"
                                    control={formMethods.control}
                                    rules={rules.title}
                                    render={({onChange, value}) => (
                                        <MySelect
                                            name="title"
                                            label={t('title')}
                                            value={value}
                                            options={civilityOptions.options}
                                            // nullable={true}
                                            error={formMethods.errors.title}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>

                            {!!marketConfig?.reseller_tax_id && !companyAddress && (
                                <Controller
                                    name="taxId"
                                    control={formMethods.control}
                                    rules={rules.taxId}
                                    render={({
                                                 onChange,
                                                 value
                                             }) => fieldsConfigs.taxId.mask_status ? (
                                        <MyInputMask
                                            name="taxId"
                                            label={t('tax_id')}
                                            mask={fieldsConfigs.taxId.inputMask}
                                            value={value}
                                            error={formMethods.errors.taxId}
                                            onChange={onChange}
                                        />
                                    ) : (
                                        <MyInput
                                            name="taxId"
                                            label={t('tax_id')}
                                            value={value}
                                            error={formMethods.errors.taxId}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            )}
                            {!!marketConfig?.reseller_company_tax_id && companyAddress && (
                                <Controller
                                    name="taxId"
                                    control={formMethods.control}
                                    rules={rules.companyTaxId}
                                    render={({
                                                 onChange,
                                                 value
                                             }) => fieldsConfigs.companyTaxId.mask_status ? (
                                        <MyInputMask
                                            name="taxId"
                                            label={t('company_tax_id') ?? t('tax_id')}
                                            mask={fieldsConfigs.companyTaxId.inputMask}
                                            value={value}
                                            error={formMethods.errors.taxId}
                                            onChange={onChange}
                                        />
                                    ) : (
                                        <MyInput
                                            name="taxId"
                                            label={t('company_tax_id') ?? t('tax_id')}
                                            value={value}
                                            error={formMethods.errors.taxId}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            )}


                            <div className="gridSm">
                                <div className="col">
                                    <Controller
                                        name="name"
                                        control={formMethods.control}
                                        rules={rules.name}
                                        render={({onChange, value}) => (
                                            <MyInput
                                                name="name"
                                                label={t('name')}
                                                value={value}
                                                error={formMethods.errors.name}
                                                onChange={onChange}
                                                maxLength={rules.name.maxLength?.value}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col">
                                    <Controller
                                        name="surname"
                                        control={formMethods.control}
                                        rules={rules.surname}
                                        render={({onChange, value}) => (
                                            <MyInput
                                                name="surname"
                                                label={t('surname')}
                                                value={value}
                                                error={formMethods.errors.surname}
                                                onChange={onChange}
                                                maxLength={rules.surname.maxLength?.value}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="gridSm">
                                <div className="col">
                                    <Controller
                                        name="postcode"
                                        control={formMethods.control}
                                        rules={rules.postcode}
                                        render={({
                                                     onChange,
                                                     value
                                                 }) => fieldsConfigs.postcode.mask_status ? (
                                            <MyInputMask
                                                name="postcode"
                                                label={t('postcode')}
                                                mask={fieldsConfigs.postcode.inputMask}
                                                value={value}
                                                error={formMethods.errors.postcode}
                                                onChange={onChange}
                                                forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                            />
                                        ) : (
                                            <MyInput
                                                name="postcode"
                                                label={t('postcode')}
                                                value={value}
                                                error={formMethods.errors.postcode}
                                                onChange={onChange}
                                                forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col">
                                    <Controller
                                        name="city"
                                        control={formMethods.control}
                                        rules={rules.city}
                                        render={({onChange, value}) => (
                                            <MyInput
                                                name="city"
                                                label={t('city')}
                                                value={value}
                                                error={formMethods.errors.city}
                                                onChange={onChange}
                                                maxLength={rules.city.maxLength?.value}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <Controller
                                name="address"
                                control={formMethods.control}
                                rules={rules.address}
                                render={({onChange, value}) => (
                                    <MyInput
                                        name="address"
                                        label={t('address_line_1')}
                                        value={value}
                                        error={formMethods.errors.address}
                                        onChange={onChange}
                                        maxLength={rules.address.maxLength?.value}
                                    />
                                )}
                            />

                            {!!marketConfig?.address_line2_enabled && (
                                <Controller
                                    name="address2"
                                    control={formMethods.control}
                                    rules={rules.address2}
                                    render={({onChange, value}) => (
                                        <MyInput
                                            name="address2"
                                            label={t('address_line_2')}
                                            value={value}
                                            error={formMethods.errors.address2}
                                            onChange={onChange}
                                            maxLength={rules.address2.maxLength?.value}
                                        />
                                    )}
                                />
                            )}

                            <Controller
                                name="country"
                                control={formMethods.control}
                                rules={rules.country}
                                render={({onChange, value}) => (
                                    <MySelect
                                        name="country"
                                        label={t('country')}
                                        value={value}
                                        options={countryOptions.countryOptions}
                                        error={formMethods.errors.country}
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <Controller
                                name="lang"
                                control={formMethods.control}
                                rules={rules.lang}
                                render={({onChange, value}) => (
                                    <MySelect
                                        name="lang"
                                        label={t('lang')}
                                        value={value}
                                        options={countryOptions.languageOptions}
                                        error={formMethods.errors.lang}
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <Controller
                                name="phone"
                                control={formMethods.control}
                                rules={rules.phone}
                                render={({
                                             onChange,
                                             value
                                         }) => fieldsConfigs.phone.mask_status ? (
                                    <MyInputMask
                                        name="phone"
                                        label={t('phone')}
                                        mask={fieldsConfigs.phone.inputMask}
                                        formatChars={fieldsConfigs.phone.formatChars}
                                        value={value}
                                        error={formMethods.errors.phone}
                                        onChange={onChange}
                                    />
                                ) : (
                                    <MyInput
                                        name="phone"
                                        label={t('phone')}
                                        value={value}
                                        error={formMethods.errors.phone}
                                        onChange={onChange}
                                    />
                                )}
                            />

                            {!!fieldsConfigs.phoneMobile.status && (
                                <Controller
                                    name="phoneMobile"
                                    control={formMethods.control}
                                    rules={rules.phoneMobile}
                                    render={({
                                                 onChange,
                                                 value
                                             }) => fieldsConfigs.phoneMobile.mask_status ? (
                                        <MyInputMask
                                            name="phoneMobile"
                                            label={t('phone_mobile')}
                                            mask={fieldsConfigs.phoneMobile.inputMask}
                                            formatChars={fieldsConfigs.phoneMobile.formatChars}
                                            value={value}
                                            error={formMethods.errors.phoneMobile}
                                            onChange={onChange}
                                        />
                                    ) : (
                                        <MyInput
                                            name="phoneMobile"
                                            label={t('phone_mobile')}
                                            value={value}
                                            error={formMethods.errors.phoneMobile}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            )}

                            {!!fieldsConfigs.phoneHome.status && (
                                <Controller
                                    name="phoneHome"
                                    control={formMethods.control}
                                    rules={rules.phoneHome}
                                    render={({
                                                 onChange,
                                                 value
                                             }) => fieldsConfigs.phoneHome.mask_status ? (
                                        <MyInputMask
                                            name="phoneHome"
                                            label={t('phone_home')}
                                            mask={fieldsConfigs.phoneHome.inputMask}
                                            formatChars={fieldsConfigs.phoneHome.formatChars}
                                            value={value}
                                            error={formMethods.errors.phoneHome}
                                            onChange={onChange}
                                        />
                                    ) : (
                                        <MyInput
                                            name="phoneHome"
                                            label={t('phone_home')}
                                            value={value}
                                            error={formMethods.errors.phoneHome}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            )}


                            {!props.nespressoMember && (
                                <div className="gridSm">
                                    <div className="col">
                                        <Controller
                                            name="email"
                                            control={formMethods.control}
                                            rules={rules.email}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="email"
                                                    label={t('email')}
                                                    value={value}
                                                    error={formMethods.errors.email}
                                                    onChange={onChange}
                                                    inputProps={{
                                                        onCopy: e => countryConfig.custom_format?.custom_email_format && !Number(countryConfig.custom_format.custom_email_format.copy) ? e.preventDefault() : undefined,
                                                        onPaste: e => countryConfig.custom_format?.custom_email_format && !Number(countryConfig.custom_format.custom_email_format.past) ? e.preventDefault() : undefined,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col">
                                        <Controller
                                            name="reEmail"
                                            control={formMethods.control}
                                            rules={rules.reEmail}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="reEmail"
                                                    label={t('re_email')}
                                                    value={value}
                                                    error={formMethods.errors.reEmail}
                                                    onChange={onChange}
                                                    inputProps={{
                                                        onCopy: e => countryConfig.custom_format?.custom_re_email_format && !Number(countryConfig.custom_format.custom_re_email_format.copy) ? e.preventDefault() : undefined,
                                                        onPaste: e => countryConfig.custom_format?.custom_re_email_format && !Number(countryConfig.custom_format.custom_re_email_format.past) ? e.preventDefault() : undefined,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            )}

                            {!props.nespressoMember && (
                                <div className="gridSm">
                                    <div className="col">
                                        <Controller
                                            name="password"
                                            control={formMethods.control}
                                            rules={rules.password}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="password"
                                                    type="password"
                                                    label={t('password')}
                                                    value={value}
                                                    error={formMethods.errors.password}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col">
                                        <Controller
                                            name="rePassword"
                                            control={formMethods.control}
                                            rules={rules.rePassword}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="rePassword"
                                                    type="password"
                                                    label={t('re_password')}
                                                    value={value}
                                                    error={formMethods.errors.rePassword}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            )}


                            <Controller
                                name="withCustomAddress"
                                control={formMethods.control}
                                rules={rules.withCustomAddress}
                                render={({onChange, value}) => (
                                    <div className="--mb2">
                                        <MyCheckbox
                                            name="withCustomAddress"
                                            label={t('deliver_to_another_address')}
                                            value={value}
                                            error={formMethods.errors.withCustomAddress}
                                            onChange={onChange}
                                        />
                                    </div>
                                )}
                            />


                            {!!withCustomAddress && (
                                <>
                                    <Controller
                                        name="customCompanyAddress"
                                        control={formMethods.control}
                                        rules={rules.customCompanyAddress}
                                        render={({onChange, value}) => (
                                            <div className="--mb2">
                                                <MyCheckbox
                                                    name="customCompanyAddress"
                                                    label={t('company_address')}
                                                    value={value}
                                                    error={formMethods.errors.customCompanyAddress}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        )}
                                    />

                                    <div hidden={!customCompanyAddress}>
                                        <Controller
                                            name="customCompanyName"
                                            control={formMethods.control}
                                            rules={rules.customCompanyName}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="customCompanyName"
                                                    label={t('company_name')}
                                                    value={value}
                                                    error={formMethods.errors.customCompanyName}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div hidden={customCompanyAddress}>
                                        <Controller
                                            name="customTitle"
                                            control={formMethods.control}
                                            rules={rules.customTitle}
                                            render={({onChange, value}) => (
                                                <MySelect
                                                    name="customTitle"
                                                    label={t('title')}
                                                    value={value}
                                                    options={civilityOptions.options}
                                                    error={formMethods.errors.customTitle}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>


                                    <div className="gridSm">
                                        <div className="col">
                                            <Controller
                                                name="customName"
                                                control={formMethods.control}
                                                rules={rules.name}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="customName"
                                                        label={t('name')}
                                                        value={value}
                                                        error={formMethods.errors.customName}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col">
                                            <Controller
                                                name="customSurname"
                                                control={formMethods.control}
                                                rules={rules.surname}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="customSurname"
                                                        label={t('surname')}
                                                        value={value}
                                                        error={formMethods.errors.customSurname}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="gridSm">
                                        <div className="col">
                                            <Controller
                                                name="customPostcode"
                                                control={formMethods.control}
                                                rules={rules.postcode}
                                                render={({
                                                             onChange,
                                                             value
                                                         }) => fieldsConfigs.postcode.mask_status ? (
                                                    <MyInputMask
                                                        name="customPostcode"
                                                        label={t('postcode')}
                                                        mask={fieldsConfigs.postcode.inputMask}
                                                        value={value}
                                                        error={formMethods.errors.customPostcode}
                                                        onChange={onChange}
                                                        forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                                    />
                                                ) : (
                                                    <MyInput
                                                        name="customPostcode"
                                                        label={t('postcode')}
                                                        value={value}
                                                        error={formMethods.errors.customPostcode}
                                                        onChange={onChange}
                                                        forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col">
                                            <Controller
                                                name="customCity"
                                                control={formMethods.control}
                                                rules={rules.city}
                                                render={({onChange, value}) => (
                                                    <MyInput
                                                        name="customCity"
                                                        label={t('city')}
                                                        value={value}
                                                        error={formMethods.errors.customCity}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <Controller
                                        name="customAddress"
                                        control={formMethods.control}
                                        rules={rules.address}
                                        render={({onChange, value}) => (
                                            <MyInput
                                                name="customAddress"
                                                label={t('address_line_1')}
                                                value={value}
                                                error={formMethods.errors.customAddress}
                                                onChange={onChange}
                                            />
                                        )}
                                    />

                                    {!!marketConfig?.address_line2_enabled && (
                                        <Controller
                                            name="customAddress2"
                                            control={formMethods.control}
                                            rules={rules.address2}
                                            render={({onChange, value}) => (
                                                <MyInput
                                                    name="customAddress2"
                                                    label={t('address_line_2')}
                                                    value={value}
                                                    error={formMethods.errors.customAddress2}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    )}

                                    {!!marketConfig?.other_delivery_phone_enabled && (
                                        <>
                                            <Controller
                                                name="customPhone"
                                                control={formMethods.control}
                                                rules={rules.phone}
                                                render={({onChange, value}) => (
                                                    <MyInputMask
                                                        name="customPhone"
                                                        label={t('phone')}
                                                        mask={fieldsConfigs.phone.inputMask}
                                                        formatChars={fieldsConfigs.phone.formatChars}
                                                        value={value}
                                                        error={formMethods.errors.customPhone}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />

                                            {!!fieldsConfigs.phoneMobile.status && (
                                                <Controller
                                                    name="customPhoneMobile"
                                                    control={formMethods.control}
                                                    rules={rules.phoneMobile}
                                                    render={({onChange, value}) => (
                                                        <MyInputMask
                                                            name="customPhoneMobile"
                                                            label={t('phone_mobile')}
                                                            mask={fieldsConfigs.phoneMobile.inputMask}
                                                            formatChars={fieldsConfigs.phoneMobile.formatChars}
                                                            value={value}
                                                            error={formMethods.errors.customPhoneMobile}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            )}

                                            {!!fieldsConfigs.phoneHome.status && (
                                                <Controller
                                                    name="customPhoneHome"
                                                    control={formMethods.control}
                                                    rules={rules.phoneHome}
                                                    render={({onChange, value}) => (
                                                        <MyInputMask
                                                            name="customPhoneHome"
                                                            label={t('phone_home')}
                                                            mask={fieldsConfigs.phoneHome.inputMask}
                                                            formatChars={fieldsConfigs.phoneHome.formatChars}
                                                            value={value}
                                                            error={formMethods.errors.customPhoneHome}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>

                <BenefitsInfo/>


                <div className="--mb4">
                    <FormFragmentBenefits
                        modulePrefix="etrade."
                        config={{
                            email: props.plan.product_plan_marketing_email_visible,
                            mobile: props.plan.product_plan_marketing_mobile_visible,
                            phone: props.plan.product_plan_marketing_phone_visible,
                            post: props.plan.product_plan_marketing_post_visible,
                            required: props.plan.product_plan_marketing_required,
                        }}
                    />
                </div>


                <TermsInfo modulePrefix="etrade."/>


                <div className="--mb4">
                    <Controller
                        name="termsAndConditions"
                        control={formMethods.control}
                        rules={rules.termsAndConditions}
                        render={({onChange, value}) => (
                            <MyCheckbox
                                name="termsAndConditions"
                                value={value}
                                error={formMethods.errors.termsAndConditions}
                                onChange={onChange}
                                disableLabelClick
                            >
                            <span
                                className="--bold"
                                dangerouslySetInnerHTML={{__html: t('etrade.terms_and_conditions_confirmation', termsFileUrl ?? t('terms_and_conditions_url'))}}
                            />
                            </MyCheckbox>
                        )}
                    />

                    <Controller
                        name="privacyPolicy"
                        control={formMethods.control}
                        rules={rules.privacyPolicy}
                        render={({onChange, value}) => (
                            <MyCheckbox
                                name="privacyPolicy"
                                value={value}
                                error={formMethods.errors.privacyPolicy}
                                onChange={onChange}
                                disableLabelClick
                            >
                            <span
                                className="--bold"
                                dangerouslySetInnerHTML={{__html: t('etrade.privacy_policy_confirmation', privacyFileUrl ?? t('privacy_policy_url'))}}
                            />
                            </MyCheckbox>
                        )}
                    />
                </div>


                {/*<div className="--mb4">*/}
                {/*    <hr className="-light --mb3"/>*/}
                {/*    <h3 className="par-1">{t('choose_payment_type')}</h3>*/}
                {/*    <Controller*/}
                {/*        name="paymentMethod"*/}
                {/*        control={formMethods.control}*/}
                {/*        rules={rules.paymentMethod}*/}
                {/*        render={({onChange, value}) => (*/}
                {/*            <>*/}
                {/*                {props.plan?.payment_methods?.length ? props.plan?.payment_methods?.map((type, idx) => (*/}
                {/*                    <MyRadioCustom*/}
                {/*                        key={idx}*/}
                {/*                        name="paymentMethod"*/}
                {/*                        value={type.id_payment_method}*/}
                {/*                        label={localizeObject(type.payment_method_title)}*/}
                {/*                        selected={value}*/}
                {/*                        error={formMethods.errors.paymentMethod}*/}
                {/*                        onChange={handleChangePaymentType}*/}
                {/*                    />*/}
                {/*                )) : (<p className="par-2 --error">NO PAYMENT METHOD!</p>)}*/}
                {/*            </>*/}
                {/*        )}*/}
                {/*    />*/}
                {/*</div>*/}


                {!!paymentMethod?.payment_method_need_code_verify && (
                    <div className="--mb4">

                        <Controller
                            name="accountNumber"
                            control={formMethods.control}
                            rules={rules.accountNumber}
                            render={({
                                         onChange,
                                         value
                                     }) => fieldsConfigs.accountNumber.mask_status ? (
                                <MyInputMask
                                    name="accountNumber"
                                    label={t('r_ms_customer.account_number')}
                                    mask={fieldsConfigs.accountNumber.inputMask}
                                    formatChars={fieldsConfigs.accountNumber.formatChars}
                                    value={value}
                                    error={formMethods.errors.accountNumber}
                                    loading={props.loadingBank}
                                    onChange={handleChangeAccountNumber}
                                />
                            ) : (
                                <MyInput
                                    name="accountNumber"
                                    label={t('r_ms_customer.account_number')}
                                    value={value}
                                    error={formMethods.errors.accountNumber}
                                    loading={props.loadingBank}
                                    onChange={handleChangeAccountNumber}
                                />
                            )}
                        />
                        {!!props.bank && (
                            <div className="--mb3 --pl2">
                                <p className="par-2 --semiBold">{props.bank.bank_name}</p>
                            </div>
                        )}


                        <Controller
                            name="bank"
                            control={formMethods.control}
                            rules={rules.bank}
                            render={({onChange, value}) => (
                                <MySelect
                                    name="bank"
                                    label={t('r_ms_customer.bank')}
                                    value={value}
                                    options={mapBankToOptions(props.banks)}
                                    error={formMethods.errors.bank}
                                    loading={props.loadingBanks}
                                    onChange={onChange}
                                />
                            )}
                        />

                        {!!props.verificationCodeVisible && (
                            <>
                                <div className="--mb4"/>
                                <p className="par-2">{t('r_ms_customer.verification_code_info')}</p>
                                <Controller
                                    name="verificationCode"
                                    control={formMethods.control}
                                    rules={rules.verificationCode}
                                    render={({onChange, value}) => (
                                        <MyInput
                                            name="verificationCode"
                                            label={t('r_ms_customer.verification_code')}
                                            value={value}
                                            error={formMethods.errors.verificationCode}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </>
                        )}
                    </div>
                )}


                {!props.nespressoMember && (
                    <div className="--mb4">
                        <Controller
                            name="recaptchaToken"
                            control={formMethods.control}
                            rules={rules.recaptchaToken}
                            render={({onChange, value}) => (
                                <RecaptchaV2
                                    value={value}
                                    onChange={onChange}
                                    error={formMethods.errors.recaptchaToken}
                                />
                            )}
                        />
                    </div>
                )}


                {!!Object.keys(formMethods.formState.errors)?.length && (
                    <p className="par-2 --error --mb4">{t('fill_all_required_fields')}</p>
                )}


                <div className="step__nav">
                    <Link to={stepNavigation.prev()}>
                        <Button
                            type="primary-outline"
                            label={t('back')}
                            disabled={props.sending}
                        />
                    </Link>
                    <Button
                        btnType="submit"
                        type="primary"
                        label={t('continue')}
                        disabled={props.sending}
                    />
                </div>
            </form>
        </FormProvider>
    );
}


function mapBankToOptions(banks) {
    const arr = [];
    banks?.map(b => {
        arr.push({
            value: b.id_subscription_bank,
            label: b.name
        })
    })
    return arr;
}
