import {useTranslation} from "../../../misc/Hooks";
import {Alert} from "../../../components/_index";
import {Preloader} from "../../../misc/_index";
import React from "react";
import {CustomerForm} from "../../../components/forms/etrade";
import {Button} from "../../../components/buttons/_index";
import * as eTradeReducer from "../../../redux/eTradeReducer";
import {useDispatch} from "react-redux";


export default function CustomerScreen(props) {
    const dispatch = useDispatch()
    const t = useTranslation()

    const onClickNavToLogin = () => {
        // history.push(navigation(Routes.SHOP_CUSTOMER_IDENTIFICATION))
        dispatch(eTradeReducer.setNespressoMember(undefined));
    }

    return (
        <div>
            <div className="gridSm -full">
                <div>
                    <div className="--mb4">
                        <CustomerForm
                            data={props.data}
                            customer={props.customer}
                            sending={props.sending}
                            plan={props.plan}
                            nespressoMember={props.nespressoMember}
                            // paymentMethod={props.paymentMethod}
                            addresses={props.addresses}
                            loadingAddresses={props.loadingAddresses}
                            onSubmit={props.onSubmit}
                        />
                        {!!props.error && (
                            <div className="step__nav">
                                <Alert type="error" text={t(`error.${props.error}`)}/>
                            </div>
                        )}
                    </div>
                    {!!props.sending && <Preloader/>}
                    {!!props.loginFormVisible && (
                        <div className="grid --mb4 --pt4">
                            <div>
                                <p className="par-2 --mb3 --error">{t(`error.account_exists_please_log_in`)}</p>
                                <Button
                                    type="primary"
                                    label={t('error.account_exists_please_log_in_button')}
                                    onClick={onClickNavToLogin}
                                />
                            </div>
                            <div/>
                        </div>
                    )}
                </div>
                <div/>
            </div>
        </div>
    )
}
