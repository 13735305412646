import React, {useState} from 'react';
import BaseModal from "./BaseModal";
import {Button, PlusMinusButton} from "../buttons/_index";
import {useCart, usePriceFormatter, useTranslation} from "../../misc/Hooks";
import {getImage} from "../../misc/Helpers";
import {useSelector} from "react-redux";
import SimpleItem from "../SimpleItem";
import {GetInTouchModal} from "./_index";


interface Props {
    visible: boolean,
    item: any,
    onClose: void,
}

export default (props: Props) => {
    const t = useTranslation();
    const {isItemLocked, updateItem} = useCart();
    const {formatPrice, checkPromoPrice} = usePriceFormatter();
    const store = useSelector(({ev}) => ({
        products: ev.products,
        cart: ev.cart,
    }))
    const [gitModalVisible, setGitModalVisible] = useState(false);


    const hasPromoPrice = checkPromoPrice(
        parseInt(props.item.product_price_promo?.status),
        props.item.product_price_promo?.date_from,
        props.item.product_price_promo?.date_to,
    );


    const coverImage = getImage(props.item.product_gallery);


    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="packageModal">
                <div className="coffeeModal">
                    <div>
                        {!!coverImage && (
                            <div className="coffeeModal__thumb --mb3">
                                <img src={coverImage} alt={props.item.product_title}/>
                            </div>
                        )}
                        {!!Object.keys(props.item.product_coffees_in_set).length && (
                            <div className="coffeeModal__similar">
                                <h5 className="par-2 --alignCenter --mb3">{t('coffee.selection_includes')}</h5>
                                <div className="similar">
                                    {Object.keys(props.item.product_coffees_in_set)
                                        .map((sId, idx) => {
                                                const p = store.products.find(p => p.id_product === parseInt(sId));
                                                return !!p && (
                                                    <div key={idx} className="similar__item">
                                                        <div className="similar__thumb">
                                                            <img src={getImage(p.product_gallery)} alt={p.product_title}/>
                                                        </div>
                                                        <h6 className="similar__title">{p.product_title}</h6>
                                                    </div>
                                                )
                                            }
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <h3 className="coffeeModal__title">{props.item.product_title}</h3>
                        <p
                            className="coffeeModal__lead par-5"
                            dangerouslySetInnerHTML={{__html: props.item.product_lead}}
                        />
                        <h5 className="coffeeModal__price">
                            {!hasPromoPrice ? (
                                <span>{formatPrice(props.item.product_price?.gross)}</span>
                            ) : (
                                <>
                                    <small><s className="--textDark" style={{marginRight: '6px'}}>{formatPrice(props.item.product_price?.gross)}</s></small>
                                    <span>{formatPrice(props.item.product_price_promo?.gross)}</span>
                                </>
                            )}
                        </h5>
                        {!props.item.product_additional?.length && (
                            <div className="coffeeModal__button">
                                {props.itemInCart ? (
                                    <PlusMinusButton
                                        type="primary"
                                        size="sm"
                                        plusDisabled={props.itemInCart.value >= props.item.product_max_qty || isItemLocked(props.item.id_product)}
                                        value={props.itemInCart.value * props.item.product_package_qty}
                                        step={props.item.product_step}
                                        packageQty={props.item.product_package_qty}
                                        max={props.item.product_max_qty ?? undefined}
                                        onClickMinus={() => updateItem(props.item.id_product, (props.itemInCart?.value ?? 0) - props.item.product_step)}
                                        onClickPlus={() => updateItem(props.item.id_product, (props.itemInCart?.value ?? 0) + props.item.product_step)}
                                        onChange={v => updateItem(props.item.id_product, v)}
                                    />
                                ) : (
                                    <Button
                                        type="primary"
                                        size="sm"
                                        label={t('buy_now')}
                                        disabled={isItemLocked(props.item.id_product)}
                                        onClick={() => updateItem(props.item.id_product, props.item.product_step)}
                                    />
                                )}
                            </div>
                        )}

                        <div className="coffeeModal__lead par-5" dangerouslySetInnerHTML={{__html: props.item.product_description}}/>

                    </div>
                </div>
                {!!props.item.product_additional?.length && (
                    <div className="packageModal__footer">
                        <h4 className="packageModal__label par-1">{t('gifts')}</h4>
                        <div className="packageModal__products">
                            {props.item.product_additional.map(id => {
                                const p = store.products.find(p => p.id_product === parseInt(id));
                                return !!p && (
                                    <SimpleItem
                                        key={id}
                                        item={p}
                                        cart={store.cart}
                                        hideModal={props.hideModal}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )}
                <div className="packageModal__help">
                    <div className="par-4">
                        <b>{t('need_help')} </b>
                        <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                            <b>{t('get_in_touch')}</b>
                        </a>
                    </div>
                </div>
            </div>


            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />

        </BaseModal>
    );
}
