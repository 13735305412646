import React, {useState} from 'react';
import {Button} from "./buttons/_index";
import {PlanRmsItemModal} from "./modals/_index";
import {useCart, usePriceFormatter, useTranslation} from "../misc/Hooks";
import {getImage} from "../misc/Helpers";
import {Config} from "../utils/_index";


interface Props {
    item: any,
    cart: any,
}


const PlanRmsItem = (props: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const {formatPriceObj} = usePriceFormatter();
    const {updateItem, clearCartAndAddItem, isItemLocked} = useCart('rms');
    const t = useTranslation();


    const itemInCart = props.cart?.items.find(i => i.id === props.item.id_product);
    const isLocked = isItemLocked(props.item.id_product);

    const coverImage = getImage(props.item.product_gallery);


    return (
        <div className="promoItem">
            <div className="promoItem__header">
                {!!coverImage && (
                    <div className="promoItem__thumb">
                        <img src={coverImage} alt={props.item.product_title}/>
                    </div>
                )}
                <h3 className="promoItem__title">{props.item.product_title}</h3>
                {!!props.item.product_plan_description1 && (
                    <p
                        className="promoItem__text"
                        dangerouslySetInnerHTML={{__html: props.item.product_plan_description1}}
                    />
                )}
            </div>


            <div className="promoItem__body">
                <div className="promoItem__line --mb2">
                    {!!props.item.product_plan_price && (
                        <>
                            <h4 className="promoItem__price">{formatPriceObj(props.item.product_plan_price, 0)}</h4>
                            {props.item.product_type === Config.COFFEE_TYPE.PLAN_COFFEE && (
                                <h4 className="promoItem__period">{t('plan_price_label')}</h4>
                            )}
                        </>
                    )}
                </div>

                <button
                    className="link -primary"
                    onClick={() => setModalVisible(true)}
                >{t('read_more')}</button>
            </div>


            <div className="promoItem__content -center primaryUl">
                <div
                    dangerouslySetInnerHTML={{__html: props.item.product_plan_description2 ? props.item.product_plan_description2 : ''}}
                />
            </div>


            <div className="promoItem__footer">
                <div className="coffeeItem__button">
                    <Button
                        type="primary"
                        size="sm"
                        label={t(!itemInCart ? 'buy_now' : 'remove_from_cart')}
                        onClick={() => clearCartAndAddItem(props.item.id_product, !itemInCart ? props.item.product_step : 0)}
                        disabled={isLocked && !itemInCart}
                    />

                </div>
            </div>


            {!!modalVisible && (
                <PlanRmsItemModal
                    visible={modalVisible}
                    item={props.item}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    )
}


export default PlanRmsItem;
