import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Footer, Header} from "../../components/misc/_index";
import {Redirect, Route, Switch} from "react-router-dom";
import {
   AdminAndReportsContainer,
   AssistedRegistrationContainer,
   AuthContainer,
   HomeContainer,
   MachineSubscriptionContainer,
   ReportsContainer, ResellerReportsContainer
} from "./_index";
import {Config, Routes} from "../../utils/_index";
import {useNavigation, useRmsSteps} from "../../misc/Hooks";
import {MachineSubscriptionFunnel} from "./machine_subscription/_index";
import {AppProvider} from "../../misc/_index";
import {useIdleTimer} from "react-idle-timer";
import * as mainReducer from "../../redux/mainReducer";
import * as resellerReducer from "../../redux/resellerReducer";
import {ForgotPasswordResellerContainer, SetNewPasswordResellerContainer} from "./forgot_password";
import {Breadcrumb} from "../../components/_index";
import * as rmsReducer from "../../redux/rmsReducer";
import * as evReducer from "../../redux/evReducer";

const ROUTE_LIST = [
   {
      route: Routes.RESELLER_ADMIN_REPORTS,
      component: AdminAndReportsContainer,
      size: "",
      trace: [
         {
            route: Routes.RESELLER,
            name: 'reseller'
         },
         {
            route: null,
            name: 'reseller_options.admin_and_reports'
         },
      ]
   },
   {
      route: Routes.RESELLER,
      component: HomeContainer,
      size: "",
      trace: [
         {
            route: null,
            name: 'reseller'
         }
      ]
   },
   {
      route: Routes.RESELLER_MACHINE_SUBSCRIPTIONS,
      component: MachineSubscriptionContainer,
      size: "-xxl",
      trace: [
         {
            route: Routes.RESELLER,
            name: 'reseller'
         },
         {
            route: null,
            name: 'reseller_options.machine_subscription'
         }
      ]
   },
   {
      route: Routes.RESELLER_ASSISTED_REGISTRATION,
      component: AssistedRegistrationContainer,
      size: "-xxl",
      trace: [
         {
            route: Routes.RESELLER,
            name: 'reseller'
         },
         {
            route: null,
            name: 'reseller_options.assisted_registration'
         }
      ]
   },
   {
      route: Routes.RESELLER_REPORTS,
      component: ReportsContainer,
      size: "-xxl",
      trace: [
         {
            route: Routes.RESELLER,
            name: 'reseller'
         },
         {
            route: Routes.RESELLER_ADMIN_REPORTS,
            name: 'reseller_options.admin_and_reports'
         },
         {
            route: null,
            name: 'reseller_options.reports'
         }
      ]
   },
]

export default (props) => {
   const navigation = useNavigation();
   const steps = useRmsSteps();
   const dispatch = useDispatch();
   const store = useSelector(({main, reseller}) => ({
      user: main.user,
      lastVisit: reseller.lastVisit
   }));

   const {getLastActiveTime} = useIdleTimer({
      timeout: Config.RESELLER_SESSION_TIME,
      onIdle: e => handleIdle(e),
      debounce: 500,
   })

   const handleIdle = (e) => {
      dispatch(rmsReducer.clearStore())
      dispatch(evReducer.clearStore())
      dispatch(mainReducer.clearUser())
   }

    useEffect(()=>{
        if(store.lastVisit){
            if((store.lastVisit + Config.RESELLER_SESSION_TIME) <= Date.now()){
               console.log(store.lastVisit, store.lastVisit + Config.RESELLER_SESSION_TIME <= Date.now())
                handleIdle()
            }
        }
        window.addEventListener("beforeunload", function(e) {
            dispatch(resellerReducer.setLastVisit(getLastActiveTime()))
        });
    }, [])

   return (
      <AppProvider>
         <div className="app">
            <Header
               homeRoute={navigation(Routes.RESELLER)}
               cartDisabled
            />

            <div className="main">
               <div className="wrapper-bg formContainer">


                  <Switch>
                     {ROUTE_LIST.map((item, idx) => (
                        <Route key={idx} exact path={item.route} render={() => <Breadcrumb item={item}/>}/>
                     ))}
                  </Switch>

                     <Switch>

                        <Route exact path={Routes.RESELLER_AUTH}>
                           <AuthContainer/>
                        </Route>
                        <Route exact path={Routes.RESELLER_ADMIN_REPORTS}>
                           {store.user && store.user.reseller_role !== null ? (
                              <AdminAndReportsContainer/>
                           ) : <Redirect to={navigation(Routes.RESELLER_AUTH)}/>}
                        </Route>
                        <Route exact path={Routes.RESELLER_REPORTS}>
                           {store.user && store.user.reseller_role !== null ? (
                              <ResellerReportsContainer/>
                           ) : <Redirect to={navigation(Routes.RESELLER_AUTH)}/>}
                        </Route>
                        <Route exact path={Routes.RESELLER_ASSISTED_REGISTRATION}>
                           {store.user ? (
                              <AssistedRegistrationContainer/>
                           ) : <Redirect to={navigation(Routes.RESELLER_AUTH)}/>}
                        </Route>

                        <Route exact path={Routes.RESELLER_FORGOT_PASSWORD}>
                           <ForgotPasswordResellerContainer/>
                        </Route>

                        <Route exact path={Routes.RESELLER_FORGOT_PASSWORD_SET_NEW}>
                           <SetNewPasswordResellerContainer/>
                        </Route>

                        <Route exact path={Routes.RESELLER_MACHINE_SUBSCRIPTIONS}>
                           {store.user ? (
                              <MachineSubscriptionContainer/>
                           ) : <Redirect to={navigation(Routes.RESELLER_AUTH)}/>}
                        </Route>
                        <Route exact path={steps.map(s => s.route)}>
                           {store.user ? (
                              <MachineSubscriptionFunnel/>
                           ) : <Redirect to={navigation(Routes.RESELLER_AUTH)}/>}
                        </Route>
                        <Route>
                           {store.user ? (
                              <HomeContainer/>
                           ) : <Redirect to={navigation(Routes.RESELLER_AUTH)}/>}
                        </Route>

                     </Switch>

               </div>
            </div>


            <Footer/>
         </div>
      </AppProvider>
   )
}
