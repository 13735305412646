import {AppProvider} from "../../misc/_index";


export default function Provider(props) {

    return (
        <AppProvider>
            {props.children}
        </AppProvider>
    )
}
