import React from 'react';


interface Props {
    type: 'success' | 'error' | 'info',
    size?: 'md' | 'lg',
    text: string,
}


export default (props: Props) => {
    return (
        <div className={`alert -${props.type} ${props.size ? `-${props.size}` : ''}`}>
            {props.text ? (
                <span dangerouslySetInnerHTML={{__html: props.text}} />
            ) : props.children}
        </div>
    )
}
