import React, {useRef, useState} from 'react';


interface Props {
    name: string,
    label: string,
    selected: string,
    value: string,
    error: any,
    onChange: void,
}


export default (props: Props) => {
    const inputRef = useRef(null);
    const [isFocused, setFocused] = useState(false);


    const handleChange = (e) => {
        inputRef.current?.click();
    }


    let formGroupClassNames = '';
    if(isFocused) {
        formGroupClassNames += ' -focused';
    }
    if(props.error) {
        formGroupClassNames += ' -invalid';
    }


    return (
        <div className={`formCheckbox ${formGroupClassNames}`}>

            <div className="formCheckbox__field">
                <input
                    ref={inputRef}
                    type="radio"
                    id={props.name ?? ''}
                    name={props.name ?? ''}
                    value={props.value}
                    // checked={props.selected === props.value}
                    onChange={e => e.target.checked ? props.onChange(props.value) : undefined}
                    hidden
                />

                <div className="formCheckbox__input"
                     data-checked={props.selected === props.value}
                     onClick={handleChange}
                />

                <label onClick={handleChange}>
                    {props.label ?? props.children}
                </label>
            </div>

            {!!props.error && (
                <span className="formGroup__invalidFeedback">{props.error.message}</span>
            )}
        </div>
    );
}
