import React, {useEffect} from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyInput, RecaptchaV2} from "./inputs/_index";
import Patterns from "../../utils/Patterns";
import {Button} from "../buttons/_index";
import {useLocalizeObject, useMarketConfig, useTranslation} from "../../misc/Hooks";


export default (props) => {
    const marketConfig = useMarketConfig();
    const t = useTranslation();
    const localizeObject = useLocalizeObject();
    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            login: '',
            password: '',
            recaptchaToken: '',
        }
    });


    const rules = {
        login: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: Patterns.email, message: t('validation.invalid')},
        },
        password: {
            required: {value: true, message: t('validation.required')}
        },
        recaptchaToken: {
            required: {value: true, message: t('validation.required')}
        },
    }


    useEffect(() => {
        if(props.error) {
            formMethods.setError('login', {
                type: 'incorrect',
                message: t(`validation.${props.error}`)
            })
            formMethods.setError('password', {
                type: 'incorrect',
                message: t(`validation.${props.error}`)
            })
        }
    }, [props.error])


    const onSubmit = (data) => {
        props.onSubmit(data);
    }

    const forgotPasswordLink = localizeObject(marketConfig.forgot_password_link);

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>

                <p className="par-2">{props.text ?? t('ev_customer_identification.sign_in')}</p>
                <Controller
                    name="login"
                    control={formMethods.control}
                    rules={rules.login}
                    render={({onChange, value}) => (
                        <MyInput
                            name="login"
                            label={t('email')}
                            value={value}
                            error={formMethods.errors.login}
                            onChange={onChange}
                        />
                    )}
                />

                <Controller
                    name="password"
                    control={formMethods.control}
                    rules={rules.password}
                    render={({onChange, value}) => (
                        <MyInput
                            name="password"
                            type="password"
                            label={t('password')}
                            value={value}
                            error={formMethods.errors.password}
                            onChange={onChange}
                        />
                    )}
                />

                <div className="--mb3" style={{textAlign: 'right'}}>
                    <a href={forgotPasswordLink ?? t('forgot_your_password_url')} target="_blank">
                        <Button
                            type="grey"
                            size="sm"
                            label={t('forgot_your_password')}
                        />
                    </a>
                </div>

                <div className="--mb4">
                    <Controller
                        name="recaptchaToken"
                        control={formMethods.control}
                        rules={rules.recaptchaToken}
                        render={({onChange, value}) => (
                            <RecaptchaV2
                                value={value}
                                onChange={onChange}
                                error={formMethods.errors.recaptchaToken}
                            />
                        )}
                    />
                </div>


                <Button
                    btnType="submit"
                    type="primary"
                    label={props.submitText ?? t('continue')}
                    disabled={props.loading}
                    onClick={formMethods.handleSubmit(onSubmit)}
                />
            </form>
        </FormProvider>
    );
}
