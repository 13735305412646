import React from 'react';
import {PlusMinusButton} from "./buttons/_index";
import {
    useCart,
    useLocalizeObject,
    useMarketConfig,
    useNavigation,
    usePriceFormatter,
    useTranslation
} from "../misc/Hooks";
import {Config} from "../utils/_index";
import {getImage, getPackageCapsulesCount, getPackageCapsulesLength} from "../misc/Helpers";
import {useSelector} from "react-redux";


interface Props {
    cart: any,
    storeName: string,
    products: [],
    scrollable?: boolean,
    disableFooter?: boolean,
    onContinue?: void,
}


const CartSummary = (props: Props) => {
    const t = useTranslation();
    const marketConfig = useMarketConfig();
    const {formatPrice} = usePriceFormatter();
    const localizeObject = useLocalizeObject();
    const {capsulesValue, isCartEditable, updateItem} = useCart(props.storeName);
    const store = useSelector(state => ({
        user: state.main.user,
        promotion: state[props.storeName]?.promotion,
    }));


    const getCategories = (categories, products) => {
        const arr = [...categories];
        arr.map(c => {
            const prods = products.filter(p => p.id_category === c.id_category);
            let capsulesCount = 0;
            prods.map(p => {
                const cartProd = props.cart.items.find(i => i.id === p.id_product);
                if(cartProd) {
                    if(c.category_type === Config.COFFEE_TYPE.PACKAGE) {
                        capsulesCount += getPackageCapsulesLength(p);
                    }
                    else {
                        capsulesCount += cartProd.value * (p.product_step * p.product_package_qty);
                    }
                }
            })

            c.products = prods;
            c.capsulesCount = capsulesCount;
        })

        return arr.sort((a,b) => {
            if(a.category_type === 'gift' || a.category_type === 'voucher' || a.category_type === 'delivery')
                return 1;
            if(a.category_type === 'package' || a.category_type === 'capsule' || a.category_type?.startsWith('plan'))
                return -1;
        });
    }


    const promotionSummaryText = localizeObject(store.promotion?.promotion_summary_text);
    const summaryText = promotionSummaryText ?? localizeObject(marketConfig?.summary_text);


    return (
        <div className={`cartSummary ${props.scrollable ? ' -scrollable' : ''}`}>
            {getCategories(props.categories, props.products).map(c => (
                <div key={c.id_category}>
                    <div className="cartSummary__header">
                        <div className="cartSummary__title">{c.category_title}</div>
                        {(c.category_type !== Config.COFFEE_TYPE.GIFT && c.category_type !== Config.COFFEE_TYPE.DELIVERY && c.category_type !== Config.COFFEE_TYPE.REBATE && !c.category_type.toString().startsWith('plan_')) && (
                            <div className="cartSummary__subtitle">{t('cart.capsules_total', c.capsulesCount)}</div>
                        )}
                    </div>
                    <div className="cartSummary__items">
                        {c.products.map(p => {
                            const itemInCart = props.cart.items.find(i => i.id === p.id_product);
                            return !!itemInCart && (
                                <div key={p.id_product} className="summaryItem">
                                    <div className="summaryItem__thumb">
                                        <img src={getImage(p.product_gallery)} alt={p.product_title}/>
                                    </div>
                                    <div className="summaryItem__content">
                                        <div className="summaryItem__header">
                                            <h4 className="summaryItem__title">{p.product_title}</h4>
                                        </div>
                                        {(c.category_type !== Config.COFFEE_TYPE.GIFT && c.category_type !== Config.COFFEE_TYPE.DELIVERY && c.category_type !== Config.COFFEE_TYPE.REBATE) && !c.category_type.toString().startsWith('plan_') && (
                                            <div className="summaryItem__text">
                                                {c.category_type === Config.COFFEE_TYPE.PACKAGE ? (
                                                    t('cart.item_capsules', getPackageCapsulesLength(p), getPackageCapsulesCount(p))
                                                ) : (
                                                    t('cart.item_capsules', itemInCart.value, itemInCart.value * p.product_package_qty)
                                                )}
                                            </div>
                                        )}
                                        <div className="summaryItem__footer">
                                            <div className="summaryItem__value">
                                                {(c.category_type !== Config.COFFEE_TYPE.GIFT
                                                    && c.category_type !== Config.COFFEE_TYPE.DELIVERY
                                                    && c.category_type !== Config.COFFEE_TYPE.PLAN_MACHINE
                                                    && c.category_type !== Config.COFFEE_TYPE.REBATE) && (
                                                    <PlusMinusButton
                                                        value={itemInCart.value * p.product_package_qty}
                                                        step={p.product_step}
                                                        packageQty={p.product_package_qty}
                                                        max={0}
                                                        minusDisabled={true}
                                                        plusDisabled={true}
                                                        onClickMinus={() => undefined}
                                                        onClickPlus={() => undefined}
                                                        onChange={v => undefined}
                                                    />
                                                )}
                                            </div>
                                            <div className="summaryItem__price">
                                                {p.product_type === Config.COFFEE_TYPE.PLAN_COFFEE
                                                || p.product_type === Config.COFFEE_TYPE.PLAN_MACHINE ? (
                                                    formatPrice(p.product_plan_price?.gross)
                                                ) : p.product_percentage_rebate ? (
                                                    `-${Number(p.product_percentage_rebate).toFixed(0)}%`
                                                ) : formatPrice(p.product_price?.gross)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            ))}


            {!!(props.cart.items.length && summaryText) && (
                <p className="cartSummary__info" dangerouslySetInnerHTML={{__html: summaryText}} />
            )}


            {!props.disableFooter && (props.cart.items.length ? (
                <>
                    <div className="cartBox__total">
                        <span className="cartBox__name">{t('cart.total')}</span>
                        <span className="cartBox__price">{formatPrice(capsulesValue)}</span>
                    </div>
                </>
            ) : (
                <div className="cartSummary__empty">
                    <h4 className="cartSummary__head">{t('cart.empty.title')}</h4>
                    <p className="cartSummary__text">{t('cart.empty.text')}</p>
                </div>
            ))}


        </div>
    );
}


export default CartSummary;
