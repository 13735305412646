import {Controller, useForm} from "react-hook-form";
import {MyInput} from "../inputs/_index";
import {useTranslation} from "../../../misc/Hooks";
import {Button} from "../../buttons/_index";
import {useFormRules} from "../../../hooks";
import Patterns from "../../../utils/Patterns";
import {Preloader} from "../../../misc/_index";
import {useEffect} from "react";


export default function ForgotPasswordResellerForm(props) {
    const t = useTranslation();
    const formRules = useFormRules();
    const {control, errors, setError, clearErrors, handleSubmit} = useForm({
        mode: 'onChange',
    })


    useEffect(() => {
        if(props.emailNotSent.message) {
            setError('email', {type: 'invalid', message: props.emailNotSent.message});
        } else {
            clearErrors('email');
        }
    }, [props.emailNotSent])


    const rules = {
        email: formRules.make({
            required: true,
            pattern: Patterns.email,
            patternMessage: t('validation.invalid_email') || t('validation.invalid'),
        })
    }

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>

            <div className="grid">
                <div>
                    <Controller
                        control={control}
                        name="email"
                        defaultValue=""
                        rules={rules.email}
                        render={({value, onChange, onBlur}) => (
                            <MyInput
                                type="email"
                                value={value}
                                label={t('email')}
                                error={errors.email}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />
                </div>
                <div/>
            </div>


            <Button
                btnType="submit"
                disabled={props.loading}
                label={t('continue')}
            />

            {!!props.loading && <div className="grid --pt4"><Preloader classes="--mb0"/><div/></div>}

        </form>
    )
}
