import React from 'react';
import {Preloader} from "../../misc/_index";


interface Props {
    btnType?: string,
    type: 'primary' | 'primary-outline' | 'grey' | 'secondary',
    size?: 'sm' | 'md',
    active?: boolean,
    style?: any,
    children?: any,
    label?: string,
    disabled?: boolean,
    onClick: void,
}


export default (props: Props) => {
    let className = '';
    switch (props.type) {
        case "primary-outline":
            className = '-primary -outline';
            break;
        case "grey":
            className = '-grey';
            break;
        case "secondary":
            className = '-secondary';
            break;
        default:
            className = '-primary';
    }

    switch (props.size) {
        case "sm": {
            className += ' -sm';
            break;
        }
        case "md": {
            className += ' -md';
            break;
        }
    }


    if(props.active) {
        className += ' -active';
    }

    return (
        <button
            type={props.btnType ?? 'button'}
            className={`btn ${className} -withIcon`}
            style={props.style}
            disabled={!!props.disabled}
            onClick={props.onClick}
        >
            {props.icon && (
                <img className="btn__icon" style={{width: props.width ?? '30px'}} src={props.icon} alt=""/>
            )}
            {props.label ? (
                <span dangerouslySetInnerHTML={{__html: props.label}}/>
            ) : props.children}
        </button>
    )
}
