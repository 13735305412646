import {useNavigation, useTranslation} from "../../../misc/Hooks";
import {Alert} from "../../../components/_index";
import {Preloader} from "../../../misc/_index";
import React from "react";
import {CustomerForm} from "../../../components/forms/stay_home";
import * as stayHomeReducer from "../../../redux/stayHomeReducer";
import {useDispatch} from "react-redux";
import {Routes} from "../../../utils/_index";
import {Button} from "../../../components/buttons/_index";
import {useHistory} from "react-router-dom";


export default function CustomerScreen(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const navigation = useNavigation()
    const t = useTranslation();

    const onClickNavToLogin = () => {
        history.push(navigation(Routes.STAY_HOME_AUTHORIZATION))
        dispatch(stayHomeReducer.setNespressoMember(undefined));
    }

    return (
        <div>
            <div className="gridSm -full">
                <div>
                    <div className="--mb4">
                        <CustomerForm
                            customer={props.customer}
                            sending={props.sending}
                            plan={props.plan}
                            nespressoMember={props.nespressoMember}
                            // paymentMethod={props.paymentMethod}
                            addresses={props.addresses}
                            loadingAddresses={props.loadingAddresses}
                            onSubmit={props.onSubmit}
                        />
                        {!!props.error && (
                            <div className="step__nav">
                                <Alert type="error" text={t(`error.${props.error}`)}/>
                            </div>
                        )}
                        {!!props.sending && <Preloader/>}
                        {!!props.loginFormVisible && (
                            <div className="grid --mb4 --pt4">
                                <div>
                                    <p className="par-2 --mb3 --error">{t(`error.account_exists_please_log_in`)}</p>
                                    <Button
                                        type="primary"
                                        label={t('error.account_exists_please_log_in_button')}
                                        onClick={onClickNavToLogin}
                                    />
                                </div>
                                <div/>
                            </div>
                        )}
                    </div>
                </div>
                <div/>
            </div>
        </div>
    )
}
