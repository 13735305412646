import {Config, Routes} from "../../../utils/_index";
import {useSelector} from "react-redux";


/**
 * Returns Order Finish module steps
 * @returns {{component, route: string, title: string}[]}
 */
export default function useSteps() {
    const store = useSelector(({of}) => ({
        finishOrder: of.finishOrder,
    }))
    return Config.ORDER_FINISH_STEPS.filter(step => {
        if (step.route === Routes.ORDER_FINISH_AUTHORIZATION) {
            if(store.finishOrder) {
                if(!store.finishOrder.webaccount_exist)
                    step.title = 'finish_order_account.title2'
                else
                    step.title = 'finish_order_account.title'
            } else
                step.title = 'ev_customer_identification.title'
        }

        if (!store.finishOrder?.webaccount_exist && step.route === Routes.ORDER_FINISH_CUSTOMER)
            return false
        return true
    })
}
