import React from "react";
import {usePriceFormatter, useTranslation} from "../misc/Hooks";


export default function MemberCreditInfo({memberCredit, ...props}) {
    const t = useTranslation()
    const {formatPrice} = usePriceFormatter();

    return !!memberCredit && (memberCredit.grandTotal > 0 && memberCredit.used > 0) && (
        <div className="evDP__info">
            <p className="par-2">{t('member_credit.text')}</p>
            <p className="par-2">
                {t('member_credit.available_credit', formatPrice(memberCredit.used + memberCredit.remaining))}
                <br/> {t('member_credit.remaining_after', formatPrice(memberCredit.remaining))}
                <br/> {t('member_credit.charge', formatPrice(memberCredit.grandTotal - memberCredit.used))}
            </p>
        </div>
    )
}
