import {useTranslation} from "../../misc/Hooks";
import {useState} from "react";
import {TermsInfoModal} from "../modals/_index";


export default (props) => {
    const t = useTranslation();
    const [modalVisible, setModalVisible] = useState(false)

    const modalButton = t(`${props.modulePrefix ?? ''}terms_info.modal_button`);
    const modalContent = t(`${props.modulePrefix ?? ''}terms_info.modal_content`);
    const text1 = t(`${props.modulePrefix ?? ''}terms_info.text1`);
    const text2 = t(`${props.modulePrefix ?? ''}terms_info.text2`);
    const text3 = t(`${props.modulePrefix ?? ''}terms_info.text3`);

    if(!modalButton && !text1 && !text2 && !text3) {
        return null;
    }

    return (
        <div className="termsInfo par-2 --mb4">
            {!!modalButton && (
                <div>
                    <button
                        type="button"
                        className="link -primary -parentSize --mb3"
                        dangerouslySetInnerHTML={{__html: modalButton}}
                        onClick={() => setModalVisible(true)}
                    />
                </div>
            )}
            {!!text1 && (
                <p className="--mb3" dangerouslySetInnerHTML={{__html: text1}} />
            )}
            {!!text2 && (
                <p className="--mb3" dangerouslySetInnerHTML={{__html: text2}} />
            )}
            {!!text3 && (
                <p dangerouslySetInnerHTML={{__html: text3}} />
            )}

            {!!modalButton && (
                <TermsInfoModal visible={modalVisible} onClose={() => setModalVisible(false)}>
                    <div className="par-5 --mb2" dangerouslySetInnerHTML={{__html: modalContent}} />
                </TermsInfoModal>
            )}
        </div>
    )
}
