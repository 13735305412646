import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom'
import {GTM} from "../../misc/_index";
import CustomerIdentificationStep from "../../components/ev/CustomerIdentificationStep";
import * as authorizationActions from '../../actions/AuthorizationActions';
import {Routes} from "../../utils/_index";
import {useNavigation, useTranslation} from "../../misc/Hooks";
import * as evReducer from '../../redux/evReducer';


interface Props {
}


let MOUNTED = false;

export const CustomerIdentificationStepContainer = (props: Props) => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const store = useSelector(({main, ev}) => ({
        user: main.user,
        recaptchaKey: main.recaptchaKey,
        nespressoMember: ev.nespressoMember,
        customer: ev.customer,
    }))
    const t = useTranslation();
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        MOUNTED = true;
        document.title = t('ev_customer_identification.title');
        GTM.showPage(t('ev_customer_identification.title'))

        return () => {
            MOUNTED = false;
        }
    }, [])


    const handleSelect = (data) => {
        dispatch(evReducer.setIsNewMember(!data));
        dispatch(evReducer.setNespressoMember(data));
        if(data === false && (data !== store.nespressoMember)) {
            dispatch(evReducer.setPromotion(null))
            dispatch(evReducer.setOrderAccount(null, false))
        }
        if(data === false) {
            dispatch(evReducer.setCustomer(null))
            history.push(navigation(Routes.SHOP_PROMO_SELECTION));
        }
    }


    const handleSubmit = (data) => {
        setLoading(true);
        authorizationActions.signIn(data).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                dispatch(evReducer.setIsNewMember(false));
                dispatch(evReducer.setNespressoMember({
                    recaptchaToken: data.recaptchaToken,
                    email: data.login,
                    ...res.data,
                }));
                if(store.nespressoMember?.email !== data.login) {
                    dispatch(evReducer.setPromotion(null))
                    dispatch(evReducer.setOrderAccount(null, false))
                }
                history.push(navigation(Routes.SHOP_PROMO_SELECTION))
            }
            else {
                if(res.messages?.length) {
                    if(MOUNTED) {
                        setError(res.messages[0]);
                    }
                }
                console.warn('Something gone wrong! ', res);
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if(MOUNTED) {
                setError(null);
                setLoading(false);
            }
        })
    }


    const handleSubmitEmail = (data) => {
        const customer = {
            email: data.email,
            lang: data.lang,
        }
        dispatch(evReducer.setCustomer(customer))
        if(store.customer?.email !== customer.email) {
            dispatch(evReducer.setPromotion(null))
        }
        history.push(navigation(Routes.SHOP_PROMO_SELECTION));
    }


    return (
        <CustomerIdentificationStep
            nespressoMember={store.nespressoMember}
            error={error}
            loading={loading}
            user={store.user}
            customer={store.customer}
            onSelect={handleSelect}
            onSubmit={handleSubmit}
            onSubmitEmail={handleSubmitEmail}
        />
    );
}
