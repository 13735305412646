import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch,} from "react-router-dom";
import Axios from "../utils/Axios";
import * as configActions from "../actions/ConfigActions";
import * as categoryActions from "../actions/CategoryActions";
import {checkAutoAdd} from "./Helpers";
import * as evReducer from "../redux/evReducer";
import {useResellerActions} from "../actions/useResellerActions";
import {useLanguage} from "../hooks";


let MOUNTED = false;
let LOADING_UUID = false;


const EvAppConfig = (props) => {
    const match = useRouteMatch();
    const resellerActions = useResellerActions()
    const currentLanguage = useLanguage();
    const dispatch = useDispatch();
    const store = useSelector(({main, ev}) => ({
        user: main.user,
        marketConfig: main.marketConfig,
        country: main.country,
        coffeeTypes: main.coffeeTypes,
        coffeeSizes: main.coffeeSizes,
        aromaticProfiles: main.aromaticProfiles,
        civilities: main.civilities,
        promotion: ev.promotion,
        machine: ev.machine,
        proofOfPurchase: ev.proofOfPurchase,
        cart: ev.cart,
        uuid: ev.uuid,
        coffeeLines: ev.coffeeLines,
        categories: ev.categories,
        products: ev.products,
        nespressoMember: ev.nespressoMember,
        customer: ev.customer,
        cookiesAccepted: ev.cookiesAccepted,
    }))

    useEffect(() => {
        Axios.defaults.headers.common['Language'] = currentLanguage;

        if(!store.promotion) {
            dispatch(evReducer.updateCart({items: [], sum: ''}))
        }
    }, [store.categories])


    useEffect(() => {
        if (store.promotion) {
            getCategories();
        }
    }, [match.params.lang, store.promotion])


    useEffect(async () => {
        MOUNTED = true;

        return () => {
            MOUNTED = false;
        }
    }, [])



    const getCategories = () => {
        const hasAccount = store.nespressoMember !== false
        dispatch(evReducer.setLoadingCategories(true));
        if(store.user) {
            resellerActions.getCategories(
                store.promotion?.id_promotion,
                store.machine?.id_machine ?? 0,
                store.proofOfPurchase?.retailerStore?.value ?? 0,
                hasAccount ? 1 : 0,
            ).then(handleSuccess).catch(err => {
                console.error('Error: ', err)
            })
        } else {
            categoryActions.getAll(
                store.promotion?.id_promotion,
                store.machine?.id_machine ?? 0,
                store.proofOfPurchase?.retailerStore?.value ?? 0,
                hasAccount ? 1 : 0,
            ).then(handleSuccess).catch(err => {
                console.error('Error: ', err)
            })
        }

        async function handleSuccess(r) {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(evReducer.setProducts(Object.values(res.data.products)));
                    dispatch(evReducer.setCategories(res.data.categories));
                    dispatch(evReducer.updateCart(checkAutoAdd(res.data.categories, Object.values(res.data.products), store.cart, store.machine, store.proofOfPurchase?.machinePrice)))
                }
            } else {
                console.warn('Something gone wrong! ', res);
            }
        }
    }


    const getUuid = () => {
        LOADING_UUID = true;
        configActions.getUuid().then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(evReducer.setUuid(res.data));
                }
            } else {
                console.warn('Cannot get UUID! ', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            LOADING_UUID = false;
        })
    }

    return (
      <>
          {props.children}
      </>
    );
}


export default EvAppConfig;
