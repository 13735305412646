import Axios from "../utils/Axios";


export const get = () => {
    return Axios.get(`/market-config`);
}

export const getUuid = () => {
    return Axios.get(`/uuid`);
}
