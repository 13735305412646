import {useTranslation} from "../../../misc/Hooks";
import React, {useEffect} from "react";
import {OrderFinishSignInForm, PasswordForm} from "../../../components/forms/_index";
import {Preloader} from "../../../misc/_index";
import {Button} from "../../../components/buttons/_index";
import {Link} from "react-router-dom";
import {useStepsNavigation} from "../../../hooks/modules/order_finish";


export default function AuthorizationScreen(props) {
    const t = useTranslation()
    const stepsNavigation = useStepsNavigation()

    return (
        <div>
            <div className="grid">
                <div>
                    {props.nespressoMember ? (
                        <>
                            <p className="par-2 --mb4">{t('finish_order_account.you_are_signed_in')}</p>
                            <div className="step__nav -bottom">
                                <Link to={stepsNavigation.next()}>
                                    <Button
                                        label={t('continue')}
                                    />
                                </Link>
                            </div>

                        </>
                    ) : props.order?.webaccount_exist ? (
                        <>
                            <div className="--mb4">
                                <OrderFinishSignInForm
                                    loading={props.loading}
                                    email={props.order.delivery_email}
                                    error={props.error}
                                    onSubmit={props.onSubmit}
                                />
                            </div>
                        </>
                    ) : (
                        <PasswordForm
                            error={props.error}
                            loading={props.loading}
                            submitText={t('create_my_account')}
                            onSubmit={props.onSubmitPassword}
                        />
                    )}
                    {!!props.loading && <Preloader/>}
                </div>
                <div/>
            </div>
        </div>
    )

}
