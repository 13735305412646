import React, {useState} from "react";
import {MyInput, MySelect} from "../inputs/_index";
import {Button} from "../../buttons/_index";
import {usePriceFormatter, useTranslation} from "../../../misc/Hooks";
import {Controller, useForm} from "react-hook-form";
import {useSelector} from "react-redux";


const TpsCartForm = (props) => {
    const t = useTranslation();
    const {formatPriceObj, formatPrice} = usePriceFormatter();
    const {marketConfig} = useSelector(({main}) => ({
        marketConfig: main.marketConfig,
    }))
    const {control, errors, watch, setValue, handleSubmit} = useForm({
        defaultValues: {
            category: null,
            product: null,
            quantity: '',
        }
    })
    const category = watch('category');

    const [product, setProduct] = useState(null);


    const rules = {
        required: {required: {value: true, message: t('validation.required')}},
        quantity: {
            // required: {value: true, message: t('validation.required')},
            validate: v => {
                // console.log(Number(v) > 0)
                return Number(v) > 0 ? true : t('validation.invalid')
            },
        },
    };


    const onChangeCategory = (v, callback) => {
        setProduct(null);
        setValue('product', null);
        setValue('quantity', '');
        callback(v);
    }


    const onChangeProduct = (v, callback) => {
        setProduct(getProduct(props.categories, v.value));
        setValue('quantity', '');
        callback(v);
    }


    const removeProduct = (id) => {
        const arr = [...props.value];
        const idx = arr.findIndex(i => i.id === id);

        if(idx !== -1) {
            arr.splice(idx, 1);

            setProduct(null);
            setValue('product', null);
            setValue('quantity', '');

            props.onChange(arr);
        }
    }


    const onSubmit = (data) => {
        const arr = [...props.value];
        const idx = arr.findIndex(i => i.id === data.product.value);

        const obj = {
            id: data.product.value,
            value: Number(data.quantity) / product.product_package_qty,
        };

        if(idx !== -1) {
            arr[idx] = obj;
        }
        else {
            arr.push(obj)
        }

        props.onChange(arr);
    }


    let totalPrice = 0;
    props.value.map(i => {
        const product = getProduct(props.categories, i.id);
        const qty = getProductStep(product);

        if(marketConfig?.market_price_type_display === 'net') {
            totalPrice += qty * i.value * product.product_price?.net;
        } else {
            totalPrice += qty * i.value * product.product_price?.gross
        }
    })

    return (
        <div className="tpsCart">
            <div className="tpsCart__header grid --mb4">
                <Controller
                    name="category"
                    control={control}
                    rules={rules.required}
                    render={({onChange, value}) => (
                        <MySelect
                            name="category"
                            label={t('tps.category')}
                            value={value}
                            options={mapCategoriesToOptions(props.categories)}
                            error={errors.category ?? props.error}
                            onChange={v => onChangeCategory(v, onChange)}
                        />
                    )}
                />
                <Controller
                    name="product"
                    control={control}
                    rules={rules.required}
                    render={({onChange, value}) => (
                        <MySelect
                            name="product"
                            label={t('tps.product')}
                            value={value}
                            options={category ? mapProductsToOptions(props.categories, category.value) : []}
                            error={errors.product ?? props.error}
                            onChange={v => onChangeProduct(v, onChange)}
                            isSearchable
                        />
                    )}
                />

                <Controller
                    name="quantity"
                    control={control}
                    rules={rules.quantity}
                    render={({onChange, value}) => (
                        <MyInput
                            type="number"
                            label={t('tps.quantity')}
                            value={value}
                            inputProps={{step: getProductStep(product), min: getProductStep(product), max: product?.product_max_qty ?? 100}}
                            error={errors.quantity ?? props.error}
                            onChange={onChange}
                        />
                    )}
                />


                <Button
                    label={t('tps.add')}
                    disabled={props.sending}
                    onClick={handleSubmit(onSubmit)}
                />
            </div>

            <div style={{overflowX: 'auto'}}>
                <table className="tpsCart__table">
                    <thead>
                    <tr>
                        <th style={{minWidth: 125}}>{t('tps.product_name')}</th>
                        <th style={{width: 125}}>{t('tps.price')}</th>
                        <th style={{width: 125}}>{t('tps.quantity')}</th>
                        <th style={{width: 125}}>{t('tps.value')}</th>
                        <th style={{width: 125}}/>
                    </tr>
                    </thead>
                    <tbody>
                    {props.value?.map(i => {
                        const product = getProduct(props.categories, i.id)
                        const qty = getProductStep(product);
                        return !!product && (
                            <tr key={i.id}>
                                <td>{product.product_title}</td>
                                <td>{formatPriceObj(product.product_price)}</td>
                                <td>{qty * i.value}</td>
                                <td>
                                    {marketConfig?.market_price_type_display === 'net' ? (
                                        formatPrice(qty * i.value * product.product_price?.net)
                                    ) : (
                                        formatPrice(qty * i.value * product.product_price?.gross)
                                    )}
                                </td>
                                <td style={{textAlign: 'right'}}>
                                    <Button type="secondary" label={t('remove')} onClick={() => removeProduct(i.id)}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan={2}/>
                        <td><b>{t('total')}</b></td>
                        <td>{formatPrice(totalPrice)}</td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}


function mapCategoriesToOptions(categories) {
    const arr = [];
    categories?.map(c => {
        if(c.categories?.length) {
            const options = [];


            c.categories.map(sc => {
                options.push({
                    value: sc.id_category,
                    label: sc.category_title,
                })
            })

            arr.push({
                label: c.category_title,
                options,
            })
        }
        else {
            arr.push({
                value: c.id_category,
                label: c.category_title,
            })
        }
    })
    return arr;
}



function mapProductsToOptions(categories, categoryId) {
    const arr = [];

    let category = categories?.find(c => c.id_category === categoryId);
    if(!category) {
        categories.find(c => c.categories?.find(sc => {
            if(sc.id_category === categoryId) {
                category = sc;
                return true;
            }
            return false;
        }))
    }

    category?.products?.map(p => {
        arr.push({
            value: p.id_product,
            label: p.product_title,
        })
    });

    return arr;
}


function getProductStep(product) {
    if(!product)
        return 10;
    return product.product_step * product.product_package_qty;
}


function getProduct(categories, id) {
    let product = null;
    categories?.find(c => {
        const found = c.categories?.find(sc => sc.products?.find(p => {
            if(p.id_product === id) {
                product = p;
                return true;
            }
            return false;
        }))


        if(found)
            return true;

        product = c.products?.find(p => p.id_product === id);
        return product;
    })
    return product;
}


export default TpsCartForm;
