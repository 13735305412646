import React, {useEffect, useMemo, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Button} from "../buttons/_index";
import {useNavigation, useTranslation} from "../../misc/Hooks";
import {useDispatch, useSelector} from "react-redux";
import * as evReducer from "../../redux/evReducer";
import {Config, Routes} from "../../utils/_index";
import Table from "../Table";
import {isoDateConverterWithTime} from "../../misc/Helpers";
import {useLanguage} from "../../hooks";
import Env from "../../utils/Env";
import ResendEmailButton from "./ResendEmailButton";


const mapOrdersToOptions = (orders) => {
    return orders.map(order => ({
        id: order.id_order,
        // pos_name: {
        //     translate: order.shop_title_translate,
        //     title: order.shop_title,
        // },
        // reseller_email: order.reseller_email,
        customer_name: `${order.delivery_firstname} ${order.delivery_lastname}`,
        customer_email: order.delivery_email,
        type_of_order: order.promotion_type,
        tracking_code: order.tracking_code,
        status_of_registration: order.status,
        status_of_payment: order.payment_status,
        date: isoDateConverterWithTime(order.created_at, '-', ':'),
        type_of_machine: order.machine_coffee_type,
        serial_number: order.serial,
        options: order,
    }))
}

export default (props) => {
    const t = useTranslation();
    const navigation = useNavigation();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const store = useSelector(({main}) => ({
        country: main.country,
    }))

    const languages = Env.LANGUAGES_FROM_BACKOFFICE ? (
       store.country?.country_languages?.map(l => l.lang_code) ?? Env.LANGUAGES
    ) : Env.LANGUAGES;

    const defaultLang = Env.DEFAULT_LANG ?? languages[0];

    const columns = useMemo(() => [
        {Header: t('assisted_registration.id'), accessor: 'id'},
        // {
        //     Header: t('assisted_registration.pos_name'),
        //     accessor: 'pos_name',
        //     Cell: itemProps => {
        //         let posName = null;
        //         if(itemProps.value.translate){
        //             const json = JSON.parse(itemProps.value.translate);
        //             const keys = Object.keys(json)
        //             const index = keys.findIndex(k => k === (match.params.lang ?? defaultLang));
        //             if(index>-1){
        //                 posName = Object.values(json)[index];
        //             }
        //         }
        //
        //         return posName ?? itemProps.value.title
        //     }
        // },
        // {Header: t('assisted_registration.reseller_email'), accessor: 'reseller_email'},
        // {
        //     Header: t('assisted_registration.type_of_order'),
        //     accessor: 'type_of_order',
        //     Cell: itemProps => t(`assisted_registration.type_order.${itemProps.value}`)
        // },
        {Header: t('assisted_registration.tracking_code'), accessor: 'tracking_code'},
        {
            Header: t('assisted_registration.status_of_registration'),
            accessor: 'status_of_registration',
            Cell: itemProps => <Button size="md" label={t(`subscription_status.${itemProps.value}`)}/>
        },
        {
            Header: t('assisted_registration.status_of_payment'),
            accessor: 'status_of_payment',
            Cell: itemProps => <Button size="md" label={t(`payment_status.${itemProps.value}`)}/>
        },
        {Header: t('assisted_registration.date'), accessor: 'date'},
        {Header: t('assisted_registration.customer_name'), accessor: 'customer_name'},
        {Header: t('assisted_registration.customer_email'), accessor: 'customer_email'},

        {Header: t('assisted_registration.type_of_machine'), accessor: 'type_of_machine'},
        {Header: t('assisted_registration.serial_number'), accessor: 'serial_number'},
        {
            Header: t('order.options'),
            accessor: 'options',
            Cell: props => {
                return [1, 2].includes(props.value.payment_status) ? null : (
                    <ResendEmailButton
                    value={props.value}
                    onClick={onResendOrder}
                    />
                )
            }
        },
    ], [])

    const onResendOrder = (id) => {
        props.resendOrder(id)
    }

    const navToMachineRegistration = () => {
        dispatch(evReducer.clearStore())
        history.push(navigation(Routes.SHOP_MACHINE_REGISTRATION))
    }

    const ordersOptions = useMemo(() => mapOrdersToOptions(props.orders), [props.orders])

    return (
        <>
            <div className="resMS">
                <div className="resMS__header">
                    <div className="--flex --spaceBetween">
                        <Button
                            type="primary-outline"
                            // label={t('ev_machine_registration.title')}
                            label={t('assisted_registration.join_the_promotion') ?? t('register_new_customer')}
                            onClick={navToMachineRegistration}
                        />
                    </div>
                </div>
                <div className="resMS__table">
                    <Table
                        title={t('assisted_registration.orders')}
                        columns={columns}
                        data={ordersOptions}
                        noSearchForm
                        maxPage={props.ordersMaxPage}
                        results={props.ordersResults}
                        loading={props.ordersLoading}
                        getData={(cp, ps, v = null) => props.getData(cp, ps, v)}
                        repeatTimeout={20000}
                        autoFetch
                        repeatFetching
                    />
                </div>

            </div>

        </>
    )
}
