import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import {persistor, store} from './app/redux/store';
import {Navigation} from "./app/misc/_index";
import Modal from "react-modal";
import smoothscroll from 'smoothscroll-polyfill';
import TagManager from "react-gtm-module";


import './app/assets/style/main.css';
import Env from "./app/utils/Env";


Modal.setAppElement('#root')

smoothscroll.polyfill();


const tagManagerArgs = {
    gtmId: Env.GTM_ID,
}

if(Env.GTM_ID) {
    TagManager.initialize(tagManagerArgs)
}


function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>

                <Navigation/>

            </PersistGate>
        </Provider>
    );
}


export default App;
