



const initialState = {
    shop: null,
    firstname: '',
    lastname: '',
    lastVisit: null,
};


const SET_SHOP = 'reseller/setShop';
const SET_NAME = 'reseller/setName';
const SET_LAST_VISIT = 'reseller/setLastVisit';


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHOP:
            return {...state, shop: action.shop};
        case SET_NAME:
            return {
                ...state,
                firstname: action.firstname,
                lastname: action.lastname,
            };
        case SET_LAST_VISIT:
            return {...state, lastVisit: action.lastVisit};

        default: return state;

    }
}


export const setShop = (shop) => ({type: SET_SHOP, shop})
export const setName = (firstname, lastname) => ({type: SET_NAME, firstname, lastname})
export const setLastVisit = (lastVisit) => ({type: SET_LAST_VISIT, lastVisit})


export default reducer;
