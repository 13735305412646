import {Route, Switch} from "react-router-dom";
import Routes from "../../utils/Routes";
import React from "react";
import Header from "../../components/misc/Header";
import Provider from "../auth/Provider";
import {RegistrationContainer, VerificationResellerContainer} from "./containers";

export default function Funnel(props) {

    return (
        <Provider>
            <div className="app">
                <Header
                    cartDisabled
                    alertsDisabled
                />
                <div className="main">
                    <div className="wrapper-bg formContainer">
                        <div className="wrapper">
                            <Switch>
                                <Route exact path={Routes.AUTH_RESELLER_REGISTRATION} component={RegistrationContainer} />
                                <Route exact path={Routes.AUTH_RESELLER_VERIFICATION} component={VerificationResellerContainer} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </Provider>
    )
}