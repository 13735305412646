import React, {useState} from 'react';
import BaseModal from "./BaseModal";
import {useTranslation} from "../../misc/Hooks";
import {GetInTouchModal} from "./_index";
import {FilterForm} from "../forms/_index";


interface Props {
    visible: boolean,
    categories: [],
    filter: any,
    cupSizes: [],
    aromaticProfiles: [],
    onClose: void,
    onSubmit: void,
    onReset: void,
}


export default (props: Props) => {
    const [gitModalVisible, setGitModalVisible] = useState(false);
    const t = useTranslation();


    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            {!!props.visible && (
              <>
                  <button
                      className="modal__close -left filterModal__reset"
                      onClick={props.onReset}
                  >
                      {t('reset')}
                  </button>
                  <div className="modal__header">
                      <h3 className="modal__title">{t('filter.title')}</h3>
                  </div>

                  <div className="modal__body">
                      <FilterForm
                          filter={props.filter}
                          cupSizes={props.cupSizes}
                          aromaticProfiles={props.aromaticProfiles}
                          coffeeTypes={props.coffeeTypes}
                          productsCount={props.productsCount}
                          onSubmit={props.onSubmit}
                          hidePopup={props.hidePopup}
                      />
                  </div>

                  <div className="modal__footer">

                      <div className="par-4">
                          <b>{t('need_help')} </b>
                          <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                              <b>{t('get_in_touch')}</b>
                          </a>
                      </div>
                  </div>

                  <GetInTouchModal
                      visible={gitModalVisible}
                      onClose={() => setGitModalVisible(false)}
                  />
              </>
            )}
        </BaseModal>
    );
}
