import {useSelector} from "react-redux";
import {mapStackToOptions} from "../misc/Helpers";


export default function useStackOptions(stackName) {
    const stack = useSelector(({main}) => main[stackName])

    const options = mapStackToOptions(stack)

    const find = (value) => {
        if (!value) return null
        if (typeof value === 'object')
            return options.find(v => v.value == value.value)
        return options.find(v => v.value == value)
    }

    const startsWith = async (name) => {
        if (!name) return options.slice(0, 100)
        let i = 0
        return options.filter(o => {
            if(i >= 100) return
            const res = o.label.toLowerCase().startsWith(name.toLowerCase())
                || o.value.toLowerCase().startsWith(name.toLowerCase())
            if(res) {
                i++
                return true
            }
            return false
        })
    }

    return {options, find, startsWith}
}
