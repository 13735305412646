import {Controller, useForm} from "react-hook-form";
import {MyCheckbox, MyInput, MySelect} from "../../../../components/forms/inputs/_index";
import {Button} from "../../../../components/buttons/_index";
import React, {useState} from "react";
import {useTranslation} from "../../../../misc/Hooks";
import Patterns from "../../../../utils/Patterns";
import {useSelector} from "react-redux";

export default function UserEditForm(props) {
    const t = useTranslation();
    const store = useSelector(({main}) => ({
        user: main.user
    }))

    const [shopOptions, setShopOptions] = useState(props.data.user.parent_options.find(p => p.id_reseller === props.data.reseller.id_parent).reseller_shops.map(s => ({
        label: s.shop_title,
        value: s.id_shop
    })))
    const roleOptions = [
        {label: t('reseller_client.reseller_role.pos_super_user'), value: 'pos_super_user'},
        {label: t('reseller_client.reseller_role.pos_user'), value: 'pos_user'},
    ]
    const supervisorOptions = props.data.user.parent_options.map(p => ({
        label: `${p.reseller_firstname} ${p.reseller_lastname}`,
        value: p.id_reseller
    }));

    const {control, errors, handleSubmit, watch, setValue} = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            firstname: props.data?.reseller?.reseller_firstname ?? '',
            lastname: props.data?.reseller?.reseller_lastname ?? '',
            email: props.data?.reseller?.reseller_email ?? '',
            role: roleOptions.find(r => r.value === props.data?.reseller?.reseller_role) ?? null,
            shop: shopOptions.find(s => s.value === props.data.reseller?.reseller_shops.id_shop) ?? null,
            supervisor: supervisorOptions.find(s => s.value === props.data.reseller.id_parent) ?? null,
            status: Boolean(props.data?.reseller?.reseller_status),
            number: props.data?.reseller?.reseller_number
        }
    });

    const supervisorWatch = watch('supervisor');
    const roleWatch = watch('role');
    const shopWatch = watch('shop');

    const onChangeSupervisor = (data) => {
        const newParent = props.data.user.parent_options.find(p => p.id_reseller === data.value);
        const newShops = newParent.reseller_shops.map(s => ({label: s.shop_title, value: s.id_shop}))

        if (roleWatch.value === 'pos_super_user' && props.data.reseller.childs_count) {
            alert(t('reseller_client.error_form_relations'))
        } else {
            if (roleWatch.value === newParent.reseller_role) {
                alert(t('reseller_client.error_form_same_role'))
            } else {
                setValue('supervisor', {label: data.label, value: data.value})
                setShopOptions(newShops)
                if (!newShops.find(s => s.value === shopWatch.value)) {
                    setValue('shop', {label: newShops[0].label, value: newShops[0].value})
                }
            }
        }
    }

    const onChangeRole = (data) => {
        if (props.data.reseller.reseller_role === 'pos_super_user') {
            if (props.data.reseller.childs_count) {
                alert(t('reseller_client.error_form_relations'))
            } else {
                if (props.data.user.parent_options.find(s => s.id_reseller === supervisorWatch.value).reseller_role === 'pos_super_user' && data.value === 'pos_super_user') {
                    alert(t('reseller_client.error_form_same_role'))
                } else {
                    setValue('role', {label: data.label, value: data.value})
                }
            }
        } else {
            if (props.data.user.parent_options.find(s => s.id_reseller === supervisorWatch.value).reseller_role === 'pos_super_user' && data.value === 'pos_super_user') {
                alert(t('reseller_client.error_form_same_role'))
            } else {
                setValue('role', {label: data.label, value: data.value})
            }
        }
    }

    const onChangeShop = (data) => {
        if (props.data.reseller.childs_count) {
            alert(t('reseller_client.error_form_relations'))
        } else {
            setValue('shop', {label: data.label, value: data.value})
        }
    }


    const rules = {
        firstname: {
            required: {value: true, message: t('validation.required')},
        },
        lastname: {
            required: {value: true, message: t('validation.required')},
        },
        number: {
            required: {value: true, message: t('validation.required')},
        },
        email: {
            required: {
                value: true,
                message: t('validation.required'),
            },
            pattern: {
                value: Patterns.email,
                message: t('validation.type_email')
            },
        },
        supervisor: {
            required: {value: true, message: t('validation.required')},
        },
        role: {
            required: {value: true, message: t('validation.required')},
        },
        shop: {
            required: {value: true, message: t('validation.required')},
        },
    }


    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>

                <div className="grid">
                    <Controller
                        name="firstname"
                        control={control}
                        rules={rules.firstname}
                        render={({onChange, value}) => (
                            <MyInput
                                type="text"
                                label={t('reseller_client.firstname') || t('firstname')}
                                value={value}
                                error={errors.firstname}
                                onChange={onChange}
                            />
                        )}
                    />
                    <Controller
                        name="lastname"
                        control={control}
                        rules={rules.lastname}
                        render={({onChange, value}) => (
                            <MyInput
                                type="text"
                                label={t('reseller_client.lastname') || t('lastname')}
                                value={value}
                                error={errors.lastname}
                                onChange={onChange}
                            />
                        )}
                    />
                </div>
                <div className="grid">
                    <Controller
                        name="number"
                        control={control}
                        rules={rules.number}
                        render={({onChange, value}) => (
                            <MyInput
                                type="text"
                                label={t('reseller_client.reseller_number')}
                                value={value}
                                error={errors.number}
                                onChange={onChange}
                            />
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        rules={rules.email}
                        render={({onChange, value}) => (
                            <MyInput
                                type="email"
                                label={t('reseller_client.email') || t('email')}
                                value={value}
                                error={errors.email}
                                onChange={onChange}
                            />
                        )}
                    />
                </div>
                <div className="grid">
                    <Controller
                        name="supervisor"
                        control={control}
                        rules={rules.lang}
                        render={({onChange, value}) => (
                            <MySelect
                                name="supervisor"
                                label={t('reseller_client.supervisor')}
                                value={value}
                                options={supervisorOptions}
                                error={errors.supervisor}
                                onChange={onChangeSupervisor}
                                disabled={store.user.reseller_role !== 'ka_super_user'}
                            />
                        )}
                    />
                    <Controller
                        name="role"
                        control={control}
                        rules={rules.lang}
                        render={({onChange, value}) => (
                            <MySelect
                                name="role"
                                label={t('reseller_client.role') || t('role')}
                                value={value}
                                options={roleOptions}
                                error={errors.role}
                                onChange={onChangeRole}
                                disabled={store.user.reseller_role !== 'ka_super_user'}
                            />
                        )}
                    />

                    <Controller
                        name="shop"
                        control={control}
                        rules={rules.lang}
                        render={({onChange, value}) => (
                            <MySelect
                                name="shop"
                                label={t('reseller_client.shop') || t('shop')}
                                value={value}
                                options={shopOptions}
                                error={errors.shop}
                                onChange={onChangeShop}
                                disabled={store.user.reseller_role !== 'ka_super_user'}
                            />
                        )}
                    />
                </div>
                <div className="--mb2">
                    <Controller
                        name="status"
                        control={control}
                        rules={rules.status}
                        render={({onChange, value}) => (
                            <MyCheckbox
                                name="status"
                                label={t('reseller_client.status') || t('status')}
                                value={value}
                                error={errors.status}
                                onChange={onChange}
                            />
                        )}
                    />
                </div>
                <div className="--mb2">
                    <Button
                        btnType="submit"
                        type="primary"
                        size=""
                        style={{minWidth: '31%'}}
                        label={t('reseller_client.save') || t('save')}
                        onClick={handleSubmit(props.onSubmit)}
                        disabled={props.sending.status}
                    />
                </div>
                <span className={`--dBlock --bold ${props.sending.finishStatus === 'success' ? '--success' : '--error'}`}>
                    {props.sending.message}
                </span>

            </form>
        </>
    )
}
