import React from "react";
import {Footer, Header} from "./misc/_index";
import {UploadPopForm} from "./forms/_index";
import {Preloader} from "../misc/_index";
import {useTranslation} from "../misc/Hooks";


export default (props) => {
    const t = useTranslation();


    return (
        <div className="app">
            <Header cartDisabled/>

            <div className="main">
                <div className="wrapper-bg formContainer">

                    <div className="wrapper">

                        <div className="grid">
                            <div>
                                <div className="--mb4">
                                    {props.order ? (
                                        <>
                                            <h2
                                                className="par-2"
                                                dangerouslySetInnerHTML={{__html: t('upload_pop.order_id', props.order.id_order)}}
                                            />
                                        </>
                                    ) : (
                                        <Preloader/>
                                    )}
                                </div>
                                <UploadPopForm
                                    loading={props.processing}
                                    disabled={props.message?.type === 'success'}
                                    onSubmit={props.onSubmit}
                                />


                                {!!props.message && (
                                    <p
                                        className={`par-2 ${props.message.type === 'success' ? '--success' : '--error'}`}
                                        dangerouslySetInnerHTML={{__html: props.message.text}}
                                    />
                                )}
                            </div>
                            <div/>
                        </div>




                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}

