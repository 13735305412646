import React, {useEffect, useRef, useState} from 'react';
import {getImage} from "../misc/Helpers";
import {Config} from "../utils/_index";
import {useCart, useTranslation} from "../misc/Hooks";


function isNextLocked(items: number): boolean {
    let locked = true;

    const width = document.body.offsetWidth;
    if(width < 30 * 16) {
        if(items > 1) locked = false;
    }
    else if(width < 36 * 16) {
        if(items > 2) locked = false;
    }
    else if(width < 48 * 16) {
        if(items > 3) locked = false;
    }
    else {
        if(items > 5) locked = false;
    }

    return locked;
}



interface Props {
    categories: [],
    promotion: any,
    easyOrderSetup: any,
    openFilter: void,
}



const CategoryBar = (props: Props) => {
    const t = useTranslation();
    const sliderRef = useRef(null);
    const {isCategoryValid} = useCart();
    const [offset, setOffset] = useState(0);
    const [lockNext, setLockNext] = useState(isNextLocked(props.categories.filter(c => c.products?.length).length));


    useEffect(() => {
        setLockNext(isNextLocked(props.categories.filter(c => c.products?.length).length))
    }, [props.categories])

    useEffect(() => {
        const el = sliderRef?.current?.querySelector('div');

        if(el) {
            sliderRef?.current?.scroll({
                left: offset * el.offsetWidth,
                top: 0,
                behavior: 'smooth',
            })
        }
    }, [offset])


    useEffect(() => {
        if(props.promotion.promotion_type !== Config.PROMO_TYPE.EASY_ORDER) {
            init();
        }

        scrollEventListener();

        window.addEventListener('scroll', scrollEventListener)

        return () => {
            window.removeEventListener('scroll', scrollEventListener)
        }
    }, [])


    useEffect(() => {
        if(props.promotion.promotion_type === Config.PROMO_TYPE.EASY_ORDER && props.easyOrderSetup) {
            init();
        }
    }, [props.easyOrderSetup])


    const init = () => {
        const headerHeight = document.querySelector('.header')?.clientHeight ?? 0;
        if(document.querySelector('.evCS__categories')?.style.top !== `${headerHeight - 25}px`) {
            document.querySelector('.evCS__categories').style.top = `${headerHeight - 25}px`;
        }
    }


    const scrollEventListener = () => {
        if(props.promotion.promotion_type !== Config.PROMO_TYPE.EASY_ORDER) {
            init();
        }

        const headerHeight = document.querySelector('.header')?.clientHeight ?? 0;
        const bar = document.querySelector('.evCS__categories');

        if(bar) {
            if(bar.getBoundingClientRect().top <= headerHeight - 25) {
                bar.querySelector('.bar').classList.add('-active');
            }
            else {
                bar.querySelector('.bar')?.classList.remove('-active');
            }
        }
    }


    const scrollTo = (idx) => {
        const headerHeight = document.querySelector('.header')?.clientHeight ?? 0;
        const barHeight = document.querySelector('.evCS__categories')?.clientHeight ?? 0;

        const section = document.getElementById(`category_${idx}`)

        let offset = section.getBoundingClientRect().top + document.documentElement.scrollTop;
        offset -= headerHeight + barHeight;

        window.scrollTo({behavior: 'smooth', top: offset})
    }


    const sliderPrev = () => {
        const newOffset = offset - 1;
        if(newOffset >= 0) {
            setOffset(newOffset)
            if(lockNext) {
                setLockNext(false);
            }
        }
    }


    const sliderNext = () => {
        const newOffset = offset + 1;

        let itemsCount = 5;

        const width = document.body.offsetWidth;
        if(width < 30 * 16) {
            itemsCount = 1;
        }
        else if(width < 36 * 16) {
            itemsCount = 2;
        }
        else if(width < 48 * 16) {
            itemsCount = 3;
        }

        if(newOffset + itemsCount <= sliderRef.current.children.length) {
            setOffset(newOffset)
            if(newOffset + itemsCount === sliderRef.current.children.length) {
                setLockNext(true);
            }
            else if(lockNext) {
                setLockNext(false);
            }
        }
        else if(!lockNext) {
            setLockNext(true);
        }
    }

    if(props.promotion.promotion_type === Config.PROMO_TYPE.EASY_ORDER && !props.easyOrderSetup)
        return null;

    return (
        <div className="evCS__categories">
            <div className="bar">
                <div className="bar__nav">
                    <div className="bar__button">
                        <button
                            type="button"
                            onClick={props.openFilter}
                        >
                            <img src={require('../assets/img/icons/filter.svg').default} alt={t('fitler.title')}/>
                        </button>
                    </div>

                    <div className="bar__button -right">
                        <button
                            type="button"
                            onClick={sliderPrev}
                            disabled={!offset}
                        >
                            <img src={require('../assets/img/icons/arrow-left.svg').default} alt={t('filter.title')}/>
                        </button>
                        <button
                            type="button"
                            onClick={sliderNext}
                            disabled={lockNext}
                        >
                            <img src={require('../assets/img/icons/arrow-right.svg').default} alt={t('filter.title')}/>
                        </button>
                    </div>
                </div>


                <div className="bar__slider" ref={sliderRef}>

                    {props.categories.map((cat, idx) => {
                        if(props.promotion.promotion_type === Config.PROMO_TYPE.EASY_ORDER
                            && !props.easyOrderSetup) {
                            return null;
                        }

                        // unlock rest of the categories, if this category is valid
                        let isValid = true;
                        props.categories.map((c, cIdx) => {
                            if (!isValid)
                                return;
                            if (cIdx > idx || cIdx === idx)
                                return;

                            if(c.promotionCategory?.promotion_category_hide_others)
                                isValid = isCategoryValid(c.id_category);
                        })

                        if (!isValid) {
                            return null;
                        }


                        return !!cat.products?.length && (
                            <div key={idx} className="bar__item">
                                <div className="barItem" onClick={() => scrollTo(idx)}>
                                    <div className="barItem__thumb">
                                        <div className="barItem__thumbContent">
                                            <img
                                                src={getImage(cat.category_gallery, Config.GALLERY_TYPE.BACKGROUND)}
                                                alt="" className="barItem__bg"
                                            />
                                            <img
                                                src={getImage(cat.category_gallery, Config.GALLERY_TYPE.COVER)}
                                                alt="" className="barItem__img"
                                            />
                                        </div>
                                    </div>
                                    <div className="barItem__label">{cat.category_title}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}


export default React.memo(CategoryBar);
