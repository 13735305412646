import React from "react";
import {useCart, usePriceFormatter, useShStepNavigation, useShSteps, useTranslation} from "../../misc/Hooks";
import {getStepHeaderHeight} from "../../misc/Helpers";
import {Alert, CartSummary} from "../_index";
import Env from "../../utils/Env";
import {PaymentTypeForm} from "../forms/_index";
import {MyLink} from "../../misc/MyLink";
import {Button} from "../buttons/_index";
import {Preloader} from "../../misc/_index";
import {useRouteMatch} from "react-router-dom";


const Summary = (props) => {
    const t = useTranslation();
    const steps = useShSteps();
    const stepNavigation = useShStepNavigation();
    const match = useRouteMatch();
    const {formatPrice} = usePriceFormatter();
    const {
        capsulesValue,
        capsulesVatValue,
        giftValue,
        deliveryValue,
        cartValue,
        isCartValid,
    } = useCart('stayHome');


    const getErrorFile = () => {
        return "data:text/json;charset=utf-8," + encodeURIComponent(props.errorLog);
    }


    return (
        <div className="shS">


            <div className="evDP__info">
                <div className="grid">
                    <div/>
                    <div className="evDP__cart" style={{marginTop: `-${getStepHeaderHeight() * steps.length}px`}}>
                        <CartSummary
                            storeName="stayHome"
                            cart={props.cart}
                            categories={props.categories}
                            products={props.products}
                            customItems={props.customCategories}
                            disableFooter
                        />
                    </div>
                </div>
            </div>


            <h3 className="evDP__title">{t('ev_delivery_payment.delivery_address')}</h3>
            <div className="evDP__info">
                <div className="grid">
                    <div className="info">
                        <div className="info__row">
                            {!!props.customer.name && (
                                <div className="info__info">
                                    <h5 className="info__name">{t('name')}</h5>
                                    <p className="info__value">{props.customer.name}</p>
                                </div>
                            )}
                            {!!props.customer.surname && (
                                <div className="info__info">
                                    <h5 className="info__name">{t('surname')}</h5>
                                    <p className="info__value">{props.customer.surname}</p>
                                </div>
                            )}
                        </div>
                        <div className="info__row">
                            {!!props.customer.postcode && (
                                <div className="info__info">
                                    <h5 className="info__name">{t('postcode')}</h5>
                                    <p className="info__value">{props.customer.postcode}</p>
                                </div>
                            )}
                            {!!props.customer.city && (
                                <div className="info__info">
                                    <h5 className="info__name">{t('city')}</h5>
                                    <p className="info__value">{props.customer.city}</p>
                                </div>
                            )}
                        </div>
                        {!!props.customer.address && (
                            <div className="info__info">
                                <h5 className="info__name">{t('address_line_1')}</h5>
                                <p className="info__value">{props.customer.address}</p>
                            </div>
                        )}
                        {!!props.customer.address2 && (
                            <div className="info__info">
                                <h5 className="info__name">{t('address_line_2')}</h5>
                                <p className="info__value">{props.customer.address2}</p>
                            </div>
                        )}
                        {!!props.customer.phone && (
                            <div className="info__info">
                                <h5 className="info__name">{t('phone')}</h5>
                                <p className="info__value">{props.customer.phone}</p>
                            </div>
                        )}
                        <div className="info__info">
                            <h5 className="info__name">{t('email')}</h5>
                            <p className="info__value">{props.customer.email}</p>
                        </div>
                    </div>
                    <div className="summary">
                        <div className="summary__row">
                            <h5 className="summary__name">{t('ev_delivery_payment.subtotal')}</h5>
                            <p className="summary__value">{formatPrice(capsulesValue)}</p>
                        </div>
                        <div className="summary__row">
                            <h5 className="summary__name --bold">{t('ev_delivery_payment.benefit')}</h5>
                            <p className="summary__value --bold">{formatPrice(giftValue)}</p>
                        </div>
                        <div className="summary__row">
                            <h5 className="summary__name">{t('ev_delivery_payment.tax')}</h5>
                            <p className="summary__value">{formatPrice(capsulesVatValue)}</p>
                        </div>
                        <div className="summary__row --mb4">
                            <h5 className="summary__name">{t('ev_delivery_payment.delivery')}</h5>
                            <p className="summary__value">{deliveryValue > 0 ? formatPrice(deliveryValue) : t('free')}</p>
                        </div>
                        <div className="summary__row">
                            <h5 className="summary__name">{t('ev_delivery_payment.total')}</h5>
                            <p className="summary__value --success --bold">{formatPrice(cartValue + deliveryValue)}</p>
                        </div>
                    </div>
                </div>
                <div className="grid">
                    <div/>
                    <div className="evDP__summaryInfo">
                        <p className="par-4">{t('ev_delivery_payment.total_info')}</p>
                        <p className="par-4">{t('ev_delivery_payment.summary_info')}</p>
                    </div>
                </div>
            </div>


            <div className="grid">
                <div>
                    {(props.paymentMethod === 'credit_card' && props.order) && !props.user && (
                        <iframe
                            width="100%"
                            height="600px"
                            style={{border: 'none'}}
                            src={`/payment.html?hash=${props.uuid}&token=${props.order.jwtToken}&lang=${match.params.lang ?? Env.DEFAULT_LANG}&api_url=${Env.API_URL}`}
                        />
                    )}
                </div>
                <div>
                    {!props.order ? (
                        <>
                            <div className="evDP__info">
                                <h3 className="par-1">{t('choose_payment_type')}</h3>
                                <PaymentTypeForm type={props.paymentMethod} onSubmit={props.onChangePaymentMethod}/>
                            </div>
                            <div className="step__nav -right -bottom --mb4">
                                <MyLink route={stepNavigation.prev()} disabled={props.loading}>
                                    <Button
                                        type="primary-outline"
                                        size="sm"
                                        label={t('back')}
                                        disabled={props.loading}
                                    />
                                </MyLink>
                                <Button
                                    type="primary"
                                    label={props.user ? t('confirm') : t('buy')}
                                    disabled={!isCartValid || !props.paymentMethod || props.loading}
                                    onClick={props.onSubmit}
                                />
                            </div>
                            {!!props.error && (
                                <div className="step__nav -right">
                                    <Alert type="error">
                                        <span dangerouslySetInnerHTML={{__html: t(`error.${props.error}`)}} style={{marginRight: '3px'}}/>
                                        {Env.ENV === 'prod' ? (
                                            <button type="button" className="link -parentSize" onClick={props.onSendErrors}>
                                                <u>{t('report_error')}</u>
                                            </button>
                                        ) : (
                                            <>
                                                <button
                                                    type="button"
                                                    className="link -parentSize"
                                                    style={{marginRight: '3px'}}
                                                    onClick={props.onSendErrors}
                                                >
                                                    <u>{t('report_error')}</u>
                                                </button>
                                                <a
                                                    href={getErrorFile()}
                                                    className="link -parentSize"
                                                    download={`stay_home_send_order_${new Date().toISOString()}.json`}
                                                >
                                                    <u>{t('download_error')}</u>
                                                </a>
                                            </>
                                        )}
                                    </Alert>
                                </div>
                            )}
                            {!!props.loading && <Preloader/>}
                        </>
                    ) : !!props.paymentMethod && (
                        <>
                            <div className="summary__row --mb4">
                                <div className="summary__name --bold">{t('payment_type')}</div>
                                <div className="summary__value -bold">{t(`payment_types.${props.paymentMethod}`)}</div>
                            </div>
                        </>
                    )}
                </div>
            </div>

        </div>
    )
}


export default Summary;
