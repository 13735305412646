import React, {useRef} from 'react';
import MaskedInput from 'react-input-mask';
import {Controller, useFormContext} from "react-hook-form";
import {getBase64FromFile} from "../../../misc/Helpers";


interface Props {
    name: string,
    label: string,
    mask: string,
    maskChar: string,
    formatChars: any,
    alwaysShowMask: boolean,
    value: string,
    acceptFiles: string,
    error: any,
    onChange: void,
    onUploadFile: void,
}


export default (props: Props) => {
    const inputRef = useRef(null);
    const fileInputRef = useRef(null);

    const {control} = useFormContext();

    const selectFile = (e) => {
        e.stopPropagation();
        e.preventDefault();
        fileInputRef.current?.click()
    }

    const handleUploadFile = (e, callback) => {
        const files = e.target?.files;
        if(files[0]) {
            getBase64FromFile(files[0], props.acceptFiles).then(res => {
                props.onUploadFile(res);
                callback(res)
            }).catch(err => {
                console.error('err: ', err)
            })
        }
    }

    const getFile = (files) => new Promise((resolve, reject) => {
        const file = files[0];
        if(file && props.acceptFiles.includes(file.type)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        }
    })


    let formGroupClassNames = '';
    if(props.value?.length || props.error) {
        formGroupClassNames += ' -active';
    }
    if(props.error) {
        formGroupClassNames += ' -invalid';
    }
    if(!props.error && props.value?.length) {
        formGroupClassNames += ' -valid';
    }

    return (
        <div className={`formGroup -file -active ${formGroupClassNames}`}>
            <label className="formGroup__field">
                {!!props.label && (
                    <span className="formGroup__label">{props.label}</span>
                )}
                <MaskedInput
                    mask={props.mask}
                    maskChar={props.maskChar ?? '_'}
                    alwaysShowMask={typeof props.alwaysShowMask !== 'undefined' ? props.alwaysShowMask : true}
                    value={props.value}
                    onChange={props.onChange}
                >
                    {(inputProps) => (
                        <input
                            ref={inputRef}
                            name={props.name ?? ''}
                            {...inputProps}
                        />
                    )}
                </MaskedInput>


                <button type="button" className="formGroup__fileButton" onClick={selectFile} disabled={props.loading}>
                    {props.loading ? (
                        <img src={require('../../../assets/img/icons/preloader2.gif').default} alt="loading"/>
                    ) : (
                        <img src={require('../../../assets/img/icons/upload.svg').default} alt="upload"/>
                    )}
                </button>

                <Controller
                    name="file"
                    control={control}
                    render={({onChange, value}) => (
                        <input
                            ref={fileInputRef}
                            type="file"
                            onChange={e => handleUploadFile(e, onChange)}
                            accept={props.acceptFiles}
                            hidden
                        />
                    )}
                />


            </label>



            {!!props.error && (
                <span className="formGroup__invalidFeedback">{typeof props.error === 'string' ? props.error : props.error.message}</span>
            )}
        </div>
    );
}
