import React, {useEffect, useRef, useState} from 'react';
import Select from "react-select";
import {useTranslation} from "../../../misc/Hooks";


interface Props {
   name: string,
   type: string,
   label: string,
   value: [],
   placeholder?: string,
   options: [],
   error: any,
   nullable?: boolean,
   raw?: boolean,
   onChange: void,

}


export default (props: Props) => {
   const t = useTranslation();
   const inputRef = useRef(null);
   const [isFocused, setFocused] = useState(false);
   const [selectedValues, setSelectedValues] = useState([])

   useEffect(()=> {
      setSelectedValues(props.value)
   }, [props.value])

   const handleChange = (e) => {
      if (!e?.value) {
         setFocused(false);
      }
      const selected = [...selectedValues]
      const index = selected.findIndex((item) => item.value === e.value)
      if (index !== -1) {
         selected.splice(index, 1)
      } else {
         selected.push(e)
      }
      setSelectedValues(selected)
      props.onChange(selected)
   }

   const deleteSelectedItem = (id) => {
      const arr = [...selectedValues];
      const idx = arr.findIndex((s) => s.value === id);
      if (idx > -1) {
         arr.splice(idx, 1);
      }
      props.onChange(arr)
      props.onBlur()
      setSelectedValues(arr)
   }

   const selectStyles = {
      control: (styles) => (
         {
            display: 'flex',
            paddingLeft: props.disabled ? 0 : '0.93rem',
            paddingRight: props.disabled ? 0 : '0.93rem',
            paddingTop: '1.115rem',
            paddingBottom: '0.7rem',
         }
      ),
      singleValue: (styles, {isDisabled}) => ({
         ...styles,
         color: (!props.error && props.value?.value && !props.raw) ? '#2E8561' : '#000000',
         margin: 0,
      }),
      valueContainer: (provider, state) => (
         {
            ...provider,
            padding: '0',
         }
      ),
      indicatorsContainer: (provider, state) => (
         {
            ...provider,
            padding: 0,
         }
      ),
      indicatorSeparator: (provider, state) => (
         {
            display: 'none'
         }
      ),
      dropdownIndicator: (provider, state) => (
         {
            ...provider,
            padding: 0,
         }
      ),
      placeholder: (provider, state) => (
         {
            ...provider,
            fontSize: '0.875em',
            lineHeight: 1,
            color: '#000000',
         }
      ),
      input: (provider, state) => (
         {
            ...provider,
            padding: 0,
            lineHeight: 1,
         }
      ),
      menu: (provider, state) => (
         {
            ...provider,
            top: 'calc(100% - 0.3rem)',
            fontSize: '0.875em',
            color: '#000000',
            borderColor: 'blue',
            borderRadius: '8px',
         }
      ),
      option: (provider, state) => ({
         ...provider,
         backgroundColor: state.isSelected ? '#2E8561' : undefined,
         '&:hover': {
            backgroundColor: '#2E8561',
            color: '#ffffff',
         },
      }),
   };


   let formGroupClassNames = '';
   if (props.value?.value || props.error) {
      formGroupClassNames += ' -active';
   }
   if (isFocused) {
      formGroupClassNames += ' -focused';
   }
   if (props.error) {
      formGroupClassNames += ' -invalid';
   }
   if (!props.error && props.value?.value && !props.raw) {
      formGroupClassNames += ' -valid';
   }
   if (props.disabled) {
      formGroupClassNames += ' -disabled';
   }

   const options = [];

   if (props.nullable) {
      options.push({
         label: t('none'),
         value: null,
      })
   }

   if (props.options?.length) {
      const arr = [];

      if (props.value && !props.noBottomOptions) {
         props.options.map(o => {
            if (!(props.value.find(v => v.value === o.value))) {
               arr.push(o)
            }
         })
      } else {
         arr.push(...props.options)
      }

      options.push(...arr);
   }

   const onBlurSelect = () => {
      setFocused(false)
      if (props.onBlur) {
         props.onBlur()
      }
   }

   const OptionComponent = (option) => {
      return (
         <div
            className={`customSelectOption ${props.selectedColor && selectedValues.find(s => s.value === option.data.value) ? '-selected' : ''}`}
            onClick={() => option.setValue(option.data)}>
            <div className="customSelectOption__label">{option.label}</div>
         </div>
      )
   }

   return (
      <div className={`formGroup -select ${formGroupClassNames}`}>
         <label className="formGroup__field">
            {!!props.label && (
               <span className="formGroup__label">{props.label}</span>
            )}

            <Select
               ref={inputRef}
               styles={selectStyles}
               name={props.name ?? ''}
               placeholder={props.placeholder ?? null}
               isSearchable={typeof props.isSearchable !== 'undefined' ? props.isSearchable : false}
               value={props.value?.value ? props.value : null}
               options={options}
               noOptionsMessage={v => t('no_options')}
               isDisabled={!!props.loading || props.disabled}
               onChange={handleChange}
               onFocus={() => setFocused(true)}
               onBlur={onBlurSelect}
               menuIsOpen={isFocused}
               components={{Option: OptionComponent}}
            />
            {!!props.loading && (
               <span className="formGroup__loading">
                        <img src={require('../../../assets/img/icons/preloader2.gif').default} alt=""/>
                    </span>
            )}

         </label>
         {(typeof props.value === 'object' && props.value.length > 0 && !props.noBottomOptions) && (
            <div className={`formGroup__selectedOptions ${props.disabled ? '-disabled' : ''}`}>
               {props.value.map(v => (
                  <div className="formGroup__selectedItem"
                       onClick={props.disabled ? null : () => deleteSelectedItem(v.value)}>
                     <span>{v.label} <img src={require('../../../assets/img/icons/close-white.svg').default}
                                          alt=""/></span>
                  </div>
               ))}
            </div>
         )}

         {!!props.error && (
            <span className="formGroup__invalidFeedback">{props.error.message}</span>
         )}
      </div>
   );
}
