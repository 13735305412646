import React, {useEffect} from "react";
import {Alert} from "../_index";
import {useSelector} from "react-redux";
import CartSummarySimple from "../CartSummarySimple";
import {Link, useHistory} from "react-router-dom";
import {Button} from "../buttons/_index";
import {
    useCart,
    useLocalizeObject,
    useMarketConfig,
    useNavigation,
    usePriceFormatter,
    useRmsStepNavigation,
    useRmsSteps,
    useTranslation
} from "../../misc/Hooks";
import {Preloader} from "../../misc/_index";
import {getAllStepsHeaderHeight, getStepHeaderHeight} from "../../misc/Helpers";
import Env from "../../utils/Env";
import {PaymentTypeResellerForm} from "../forms/_index";
import {isEnabledByMarket} from "../../utils/MarketConfig";


export default (props) => {
    const t = useTranslation();
    const localizeObject = useLocalizeObject();
    const navigation = useNavigation();
    const stepNavigation = useRmsStepNavigation();
    const history = useHistory();
    const steps = useRmsSteps();
    const marketConfig = useMarketConfig();
    const store = useSelector(({rms}) => ({
        cart: rms.cart,
        products: rms.products,
        categories: rms.categories,
        nespressoMember: rms.nespressoMember,
    }))
    const {formatPrice} = usePriceFormatter();
    const {
        capsulesValue,
        capsulesVatValue,
        giftValue,
        deliveryValue,
        cartValue,
        isCartValid,
    } = useCart('rms');

    useEffect(() => {
        const cartWrapperRef = document.querySelector('.evDP__cart')
        if (cartWrapperRef) {
            cartWrapperRef.style.marginTop = `-${getAllStepsHeaderHeight()}px`
        }
    })

    const getErrorFile = () => {
        return "data:text/json;charset=utf-8," + encodeURIComponent(props.errorLog);
    }

    const InfoComponent = ({label, value}) => !!value && (
        <div className="info__info">
            <h5 className="info__name">{label}</h5>
            <p className="info__value">{value}</p>
        </div>
    )

    const CustomAddressComponent = () => (
        <div>
            <h3 className="evDP__title">{t('ev_delivery_payment.custom_delivery_address')}</h3>
            <div className="info">
                {props.customer.customCompanyAddress ? (
                    <>
                        <InfoComponent label={t('company_name')} value={props.customer.customCompanyName}/>
                        <InfoComponent label={t('tax_id')} value={props.customer.customTaxId}/>
                    </>
                ) : (
                    <InfoComponent label={t('title')} value={props.customer.customTitle?.label}/>
                )}
                <div className="info__row">
                    <InfoComponent label={t('name')} value={props.customer.customName}/>
                    <InfoComponent label={t('surname')} value={props.customer.customSurname}/>
                </div>
                <div className="info__row">
                    <InfoComponent label={t('postcode')} value={props.customer.customPostcode}/>
                    <InfoComponent label={t('city')} value={props.customer.customCity}/>
                </div>
                <InfoComponent label={t('address_line_1')} value={props.customer.customAddress}/>
                <InfoComponent label={t('address_line_2')} value={props.customer.customAddress2}/>
                <InfoComponent label={t('region')} value={props.customer.customRegion?.label}/>
                <InfoComponent label={t('phone')} value={props.customer.customPhone}/>
                <InfoComponent label={t('phone_mobile')} value={props.customer.customPhoneMobile}/>
                <InfoComponent label={t('phone_home')} value={props.customer.customPhoneHome}/>
            </div>
        </div>
    )

    const SummaryComponent = () => (
        <div className="summary">
            {!marketConfig.hide_summary_subtotal_value && (
                <div className="summary__row">
                    <h5 className="summary__name">{t('ev_delivery_payment.subtotal')}</h5>
                    <p className="summary__value">{formatPrice(capsulesValue)}</p>
                </div>
            )}
            {!marketConfig.hide_summary_benefit_value && (
                <div className="summary__row">
                    <h5 className="summary__name --bold">{t('ev_delivery_payment.benefit')}</h5>
                    <p className="summary__value --bold">{formatPrice(giftValue)}</p>
                </div>
            )}
            {!marketConfig.hide_summary_tax_value && (
                <div className="summary__row">
                    <h5 className="summary__name">{t('ev_delivery_payment.tax')}</h5>
                    <p className="summary__value">{formatPrice(capsulesVatValue)}</p>
                </div>
            )}
            {!marketConfig.hide_summary_delivery_value && (
                <div className="summary__row --mb4">
                    <h5 className="summary__name">{t('ev_delivery_payment.delivery')}</h5>
                    <p className="summary__value">{deliveryValue > 0 ? formatPrice(deliveryValue) : t('free')}</p>
                </div>
            )}
            {/*{!!t('ev_delivery_payment.delivery_info') && (*/}
            {/*    <p className="par-4 --mb4" style={{textAlign: 'right'}}>{t('ev_delivery_payment.delivery_info')}</p>*/}
            {/*)}*/}
            <div className="summary__row">
                <h5 className="summary__name">{t('ev_delivery_payment.total')}</h5>
                <p className="summary__value --success --bold">{formatPrice(cartValue + deliveryValue)}</p>
            </div>
        </div>
    )

    const withCustomAddress = !!props.customer?.withCustomAddress;

    return (
        <div>

            <div className="evDP__info">
                <div className="grid">
                    <div style={{}}/>
                    <div className="evDP__cart">
                        <CartSummarySimple
                            storeName="rms"
                            cart={store.cart}
                            categories={store.categories}
                            products={store.products}
                            disableFooter
                        />
                    </div>
                </div>
            </div>


            <div className="evDP__info">
                <div className="grid">
                    <div>
                        <h3 className="evDP__title">{t('ev_delivery_payment.delivery_address')}</h3>
                        {!store.nespressoMember ? (
                            <div className="info">
                                {props.customer.companyAddress ? (
                                    <>
                                        <InfoComponent label={t('company_name')} value={props.customer.companyName}/>
                                        <InfoComponent label={t('tax_id')} value={props.customer.taxId}/>
                                        <InfoComponent label={t('profession')} value={props.customer.profession?.label}/>
                                    </>
                                ) : (
                                    <InfoComponent label={t('title')} value={props.customer.title?.label}/>
                                )}
                                <div className="info__row">
                                    <InfoComponent label={t('name')} value={props.customer.name}/>
                                    <InfoComponent label={t('surname')} value={props.customer.surname}/>
                                </div>
                                <div className="info__row">
                                    <InfoComponent label={t('postcode')} value={props.customer.postcode}/>
                                    <InfoComponent label={t('city')} value={props.customer.city}/>
                                </div>
                                <InfoComponent label={t('address_line_1')} value={props.customer.address}/>
                                <InfoComponent label={t('address_line_2')} value={props.customer.address2}/>
                                <InfoComponent label={t('region')} value={props.customer.region?.label}/>
                                <InfoComponent label={t('phone')} value={props.customer.phone}/>
                                <InfoComponent label={t('phone_mobile')} value={props.customer.phoneMobile}/>
                                <InfoComponent label={t('phone_home')} value={props.customer.phoneHome}/>
                                <InfoComponent label={t('email')} value={props.customer.email}/>
                            </div>
                        ) : (
                            <div className="info">
                                <InfoComponent label={t('email')} value={props.customer.email}/>
                            </div>
                        )}
                    </div>
                    {withCustomAddress ? (
                        <CustomAddressComponent/>
                    ) : (
                        <SummaryComponent/>
                    )}
                </div>
                {withCustomAddress && (
                    <>
                        <hr className="-light --mb4"/>
                        <div className="grid">
                            <div/>
                            <SummaryComponent/>
                        </div>
                    </>
                )}
                <div className="grid">
                    <div/>
                    <div className="evDP__summaryInfo">
                        <p className="par-4">{t('ev_delivery_payment.total_info')}</p>
                        {!isEnabledByMarket('hideTotalPriceInfoMachineSubscription') && (
                            <p className="par-4">{t('ev_delivery_payment.summary_info')}</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="grid">
                <div>
                </div>
                <div>
                    {store.nespressoMember ? (
                        <div className="evDP__info">
                            <h3 className="par-1">{t('choose_payment_type')}</h3>
                            <PaymentTypeResellerForm
                                stepNavigation={stepNavigation}
                                plan={props.plan}
                                customer={props.customer}
                                types={props.plan.payment_methods}
                                type={props.customer?.paymentMethod}
                                onSubmit={props.onChangePaymentMethod}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="summary__row --mb4">
                                <div className="summary__name --bold">{t('payment_type')}</div>
                                <div
                                    className="summary__value -bold">{localizeObject(props.customer.paymentMethod?.payment_method_title)}</div>
                            </div>
                            <div className="step__nav -right -bottom --mb4">
                                <Link to={stepNavigation.prev()}>
                                    <Button
                                        type="primary-outline"
                                        size="sm"
                                        disabled={props.sending}
                                        label={t('back')}
                                    />
                                </Link>
                                <Button
                                    type="primary"
                                    label={t('confirm')}
                                    disabled={props.sending || !props.customer.paymentMethod}
                                    onClick={props.onSubmit}
                                />
                            </div>
                        </>
                    )}


                    {!!props.error && (
                        <Alert type="error">
                            <span dangerouslySetInnerHTML={{__html: t(`error.${props.error}`)}}
                                  style={{marginRight: '3px'}}/>
                            {Env.ENV === 'prod' ? (
                                <button type="button" className="link -parentSize" onClick={props.onSendErrors}>
                                    <u>{t('report_error')}</u>
                                </button>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        className="link -parentSize"
                                        style={{marginRight: '3px'}}
                                        onClick={props.onSendErrors}
                                    >
                                        <u>{t('report_error')}</u>
                                    </button>
                                    <a
                                        href={getErrorFile()}
                                        className="link -parentSize"
                                        download={`rms-send_order_${new Date().toISOString()}.json`}
                                    >
                                        <u>{t('download_error')}</u>
                                    </a>
                                </>
                            )}
                        </Alert>
                    )}
                    {!!props.sending && (
                        <div style={{textAlign: 'center'}}>
                            <Preloader classes="--mb3"/>
                            <p className="par-2" dangerouslySetInnerHTML={{__html: t('ev_delivery_payment.loading_text')}}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
