import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import {Routes} from "../utils/_index";
import {EVFunnelContainer} from "../containers/ev/_index";
import * as mainReducer from '../redux/mainReducer';
import * as rmsReducer from '../redux/rmsReducer';
import * as evReducer from '../redux/evReducer';
import * as ofReducer from '../redux/ofReducer';
import * as stayHomeReducer from '../redux/stayHomeReducer';
import * as tpsReducer from '../redux/tpsReducer';
import {ResellerFunnelContainer} from "../containers/reseller/_index";
import Env from "../utils/Env";
import {
    ClearStorageContainer,
    MaintenanceContainer,
    OrderFinalizedContainer,
    PaymentRejectedContainer,
    UploadPopContainer
} from "../containers/_index";
import Axios from "../utils/Axios";
import {Error404Screen} from "../components/misc/_index";
import {useEvSteps, useOfSteps, useRmsSteps, useShSteps} from "./Hooks";
import * as eTradeHooks from '../hooks/modules/etrade'
import * as stayHomeHooks from '../hooks/modules/stay_home'
import * as orderFinishHooks from '../hooks/modules/order_finish'
// import {OrderFinishFunnel} from "../containers/order/_index";
import {TpsFunnel} from "../containers/tps";
import ResellerFunnel from "../modules/reseller/ResellerFunnel";
import AuthFunnel from "../modules/auth/Funnel";
import BaseModal from "../components/modals/BaseModal";
import Funnel from "../modules/reseller/users/Funnel";
import ETradeFunnel from "../modules/etrade/Funnel"
import StayHomeFunnel from "../modules/stay_home/Funnel"
import OrderFinishFunnel from "../modules/order_finish/Funnel"
import * as eTradeReducer from "../redux/eTradeReducer";


const Navigation = (props) => {
    const dispatch = useDispatch();
    const {_userToken} = useSelector(({main}) => ({
        _userToken: main.userToken,
    }))
    const evSteps = useEvSteps();
    const ofSteps = useOfSteps();
    const rmsSteps = useRmsSteps();
    const shSteps = useShSteps();
    const eTradeSteps = eTradeHooks.useSteps()
    const stayHomeSteps = stayHomeHooks.useSteps()
    const orderFinishSteps = orderFinishHooks.useSteps()
    const [timeoutModalVisible, setTimeoutModalVisible] = useState(false);


    const handleClearStore = () => {
        dispatch(mainReducer.clearStore())
        dispatch(evReducer.clearStore())
        dispatch(rmsReducer.clearStore())
        dispatch(ofReducer.clearStore())
        dispatch(stayHomeReducer.clearStore())
        dispatch(tpsReducer.clearStore())
        dispatch(eTradeReducer.clearStore())


        setTimeoutModalVisible(true);
        setTimeout(() => {
            window.location.reload()
        }, 3000)
    }


    if (_userToken) {
        if (_userToken !== Axios.defaults.headers.get['Authorization']) {
            Axios.defaults.headers.get['Authorization'] = `Bearer ${_userToken}`;
            Axios.defaults.headers.post['Authorization'] = `Bearer ${_userToken}`;
        }
    } else {
        Axios.defaults.headers.get['Authorization'] = '';
        Axios.defaults.headers.post['Authorization'] = '';
    }

    return (
        <>
            <Router>
                <Switch>

                    {/*{!!_order && (*/}
                    <Route exact path={Routes.ORDER_FINALIZED} component={OrderFinalizedContainer}/>
                    {/*)}*/}
                    <Route exact path={Routes.UPLOAD_PROOF_OF_PURCHASE} component={UploadPopContainer}/>

                    <Route exact path={Routes.TPS} component={TpsFunnel}/>

                    <Route exact path={Routes.CLEAR_STORAGE} component={ClearStorageContainer}/>
                    <Route exact path={Routes.MAINTENANCE} component={MaintenanceContainer}/>
                    <Route exact path={Routes.PAYMENT_REJECTED} component={PaymentRejectedContainer}/>

                    {/*<Route exact path={Routes.SHOP} component={IndexContainer}/>*/}

                    {/*<Route*/}
                    {/*    exact path={[*/}
                    {/*    Routes.FINISH_ORDER,*/}
                    {/*    Routes.FINISH_ORDER_TOKEN,*/}
                    {/*    ...ofSteps.map(s => s.route)*/}
                    {/*]}*/}
                    {/*    component={OrderFinishFunnel}*/}
                    {/*/>*/}

                    <Route
                        exact path={[
                        ...orderFinishSteps.map(s => s.route),
                        Routes.ORDER_FINISH_INVALID_TOKEN,
                        Routes.ORDER_FINISH_TOKEN_EXPIRED,
                        Routes.ORDER_FINISH_ORDER_FINALIZED,
                        Routes.FINISH_ORDER,
                        Routes.ORDER_FINISH,
                    ]}
                        component={OrderFinishFunnel}
                    />

                    <Route
                        exact path={[
                        Routes.SHOP,
                        ...evSteps.map(s => s.route)
                    ]}
                        component={EVFunnelContainer}
                    />

                    {/*<Route*/}
                    {/*    exact path={[*/}
                    {/*    Routes.STAY_HOME,*/}
                    {/*    ...shSteps.map(s => s.route)*/}
                    {/*]}*/}
                    {/*    component={SHFunnel}*/}
                    {/*/>*/}

                    <Route
                        exact path={[
                        ...eTradeSteps.map(s => s.route),
                        Routes.ETRADE_INVALID_TOKEN,
                        Routes.ETRADE_TOKEN_EXPIRED,
                        Routes.ETRADE_ORDER_FINALIZED,
                        Routes.ETRADE,
                    ]}
                        component={ETradeFunnel}
                    />

                    <Route
                        exact path={[
                        ...stayHomeSteps.map(s => s.route),
                        Routes.STAY_HOME_ORDER_FINALIZED,
                        Routes.STAY_HOME_TOKEN_EXPIRED,
                        Routes.STAY_HOME,
                    ]}
                        component={StayHomeFunnel}
                    />

                    <Route
                        exact path={[
                        Routes.RESELLER,
                        Routes.RESELLER_AUTH,
                        Routes.RESELLER_FORGOT_PASSWORD,
                        Routes.RESELLER_FORGOT_PASSWORD_SET_NEW,
                        Routes.RESELLER_MACHINE_SUBSCRIPTIONS,
                        Routes.RESELLER_ASSISTED_REGISTRATION,
                        Routes.RESELLER_ADMIN_REPORTS,
                        Routes.RESELLER_REPORTS,
                        Routes.RESELLER_REPORTS_NEW,
                        ...rmsSteps.map(s => s.route),
                    ]}
                        component={ResellerFunnelContainer}
                    />

                    <Route
                        exact path={[
                            Routes.RESELLER_USERS,
                            Routes.RESELLER_USERS_USER,
                    ]}
                        component={ResellerFunnel}
                    />

                    <Route
                        exact path={[
                        Routes.AUTH_RESELLER_REGISTRATION,
                        Routes.AUTH_RESELLER_VERIFICATION,
                    ]}
                        component={AuthFunnel}
                    />

                    <Route path={Routes.LANG_PARAM}>
                        <Error404Screen/>
                    </Route>

                </Switch>
            </Router>


            {Env.ENV !== 'prod' && (
                <>
                    <div style={{position: 'fixed', bottom: 0, right: 0, zIndex: 3000}}>
                        <button onClick={handleClearStore}>Clear data</button>
                    </div>

                    <BaseModal visible={timeoutModalVisible} onClose={() => undefined}>
                        <div style={{textAlign: 'center', paddingTop: '4rem', paddingBottom: '4rem'}}>
                            <h1 className="par-1">Page will be reloaded in 3 seconds.</h1>
                            <h3 className="par-2">This modal is not visible in production.</h3>
                        </div>
                    </BaseModal>
                </>
            )}
        </>
    );
}


export default Navigation;
