import AuthorizationScreen from "../screens/AuthorizationScreen";
import * as eTradeReducer from "../../../redux/eTradeReducer";
import * as authorizationActions from "../../../actions/AuthorizationActions";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "../../../misc/Hooks";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {useStepsNavigation} from "../../../hooks/modules/etrade";
import {GTM} from "../../../misc/_index";


let MOUNTED = false

export default function AuthorizationContainer(props) {
    const stepsNavigation = useStepsNavigation()
    const dispatch = useDispatch();
    const store = useSelector(({eTrade}) => ({
        nespressoMember: eTrade.nespressoMember,
        customer: eTrade.customer,
    }))
    const t = useTranslation();
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        MOUNTED = true;
        document.title = t('etrade.authorization.title');
        GTM.showPage(t('etrade.authorization.title'))

        return () => {
            MOUNTED = false;
        }
    }, [])


    const handleSelect = (data) => {
        dispatch(eTradeReducer.setNespressoMember(data));
        if(data === false) {
            dispatch(eTradeReducer.setCustomer(null))
            history.push(stepsNavigation.next());
        }
    }


    const handleSubmit = (data) => {
        setLoading(true);
        authorizationActions.signIn(data).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                if(res.data?.has_subscription) {
                    setError('customer_already_has_subscription');
                } else {
                    dispatch(eTradeReducer.setNespressoMember({
                        recaptchaToken: data.recaptchaToken,
                        email: data.login,
                        ...res.data,
                    }));
                    history.push(stepsNavigation.next());
                }
            }
            else {
                if(res.messages?.length) {
                    if(MOUNTED) {
                        setError(res.messages[0]);
                    }
                }
                console.warn('Something gone wrong! ', res);
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if(MOUNTED) {
                setError(null);
                setLoading(false);
            }
        })
    }


    return (
        <AuthorizationScreen
            nespressoMember={store.nespressoMember}
            error={error}
            loading={loading}
            onSelect={handleSelect}
            onSubmit={handleSubmit}
        />
    )
}
