import React, {useState} from 'react';
import {Button, PlusMinusButton} from "./buttons/_index";
import {CoffeeItemModal} from "./modals/_index";
import {useCart, usePriceFormatter, useTranslation} from "../misc/Hooks";
import {getImage} from "../misc/Helpers";
import {Config} from "../utils/_index";
import PlanItem from "./PlanItem";
import {EasyOrderGiftItem, PackageItem} from "./_index";


interface Props {
    item: any,
    cart: any,
    onChange: void,
}


const CoffeeItem = (props: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const {formatPrice, formatPriceObj, checkPromoPrice} = usePriceFormatter();
    const {updateItem, isItemLocked} = useCart();
    const t = useTranslation();

    const itemInCart = props.cart?.items.find(i => i.id === props.item.id_product);


    let intensityBox = [];
    for (let i = 0; i < 13; i++) {
        let active = i < parseInt(props.item.product_intensity) ? '-active' : '';
        intensityBox.push(<span key={i} className={active}/>);
    }


    if(props.item.product_type === Config.COFFEE_TYPE.PLAN_COFFEE) {
        return <PlanItem {...props}/>
    }

    if(props.item.product_type === Config.COFFEE_TYPE.PACKAGE) {
        return <PackageItem {...props}/>
    }

    if(props.item.product_type === Config.COFFEE_TYPE.EASY_ORDER_GIFT) {
        return <EasyOrderGiftItem {...props}/>
    }

    const hasPromoPrice = checkPromoPrice(
        parseInt(props.item.product_price_promo?.status),
        props.item.product_price_promo?.date_from,
        props.item.product_price_promo?.date_to,
    );

    return (
        <div className="coffeeItem">
            <div className="coffeeItem__header">
                <div className="coffeeItem__thumb">
                    <img src={getImage(props.item.product_gallery)} alt=""/>
                </div>
                <h3 className="coffeeItem__title">{props.item.product_title}</h3>
            </div>

            <div className="coffeeItem__body">
                {!!props.item.product_lead && (
                    <p className="coffeeItem__subtitle">{props.item.product_lead}</p>
                )}

                <div className="coffeeItem__price">
                    {!hasPromoPrice ? (
                        <span>{formatPriceObj(props.item.product_price)}</span>
                    ) : (
                        <>
                            <small><s className="--textDark" style={{marginRight: '6px'}}>{formatPriceObj(props.item.product_price)}</s></small>
                            <s>{formatPriceObj(props.item.product_price_promo)}</s>
                        </>
                    )}
                </div>

                <div className="coffeeItem__link">
                    <button className="link -primary" onClick={() => setModalVisible(true)}>{t('read_more')}</button>
                </div>

                {props.item.product_type === Config.COFFEE_TYPE.CAPSULE && !!props.item.product_intensity ? (
                    <div className="coffeeItem__intensity">
                        <div className="coffeeItem__intensityBox">
                            {intensityBox.map(item => item)}
                        </div>
                        <span className="coffeeItem__intensityText">{t('coffee.intensity')} {props.item.product_intensity}</span>
                    </div>
                ) : <div className="--mb1"/>}

                <div className="coffeeItem__button">
                    {itemInCart ? (
                        <PlusMinusButton
                            type="primary"
                            size="sm"
                            plusDisabled={itemInCart.value >= props.item.product_max_qty || isItemLocked(props.item.id_product)}
                            value={itemInCart.value * props.item.product_package_qty}
                            step={props.item.product_step}
                            packageQty={props.item.product_package_qty}
                            max={props.item.product_max_qty ?? undefined}
                            onClickMinus={() => updateItem(props.item.id_product, (itemInCart?.value ?? 0) - props.item.product_step)}
                            onClickPlus={() => updateItem(props.item.id_product, (itemInCart?.value ?? 0) + props.item.product_step)}
                            onChange={v => updateItem(props.item.id_product, v)}
                        />
                    ) : (
                        <Button
                            type="primary"
                            size="sm"
                            label={t('buy_now')}
                            disabled={isItemLocked(props.item.id_product)}
                            onClick={() => updateItem(props.item.id_product, props.item.product_step)}
                        />
                    )}

                </div>
            </div>



            {!!modalVisible && (
                <CoffeeItemModal
                    visible={modalVisible}
                    item={props.item}
                    itemInCart={itemInCart}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    );
}


export default React.memo(CoffeeItem);
