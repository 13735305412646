import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {GTM} from "../../misc/_index";
import CustomerInformationStep from "../../components/ev/CustomerInformationStep";
import * as evReducer from "../../redux/evReducer";
import * as authActions from '../../actions/AuthorizationActions';
import {useEvStepNavigation, useTranslation} from "../../misc/Hooks";
import * as OrderService from "../../services/OrderService";
import {useLinkAccount, useOrderData, useStateObject} from "../../hooks";


let MOUNTED = false;

export const CustomerInformationStepContainer = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const store = useSelector(({main, ev}) => ({
        country: main.country,
        user: main.user,
        customer: ev.customer,
        isNewMember: ev.isNewMember,
        nespressoMember: ev.nespressoMember,
        nespressoMemberExist: ev.nespressoMemberExist,
        promotion: ev.promotion,
        canRegisterResponse: ev.canRegisterResponse,
        addresses: ev.addresses,
        orderAccount: ev.orderAccount,
        orderAccountLinked: ev.orderAccountLinked,
    }));
    const t = useTranslation();
    const stepNavigation = useEvStepNavigation();
    const linkAccountAction = useLinkAccount()
    const [loginFormVisible, setLoginFormVisible] = useState(store.canRegisterResponse?.status === 'ACCOUNT_EXISTS');
    const [loadingAddresses, setLoadingAddresses] = useState(false);
    const orderData = useOrderData('ev')
    const [state, setState] = useStateObject({
        loading: false,
        error: null,
        errorLog: null,
    })

    useEffect(async () => {
        if (store.isNewMember && store.orderAccount && !store.orderAccountLinked) {
            linkAccount(orderData.getCustomer(), store.orderAccount)
        }
    }, [store.orderAccountLinked])

    useEffect(() => {
        MOUNTED = true;
        document.title = t('ev_customer_information.title');
        GTM.showPage(t('ev_customer_information.title'))

        if (store.nespressoMember) {
            const data = {
                token: store.nespressoMember.token,
            };
            getClientAddresses(data);
        }

        return () => {
            MOUNTED = false;
        }
    }, [])

    const createAccount = async (data) => {
        const customerData = orderData.getCustomer(data)

        let account = store.nespressoMember ? {
            jwtToken: store.nespressoMember.token,
            memberNumber: store.nespressoMember.member_id,
        } : store.orderAccount
        let linked = store.nespressoMember ? true : store.orderAccountLinked

        if (!account) {
            const res = await OrderService.createAccount(customerData, data.recaptchaToken)
                .catch(async err => {
                    setState({error: 'cannot_create_account', errorLog: JSON.stringify(err.data)})
                })

            if (res) {
                account = res.account
                linked = res.linked
                dispatch(evReducer.setOrderAccount(account, linked))
            }
        }

        if (account && !linked) {
            linked = !!(await linkAccount(customerData, account))
        }

        if (account && linked) {
            dispatch(evReducer.setNespressoMember({
                token: account.jwtToken,
                member_id: account.member_number,
            }))
            return account
        }

        return null
    }

    const linkAccount = async (data, account) => {
        const payload = {
            customer: data,
            member_token: account.jwtToken,
        }
        const linkedAccount = await linkAccountAction(payload, account.res.memberNumber)
        if (linkedAccount) {
            dispatch(evReducer.setOrderAccount(linkedAccount, true))
            return linkedAccount
        }
        dispatch(evReducer.setOrderAccount(account, true))
        return account
    }

    const canRegister = (data) => {
        const formData = {
            ...data,
            recaptcha_token: data.recaptchaToken,
        }

        const params = {id_promotion: store.promotion?.id_promotion ?? 0}

        setLoginFormVisible(false);
        setState({loading: true, error: null, errorLog: null})
        authActions.canRegister(formData, params)
            .then(async r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (MOUNTED) {
                        dispatch(evReducer.setCanRegisterResponse(res.data))
                        switch (res.data.status) {
                            case 'ACCOUNT_EXISTS':
                                if (res.data.memberNumber) {
                                    if (store.user) {
                                        dispatch(evReducer.setCustomer(data));
                                        history.push(stepNavigation.next());
                                    } else {
                                        // history.push(navigation(Routes.SHOP_CUSTOMER_IDENTIFICATION));
                                        setLoginFormVisible(true);
                                        // dispatch(evReducer.setNespressoMember(null));
                                        // dispatch(evReducer.setPromotion(null));
                                    }
                                } else {
                                    dispatch(evReducer.setCustomer(null))
                                    setState({error: 'member_exists_error'})
                                }
                                break;
                            case 'MEMBER_EXISTS':
                                dispatch(evReducer.setCustomer(data));
                                history.push(stepNavigation.next());
                                break;
                            case 'ALLOWED':
                                dispatch(evReducer.setCustomer(data));
                                if (store.user) {
                                    history.push(stepNavigation.next());
                                } else {
                                    const account = await createAccount(data)
                                    if (account) {
                                        history.push(stepNavigation.next());
                                    }
                                }
                                break;
                            case 'NOT_ALLOWED':
                            default:
                                dispatch(evReducer.setCustomer(null))
                                setState({error: 'cannot_create_account'})
                                break;
                        }
                    }
                } else {
                    if (['phone_to_many_times_used'].includes(res?.messages[0])) {
                        setState({error: res?.messages[0]})
                    } else if (store.user) {
                        dispatch(evReducer.setCustomer(data));
                        dispatch(evReducer.setNespressoMemberExist(true))
                        history.push(stepNavigation.next());
                    } else if (res.data?.json?.length) {
                        if (res.data.json.reverse()[0]?.errorReason === 'NOT_ALLOWED') {
                            setState({error: 'email_was_already_registered'})

                        } else {
                            setState({error: res.data.json.reverse()[0].message})
                        }
                    } else {
                        setState({error: (res?.messages[0] ?? res?.data?.message) || 'unknown_error', errorLog: res})
                    }
                }
            })
            .catch(err => {
                setState({error: 'unknown_error', errorLog: err})
            })
            .finally(() => {
                if (MOUNTED) {
                    setState({loading: false})
                }
            })
    }

    const canAttempt = (data) => {
        const params = {id_promotion: store.promotion?.id_promotion ?? 0}
        const email = store.nespressoMember?.email ?? store.customer.email

        setState({loading: true, error: null, errorLog: null})
        authActions.canAttempt(data, params)
            .then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (MOUNTED) {
                        data.email = email;
                        dispatch(evReducer.setCustomer(data));
                        history.push(stepNavigation.next());
                    }
                } else {
                    if (['phone_to_many_times_used'].includes(res?.messages[0])) {
                        setState({error: res?.messages[0]})
                    } else {
                        setState({error: (res?.messages[0] ?? res?.data?.message) || 'unknown_error', errorLog: res})
                    }
                }
            })
            .catch(err => {
                setState({error: 'unknown_error', errorLog: err})
            })
            .finally(() => {
                if (MOUNTED) {
                    setState({loading: false})
                }
            })
    }

    const getClientAddresses = (memberId) => {
        setLoadingAddresses(true);
        authActions.getAddresses(memberId).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    if (store.nespressoMember && store.isNewMember && !store.customer.memberAddress && res.data.length) {
                        const address = res.data.reverse()[0]
                        if (address) {
                            dispatch(evReducer.setCustomer({
                                ...store.customer,
                                memberAddress: {
                                    label: `${address.address} ${address.address2}, ${address.postcode} ${address.city}`,
                                    value: address.id,
                                },
                            }))
                        }
                    }
                    dispatch(evReducer.setAddresses(res.data))
                }
            } else {
                console.warn('Something gone wrong! ', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if (MOUNTED) {
                setLoadingAddresses(false);
            }
        })
    }

    const handleSubmit = async (formData) => {
        const data = orderData.getCustomer(formData)

        if (store.orderAccount && !store.orderAccountLinked) {
            setState({loading: true})
            const account = await createAccount(data)
            if (account) {
                history.push(stepNavigation.next());
            } else {
                setState({loading: false})
            }
        } else if (!store.nespressoMember) {
            canRegister(formData)
        } else {
            canAttempt(formData)
        }
    }


    return (
        <CustomerInformationStep
            promotion={store.promotion}
            country={store.country}
            addresses={store.addresses}
            customer={store.customer}
            nespressoMember={store.nespressoMember}
            nespressoMemberExist={store.nespressoMemberExist}
            loginFormVisible={loginFormVisible}
            user={store.user}
            error={state.error}
            loading={state.loading}
            loadingAddresses={loadingAddresses}
            onSubmit={handleSubmit}
        />
    );
}
