import React from 'react';
import {useTranslation} from "../../misc/Hooks";
import {Error404, Footer, Header} from "./_index";


interface Props {

}

export default (props: Props) => {
    const t = useTranslation();
    return (
        <div className="errorPage">
            <h1 className="par-1">404</h1>
            <h3 className="par-1">{t('page_not_found')}</h3>
        </div>
    );
}
