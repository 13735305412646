import React from 'react';
import {Button} from "../buttons/_index";
import {useRmsStepNavigation, useTranslation} from "../../misc/Hooks";
import {EmailForm} from "../forms/_index";


export default (props) => {
    const t = useTranslation();
    const stepNavigation = useRmsStepNavigation();
    return (
        <div>
            <div className="grid">
                <div>
                    <p className="par-1 --bold --mb3">{t('r_ms_identification.text')}</p>


                    <div className="evCID__buttons --mb4">
                        <Button
                            label={t('yes')}
                            type="grey"
                            active={props.nespressoMember !== undefined && props.nespressoMember === true}
                            onClick={() => props.onSelect(true)}
                        />
                        <Button
                            label={t('no')}
                            type="grey"
                            active={props.nespressoMember !== undefined && props.nespressoMember === false}
                            onClick={() => props.onSelect(false)}
                        />
                    </div>

                    {props.nespressoMember !== undefined && props.nespressoMember === true && (
                        <div className="grid">
                            <div>
                                <EmailForm
                                    data={props.customer}
                                    stepNavigation={stepNavigation}
                                    onSubmit={props.onSubmitEmail}
                                />
                            </div>
                            <div/>
                        </div>
                    )}



                    {/*{typeof props.nespressoMember !== undefined && (*/}
                    {/*    <div className="step__nav">*/}
                    {/*        <Link to={stepNavigation.prev()}>*/}
                    {/*            <Button*/}
                    {/*                type="primary-outline"*/}
                    {/*                label={t('back')}*/}
                    {/*            />*/}
                    {/*        </Link>*/}
                    {/*        <Link to={stepNavigation.next()}>*/}
                    {/*            <Button*/}
                    {/*                label={t('continue')}*/}
                    {/*            />*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
                <div/>
            </div>
        </div>
    )
}
