import React from 'react';
import {useCountryConfig, useMarketConfig, useTranslation} from "../../misc/Hooks";
import {getFileUrl} from "../../misc/Helpers";
import Env from "../../utils/Env";
import {useRouteMatch} from "react-router-dom";


interface Props {
    module?: string,
    simple?: boolean,
}


const Footer = (props: Props) => {
    const t = useTranslation();
    const marketConfig = useMarketConfig();
    const countryConfig = useCountryConfig();
    const match = useRouteMatch();


    const year = new Date().getFullYear();

    const files = marketConfig.files?.filter(f => f.lang === (match.params.lang ?? Env.DEFAULT_LANG)
        && f.type === 'privacy_policy');

    const contactUrl = countryConfig?.contact_url || t(`${props.module ? `${props.module}.` : ''}contact_us_url`);

    return (
        <footer className="footer">
            <div className="wrapper-bg">
                <div className="wrapper -xxl">
                    <div className="grid">
                        <div className="footer__text">&copy; Nespresso {year}</div>
                        <ul>
                            {!props.simple && files?.map((f, idx) => (
                                <li key={idx}>
                                    <a href={getFileUrl(f)} title={f.label} target="_blank">{f.name}</a>
                                </li>
                            ))}
                            {!!contactUrl && (
                                <li>
                                    <a href={contactUrl} target="_blank">{t(`${props.module ? `${props.module}.` : ''}contact_us`)}</a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>

        </footer>
    );
}


export default Footer;
