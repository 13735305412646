import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from "./buttons/_index";
import * as mainReducer from '../redux/mainReducer';
import * as rmsReducer from '../redux/rmsReducer';
import {Link, useHistory} from "react-router-dom";
import {Routes} from "../utils/_index";
import {useNavigation, useTranslation} from "../misc/Hooks";


interface Props {}

export default (props: Props) => {
    const t = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const store = useSelector(({main}) => ({
        user: main.user,
    }))
    const navigation = useNavigation();


    const handleClick = (e) => {
        e.preventDefault();
        dispatch(rmsReducer.clearStore());
        dispatch(mainReducer.clearUser());
        history.push(navigation(Routes.RESELLER_AUTH))
    }


    if(!store.user)
        return null;

    return (
        <>
            <Link to={navigation(Routes.RESELLER)}>
                <Button
                    size="sm"
                    type="grey"
                    label={t('reseller')}
                />
            </Link>
            <Button
                size="sm"
                type="grey"
                label={t('logout')}
                onClick={handleClick}
            />
        </>
    )
}
