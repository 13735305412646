import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PromoSelectionStep from "../../components/ev/PromoSelectionStep";
import * as promotionActions from '../../actions/PromotionActions';
import {useTranslation} from "../../misc/Hooks";
import * as evReducer from "../../redux/evReducer";
import {GTM} from "../../misc/_index";
import Axios from "../../utils/Axios";
import {useLanguage} from "../../hooks";


interface Props {
}


let MOUNTED = false;


export const PromoSelectionStepContainer = (props: Props) => {
    const history = useHistory();
    const t = useTranslation();
    const currentLanguage = useLanguage()
    const dispatch = useDispatch();
    const store = useSelector(({main, reseller, ev}) => ({
        user: main.user,
        country: main.country,
        shop: reseller.shop,
        serialNumber: ev.serialNumber,
        machine: ev.machine,
        proofOfPurchase: ev.proofOfPurchase,
        promoCode: ev.promoCode,
        nespressoMember: ev.nespressoMember,
        promotion: ev.promotion,
        isNewMember: ev.isNewMember,
    }))
    const [promotions, setPromotions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        if (props.index === props.currentStep) {
            getAll();
        }
    }, [props.currentStep])


    useEffect(() => {
        MOUNTED = true;
        document.title = t('ev_promo_selection.title');
        GTM.showPage(t('ev_promo_selection.title'))

        return () => {
            MOUNTED = false;
        }
    }, [])


    const getAll = () => {
        Axios.defaults.headers.common['Language'] = currentLanguage;

        const date = new Date(store.proofOfPurchase?.date);
        let purchaseDate = null;

        if(store.proofOfPurchase) {
            purchaseDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        }

        let shopId = store.promoCode ? store.promoCode.id_shop : store.proofOfPurchase?.retailerStore?.value ?? null;
        if(store.user) {
            shopId = store.shop
        }
        const promotionId = store.promoCode ? store.promoCode.id_promotion : 0;

        const hasAccount = store.nespressoMember !== false

        if(store.user) {
            promotionActions.getAllReseller(
                store.country.id_country,
                store.machine.id_machine,
                shopId,
                purchaseDate,
                hasAccount ? 1 : 0,
                promotionId,
            ).then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (MOUNTED) {
                        setPromotions(res.data.sort((a, b) => a.promotion_priority > b.promotion_priority ? -1 : 1));
                    }
                } else {
                    if (res.data) {
                        if (MOUNTED) {
                            setError(res.data);
                        }
                    }
                    console.warn('Something gone wrong! ', res);
                }
            }).catch(err => {
                console.error('Error: ', err);
            }).finally(() => {
                if (MOUNTED) {
                    setLoading(false);
                }
            })
        } else {
            const params = {}
            if(store.nespressoMember) {
                params['member_number'] = store.nespressoMember.member_id
            }

            promotionActions.getAll(
                store.country.id_country,
                store.machine.id_machine,
                shopId,
                purchaseDate,
                hasAccount ? 1 : 0,
                promotionId,
                params,
            ).then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (MOUNTED) {
                        setPromotions(res.data.sort((a, b) => a.promotion_priority > b.promotion_priority ? -1 : 1));
                    }
                } else {
                    if (res.data) {
                        if (MOUNTED) {
                            setError(res.data);
                        }
                    }
                    console.warn('Something gone wrong! ', res);
                }
            }).catch(err => {
                console.error('Error: ', err);
            }).finally(() => {
                if (MOUNTED) {
                    setLoading(false);
                }
            })
        }
    }


    const handleSelectPromo = (promo) => {
        dispatch(evReducer.setPromotion(promo))
    }


    return (
        <PromoSelectionStep
            loading={loading}
            nespressoMember={store.nespressoMember}
            promotion={store.promotion}
            promotions={promotions}
            error={error}
            onSelectPromo={handleSelectPromo}
        />
    );
}
