import {ForgotPasswordReseller} from "../../../components/reseller/forgot_password";
import {useEffect, useState} from "react";
import {useTranslation} from "../../../misc/Hooks";
import {
   ForgotPasswordEmailNotSentResellerModal,
   ForgotPasswordEmailSentResellerModal
} from "../../../components/modals/reseller";
import {useResellerActions} from "../../../actions/useResellerActions";


export default function ForgotPasswordResellerContainer(props) {
    const t = useTranslation();
    const resellerActions = useResellerActions();
    const [emailSent, setEmailSent] = useState({modalVisible: false, message: undefined})
    const [emailNotSent, setEmailNotSent] = useState({modalVisible: false, message: undefined})
    const [processing, setProcessing] = useState(false);


    useEffect(() => {
        document.title = t('forgot_password_reseller.seo_title')
    }, [])


    const handleSubmit = async (data) => {
        const formData = {
            email: data.email,
        }

        setProcessing(true);
        await sendData(formData)
            .then(() => setEmailSent({modalVisible: true}))
            .catch(error => {
                setEmailNotSent({modalVisible: false, message: t(`error.${error.message}`)})
            })
        setProcessing(false)
    }


    const sendData = async (data) => {
        return resellerActions.forgotPassword(data)
            .then(r => {
                const res = r.data;
                if(res.status === 'error') {
                    throw new Error(res.messages[0])
                }
            })
            .catch(error => {
                throw error ?? new Error('unknown_error')
            })
    }

    const handleCloseModal = () => {
        window.location.reload();
    }


    return (
       <div className="wrapper">
       <ForgotPasswordReseller
                processing={processing}
                emailNotSent={emailNotSent}
                onSubmit={handleSubmit}
            />

            <ForgotPasswordEmailSentResellerModal
                visible={emailSent.modalVisible}
                text={emailSent.message}
                onClose={handleCloseModal}
            />
            <ForgotPasswordEmailNotSentResellerModal
                visible={emailNotSent.modalVisible}
                text={emailNotSent.message}
                onClose={handleCloseModal}
            />
       </div>
    )
}
