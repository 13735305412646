import {useTranslation} from "../../../misc/Hooks";
import React, {useState} from "react";
import {PlanSelectionModal} from "./index";


export default function PlanSelectionQuestion(props) {
    const t = useTranslation()
    const [modalVisible, setModalVisible] = useState(false)

    const openModal = () => setModalVisible(true)
    const closeModal = () => setModalVisible(false)

    const ButtonComponent = () => {
        const label = t('stay_home.plan_selection.question_button')
        if(!label) return null
        return (
            <a
                className="link -primary -parentSize"
                onClick={openModal}
                dangerouslySetInnerHTML={{__html: label}}
            />
        )
    }

    return (
        <>
            <p className="par-1 --semiBold">
                {t('stay_home.plan_selection.question_start')}
                <ButtonComponent/>
                {t('stay_home.plan_selection.question_end')}
            </p>

            <PlanSelectionModal visible={modalVisible} onClose={closeModal}/>
        </>
    )
}
