import {useLocation, useRouteMatch} from "react-router-dom";
import {useNavigation} from "../../../misc/Hooks";
import useSteps from "./useSteps";


export default function useStepsNavigation() {
    const navigation = useNavigation();
    const steps = useSteps();
    const match = useRouteMatch();
    const location = useLocation();

    const arr = location.pathname.split('/');
    const route = arr[arr.length - 1] ?? '';
    const currentStepIndex = steps.findIndex(s => s.route === match.path);

    const prev = () => {
        const prev = steps[currentStepIndex - 1];
        if (prev) {
            return navigation(prev.route);
        }
        return navigation(steps[currentStepIndex].route);
    }

    const next = () => {
        const next = steps[currentStepIndex + 1];
        if (next) {
            return navigation(next.route);
        }
        return navigation(steps[currentStepIndex].route);
    }


    return {
        prev,
        next,
    }
}
