import React from 'react';


interface Props {
    btnType?: string,
    positionClass: string,
    style: any,
    disabled: boolean,
    onClick: void,
}


export default (props: Props) => {
    return (
        <button
            type={props.btnType ?? 'button'}
            className={`btnClose ${props.positionClass}`}
            style={props.style}
            disabled={props.disabled} onClick={props.onClick}
        >
            <img src={require('../../assets/img/icons/close.svg').default} alt="x" />
        </button>
    )
}
