import React, {useEffect} from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyInput} from "./inputs/_index";
import {Button} from "../buttons/_index";
import {useNavigation, useTranslation} from "../../misc/Hooks";
import {Link} from "react-router-dom";
import {Routes} from "../../utils/_index";


export default (props) => {
    const t = useTranslation();
    const navigation = useNavigation();
    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            login: '',
            password: '',
        }
    });
    const login = formMethods.watch('login');
    const password = formMethods.watch('password');


    const rules = {
        login: {
            required: {
                value: true,
                message: t('validation.required'),
            },
        },
        password: {
            required: {
                value: true,
                message: t('validation.required'),
            }
        },
    }


    useEffect(() => {
        if(props.error) {
            formMethods.setError('login', {
                type: 'incorrect',
                message: t(`validation.${props.error}`)
            })
            formMethods.setError('password', {
                type: 'incorrect',
                message: t(`validation.${props.error}`)
            })
        }
    }, [props.error])


    const onSubmit = (data) => {
        props.onSubmit(data);
    }


    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>

                <p className="par-2">{props.text ?? t('ev_customer_identification.sign_in')}</p>
                <Controller
                    name="login"
                    control={formMethods.control}
                    rules={rules.login}
                    render={({onChange, value}) => (
                        <MyInput
                            name="login"
                            label={t('login')}
                            value={value}
                            error={formMethods.errors.login}
                            onChange={onChange}
                        />
                    )}
                />

                <Controller
                    name="password"
                    control={formMethods.control}
                    rules={rules.password}
                    render={({onChange, value}) => (
                        <MyInput
                            name="password"
                            type="password"
                            label={t('password')}
                            value={value}
                            error={formMethods.errors.password}
                            onChange={onChange}
                        />
                    )}
                />

                <div className="--mb2" style={{textAlign: 'right'}}>
                    <Link to={navigation(Routes.RESELLER_FORGOT_PASSWORD)}>
                        <Button
                            type="grey"
                            size="sm"
                            label={t('forgot_your_password')}
                        />
                    </Link>
                </div>


                <Button
                    btnType="submit"
                    type="primary"
                    label={props.submitText ?? t('continue')}
                    disabled={!login || !password}
                    onClick={formMethods.handleSubmit(onSubmit)}
                />
            </form>
        </FormProvider>
    );
}
