import React, {useState} from 'react';
import BaseModal from "./BaseModal";
import {Button, PlusMinusButton} from "../buttons/_index";
import {getImage} from "../../misc/Helpers";
import {useCart, usePriceFormatter, useTranslation} from "../../misc/Hooks";
import {Config} from "../../utils/_index";
import {useSelector} from "react-redux";
import {Accordion} from "../misc/_index";
import {GetInTouchModal} from "./_index";


interface Props {
    visible: boolean,
    item: any,
    itemInCart: any,
    onClose: void,
}


const EasyOrderGiftItemModal = (props: Props) => {
    const {formatPrice, formatPriceObj, checkPromoPrice} = usePriceFormatter();
    const {updateItem, isItemLocked} = useCart();
    const t = useTranslation();
    const store = useSelector(({main, ev}) => ({
        coffeeSizes: main.coffeeSizes,
        products: ev.products,
    }))
    const [gitModalVisible, setGitModalVisible] = useState(false);


    const renderSize = (s, idx) => {
        const size = store.coffeeSizes?.find(i => i.stack_value === s);

        if(!size) {
            return null;
        }

        if(s.match(/_image$/)) {
            return  (
                <div key={idx} className="coffeeModal__imageSize">
                    <img src={getImage(size.stack_gallery)} alt={size.stack_title}/>
                    <p className="coffeeModal__size" dangerouslySetInnerHTML={{__html: size.stack_title}}/>
                </div>
            )
        }
        return <span key={idx} className="coffeeModal__size" dangerouslySetInnerHTML={{__html: size.stack_title}}/>;
    }


    const hasPromoPrice = checkPromoPrice(
        parseInt(props.item.product_price_promo?.status),
        props.item.product_price_promo?.date_from,
        props.item.product_price_promo?.date_to,
    );


    let intensityBox = [];
    for (let i = 0; i < 13; i++) {
        let active = i < parseInt(props.item.product_intensity) ? '-active' : '';
        intensityBox.push(<span key={i} className={active}/>);
    }


    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="coffeeModal">
                <div>
                    <div className="coffeeModal__thumb --mb3">
                        <img src={getImage(props.item.product_gallery)} alt={props.item.product_title}/>
                    </div>
                    {props.item.product_type === Config.COFFEE_TYPE.CAPSULE && (
                        <>
                            <div className="coffeeModal__intensity --mb3">
                                {!!parseInt(props.item.product_intensity) && (
                                    <div className="coffeeItem__intensity">
                                        <div className="coffeeItem__intensityBox">
                                            {intensityBox.map(item => item)}
                                        </div>
                                        <span className="coffeeItem__intensityText">{t('coffee.intensity')} {props.item.product_intensity}</span>
                                    </div>
                                )}
                            </div>
                            {!!props.item.product_cup_size?.length && (
                                <div className="--mb3">
                                    <h5 className="par-2 --alignCenter --mb2_5">{t('coffee.cup_sizes')}</h5>
                                    <div className="coffeeModal__sizes">
                                        {props.item.product_cup_size.map(renderSize)}
                                    </div>
                                </div>
                            )}
                            {!!props.item.product_similar?.length && (
                                <div className="coffeeModal__similar">
                                    <h5 className="par-2 --alignCenter --mb3">{t('coffee.similar_coffees')}</h5>
                                    <div className="similar">
                                        {props.item.product_similar.map(sId => {
                                            const s = store.products.find(p => p.id_product === parseInt(sId));
                                            return !!s && (
                                                <div key={sId} className="similar__item">
                                                    <div className="similar__thumb">
                                                        <img src={getImage(s.product_gallery)} alt={s.product_title}/>
                                                    </div>
                                                    <h6 className="similar__title">{s.product_title}</h6>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div>
                    <h3 className="coffeeModal__title">{props.item.product_title}</h3>
                    <div className="coffeeModal__lead par-5" dangerouslySetInnerHTML={{__html: props.item.product_lead}}/>

                    <div
                        className="coffeeModal__description par-5 --mb4"
                        dangerouslySetInnerHTML={{__html: props.item.product_description}}
                    />

                    <div className="par-4">
                        <b>{t('need_help')} </b>
                        <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                            <b>{t('get_in_touch')}</b>
                        </a>
                    </div>
                </div>
            </div>


            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />
        </BaseModal>
    );
}


export default EasyOrderGiftItemModal;
