import {useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {useEffect, useState} from "react"
import {mapStackToOptions} from "../../../misc/Helpers"
import * as authActions from "../../../actions/AuthorizationActions"
import * as authorizationActions from "../../../actions/AuthorizationActions"
import * as ofReducer from "../../../redux/ofReducer"
import CustomerScreen from "../screens/CustomerScreen"
import {useStepsNavigation} from "../../../hooks/modules/order_finish"
import {GTM} from "../../../misc/_index";
import {useTranslation} from "../../../misc/Hooks";
import {useOrderData} from "../../../hooks";
import * as evReducer from "../../../redux/evReducer";

let MOUNTED = false

export default function CustomerContainer(props) {
    const history = useHistory()
    const dispatch = useDispatch()
    const t = useTranslation()
    const store = useSelector(({main, of}) => ({
        country: main.country,
        user: main.user,
        civilities: main.civilities,
        customer: of.customer,
        nespressoMember: of.nespressoMember,
        nespressoMemberExist: of.nespressoMemberExist,
        promotion: of.promotion,
        addresses: of.addresses,
        finishOrder: of.finishOrder,
    }))
    const plan = useSelector(({of}) => {
        return of.products?.find(p => {
            const item = of.cart?.items?.find(i => i.id === p.id_product)
            return item && p.product_type.startsWith('plan_')
        })
    })
    const stepsNavigation = useStepsNavigation()
    const [error, setError] = useState(null)
    const [signInError, setSignInError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingAddresses, setLoadingAddresses] = useState(false)
    const civilityOptions = mapStackToOptions(store.civilities).sort((a, b) => Number(a.value) < Number(b.value) ? -1 : 1)

    useEffect(() => {
        MOUNTED = true

        document.title = t('finish_order_customer.title')
        GTM.showPage(t('finish_order_customer.title'))

        if (store.nespressoMember) {
            const data = {
                token: store.nespressoMember.token,
            }
            getClientAddresses(data)
        }

        return () => {
            MOUNTED = false
        }
    }, [])


    const canAttempt = (data) => {
        const params = {}
        const email = store.nespressoMember?.email ?? store.customer.email

        if(plan) {
            params['id_plan_machine'] = plan?.id_product ?? 0
        } else {
            params['id_promotion'] = store.finishOrder?.id_promotion ?? 0
        }

        setLoading(true)
        authActions.canAttempt(data, params)
            .then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (MOUNTED) {
                        data.email = email;
                        dispatch(ofReducer.setCustomer(data));
                        history.push(stepsNavigation.next());
                    }
                } else {
                    if (['phone_to_many_times_used'].includes(res?.messages[0])) {
                        setError(res?.messages[0])
                    } else {
                        setError((res?.messages[0] ?? res?.data?.message) || 'unknown_error')
                    }
                }
            })
            .catch(err => {
                setError('unknown_error')
            })
            .finally(() => {
                if (MOUNTED) {
                    setLoading(false)
                }
            })
    }

    const getClientAddresses = (memberId) => {
        setLoadingAddresses(true)
        authActions.getAddresses(memberId).then(r => {
            const res = r.data
            if (res.status === 'success') {
                if (MOUNTED) {
                    if (store.nespressoMember && !store.finishOrder.webaccount_exist && !store.customer?.memberAddress && res.data.length) {
                        const address = res.data.reverse()[0]
                        const x = {
                            ...store.customer,
                            memberAddress: {
                                label: `${address.address} ${address.address2}, ${address.postcode} ${address.city}`,
                                value: address.id,
                            },
                        }
                        console.log(x)
                        if (address) {
                            dispatch(ofReducer.setCustomer(x))
                        }
                    }
                    dispatch(ofReducer.setAddresses(res.data))
                }
            } else {
                console.warn('Something gone wrong! ', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if (MOUNTED) {
                setLoadingAddresses(false)
            }
        })
    }


    const handleSubmit = (formData) => {
        let data = formData
        const address = store.addresses.find(a => a.id === formData.memberAddress?.value)
        if (address) {
            data = {
                ...formData,
                ...address,
                email: store.nespressoMember.email,
                address_id: address.id,
                phone: formData.phone,
                title: civilityOptions.find(o => Number(o.value) === Number(address.title))
            }
        }

        canAttempt(data)
    }


    const handleSubmitSignIn = (data) => {
        authorizationActions.signIn(data)
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    dispatch(ofReducer.setNespressoMember({
                        email: data.login,
                        ...res.data,
                    }))
                    dispatch(ofReducer.setCustomer(null))
                    setError(null)
                    getClientAddresses({token: res.data.token})
                } else {
                    if (res.messages?.length) {
                        if (MOUNTED) {
                            setSignInError(res.messages[0])
                        }
                    }
                    console.warn('Something gone wrong! ', res)
                }
            })
            .catch(err => {
                console.error('Error: ', err)
            })
            .finally(() => {
                if (MOUNTED) {
                    setSignInError(null)
                }
            })
    }

    return (
        <CustomerScreen
            plan={plan}
            promotion={store.promotion}
            country={store.country}
            addresses={store.addresses}
            customer={store.customer}
            nespressoMember={store.nespressoMember}
            nespressoMemberExist={store.nespressoMemberExist}
            loadingAddresses={loadingAddresses}
            user={store.user}
            error={error}
            signInError={signInError}
            loading={loading}
            onSubmit={handleSubmit}
            onSubmitSignIn={handleSubmitSignIn}
        />
    )
}