import {useTranslation} from "../../../misc/Hooks";
import {MyLink, Preloader} from "../../../misc/_index";
import {Link} from "react-router-dom";
import {Button} from "../../../components/buttons/_index";
import React from "react";
import {useStepsNavigation} from "../../../hooks/modules/stay_home";
import {PlanItem, PlanSelectionQuestion} from "../components";


export default function PlanSelection(props) {
    const t = useTranslation()
    const stepsNavigation = useStepsNavigation()


    return (
        <div className="shPS">

            <div className="--mb2">
                <PlanSelectionQuestion/>
            </div>


            <div className="evCID__buttons --mb4">
                <Button
                    label={t('yes')}
                    type="grey"
                    active={props.userHasPlan === true}
                    onClick={() => props.onChangeUserHasPlan(true)}
                />
                <Button
                    label={t('no')}
                    type="grey"
                    active={props.userHasPlan === false}
                    onClick={() => props.onChangeUserHasPlan(false)}
                />
            </div>


            {props.userHasPlan === true && (
                <p className="par-1 --semiBold --mb4 --error">{t('stay_home.plan_selection.user_has_plan')}</p>
            )}

            {props.userHasPlan === false && (
                props.loading ? (
                    <Preloader/>
                ) : props.plans.length ? (
                    <div className="evPS__items">
                        {props.plans.map(p => (
                            <PlanItem
                                key={p.id_product}
                                storeName="stayHome"
                                item={p}
                                cart={props.cart}
                                onChange={props.onChange}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="--mb4">
                        <p className="par-5 --bold" dangerouslySetInnerHTML={{__html: t('no_plans')}}/>
                    </div>
                )
            )}


            <div className="step__nav">
                <Link to={stepsNavigation.prev()}>
                    <Button
                        type="primary-outline"
                        label={t('back')}
                    />
                </Link>
                <MyLink route={stepsNavigation.next()} disabled={props.userHasPlan === false ? !props.plan : false}>
                    <Button
                        type="primary"
                        disabled={props.userHasPlan === false ? !props.plan : false}
                        label={t('continue')}
                    />
                </MyLink>
            </div>

        </div>
    )
}
