import React from 'react';
import {Link} from "react-router-dom";
import {Preloader} from "../../misc/_index";
import {useRmsStepNavigation, useTranslation} from "../../misc/Hooks";
import {PlanRmsItem} from "../_index";
import {Button} from "../buttons/_index";


export default (props) => {
    const t = useTranslation();
    const stepNavigation = useRmsStepNavigation();


    return (
        <div>

            {props.loading ? (
                <Preloader/>
            ) : props.plans.length ? (
                <div className="evPS__items">
                    {props.plans.map(p => (
                        <PlanRmsItem
                            key={p.id_product}
                            item={p}
                            cart={props.cart}
                        />
                    ))}
                </div>
            ) : (
                <div className="--mb4">
                    <p className="par-5 --bold" dangerouslySetInnerHTML={{__html: t('no_plans')}}/>
                </div>
            )}


            <div className="step__nav">
                <Link to={stepNavigation.prev()}>
                    <Button
                        type="primary-outline"
                        label={t('back')}
                    />
                </Link>
                <Link to={stepNavigation.next()}>
                    <Button
                        type="primary"
                        label={t('continue')}
                        disabled={!props.cart.items.length}
                    />
                </Link>
            </div>
        </div>
    )
}
