import React from 'react';
import BaseModal from "./BaseModal";
import {useTranslation} from "../../misc/Hooks";
import {getFileUrl, getImage} from "../../misc/Helpers";
import Env from "../../utils/Env";
import {useRouteMatch} from "react-router-dom";


interface Props {
    visible: boolean,
    item: any,
    onClose: void,
}

const PlanMachineItemModal = (props: Props) => {
    const t = useTranslation();
    const match = useRouteMatch();

    const coverImage = getImage(props.item.product_gallery);
    const files = props.item.product_files
        ?.filter(f => f.lang === (match.params.lang ?? Env.DEFAULT_LANG))
        ?.filter(f => Number(f.status) === 1) ?? [];


    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="coffeeModal">
                {!!coverImage && (
                    <div>
                        <div className="coffeeModal__thumb --mb3">
                            <img src={coverImage} alt={props.item.product_title}/>
                        </div>
                    </div>
                )}
                <div>
                    <h3 className="coffeeModal__title">{props.item.product_title}</h3>
                    {!!props.item.product_plan_description1 && (
                        <div className="coffeeModal__lead par-5" dangerouslySetInnerHTML={{__html: props.item.product_plan_description1}}/>
                    )}
                    <div className="coffeeModal__lead par-5" dangerouslySetInnerHTML={{__html: props.item.product_plan_description3}}/>
                    <div>
                        {files?.map((f, idx) => (
                            <div key={idx}>
                                <a href={getFileUrl(f)} title={f.label} target="_blank" className="link -primary">{f.name}</a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </BaseModal>
    );
}


export default PlanMachineItemModal;
