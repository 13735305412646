import {UserEditForm, UserEditModal} from "../components";
import {useTranslation} from "../../../../misc/Hooks";
import {Preloader} from "../../../../misc/_index";

export default function UserScreen(props) {
    const t = useTranslation();
    return (
       <>
          <div className="wrapper">
             <div className="resellerUsers">
                <div className="resHome">
                   <div className="resellerOptions">
                      <div className="resellerOptions__header">
                         <h2 className="--uppercase">{t('reseller_client.edit_user')}</h2>
                      </div>
                      {!props.loading ? (
                         <UserEditForm data={props.formData} onSubmit={props.onSubmitFrom} sending={props.sending}/>
                      ) : (
                         <Preloader/>
                      )}
                   </div>
                </div>
             </div>
          </div>

          <UserEditModal
            onClose={() => props.setSending((old) => ({
               ...old,
               modalVisible: false,
            }))}
            visible={props.sending.modalVisible}
            sending={props.sending}
          />
       </>
    )
}