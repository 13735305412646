import {useSelector} from "react-redux";
import {useLanguage} from "./index";
import {Config} from "../utils/_index";
import {getDate} from "../misc/Helpers";
import {useTranslation} from "../misc/Hooks";


export default function useOrderData(moduleName) {
    const t = useTranslation()
    const {module, ...store} = useSelector(state => ({
        main: state.main,
        reseller: state.reseller,
        module: state[moduleName],
    }))
    const currentLanguage = useLanguage()


    const getData = () => {
        let data = {
            source: 'ev',
            id_country: store.main.country.id_country,
            currency_prefix: store.main.country.country_currency_prefix,
            currency_suffix: store.main.country.country_currency_suffix,
            currency_name: store.main.country.country_currency_name,
            test_order: module.testOrder ? 1 : 0,
            uuid: module.uuid,
            filename_receipt: null,
            ...getPromotionData(),
            ...getPromoCodeData(),
            ...getMachineData(),
            ...getSerialNumberData(),
            ...getProofOfPurchaseData(),
            ...getCustomerData(),
            ...getCustomerBenefitsData(),
            ...getNespressoMemberData(),
            ...getOcrLogsData(),
            ...getCanRegisterResponseData(),
            ...getUserData(),
            ...getPaymentMethodData(),
        }

        if (store.main.user) {
            if (module.nespressoMember === null) {
                data.webaccount_exist = 1;
            }

            if (module.nespressoMemberExist) {
                data.webaccount_exist = 1;
            }

            if (!data.id_shop) {
                data.id_shop = store.reseller.shop ?? null;
            }
        }

        return data
    }

    const getCustomer = (customer) => {
        return {
            source: 'ev',
            id_country: store.main.country.id_country,
            currency_prefix: store.main.country.country_currency_prefix,
            currency_suffix: store.main.country.country_currency_suffix,
            currency_name: store.main.country.country_currency_name,
            test_order: module.testOrder ? 1 : 0,
            uuid: module.uuid,
            ...getCustomerData(customer),
            ...getCustomerBenefitsData(customer),
            ...getNespressoMemberData(),
            ...getCanRegisterResponseData(),
        }
    }

    const getPromotionData = (promotion = module.promotion) => {
        if (!promotion) return null
        const data = {
            id_promotion: promotion.id_promotion,
            promotion_type: promotion.promotion_type,
        }
        if (promotion.promotion_type === Config.PROMO_TYPE.EASY_ORDER && module.frequencies && module.easyOrderSetup) {
            data.easy_order_frequency = module.frequencies.find(i => i.id === module.easyOrderSetup.frequency?.value)
            data.easy_order_next_shipment = getDate(module.easyOrderSetup.nextShipment)
        }
        return data
    }

    const getPromoCodeData = (promoCode = module.promoCode) => {
        if (!promoCode) return null
        return {
            id_promo_code: promoCode.id_promo_code,
        }
    }

    const getMachineData = (machine = module.machine) => {
        if (!machine) return null
        return {
            id_machine: machine.id_machine,
            coffee_type: machine.machine_coffee_type,
        }
    }

    const getSerialNumberData = (serialNumber = module.serialNumber) => {
        if (!serialNumber) return null
        return {
            serial: serialNumber.serial_number ?? null,
            filename_machine: serialNumber.filename ?? null,
        }
    }

    const getProofOfPurchaseData = (proofOfPurchase = module.proofOfPurchase) => {
        if (!proofOfPurchase) return null
        let data = {
            id_shop: proofOfPurchase.retailerStore?.value ?? 0,
            invoice_date: getDate(proofOfPurchase.date),
            invoice_number: proofOfPurchase.invoiceNumber,
            invoice_price: proofOfPurchase.machinePrice ?? null,
        }
        if (module.proofOfPurchaseLogs) {
            data = {
                ...data,
                ocr_number: module.proofOfPurchaseLogs.data?.invoice,
                ocr_date: module.proofOfPurchaseLogs.data?.date,
                ocr_price: module.proofOfPurchaseLogs.data?.total,
                ocr_shop: module.proofOfPurchaseLogs.data?.id_shop,
                ocr_shop_group: module.proofOfPurchaseLogs.data?.id_shop_group,
                filename_receipt: module.proofOfPurchaseLogs.filename,
            }
        }
        return data
    }

    const getCustomerData = (customer = module.customer) => {
        if (!customer) return null

        const address = module.addresses?.find(a => a.id === customer.memberAddress?.value);
        if (address) {
            customer = {
                ...customer,
                ...address,
                email: customer.email ?? module.nespressoMember.email,
                phone: customer.phone,
            }

            const civility = module.civilities?.find(c => Number(c.stack_value) === Number(address.title));
            if (civility) {
                customer.title = {
                    label: civility.stack_title,
                    value: civility.stack_value,
                }
            } else {
                customer.title = {
                    label: t('unknown_civility'),
                    value: address.title,
                }
            }
        }

        return {
            lang: customer.lang?.value ?? currentLanguage,
            address_id: customer.memberAddress?.value ?? null,

            delivery_is_company: customer.companyAddress ? 1 : 0,
            delivery_company_name: customer.companyName ?? '',
            delivery_company_tax_id: customer.taxId ?? '',
            delivery_company_irs: customer.irs ?? '',
            delivery_company_profession: customer.profession?.value ?? null,
            delivery_civility: customer.title ? Number(customer.title.value) : 0,
            delivery_firstname: customer.name ?? '',
            delivery_lastname: customer.surname ?? '',
            delivery_zipcode: customer.postcode ?? '',
            delivery_city: customer.city ?? '',
            delivery_address1: customer.address ?? '',
            delivery_address2: customer.address2 ?? '',
            delivery_region: customer.region?.value ?? null,
            delivery_country: customer.country?.value ?? '',
            delivery_phone: customer.phone ?? '',
            delivery_phone_mobile: customer.phoneMobile ?? '',
            delivery_phone_home: customer.phoneHome ?? '',
            delivery_email: customer.email ?? '',

            other_delivery: customer.withCustomAddress ? 1 : 0,
            other_delivery_is_company: customer.customCompanyAddress ? 1 : 0,
            other_delivery_company_name: customer.customCompanyName ?? '',
            other_delivery_company_tax_id: customer.customTaxId ?? '',
            other_delivery_civility: customer.customTitle ? Number(customer.customTitle.value) : 0,
            other_delivery_firstname: customer.customName ?? '',
            other_delivery_lastname: customer.customSurname ?? '',
            other_delivery_zipcode: customer.customPostcode ?? '',
            other_delivery_city: customer.customCity ?? '',
            other_delivery_address1: customer.customAddress ?? '',
            other_delivery_address2: customer.customAddress2 ?? '',
            other_delivery_region: customer.customRegion?.value ?? null,
            other_delivery_country: customer.customCountry?.value ?? '',
            other_delivery_phone: customer.customPhone ?? '',
            other_delivery_phone_mobile: customer.customPhoneMobile ?? '',
            other_delivery_phone_home: customer.customPhoneHome ?? '',
            other_delivery_email: customer.customEmail ?? '',

            payment_bsb_number: customer.pdBsbNumber ?? null,
            payment_account_number: customer.pdAccountNumber ?? null,
            payment_data_confirmed: customer.pdDataConfirmed ? 1 : 0,

            regulations: customer.termsAndConditions ? 1 : 0,
            privacy_policy: customer.privacyPolicy ? 1 : 0,

            member_password: customer.password,

            webaccount_exist: module.isNewMember ? 0 : 1,
        }
    }

    const getNespressoMemberData = (nespressoMember = module.nespressoMember) => {
        if (!nespressoMember) return null
        return {
            member_number: nespressoMember.member_id,
            member_token: nespressoMember.token,
        }
    }

    const getCustomerBenefitsData = (customer = module.customer) => {
        const benefits = {
            marketing_email: store.nespressoMember?.consents?.contactByEmail ? 1 : 0,
            marketing_mobile: store.nespressoMember?.consents?.mobileNotification ? 1 : 0,
            marketing_phone: store.nespressoMember?.consents?.contactByPhone ? 1 : 0,
            marketing_post: store.nespressoMember?.consents?.contactByPost ? 1 : 0
        }
        if (customer) {
            if (customer.benefits?.email !== undefined)
                benefits.marketing_email = customer.benefits?.email ? 1 : 0
            if (customer.benefits?.mobile !== undefined)
                benefits.marketing_mobile = customer.benefits?.mobile ? 1 : 0
            if (customer.benefits?.phone !== undefined)
                benefits.marketing_phone = customer.benefits?.phone ? 1 : 0
            if (customer.benefits?.post !== undefined)
                benefits.marketing_post = customer.benefits?.post ? 1 : 0
        }
        return benefits
    }

    const getOcrLogsData = (ocrLogs = module.ocrLogs) => {
        if (!ocrLogs) return null
        return {
            ocr_log: JSON.stringify(ocrLogs),
        }
    }

    const getCanRegisterResponseData = (canRegisterResponse = module.canRegisterResponse) => {
        if (!canRegisterResponse) return null
        const data = {
            can_register_status: canRegisterResponse,
        }
        if (canRegisterResponse.status === 'MEMBER_EXISTS') {
            data.member_number = canRegisterResponse.memberNumber;
        }
        if (store.main.user && canRegisterResponse.status === 'ACCOUNT_EXISTS' && canRegisterResponse.memberNumber) {
            data.member_number = canRegisterResponse.memberNumber;
            data.webaccount_exist = 1;
        }
        return data
    }

    const getUserData = (user = store.user) => {
        if (!user) return null
        return {
            id_reseller: user.id_reseller,
            reseller_firstname: user.firstname ?? null,
            reseller_lastname: user.lastname ?? null,
        }
    }

    const getPaymentMethodData = (paymentMethod = module.paymentMethod) => {
        if (!paymentMethod) return null
        return {
            id_payment_method: paymentMethod.id_payment_method ?? null,
            payment_method_id_nestle: paymentMethod.id_nestle ?? '',
        }
    }

    const getCartQuotationData = (cartQuotationData = module.cartQuotationData) => {
        if (!cartQuotationData) return null
        return {
            quotation_finance: cartQuotationData.finance,
            quotation_total_amount: cartQuotationData.finance?.amount?.toPay,
            quotation_delivery_method: cartQuotationData.delivery?.deliveryMode?.id,
        }
    }

    const memberCreditCart = (cart = module.cart, products = module.products) => {
        if(!cart || !products) return []
        return cart.items.reduce((arr, item) => {
            const product = products.find(p => p.id_product === item.id);
            if(product) {
                arr.push({
                    item: product.product_code,
                    quantity: item.value * product.product_package_qty,
                })
            }
            return arr
        }, [])
    }

    return {
        getData,
        getCustomer,
        getCartQuotationData,
        memberCreditCart,
    }
}
