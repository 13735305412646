import {useParams} from "react-router-dom";
import useLanguages from "./useLanguages";


/**
 * Returns current or default site language
 * @returns {string}
 */
export default function useLanguage() {
  const {defaultLang} = useLanguages()
  const params = useParams()

  return params.lang ?? defaultLang
}
