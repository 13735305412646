import {Preloader} from "../../misc/_index";
import {CustomerInformationForm, SignInForm} from "../forms/_index";
import {useOfStepNavigation, useTranslation} from "../../misc/Hooks";


interface Props {
    promotion: any,
    addresses: [],
    customer: any,
    onSubmit: void,
}


export default (props: Props) => {
    const t = useTranslation();
    const stepNavigation = useOfStepNavigation();
    return (
        <div className="evCIN">


            <p className="par-1 --bold">{t('ev_customer_information.your_details')}</p>


            <div className="--mb4">
                <CustomerInformationForm
                    stepNavigation={stepNavigation}
                    user={props.user}
                    promotion={props.promotion}
                    plan={props.plan}
                    country={props.country}
                    nespressoMember={props.nespressoMember}
                    addresses={props.addresses}
                    error={props.error}
                    loading={props.loading}
                    customer={props.customer}
                    loadingAddresses={props.loadingAddresses}
                    onSubmit={props.onSubmit}
                />
            </div>

            {!!props.loading && (
                <div className="grid">
                    <Preloader/>
                    <div/>
                </div>
            )}

            {!!props.error && !props.nespressoMemberExist && (
                <div className="grid --mb4">
                    {props.error === 'email_in_use' ? (
                        <div>
                            <p className="par-2 --mb3 --error">{t(`error.${props.error}`)}</p>
                            <SignInForm onSubmit={props.onSubmitSignIn} error={props.signInError}/>
                        </div>
                    ) : (
                        <div>
                            <p className="par-2 --error">{t(`error.${props.error}`) || 'unknown error'}</p>
                        </div>
                    )}
                    <div/>
                </div>
            )}


        </div>
    );
}
