import {useRef} from "react";

export default function useMemberPhoneMask(formMethods){
    const memberPhoneRef = useRef()

    async function checkMemberPhone(addressId, addresses, customer){
        const address = addresses.find(a => a.id === addressId);
        let phone = address.phone ?? customer?.phone;

        if(!memberPhoneRef?.current)
            await formMethods.setValue('memberPhone', phone)


        let value = '';
        let emptySpaces = memberPhoneRef.current.value.split('_').length - 1;

        value = memberPhoneRef.current.value.replaceAll(' ', '');
        value = value.substr(0, value.length - emptySpaces)
        phone = phone.substr(-1 * emptySpaces);

        value += phone;

        await formMethods.setValue('memberPhone', value)

        if (memberPhoneRef?.current?.value)
            formMethods.setValue('memberPhone', memberPhoneRef.current.value)

        return null;
    }

    return {
        checkMemberPhone,
        ref: memberPhoneRef
    }

}