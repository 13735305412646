import React from 'react';
import {useTranslation} from "./Hooks";


interface Props {
    classes?: string,
}

export default (props: Props) => {
    const t = useTranslation();


    return (
        <div className={`preloader ${props.classes ? props.classes : ''}`}>
            <img
                src={require('../assets/img/icons/preloader2.gif').default}
                alt={t('loading')}
                className="preloader__img"
            />
        </div>
    );
}
