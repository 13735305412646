import '../../../assets/style/vendors/skeleton.css';
import {Preloader} from "../../../misc/_index";
import {useSelector} from "react-redux";
import {useState} from "react";
import {useTranslation} from "../../../misc/Hooks";
import {GetInTouchModal} from "../../../components/modals/_index";
import {CodeField} from "../components";
import {Button} from "../../../components/buttons/_index";


export default function OrderFinalized(props) {
    const t = useTranslation();
    const data = useSelector(({eTrade}) => eTrade.orderFinalizedData)
    const [gitModalVisible, setGitModalVisible] = useState(false);


    return (
        <div className="wrapper">
            {props.loading ? (
                <Preloader/>
            ) : data ? (
                <div className="par-2">
                    <div dangerouslySetInnerHTML={{__html: data.typ?.thank_you_page_text}}/>
                    <div className="grid">
                        <div/>
                        <div>
                            <div className="--mb3">
                                <CodeField code={data.order.etrade_code.etrade_code_name}/>
                            </div>
                            {!!data.order.etrade_shop_redirect && (
                                <div className="--mb3">
                                    <a href={data.order.etrade_shop_redirect}><Button type="primary-outline" label={t('back_to', data.order.invoice_shop?.shop_title)}/></a>
                                </div>
                            )}
                            <div className="par-4 --semiBold">
                                <span>{t('need_help')} </span>
                                <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                                    {t('get_in_touch')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="--mb4" style={{textAlign: 'center'}}>
                    <h1 className="par-1">{t('etrade.order_not_found.title')}</h1>
                    <p className="par-2">{t('etrade.order_not_found.text')}</p>
                </div>
            )}


            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />
        </div>
    )
}
