import {Link} from "react-router-dom";
import {EmailForm, SignInForm} from "../forms/_index";
import {Button} from "../buttons/_index";
import {useEvStepNavigation, useTranslation} from "../../misc/Hooks";
import {Preloader} from "../../misc/_index";


interface Props {
    nespressoMember: any,
    error: any,
    onSelect: void,
    onSubmit: void,
}


const CustomerIdentificationStep = (props: Props) => {
    const t = useTranslation();
    const stepNavigation = useEvStepNavigation();
    return (
        <div className="evCID">

            <div className="grid">
                <div>
                    <p className="par-1 --bold --mb3">{t('ev_customer_identification.text')}</p>


                    <div className="evCID__buttons --mb4">
                        <Button
                            label={t('yes')}
                            type="grey"
                            active={props.nespressoMember !== undefined && (props.nespressoMember === null || typeof props.nespressoMember === 'object')}
                            onClick={() => props.onSelect(null)}
                        />
                        <Button
                            label={t('no')}
                            type="grey"
                            active={props.nespressoMember !== undefined && props.nespressoMember === false}
                            onClick={() => props.onSelect(false)}
                        />
                    </div>


                    {props.nespressoMember === null && !props.user && (
                        <div className="grid">
                            <div className="col">
                                <div className="--mb3">
                                    <SignInForm
                                        error={props.error}
                                        loading={props.loading}
                                        onSubmit={props.onSubmit}
                                    />
                                </div>
                                {!!props.loading && <Preloader/>}
                            </div>
                        </div>
                    )}

                    {props.nespressoMember === null && !!props.user && (
                        <div className="grid">
                            <div>
                                <EmailForm
                                    data={props.customer}
                                    stepNavigation={stepNavigation}
                                    onSubmit={props.onSubmitEmail}
                                />
                            </div>
                            <div/>
                        </div>
                    )}

                    {props.nespressoMember === false && props.customer && (
                        <div className="step__nav">
                            <Link to={stepNavigation.prev()}>
                                <Button
                                    type="primary-outline"
                                    label={t('back')}
                                />
                            </Link>
                            <Link to={stepNavigation.next()}>
                                <Button
                                    label={t('continue')}
                                />
                            </Link>
                        </div>
                    )}


                    {(props.nespressoMember !== null && props.nespressoMember !== false) && (
                        <div className="--mb4">{t('you_are_signed_in')}</div>
                    )}
                </div>
                <div/>
            </div>

        </div>
    );
}


export default CustomerIdentificationStep;
