import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as mainReducer from "../redux/mainReducer";
import * as MainActions from "../actions/MainActions";
import * as configActions from "../actions/ConfigActions";
import * as countryActions from "../actions/CountryActions";
import Axios from "../utils/Axios";
import Env from "../utils/Env";
import {useNavigation} from "./Hooks";
import {RecaptchaKeyNotFoundModal} from "../components/modals/_index";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {Config, Routes} from "../utils/_index";
import {GTM} from "./_index";
import {useLanguage} from "../hooks";
import PagePreloader from "../components/PagePreloader";
import {getMarketStacks} from "../utils/MarketConfig";


let MOUNTED = false;

const AppProvider = (props) => {
   const match = useRouteMatch();
   const history = useHistory();
   const location = useLocation()
   const navigation = useNavigation();
   const dispatch = useDispatch();
   const currentLanguage = useLanguage()
   const store = useSelector(({main}) => ({
      country: main.country,
      marketConfig: main.marketConfig,
      recaptchaKey: main.recaptchaKey,
   }))
   const [recaptchaKeyNotFoundModalVisible, setRecaptchaKeyNotFoundModalVisible] = useState(false);
   const [loaded, setLoaded] = useState(!!(store.country && store.marketConfig))

   useEffect(() => {
      Axios.defaults.headers.common['Language'] = currentLanguage;

      if (Config.RTL_LANGUAGES.includes(currentLanguage)) {
         document.body.classList.add('-rtl');
      }
   }, [])

   useEffect(() => {
      if(!location.search)
         window.scrollTo(0, 0);
   }, [location.pathname])

   useEffect(() => {
      if (!store.recaptchaKey && store.marketConfig) {
         // setRecaptchaKeyNotFoundModalVisible(true);
         if (Env.ENV !== 'dev') {
            history.push(navigation(Routes.MAINTENANCE))
         }
      }
   }, [store.recaptchaKey])


   useEffect(() => {
      if (store.country) {
         GTM.configure(store.country.api_market, store.country.country_currency_name)
      }
   }, [store.country])


   useEffect(async () => {
      MOUNTED = true;

      await Promise.all([
         getConfig(),
         getCountry(),
         getStacks(),
      ]).finally(() => {
         if(MOUNTED) {
            setLoaded(true)
         }
      })
      // await getConfig();
      // await getCountry();
      // await getStacks();

      return () => {
         MOUNTED = false;
      }
   }, [])


   const getConfig = async () => {
      return configActions.get().then(r => {
         const res = r.data;
         if (res.status === 'success') {
            if (MOUNTED) {
               dispatch(mainReducer.setMarketConfig(
                  res.data.config,
                  res.data.vat_modifiers,
                  res.data.recaptcha_key,
                  res.data.display,
               ));
            }
         } else {
            console.log('Something gone wrong! ', res)
         }
      }).catch(err => {
         history.push(navigation(Routes.MAINTENANCE))
         console.warn('Error: ', err)
      })
   }

   const getCountry = async () => {
      return countryActions.getByDomain().then(r => {
         const res = r.data;

         Axios.defaults.headers.common['Language'] = currentLanguage;

         if (res.status === 'success') {
            if (MOUNTED) {
               if (!GTM.isConfigured()) {
                  GTM.configure(res.data.api_market, res.data.country_currency_name)
               }

               dispatch(mainReducer.setCountry(res.data));

               if (match.url === (navigation(Routes.SHOP) ? navigation(Routes.SHOP) : '/')) {
                  if (res.data.country_homepage_redirect) {
                     window.location.href = res.data.country_homepage_redirect.replace('_LANG_', currentLanguage);
                  } else {
                     history.push(navigation(Routes.SHOP_MACHINE_REGISTRATION))
                  }
               }
            }
         } else {
            console.log('Something gone wrong! ', res)
         }
      }).catch(err => {
         console.warn('Error: ', err)
      })
   }


   const getStacks = async () => {
      const stacks = getMarketStacks()

      const arr = {}
      await Promise.all(stacks.map(async s => {
         await MainActions.getStackByType(s).then(r => {
            const res = r.data;
            if (res.status === 'success') {
               arr[s] = res.data
            } else {
               console.log('Something gone wrong! ', res);
            }
         })
      }))

      dispatch(mainReducer.setStacks(arr));
   }

   if (!loaded)
      return <PagePreloader/>

   return (
      <>
         {props.children}


         {Env.ENV !== 'prod' && (
            <RecaptchaKeyNotFoundModal
               visible={recaptchaKeyNotFoundModalVisible}
            />
         )}
      </>
   )
}


export default AppProvider;
