import {useTranslation} from "../../../misc/Hooks";
import {Controller, useForm} from "react-hook-form";
import {MyInput, MySelect} from "../../../components/forms/inputs/_index";
import {Button} from "../../../components/buttons/_index";
import React, {useEffect, useState} from "react";
import Patterns from "../../../utils/Patterns";

export default function RegistrationResellerForm(props) {
    const t = useTranslation();
    const [logs, setLogs] = useState([]);
    const [shops, setShops] = useState([]);

    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            group: {label: props.shopFromToken?.shop_group_title, value: props.shopFromToken?.id_shop_group},
            shop: {label: props.shopFromToken?.shop_title, value: props.shopFromToken?.id_shop}
        }
    });

    useEffect(() => {
        if (props.sendingStatus.status === 'error') {
            setCustomError('email', props.sendingStatus.errors.reseller_email)
            setCustomError('number', props.sendingStatus.errors.reseller_number)
            setCustomError('login', props.sendingStatus.errors.reseller_login)
        } else {
            formMethods.clearErrors()
            formMethods.setValue('login', '')
            formMethods.setValue('number', '')
            formMethods.setValue('email', '')
            formMethods.setValue('firstname', '')
            formMethods.setValue('lastname', '')
        }
    }, [props.sendingStatus])

    const pushLog = (l) => {
        const arr = [...logs];
        arr.push({
            type: 'proof_of_purchase',
            ...l,
        })
        setLogs(arr);
    }

    const setCustomError = (name, field) => {
        if (field)
            formMethods.setError(name, {
                type: "manual",
                message: t(`reseller_registration.register_error.${name}.${field[0].toLowerCase()}`)
            })
    }


    const mapShopGroupsToOptions = () => {
        const options = [];
        props.shops?.map(shop => {
            options.push({
                label: shop.shop_group_title,
                value: shop.id_shop_group,
            })
        })
        return options;
    }

    const mapShopsToOptions = (shops: []) => {
        const options = [];
        shops?.map(shop => {
            let label = shop.shop_title;

            options.push({
                label,
                value: shop.id_shop,
            })
        })
        return options;
    }

    const handleChangeTradePartner = (value, onChange) => {
        formMethods.setValue('shop', null);
        const shop = props.shops.find(s => s.id_shop_group === value.value);
        if (shop) {
            pushLog({date: new Date().toISOString(), manually: 1, field: 'id_shop_group', value: shop.id_shop_group});
            setShops(mapShopsToOptions(shop.shops))
        }
        onChange(value);
    }

    const login = formMethods.watch('login');
    const number = formMethods.watch('number');
    const firstname = formMethods.watch('firstname');
    const lastname = formMethods.watch('lastname');
    const email = formMethods.watch('email');
    const shop = formMethods.watch('shop');

    const rules = {
        login: {
            required: {
                value: true,
                message: t('validation.required'),
            },
            pattern: {value: Patterns.login, message: t('validation.invalid')},
        },
        number: {
            required: {
                value: true,
                message: t('validation.required'),
            },
            maxLength: {
                value: 255,
                message: 'validation.max_length',
            },
        },
        firstname: {
            required: {
                value: true,
                message: t('validation.required'),
            }
        },
        lastname: {
            required: {
                value: true,
                message: t('validation.required'),
            }
        },
        email: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: Patterns.email, message: t('validation.invalid_email') || t('validation.invalid')}
        },
        group: {
            required: {value: true, message: t('validation.required')}
        },
        shop: {
            required: {value: true, message: t('validation.required')}
        }
    }

    const onSubmit = (data) => {
        props.onSubmit(data);
    }

    return (
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className="grid">
                <Controller
                    name="login"
                    control={formMethods.control}
                    rules={rules.login}
                    render={({onChange, value, ref}) => (
                        <MyInput
                            inputRef={ref}
                            name="login"
                            label={t('reseller_registration.login') || t('login')}
                            value={value}
                            error={formMethods.errors.login}
                            onChange={onChange}
                        />
                    )}
                />
            </div>
            <div className="grid">
                <Controller
                    name="number"
                    control={formMethods.control}
                    rules={rules.number}
                    render={({onChange, value}) => (
                        <MyInput
                            name="number"
                            label={t('reseller_registration.reseller_number')}
                            value={value}
                            error={formMethods.errors.number}
                            onChange={onChange}
                        />
                    )}
                />
            </div>
            <div className="grid">
                <Controller
                    name="firstname"
                    control={formMethods.control}
                    rules={rules.firstname}
                    render={({onChange, value}) => (
                        <MyInput
                            name="firstname"
                            label={t('reseller_registration.firstname') || t('firstname')}
                            value={value}
                            error={formMethods.errors.firstname}
                            onChange={onChange}
                        />
                    )}
                />
                <Controller
                    name="lastname"
                    control={formMethods.control}
                    rules={rules.lastname}
                    render={({onChange, value}) => (
                        <MyInput
                            name="lastname"
                            label={t('reseller_registration.lastname') || t('lastname')}
                            value={value}
                            error={formMethods.errors.lastname}
                            onChange={onChange}
                        />
                    )}
                />
            </div>
            <div className="grid">
                <Controller
                    name="email"
                    control={formMethods.control}
                    rules={rules.email}
                    render={({onChange, value}) => (
                        <MyInput
                            name="email"
                            label={t('reseller_registration.email') || t('email')}
                            value={value}
                            error={formMethods.errors.email}
                            onChange={onChange}
                        />
                    )}
                />
            </div>
            <div className="grid">
                <Controller
                    name="group"
                    control={formMethods.control}
                    rules={rules.group}
                    render={({onChange, value}) => (
                        <MySelect
                            name="group"
                            label={t('reseller_registration.trade_partner') || t('trade_partner')}
                            value={value}
                            loading={props.loading}
                            options={mapShopGroupsToOptions()}
                            error={formMethods.errors.group}
                            onChange={v => handleChangeTradePartner(v, onChange)}
                            isSearchable
                            disabled={!!props.shopFromToken}
                        />
                    )}
                />
            </div>
            <div className="grid">
                <Controller
                    name="shop"
                    control={formMethods.control}
                    rules={rules.shop}
                    render={({onChange, value}) => (
                        <MySelect
                            name="shop"
                            label={t('reseller_registration.retailer_store') || t('retailer_store')}
                            value={value}
                            loading={props.loading}
                            options={shops}
                            error={formMethods.errors.shop}
                            onChange={v => {
                                pushLog({
                                    date: new Date().toISOString(),
                                    manually: 1,
                                    field: 'id_shop',
                                    value: v.value
                                });
                                onChange(v);
                            }}
                            isSearchable
                            disabled={!!props.shopFromToken}
                        />
                    )}
                />
            </div>

            <Button
                btnType="submit"
                type="primary"
                label={t('reseller_registration.continue') || t('continue')}
                disabled={(!login || !number || !firstname || !lastname || !email || !shop) || props.blockButton}
                onClick={formMethods.handleSubmit(onSubmit)}
            />
        </form>
    )
}
