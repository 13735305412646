import {Routes} from "../../../utils/_index";
import {useNavigation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";


/**
 * Validates eTrade module route / step
 * @param route
 * @type route: string
 * @returns {{lastValidRoute: (*), value: boolean}}
 */
export default function useStepValidation(route) {
    const navigation = useNavigation()
    const store = useSelector(({eTrade}) => ({
        data: eTrade.data,
        token: eTrade.token,
        nespressoMember: eTrade.nespressoMember,
        customer: eTrade.customer,
        order: eTrade.order,
    }))

    const response = {
        canShowStep: !!store.data,
        lastValidStep: Routes.ETRADE_MACHINE_PLAN,
    }

    if(store.order) {
        response.canShowStep = route === Routes.ETRADE_SUMMARY
        response.lastValidStep = Routes.ETRADE_SUMMARY
    } else {
        switch (route) {
            case Routes.ETRADE_SUMMARY:
                if(!store.customer) {
                    response.canShowStep = false
                    response.lastValidStep = Routes.ETRADE_CUSTOMER
                }
            case Routes.ETRADE_CUSTOMER:
                if(store.nespressoMember === undefined || store.nespressoMember === null) {
                    response.canShowStep = false
                    response.lastValidStep = Routes.ETRADE_AUTHORIZATION
                }
            case Routes.ETRADE_AUTHORIZATION:
                break
            case Routes.ETRADE_MACHINE_PLAN:
                if(!store.token) {
                    response.canShowStep = false
                    response.lastValidStep = Routes.ETRADE_INVALID_TOKEN
                }
        }
    }


    return {
        value: response.canShowStep,
        lastValidRoute: navigation(response.lastValidStep),
    }
}
