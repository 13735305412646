import React, {useRef, useState} from 'react';


interface Props {
    name: string,
    label: string,
    value: string,
    error: any,
    disableLabelClick: boolean,
    green?: boolean,
    onChange: void,
}


export default (props: Props) => {
    const inputRef = useRef(null);
    const [isFocused, setFocused] = useState(false);


    const handleChange = (e) => {
        inputRef.current?.click();
    }


    let formGroupClassNames = '';
    if(props.value?.length || props.error) {
        formGroupClassNames += ' -active';
    }
    if(isFocused) {
        formGroupClassNames += ' -focused';
    }
    if(props.error) {
        formGroupClassNames += ' -invalid';
    }
    if(!props.error && props.value?.length) {
        formGroupClassNames += ' -valid';
    }
    if(props.green) {
        formGroupClassNames += ' -green';
    }
    if(props.value) {
        formGroupClassNames += ' -checked';
    }

    return (
        <div className={`customCheckbox ${formGroupClassNames}`}>

            <label className="customCheckbox__field">
                <input
                    ref={inputRef}
                    type="checkbox"
                    id={props.name ?? ''}
                    name={props.name ?? ''}
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                    hidden
                />

                {/*<div className="formCheckbox__input"*/}
                {/*     data-checked={props.value}*/}
                {/*     onClick={handleChange}*/}
                {/*/>*/}


                    {props.label ?? props.children}

            </label>

            {!!props.error && (
                <span className="formGroup__invalidFeedback">{props.error.message}</span>
            )}
        </div>
    );
}
