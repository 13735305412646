import React from "react";
import {useTranslation} from "../misc/Hooks";


interface ConfigProps {
    required?: boolean,
    requiredMessage?: string,
    minLength?: number,
    maxLength?: number,
    pattern?: any,
    patternInfo?: string,
    patternMessage?: string,
    validate?: void,
    sameAs?: void,
    sameAsMessage?: string,
    dateBiggerThan?: any,
    dateBiggerThanMessage?: string,
    dateLessThan?: any,
    dateLessThanMessage?: string,
    noEmptyArray?: any,
}


const useFormRules = () => {
    const t = useTranslation()


    const make = (config: ConfigProps) => {
        const response = {};

        if(config.required) {
            response.required = {value: true, message: config.requiredMessage ?? t('validation.required')};
        }
        if(typeof config.minLength === 'number') {
            response.minLength = {value: config.minLength, message: t('validation.min_length', config.minLength)};
        }
        if(typeof config.maxLength === 'number') {
            response.maxLength = {value: config.maxLength, message: t('validation.max_length', config.maxLength)};
        }
        if(config.pattern) {
            response.pattern = {
                value: config.pattern,
                message: config.patternMessage ?? (config.patternInfo ? t('validation.pattern', config.patternInfo) : t('validation.invalid'))
            };
        }
        if(config.sameAs) {
            // console.log('same as', config.sameAs)
            response.validate = v => v === config.sameAs ? true : config.sameAsMessage || t('validation.invalid');
        }
        if(config.validate) {
            response.validate = config.validate;
            // console.log('validate...')
        }
        if(config.dateBiggerThan){
            response.validate = v => v >= config.dateBiggerThan ? true : config.dateBiggerThanMessage || t('validation.date_bigger_than');
        }
        if(config.dateLessThan){
            response.validate = v => v <= config.dateLessThan ? true : config.dateLessThanMessage || t('validation.date_less_than');
        }
        if(config.noEmptyArray) {
            response.validate = v => v.length ? true : config.requiredMessage ?? t('validation.required');
        }

        return response;
    }


    return {
       make,
    }
}


export default useFormRules;
