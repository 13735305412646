import {useMarketConfig, useNavigation, useTranslation} from "../../../misc/Hooks"
import {useHistory, useLocation} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {useEffect, useMemo, useRef, useState} from "react"
import {mapOrderToCustomer, mapStackToOptions} from "../../../misc/Helpers"
import * as orderActions from "../../../actions/OrderActions"
import * as ofReducer from "../../../redux/ofReducer"
import {Routes} from "../../../utils/_index"
import SummaryScreen from "../screens/SummaryScreen"
import {useStepsNavigation} from "../../../hooks/modules/order_finish"
import {useCivilityOptions, useOrderData} from "../../../hooks";
import * as OrderService from "../../../services/OrderService";
import * as Helpers from "../../../misc/Helpers";
import {GTM, Preloader} from "../../../misc/_index";
import * as evReducer from "../../../redux/evReducer";

let MOUNTED = false

export default function SummaryContainer(props) {
    const quotationTimeoutRef = useRef(null)
    const t = useTranslation()
    const navigation = useNavigation()
    const history = useHistory()
    const location = useLocation()
    const stepsNavigation = useStepsNavigation()
    const marketConfig = useMarketConfig()
    const dispatch = useDispatch()
    const store = useSelector(({main, of}) => ({
        country: main.country,
        civilities: main.civilities,
        finishOrder: of.finishOrder,
        finishOrderToken: of.finishOrderToken,
        finishOrderUser: of.finishOrderUser,
        uuid: of.uuid,
        order: of.order,
        customer: of.customer,
        nespressoMember: of.nespressoMember,
        products: of.products,
        categories: of.categories,
        addresses: of.addresses,
        cart: of.cart,
        orderAccount: of.orderAccount,
        orderAccountLinked: of.orderAccountLinked,
        cartQuotationData: of.cartQuotationData,
    }))
    const civilityOptions = useCivilityOptions()
    const [memberCredit, setMemberCredit] = useState(null)
    const [recaptchaToken, setRecaptchaToken] = useState(null)
    const [orderState, setOrderState] = useState({
        loading: false,
        errorLog: store.order?.res?.error ? JSON.stringify(store.order) : null,
        error: store.order?.res?.error ? store.order.order ? 'order_saved_error_occurred' : 'order_save_error' : null,
    })
    const orderUuid = useMemo(() => {
        if (!location.search)
            return null
        return new URLSearchParams(location.search).get('uuid')
    }, [location])
    const orderData = useOrderData('of')
    const [savedOrder, setSavedOrder] = useState({loading: !!orderUuid, order: null})
    const [cartQuotationState, setCartQuotationState] = useState({loading: false});

    useEffect(() => {
        if (!orderUuid && store.cartQuotationData) {
            clearTimeout(quotationTimeoutRef.current)
            quotationTimeoutRef.current = setTimeout(() => {
                cartQuotation()
            }, 850)
        }
    }, [store.cart])

    useEffect(() => {
        MOUNTED = true;

        document.title = t('finish_order_summary.title')
        GTM.showPage(t('finish_order_summary.title'))

        if (orderUuid) {
            getOrder(orderUuid)
        } else {
            if(!store.cartQuotationData){
                cartQuotation()
            }
        }

        return () => {
            MOUNTED = false;
        }
    }, [])

    const cartQuotation = async () => {
        setCartQuotationState({loading: true})
        setOrderState({error: null, errorLog: null})

        if(store.finishOrder.payment_method_integration_type === 'quotation_non_cc') {
            const data = {
                customer: orderData.getData(),
                cart: store.cart,
            };

            const res = await OrderService.cartQuotation(data)
                .catch(e => {
                    setOrderState({error: e.message || 'invalid_order', errorLog: JSON.stringify(e.data)})
                })
            if (res) {
                // TODO: filtrowanie należy przeerobić tak, zeby porównywało jedynie pole api_payment_method, a w przypadku pustości pola - przepuszcza dalej
                //const paymentMethods = store.promotion.payment_methods.filter(p => res.eligibleInformation?.allowedPaymentModes.find(ap => ap.paymentMode === p.id_nestle))
                //setPaymentMethods(paymentMethods)
                // setPaymentMethods(store.promotion.payment_methods)
                // setMemberCredit(res.creditData)
                dispatch(evReducer.setCartQuotationData(res.data))
            }
        } else {
            const customer = orderData.getData()
            const data = {
                customer,
                address_id: customer.address_id,
                items: orderData.memberCreditCart(),
            };

            const res = await OrderService.memberCredit(data)
                .catch(e => {
                    setOrderState({error: e.message || 'invalid_order', errorLog: JSON.stringify(e.data)})
                })
            if(res) {
                setMemberCredit(res.creditData)
            }
        }

        setCartQuotationState({loading: false})
    }

    const handleSubmit = async () => {
        setOrderState({loading: true, error: null, errorLog: null})

        const payload = {
            customer: orderData.getData(),
            cart: store.cart,
            recaptcha_token: recaptchaToken,
            ...orderData.getCartQuotationData(),
        };

        const savedOrder = await OrderService.updateOrder(store.finishOrderToken, payload)
            .catch(err => {
                setOrderState({error: 'order_save_error', errorLog: JSON.stringify(err.data)})
            })

        if (savedOrder) {
            if (savedOrder.error) {
                if(orderData.error === 'order_exists') {
                    history.push({search: `?uuid=${payload.customer.uuid}`})
                    await getOrder(payload.customer.uuid)
                } else {
                    setOrderState({errorLog: JSON.stringify(savedOrder.errorLog)})
                }
            } else {
                history.replace({search: `?uuid=${savedOrder.data.order.uuid}`})
                await getOrder(savedOrder.data.order.uuid)
            }
        }
    }

    const handleSendErrors = () => {
        // todo: implement functionality
    }

    const handlePressRestart = () => {
        window.location.href = navigation(Routes.ORDER_FINISH, store.token)
    }

    const getOrder = async (uuid) => {
        await Helpers.getOrder(uuid)
            .then(async data => {
                if (!data.order) {
                    history.push(navigation(Routes.ORDER_FINISH))
                    return
                }


                let order = {...data.order}

                setSavedOrder({...data})
                setOrderState(prev => ({...prev, loading: true}))
                dispatch(ofReducer.clearStore())

                if(!order.quotation_response) {
                    const quotationData = await OrderService.makeQuotation(order.id_order)
                        .catch(err => {
                            setOrderState({error: 'order_save_error', errorLog: JSON.stringify(err.data)})
                        })
                    order = quotationData?.order

                    if (order) {
                        // setSavedOrder({...data, order})
                        await getOrder(uuid)
                        return
                    } else {
                        setOrderState({error: 'order_save_error', errorLog: JSON.stringify(order)})
                    }
                }

                if(order) {
                    setSavedOrder({...data})
                    setOrderState(prev => ({...prev, loading: false}))

                    switch (order.quotation_response) {
                        case 'TYP':
                            history.push(navigation(Routes.ORDER_FINISH_ORDER_FINALIZED, order.uuid))
                            break
                        case 'HOP':
                            window.location.href = order.hop_link
                            break
                        case 'PAYMENT':
                            if (order.is_paid) {
                                history.push(navigation(Routes.ORDER_FINISH_ORDER_FINALIZED, order.uuid))
                            }
                    }
                }
            })
    }

    return (
        <>
            {savedOrder.loading || (cartQuotationState.loading && !store.cartQuotationData) ? (
                <Preloader/>
            ) : (
                <SummaryScreen
                    orderState={orderState}
                    memberCredit={memberCredit}
                    cartQuotationState={cartQuotationState}
                    recaptchaToken={recaptchaToken}
                    cart={savedOrder.order ? savedOrder.cart : store.cart}
                    categories={savedOrder.order ? savedOrder.categories : store.categories}
                    products={savedOrder.order ? savedOrder.products : store.products}
                    customer={savedOrder.order ? mapOrderToCustomer(savedOrder.order, civilityOptions) : store.customer ?? mapOrderToCustomer(store.finishOrder, civilityOptions.options)}
                    order={savedOrder.order}
                    onChangeRecaptchaToken={token => setRecaptchaToken(token)}
                    onSendErrors={handleSendErrors}
                    onPressRestart={handlePressRestart}
                    onSubmit={handleSubmit}
                />
            )}
        </>
    )
}
