import '../../../assets/style/vendors/skeleton.css';
import {useLocalizeObject, useTranslation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Button} from "../../../components/buttons/_index";
import {useStepsNavigation} from "../../../hooks/modules/etrade";


export default function MachinePlanScreen(props) {
    const stepsNavigation = useStepsNavigation()
    const t = useTranslation()
    const localizeObject = useLocalizeObject()
    const data = useSelector(({eTrade}) => eTrade.data)


    return (
        <div className="eTradeMachinePlan">
            <div className="--mb4">
                <div className="par-5 listCheck -circle" dangerouslySetInnerHTML={{__html: localizeObject(data.etrade_text)}}/>
            </div>

            <div className="step__nav">
                <Link to={stepsNavigation.next()}>
                    <Button label={t('continue')}/>
                </Link>
            </div>
        </div>
    )
}
