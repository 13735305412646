import React, {useEffect, useState} from 'react';
import ResellerReports from "../../components/reseller/reports/ResellerReports";
import {isoDateConverter} from "../../misc/Helpers";
import {useResellerActions} from "../../actions/useResellerActions";
import {useTranslation} from "../../misc/Hooks";
import {useSelector} from "react-redux";


let MOUNTED = false;

export default function ResellerReportsContainer(props) {
    const resellerActions = useResellerActions();
    const t = useTranslation();
    const store = useSelector(({main}) => ({
        user: main.user
    }));
    const [filters, setFilters] = useState({
        loading: true,
        data: []
    })
    const [users, setUsers] = useState({
        data: [],
        maxPage: 1,
        results: 0,
        loading: true,
        allResellers: []
    })
    const [dataStateOrders, setDataStateOrders] = useState({
        data: [],
        maxPage: 1,
        results: 0,
        loading: true,
    })
    const [ordersFiltersOptions, setOrdersFiltersOptions] = useState({
        resellers: [],
        shops: [],
        loading: true
    })
    const [dataReportsState, setDataReportsState] = useState({
        data: [],
        maxPage: 1,
        results: 0,
        loading: true,
        page: 1,
    })
    const [generateStatus, setGenerateStatus] = useState({
        modalVisible: false,
        loading: false,
        url: null
    })

    useEffect(() => {

    }, [users])

    useEffect(() => {
        MOUNTED = true;
        getOrdersFilterOptions()
        getReportsFilters()
        return () => {
            MOUNTED = false;
        }
    }, [])

    const getOrders = async (page, pageSize, searchData = null, filters = {}) => {
        setDataStateOrders((old) => ({
            ...old,
            loading: true
        }))

        const params = `limit=${pageSize}&page=${page}`;
        const payload = {
            sort: null,
            slug: searchData?.slug,
            order_type: null,
            date_from: filters.date_from ? isoDateConverter(filters.date_from, '-') : null,
            date_to: filters.date_to ? isoDateConverter(filters.date_to, '-') : null,
            id_shop: filters.shops ? filters.shops.map((shop) => shop.value) : [],
            id_reseller: filters.resellers ? filters.resellers.map((reseller) => reseller.value) : [],
            status: [],
        }

        await resellerActions.getOrders(payload, params)
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    setDataStateOrders({
                        data: res.data.data,
                        results: res.data.total,
                        maxPage: res.data.last_page,
                        loading: false,
                    })
                } else {

                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    const getOrdersFilterOptions = () => {
        const params = `all_resellers=1`;

        const data = {
            id_shop: []
        }
        resellerActions.reportsFilter(params, data).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                setOrdersFiltersOptions({
                    resellers: res.data.resellers,
                    shops: res.data.shops,
                    loading: false
                })
            } else {
                console.log(res)
            }
        }).catch(err => {
            console.error(err)
        })
    }

    const getGeneratedReports = async (page, pageSize, searchData = null) => {
        let params = `limit=${pageSize}&page=${page}`;
        if (searchData?.slug) {
            params += `&slug=${searchData.slug}`
        }

        setDataReportsState((old) => ({
            ...old,
            loading: true
        }))

        let items = [];
        await resellerActions.getGeneratedReports(params).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                items = [...res.data.data];
                if (MOUNTED) {
                    setDataReportsState({
                        data: items,
                        maxPage: res.data.last_page,
                        results: res.data.total,
                        loading: false,
                        page: res.data.current_page,
                    })
                }
            } else {
                console.warn('Something gone wrong!')
            }
        }).catch(err => {
            console.error('Error: ', err);
        }).finally(async () => {
        })
    }

    const getReportsFilters = (data = null) => {
        setFilters((old) => ({
            ...old,
            loading: true
        }))

        resellerActions.getReportsFilters(data).then(r => {
            const res = r.data

            if (res.status === 'success') {
                setFilters((old) => ({
                    loading: false,
                    data: res.data
                }))
            } else {
                console.log(res)
            }


        }).catch(err => {
            console.error(err)
        })

    }

    const getUsers = async (page, pageSize, data = null) => {
        setUsers((old) => ({
            ...old,
            loading: true,
        }))
        const params = {
            limit: pageSize,
            page: page,
            ...data
        }

        await resellerActions.getResellerList(params).then(r => {
            const res = r.data;

            if (res.status === 'success') {
                setUsers({
                    data: res.data.resellers.data,
                    maxPage: res.data.resellers.last_page,
                    results: res.data.resellers.total,
                    loading: false,
                    allResellers: res.data.all_reseller_ids
                })
            } else {
                console.log(res)
            }
        }).catch((err) => {
            console.error(err)
        })

    }

    const onSubmitReport = (data) => {
        setGenerateStatus({
            modalVisible: true,
            loading: true,
            url: null
        })

        let resellersIds = [...data.selectedUsers];
        if ((data.withMe) || (data.selectedUsers.length === 0 && !data.withMe)) {
            resellersIds.push(store.user.id_reseller)
        }


        const postData = {
            reseller_id: resellersIds,
            shop_id: [],
            date_from: isoDateConverter(data.dateFrom, '-'),
            date_to: isoDateConverter(data.dateTo, '-'),
            translations: {
                columns: {
                    id: t('order.id'),
                    shop_name: t('order.shop_name'),
                    reseller_email: t('order.reseller_email'),
                    promotion_type: t('order.promotion_type'),
                    tracking_code: t('order.tracking_code'),
                    status_of_registration: t('order.status_of_registration'),
                    status_of_payment: t('order.status_of_payment'),
                    data_time: t('order.date_time'),
                    machine_type: t('order.machine_type'),
                    serial_number: t('order.serial_number')
                },
                subscription_status: {
                    "-3": t('subscription_status.-3'),
                    "-1": t('subscription_status.-1'),
                    "0": t('subscription_status.0'),
                    "1": t('subscription_status.1'),
                    processed: t('subscription_status.processed'),
                    in_treatment: t('subscription_status.in_treatment')
                },
                payment_status: {
                    "-1": t('payment_status.-1'),
                    expired: t('payment_status.expired'),
                    "0": t('payment_status.0'),
                    "1": t('payment_status.1'),
                    "2": t('payment_status.2'),
                }
            }
        }

        resellerActions.generateRaport(postData).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                setGenerateStatus({
                    modalVisible: true,
                    loading: true,
                    url: null
                })

                setTimeout(() => {
                    checkRaportStatus(res.data.id_reseller_report)
                }, 3000)

            } else {
                console.warn('Something gone wrong!')
            }
        }).catch(err => {
            console.error(err)
        })
    }

    const checkRaportStatus = (id) => {
        resellerActions.getGeneratedReports(`id=${id}`).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (res.data.data[0].reseller_report_status === 0) {
                    setTimeout(() => {
                        checkRaportStatus(id)
                    }, 3000)
                } else {
                    setGenerateStatus((old) => ({
                        ...old,
                        loading: false,
                        url: res.data.data[0].reseller_report_url
                    }))
                }
            }
        })
    }

    const onResendOrder = (id) => {
        resellerActions.resendOrder(id)
            .then(() => {
            })
            .catch(err => console.error(err))
    }
    return (
        <div className="wrapper -xxl">

            <ResellerReports
                generateModule={{
                    filters: filters,
                    getFilters: getReportsFilters,
                    users: users,
                    getUsers: getUsers,
                    onSubmitReport: onSubmitReport,
                    generateStatus: generateStatus,
                    setGenerateStatus: setGenerateStatus
                }}
                ordersModule={{
                    getData: getOrders,
                    config: dataStateOrders,
                    filtersOptions: ordersFiltersOptions,
                    resendOrder: onResendOrder
                }}
                reportsModule={{
                    getData: getGeneratedReports,
                    config: dataReportsState,
                }}
            />
        </div>
    )
}
