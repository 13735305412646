import Axios from "../utils/Axios";


export const createAccount = (data) => {
    return Axios.post(`/order/account`, data);
}

export const makeQuotation = (data) => {
    return Axios.post(`/order/quotation`, data);
}

export const finalize = (data) => {
    return Axios.post(`/order/save`, data);
}

export const finalizeReseller = (data) => {
    return Axios.post(`/reseller/order/save`, data);
}

export const finalizeTps = (data) => {
    return Axios.post(`/order/save-tps`, data);
}

export const finalizeETrade = (data) => {
    return Axios.post(`/etrade/save`, data);
}

export const finalizeStayHome = (data, token) => {
    return Axios.post(`/stay-home/order/save/${token}`, data);
}

export const getOrderInfo = (token) => {
    return Axios.get(`/order/reupload/${token}`)
}

export const reUploadPop = (token, data) => {
    return Axios.post(`/order/reupload/${token}`, data)
}

export const getOrder = (token) => {
    return Axios.get(`/order/${token}`);
}

export const getOrderByUuid = (token) => {
    return Axios.get(`/order/by-uuid/${token}`);
}

export const updateOrder = (token, data) => {
    return Axios.post(`/order/${token}`, data);
}

export const linkMember = (data, memberNumber) => {
    return Axios.post(`/order/link/${memberNumber}`, data);
}

export const linkMemberOld = (memberNumber) => {
    return Axios.post(`/order/link-quotation/${memberNumber}`);
}

export const checkCredit = (data) => {
    return Axios.post(`/order/check-credit`, data);
}

export const easyOrderFrequencies = (lang) => {
    return Axios.get(`/order/frequencies?lang=${lang}`);
}

export const cartQuotation = (data) => {
    return Axios.post(`/cart/quotation`, data);
}
