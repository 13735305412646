import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import * as ofReducer from "../../redux/ofReducer";
import * as authActions from '../../actions/AuthorizationActions';
import * as authorizationActions from '../../actions/AuthorizationActions';
import {useOfStepNavigation, useTranslation} from "../../misc/Hooks";
import Customer from "../../components/order/Customer";
import {mapStackToOptions} from "../../misc/Helpers";
import * as evReducer from "../../redux/evReducer";


let MOUNTED = false;

export const CustomerContainer = (props) => {
    const t = useTranslation()
    const history = useHistory();
    const dispatch = useDispatch();
    const store = useSelector(({main, of}) => ({
        country: main.country,
        user: main.user,
        civilities: main.civilities,
        customer: of.customer,
        nespressoMember: of.nespressoMember,
        nespressoMemberExist: of.nespressoMemberExist,
        promotion: of.promotion,
        addresses: of.addresses,
    }));
    const plan = useSelector(({of}) => {
        return of.products?.find(p => {
            const item = of.cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })
    const stepNavigation = useOfStepNavigation();
    const [error, setError] = useState(null);
    const [signInError, setSignInError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingAddresses, setLoadingAddresses] = useState(false);
    const civilityOptions = mapStackToOptions(store.civilities).sort((a,b) => Number(a.value) < Number(b.value) ? -1 : 1)


    useEffect(() => {
        MOUNTED = true;

        if (store.nespressoMember) {
            const data = {
                token: store.nespressoMember.token,
            };
            getClientAddresses(data);
        }

        return () => {
            MOUNTED = false;
        }
    }, [])


    const canRegister = (data) => {
        setLoading(true);
        authActions.canRegister(data).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (res.data) {
                    dispatch(ofReducer.setCustomer(data));
                    history.push(stepNavigation.next());
                } else {
                    if (!store.user) {
                        dispatch(ofReducer.setCustomer(null))
                        setError('email_in_use')
                    } else {
                        dispatch(ofReducer.setCustomer(data));
                        dispatch(ofReducer.setNespressoMemberExist(true))
                        history.push(stepNavigation.next());
                    }
                }
            } else {
                console.warn('Something gone wrong! ', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if (MOUNTED) {
                setLoading(false);
            }
        })
    }

    const canAttempt = (data) => {
        const params = {}
        if(store.promotion) {
            params['id_promotion'] = store.promotion.id_promotion
        }
        if(plan) {
            params['id_plan_machine'] = plan.id_product
        }

        setLoading(true);
        setError(null);
        authActions.canAttempt(data, params).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                if (MOUNTED) {
                    data.email = store.nespressoMember?.email;
                    dispatch(ofReducer.setCustomer(data));
                    history.push(stepNavigation.next());
                }
            } else {
                if(['phone_to_many_times_used'].includes(res?.messages[0])) {
                    setError(res?.messages[0])
                } else {
                    setError(res?.messages[0] ?? res?.data?.message ?? t('error.unknown_error'))
                }
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if(MOUNTED) {
                setLoading(false);
            }
        })
    }

    const getClientAddresses = async (memberId) => {
        setLoadingAddresses(true)
        await authActions.getAddresses(memberId).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(ofReducer.setAddresses(res.data));
                }
            } else {
                console.warn('Something gone wrong! ', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        })
        setLoadingAddresses(false)
    }


    const handleSubmit = (formData) => {
        let data = formData;
        const address = store.addresses.find(a => a.id === formData.memberAddress?.value);
        if (address) {
            data = {
                ...formData,
                ...address,
                email: store.nespressoMember.email,
                address_id: address.id,
                phone: formData.phone,
                title: civilityOptions.find(o => Number(o.value) === Number(address.title))
            }
        }


        if (!store.nespressoMember) {
            canRegister(data)
        } else {
            canAttempt(data)
        }
    }


    const handleSubmitSignIn = (data) => {
        authorizationActions.signIn(data).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                dispatch(ofReducer.setNespressoMember({
                    email: data.login,
                    ...res.data,
                }))
                dispatch(ofReducer.setCustomer(null))
                setError(null);
                getClientAddresses({token: res.data.token})
            } else {
                if (res.messages?.length) {
                    if (MOUNTED) {
                        setSignInError(res.messages[0]);
                    }
                }
                console.warn('Something gone wrong! ', res);
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if (MOUNTED) {
                setSignInError(null);
            }
        })
    }


    return (
        <Customer
            plan={plan}
            promotion={store.promotion}
            country={store.country}
            addresses={store.addresses}
            customer={store.customer}
            nespressoMember={store.nespressoMember}
            nespressoMemberExist={store.nespressoMemberExist}
            user={store.user}
            error={error}
            signInError={signInError}
            loading={loading}
            loadingAddresses={loadingAddresses}
            onSubmit={handleSubmit}
            onSubmitSignIn={handleSubmitSignIn}
        />
    );
}
