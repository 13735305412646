import {useNavigation, useTranslation} from "../../../../misc/Hooks";
import {NewUsersTable, UsersTable} from "../components";
import {Link, useParams} from "react-router-dom";
import {Routes} from "../../../../utils/_index";

export default function UsersScreen(props) {
    const t = useTranslation();
    const params = useParams();
    const navigation = useNavigation();

    return (
       <div className="wrapper -xxl">
       <div className="resellerUsers">
            <div className="resHome">
                <div className="resellerOptions">
                    <div className="resellerOptions__header">
                        {props.resellerLoading ? (
                            <h2 className="par-1">{t('loading')}</h2>
                        ) : (
                            <>
                                <h2 className="par-1 --mb0">
                                    {t('reseller_client.reseller') || t('reseller')}: {props.reseller.reseller_firstname} {props.reseller.reseller_lastname}
                                </h2>
                                <h3 className="par-2">{t(`reseller_client.reseller_role.${props.reseller.reseller_role}`)}</h3>
                               {params.id && (
                                  <Link className="backLink" to={navigation(Routes.RESELLER_USERS)} dangerouslySetInnerHTML={{__html: "< " + t('back')}}/>
                               )}
                            </>
                        )}
                    </div>
                    <div className="--mb4">
                        <UsersTable
                            reseller={props.reseller}
                            children={props.reseller?.childs?.data}
                            resellerLoading={props.resellerLoading}
                            certificateRenewal={props.certificateRenewal}
                            changeStatus={props.changeStatus}
                            resetPassword={props.resetPassword}
                            maxPage={props.resellerMaxPage}
                            results={props.resellerResults}
                            getData={props.getData}
                        />
                    </div>

                    <div className="resellerOptions__header">
                        <h2 className="par-1">
                            {t('reseller_client.new_request')}
                        </h2>
                    </div>
                    <NewUsersTable
                        items={props.resellerRequestItems}
                        newRequestLoading={props.newRequestLoading}
                        maxPage={props.resellerRequestMaxPage}
                        results={props.resellerRequestResults}
                        getData={props.getNewRequestData}
                        resellerVerify={props.resellerVerify}
                    />
                </div>
            </div>
        </div>
       </div>
    )
}
