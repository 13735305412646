import React from 'react';
import Home from "../../components/reseller/Home";


export default (props) => {
   return (
      <div className="wrapper">
         <Home/>
      </div>
   )
}
