import BaseModal from "../../../components/modals/BaseModal";
import {useTranslation} from "../../../misc/Hooks";
import React from "react";


/**
 *
 * @param visible
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
export default function PlanSelectionModal({visible, onClose}) {
    const t = useTranslation()

    return (
        <BaseModal visible={visible} onClose={onClose}>
            <div className="modal__header">
                <h3 className="modal__title">{t('stay_home.plan_selection_modal.title')}</h3>
            </div>

            <div className="modal__body">
                <div className="par2" dangerouslySetInnerHTML={{__html: t('stay_home.plan_selection_modal.text')}}/>
            </div>
        </BaseModal>
    )
}
