import React, {useEffect, useState} from "react";
import {Config, Routes} from "../../../utils/_index";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as rmsReducer from '../../../redux/rmsReducer';
import {useNavigation, useTranslation} from "../../../misc/Hooks";
import SummaryMS from "../../../components/reseller/SummaryMS";
import {LinkSentModal} from "../../../components/modals/_index";
import {useResellerActions} from "../../../actions/useResellerActions";


let MOUNTED = false;

export const SummaryContainer = (props) => {
    const t = useTranslation();
    const navigation = useNavigation();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch()
    const resellerActions = useResellerActions()
    const store = useSelector(({main, rms, reseller}) => ({
        country: main.country,
        user: main.user,
        shop: reseller.shop,
        firstname: reseller.firstname,
        lastname: reseller.lastname,
        uuid: rms.uuid,
        order: rms.order,
        customer: rms.customer,
        paymentMethod: rms.paymentMethod,
        machine: rms.machine,
        cart: rms.cart,
        serialNumber: rms.serialNumber,
        testOrder: rms.testOrder,
        nespressoMember: rms.nespressoMember,
        nespressoMemberExist: rms.nespressoMemberExist,
        canRegisterResponse: rms.canRegisterResponse,
        bank: rms.bank,
        verificationCodeToken: rms.verificationCodeToken,
    }));
    const plan = useSelector(({rms}) => {
        return rms.products?.find(p => {
            const item = rms.cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })
    const [error, setError] = useState(null);
    const [errorLog, setErrorLog] = useState(null);
    const [sending, setSending] = useState(false);
    const [linkSentModal, setLinkSentModal] = useState(false);


    useEffect(() => {
        MOUNTED = true;

        return () => {
            MOUNTED = false;
        }
    }, [])

    const handleSubmit = async (customer = store.customer, bank = store.bank) => {
        // const items = [];
        // store.plans.map(p => items.push({id: p.id_product, value: p.product_step}))


        let formData = {
            customer: {
                source: 'ev',
                serial: store.serialNumber?.serial_number ?? null,
                test_order: store.testOrder ? 1 : 0,
                // id_promotion: store.promotion.id_promotion,
                id_machine: store?.machine.id_machine,
                id_country: store.country.id_country,
                // id_shop: store.proofOfPurchase?.retailerStore?.value ?? null,
                promotion_type: Config.PROMO_TYPE.SUBSCRIPTION_MACHINE,
                coffee_type: store?.machine?.machine_coffee_type ?? null,
                uuid: store.uuid,
                lang: customer?.lang?.value ?? null,
                filename_machine: store.serialNumber.filename ?? '',
                // filename_receipt: store.proofOfPurchaseLogs.filename ?? '',
                currency_prefix: store.country?.country_currency_prefix ?? null,
                currency_suffix: store.country?.country_currency_suffix ?? null,
                currency_name: store.country?.country_currency_name ?? null,

                // invoice_date: store.proofOfPurchase.date ?? null,
                // invoice_number: store.proofOfPurchase.invoiceNumber ?? '',
                // invoice_price: store.proofOfPurchase.machinePrice ?? '',

                // ocr_number: store.proofOfPurchaseLogs.data.invoice ?? '',
                // ocr_date: store.proofOfPurchaseLogs.data.date ?? '',
                // ocr_price: store.proofOfPurchaseLogs.data.total ?? '',
                // ocr_shop: store.proofOfPurchaseLogs.data.id_shop ?? null,
                // ocr_shop_group: store.proofOfPurchaseLogs.data.id_shop_group ?? null,

                // address_id: customer?.address_id ?? null,

                delivery_is_company: customer.companyAddress ? 1 : 0,
                delivery_company_name: customer.companyName ?? '',
                delivery_company_tax_id: customer.taxId ?? '',
                delivery_company_profession: store.customer.profession?.value ?? null,
                delivery_civility: customer?.title ? Number(customer.title.value) : null,
                delivery_firstname: customer?.name ?? '',
                delivery_lastname: customer?.surname ?? '',
                delivery_zipcode: customer?.postcode ?? '',
                delivery_city: customer?.city ?? '',
                delivery_address1: customer?.address ?? '',
                delivery_address2: customer?.address2 ?? '',
                delivery_region: store.customer.region?.value ?? null,
                delivery_country: customer?.country?.value ?? '',
                delivery_phone: customer?.phone ?? '',
                delivery_phone_mobile: customer?.phoneMobile ?? '',
                delivery_phone_home: customer?.phoneHome ?? '',
                delivery_email: customer?.email ?? '',


                other_delivery: customer.withCustomAddress ? 1 : 0,
                other_delivery_is_company: customer.customCompanyAddress ? 1 : 0,
                other_delivery_company_name: customer.customCompanyName ?? '',
                other_delivery_company_tax_id: customer.customTaxId ?? '',
                other_delivery_civility: customer?.customTitle ? Number(customer?.customTitle?.value) : null,
                other_delivery_firstname: customer?.customName ?? '',
                other_delivery_lastname: customer?.customSurname ?? '',
                other_delivery_zipcode: customer?.customPostcode ?? '',
                other_delivery_city: customer?.customCity ?? '',
                other_delivery_address1: customer?.customAddress ?? '',
                other_delivery_address2: customer?.customAddress2 ?? '',
                other_delivery_region: store.customer.customRegion?.value ?? null,
                other_delivery_country: customer?.customCountry?.value ?? '',
                other_delivery_phone: customer?.customPhone ?? '',
                other_delivery_phone_mobile: customer?.customPhoneMobile ?? '',
                other_delivery_phone_home: customer?.customPhoneHome ?? '',
                other_delivery_email: customer?.customEmail ?? '',

                marketing_email: customer.benefits?.email ? 1 : 0,
                marketing_mobile: customer.benefits?.mobile ? 1 : 0,
                marketing_phone: customer.benefits?.phone ? 1 : 0,
                marketing_post: customer.benefits?.post ? 1 : 0,

                regulations: customer?.termsAndConditions ? 1 : 0,
                privacy_policy: customer?.privacyPolicy ? 1 : 0,

                id_payment_method: customer?.paymentMethod?.id_payment_method ?? null,
                payment_method_id_nestle: customer?.paymentMethod?.id_nestle ?? '',

                // member_number: store.nespressoMember?.member_id ?? '',
                // member_token: store.nespressoMember?.token ?? '',
                // member_password: customer.password ?? '',0,

                payment_bsb_number: customer?.pdBsbNumber,
                payment_account_number: customer?.pdAccountNumber,
                payment_data_confirmed: customer?.pdDataConfirmed ? 1 : 0,

                webaccount_exist: store.nespressoMember ? 1 : 0,

                can_register_status: store.canRegisterResponse,

                id_shop: store.shop,

                subscription_machine_token: store.verificationCodeToken,
            },
            cart: store.cart,
        };


        if (store.user) {
            // formData.customer.webaccount_exist = 0;
            // if (store.nespressoMember === null) {
            //     formData.customer.webaccount_exist = 1;
            // }
            if (store.nespressoMemberExist) {
                formData.customer.webaccount_exist = 1;
            }

            formData.customer.reseller_firstname = store.firstname ?? null;
            formData.customer.reseller_lastname = store.lastname ?? null;
        }


        if (store.canRegisterResponse?.status === 'MEMBER_EXISTS') {
            formData.customer.member_number = store.canRegisterResponse?.memberNumber;
        }


        if (store.canRegisterResponse?.status === 'ACCOUNT_EXISTS' && store.canRegisterResponse?.memberNumber) {
            formData.customer.member_number = store.canRegisterResponse?.memberNumber;
            formData.customer.webaccount_exist = 1;
        }


        setSending(true);
        await resellerActions.saveSubscription(formData).then(async r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    setLinkSentModal(true);
                }
            } else {
                if (res.messages?.length) {
                    setErrorLog(JSON.stringify(res.messages))

                    if (res.messages.length > 1) {
                        setError('order_save_error')
                    } else {
                        setError(res.messages[0]);
                    }
                } else {
                    setError(res?.data?.message ?? t('error.unknown_error'))
                    console.error('Something gone wrong! ', res)
                }
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if (MOUNTED) {
                setSending(false);
            }
        })
    }


    const handleSendErrors = () => {
        // todo: implement functionality
    }


    const handleChangePaymentMethod = async (data) => {
        const customer = {
            ...store.customer,
            paymentMethod: data.type ?? store.customer.paymentMethod,
            accountNumber: data.accountNumber,
        }
        const bank = data.bank;

        dispatch(rmsReducer.setCustomer(customer))
        dispatch(rmsReducer.setBank(bank))

        await handleSubmit(customer, bank)
    }


    return (
        <>
            <SummaryMS
                error={error}
                errorLog={errorLog}
                sending={sending}
                customer={store.customer}
                plan={plan}
                onSubmit={() => handleSubmit()}
                onChangePaymentMethod={handleChangePaymentMethod}
            />
            <LinkSentModal
                visible={linkSentModal}
                onClose={() => {
                    dispatch(rmsReducer.clearStore())
                    setLinkSentModal(false);
                    history.push(navigation(Routes.RESELLER_MACHINE_SUBSCRIPTIONS));
                }}
            />
        </>
    )
}

