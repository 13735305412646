import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import DeliveryPaymentStep from "../../components/ev/DeliveryPaymentStep";
import * as evReducer from '../../redux/evReducer';
import {useMarketConfig, useNavigation, useTranslation} from "../../misc/Hooks";
import {Routes} from "../../utils/_index";
import {LinkSentModal} from "../../components/modals/_index";
import {GTM, Preloader} from "../../misc/_index";
import * as OrderService from '../../services/OrderService'
import * as Helpers from "../../misc/Helpers";
import {mapOrderToCustomer} from "../../misc/Helpers";
import {useCivilityOptions, useOrderData, useStateObject} from "../../hooks";


let MOUNTED = false;

export const DeliveryPaymentStepContainer = (props) => {
    const quotationTimeoutRef = useRef(null)
    const dispatch = useDispatch();
    const store = useSelector(({main, ev}) => ({
        user: main.user,
        cart: ev.cart,
        categories: ev.categories,
        products: ev.products,
        customer: ev.customer,
        promotion: ev.promotion,
        paymentMethod: ev.paymentMethod,
        order: ev.order,
        cartQuotationData: ev.cartQuotationData,
    }))
    const navigation = useNavigation();
    const history = useHistory();
    const location = useLocation()
    const t = useTranslation();
    const marketConfig = useMarketConfig()
    const [linkSentModal, setLinkSentModal] = useState(false);
    const [memberCredit, setMemberCredit] = useState(null);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const orderUuid = useMemo(() => {
        if (!location.search)
            return null
        return new URLSearchParams(location.search).get('uuid')
    }, [location])
    const [savedOrder, setSavedOrder] = useState({loading: !!orderUuid, order: null})
    const civilityOptions = useCivilityOptions()
    const [paymentMethods, setPaymentMethods] = useState(store.promotion?.payment_methods ?? []);
    const [cartQuotationState, setCartQuotationState] = useState({loading: false});

    const orderData = useOrderData('ev')
    const [state, setState] = useStateObject({
        loading: false,
        error: null,
        errorLog: null,
    })

    useEffect(() => {
        if (!orderUuid && !store.user && store.cartQuotationData) {
            clearTimeout(quotationTimeoutRef.current)
            quotationTimeoutRef.current = setTimeout(() => {
                cartQuotation()
            }, 850)
        }
    }, [store.cart])

    useEffect(() => {
        MOUNTED = true;
        document.title = t('ev_delivery_payment.title');
        GTM.showPage(t('ev_delivery_payment.title'))

        if (orderUuid) {
            getOrder(orderUuid)
        } else {
            if (!store.cartQuotationData && !store.user) {
                cartQuotation()
            }
        }

        return () => {
            MOUNTED = false;
        }
    }, [])

    const cartQuotation = async () => {
        setCartQuotationState({loading: true})
        setState({error: null, errorLog: null})

        const nonPersistentQuotationPayment = store.promotion.payment_methods.find(p => p.payment_method_integration_type === 'quotation_non_cc')

        if(nonPersistentQuotationPayment) {
            const data = {
                customer: orderData.getData(),
                cart: store.cart,
            };

            const res = await OrderService.cartQuotation(data)
                .catch(e => {
                    setState({error: e.message || 'invalid_order', errorLog: JSON.stringify(e.data)})
                })
            if (res) {
                // TODO: filtrowanie należy przeerobić tak, zeby porównywało jedynie pole api_payment_method, a w przypadku pustości pola - przepuszcza dalej
                //const paymentMethods = store.promotion.payment_methods.filter(p => res.eligibleInformation?.allowedPaymentModes.find(ap => ap.paymentMode === p.id_nestle))
                //setPaymentMethods(paymentMethods)
                setPaymentMethods(store.promotion.payment_methods)
                setMemberCredit(res.creditData)
                dispatch(evReducer.setCartQuotationData(res.data))
            }
        } else {
            const customer = orderData.getData()
            const data = {
                customer,
                address_id: customer.address_id,
                items: orderData.memberCreditCart(),
            };

            const res = await OrderService.memberCredit(data)
                .catch(e => {
                    setState({error: e.message || 'invalid_order', errorLog: JSON.stringify(e.data)})
                })
            if(res) {
                setMemberCredit(res.creditData)
            }
        }

        setCartQuotationState({loading: false})
    }

    const handleChangePaymentMethod = (data) => {
        if (!state.loading) {
            dispatch(evReducer.setPaymentMethod(data.type))
        }
    }

    const handleSubmit = async () => {
        setState({loading: true, error: null, errorLog: null})

        const payload = {
            customer: orderData.getData(),
            cart: store.cart,
            recaptcha_token: recaptchaToken,
            ...orderData.getCartQuotationData(),
        };

        let savedOrder = null
        if (store.user) {
            savedOrder = await OrderService.createOrderReseller(payload)
                .catch(err => {
                    setState({error: 'order_save_error', errorLog: JSON.stringify(err.data)})
                })
        } else {
            savedOrder = await OrderService.createOrder(payload)
                .catch(err => {
                    setState({error: 'order_save_error', errorLog: JSON.stringify(err.data)})
                })
        }

        if (savedOrder) {
            if (savedOrder.error) {
                if(orderData.error === 'order_exists') {
                    history.replace({search: `?uuid=${payload.customer.uuid}`})
                    await getOrder(payload.customer.uuid)
                } else {
                    setState({errorLog: JSON.stringify(savedOrder.errorLog)})
                }
            } else {
                const order = savedOrder.data

                history.replace({search: `?uuid=${order.order.uuid}`})

                if (store.user) {
                    setLinkSentModal(true)
                } else {
                    await getOrder(order.order.uuid)
                }
            }
        }

        setState({loading: false})
    }

    const getOrder = async (uuid) => {
        await Helpers.getOrder(uuid)
            .then(async data => {
                if (!data.order) {
                    history.push(navigation(Routes.SHOP))
                    return
                }

                let order = {...data.order}

                setSavedOrder({...data})
                setState(prev => ({...prev, loading: true}))
                dispatch(evReducer.clearStore())

                if(!order.quotation_response) {
                    const quotationData = await OrderService.makeQuotation(order.id_order)
                        .catch(err => {
                            setState({error: 'order_save_error', errorLog: JSON.stringify(err.data)})
                        })

                    order = quotationData?.order

                    if (order) {
                        // setSavedOrder({...data, order})
                        await getOrder(uuid)
                        return
                    } else {
                        setState({error: 'order_save_error', errorLog: JSON.stringify(order)})
                    }
                }

                if(order) {
                    setSavedOrder({...data})
                    setState(prev => ({...prev, loading: false}))

                    switch (order.quotation_response) {
                        case 'TYP':
                            history.push(navigation(Routes.ORDER_FINALIZED, order.uuid))
                            break
                        case 'HOP':
                            window.location.href = order.hop_link
                            break
                        case 'PAYMENT':
                            if (order.is_paid) {
                                history.push(navigation(Routes.ORDER_FINALIZED, order.uuid))
                            }
                    }
                }
            })
    }

    const handleSendErrors = () => {
        // todo: implement functionality
    }

    return (
        <>
            {savedOrder.loading || (cartQuotationState.loading && !store.cartQuotationData) ? (
                <Preloader/>
            ) : (
                <DeliveryPaymentStep
                    error={state.error}
                    errorLog={state.errorLog}
                    loading={state.loading}
                    memberCredit={memberCredit}
                    recaptchaToken={recaptchaToken}
                    cartQuotationState={cartQuotationState}
                    user={store.user}
                    cart={savedOrder.order ? savedOrder.cart : store.cart}
                    categories={savedOrder.order ? savedOrder.categories : store.categories}
                    products={savedOrder.order ? savedOrder.products : store.products}
                    customer={savedOrder.order ? mapOrderToCustomer(savedOrder.order, civilityOptions) : store.customer}
                    paymentMethod={savedOrder.order ? {payment_method_title: savedOrder.order.payment_method_title} : store.paymentMethod}
                    order={savedOrder.order}
                    promotion={savedOrder.order ? savedOrder.promotion : store.promotion}
                    cartQuotationData={savedOrder.order ? savedOrder.quotationData : store.cartQuotationData}
                    paymentMethods={paymentMethods}
                    onChangeRecaptchaToken={token => setRecaptchaToken(token)}
                    onChangePaymentMethod={handleChangePaymentMethod}
                    onSendErrors={handleSendErrors}
                    onSubmit={handleSubmit}
                />
            )}


            <LinkSentModal
                visible={linkSentModal}
                onClose={() => {
                    dispatch(evReducer.clearStore())
                    setLinkSentModal(false);
                    history.push(navigation(Routes.RESELLER_ASSISTED_REGISTRATION));
                }}
            />
        </>
    );
}
