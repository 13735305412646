import React, {useEffect, useState} from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyAsyncSelect, MyCheckbox, MyInput, MyInputMask, MyRadio, MySelect, RecaptchaV2} from "./inputs/_index";
import Patterns from "../../utils/Patterns";
import {Button} from "../buttons/_index";
import {getFieldFormat, regexFromString, scrollVertically} from "../../misc/Helpers";
import {
    useCountryConfig,
    useLocalizeFile,
    useLocalizeObject,
    useMarketConfig,
    useRmsStepNavigation,
    useTranslation
} from "../../misc/Hooks";
import {Link} from "react-router-dom";
import {BenefitsInfo, TermsInfo} from "../misc/_index";
import {useSelector} from "react-redux";
import {FormFragmentBenefits} from "./fragments";
import {useCivilityOptions, useCountryOptions, useLanguages, useStackOptions} from "../../hooks";
import * as MainActions from "../../actions/MainActions";
import {isEnabledByMarket} from "../../utils/MarketConfig";



export default (props) => {
    const t = useTranslation();
    const localizeFile = useLocalizeFile();
    const localizeObject = useLocalizeObject();
    const countryConfig = useCountryConfig();
    const marketConfig = useMarketConfig();
    const stepNavigation = useRmsStepNavigation();
    const countryOptions = useCountryOptions()
    const civilityOptions = useCivilityOptions();
    const professionOptions = useStackOptions('professions')
    const regionOptions = useStackOptions('regions')
    const languages = useLanguages();
    const store = useSelector(({main, rms}) => ({
        civilities: main.civilities,
        country: main.country,
        verificationCodeToken: rms.verificationCodeToken,
    }))

    const [citiesState, setCitiesState] = useState({loading: false, data: []})
    const [customCitiesState, setCustomCitiesState] = useState({loading: false, data: []})


    //const langOptions = Env.LANGUAGES.map(l => ({label: t(`language.${l}`), value: l}))

    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            companyAddress: !!props.customer?.companyAddress,
            companyName: props.customer?.companyName ?? '',
            taxId: props.customer?.taxId ?? '',
            profession: props.customer?.profession ? professionOptions.find(props.customer.profession.value) : null,
            title: props.customer?.title ? civilityOptions.find(props.customer.title.value) : null,
            name: props.customer?.name ?? '',
            surname: props.customer?.surname ?? '',
            postcode: props.customer?.postcode ?? '',
            city: props.customer?.city ?? '',
            address: props.customer?.address ?? '',
            address2: props.customer?.address2 ?? '',
            region: props.customer?.region ? regionOptions.find(props.customer.region.value) : null,
            lang: props.customer?.lang ? countryOptions.findLanguage(props.customer.lang.value) : isEnabledByMarket("selectDefaultFirstCountry") ? countryOptions.countryOptions[0] : null,
            country: props.customer?.country ? countryOptions.findCountry(props.customer.country.value) : isEnabledByMarket("selectDefaultLang") ? countryOptions.findLanguage(languages.defaultLang) : null,
            phone: props.customer?.phone ?? '',
            phoneMobile: props.customer?.phoneMobile ?? '',
            phoneHome: props.customer?.phoneHome ?? '',
            email: props.customer?.email ?? '',
            reEmail: props.customer?.reEmail ?? '',


            withCustomAddress: !!props.customer?.withCustomAddress,
            customCompanyAddress: !!props.customer?.customCompanyAddress,
            customCompanyName: props.customer?.customCompanyName ?? '',
            customTaxId: props.customer?.customTaxId ?? '',
            customTitle: props.customer?.customTitle ? civilityOptions.find(props.customer.customTitle.value) : null,
            customName: props.customer?.customName ?? '',
            customSurname: props.customer?.customSurname ?? '',
            customPostcode: props.customer?.customPostcode ?? '',
            customCity: props.customer?.customCity ?? '',
            customAddress: props.customer?.customAddress ?? '',
            customAddress2: props.customer?.customAddress2 ?? '',
            customRegion: props.customer?.customRegion ? regionOptions.find(props.customer.customRegion.value) : null,
            customPhone: props.customer?.customPhone ?? '',
            customPhoneMobile: props.customer?.customPhoneMobile ?? '',
            customPhoneHome: props.customer?.customPhoneHome ?? '',

            benefitsEmail: !!props.customer?.benefitsEmail,
            benefitsPhone: !!props.customer?.benefitsPhone,
            benefits: {
                email: [3,4].includes(props.plan.product_plan_marketing_email_visible),
                mobile: [3,4].includes(props.plan.product_plan_marketing_mobile_visible),
                phone: [3,4].includes(props.plan.product_plan_marketing_phone_visible),
                post: [3,4].includes(props.plan.product_plan_marketing_post_visible),
                ...props.customer?.benefits
            },

            termsAndConditions: !!props.customer?.termsAndConditions,
            privacyPolicy: !!props.customer?.privacyPolicy,

            paymentMethod: props.customer?.paymentMethod ?? null,

            accountNumber: props.customer?.accountNumber ?? '',
            bank: props.customer?.bank ?? null,
            verificationCode: '',

            recaptchaToken: '',
        }
    });
    const companyAddress = formMethods.watch('companyAddress');
    const withCustomAddress = formMethods.watch('withCustomAddress');
    const customCompanyAddress = formMethods.watch('customCompanyAddress');
    const paymentMethod = formMethods.watch('paymentMethod');
    const accountNumber = formMethods.watch('accountNumber');
    const postcode = formMethods.watch('postcode');
    const customPostcode = formMethods.watch('customPostcode');


    const ACCOUNT_NUMBER_MASK = {
        pattern: regexFromString(countryConfig.custom_format?.custom_bank_account_format?.pattern) ?? /\+48 [29][0-9]{2} [0-9]{3} [0-9]{3}/,
        inputMask: countryConfig.custom_format?.custom_bank_account_format?.mask ?? '+48 999 999 999',
    }

    const fieldsConfigs = {
        taxId: getFieldFormat(countryConfig.custom_format?.custom_tax_id_format),
        companyTaxId: getFieldFormat(countryConfig.custom_format?.custom_company_tax_id_format),
        name: getFieldFormat(countryConfig.custom_format?.custom_firstname_format),
        surname: getFieldFormat(countryConfig.custom_format?.custom_lastname_format),
        postcode: getFieldFormat(countryConfig.custom_format?.custom_postcode_format),
        city: getFieldFormat(countryConfig.custom_format?.custom_city_format),
        address: getFieldFormat(countryConfig.custom_format?.custom_address1_format),
        address2: getFieldFormat(countryConfig.custom_format?.custom_address2_format),
        phone: getFieldFormat(countryConfig.custom_format?.custom_phone_format),
        phoneMobile: getFieldFormat(countryConfig.custom_format?.custom_phone_mobile_format),
        phoneHome: getFieldFormat(countryConfig.custom_format?.custom_phone_home_format),
    }

    const rules = {
        companyAddress: {},
        companyName: {
            required: {value: !!companyAddress, message: t('validation.required')},
        },
        taxId: {
            required: {value: !!fieldsConfigs.taxId.reseller_required, message: t('validation.required')},
            pattern: {value: fieldsConfigs.taxId.pattern, message: t('validation.invalid_tax_id') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.taxId.max_length,
                message: t('validation.max_length', fieldsConfigs.taxId.max_length)
            }
        },
        companyTaxId: {
            required: {value: !!fieldsConfigs.companyTaxId.reseller_required, message: t('validation.required')},
            pattern: {value: fieldsConfigs.companyTaxId.pattern, message: t('validation.invalid_tax_id') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.companyTaxId.max_length,
                message: t('validation.max_length', fieldsConfigs.companyTaxId.max_length)
            }
        },
        profession: {
            required: {value: true, message: t('validation.required')}
        },
        title: {
            required: {value: !companyAddress, message: t('validation.required')},
        },
        name: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.name.pattern ?? Patterns.onlyText, message: t('validation.invalid_name') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.name.max_length,
                message: t('validation.max_length', fieldsConfigs.name.max_length)
            },
        },
        surname: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.surname.pattern ?? Patterns.onlyText, message: t('validation.invalid_surname') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.surname.max_length,
                message: t('validation.max_length', fieldsConfigs.surname.max_length)
            }
        },
        postcode: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.postcode.pattern, message: t('validation.invalid_postcode') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.postcode.max_length,
                message: t('validation.max_length', fieldsConfigs.postcode.max_length)
            }
        },
        city: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.city.pattern, message: t('validation.invalid_city') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.city.max_length,
                message: t('validation.max_length', fieldsConfigs.city.max_length)
            },
        },
        address: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.address.pattern ?? Patterns.address, message: t('validation.invalid_address') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.address.max_length,
                message: t('validation.max_length', fieldsConfigs.address.max_length)
            },
        },
        address2: {
            required: {value: fieldsConfigs.address2.required, message: t('validation.required')},
            pattern: {value: fieldsConfigs.address2.pattern, message: t('validation.invalid_address') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.address2.max_length,
                message: t('validation.max_length', fieldsConfigs.address2.max_length)
            },
        },
        region: {
            required: {value: true, message: t('validation.required')}
        },
        country: {
            required: {value: true, message: t('validation.required')}
        },
        lang: {
            required: {value: true, message: t('validation.required')}
        },
        phone: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.phone.pattern, message: t('validation.invalid_phone') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.phone.max_length,
                message: t('validation.max_length', fieldsConfigs.phone.max_length)
            }
        },
        phoneMobile: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.phoneMobile.pattern, message: t('validation.invalid_phone_mobile') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.phoneMobile.max_length,
                message: t('validation.max_length', fieldsConfigs.phoneMobile.max_length)
            }
        },
        phoneHome: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: fieldsConfigs.phoneHome.pattern, message: t('validation.invalid_phone_home') || t('validation.invalid')},
            maxLength: {
                value: fieldsConfigs.phoneHome.max_length,
                message: t('validation.max_length', fieldsConfigs.phoneHome.max_length)
            }
        },
        email: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: Patterns.email, message: t('validation.invalid_email') || t('validation.invalid')},
            maxLength: {
                value: 50,
                message: t('validation.max_length', 50),
            }
        },
        reEmail: {
            required: {value: true, message: t('validation.required')},
            validate: v => v === formMethods.watch('email') ? true : t('validation.same_as', t('email')),
            maxLength: {
                value: 50,
                message: t('validation.max_length', 50),
            }
        },

        customAddress: {},
        customCompanyName: {
            required: {value: !!customCompanyAddress, message: t('validation.required')},
        },
        customTitle: {
            required: {value: !customCompanyAddress, message: t('validation.required')},
        },

        benefitsEmail: {},
        benefitsPhone: {},
        termsAndConditions: {
            required: {value: true, message: t('validation.are_required', t('terms_and_conditions'))}
        },
        privacyPolicy: {
            required: {value: true, message: t('validation.is_required', t('privacy_policy'))}
        },
        paymentMethod: {
            required: {value: true, message: t('validation.required')},
        },

        accountNumber: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: ACCOUNT_NUMBER_MASK.pattern, message: t('validation.invalid')},
        },
        bank: {required: {value: true, message: t('validation.required')}},
        verificationCode: {required: {value: true, message: t('validation.required')}},

        recaptchaToken: {
            required: {value: true, message: t('validation.required')}
        },
    }


    const termsFileUrl = localizeFile(props.plan?.product_files, 'regulations');
    const privacyFileUrl = localizeFile(props.plan?.product_files, 'privacy_policy')
        ?? localizeFile(marketConfig.files, 'privacy_policy');

    useEffect(async () => {
        if(marketConfig.post_code_automatic_city) {
            if(postcode?.toString().match(fieldsConfigs.postcode.pattern)) {
                setCitiesState(prev => ({...prev, loading: true}))
                const cities = await getCities(postcode)
                if(cities.length) {
                    setCitiesState({loading: false, data: cities})
                } else {
                    setCitiesState(prev => ({...prev, loading: false}))
                    formMethods.setError('postcode', {type: 'invalid', message: t('validation.invalid_postcode') || t('validation.invalid')})
                }
            } else {
                formMethods.setValue('city', '')
                setCitiesState({loading: false, data: []})
            }
        }
    }, [postcode])

    useEffect(async () => {
        if(marketConfig.post_code_automatic_city) {
            if(customPostcode?.toString().match(fieldsConfigs.postcode.pattern)) {
                setCustomCitiesState(prev => ({...prev, loading: true}))
                const cities = await getCities(customPostcode)
                if(cities.length) {
                    setCustomCitiesState({loading: false, data: cities})
                } else {
                    setCustomCitiesState(prev => ({...prev, loading: false}))
                    formMethods.setError('customPostcode', {type: 'invalid', message: t('validation.invalid_postcode') || t('validation.invalid')})
                }
            } else {
                formMethods.setValue('customCity', '')
                setCustomCitiesState({loading: false, data: []})
            }
        }
    }, [customPostcode])
    useEffect(() => {
        switch (props.error) {
            case 'phone_to_many_times_used':
                formMethods.setError('phone', {type: 'phone_to_many_times_used', message: t('error.phone_to_many_times_used')})
                window.scrollTo({top: 0, behavior: 'smooth'})
                break;
        }
    }, [props.error])

    useEffect(() => {
        if(props.customer?.title) {
            formMethods.setValue('title', civilityOptions.find(props.customer.title.value))
        }
        if(props.customer?.customTitle) {
            formMethods.setValue('customTitle', civilityOptions.find(props.customer.customTitle.value))
        }
    }, [store.civilities])

    useEffect(() => {
        if(props.customer?.country) {
            formMethods.setValue('country', countryOptions.findCountry(props.customer.country.value))
        }
    }, [store.country])

    useEffect(() => {
        if(formMethods.formState.submitCount && Object.keys(formMethods.formState.errors).length) {
            const el = document.querySelector('#customerDetailsForm .formGroup.-invalid');
            if(el) {
                scrollVertically(el, 15, 'smooth');
            }
        }
    }, [formMethods.formState.submitCount])


    useEffect(() => {
        if(props.verificationCodeError) {
            formMethods.setError('verificationCode', {type: 'validate', message: t(`error.${props.verificationCodeError}`)})
        }
        else {
            formMethods.clearErrors('verificationCode')
        }
    }, [props.verificationCodeError])


    useEffect(() => {
        if(props.bankError) {
            formMethods.setError('accountNumber', {type: 'invalid', message: props.bankError});
        } else {
            formMethods.clearErrors('accountNumber');
        }
    }, [props.bankError])

    const getCities = async (postcode) => {
        return await MainActions.getCitiesByPostcode(postcode)
          .then(r => {
              const res = r.data
              if(res.status === 'success') {
                  return res.data.map(c => ({
                      label: localizeObject(c.city_title),
                      value: localizeObject(c.city_title),
                  }))
              } else {
                  return []
              }
          })
          .catch(e => [])
    }

    const handleChangePaymentType = (data) => {
        formMethods.setValue('paymentMethod', data);

        if(data) {
            formMethods.clearErrors('paymentMethod')
        }
    }


    const handleChangeAccountNumber = (data) => {
        const value = data?.target?.value;
        formMethods.setValue('accountNumber', value);
        if(value?.match(ACCOUNT_NUMBER_MASK.pattern)) {
            props.onChangeAccountNumber(value);
        }
    }

    const onClickResendCode = () => {
        const email = formMethods.getValues('email')
        props.onResendCode(email)
    }

    const onSubmit = (data) => {
        if(data.paymentMethod.payment_method_need_code_verify && !data.bank && !props.bank) {
            formMethods.setError('accountNumber', {type: 'invalid', message: t('validation.invalid')})
        } else {
            props.onSubmit(data);
        }
    }

    return (
        <FormProvider {...formMethods}>
            <form id="customerDetailsForm" onSubmit={formMethods.handleSubmit(onSubmit)}>


                <div className="--mb4">
                    <Controller
                        name="companyAddress"
                        control={formMethods.control}
                        rules={rules.companyAddress}
                        render={({onChange, value}) => (
                            <div className="--mb2">
                                <MyCheckbox
                                    name="companyAddress"
                                    label={t('company_address')}
                                    value={value}
                                    error={formMethods.errors.companyAddress}
                                    onChange={onChange}
                                />
                            </div>
                        )}
                    />


                    <div hidden={!companyAddress}>
                        <Controller
                            name="companyName"
                            control={formMethods.control}
                            rules={rules.companyName}
                            render={({onChange, value}) => (
                                <MyInput
                                    name="companyName"
                                    label={t('company_name')}
                                    value={value}
                                    error={formMethods.errors.companyName}
                                    onChange={onChange}
                                />
                            )}
                        />
                        {isEnabledByMarket('profession') && companyAddress && (
                            <Controller
                                name="profession"
                                control={formMethods.control}
                                rules={rules.profession}
                                render={({onChange, value}) => (
                                    <MyAsyncSelect
                                        name="profession"
                                        label={t('profession')}
                                        value={value}
                                        // options={professionOptions.options}
                                        loadOptions={professionOptions.startsWith}
                                        error={formMethods.errors.profession}
                                        onChange={onChange}
                                        isSearchable
                                    />
                                )}
                            />
                        )}
                    </div>

                    <div hidden={companyAddress}>
                        <Controller
                            name="title"
                            control={formMethods.control}
                            rules={rules.title}
                            render={({onChange, value}) => (
                                <MySelect
                                    name="title"
                                    label={t('title')}
                                    value={value}
                                    options={civilityOptions.options}
                                    // nullable={true}
                                    error={formMethods.errors.title}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </div>

                    {!!fieldsConfigs.taxId.status && !companyAddress && (
                        <Controller
                            name="taxId"
                            control={formMethods.control}
                            rules={rules.taxId}
                            render={({onChange, value}) => fieldsConfigs.taxId.mask_status ? (
                                <MyInputMask
                                    name="taxId"
                                    label={t('tax_id')}
                                    mask={fieldsConfigs.taxId.inputMask}
                                    value={value}
                                    error={formMethods.errors.taxId}
                                    onChange={onChange}
                                />
                            ) : (
                                <MyInput
                                    name="taxId"
                                    label={t('tax_id')}
                                    value={value}
                                    error={formMethods.errors.taxId}
                                    onChange={onChange}
                                    maxLength={rules.taxId.maxLength?.value}
                                />
                            )}
                        />
                    )}
                    {!!fieldsConfigs.companyTaxId.status && companyAddress && (
                        <Controller
                            name="taxId"
                            control={formMethods.control}
                            rules={rules.companyTaxId}
                            render={({onChange, value}) => fieldsConfigs.companyTaxId.mask_status ? (
                                <MyInputMask
                                    name="taxId"
                                    label={t('company_tax_id') || t('tax_id')}
                                    mask={fieldsConfigs.companyTaxId.inputMask}
                                    value={value}
                                    error={formMethods.errors.taxId}
                                    onChange={onChange}
                                />
                            ) : (
                                <MyInput
                                    name="taxId"
                                    label={t('company_tax_id') || t('tax_id')}
                                    value={value}
                                    error={formMethods.errors.taxId}
                                    onChange={onChange}
                                    maxLength={rules.companyTaxId.maxLength?.value}
                                />
                            )}
                        />
                    )}


                    <div className="gridSm">
                        <div className="col">
                            <Controller
                                name="surname"
                                control={formMethods.control}
                                rules={rules.surname}
                                render={({onChange, value}) => (
                                    <MyInput
                                        name="surname"
                                        label={t('surname')}
                                        value={value}
                                        error={formMethods.errors.surname}
                                        onChange={onChange}
                                        maxLength={rules.surname.maxLength?.value}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="name"
                                control={formMethods.control}
                                rules={rules.name}
                                render={({onChange, value}) => (
                                    <MyInput
                                        name="name"
                                        label={t('name')}
                                        value={value}
                                        error={formMethods.errors.name}
                                        onChange={onChange}
                                        maxLength={rules.name.maxLength?.value}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="gridSm">
                        <div className="col">
                            <Controller
                                name="postcode"
                                control={formMethods.control}
                                rules={rules.postcode}
                                render={({onChange, value}) => fieldsConfigs.postcode.mask_status ? (
                                    <MyInputMask
                                        name="postcode"
                                        label={t('postcode')}
                                        mask={fieldsConfigs.postcode.inputMask}
                                        value={value}
                                        error={formMethods.errors.postcode}
                                        formGroupClassNames="-ltr"
                                        onChange={onChange}
                                        forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                    />
                                ) : (
                                    <MyInput
                                        name="postcode"
                                        label={t('postcode')}
                                        value={value}
                                        error={formMethods.errors.postcode}
                                        onChange={onChange}
                                        formGroupClassNames="-ltr"
                                        maxLength={rules.postcode.maxLength?.value}
                                        forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="city"
                                control={formMethods.control}
                                rules={rules.city}
                                render={({onChange, value}) => marketConfig.post_code_automatic_city ? (
                                  <MySelect
                                    name="city"
                                    label={t('city')}
                                    value={value}
                                    options={citiesState.data}
                                    error={formMethods.errors.city}
                                    onChange={onChange}
                                    maxLength={rules.city.maxLength?.value}
                                    loading={citiesState.loading}
                                    parseValueToString
                                  />
                                ) : (
                                  <MyInput
                                    name="city"
                                    label={t('city')}
                                    value={value}
                                    error={formMethods.errors.city}
                                    onChange={onChange}
                                    maxLength={rules.city.maxLength?.value}
                                  />
                                )}
                            />
                        </div>
                    </div>

                    <Controller
                        name="address"
                        control={formMethods.control}
                        rules={rules.address}
                        render={({onChange, value}) => (
                            <MyInput
                                name="address"
                                label={t('address_line_1')}
                                value={value}
                                error={formMethods.errors.address}
                                onChange={onChange}
                                maxLength={rules.address.maxLength?.value}
                            />
                        )}
                    />

                    {!!fieldsConfigs.address2.status && (
                        <Controller
                            name="address2"
                            control={formMethods.control}
                            rules={rules.address2}
                            render={({onChange, value}) => (
                                <MyInput
                                    name="address2"
                                    label={t('address_line_2')}
                                    value={value}
                                    error={formMethods.errors.address2}
                                    onChange={onChange}
                                    maxLength={rules.address2.maxLength?.value}
                                />
                            )}
                        />
                    )}

                    {isEnabledByMarket('region') && (
                        <Controller
                            name="region"
                            control={formMethods.control}
                            rules={rules.region}
                            render={({onChange, value}) => (
                                <MyAsyncSelect
                                    name="region"
                                    label={t('region')}
                                    value={value}
                                    loadOptions={regionOptions.startsWith}
                                    error={formMethods.errors.region}
                                    onChange={onChange}
                                    isSearchable
                                />
                            )}
                        />
                    )}

                    {!isEnabledByMarket('hideCountryField') && (
                        <Controller
                            name="country"
                            control={formMethods.control}
                            rules={rules.country}
                            render={({onChange, value}) => (
                                <MySelect
                                    name="country"
                                    label={t('country')}
                                    value={value}
                                    options={countryOptions.countryOptions}
                                    error={formMethods.errors.country}
                                    onChange={onChange}
                                />
                            )}
                        />
                    )}

                    <Controller
                        name="lang"
                        control={formMethods.control}
                        rules={rules.lang}
                        render={({onChange, value}) => (
                            <MySelect
                                name="lang"
                                label={t('lang')}
                                value={value}
                                options={countryOptions.languageOptions}
                                error={formMethods.errors.lang}
                                onChange={onChange}
                            />
                        )}
                    />

                    <Controller
                        name="phone"
                        control={formMethods.control}
                        rules={rules.phone}
                        render={({onChange, value}) => fieldsConfigs.phone.mask_status ? (
                            <MyInputMask
                                name="phone"
                                label={t('phone')}
                                mask={fieldsConfigs.phone.inputMask}
                                formatChars={fieldsConfigs.phone.formatChars}
                                value={value}
                                error={formMethods.errors.phone}
                                formGroupClassNames="-ltr"
                                onChange={onChange}
                            />
                        ) : (
                            <MyInput
                                name="phone"
                                label={t('phone')}
                                value={value}
                                error={formMethods.errors.phone}
                                onChange={onChange}
                                formGroupClassNames="-ltr"
                                maxLength={rules.phone.maxLength?.value}
                            />
                        )}
                    />

                    {!!fieldsConfigs.phoneMobile.status && (
                        <Controller
                            name="phoneMobile"
                            control={formMethods.control}
                            rules={rules.phoneMobile}
                            render={({onChange, value}) => fieldsConfigs.phoneMobile.mask_status ? (
                                <MyInputMask
                                    name="phoneMobile"
                                    label={t('phone_mobile')}
                                    mask={fieldsConfigs.phoneMobile.inputMask}
                                    formatChars={fieldsConfigs.phoneMobile.formatChars}
                                    value={value}
                                    error={formMethods.errors.phoneMobile}
                                    formGroupClassNames="-ltr"
                                    onChange={onChange}
                                />
                            ) : (
                                <MyInput
                                    name="phoneMobile"
                                    label={t('phone_mobile')}
                                    value={value}
                                    error={formMethods.errors.phoneMobile}
                                    onChange={onChange}
                                    formGroupClassNames="-ltr"
                                    maxLength={rules.phoneMobile.maxLength?.value}
                                />
                            )}
                        />
                    )}

                    {!!fieldsConfigs.phoneHome.status && (
                        <Controller
                            name="phoneHome"
                            control={formMethods.control}
                            rules={rules.phoneHome}
                            render={({onChange, value}) => fieldsConfigs.phoneHome.mask_status ? (
                                <MyInputMask
                                    name="phoneHome"
                                    label={t('phone_home')}
                                    mask={fieldsConfigs.phoneHome.inputMask}
                                    formatChars={fieldsConfigs.phoneHome.formatChars}
                                    value={value}
                                    error={formMethods.errors.phoneHome}
                                    formGroupClassNames="-ltr"
                                    onChange={onChange}
                                />
                            ) : (
                                <MyInput
                                    name="phoneHome"
                                    label={t('phone_home')}
                                    value={value}
                                    error={formMethods.errors.phoneHome}
                                    onChange={onChange}
                                    formGroupClassNames="-ltr"
                                    maxLength={rules.phoneHome.maxLength?.value}
                                />
                            )}
                        />
                    )}


                    <div className="gridSm">
                        <div className="col">
                            <Controller
                                name="email"
                                control={formMethods.control}
                                rules={rules.email}
                                render={({onChange, value}) => (
                                    <MyInput
                                        name="email"
                                        label={t('email')}
                                        value={value}
                                        error={formMethods.errors.email}
                                        onChange={onChange}
                                        inputProps={{
                                            onCopy: e => countryConfig.custom_format?.custom_email_format && !Number(countryConfig.custom_format.custom_email_format.copy) ? e.preventDefault() : undefined,
                                            onPaste: e => countryConfig.custom_format?.custom_email_format && !Number(countryConfig.custom_format.custom_email_format.past) ? e.preventDefault() : undefined,
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="reEmail"
                                control={formMethods.control}
                                rules={rules.reEmail}
                                render={({onChange, value}) => (
                                    <MyInput
                                        name="reEmail"
                                        label={t('re_email')}
                                        value={value}
                                        error={formMethods.errors.reEmail}
                                        onChange={onChange}
                                        inputProps={{
                                            onCopy: e => countryConfig.custom_format?.custom_re_email_format && !Number(countryConfig.custom_format.custom_re_email_format.copy) ? e.preventDefault() : undefined,
                                            onPaste: e => countryConfig.custom_format?.custom_re_email_format && !Number(countryConfig.custom_format.custom_re_email_format.past) ? e.preventDefault() : undefined,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>


                    {/*<Controller*/}
                    {/*    name="withCustomAddress"*/}
                    {/*    control={formMethods.control}*/}
                    {/*    rules={rules.withCustomAddress}*/}
                    {/*    render={({onChange, value}) => (*/}
                    {/*        <div className="--mb2">*/}
                    {/*            <MyCheckbox*/}
                    {/*                name="withCustomAddress"*/}
                    {/*                label={t('deliver_to_another_address')}*/}
                    {/*                value={value}*/}
                    {/*                error={formMethods.errors.withCustomAddress}*/}
                    {/*                onChange={onChange}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*/>*/}



                    {!!withCustomAddress && (
                        <>
                            <Controller
                                name="customCompanyAddress"
                                control={formMethods.control}
                                rules={rules.customCompanyAddress}
                                render={({onChange, value}) => (
                                    <div className="--mb2">
                                        <MyCheckbox
                                            name="customCompanyAddress"
                                            label={t('company_address')}
                                            value={value}
                                            error={formMethods.errors.customCompanyAddress}
                                            onChange={onChange}
                                        />
                                    </div>
                                )}
                            />

                            <div hidden={!customCompanyAddress}>
                                <Controller
                                    name="customCompanyName"
                                    control={formMethods.control}
                                    rules={rules.customCompanyName}
                                    render={({onChange, value}) => (
                                        <MyInput
                                            name="customCompanyName"
                                            label={t('company_name')}
                                            value={value}
                                            error={formMethods.errors.customCompanyName}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>
                            <div hidden={customCompanyAddress}>
                                <Controller
                                    name="customTitle"
                                    control={formMethods.control}
                                    rules={rules.customTitle}
                                    render={({onChange, value}) => (
                                        <MySelect
                                            name="customTitle"
                                            label={t('title')}
                                            value={value}
                                            options={civilityOptions.options}
                                            error={formMethods.errors.customTitle}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>


                            <div className="gridSm">
                                <div className="col">
                                    <Controller
                                        name="customName"
                                        control={formMethods.control}
                                        rules={rules.name}
                                        render={({onChange, value}) => (
                                            <MyInput
                                                name="customName"
                                                label={t('name')}
                                                value={value}
                                                error={formMethods.errors.customName}
                                                onChange={onChange}
                                                maxLength={rules.name.maxLength?.value}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col">
                                    <Controller
                                        name="customSurname"
                                        control={formMethods.control}
                                        rules={rules.surname}
                                        render={({onChange, value}) => (
                                            <MyInput
                                                name="customSurname"
                                                label={t('surname')}
                                                value={value}
                                                error={formMethods.errors.customSurname}
                                                onChange={onChange}
                                                maxLength={rules.surname.maxLength?.value}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="gridSm">
                                <div className="col">
                                    <Controller
                                        name="customPostcode"
                                        control={formMethods.control}
                                        rules={rules.postcode}
                                        render={({onChange, value}) => fieldsConfigs.postcode.mask_status ? (
                                            <MyInputMask
                                                name="customPostcode"
                                                label={t('postcode')}
                                                mask={fieldsConfigs.postcode.inputMask}
                                                value={value}
                                                error={formMethods.errors.customPostcode}
                                                formGroupClassNames="-ltr"
                                                onChange={onChange}
                                                forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                            />
                                        ) : (
                                            <MyInput
                                                name="customPostcode"
                                                label={t('postcode')}
                                                value={value}
                                                error={formMethods.errors.customPostcode}
                                                onChange={onChange}
                                                formGroupClassNames="-ltr"
                                                maxLength={rules.postcode.maxLength?.value}
                                                forceUppercase={isEnabledByMarket("autoUppercasePostCode")}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col">
                                    <Controller
                                        name="customCity"
                                        control={formMethods.control}
                                        rules={rules.city}
                                        render={({onChange, value}) => marketConfig.post_code_automatic_city ? (
                                          <MySelect
                                            name="customCity"
                                            label={t('city')}
                                            value={value}
                                            options={customCitiesState.data}
                                            error={formMethods.errors.customCity}
                                            onChange={onChange}
                                            maxLength={rules.city.maxLength?.value}
                                            loading={customCitiesState.loading}
                                            parseValueToString
                                          />
                                        ) : (
                                          <MyInput
                                            name="customCity"
                                            label={t('city')}
                                            value={value}
                                            error={formMethods.errors.customCity}
                                            onChange={onChange}
                                            maxLength={rules.city.maxLength?.value}
                                          />
                                        )}
                                    />
                                </div>
                            </div>
                            <Controller
                                name="customAddress"
                                control={formMethods.control}
                                rules={rules.address}
                                render={({onChange, value}) => (
                                    <MyInput
                                        name="customAddress"
                                        label={t('address_line_1')}
                                        value={value}
                                        error={formMethods.errors.customAddress}
                                        onChange={onChange}
                                        maxLength={rules.address.maxLength?.value}
                                    />
                                )}
                            />

                            {!!fieldsConfigs.address2.status && (
                                <Controller
                                    name="customAddress2"
                                    control={formMethods.control}
                                    rules={rules.address2}
                                    render={({onChange, value}) => (
                                        <MyInput
                                            name="customAddress2"
                                            label={t('address_line_2')}
                                            value={value}
                                            error={formMethods.errors.customAddress2}
                                            onChange={onChange}
                                            maxLength={rules.address2.maxLength?.value}
                                        />
                                    )}
                                />
                            )}

                            {isEnabledByMarket('region') && (
                                <Controller
                                    name="customRegion"
                                    control={formMethods.control}
                                    rules={rules.customRegion}
                                    render={({onChange, value}) => (
                                        <MyAsyncSelect
                                            name="customRegion"
                                            label={t('region')}
                                            value={value}
                                            loadOptions={regionOptions.startsWith}
                                            error={formMethods.errors.customRegion}
                                            onChange={onChange}
                                            isSearchable
                                        />
                                    )}
                                />
                            )}

                            {!!marketConfig?.other_delivery_phone_enabled && (
                                <>
                                    <Controller
                                        name="customPhone"
                                        control={formMethods.control}
                                        rules={rules.phone}
                                        render={({onChange, value}) => fieldsConfigs.phone.mask_status ? (
                                            <MyInputMask
                                                name="customPhone"
                                                label={t('phone')}
                                                mask={fieldsConfigs.phone.inputMask}
                                                formatChars={fieldsConfigs.phone.formatChars}
                                                value={value}
                                                error={formMethods.errors.customPhone}
                                                formGroupClassNames="-ltr"
                                                onChange={onChange}
                                            />
                                        ) : (
                                            <MyInput
                                                name="customPhone"
                                                label={t('phone')}
                                                value={value}
                                                error={formMethods.errors.customPhone}
                                                onChange={onChange}
                                                formGroupClassNames="-ltr"
                                                maxLength={rules.phone.maxLength?.value}
                                            />
                                        )}
                                    />

                                    {!!fieldsConfigs.phoneMobile.status && (
                                        <Controller
                                            name="customPhoneMobile"
                                            control={formMethods.control}
                                            rules={rules.phoneMobile}
                                            render={({onChange, value}) => fieldsConfigs.phoneMobile.mask_status ? (
                                                <MyInputMask
                                                    name="customPhoneMobile"
                                                    label={t('phone_mobile')}
                                                    mask={fieldsConfigs.phoneMobile.inputMask}
                                                    formatChars={fieldsConfigs.phoneMobile.formatChars}
                                                    value={value}
                                                    error={formMethods.errors.customPhoneMobile}
                                                    formGroupClassNames="-ltr"
                                                    onChange={onChange}
                                                />
                                            ) : (
                                                <MyInput
                                                    name="customPhoneMobile"
                                                    label={t('phone_mobile')}
                                                    value={value}
                                                    error={formMethods.errors.customPhoneMobile}
                                                    onChange={onChange}
                                                    formGroupClassNames="-ltr"
                                                    maxLength={rules.phoneMobile.maxLength?.value}
                                                />
                                            )}
                                        />
                                    )}

                                    {!!fieldsConfigs.phoneHome.status && (
                                        <Controller
                                            name="customPhoneHome"
                                            control={formMethods.control}
                                            rules={rules.phoneHome}
                                            render={({onChange, value}) => fieldsConfigs.phoneHome.mask_status ?(
                                                <MyInputMask
                                                    name="customPhoneHome"
                                                    label={t('phone_home')}
                                                    mask={fieldsConfigs.phoneHome.inputMask}
                                                    formatChars={fieldsConfigs.phoneHome.formatChars}
                                                    value={value}
                                                    error={formMethods.errors.customPhoneHome}
                                                    formGroupClassNames="-ltr"
                                                    onChange={onChange}
                                                />
                                            ) : (
                                                <MyInput
                                                    name="customPhoneHome"
                                                    label={t('phone_home')}
                                                    value={value}
                                                    error={formMethods.errors.customPhoneHome}
                                                    onChange={onChange}
                                                    formGroupClassNames="-ltr"
                                                    maxLength={rules.phoneHome.maxLength?.value}
                                                />
                                            )}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>

                <BenefitsInfo/>


                <div className="--mb4">
                    <FormFragmentBenefits
                        config={{
                            email: props.plan.product_plan_marketing_email_visible,
                            mobile: props.plan.product_plan_marketing_mobile_visible,
                            phone: props.plan.product_plan_marketing_phone_visible,
                            post: props.plan.product_plan_marketing_post_visible,
                            required: props.plan.product_plan_marketing_required,
                            selectAllEnabled: props.plan.product_plan_marketing_select_all_benefits_enabled,
                        }}
                    />
                </div>


                <TermsInfo/>


                <div className="--mb4">
                    <Controller
                        name="termsAndConditions"
                        control={formMethods.control}
                        rules={rules.termsAndConditions}
                        render={({onChange, value}) => (
                            <MyCheckbox
                                name="termsAndConditions"
                                value={value}
                                error={formMethods.errors.termsAndConditions}
                                onChange={onChange}
                            >
                            <span
                                className="--bold"
                                dangerouslySetInnerHTML={{__html: t('terms_and_conditions_reseller_confirmation', termsFileUrl ?? t('terms_and_conditions_url'))}}
                            />
                            </MyCheckbox>
                        )}
                    />

                    <Controller
                        name="privacyPolicy"
                        control={formMethods.control}
                        rules={rules.privacyPolicy}
                        render={({onChange, value}) => (
                            <MyCheckbox
                                name="privacyPolicy"
                                value={value}
                                error={formMethods.errors.privacyPolicy}
                                onChange={onChange}
                            >
                            <span
                                className="--bold"
                                dangerouslySetInnerHTML={{__html: t('privacy_policy_reseller_confirmation', privacyFileUrl ?? t('privacy_policy_url'))}}
                            />
                            </MyCheckbox>
                        )}
                    />
                </div>



                <div className="--mb4">
                    <hr className="-light --mb3"/>
                    <h3 className="par-1">{t('choose_payment_type')}</h3>
                    <Controller
                        name="paymentMethod"
                        control={formMethods.control}
                        rules={rules.paymentMethod}
                        render={({onChange, value}) => (
                            <>
                                {props.plan?.payment_methods?.map((type, idx) => (
                                    <MyRadio
                                        key={idx}
                                        name="paymentMethod"
                                        value={type}
                                        label={localizeObject(type.payment_method_title)}
                                        selected={value}
                                        error={formMethods.errors.paymentMethod}
                                        onChange={handleChangePaymentType}
                                    />
                                ))}
                            </>
                        )}
                    />
                </div>


                {!!paymentMethod?.payment_method_need_code_verify && (
                    <div className="--mb4">

                        <Controller
                            name="accountNumber"
                            control={formMethods.control}
                            rules={rules.accountNumber}
                            render={({onChange, value}) => Number(countryConfig.custom_format?.custom_bank_account_format?.mask_status) ? (
                                <MyInputMask
                                    name="accountNumber"
                                    label={t('r_ms_customer.account_number')}
                                    mask={ACCOUNT_NUMBER_MASK.inputMask}
                                    formatChars={ACCOUNT_NUMBER_MASK.formatChars}
                                    value={value}
                                    error={formMethods.errors.accountNumber}
                                    loading={props.loadingBank}
                                    onChange={handleChangeAccountNumber}
                                />
                            ) : (
                                <MyInput
                                    name="accountNumber"
                                    label={t('r_ms_customer.account_number')}
                                    value={value}
                                    error={formMethods.errors.accountNumber}
                                    loading={props.loadingBank}
                                    onChange={handleChangeAccountNumber}
                                />
                            )}
                        />
                        {!!props.bank && (
                            <div className="--mb3 --pl2">
                                <p className="par-2 --semiBold">{props.bank.bank_name}</p>
                            </div>
                        )}


                        <Controller
                            name="bank"
                            control={formMethods.control}
                            rules={rules.bank}
                            render={({onChange, value}) => (
                                <MySelect
                                    name="bank"
                                    label={t('r_ms_customer.bank')}
                                    value={value}
                                    options={mapBankToOptions(props.banks)}
                                    error={formMethods.errors.bank}
                                    loading={props.loadingBanks}
                                    onChange={onChange}
                                />
                            )}
                        />

                        {!!props.verificationCodeVisible && (
                            <>
                                <div className="--mb4"/>
                                <p className="par-2">{t('r_ms_customer.verification_code_info')}</p>
                                <Controller
                                    name="verificationCode"
                                    control={formMethods.control}
                                    rules={rules.verificationCode}
                                    render={({onChange, value}) => (
                                        <MyInput
                                            name="verificationCode"
                                            label={t('r_ms_customer.verification_code')}
                                            value={value}
                                            error={formMethods.errors.verificationCode}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                                <Button label={t('resend')} disabled={props.sending} onClick={onClickResendCode}/>
                            </>
                        )}
                    </div>
                )}


                {!props.nespressoMember && (
                    <div className="--mb4">
                        <Controller
                            name="recaptchaToken"
                            control={formMethods.control}
                            rules={rules.recaptchaToken}
                            render={({onChange, value}) => (
                                <RecaptchaV2
                                    value={value}
                                    onChange={onChange}
                                    error={formMethods.errors.recaptchaToken}
                                />
                            )}
                        />
                    </div>
                )}


                {!!Object.keys(formMethods.formState.errors)?.length && (
                    <p className="par-2 --error --mb4">{t('fill_all_required_fields')}</p>
                )}


                <p className="par-4 --error --mb4">{t('fill_fields_correctly')}</p>


                <div className="step__nav">
                    <Link to={stepNavigation.prev()}>
                        <Button
                            type="primary-outline"
                            label={t('back')}
                            disabled={props.sending}
                        />
                    </Link>
                    <Button
                        btnType="submit"
                        type="primary"
                        label={t('continue')}
                        disabled={props.sending}
                    />
                </div>
            </form>
        </FormProvider>
    );
}



function mapBankToOptions(banks) {
    const arr = [];
    banks?.map(b => {
        arr.push({
            value: b.id_subscription_bank,
            label: b.name
        })
    })
    return arr;
}
