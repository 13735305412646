import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Summary} from "../../components/stay_home/_index";
import {useTranslation} from "../../misc/Hooks";
import * as stayHomeReducer from "../../redux/stayHomeReducer";


export const SummaryController = (props) => {
    const dispatch = useDispatch();
    const store = useSelector(({main, stayHome}) => ({
        customer: stayHome.customer,
        categories: stayHome.categories,
        products: stayHome.products,
        plan: stayHome.plan,
        cart: stayHome.cart,
        uuid: stayHome.uuid,
        paymentMethod: stayHome.paymentMethod,
    }))
    const t = useTranslation();
    const [error, setError] = useState(null);
    const [errorLog, setErrorLog] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        document.title = t('sh_summary.title')
    }, [])


    const handleSubmit = (data) => {
        console.log('submited!')
    }


    const handleChangePaymentMethod = (data) => {
        // setPaymentType(data.type);
        if (!loading) {
            dispatch(stayHomeReducer.setPaymentMethod(data.type))
        }
    }


    const handleSendErrors = () => {
        // todo: implement functionality
    }


    return (
        <Summary
            error={error}
            errorLog={errorLog}
            loading={loading}
            uuid={store.uuid}
            cart={store.cart}
            customer={store.customer}
            products={store.products}
            categories={store.categories}
            paymentMethod={store.paymentMethod}
            order={store.order}
            onChangePaymentMethod={handleChangePaymentMethod}
            onSendErrors={handleSendErrors}
            onSubmit={handleSubmit}
        />
    )
}
