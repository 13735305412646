import {Link, useHistory} from 'react-router-dom';
import {Button} from "./buttons/_index";
import {Routes} from "../utils/_index";
import {useMarketConfig, useNavigation, useTranslation} from "../misc/Hooks";
import {useDispatch, useSelector} from "react-redux";
import * as evReducer from "../redux/evReducer";


interface Props {
    borderBottom?: boolean,
}


export default (props: Props) => {
    const history = useHistory();
    const navigation = useNavigation();
    const marketConfig = useMarketConfig()
    const dispatch = useDispatch();
    const t = useTranslation();
    const store = useSelector(({main, ev}) => ({
        user: main.user,
    }))
    const navToMachineRegistration = () => {
        dispatch(evReducer.clearStore())
        history.push(navigation(Routes.SHOP_MACHINE_REGISTRATION))
    }

    const navToAssistedRegistration = () => {
        dispatch(evReducer.clearStore())
        history.push(navigation(Routes.RESELLER_ASSISTED_REGISTRATION))
    }

    const anyVisible = (marketConfig.reseller_redemption_enabled === undefined || marketConfig.reseller_redemption_enabled)
        || (marketConfig.reseller_machine_subscription_enabled === undefined || marketConfig.reseller_machine_subscription_enabled)
        || (marketConfig.dashboard_enabled === undefined || marketConfig.dashboard_enabled)

    return (
        <div className={`resellerOptions ${props.borderBottom ? '-borderBottom' : ''}`}>
            {anyVisible && (
                <div className="resellerOptions__header">
                    <h2 className="par-1">{t('reseller_options.title')}</h2>
                </div>
            )}

            <div className="resellerOptions__items">
                {!!(marketConfig.reseller_redemption_enabled === undefined || marketConfig.reseller_redemption_enabled) && (
                    <div className="resellerOptions__item">
                        <img
                            src={require('../assets/img/icons/register_m-ic-light.svg').default}
                            alt=""
                            className="resellerOptions__bg"
                        />

                        <div className="resellerOptions__thumb">
                            <img src={require('../assets/img/icons/register_m-ic.svg').default} alt="" className="img-fluid"/>
                        </div>
                        <h4 className="resellerOptions__label">{t('reseller_options.assisted_registration')}</h4>
                        <div className="resellerOptions__button">
                            {/*<Button label={t('select')} onClick={navToMachineRegistration}/>*/}
                            <Button label={t('select')} onClick={navToAssistedRegistration}/>
                        </div>
                    </div>
                )}

                {!!(marketConfig.reseller_machine_subscription_enabled === undefined || marketConfig.reseller_machine_subscription_enabled) && (
                    <div className="resellerOptions__item">
                        <img
                            src={require('../assets/img/icons/machine-ic-light.svg').default}
                            alt=""
                            className="resellerOptions__bg"
                        />

                        <div className="resellerOptions__thumb">
                            <img src={require('../assets/img/icons/machine-ic.svg').default} alt="" className="img-fluid"/>
                        </div>
                        <h4 className="resellerOptions__label">{t('reseller_options.machine_subscription')}</h4>
                        <div className="resellerOptions__button">
                            <Link to={navigation(Routes.RESELLER_MACHINE_SUBSCRIPTIONS)}>
                                <Button label={t('select')}/>
                            </Link>
                        </div>
                    </div>
                )}

                {!!(marketConfig.reseller_dashboard_enabled === undefined || marketConfig.reseller_dashboard_enabled) && (
                    <div className="resellerOptions__item">
                        <img
                            src={require('../assets/img/icons/dashboard-ic-light.svg').default}
                            alt=""
                            className="resellerOptions__bg"
                        />

                        <div className="resellerOptions__thumb">
                            <img src={require('../assets/img/icons/dashboard-ic.svg').default} alt="" className="img-fluid"/>
                        </div>
                        <h4 className="resellerOptions__label">{t('reseller_options.my_dashboard')}</h4>
                        <div className="resellerOptions__button">
                            {/*<Link to={"/"}>*/}
                                <Button label={t('select')}/>
                            {/*</Link>*/}
                        </div>
                    </div>
                )}
                {store.user.reseller_role !== null && (
                    <div className="resellerOptions__item">
                        <img
                            src={require('../assets/img/icons/assisted-ic-light.svg').default}
                            alt=""
                            className="resellerOptions__bg"
                        />

                        <div className="resellerOptions__thumb">
                            <img src={require('../assets/img/icons/assisted-ic.svg').default} alt="" className="img-fluid"/>
                        </div>
                        <h4 className="resellerOptions__label">{t('reseller_options.admin_and_reports')}</h4>
                        <div className="resellerOptions__button">
                            <Link to={navigation(Routes.RESELLER_ADMIN_REPORTS)}>
                                <Button label={t('select')}/>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
