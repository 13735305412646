import React, {useEffect, useState} from 'react';
import {Config} from "../../utils/_index";
import {Link} from "react-router-dom";
import {Button} from "../buttons/_index";
import {CategoryBar, CoffeeItem} from "../_index";
import {getImage} from "../../misc/Helpers";
import {Preloader} from "../../misc/_index";
import {useCart, useEvStepNavigation, useLocalizeObject, useTranslation} from "../../misc/Hooks";
import {FilterModal} from "../modals/_index";
import {EasyOrderSetupForm} from "../forms/_index";


interface Props {
    loading: boolean,
    promotion: any,
    categories: [],
    cart: any,
    onChange: void,
}


const CoffeeSelectionStep = (props: Props) => {
    const t = useTranslation();
    const localizeObject = useLocalizeObject()
    const {isCartValid, isCategoryValid} = useCart();
    const stepNavigation = useEvStepNavigation();
    const [categories, setCategories] = useState([]);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [showAdditionalEnabled, setShowAdditionalEnabled] = useState(false);
    const [showAdditional, setShowAdditional] = useState(false);

    const promoImage = getImage(props.promotion.promotion_gallery, Config.GALLERY_TYPE.BACKGROUND);


    useEffect(() => {
       const categories = props.categories.filter((cat, idx) => {
           if (props.promotion.promotion_type === Config.PROMO_TYPE.EASY_ORDER
               && !props.easyOrderSetup) {
               return false;
           }

           // unlock rest of the categories, if this category is valid
           let isValid = true;
           props.categories.map((c, cIdx) => {
               if(!isValid)
                   return;
               if(cIdx > idx || cIdx === idx)
                   return;

               if(c.promotionCategory?.promotion_category_hide_others)
                   isValid = isCategoryValid(c.id_category);
           })

           return isValid;
       })

        setShowAdditionalEnabled(!!categories.find(c => c.promotionCategory?.promotion_category_is_additional))
        setCategories(categories.filter(cat => !(!showAdditional && cat.promotionCategory.promotion_category_is_additional)))


    }, [props.categories, props.cart, props.easyOrderSetup, showAdditional])


    const handleSubmit = (data) => {
        props.onFilterSubmit(data);
    }


    const handleHidePopup = () => {
        setFilterModalVisible(false);
    }


    const getProductsCount = () => {
        let count = 0;
        props.categories.map(c => {
            count += c.products.length
        })

        return count;
    }


    const getCategoryProductsModifier = (cat) => {
        if (cat.category_type === Config.COFFEE_TYPE.PLAN_COFFEE
            || cat.category_type === Config.COFFEE_TYPE.PACKAGE
            || cat.category_type === Config.COFFEE_TYPE.ACCESSORY
        ) {
            return '-lg';
        }
        return '';
    }


    return (
        <div className="evCS">

            {!!promoImage && (
                <div className="evCS__promoImage">
                    <img src={promoImage} alt=""/>
                </div>
            )}

            <div className="evCS__header">
                <div
                    className="par-1"
                    dangerouslySetInnerHTML={{__html: localizeObject(props.promotion.promotion_description4)}}
                />
            </div>


            {props.loading ? (
                <Preloader/>
            ) : props.categories?.length ? (
                <>
                    {!!props.categories.length && (
                        <CategoryBar
                            categories={categories}
                            promotion={props.promotion}
                            easyOrderSetup={props.easyOrderSetup}
                            openFilter={() => setFilterModalVisible(true)}
                        />
                    )}


                    {props.promotion.promotion_type === Config.PROMO_TYPE.EASY_ORDER && (
                        <div className="--mb3">
                            <EasyOrderSetupForm
                                data={props.easyOrderSetup}
                                frequencies={props.frequencies}
                                onSubmit={props.onSubmitEasyOrderSetup}
                            />
                        </div>
                    )}

                    {props.promotion.promotion_type === Config.PROMO_TYPE.EASY_ORDER && !!props.easyOrderSetup && (
                        <h3 className="evCS__categoryName --lg">{t('easy_order.select_gift')}</h3>
                    )}

                    {categories.map((cat, idx) => {
                        return !!cat.products?.length && (
                            <div key={idx}>
                                <div id={`category_${idx}`} className="evCS__section">
                                    <h3 className="evCS__categoryName">{cat.category_title}</h3>
                                    <div
                                        className="evCS__categoryDescription"
                                        dangerouslySetInnerHTML={{__html: cat.category_description}}
                                    />

                                    {!!cat.products.length && (
                                        <div className={`evCS__products ${getCategoryProductsModifier(cat)}`}>
                                            {cat.products?.map(product => (
                                                <CoffeeItem
                                                    key={product.id_product}
                                                    item={product}
                                                    cart={props.cart}
                                                    onChange={props.onChange}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {!!(showAdditional && cat.promotionCategory?.promotion_category_hide_others && props.promotion.promotion_type === Config.PROMO_TYPE.EASY_ORDER) && (
                                    <h3 className="evCS__categoryName --lg">{t('easy_order.select_coffees')}</h3>
                                )}
                            </div>
                        )
                    })}

                    {showAdditionalEnabled && props.promotion.promotion_type !== Config.PROMO_TYPE.EASY_ORDER && (
                        <div className="--mb4">
                            <Button
                                type="primary"
                                label={t(`${showAdditional ? 'hide' : 'show'}_additional.${props.categories.find(c => c.promotionCategory?.promotion_category_is_additional)?.products[0]?.product_type}`)}
                                onClick={() => setShowAdditional(!showAdditional)}
                            />
                        </div>
                    )}
                </>
            ) : (
                <p className="par-2 --semiBold --mb4">{t('no_products')}</p>
            )}


            {(props.promotion.promotion_type !== Config.PROMO_TYPE.EASY_ORDER || (props.promotion.promotion_type === Config.PROMO_TYPE.EASY_ORDER && props.easyOrderSetup)) && (
                <div className="step__nav">
                    <Link to={stepNavigation.prev()}>
                        <Button
                            type="primary-outline"
                            label={t('back')}
                        />
                    </Link>
                    {showAdditionalEnabled && !showAdditional ? (
                        <Button
                            type="primary"
                            label={t('continue')}
                            onClick={() => setShowAdditional(true)}
                        />
                    ) : (
                        <Link to={stepNavigation.next()}>
                            <Button
                                type="primary"
                                label={t('continue')}
                                disabled={!isCartValid}
                            />
                        </Link>
                    )}
                </div>
            )}



            <FilterModal
                visible={filterModalVisible}
                filter={props.filter}
                cupSizes={props.cupSizes}
                aromaticProfiles={props.aromaticProfiles}
                coffeeTypes={props.coffeeTypes}
                productsCount={getProductsCount}
                onClose={() => setFilterModalVisible(false)}
                onSubmit={handleSubmit}
                onReset={props.onFilterReset}
                hidePopup={handleHidePopup}
            />
        </div>
    );
}


export default CoffeeSelectionStep;
