import React, {useEffect, useState} from "react";
import {usePagination, useTable} from "react-table";
import {MySelect} from "../../../../components/forms/inputs/_index";
import {SearchForm} from "../../../../components/forms/_index";
import {useTranslation} from "../../../../misc/Hooks";
import {Preloader} from "../../../../misc/_index";
import {Button} from "../../../../components/buttons/_index";


interface Props {
    title: string,
    columns: any,
    data: any,
    maxPage: number,
    getData: void,
    search: void,
    actions: boolean,
    setSelected?: void,
    selected?: any,
    actionVoid?: void,
    changeStatus?: void,
    certificateRenewal?: void,
    actionsOptions?: any,
}


export default (props: Props) => {
    const t = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSizeApi] = useState(10);
    const [filterStatus, setFilterStatus] = useState({label: t('table.status.all'), value: '-1'});
    const [certificateStatus, setCertificateStatus] = useState({label: t('table.certificate.all'), value: '-1'});
    const [searchText, setSearchText] = useState('');
    const [actionValue, setActionValue] = useState([])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        pageCount,
        setPageSize
    } = useTable({columns: props.columns, data: props.data, initialState: {pageSize: 5}}, usePagination);

    useEffect(() => {
        setPageSize(pageSize)
        props.getData(currentPage, pageSize, filterStatus.value, certificateStatus.value)
        props.setSelected([])
    }, [currentPage, pageSize, filterStatus, certificateStatus])

    useEffect(()=> {
        if(currentPage > props.maxPage){
            setCurrentPage(1)
        }
    }, [props.maxPage])

    return (
        <>
            <div className="boxTable">
                <div className="boxTable__header">
                    <h2 className="par-1">{props.title}</h2>
                </div>
                <div className="boxTable__overflow">
                    <div className={`boxTable__bar`}>
                        <div className="boxTable__barLeft">
                            <div style={{width: '125px', minWidth: '90px'}}>
                                <MySelect
                                    value={{label: pageSize, value: pageSize}}
                                    label={t('table.results')}
                                    options={[
                                        {label: '5', value: 5},
                                        {label: '10', value: 10},
                                        {label: '20', value: 20},
                                        {label: '50', value: 50},
                                        {label: '100', value: 100},
                                    ]}
                                    onChange={v => setPageSizeApi(v?.value)}
                                    raw
                                />
                            </div>
                            <div style={{width: '150px', minWidth: '90px'}}>
                                <MySelect
                                    value={{label: filterStatus.label, value: filterStatus.value}}
                                    label={t('table.status.status')}
                                    options={[
                                        {label: t('table.status.all'), value: '-1'},
                                        {label: t('table.status.inactive'), value: '0'},
                                        {label: t('table.status.active'), value: '1'},
                                    ]}
                                    onChange={v => setFilterStatus(v)}
                                    raw
                                />
                            </div>
                            <div style={{width: '150px', minWidth: '90px'}}>
                                <MySelect
                                    value={{label: certificateStatus.label, value: certificateStatus.value}}
                                    label={t('table.certificate.certificate')}
                                    options={[
                                        {label: t('table.certificate.all'), value: '-1'},
                                        {label: t('table.certificate.expired'), value: '0'},
                                        {label: t('table.certificate.active'), value: '1'},
                                    ]}
                                    onChange={v => setCertificateStatus(v)}
                                    raw
                                />
                            </div>
                        </div>
                        <div className="boxTable__barRight">
                            <div style={{width: '300px', minWidth: '150px'}}>
                                <SearchForm
                                    onSubmit={v => props.getData(currentPage, pageSize, filterStatus.value, certificateStatus.value, v)}/>
                            </div>
                        </div>
                    </div>
                    <div className="boxTable__table">
                        <table {...getTableProps()}>
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup}>
                                    {headerGroup.headers.map(column => {
                                        if(typeof column.render('Header') === 'object'){
                                            return (<th>{column.render('Header')}</th>)
                                        } else {
                                            return (
                                                <th {...column.getHeaderProps()}
                                                    dangerouslySetInnerHTML={{__html: column.render('Header')}}/>
                                            )
                                        }
                                    })}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                )
                            })}
                            {props.loading ? (
                                <tr>
                                    <td colSpan="100" className="-noResults">
                                        <Preloader classes="--mb0"/>
                                    </td>
                                </tr>
                            ) : !page.length && (
                                <tr>
                                    <td colSpan="100" className="-noResults">{t('table.no_results')}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="boxTable__footer">
                    <span>{t('table.page_status', currentPage, pageSize, props.results)}</span>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {currentPage > 3 && (
                            <button onClick={() => setCurrentPage(1)}>
                                <img style={{height: '12px'}}
                                     src={require('../../../../assets/img/icons/arrow-left-double.svg').default}
                                     alt=""/>
                            </button>
                        )}
                        <button type="button"
                                onClick={() => currentPage > 1 ? setCurrentPage(currentPage - 1) : undefined}>
                            <img style={{height: '12px'}}
                                 src={require('../../../../assets/img/icons/arrow-left.svg').default} alt=""/>
                        </button>
                        <div className="boxTable__pagination">
                            {new Array(props.maxPage).fill().map((_, idx) => idx + 1)
                                .map(i => !!(i >= currentPage - 2 && i <= currentPage + 2) && (
                                    <button style={{fontWeight: i === currentPage ? '700' : '400'}}
                                            onClick={() => setCurrentPage(i)}>{i}</button>
                                ))}
                        </div>
                        <button type="button"
                                onClick={() => currentPage + 1 <= props.maxPage ? setCurrentPage(currentPage + 1) : undefined}>
                            <img style={{height: '12px'}}
                                 src={require('../../../../assets/img/icons/arrow-right.svg').default} alt=""/>
                        </button>
                        {currentPage < props.maxPage - 2 && (
                            <button onClick={() => setCurrentPage(props.maxPage)}>
                                <img style={{height: '12px'}}
                                     src={require('../../../../assets/img/icons/arrow-right-double.svg').default}
                                     alt=""/>
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {props.actions && (
                <div className="actionBar">
                    {props.selected.length > 0 && (
                        <>
                            <span className="actionBar__label">{t('for_selected')} ({props.selected.length}):</span>
                            <MySelect
                                name="action"
                                label={t('action')}
                                value={actionValue}
                                options={props.actionsOptions}
                                onChange={(val) => setActionValue(val)}
                                noMarginBottom
                            />
                            <Button
                                label={t('take_the_action')}
                                onClick={() => props.actionVoid(actionValue.value)}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
}
