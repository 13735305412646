import {useTranslation} from "../../../misc/Hooks";
import {SHCodeForm, SHEmailForm} from "../../../components/forms/_index";
import {Preloader} from "../../../misc/_index";


export default function Identification(props) {
    const t = useTranslation()

    return (
        <div className="shAuthorization">

            <div className="grid --mb4">
                <div style={{position: 'relative', height: '100%'}}>
                    <img src={require('../../../assets/img/stay-home-img.jpg').default} alt=""
                         className="shAuthorization__img"/>
                </div>
                <div
                    className="par-5 --mb0"
                    dangerouslySetInnerHTML={{__html: t('stay_home.identification.text')}}
                />
            </div>

            {!props.token ? (
                <div className="grid">
                    <div>
                        <p
                            className="par-2"
                            dangerouslySetInnerHTML={{__html: t('stay_home.identification.email_info')}}
                        />
                        <div className="--mb4">
                            <SHEmailForm
                                email={props.email}
                                error={props.error}
                                loading={props.loading}
                                onSubmit={props.onSubmit}
                            />
                        </div>
                        {!!props.loading && <Preloader/>}
                    </div>
                    <div/>
                </div>
            ) : (
                <div className="grid">
                    <div>
                        <p
                            className="par-2"
                            dangerouslySetInnerHTML={{__html: t('stay_home.identification.code_info')}}
                        />
                        <div className="--mb4">
                            <SHCodeForm
                                code={props.code}
                                error={props.error}
                                loading={props.loading}
                                onSubmit={props.onSubmitCode}
                                onGoBack={props.onGoBack}
                            />
                        </div>
                        {!!props.loading && <Preloader/>}
                    </div>
                    <div/>
                </div>
            )}
        </div>
    )
}
