import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Env from "../utils/Env";
import * as rmsReducer from "../redux/rmsReducer";
import * as mainReducer from "../redux/mainReducer";
import * as evReducer from "../redux/evReducer";
import {useLanguage} from "../hooks";
import {getURLSearchParams} from "../misc/Helpers";
import Axios from "../utils/Axios";
import {useEffect} from "react";


const instance = axios.create()
instance.defaults.baseURL = Env.API_URL + '/reseller/';
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


export const useResellerActions = () => {
    const dispatch = useDispatch();
    const currentLanguage = useLanguage();
    const store = useSelector(({main}) => ({
        userToken: main.userToken,
    }))

    instance.defaults.headers.common['Language'] = currentLanguage;
    instance.defaults.headers.get['Authorization'] = store.userToken ? `Bearer ${store.userToken}` : ''
    instance.defaults.headers.post['Authorization'] =  store.userToken ? `Bearer ${store.userToken}` : ''

    instance.interceptors.response.use(r => {
        const res = r.data;
        if (res.status === 'error') {
            if (res.messages.find(m => m === 'reseller_not_logged')) {
                console.log('LOGOUT!')
                logout()
            }
        }
        return r;
    }, err => err)

    const logout = () => {
        dispatch(rmsReducer.clearStore())
        dispatch(evReducer.clearStore())
        dispatch(mainReducer.clearUser())
    }

    const getPlanByMachineId = (id, countryId) => instance.get(`plan-coffee/${id}/${countryId}`);
    const getBanks = () => instance.get(`/banks`);
    const generateCode = (data, planId) => instance.post(`/generate-code/${planId}`, data);
    const verifyCode = (code, token) => instance.post(`/check-code/${code}/${token}`);

    const forgotPassword = (data) => instance.post(`/forgot-password`, data);
    const isForgotPasswordTokenValid = (token) => instance.get(`/change-password/${token}`);
    const changePassword = (token, data) => instance.post(`/change-password/${token}`, data);

    const getCategories = (promotionId, machineId, shopId, userLogged) => {
        return instance.get(`/categories/${promotionId}/${machineId}/${shopId}/${userLogged}`);
    }

    const getResellerChildren = (resellerId = 0, params) => {
        return instance.get(`/childs?id_reseller=${resellerId}&${params}`)
    }

    const getNewRequest = (params) => {
        return instance.get(`/unverified?${params}`)
    }

    const certificateRenewal = (data) => instance.post(`/certificate`, data);

    const sendResetPasswordEmail = (resellerId) => {
        return instance.get(`/password-reset?id_reseller=${resellerId}`)
    }

    const changeResellerStatus = (data) => instance.post(`/status`, data);

    const getResellerEditData = (resellerId) => {
        return instance.get(`/form-data?id_reseller=${resellerId}`)
    }

    const resellerUpdate = (data) => instance.post(`/update`, data);
    const resellerRegister = (data) => instance.post(`/register`, data);

    const resellerVerify = (data) => instance.post(`/verify`, data);

    const getReportsResellerOptions = () => {
        return instance.get(`/reports/resellers`)
    };
    const getGeneratedReports = (params) => {
        return instance.get(`/reports?${params}`)
    };
    const generateRaport = (data) => instance.post(`/reports/generate`, data)

    const getReportsFilters = (params) => instance.get(`/reports/filters${getURLSearchParams(params)}`);

    const reportsFilter = (params) => instance.get(`/reports/filters${getURLSearchParams(params)}`);

    const getResellerList = (params) => instance.get(`/reports/list${getURLSearchParams(params)}`)

    const getSubscriptions = (type, params = '') => {
        return instance.get(`/subscriptions/${type}?${params}`)
    }

    const getOrders = (data, params = '') => {
        return instance.post(`/reports/orders?${params}`, data)
    }

    const resendOrder = (id) => {
        return instance.post(`/resend/${id}`)
    }

    const processOrder = (id) => {
        return instance.post(`/process/${id}`)
    }

    const saveSubscription = (data) => {
        return instance.post(`/subscription`, data);
    }



    return {
        getPlanByMachineId,
        getBanks,
        generateCode,
        verifyCode,
        forgotPassword,
        isForgotPasswordTokenValid,
        changePassword,
        getCategories,
        getResellerChildren,
        certificateRenewal,
        sendResetPasswordEmail,
        changeResellerStatus,
        getResellerEditData,
        resellerUpdate,
        resellerRegister,
        getNewRequest,
        resellerVerify,
        getReportsResellerOptions,
        generateRaport,
        getGeneratedReports,
        getReportsFilters,
        reportsFilter,
        getResellerList,
        getSubscriptions,
        getOrders,
        resendOrder,
        processOrder,
        saveSubscription,
    }
}
