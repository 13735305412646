import React from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyRadio} from "./inputs/_index";
import {useLocalizeObject, useTranslation} from "../../misc/Hooks";


const PaymentTypeForm = (props) => {
    const t = useTranslation();
    const localizeObject = useLocalizeObject();
    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            type: props.type,
        }
    });


    const rules = {
        type: {
            required: {
                value: true,
                message: t('validation.required'),
            }
        },
    }


    const handleChange = (data) => {
        formMethods.setValue('type', data);
        formMethods.handleSubmit(props.onSubmit)()
    }


    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>

                <Controller
                    name="type"
                    control={formMethods.control}
                    rules={rules.type}
                    render={({onChange, value}) => (
                        <>
                            {props.types?.map((type, idx) => (
                                <MyRadio
                                    key={idx}
                                    name="type"
                                    value={type}
                                    label={localizeObject(type.payment_method_title)}
                                    selected={value}
                                    error={formMethods.errors.type}
                                    onChange={handleChange}
                                />
                            ))}
                        </>
                    )}
                />

            </form>
        </FormProvider>
    );
}


export default PaymentTypeForm;
