import {Button} from "../../../components/buttons/_index";
import {useTranslation} from "../../../misc/Hooks";
import {Link} from "react-router-dom";
import {SignInForm} from "../../../components/forms/_index";
import {Preloader} from "../../../misc/_index";
import {useStepsNavigation} from "../../../hooks/modules/stay_home";


export default function AuthorizationScreen(props) {
    const t = useTranslation()
    const stepsNavigation = useStepsNavigation()


    return (
        <div>
            <div className="grid">
                <div>
                    <p className="par-1 --semiBold --mb2">{t('stay_home.authorization.text')}</p>


                    <div className="evCID__buttons --mb4">
                        <Button
                            label={t('yes')}
                            type="grey"
                            active={props.nespressoMember !== undefined && (props.nespressoMember === null || typeof props.nespressoMember === 'object')}
                            onClick={() => props.onSelect(null)}
                        />
                        <Button
                            label={t('no')}
                            type="grey"
                            active={props.nespressoMember !== undefined && props.nespressoMember === false}
                            onClick={() => props.onSelect(false)}
                        />
                    </div>

                    {props.nespressoMember === null && (
                        <div className="grid">
                            <div className="col">
                                <div className="--mb3">
                                    <SignInForm
                                        error={props.error}
                                        loading={props.loading}
                                        onSubmit={props.onSubmit}
                                    />
                                </div>
                                {!!props.loading && <Preloader/>}
                            </div>
                            <div/>
                        </div>
                    )}

                    {props.nespressoMember === false && (
                        <div className="step__nav">
                            <Link to={stepsNavigation.prev()}>
                                <Button
                                    type="primary-outline"
                                    label={t('back')}
                                />
                            </Link>
                            <Link to={stepsNavigation.next()}>
                                <Button
                                    label={t('continue')}
                                />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
