import {SignInResellerForm, SignInResellerShopForm} from "../forms/_index";
import {useTranslation} from "../../misc/Hooks";
import {Preloader} from "../../misc/_index";
import {getMarketImage, getSerialNumberImages} from "../../utils/MarketConfig";
import {TemporaryPasswordResellerForm} from "../forms/reseller";


export default (props) => {
    const t = useTranslation();


    return (
        <div className="resAuth">
            {!props.user ? (
                <div className="resAuth__content">
                    <h1 className="par-1">{t('reseller_auth.title')}</h1>
                    <h2 className="par-2 --mb3">{t('reseller_auth.subtitle')}</h2>

                    <div className="--mb4">
                        <SignInResellerForm
                            text={t('sign_in')}
                            submitText={t('log_in')}
                            error={props.error}
                            onSubmit={props.onSubmit}
                        />
                    </div>

                    {!!props.loading && <Preloader/>}

                </div>
            ) : (
                <>
                   {props.showNewPassword ? (
                      <div className="resAuth__content">
                         <h2 className="par-2 --mb3">{t('reseller_auth.set_new_password')}</h2>

                         <div className="--mb4">
                            <TemporaryPasswordResellerForm
                               onSubmit={props.onSubmitTemporary}
                               error={props.error}
                            />
                         </div>

                         {!!props.loading && <Preloader/>}
                      </div>
                   ) : (
                      <div className="resAuth__content">
                         <h2 className="par-2 --mb3">{t('reseller_auth.select_shop')}</h2>

                         <div className="--mb4">
                            <SignInResellerShopForm
                               shops={props.user.reseller_shops}
                               onSubmit={props.onSubmitShop}
                            />
                         </div>

                         {!!props.loading && <Preloader/>}
                      </div>
                   )}
                </>
            )}
            <div className="resAuth__thumb">
                <img
                    src={getMarketImage('machine')}
                    alt={t('reseller_auth.title')}
                    className="img-fluid"
                />
            </div>
        </div>
    )
}
