import {SetNewPasswordReseller} from "../../../components/reseller/forgot_password";
import {useEffect, useState} from "react";
import {useNavigation, useTranslation} from "../../../misc/Hooks";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Routes} from "../../../utils/_index";
import {
   ForgotPasswordPasswordChangedResellerModal,
   ForgotPasswordTokenExpiredResellerModal
} from "../../../components/modals/reseller";
import {useResellerActions} from "../../../actions/useResellerActions";


export default function SetNewPasswordResellerContainer(props) {
   const t = useTranslation();
   const history = useHistory();
   const navigation = useNavigation();
   const resellerActions = useResellerActions();
   const routeMatch = useRouteMatch();
   const [checkingToken, setCheckingToken] = useState(false);
   const [tokenExpiredModalVisible, setTokenExpiredModalVisible] = useState(false);
   const [passwordChangedModalVisible, setPasswordChangedModalVisible] = useState(false);
   const [error, setError] = useState(null);
   const [processing, setProcessing] = useState(false);


   useEffect(() => {
      document.title = t('set_new_password_reseller.seo_title');

      setCheckingToken(true);
      checkToken(routeMatch.params.token)
         .then(isValid => {
            if (isValid) {
               setCheckingToken(false);
            } else {
               setTokenExpiredModalVisible(true);
            }
         })
   }, [])


   const checkToken = async (token) => {
      return await resellerActions.isForgotPasswordTokenValid(token)
         .then(r => {
            const res = r.data;
            return res.status === 'success';
         })
         .catch(() => false)
   }


   const handleSubmit = async (data) => {
      const formData = {
         password: data.password,
         repassword: data.rePassword,
      };

      setProcessing(true);
      setError(null);
      await sendData(routeMatch.params.token, formData)
         .then(() => setPasswordChangedModalVisible(true))
         .catch(error => setError(t(`error.${error.message}`)))
      setProcessing(false);
   }

   const sendData = async (token, data) => {
      return await resellerActions.changePassword(token, data)
         .then(r => {
            const res = r.data;
            if (res.status === 'error') {
               throw new Error(res.messages[0])
            }
         })
         .catch(error => {
            throw error ?? new Error('unknown_error')
         })
   }


   const handleOnCloseTokenExpiredModal = () => {
      history.push(navigation(Routes.RESELLER_FORGOT_PASSWORD))
   }


   return (
      <div className="wrapper">
         <SetNewPasswordReseller
            checkingToken={checkingToken}
            error={error}
            processing={processing}
            onSubmit={handleSubmit}
         />

         <ForgotPasswordTokenExpiredResellerModal
            visible={tokenExpiredModalVisible}
            onClose={handleOnCloseTokenExpiredModal}
         />

         <ForgotPasswordPasswordChangedResellerModal
            visible={passwordChangedModalVisible}
            onClose={() => history.push(navigation(Routes.RESELLER_AUTH))}
         />
      </div>
   )
}
