import React, {useEffect, useState} from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyInput, MyInputMask, MyRadio} from "./inputs/_index";
import {Button} from "../buttons/_index";
import {useCountryConfig, useLocalizeObject, useTranslation} from "../../misc/Hooks";
import {regexFromString} from "../../misc/Helpers";
import {Link} from "react-router-dom";
import * as rmsReducer from "../../redux/rmsReducer";
import {useDispatch, useSelector} from "react-redux";
import {useResellerActions} from "../../actions/useResellerActions";


let MOUNTED = false;

const PaymentTypeResellerForm = (props) => {
    const t = useTranslation();
    const localizeObject = useLocalizeObject();
    const countryConfig = useCountryConfig();
    const actions = useResellerActions();
    const dispatch = useDispatch();
    const store = useSelector(({main, rms}) => ({
        country: main.country,
        verificationCodeToken: rms.verificationCodeToken,
    }))
    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            type: props.type,
            accountNumber: props.customer?.accountNumber ?? '',
            bank: props.customer?.bank ?? null,
            verificationCode: '',
        }
    });
    const type = formMethods.watch('type');
    const accountNumber = formMethods.watch('accountNumber');

    const [loading, setLoading] = useState(false);
    const [bank, setBank] = useState(null);
    const [verificationCodeVisible, setVerificationCodeVisible] = useState(false);
    const [verificationCodeError, setVerificationCodeError] = useState(null);
    const [sending, setSending] = useState(false);
    const [checkingCode, setCheckingCode] = useState(false);


    const ACCOUNT_NUMBER_MASK = {
        pattern: regexFromString(countryConfig.custom_format?.custom_bank_account_format?.pattern) ?? /\+48 [29][0-9]{2} [0-9]{3} [0-9]{3}/,
        inputMask: countryConfig.custom_format?.custom_bank_account_format?.mask ?? '+48 999 999 999',
    }

    const rules = {
        type: {
            required: {value: true, message: t('validation.required')},
        },
        accountNumber: {
            required: {value: true, message: t('validation.required')},
            pattern: {value: ACCOUNT_NUMBER_MASK.pattern, message: t('validation.invalid')},
        },
        bank: {required: {value: true, message: t('validation.required')}},
        verificationCode: {required: {value: true, message: t('validation.required')}},
    }


    useEffect(() => {
        MOUNTED = true;

        return () => {
            MOUNTED = false;
        }
    }, [])


    const getBank = async (accountNumber) => {
        setLoading(true)
        setSending(true)

        setLoading(false);
        setSending(false);
    }


    const handleChange = (data) => {
        formMethods.setValue('type', data);
    }


    const handleChangeAccountNumber = async (data) => {
        const value = data.target?.value;
        formMethods.setValue('accountNumber', value);
        if (value?.match(ACCOUNT_NUMBER_MASK.pattern) && accountNumber !== value) {
            await getBank(value);
        }
    }


    const onSubmit = async (data) => {
        setLoading(true);

        if (data.type?.payment_method_need_code_verify && (!store.verificationCodeToken || !data.verificationCode)) {
            const codeFormData = {
                email: props.customer.email,
                id_country: store.country?.id_country,
            };
            setSending(true)
            await actions.generateCode(codeFormData, props.plan.id_product).then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (MOUNTED) {
                        dispatch(rmsReducer.setVerificationCodeToken(res.data))
                        setVerificationCodeVisible(true);
                    }
                }
            }).catch(err => {
                console.error(err)
            }).finally(() => {
                if (MOUNTED) {
                    setSending(false);
                    setLoading(false);
                }
            })
            return;
        } else if (store.verificationCodeToken) {
            let error = null;
            setVerificationCodeError(null);
            setCheckingCode(true)
            await actions.verifyCode(data.verificationCode, store.verificationCodeToken).then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    // ok
                } else {
                    if (res.messages?.length) {
                        if (MOUNTED) {
                            error = res.messages[0]
                        }
                    }
                }
            }).catch(err => {
                console.error(err)
            })
            if (error) {
                formMethods.setError('verificationCode', {type: 'invalid', message: t('validation.invalid')})
                setCheckingCode(false)
                setLoading(false);
                return;
            }
        }

        data.bank = bank;

        await props.onSubmit(data)
        setLoading(false);
        setSending(false);
        setCheckingCode(false);
    }


    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>

                <Controller
                    name="type"
                    control={formMethods.control}
                    rules={rules.type}
                    render={({onChange, value}) => (
                        <>
                            {props.types?.map((type, idx) => (
                                <MyRadio
                                    key={idx}
                                    name="type"
                                    value={type}
                                    label={localizeObject(type.payment_method_title)}
                                    selected={value}
                                    error={formMethods.errors.type}
                                    onChange={handleChange}
                                />
                            ))}
                        </>
                    )}
                />

                {!!type?.payment_method_need_code_verify && (
                    <div className="--mb4 --pt4">

                        <Controller
                            name="accountNumber"
                            control={formMethods.control}
                            rules={rules.accountNumber}
                            render={({
                                         onChange,
                                         value
                                     }) => Number(countryConfig.custom_format?.custom_bank_account_format?.mask_status) ? (
                                <MyInputMask
                                    name="accountNumber"
                                    label={t('r_ms_customer.account_number')}
                                    mask={ACCOUNT_NUMBER_MASK.inputMask}
                                    formatChars={ACCOUNT_NUMBER_MASK.formatChars}
                                    value={value}
                                    error={formMethods.errors.accountNumber}
                                    loading={sending}
                                    onChange={handleChangeAccountNumber}
                                />
                            ) : (
                                <MyInput
                                    name="accountNumber"
                                    label={t('r_ms_customer.account_number')}
                                    value={value}
                                    error={formMethods.errors.accountNumber}
                                    loading={sending}
                                    onChange={handleChangeAccountNumber}
                                />
                            )}
                        />
                        {!!bank && (
                            <div className="--mb3 --pl2">
                                <p className="par-2 --semiBold">{bank.bank_name}</p>
                            </div>
                        )}


                        {/*<Controller*/}
                        {/*    name="bank"*/}
                        {/*    control={formMethods.control}*/}
                        {/*    rules={rules.bank}*/}
                        {/*    render={({onChange, value}) => (*/}
                        {/*        <MySelect*/}
                        {/*            name="bank"*/}
                        {/*            label={t('r_ms_customer.bank')}*/}
                        {/*            value={value}*/}
                        {/*            options={mapBankToOptions(props.banks)}*/}
                        {/*            error={formMethods.errors.bank}*/}
                        {/*            loading={props.loadingBanks}*/}
                        {/*            onChange={onChange}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}

                        {!!verificationCodeVisible && (
                            <>
                                <div className="--mb4"/>
                                <p className="par-2">{t('r_ms_customer.verification_code_info')}</p>
                                <Controller
                                    name="verificationCode"
                                    control={formMethods.control}
                                    rules={rules.verificationCode}
                                    render={({onChange, value}) => (
                                        <MyInput
                                            name="verificationCode"
                                            label={t('r_ms_customer.verification_code')}
                                            value={value}
                                            loading={checkingCode}
                                            error={formMethods.errors.verificationCode}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </>
                        )}
                    </div>
                )}

                <div className="step__nav -right -bottom --mb4">
                    <Link to={props.stepNavigation.prev()}>
                        <Button
                            type="primary-outline"
                            size="sm"
                            disabled={loading}
                            label={t('back')}
                        />
                    </Link>
                    <Button
                        btnType="submit"
                        type="primary"
                        label={t('confirm')}
                        disabled={loading || !type || (type?.payment_method_need_code_verify ? !bank : false)}
                    />
                </div>

            </form>
        </FormProvider>
    );
}


export default PaymentTypeResellerForm;
