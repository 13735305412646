import {Preloader} from "../../misc/_index";
import {Link} from "react-router-dom";
import {PromoItem} from "../_index";
import {Button} from "../buttons/_index";
import {useEvStepNavigation, useTranslation} from "../../misc/Hooks";
import React from "react";


interface Props {
    loading: boolean,
    promotion: any,
    promotions: [],
    onSelectPromo: void,
}


const PromoSelectionStep = (props: Props) => {
    const t = useTranslation();
    const stepNavigation = useEvStepNavigation();


    return (
        <div className="evPS">

            {props.loading ? (
                <Preloader/>
            ) : props.promotions.length ? (
                <>
                    <div className="evPS__header">
                        <p className="par-1 --bold">{t('ev_promo_selection.text')}</p>
                    </div>

                    <div className="evPS__items">
                        {props.promotions.map(promo => {
                            if(promo.promotion_type?.startsWith('subscription_') && props.nespressoMember?.has_subscription)
                                return null;

                            return (
                                <PromoItem
                                    key={promo.id_promotion}
                                    promotion={promo}
                                    selectedPromotion={props.promotion}
                                    onClick={() => props.onSelectPromo(promo)}
                                />
                            )
                        })}
                    </div>
                </>
            ) : (
                <div className="--mb4">
                    <p className="par-5 --bold" dangerouslySetInnerHTML={{__html: t('error.promotions_not_found')}}/>
                </div>
            )}

            {!!props.error && <p>{t(`error.${props.error}`)}</p>}


            <div className="step__nav">
                <Link to={stepNavigation.prev()}>
                    <Button
                        type="primary-outline"
                        label={t('back')}
                    />
                </Link>
                <Link to={stepNavigation.next()} >
                    <Button
                        type="primary"
                        label={t('confirm')}
                        disabled={!props.promotion}
                    />
                </Link>
            </div>

        </div>
    );
}


export default PromoSelectionStep;
