import {useNavigation, useTranslation} from "../misc/Hooks";
import {Link} from "react-router-dom";

export default function Breadcrumb(props) {

    const navigation = useNavigation();
    const t = useTranslation();


    return (
       <div className={`wrapper ${props.item.size}`}>
        <ul className="breadcrumb">
            {props.item.trace.map((trace, idx) => (
                <li key={idx} className="breadcrumb__element">
                    {trace.route ? (
                        <Link to={navigation(trace.route)}>{t(trace.name)}</Link>
                    ) : (<span>{t(trace.name)}</span>)}
                </li>
            ))}
        </ul>
       </div>
    )
}