import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getStayHomeTYP} from '../../../actions/MainActions'
import OrderFinalized from "../screens/OrderFinalized";
import {useDispatch} from "react-redux";


export default function OrderFinalizedContainer(props) {
    const dispatch = useDispatch()
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)


    useEffect(() => {
        fetchTyp(params.uuid)
            .then(() => setLoading(false))
    }, [])


    const fetchTyp = async (uuid) => {
        await getStayHomeTYP(uuid)
            .then(r => {
                const res = r.data
                if(res.status === 'success') {
                    setData(res.data)
                }
                else {
                    console.warn(res)
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    return (
        <OrderFinalized
            loading={loading}
            data={data}
        />
    )
}
