import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import IdentificationMS from "../../../components/reseller/IdentificationMS";
import * as rmsReducer from '../../../redux/rmsReducer'
import {useRmsStepNavigation, useTranslation} from "../../../misc/Hooks";


export const IdentificationContainer = (props) => {
    const t = useTranslation();
    const history = useHistory();
    const stepNavigation = useRmsStepNavigation();
    const dispatch = useDispatch();
    const store = useSelector(({rms}) => ({
        nespressoMember: rms.nespressoMember,
        customer: rms.customer,
    }))


    const handleSelect = (data) => {
        dispatch(rmsReducer.setNespressoMember(data));
        if (!data) {
            history.push(stepNavigation.next())
            if (store.nespressoMember !== data) {
                dispatch(rmsReducer.setCustomer(null))
            }
        }
    }


    const handleSubmitEmail = (data) => {
        dispatch(rmsReducer.setCustomer({
            email: data.email,
            lang: data.lang,
        }))
        dispatch(rmsReducer.setPaymentMethod('credit_card'))
        history.push(stepNavigation.next())
    }

    return (
        <IdentificationMS
            nespressoMember={store.nespressoMember}
            customer={store.customer}
            onSelect={handleSelect}
            onSubmitEmail={handleSubmitEmail}
        />
    )
}
