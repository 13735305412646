import CustomerScreen from "../screens/CustomerScreen";
import {useEffect, useState} from "react";
import {GTM} from "../../../misc/_index";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useStepsNavigation} from "../../../hooks/modules/stay_home";
import {useTranslation} from "../../../misc/Hooks";
import * as authActions from "../../../actions/AuthorizationActions";
import * as stayHomeReducer from "../../../redux/stayHomeReducer";


let MOUNTED = false

export default function CustomerContainer(props) {
    const history = useHistory()
    const stepsNavigation = useStepsNavigation()
    const t = useTranslation()
    const dispatch = useDispatch();
    const store = useSelector(({main, stayHome}) => ({
        data: stayHome.data,
        customer: stayHome.customer,
        nespressoMember: stayHome.nespressoMember,
        nespressoMemberExist: stayHome.nespressoMemberExist,
        addresses: stayHome.addresses,
    }));
    const plan = useSelector(({stayHome}) => {
        return stayHome.products?.find(p => {
            const item = stayHome.cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(null);
    const [loginFormVisible, setLoginFormVisible] = useState(!!store.canRegisterResponse?.status === 'ACCOUNT_EXISTS')
    const [loadingAddresses, setLoadingAddresses] = useState(false)


    useEffect(() => {
        MOUNTED = true;
        document.title = t('stay_home.customer.title');
        GTM.showPage(t('stay_home.customer.title'))

        if (store.nespressoMember) {
            const data = {
                token: store.nespressoMember.token,
            };
            getClientAddresses(data);
        }

        return () => {
            MOUNTED = false;
        }
    }, [])


    const canRegister = (data) => {
        const formData = {
            ...data,
            recaptcha_token: store.recaptchaToken,
        }

        setLoginFormVisible(false);
        setSending(true);
        setError(null);
        authActions.canRegister(formData).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(stayHomeReducer.setCanRegisterResponse(res.data))
                    switch (res.data.status) {
                        case 'ACCOUNT_EXISTS':
                            if (res.data.memberNumber) {
                                if (store.user) {
                                    dispatch(stayHomeReducer.setCustomer(data));
                                    history.push(stepsNavigation.next());
                                } else {
                                    // history.push(navigation(Routes.SHOP_CUSTOMER_IDENTIFICATION));
                                    setLoginFormVisible(true);
                                    // dispatch(evReducer.setNespressoMember(null));
                                    // dispatch(evReducer.setPromotion(null));
                                }
                            } else {
                                dispatch(stayHomeReducer.setCustomer(null))
                                setError('member_exists_error')
                            }
                            break;
                        case 'MEMBER_EXISTS':
                            dispatch(stayHomeReducer.setCustomer(data));
                            history.push(stepsNavigation.next());
                            break;
                        case 'ALLOWED':
                            dispatch(stayHomeReducer.setCustomer(data));
                            history.push(stepsNavigation.next());
                            break;
                        case 'NOT_ALLOWED':
                        default:
                            dispatch(stayHomeReducer.setCustomer(null))
                            setError('error.cannot_create_account')
                            break;
                    }
                }
            } else {
                if (res.data.json?.length) {
                    if (res.data.json.reverse()[0]?.errorReason === 'NOT_ALLOWED') {
                        setError(t('email_was_already_registered'))
                    } else {
                        setError(res.data.json.reverse()[0].message)
                    }
                } else {
                    setError(res?.messages[0] ?? res?.data?.message ?? t('error.unknown_error'))
                    console.warn('Something gone wrong! ', res)
                }
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if (MOUNTED) {
                setSending(false);
            }
        })
    }

    const getClientAddresses = (memberId) => {
        setLoadingAddresses(true);
        authActions.getAddresses(memberId).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(stayHomeReducer.setAddresses(res.data));
                }
            } else {
                console.warn('Something gone wrong! ', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if (MOUNTED) {
                setLoadingAddresses(false);
            }
        })
    }

    const handleSubmit = (formData) => {
        let data = formData;
        const address = store.addresses.find(a => a.id === formData.memberAddress?.value);
        if (address) {
            data = {
                ...formData,
                ...address,
                email: store.nespressoMember.email,
                address_id: address.id,
                phone: formData.phone,
            }

            if (formData.phone) {
                data.phone = formData.phone;
            }

            const civility = store.civilities?.find(c => Number(c.stack_value) === Number(address.title));
            if (civility) {
                data.title = {
                    label: civility.stack_title,
                    value: civility.stack_value,
                }
            } else {
                data.title = {
                    label: 'Unknown civility',
                    value: address.title,
                }
            }
        }


        if (!store.nespressoMember) {
            canRegister(data)
        } else {
            data.email = store.nespressoMember?.email;
            dispatch(stayHomeReducer.setCustomer(data));
            history.push(stepsNavigation.next());
        }
    }

    return (
        <CustomerScreen
            customer={store.customer}
            nespressoMember={store.nespressoMember}
            addresses={store.addresses}
            sending={sending}
            error={error}
            plan={plan}
            loadingAddresses={loadingAddresses}
            loginFormVisible={loginFormVisible}
            onSubmit={handleSubmit}
        />
    )
}
