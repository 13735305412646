import React, {useEffect, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {MyCheckbox} from "../inputs/_index";
import {useFormRules} from "../../../hooks";
import {useTranslation} from "../../../misc/Hooks";
import {getBenefitsOrder} from "../../../utils/MarketConfig";


interface IFormFragmentBenefitsConfig {
    email: number,
    mobile: number,
    phone: number,
    post: number,
    required: number,
    selectAllEnabled: boolean,
}


interface FormFragmentBenefitsProps {
    data?: any,
    config: IFormFragmentBenefitsConfig,
}


export default function FormFragmentBenefits(props: FormFragmentBenefitsProps) {
    const t = useTranslation();
    const formRules = useFormRules();
    const {control, watch, errors, trigger, formState, setValue} = useFormContext();
    const email = watch('benefits.email')
    const mobile = watch('benefits.mobile')
    const phone = watch('benefits.phone')
    const post = watch('benefits.post')
    const [selectedAll, setSelectedAll] = useState(email && mobile && phone && post)

    const [visibleConfig, setVisibleConfig] = useState({
        email: 0,
        mobile: 0,
        phone: 0,
        post: 0,
    })


    useEffect(() => {

        if (props.config.configOptionsVisibility === 'all' || props.config.configOptionsVisibility === undefined) {
            setVisibleConfig({
                email: props.config.email !== 0,
                mobile: props.config.mobile !== 0,
                phone: props.config.phone !== 0,
                post: props.config.post !== 0
            })
        } else if (props.config.configOptionsVisibility === 'unchecked') {
            setVisibleConfig({
                email: props.config.email !== 0 && !(props.config.userConsents?.contactByEmail),
                mobile: props.config.mobile !== 0 && !(props.config.userConsents?.mobileNotification),
                phone: props.config.phone !== 0 && !(props.config.userConsents?.contactByPhone),
                post: props.config.post !== 0 && !(props.config.userConsents?.contactByPost)
            })
        } else if (props.config.configOptionsVisibility === 'none') {

            if (!props.config.userConsents) {
                setVisibleConfig({
                    email: props.config.email !== 0,
                    mobile: props.config.mobile !== 0,
                    phone: props.config.phone !== 0,
                    post: props.config.post !== 0
                })
            }
        }
    }, [])


    useEffect(() => {
        if (formState.isDirty) {
            trigger(['benefits.email', 'benefits.mobile', 'benefits.phone', 'benefits.post'])
            if ((
                (!email && props.config.email !== 0)
                || (!mobile && props.config.mobile !== 0)
                || (!phone && props.config.phone !== 0)
                || (!post && props.config.post !== 0)
            ) && selectedAll) {
                setSelectedAll(false)
            }
        }
    }, [email, mobile, phone, post])


    const onSelectAll = () => {
        setValue('benefits.email', !selectedAll)
        setValue('benefits.mobile', !selectedAll)
        setValue('benefits.phone', !selectedAll)
        setValue('benefits.post', !selectedAll)
        setSelectedAll(!selectedAll)
    }

    const isRequired = (name) => {
        if (props.config[name] === 2)
            return {required: true};
        if (props.config.required === 2)
            return {required: true};
        if (props.config.required === 1) {
            return {
                required: !(email || mobile || phone || post),
                requiredMessage: t('validation.one_of_these_fields_must_be_specified')
            };
        }
        return {required: false};
    }


    const rules = {
        email: formRules.make({...isRequired('email')}),
        mobile: formRules.make({...isRequired('mobile')}),
        phone: formRules.make({...isRequired('phone')}),
        post: formRules.make({...isRequired('post')}),
    }

    if (!visibleConfig.email && !visibleConfig.mobile && visibleConfig.phone && !visibleConfig.post)
        return null

    return (
        <>
            {!!props.config.selectAllEnabled && (
                <MyCheckbox
                    name="selectAll"
                    value={selectedAll}
                    onChange={onSelectAll}
                    green
                >
                    <b style={{fontWeight: 800}}>{t('select_all_benefits')}</b>
                </MyCheckbox>
            )}
            {getBenefitsOrder().map((benefitName, idx) => {
                if (!!visibleConfig[benefitName]) {
                    return (
                        <Controller
                            key={idx}
                            name={`benefits.${benefitName}`}
                            control={control}
                            rules={rules[benefitName]}
                            render={({onChange, value}) => (
                                <MyCheckbox
                                    name={benefitName}
                                    value={value}
                                    error={errors.benefits ? errors.benefits[benefitName] : null}
                                    onChange={v => props.config[benefitName] === 4 ? undefined : onChange(v)}
                                    green
                                >
                                    <b dangerouslySetInnerHTML={{__html: t(`${props.modulePrefix ?? ''}receive_benefits_${benefitName}`)}}/>
                                </MyCheckbox>
                            )}
                        />
                    )
                }
            })}
        </>
    );
}
