import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PlanSelection} from "../../components/stay_home/_index";
import {useTranslation} from "../../misc/Hooks";
import * as stayHomeReducer from '../../redux/stayHomeReducer';
import {useShActions} from "../../actions/_index";


let MOUNTED = false;

export const PlanSelectionController = (props) => {
    const dispatch = useDispatch();
    const store = useSelector(({stayHome}) => ({
        token: stayHome.token,
        products: stayHome.products.filter(p => p.product_type.startsWith('plan_')),
        categories: stayHome.categories,
        cart: stayHome.cart,
    }))
    const t = useTranslation();
    const shActions = useShActions();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        MOUNTED = true;
        document.title = t('sh_plan_selection.title')

        getPlans();

        return () => {
            MOUNTED = false;
        }
    }, [])


    const getPlans = () => {
        setLoading(true);
        shActions.plans(store.token).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                if(MOUNTED) {
                    // setPlans(res.data);
                    dispatch(stayHomeReducer.setProducts(res.data.products))
                    dispatch(stayHomeReducer.setCategories(res.data.categories))
                }
            }
            else {
                console.log('Something gone wrong! ', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
            if(MOUNTED) {
                setLoading(false);
            }
        })
    }


    const handleChange = (plan) => {
        // dispatch(stayHomeReducer.setPlan(plan))
    }


    return (
        <PlanSelection
            cart={store.cart}
            plans={store.products}
            planSelected={store.cart.items.find(i => store.products.find(p => p.id_product === i.id))}
            // plan={store.plan}
            loading={loading}
            onChange={handleChange}
        />
    )
}
