import {useTranslation} from "../../../misc/Hooks";
import React from "react";
import {Preloader} from "../../../misc/_index";

export default function VerificationResellerScreen(props) {
    const t = useTranslation();

    return (
        <>

                <div className="resAuth">

                    <div className="resAuth__content">
                        <h1 className="par-1 --alignCenter">{t('reseller_verification.title')}</h1>
                        <h2 className={`par-2 --alignCenter --uppercase --mb3 --${props.statusVerification}`}>
                            {props.statusVerification === null ? t('reseller_verification.wait') : props.statusVerification === 'success' ? t(`reseller_verification.success_${props.paramsStatus}`) : t('reseller_verification.error')}
                        </h2>

                        <div className="--mb4">
                            {!!props.loading && (
                                <Preloader/>
                            )}
                        </div>

                    </div>
                </div>
        </>
    )
}
