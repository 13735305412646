import {useShStepNavigation, useTranslation} from "../../misc/Hooks";
import {CustomerShForm} from "../forms/_index";
import {Preloader} from "../../misc/_index";


export const CustomerInformation = (props) => {
    const t = useTranslation();
    const stepNavigation = useShStepNavigation();

    return (
        <div className="shCI">

            <p className="par-1 --bold">{t('ev_customer_information.your_details')}</p>

            <div className="--mb4">
                <CustomerShForm
                    stepNavigation={stepNavigation}
                    country={props.country}
                    customer={props.customer}
                    loading={props.loading}
                    onSubmit={props.onSubmit}
                />
            </div>

            {(!!props.loading && !props.loadingSignIn) && (
                <div className="grid">
                    <Preloader/>
                    <div/>
                </div>
            )}

        </div>
    )
}
