import React from "react";
import {Preloader} from "../misc/_index";


/**
 * Page preloader
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
export default function PagePreloader({text}) {
    return (
        <div className="pagePreloader">
            <div className="--mb3">
                <img src={require('../assets/img/logo-black.svg').default} alt="Nespresso"
                     className="pagePreloader__logo"/>
            </div>
            <Preloader classes="-sm --mb3"/>
            {!!text && (
                <p className="par-4">{text}</p>
            )}
        </div>
    )
}
