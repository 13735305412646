import React from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyInput, MySelect} from "./inputs/_index";
import {Button} from "../buttons/_index";
import {useMarketConfig, useTranslation} from "../../misc/Hooks";


const SignInResellerShopForm = (props) => {
    const t = useTranslation();
    const marketConfig = useMarketConfig();
    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            shop: null,
            firstname: '',
            lastname: '',
        }
    });


    const rules = {
        shop: {required: {value: true, message: t('validation.required')}},
        firstname: {required: {value: true, message: t('validation.required')}},
        lastname: {required: {value: true, message: t('validation.required')}},
    }

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>


                <Controller
                    name="shop"
                    control={formMethods.control}
                    rules={rules.shop}
                    render={({onChange, value}) => (
                        <MySelect
                            name="shop"
                            label={t('shop')}
                            options={mapShopsToOptions(props.shops)}
                            value={value}
                            error={formMethods.errors.shop}
                            onChange={onChange}
                            isSearchable
                        />
                    )}
                />

                {!!marketConfig?.reseller_custom_name && (
                    <>
                        <Controller
                            name="firstname"
                            control={formMethods.control}
                            rules={rules.firstname}
                            render={({onChange, value}) => (
                                <MyInput
                                    name="firstname"
                                    label={t('firstname')}
                                    value={value}
                                    error={formMethods.errors.firstname}
                                    onChange={onChange}
                                />
                            )}
                        />

                        <Controller
                            name="lastname"
                            control={formMethods.control}
                            rules={rules.lastname}
                            render={({onChange, value}) => (
                                <MyInput
                                    name="lastname"
                                    label={t('lastname')}
                                    value={value}
                                    error={formMethods.errors.lastname}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </>
                )}



                <Button
                    btnType="submit"
                    type="primary"
                    label={props.submitText ?? t('continue')}
                    onClick={formMethods.handleSubmit(props.onSubmit)}
                />
            </form>
        </FormProvider>
    );
}


function mapShopsToOptions(shops) {
    return shops?.map(s => ({value: s.id_shop, label: s.shop_title}));
}


export default SignInResellerShopForm;
