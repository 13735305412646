import {useSelector} from "react-redux";


/**
 * Country options
 * @returns {{countryOptions: *[], languageOptions: *[]}|{countryOptions: [{label: *, value: *}], findCountry: ((function(*=): (null|unknown))|*), findLanguage: ((function(*=): (null|*))|*), languageOptions}}
 */
export default function useCountryOptions(countryById = false) {
  const country = useSelector(({main}) => main.country)

  if(!country)
    return null

  const countryOptions =  [{
    label: country.country_title,
    value: countryById ? country.id_country : country.country_shortcode,
  }]
  const languageOptions = country.country_languages.map(l => ({
    label: l.lang_title,
    value: l.lang_code,
  }))

  const findCountry = (value) => {
    if(!value) return null
    if(typeof value === 'object')
      return countryOptions.find(v => v.value === value.value)
    return countryOptions.find(v => v.value === value)
  }

  const findLanguage = (value) => {
    if(!value) return null
    if(typeof value === 'object')
      return languageOptions.find(v => v.value === value.value)
    return languageOptions.find(v => v.value === value)
  }

  return {countryOptions, languageOptions, findCountry, findLanguage}
}
