import React, {useState} from 'react';
import {Button, PlusMinusButton} from "./buttons/_index";
import {PackageItemModal} from "./modals/_index";
import {useCart, usePriceFormatter, useTranslation} from "../misc/Hooks";
import {getImage} from "../misc/Helpers";


interface Props {
    item: any,
    cart: any,
    onChange: void,
}


export default (props: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const {formatPrice, formatPriceObj, checkPromoPrice} = usePriceFormatter();
    const {updateItem, isItemLocked} = useCart();
    const t = useTranslation();

    const hasPromoPrice = checkPromoPrice(
        parseInt(props.item.product_price_promo?.status),
        props.item.product_price_promo?.date_from,
        props.item.product_price_promo?.date_to,
    );

    const itemInCart = props.cart?.items.find(i => i.id === props.item.id_product);
    const isLocked = isItemLocked(props.item.id_product)

    const coverImage = getImage(props.item.product_gallery);

    return (
        <div className="promoItem">
            <div className="promoItem__header">
                {!!coverImage && (
                    <div className="promoItem__thumb">
                        <img src={coverImage} alt={props.item.product_title}/>
                    </div>
                )}
                <h3 className="promoItem__title">{props.item.product_title}</h3>
                <p
                    className="promoItem__text"
                    dangerouslySetInnerHTML={{__html: props.item.product_lead}}
                />
            </div>


            <div className="promoItem__body">
                <div className="packageItem__price">
                    {!hasPromoPrice ? (
                        <span>{formatPriceObj(props.item.product_price)}</span>
                    ) : (
                        <>
                            <small><s className="--textDark" style={{marginRight: '6px'}}>{formatPriceObj(props.item.product_price)}</s></small>
                            <span>{formatPriceObj(props.item.product_price_promo)}</span>
                        </>
                    )}
                </div>

                <button
                    className="link -primary"
                    onClick={() => setModalVisible(true)}
                >{t('read_more')}</button>
            </div>


            {!!props.item.product_plan_description2 && (
                <div
                    className="packageItem__content"
                    dangerouslySetInnerHTML={{__html: props.item.product_plan_description2}}
                />
            )}



            <div className="promoItem__footer">
                {!props.item.product_additional?.length ? (
                    <div className="coffeeItem__button">
                        {itemInCart ? (
                            <PlusMinusButton
                                type="primary"
                                size="sm"
                                plusDisabled={itemInCart.value >= props.item.product_max_qty}
                                value={itemInCart.value * props.item.product_package_qty}
                                step={props.item.product_step}
                                packageQty={props.item.product_package_qty}
                                max={props.item.product_max_qty ?? undefined}
                                onClickMinus={() => updateItem(props.item.id_product, (itemInCart?.value ?? 0) - props.item.product_step)}
                                onClickPlus={() => updateItem(props.item.id_product, (itemInCart?.value ?? 0) + props.item.product_step)}
                                onChange={v => updateItem(props.item.id_product, v)}
                            />
                        ) : (
                            <Button
                                type="primary"
                                size="sm"
                                label={t('buy_now')}
                                disabled={isLocked}
                                onClick={() => updateItem(props.item.id_product, props.item.product_step)}
                            />
                        )}
                    </div>
                ) : (
                    <div className="coffeeItem__button">
                        <Button
                            type="primary"
                            size="sm"
                            label={t(itemInCart ? 'remove_from_cart' : 'buy_now')}
                            disabled={isLocked}
                            onClick={() => !itemInCart ? setModalVisible(true) : updateItem(props.item.id_product, 0)}
                        />
                    </div>
                )}
            </div>


            {!!modalVisible && (
                <PackageItemModal
                    visible={modalVisible}
                    item={props.item}
                    itemInCart={itemInCart}
                    hideModal={() => setModalVisible(false)}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    )
}
