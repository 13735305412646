import React from "react";
import GeneratingReport from "./GeneratingReport";
import Orders from "./Orders";
import Reports from "./Reports";

export default (props) => {
    return (
        <>
            <GeneratingReport data={props.generateModule}/>
            <Orders data={props.ordersModule}/>
            <Reports data={props.reportsModule}/>
        </>
    )
}
