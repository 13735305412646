import React, {useEffect, useState} from 'react';
import {Button} from "./buttons/_index";
import {PlanMachineItemModal} from "./modals/_index";
import {useCart, usePriceFormatter, useTranslation} from "../misc/Hooks";
import {getImage} from "../misc/Helpers";
import {Config} from "../utils/_index";
import {useDispatch} from "react-redux";
import * as stayHomeReducer from "../redux/stayHomeReducer";


interface Props {
    storeName: string,
    item: any,
    cart: any,
    onChange: void,
}


export const PlanMachineItem = (props: Props) => {
    const dispatch = useDispatch()
    const [modalVisible, setModalVisible] = useState(false);
    const {formatPriceObj} = usePriceFormatter();
    const {updateItem, isItemLocked} = useCart(props.storeName);
    const t = useTranslation();
    const [planMachine, setPlanMachine] = useState(props.item.product_plan_variant[0])


    useEffect(() => {
        const plan = props.cart.items.find(i => i.id === props.item.id_product)
        if(plan) {
            dispatch(stayHomeReducer.setPlanMachine(planMachine))
        }
    }, [props.cart])


    const onChangePlanMachine = (planMachine) => {
        setPlanMachine(planMachine)
        const plan = props.cart.items.find(i => i.id === props.item.id_product)
        if(plan) {
            dispatch(stayHomeReducer.setPlanMachine(planMachine))
        }
    }


    const itemInCart = props.cart?.items.find(i => i.id === props.item.id_product);
    const isLocked = isItemLocked(props.item.id_product);

    const coverImage = getImage(planMachine.machine_gallery ?? props.item.product_gallery);

    return (
        <div className="promoItem">
            <div className="promoItem__header">
                {!!coverImage && (
                    <div className="promoItem__thumb">
                        <img src={coverImage} alt={props.item.product_title}/>
                    </div>
                )}
                <h3 className="promoItem__title">{props.item.product_title}</h3>
                <p
                    className="promoItem__text"
                    dangerouslySetInnerHTML={{__html: props.item.product_plan_description1}}
                />
            </div>


            <div className="promoItem__body">
                <div className="promoItem__line">
                    {!!props.item.product_plan_price && (
                        <>
                            <h4 className="promoItem__price">{formatPriceObj(props.item.product_plan_price, 0)}</h4>
                            {props.item.product_type === Config.PROMO_TYPE.SUBSCRIPTION_COFFEE && (
                                <h4 className="promoItem__period">/ Month</h4>
                            )}
                        </>
                    )}
                </div>
                {props.item.product_type === Config.PROMO_TYPE.SUBSCRIPTION_COFFEE ? (
                    <p className="promoItem__label">{t('for_your_coffee')}</p>
                ) : (
                    <p className="promoItem__label">{t('for_your_first_coffee_order')}</p>
                )}

                <button
                    className="link -primary"
                    onClick={() => setModalVisible(true)}
                >{t('read_more')}</button>
            </div>

            {!!props.item.product_plan_variant?.length && (
                <div className="gearColors">
                    <h4 className="gearColors__title">{planMachine.machine_title}</h4>
                    <div className="gearColors__items">
                        {props.item.product_plan_variant.map(v => (
                            <button
                                key={v.id_machine}
                                className={`gearColor ${planMachine.id_machine === v.id_machine ? '-active' : ''}`}
                                style={{backgroundColor: v.color}}
                                onClick={() => onChangePlanMachine(v)}
                            />
                        ))}
                    </div>
                </div>
            )}


            <div
                className="promoItem__content"
                dangerouslySetInnerHTML={{__html: props.item.product_plan_description2}}
            />


            <div className="promoItem__footer">
                <div className="coffeeItem__button">
                    <Button
                        type="primary"
                        size="sm"
                        label={t(!itemInCart ? 'buy_now' : 'remove_from_cart')}
                        onClick={() => updateItem(props.item.id_product, !itemInCart ? props.item.product_step : 0)}
                        disabled={isLocked && !itemInCart}
                    />
                    {/*<Button*/}
                    {/*    type="primary"*/}
                    {/*    size="sm"*/}
                    {/*    label={t(!isInCart ? 'buy_now' : 'remove_from_cart')}*/}
                    {/*    onClick={() => props.onChange(!isInCart ? props.item : null)}*/}
                    {/*    disabled={props.selectedPlan && !isInCart}*/}
                    {/*/>*/}
                </div>
            </div>


            {!!modalVisible && (
                <PlanMachineItemModal
                    visible={modalVisible}
                    item={props.item}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    )
}
