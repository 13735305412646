import {useState} from 'react';
import {Footer, Header} from "./misc/_index";
import {GetInTouchModal} from "./modals/_index";
import {Preloader} from "../misc/_index";
import {useTranslation} from "../misc/Hooks";

import '../assets/style/vendors/skeleton.css';


export default (props) => {
    const [gitModalVisible, setGitModalVisible] = useState(false);
    const t = useTranslation();

    return (
        <div className="app">
            <Header cartDisabled/>

            <div className="main">
                <div className="wrapper-bg formContainer">

                    <div className="wrapper">


                        <div>
                            {props.loading ? (
                                <Preloader/>
                            ) : props.data ? (
                                <div className="par-2 --mb4" style={{overflow: 'hidden'}}>
                                    <div dangerouslySetInnerHTML={{__html: props.data.thank_you_page_text}}/>
                                </div>
                            ) : (
                                <div className="--mb4" style={{textAlign: 'center'}}>
                                    <h1 className="par-1">{t('order_finalized.order_not_found.title')}</h1>
                                    <p className="par-2">{t('order_finalized.order_not_found.text')}</p>
                                </div>
                            )}
                        </div>

                        <div className="par-4 --semiBold --alignCenter">
                            <span>{t('need_help')} </span>
                            <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                                {t('get_in_touch')}
                            </a>
                        </div>

                    </div>
                </div>
            </div>


            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />


            <Footer/>
        </div>
    )
}
