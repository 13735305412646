import React, {useEffect, useState} from 'react';
import {useTranslation} from "../../misc/Hooks";
import {isoDateConverter} from "../../misc/Helpers";
import {useResellerActions} from "../../actions/useResellerActions";
import Reports from "../../components/reseller/Reports";


let MOUNTED = false;

export default function ReportsContainer(props) {
    const t = useTranslation();
    const resellerActions = useResellerActions();

    const [modalVisible, setModalVisible] = useState(false);
    const [loadingReport, setLoadingReport] = useState({
        loading: false,
        status: '',
        message: '',
        isFinished: false,
    })
    const [dataState, setDataState] = useState({
        data: [],
        maxPage: 1,
        results: 0,
        loading: true,
    })
    const [dataReportsState, setDataReportsState] = useState({
        data: [],
        maxPage: 1,
        results: 0,
        loading: true,
        page: 1,
    })
    const [filtersData, setFiltersData] = useState({
        resellers: [],
        resellerChildren: [],
        shops: [],
        loading: true,
    })
    const [ordersFiltersOptions, setOrdersFiltersOptions] = useState({
        resellers: [],
        shops: [],
        loading: true
    })

    useEffect(() => {
        MOUNTED = true;
        filters()
        getOrdersFilterOptions()
        return () => {
            MOUNTED = false;
        }
    }, [])

    const getOrders = async (page, pageSize, searchData = null, filters = {}) => {
        setDataState((old) => ({
            ...old,
            loading: true
        }))

        const params = `limit=${pageSize}&page=${page}`;
        const payload = {
            sort: null,
            slug: searchData?.slug,
            order_type: null,
            date_from: filters.date_from ? isoDateConverter(filters.date_from, '-') : null,
            date_to: filters.date_to ? isoDateConverter(filters.date_to, '-') : null,
            id_shop: filters.shops ? filters.shops.map((shop) => shop.value) : [],
            id_reseller: filters.resellers ? filters.resellers.map((reseller) => reseller.value) : [],
            status: [],
        }

        await resellerActions.getOrders(payload, params)
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    setDataState({
                        data: res.data.data,
                        results: res.data.total,
                        maxPage: res.data.last_page,
                        loading: false,
                    })
                } else {

                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    const getOrdersFilterOptions = () => {
        const params = `all_resellers=1`;

        const data = {
            id_shop: []
        }
        resellerActions.reportsFilter(params, data).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                setOrdersFiltersOptions({
                    resellers: res.data.resellers,
                    shops: res.data.shops,
                    loading: false
                })
            } else {
                console.log(res)
            }
        }).catch(err => {
            console.error(err)
        })
    }

    const filters = (id_reseller = 0, id_shop = [], type = null) => {
        setFiltersData((old) => ({
            ...old,
            loading: true
        }))
        const params = `id_reseller=${id_reseller}`;

        const data = {
            id_shop
        }

        resellerActions.reportsFilter(params, data).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (type) {
                    if (type === 'shop') {
                        console.log(res.data)
                        setFiltersData((old) => ({
                            ...old,
                            resellers: res.data.resellers,
                            resellerChildren: [],
                            loading: false
                        }))
                    } else if (type === 'reseller') {
                        setFiltersData((old) => ({
                            ...old,
                            resellerChildren: res.data.resellers,
                            loading: false
                        }))
                    }
                } else {
                    setFiltersData({
                        resellers: res.data.resellers,
                        resellerChildren: [],
                        shops: res.data.shops,
                        loading: false
                    })
                }
            } else {
                console.log(res)
            }
        }).catch(err => {
            console.error(err)
        })
    }

    const onSubmitReport = (data) => {
        setLoadingReport({
            loading: true,
            status: '',
            message: '',
            isFinished: false,
        })

        const postData = {
            reseller_id: data.reseller_id,
            shop_id: data.id_shop,
            date_from: isoDateConverter(data.date_from, '-'),
            date_to: isoDateConverter(data.date_to, '-'),
            translations: {
                columns: {
                    id: t('order.id'),
                    status_of_registration: t('order.status_of_registration'),
                    status_of_payment: t('order.status_of_payment'),
                    data_time: t('order.date_time'),
                    machine_type: t('order.machine_type'),
                    serial_number: t('order.serial_number')
                },
                subscription_status: {
                    "-3": t('subscription_status.-3'),
                    "-1": t('subscription_status.-1'),
                    "0": t('subscription_status.0'),
                    "1": t('subscription_status.1'),
                    processed: t('subscription_status.processed'),
                    in_treatment: t('subscription_status.in_treatment')
                },
                payment_status: {
                    "-1": t('payment_status.-1'),
                    expired: t('payment_status.expired'),
                    "0": t('payment_status.0'),
                    "1": t('payment_status.1'),
                    "2": t('payment_status.2'),
                }
            }
        }

        resellerActions.generateRaport(postData).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                setLoadingReport({
                    loading: false,
                    status: 'success',
                    message: 'Success!',
                    isFinished: true,
                })

            } else {
                console.warn('Something gone wrong!')
                setLoadingReport({
                    loading: false,
                    status: 'error',
                    message: 'Error!',
                    isFinished: true,
                })
            }
        }).catch(err => {
            console.error(err)
            setLoadingReport({
                loading: false,
                status: 'error',
                message: 'Error!',
                isFinished: false,
            })
        })

    }

    const getGeneratedReports = async (page, pageSize, searchData = null) => {
        let params = `limit=${pageSize}&page=${page}`;
        if (searchData?.slug) {
            params += `&slug=${searchData.slug}`
        }

        setDataReportsState((old) => ({
            ...old,
            loading: true
        }))

        let items = [];
        await resellerActions.getGeneratedReports(params).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                items = [...res.data.data];
                if (MOUNTED) {
                    setDataReportsState({
                        data: items,
                        maxPage: res.data.last_page,
                        results: res.data.total,
                        loading: false,
                        page: res.data.current_page,
                    })
                }
                // setTimeout(()=> {
                //    getGeneratedReports(urlSearchParams.get('reportsCurrent'), urlSearchParams.get('reportsLimit')).then(r => {})
                // }, 4000)
            } else {
                console.warn('Something gone wrong!')
            }
        }).catch(err => {
            console.error('Error: ', err);
        }).finally(async () => {
            // await new Promise((resolve) => setTimeout(resolve, 5000))
            // const urlSearchParams = new URLSearchParams(location.search)
            // if(Number(page) === Number(urlSearchParams.get('reportsCurrent'))){
            //    await getGeneratedReports(urlSearchParams.get('reportsCurrent'), urlSearchParams.get('reportsLimit')).then(r => {})
            // }
        })
    }


    return (
        <div className="wrapper -xxl">
            <Reports
                loadingReport={loadingReport}
                setLoadingReport={setLoadingReport}
                onSubmit={onSubmitReport}

                getDataReports={getGeneratedReports}
                reports={dataReportsState.data}
                reportsMaxPage={dataReportsState.maxPage}
                reportsResults={dataReportsState.results}
                reportsLoading={dataReportsState.loading}

                setModalVisible={setModalVisible}
                modalVisible={modalVisible}

                orders={dataState.data}
                ordersMaxPage={dataState.maxPage}
                ordersResults={dataState.results}
                ordersLoading={dataState.loading}
                getData={getOrders}

                filtersData={filtersData}
                getFiltersData={filters}

                ordersFiltersOptions={ordersFiltersOptions}
            />
        </div>
    )
}
