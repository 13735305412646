import React, {useState} from 'react';
import {Button, PlusMinusButton} from "./buttons/_index";
import {CoffeeItemModal, EasyOrderGiftItemModal, PlanItemModal} from "./modals/_index";
import {useCart, usePriceFormatter, useTranslation} from "../misc/Hooks";
import {getImage} from "../misc/Helpers";
import {Config} from "../utils/_index";


interface Props {
    item: any,
    cart: any,
    onChange: void,
}


export default (props: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const {formatPriceObj} = usePriceFormatter();
    const {updateItem, isItemLocked} = useCart();
    const t = useTranslation();


    const itemInCart = props.cart?.items.find(i => i.id === props.item.id_product);
    const isLocked = isItemLocked(props.item.id_product);

    const coverImage = getImage(props.item.product_gallery);

    return (
        <div className="promoItem">
            <div className="coffeeItem__header">
                <div className="coffeeItem__thumb">
                    <img src={getImage(props.item.product_gallery)} alt=""/>
                </div>
                <h3 className="coffeeItem__title">{props.item.product_title}</h3>
            </div>

            <div className="coffeeItem__body">
                {!!props.item.product_lead && (
                    <p className="coffeeItem__subtitle">{props.item.product_lead}</p>
                )}

                {/*<div className="coffeeItem__price">*/}
                {/*    {!hasPromoPrice ? (*/}
                {/*        <span>{formatPriceObj(props.item.product_price)}</span>*/}
                {/*    ) : (*/}
                {/*        <>*/}
                {/*            <small><s className="--textDark" style={{marginRight: '6px'}}>{formatPriceObj(props.item.product_price)}</s></small>*/}
                {/*            <span>{formatPriceObj(props.item.product_price_promo)}</span>*/}
                {/*        </>*/}
                {/*    )}*/}
                {/*</div>*/}

                <div className="coffeeItem__link --mb2_5" style={{paddingTop: '0.4rem'}}>
                    <button className="link -primary" onClick={() => setModalVisible(true)}>{t('read_more')}</button>
                </div>

                {/*{props.item.product_type === Config.COFFEE_TYPE.CAPSULE && !!props.item.product_intensity ? (*/}
                {/*    <div className="coffeeItem__intensity">*/}
                {/*        <div className="coffeeItem__intensityBox">*/}
                {/*            {intensityBox.map(item => item)}*/}
                {/*        </div>*/}
                {/*        <span className="coffeeItem__intensityText">{t('coffee.intensity')} {props.item.product_intensity}</span>*/}
                {/*    </div>*/}
                {/*) : <div className="--mb1"/>}*/}

                <div className="coffeeItem__button">
                    <Button
                        type="primary"
                        size="sm"
                        label={t(!itemInCart ? 'select' : 'remove_from_cart')}
                        onClick={() => updateItem(props.item.id_product, !itemInCart ? props.item.product_step : 0)}
                        disabled={isLocked && !itemInCart}
                    />

                </div>
            </div>


            {!!modalVisible && (
                <EasyOrderGiftItemModal
                    visible={modalVisible}
                    item={props.item}
                    itemInCart={itemInCart}
                    isLocked={isLocked}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    )
}
