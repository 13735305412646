import UsersScreen from "../screens/UsersScreen";
import {useHistory, useParams} from "react-router-dom";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigation} from "../../../../misc/Hooks";
import {Routes} from "../../../../utils/_index";

let MOUNTED = false;

export default function UsersContainer(props) {
    const actions = useResellerActions();
    const params = useParams();
    const history = useHistory();
    const navigation = useNavigation();

    const [reseller, setReseller] = useState(null)
    const [resellerLoading, setResellerLoading] = useState(true)
    const [resellerMaxPage, setResellerMaxPage] = useState(1);
    const [resellerResults, setResellerResults] = useState(0);

    const [resellerRequest, setResellerRequest] = useState(null)
    const [newRequestLoading, setNewRequestLoading] = useState(true)
    const [resellerRequestMaxPage, setResellerRequestMaxPage] = useState(1);
    const [resellerRequestResults, setResellerRequestResults] = useState(0);
    const [resellerRequestItems, setResellerRequestItems] = useState(null)

    const [resellerRequestCurrentPage, setResellerRequestCurrentPage] = useState(1)
    const [resellerRequestPageLimit, setResellerRequestPageLimit] = useState(10)

    const store = useSelector(({main}) => ({
        user: main.user
    }))

    const getResellerChildren = (page, pageSize, status = '-1', certificate = '-1', searchData = null) => {
        let paramsTable = `limit=${pageSize}&page=${page}&status=${status}&certificate_expired_at=${certificate}`;
        if (searchData?.slug)
            paramsTable += `&slug=${searchData.slug}`

        setResellerLoading(true)
        actions.getResellerChildren(params.id ?? store.user.id_reseller, paramsTable).then(r => {
            const res = r.data;
            if (res.status === 'success' && MOUNTED) {
                    setReseller(res.data)
                    setResellerMaxPage(res.data.childs.last_page);
                    setResellerResults(res.data.childs.total);
                    setResellerLoading(false)
            } else {
                console.warn('Something gone wrong!', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        })
    }

    const getNewRequest = (page, pageSize, searchData = null) => {
        let paramsTable = `limit=${pageSize}&page=${page}`
        if (searchData?.slug)
            paramsTable += `&slug=${searchData.slug}`

        setNewRequestLoading(true)
        actions.getNewRequest(paramsTable).then(r => {
            const res = r.data;
            if (res.status === 'success' && MOUNTED) {
                    setResellerRequest({...res.data})
                    setResellerRequestItems(res.data.data)
                    setResellerRequestMaxPage(res.data.last_page);
                    setResellerRequestResults(res.data.total);

                    setResellerRequestCurrentPage(page)
                    setResellerRequestPageLimit(pageSize)

                    setNewRequestLoading(false)
            } else {
                console.warn('Something gone wrong!', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        })
    }

    const certificateRenewal = (id) => {
        const data = {id_reseller: typeof id === 'number' ? [id] : id}

        actions.certificateRenewal(data).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                const arr = [...reseller?.childs?.data]
                res.data.map(d => {
                    const idx = arr.findIndex(r => r.id_reseller == d.id_reseller)
                    if (idx !== -1) {
                        arr[idx] = d;
                    }
                })
                setReseller({...reseller, childs: {...reseller.childs, data: arr}})
            } else {
                console.warn('Something went wrong!', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        })
    }

    const changeStatus = (id, status) => {
        const data = {
            id_reseller: typeof id === "number" ? [id] : id,
            reseller_status: status
        }
        actions.changeResellerStatus(data).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                const arr = [...reseller?.childs?.data]
                res.data.map(d => {
                    const idx = arr.findIndex(r => r.id_reseller == d.id_reseller)
                    if (idx !== -1) {
                        arr[idx] = d;
                    }
                })
                setReseller({...reseller, childs: {...reseller.childs, data: arr}})
            } else {
                console.warn('Something went wrong!', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        })
    }

    const resetPassword = (id) => {
        actions.sendResetPasswordEmail(id).then(r => {
            const res = r.data;
            if (res.status === 'success') {

            } else {
                console.warn('Something went wrong!', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        })
    }

    const resellerVerify = (id, status = 1) => {
        const postData = {
            id_reseller: typeof id === 'number' ? [id] : id,
            verified: status
        }
        actions.resellerVerify(postData).then(r => {
            const res = r.data
            if (res.status === 'success') {
                getNewRequest(resellerRequestCurrentPage, resellerRequestPageLimit)
                getResellerChildren(resellerRequestCurrentPage, resellerRequestPageLimit)
            } else {
                console.warn('Something went wrong!', res)
            }
        }).catch(err => {
            console.error('Error: ', err)
        })
    }

    useEffect(() => {
        MOUNTED = true;
        if (store.user.reseller_role === "pos_user")
            history.push(navigation(Routes.RESELLER))
        return () => { MOUNTED = false }
    }, [])

    useEffect(() => {
        getResellerChildren(1, 10)
        getNewRequest(1, 10)
    }, [params.id])

    return (
        <UsersScreen
            reseller={reseller}
            resellerLoading={resellerLoading}
            certificateRenewal={certificateRenewal}
            changeStatus={changeStatus}
            resetPassword={resetPassword}
            resellerMaxPage={resellerMaxPage}
            resellerResults={resellerResults}
            getData={getResellerChildren}

            resellerRequest={resellerRequest}
            newRequestLoading={newRequestLoading}
            resellerRequestMaxPage={resellerRequestMaxPage}
            resellerRequestResults={resellerRequestResults}
            getNewRequestData={getNewRequest}
            resellerRequestItems={resellerRequestItems}
            resellerVerify={resellerVerify}
        />
    )
}
