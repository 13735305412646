
const config = {
    /**
     * Professions select field
     */
    profession: ['gr'],

    /**
     * Regions select field
     */
    region: ['cl'],

    /**
     * Bigger promo select button
     */
    biggerPromoSelectButton: ['it'],

    /**
     * Hide country field
     */
    hideCountryField: ['nl'],

    /**
     * Auto uppercase in post code input
     */
    autoUppercasePostCode: ['ca'],

    /**
     * Image and text under customer information form
     */
    customBenefitsInfo: ['ca'],

    /**
     * Select the first available country in form by default
     */
    selectDefaultFirstCountry: ['fr', 'ca', 'it', 'au'],

    /**
     * Select the default lang in form by default
     */
    selectDefaultLang: ['fr', 'ca', 'it', 'au'],

    /**
     * Hide promo title
     */
    hidePromoTitle: ['il'],

    /**
     * Small form submit button (confirm/buy button)
     */
    smallFormSubmitButton: ['ch'],

    /**
     * Hide civility input
     */
    hideCivility: ['kr'],

    /**
     * Display shop name and city as shop label
     */
    shpNameAndCityLabel: ['belux'],

    /**
     * Hide total price info (summary step) in machine subscription module
     */
    hideTotalPriceInfoMachineSubscription: ['nl'],

    /**
     * Hide ingredients in Coffee Item Modal
     */
    hideIngredients: ['belux'],

    /**
     * Hide ingredients and allergens in Coffee Item Modal
     */
    hideIngredientsAndAllergens: ['belux'],
}

const stacks = {
    default: [
        'coffee_type',
        'cup_size',
        'aromatic_profile',
        'civility',
        'coffee_line',
    ],
    gr: ['profession'],
    cl: ['region'],
}

const images = {
    default: {
        machine: require('../assets/img/machine.png').default,
        helpMachine: require('../assets/img/sn-help-machine.png').default,
        helpNumber: require('../assets/img/sn-help-number.png').default,
        customBenefitsInfo: require('../assets/img/ca-opt-in.jpg').default
    },
    it: {
        machine: require('../assets/img/machine-it.png').default,
        helpMachine: require('../assets/img/sn-help-machine-it.jpg').default,
        helpNumber: require('../assets/img/sn-help-number-it.jpg').default,
    },
    ca: {
        machine: require('../assets/img/machine-ca.png').default,
    }
}

const benefitsOrder = {
    default: ['mobile', 'email', 'phone', 'post'],
    it: ['email', 'mobile', 'phone', 'post'],
    belux: ['phone', 'mobile', 'email', 'post'],
    co: ['email', 'mobile', 'phone', 'post'],
}

const priceFormat = {
    co: 'es-CO',
}

/**
 * Determines if feature is enabled in current market. Current market is defined in project environment (.env file)
 * @param {string: 'profession'
 * | 'region'
 * | 'biggerPromoSelectButton'
 * | 'hideCountryField'
 * | 'autoUppercasePostCode'
 * | 'customBenefitsInfo'
 * | 'selectDefaultFirstCountry'
 * | 'selectDefaultLang'
 * | 'hidePromoTitle'
 * | 'hideCivility'
 * | 'smallFormSubmitButton'
 * | 'shpNameAndCityLabel'
 * | 'hideTotalPriceInfoMachineSubscription'
 * | 'hideIngredients'
 * | 'hideIngredientsAndAllergens'
 * } prop Property name
 * @returns {boolean}
 */
export function isEnabledByMarket(prop) {
    if (!config[prop])
        return false

    return !!config[prop]
        .map(v => v.toString().toLowerCase())
        .includes(process.env.REACT_APP_MARKET.toLowerCase())
}


/**
 * Returns list of stacks for current market. Current market is defined in project environment (.env file)
 * @returns {string[]}
 */
export function getMarketStacks() {
    const marketStacks = stacks[process.env.REACT_APP_MARKET] ?? []
    const set = new Set([...stacks.default, ...marketStacks])
    return Array.from(set)
}


/**
 * Returns market image.
 * @param {string: 'machine'
 * | 'helpMachine'
 * | 'helpNumber'
 * | 'customBenefitsInfo'
 * } image Image type
 * @returns {{number: string, machine: string}||null}
 */
export function getMarketImage(image) {
    if(!image) return null
    const marketImages = images[process.env.REACT_APP_MARKET] ?? {}
    const obj = {...images.default, ...marketImages}
    return obj[image]
}


/**
 * Returns benefits name in array.
 * @returns {string[]}
 */
export function getBenefitsOrder() {
    return benefitsOrder[process.env.REACT_APP_MARKET] ?? benefitsOrder.default
}


/**
 * Returns locale code for specific market (country code).
 * @returns {string | null}
 */
export function getPriceFormat() {
    return priceFormat[process.env.REACT_APP_MARKET]
}
