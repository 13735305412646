import React from 'react';
import BaseModal from "../../../../components/modals/BaseModal";
import {useNavigation, useTranslation} from "../../../../misc/Hooks";
import {Button} from "../../../../components/buttons/_index";
import {useHistory} from "react-router-dom";


interface Props {
   visible: boolean,
   onClose: void,
}


export default (props: Props) => {
   const t = useTranslation();
   const history = useHistory();

   const onClose = () => {
      props.onClose()
   }

   return (
      <BaseModal
         visible={props.visible}
         onClose={onClose}
      >
      <span
         className={`--dBlock --alignCenter --bold --mb3 ${props.sending.finishStatus === 'success' ? '--success' : '--error'}`}
      >
         {t(`reseller_client.${props.sending.finishStatus}_save`)}
      </span>
         <div>
            <Button
               label={t('reseller_client.back_to_resellers')}
               type="primary-outline"
               style={{
                  margin: "0 auto",
                  display: 'block'
               }}
               onClick={() => history.goBack()}
            />
         </div>
      </BaseModal>
   );
}
