import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import Auth from "../../components/reseller/Auth";
import * as mainReducer from '../../redux/mainReducer';
import * as resellerReducer from '../../redux/resellerReducer';
import * as AuthActions from '../../actions/AuthorizationActions';
import Axios from "../../utils/Axios";
import {Routes} from "../../utils/_index";
import {useMarketConfig, useNavigation} from "../../misc/Hooks";


let MOUNTED = false;

export default (props) => {
   const navigation = useNavigation();
   const dispatch = useDispatch();
   const marketConfig = useMarketConfig();
   const store = useSelector(({main}) => ({
      user: main.user,
   }))
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(false);
   const [user, setUser] = useState(null);
   const [showNewPassword, setShowNewPassword] = useState(false)


   useEffect(() => {
      MOUNTED = true;

      return () => {
         MOUNTED = false;
      }
   }, [])


   const handleSubmit = (data) => {
      setLoading(true);
      setError(null);
      AuthActions.signInReseller(data).then(r => {
         const res = r.data;
         if (res.status === 'success') {

            if (MOUNTED) {
               dispatch(mainReducer.setUserToken(res.data));
               Axios.defaults.headers.get['Authorization'] = `Bearer ${res.data}`;
               Axios.defaults.headers.post['Authorization'] = `Bearer ${res.data}`;
               dispatch(resellerReducer.setLastVisit(Date.now()))
               getUser();
               setShowNewPassword(!!Number(res.messages[0].temporary_password))
            }
         } else {
            if (res.messages?.length) {
               if (MOUNTED) {
                  setError(res.messages[0]);
                  setLoading(false);
               }
            }
            console.warn('Something gone wrong!', res);
         }
      }).catch(err => {
         console.error('Error: ', err)
      })
   }


   const getUser = () => {
      AuthActions.getReseller().then(r => {
         const res = r.data;
         if (res.status === 'success') {
            if (MOUNTED) {
               setUser(res.data);
            }
         } else {
            console.warn('Something gone wrong!', res);
         }
      }).catch(err => {
         console.error('Error: ', err)
      }).finally(() => {
         if (MOUNTED) {
            setLoading(false)
         }
      })
   }


   const handleSubmitShop = (data) => {
      const shopId = data.shop?.value;

      Axios.defaults.headers.get['Shop'] = shopId;
      Axios.defaults.headers.post['Shop'] = shopId;


      if (marketConfig?.reseller_custom_name) {
         dispatch(resellerReducer.setName(data.firstname, data.lastname))
      }

      dispatch(resellerReducer.setShop(shopId))
      dispatch(mainReducer.setUser(user));
   }

   const onSubmitTemporary = (data) => {
      const postData = {
         password: data.password,
         repassword: data.rePassword
      }

      setLoading(true);
      AuthActions.setTemporaryPassword(postData).then(r => {
         const res = r.data;
         if (res.status === 'success') {
            if (MOUNTED) {
               setShowNewPassword(false)
               setLoading(false);
            }
         } else {
            if (res.messages?.length) {
               if (MOUNTED) {
                  setError(...res.messages[0].password);
                  setLoading(false);
               }
            }
            console.warn('Something gone wrong!', res);
         }
      }).catch(err => {
         console.error('Error: ', err)
      })
   }


   if (store.user) {
      return <Redirect to={navigation(Routes.RESELLER)}/>
   }

   return (
      <div className="wrapper">
         <Auth
            user={user}
            error={error}
            loading={loading}
            onSubmit={handleSubmit}
            onSubmitShop={handleSubmitShop}
            showNewPassword={showNewPassword}
            onSubmitTemporary={onSubmitTemporary}
         />
      </div>
   )
}
