import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import type {IStore} from "../interfaces/IStore";
import {
    evReducer,
    mainReducer,
    ofReducer,
    resellerReducer,
    rmsReducer,
    stayHomeReducer,
    tpsReducer,
    eTradeReducer
} from "./_index";


export const initialState = ({
    country: null,
    marketConfig: null,

    recaptchaKey: '',

    user: null,
    userToken: null,
}: IStore)


const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2
}


const persistedReducer = persistReducer(persistConfig, combineReducers({
    ev: evReducer,
    eTrade: eTradeReducer,
    stayHome: stayHomeReducer,
    reseller: resellerReducer,
    rms: rmsReducer,
    of: ofReducer,
    tps: tpsReducer,
    main: mainReducer,
}))


export const store = createStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store)
