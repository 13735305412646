import VerificationResellerScreen from "../screens/VerificationResellerScreen";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as authActions from '../../../actions/AuthorizationActions';
let MOUNTED = false;

export default function VerificationResellerContainer(props) {

    const params = useParams()
    const [statusVerification, setStatusVerification] = useState(null)
    useEffect(() => {
        const status = params.status === 'accept' ? 1 : params.status === 'reject' ? -1 : null
        if(statusVerification === null){
            verification(status)
        }
    }, params)

    const verification = (status) => {
        authActions.verificationReseller(status, params.token, params.parent_token).then(r => {
            const res = r.data
            console.log(res)
            if(res.status === 'success'){
                setStatusVerification('success')
            } else {
                console.log('Something went wrong!', res)
                setStatusVerification('error')
            }
        }).catch(e => {
            console.log('Error: ', e)
            setStatusVerification('error')
        })
    }

    return (
        <VerificationResellerScreen loading={!statusVerification} statusVerification={statusVerification} paramsStatus={params.status}/>
    )
}
