import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import {MyInput} from "./inputs/_index";
import {useTranslation} from "../../misc/Hooks";
import {Button} from "../buttons/_index";


const SHCodeForm = (props) => {
    const t = useTranslation();
    const {control, errors, handleSubmit, setError, clearErrors} = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            code: props.code ?? '',
        }
    });


    const rules = {
        code: {
            required: {
                value: true,
                message: t('validation.required'),
            },
        },
    }


    useEffect(() => {
        if(props.error) {
            setError('code', {type: 'validate', message: t(`error.${props.error}`)})
        }
        else {
            clearErrors('code');
        }
    }, [props.error])


    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>

                <Controller
                    name="code"
                    control={control}
                    rules={rules.code}
                    render={({onChange, value}) => (
                        <MyInput
                            type="email"
                            label={t('stay_home.identification.code_placeholder')}
                            value={value}
                            error={errors.code}
                            onChange={onChange}
                        />
                    )}
                />


                <div className="step__nav">
                    <Button
                        type="primary-outline"
                        label={t('back')}
                        disabled={props.loading}
                        onClick={props.onGoBack}
                    />
                    <Button
                        btnType="submit"
                        type="primary"
                        label={props.submitText ?? t('continue')}
                        disabled={props.loading}
                        onClick={handleSubmit(props.onSubmit)}
                    />
                </div>
            </form>
        </>
    );
}


export default SHCodeForm;
