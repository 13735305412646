import React, {useMemo, useState} from "react";
import Table from "../../Table";
import {useTranslation} from "../../../misc/Hooks";
import {Button} from "../../buttons/_index";
import {isoDateConverterWithTime} from "../../../misc/Helpers";
import {ResellersListModal} from "../../modals/reseller";

const mapReportsToOptions = (reports) => {
    return reports.map(report => ({
        generated_for: {
            shops: report.reseller_report_shops,
            resellers: report.reseller_report_resellers
        },
        date_from: report.reseller_report_date_from,
        date_to: report.reseller_report_date_to,
        created_at: isoDateConverterWithTime(report.created_at, '-', ':'),
        download: {
            url: report.reseller_report_url,
            status: report.reseller_report_status
        }
    }))
}

export default (props) => {
    const t = useTranslation();
    const [resellersList, setResellersList] = useState(null)
    const [modalVisible, setModalVisible] = useState(false);

    const columns = useMemo(() => [
        {
            Header: t('reports.table.generated_for'),
            accessor: 'generated_for',
            Cell: (itemProps) => {
                const data = {
                    items: itemProps.value.shops.length > 0 ? itemProps.value.shops : itemProps.value.resellers,
                    type: itemProps.value.shops.length > 0 ? t('shop') : t('reseller')
                }
                return (
                    <div>
                        {data.items.map((item, idx) => (
                            <>
                                {idx < 3 && (
                                    <span
                                        key={idx} className={'--dBlock'}>{item}{((idx !== data.items.length - 1) && (idx !== 2)) && (', ')}</span>
                                )}
                            </>
                        ))}
                        {data.items.length >= 4 && (
                            <Button size={"sm"}
                                    onClick={() => showResellerModal(data.items)} label={t('reports.table.see_more')}/>
                        )}
                    </div>
                )
            }
        },
        {Header: t('reports.table.date_from'), accessor: 'date_from'},
        {Header: t('reports.table.date_to'), accessor: 'date_to'},
        {Header: t('reports.table.created_at'), accessor: 'created_at'},
        {
            Header: t('reports.table.download'),
            accessor: 'download',
            Cell: (itemProps) => {
                let type = ''
                let label = ''
                switch (itemProps.value.status) {
                    case 0:
                        type = 'grey'
                        label = t('reports.table.button.in_the_process')
                        break
                    case -1:
                        type = 'error'
                        label = t('reports.table.button.error')
                        break
                    default:
                        type = '';
                        label = t('reports.table.button.download')

                }
                return (
                    <>
                        {itemProps.value.status === 1 ? (
                            <a href={itemProps.value.url} target="_blank" download>
                                <Button
                                    label={label}
                                    size={"sm"}
                                    type={type}
                                />
                            </a>
                        ) : (
                            <Button
                                label={label}
                                size={"sm"}
                                type={type}
                            />
                        )}
                    </>
                )
            }
        },
    ], [])

    const reports = useMemo(() => mapReportsToOptions(props.data.config.data), [props.data.config.data])

    const showResellerModal = (resellers) => {
        setResellersList(resellers)
        setModalVisible(true)
    }

    return (
        <>
            <div className="resMS__table">
                <Table
                    title={t('reports.table.title')}
                    columns={columns}
                    data={reports}
                    maxPage={props.data.config.maxPage}
                    results={props.data.config.results}
                    loading={props.data.config.loading}
                    getData={(cp, ps, v = null) => props.data.getData(cp, ps, v)}
                    name="reports"
                    noSearchForm
                    autoFetch
                    repeatFetching
                />
            </div>
            <ResellersListModal
                visible={modalVisible && resellersList}
                onClose={() => setModalVisible(false)}
                resellersList={resellersList}
            />
        </>
    )
}
