import React, {useMemo, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {Button, ButtonIcon} from "../buttons/_index";
import Table from "../Table";
import {useTranslation} from "../../misc/Hooks";
import {useSelector} from "react-redux";
import {GenerateReportModal, ResellersListModal} from "../modals/reseller";
import {isoDateConverterWithTime} from "../../misc/Helpers";
import Env from "../../utils/Env";
import CustomResellerOrderTable from "./CustomResellerOrderTable";

const mapReportsToOptions = (reports) => {
    return reports.map(report => ({
        generated_for: {
            shops: report.reseller_report_shops,
            resellers: report.reseller_report_resellers
        },
        date_from: report.reseller_report_date_from,
        date_to: report.reseller_report_date_to,
        created_at: isoDateConverterWithTime(report.created_at, '-', ':'),
        download: {
            url: report.reseller_report_url,
            status: report.reseller_report_status
        }
    }))
}
const mapOrdersToOptions = (orders) => {
    return orders.map(order => ({
        id: order.id_order,
        pos_name: {
            translate: order.shop_title_translate,
            title: order.shop_title,
        },
        reseller_email: order.reseller_email,
        type_of_order: order.promotion_type,
        tracking_code: order.tracking_code,
        status_of_registration: order.status,
        status_of_payment: order.payment_status,
        date: isoDateConverterWithTime(order.created_at, '-', ':'),
        type_of_machine: order.machine_coffee_type,
        serial_number: order.serial
    }))
}


export default (props) => {
    const t = useTranslation();
    const match = useRouteMatch();
    const store = useSelector(({main}) => ({
        country: main.country,
    }))
    const [resellersList, setResellersList] = useState(null)
    const [modalVisible, setModalVisible] = useState(false);

    const languages = Env.LANGUAGES_FROM_BACKOFFICE ? (
        store.country?.country_languages?.map(l => l.lang_code) ?? Env.LANGUAGES
    ) : Env.LANGUAGES;

    const defaultLang = Env.DEFAULT_LANG ?? languages[0];

    const columnsReports = useMemo(() => [
        {
            Header: t('reports.table.generated_for'),
            accessor: 'generated_for',
            Cell: (itemProps) => {
                const data = {
                    items: itemProps.value.shops.length > 0 ? itemProps.value.shops : itemProps.value.resellers,
                    type: itemProps.value.shops.length > 0 ? t('shop') : t('reseller')
                }
                return (
                       <div>
                          {data.items.map((item, idx) => (
                              <>
                                 {idx < 3 && (
                                     <span key={idx}>{item}{((idx !== data.items.length - 1) && (idx !== 2)) && (', ')}</span>
                                 )}
                              </>
                          ))}
                           {data.items.length >= 3 && (
                               <Button size={"sm"} type="secondary" style={{marginLeft: '.25rem'}} onClick={() => showResellerModal(data.items)} label={t('reports.table.see_more')}/>
                           )}
                       </div>
                )
            }
        },
        {Header: t('reports.table.date_from'), accessor: 'date_from'},
        {Header: t('reports.table.date_to'), accessor: 'date_to'},
        {Header: t('reports.table.created_at'), accessor: 'created_at'},
        {
            Header: t('reports.table.download'),
            accessor: 'download',
            Cell: (itemProps) => {
                let type = ''
                let label = ''
                switch (itemProps.value.status) {
                    case 0:
                        type = 'grey'
                        label = t('reports.table.button.in_the_process')
                        break
                    case -1:
                        type = 'error'
                        label = t('reports.table.button.error')
                        break
                    default:
                        type = '';
                        label = t('reports.table.button.download')

                }
                return (
                    <>
                        {itemProps.value.status === 1 ? (
                            <a href={itemProps.value.url} target="_blank" download>
                                <Button
                                    label={label}
                                    size={"sm"}
                                    type={type}
                                />
                            </a>
                        ) : (
                            <Button
                                label={label}
                                size={"sm"}
                                type={type}
                            />
                        )}
                    </>
                )
            }
        },
    ], [])

    const columns = useMemo(() => [
        {Header: t('assisted_registration.id'), accessor: 'id'},
        {
            Header: t('assisted_registration.pos_name'),
            accessor: 'pos_name',
            Cell: itemProps => {
                let posName = null;
                if (itemProps.value.translate) {
                    const json = JSON.parse(itemProps.value.translate);
                    const keys = Object.keys(json)
                    const index = keys.findIndex(k => k === (match.params.lang ?? defaultLang));
                    if (index > -1) {
                        posName = Object.values(json)[index];
                    }
                }

                return posName ?? itemProps.value.title
            }
        },
        {Header: t('assisted_registration.reseller_email'), accessor: 'reseller_email'},
        {
            Header: t('assisted_registration.type_of_order'),
            accessor: 'type_of_order',
            Cell: itemProps => t(`assisted_registration.type_order.${itemProps.value}`)
        },
        {Header: t('assisted_registration.tracking_code'), accessor: 'tracking_code'},
        {
            Header: t('assisted_registration.status_of_registration'),
            accessor: 'status_of_registration',
            Cell: itemProps => <Button size="md" label={t(`subscription_status.${itemProps.value}`)}/>
        },
        {
            Header: t('assisted_registration.status_of_payment'),
            accessor: 'status_of_payment',
            Cell: itemProps => <Button size="md" label={t(`payment_status.${itemProps.value}`)}/>
        },
        {Header: t('assisted_registration.date'), accessor: 'date'},
        {Header: t('assisted_registration.type_of_machine'), accessor: 'type_of_machine'},
        {Header: t('assisted_registration.serial_number'), accessor: 'serial_number'},
    ], [])


    const reports = useMemo(() => mapReportsToOptions(props.reports), [props.reports])
    const ordersOptions = useMemo(() => mapOrdersToOptions(props.orders), [props.orders])

    const showResellerModal = (resellers) => {
        setResellersList(resellers)
        setModalVisible(true)
    }

    return (
        <>
            <div className="resMS">
                {/*<h2 className="par-1">{t('reseller_machine_subscription.title')}</h2>*/}
                <div className="resMS__header">
                    <div className="--flex --spaceBetween">
                        <ButtonIcon
                            label={t('reports.form.generate_report')}
                            onClick={() => props.setModalVisible(true)}
                            icon={require('../../assets/img/icons/report.svg').default}
                        />
                    </div>
                </div>

                <div className="resMS__table">
                    <CustomResellerOrderTable
                        title={t('assisted_registration.orders')}
                        columns={columns}
                        data={ordersOptions}
                        maxPage={props.ordersMaxPage}
                        results={props.ordersResults}
                        loading={props.ordersLoading}
                        getData={(cp, ps, v = null, filters = {}) => props.getData(cp, ps, v, filters)}
                        name="orders"
                        noSearchForm
                        filters
                        ordersFiltersOptions={props.ordersFiltersOptions}
                    />
                </div>
                <div className="resMS__table">
                    <Table
                        title={t('reports.table.title')}
                        columns={columnsReports}
                        data={reports}
                        maxPage={props.reportsMaxPage}
                        results={props.reportsResults}
                        loading={props.reportsLoading}
                        getData={(cp, ps, v = null) => props.getDataReports(cp, ps, v)}
                        name="reports"
                        noSearchForm
                        autoFetch
                        repeatFetching
                    />
                </div>

            </div>
            <GenerateReportModal
                visible={props.modalVisible}
                onClose={() => props.setModalVisible(false)}
                loadingReport={props.loadingReport}
                setLoadingReport={props.setLoadingReport}
                onSubmit={props.onSubmit}
                filtersData={props.filtersData}
                getFiltersData={props.getFiltersData}
            />

            <ResellersListModal
                visible={modalVisible && resellersList}
                onClose={() => setModalVisible(false)}
                resellersList={resellersList}
            />

        </>
    )
}
