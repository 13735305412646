import {Footer, Header} from "../misc/_index";
import {useTranslation} from "../../misc/Hooks";
import {TpsForm} from "../forms/tps";
import React from "react";
import {Preloader} from "../../misc/_index";


const Tps = (props) => {
    const t = useTranslation();

    return (
        <div className="app">
            <Header cartDisabled/>

            <div className="main">
                <div className="wrapper-bg formContainer">

                    <div className="wrapper">


                        {!!props.country && (
                            <TpsForm
                                data={props.data}
                                shop={props.shop}
                                promotions={props.promotions}
                                promotion={props.promotion}
                                machines={props.machines}
                                machinePartners={props.machinePartners}
                                machineModels={props.machineModels}
                                machineColors={props.machineColors}
                                categories={props.categories}
                                paymentMethod={props.paymentMethod}
                                machine={props.machine}
                                loading={props.loading}
                                loadingPos={props.loadingPos}
                                loadingSerialNumber={props.loadingSerialNumber}
                                loadingCategories={props.loadingCategories}
                                errorPos={props.errorPos}
                                errorPop={props.errorPop}
                                errorSerialNumber={props.errorSerialNumber}
                                getShop={props.getShop}
                                sending={props.sending}
                                onChangePromotion={props.onChangePromotion}
                                onChangeSerialNumber={props.onChangeSerialNumber}
                                onUploadReceipt={props.onUploadReceipt}
                                onChangeCountry={props.onChangeCountry}
                                onChangePaymentMethod={props.onChangePaymentMethod}
                                onSubmit={props.onSubmit}
                            />
                        )}


                        {(props.sending || !props.country) && (
                            <Preloader/>
                        )}


                        {!!props.message && (
                            <div>
                                <h2
                                    className={`par-2 ${props.message.status === 'success' ? '--success' : '--error'}`}
                                >{props.message.message}</h2>
                                <button
                                    className="link -parentSize -primary"
                                    onClick={() => window.location.reload()}
                                >{t('tps.reload_page')}</button>
                                <br/>
                                {props.message.status === 'error' && (
                                    <button
                                        className="link -parentSize -primary"
                                        onClick={props.onClearAndReload}
                                    >{t('tps.clear_and_reload_page')}</button>
                                )}
                            </div>
                        )}


                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}


export default Tps;
