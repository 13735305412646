import Axios from "../utils/Axios";






export const getTranslations = (lang) => {
    return Axios.get(`/translations`);
}

export const getTYP = (promotionId) => {
    return Axios.get(`/thank-you-page/${promotionId}`);
}

export const getThankYouPage = (orderUuid) => {
    return Axios.get(`/typ/uuid/${orderUuid}`);
}

export const getPlanCoffeeTYP = (productId) => {
    return Axios.get(`/plan-machine/thank-you-page/${productId}`);
}

export const getEtradeTYP = (uuid) => {
    return Axios.get(`/etrade/thank-you-page/${uuid}`);
}

export const getStayHomeTYP = (uuid) => {
    return Axios.get(`/stay-home/thank-you-page/${uuid}`);
}

export const proofOfPurchaseOcr = (data) => {
    return Axios.post(`/validate-pop`, data);
}

export const proofOfPurchasePdfOcr = (data) => {
    return Axios.post(`/preview-pdf`, data);
}

// export const getPlanByMachineId = (id) => {
//     return Axios.get(`/reseller/plan-coffee/${id}`);
// }

export const getStackByType = (type) => {
    return Axios.get(`/stack/${type}`);
}


export const getPromoCode = (code, machineId = null) => {
    return Axios.get(`/promo-code/${code}?id_machine=${machineId}`);
}

export const getETradePlan = (slug) => {
    return Axios.get(`/etrade/plans/${slug}`);
}


export function getCitiesByPostcode(postcode) {
    return Axios.get(`cities/${postcode}`)
}
