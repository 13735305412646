import React from 'react';
import BaseModal from "../../modals/BaseModal";
import {useTranslation} from "../../../misc/Hooks";
import {GenerateReportForm} from "../../forms/reseller";
import {Button} from "../../buttons/_index";


interface Props {
    visible: boolean,
    item: any,
    itemInCart: any,
    onClose: void,
    resellersList: any,
}


export default (props: Props) => {
    const t = useTranslation();

    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            {props.resellersList && (
                <div>
                    {props.resellersList.map((reseller, key) => (
                        <span className="--dBlock">{key + 1}. {reseller}</span>
                    ))}
                </div>
            )}
        </BaseModal>
    );
}
