import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {GTM} from "../../misc/_index";
import MachineRegistrationStep from "../../components/ev/MachineRegistrationStep";
import * as evReducer from "../../redux/evReducer";
import * as shopActions from '../../actions/ShopActions';
import * as machineActions from '../../actions/MachineActions';
import * as mainActions from '../../actions/MainActions';
import * as MainActions from '../../actions/MainActions';
import {useEvStepNavigation, useTranslation} from "../../misc/Hooks";
import {uuidv4} from "../../misc/Helpers";


interface Props {
}


let MOUNTED = false;


const MachineRegistrationStepContainer = (props: Props) => {
    const history = useHistory();
    const store = useSelector(({main, ev}) => ({
        user: main.user,
        country: main.country,
        serialNumber: ev.serialNumber,
        machine: ev.machine,
        proofOfPurchase: ev.proofOfPurchase,
        uuid: ev.uuid,
        ocrLogs: ev.ocrLogs,
    }));
    const dispatch = useDispatch();
    const stepNavigation = useEvStepNavigation();
    const t = useTranslation();

    const [machine, setMachine] = useState(store.machine);
    const [shops, setShops] = useState([]);
    const [loadingShops, setLoadingShops] = useState(true);
    const [serialNumberLoading, setSerialNumberLoading] = useState(false);
    const [serialNumberError, setSerialNumberError] = useState(null);
    const [popData, setPopData] = useState(null);
    const [popError, setPopError] = useState(null);
    const [popLoading, setPopLoading] = useState(false);
    const [promoCodeError, setPromoCodeError] = useState(null);
    const [promoCodeLoading, setPromoCodeLoading] = useState(false);

    useEffect(() => {
        if (store.country) {
            getShops();
        }
    }, [store.country])


    useEffect(() => {
        MOUNTED = true;
        document.title = t('ev_machine_registration.title');
        GTM.showPage(t('ev_machine_registration.title'));

        return () => {
            MOUNTED = false;
        }
    }, [])


    const getShops = () => {
        shopActions.getAll(store.country.id_country).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    const items = res.data.sort((a, b) => a.shop_group_title.toLowerCase() < b.shop_group_title.toLowerCase() ? -1 : 1);
                    items.map(g => g.shops = g.shops.sort((a, b) => a.shop_title.toLowerCase() < b.shop_title.toLowerCase() ? -1 : 0))
                    setShops(items);
                }
            } else {
                console.warn('Something gone wrong! ', res)
            }
        }).catch(err => {
            console.error('Error: ', err);
        }).finally(() => {
            if (MOUNTED) {
                setLoadingShops(false);
            }
        })
    }


    const validateSerialNumber = (serialNumber) => {
        const formData = {
            serial_number: serialNumber,
        }
        setSerialNumberLoading(true);
        machineActions.validateSerialNumber(formData).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    setMachine(res.data)
                    setSerialNumberError(null);
                    // setMachine(res.data, null, {serial_number: serialNumber});
                    pushSerialNumberLog({manually: 1, value: serialNumber, valid: 1})
                }
            } else {
                if (res.messages?.length) {
                    if (MOUNTED) {
                        setSerialNumberError(res.messages[0]);
                        setMachine(null);
                        pushSerialNumberLog({manually: 1, value: serialNumber, valid: 0})
                    }
                } else {
                    console.log('Something gone wrong! ', res)
                }
            }
        }).catch(error => {
            console.error('Error', error.message);
        }).finally(() => {
            if (MOUNTED) {
                setSerialNumberLoading(false);
            }
        })
    }


    const handleUploadSerialNumberFile = (base64) => {
        const formData = {base64};
        setSerialNumberLoading(true);
        machineActions.getSerialNumberFromImage(formData).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    if(!res.data.serial_number) {
                        setSerialNumberError('serialnumber_no_readable');
                    }
                    else {
                        setSerialNumberError(null);
                    }
                    dispatch(evReducer.setMachine(null, false, res.data))
                    pushSerialNumberLog({manually: 0, value: res.data.serial_number, valid: 0})
                }
            } else {
                if (res.messages?.length) {
                    if (MOUNTED) {
                        setSerialNumberError(res.messages[0]);
                    }
                } else {
                    console.log('Something gone wrong! ', res)
                }
            }
        }).catch(error => {
            console.error('Error', error.message);
        }).finally(() => {
            if (MOUNTED) {
                setSerialNumberLoading(false);
            }
        })
    }


    const handleUploadPopFile = (file) => {
        setPopLoading(true);
        setPopError(null)
        if (file.type.match('application/pdf')) {
            const formData = {content: file.base64};
            dispatch(evReducer.setProofOfPurchase(null));
            dispatch(evReducer.setPromoCode(null));
            mainActions.proofOfPurchasePdfOcr(formData).then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (MOUNTED) {
                        setPopData(res.data);
                        dispatch(evReducer.setProofOfPurchaseLogs(res.data));
                        // setSerialNumberError(null);
                        // dispatch(reducer.setMachine(null, false, {
                        //     serialNumber: res.data,
                        //     file: base64,
                        // }))
                        // validateSerialNumber(res.data)
                    }
                } else {
                    if (res.messages?.length) {
                        if (MOUNTED) {
                            setPopData(null);
                            setPopError(res.messages[0])
                        }
                    } else {
                        console.log('Something gone wrong! ', res)
                    }
                }
            }).catch(error => {
                console.error('Error', error.message);
            }).finally(() => {
                if (MOUNTED) {
                    setPopLoading(false);
                }
            })
        } else {
            const formData = {base64: file.base64};
            dispatch(evReducer.setProofOfPurchase(null));
            dispatch(evReducer.setPromoCode(null));
            mainActions.proofOfPurchaseOcr(formData).then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (MOUNTED) {
                        setPopData(res.data);
                        dispatch(evReducer.setProofOfPurchaseLogs(res.data));
                        // setSerialNumberError(null);
                        // dispatch(reducer.setMachine(null, false, {
                        //     serialNumber: res.data,
                        //     file: base64,
                        // }))
                        // validateSerialNumber(res.data)
                    }
                } else {
                    if (MOUNTED) {
                        setPopData(res.data);
                        dispatch(evReducer.setProofOfPurchaseLogs(res.data));
                    }

                    if (res.messages?.length) {
                        if (MOUNTED) {
                            setPopError(res.messages[0])
                        }
                    } else {
                        console.log('Something gone wrong! ', res)
                    }
                }
            }).catch(error => {
                console.error('Error', error.message);
            }).finally(() => {
                if (MOUNTED) {
                    setPopLoading(false);
                }
            })
        }
    }


    const handleSubmitSerialNumber = (data) => {
        if(data.serialNumber !== store.serialNumber?.serial_number || !store.machine) {
            const logs = [...store.ocrLogs]
            dispatch(evReducer.clearStore())
            dispatch(evReducer.setUuid(uuidv4()))
            dispatch(evReducer.setOcrLogs(logs))
            dispatch(evReducer.setMachine(machine.machine, machine.test_order, {
                ...store.serialNumber,
                serial_number: data.serialNumber,
            }));
        }
    }


    const handleSubmitProofOfPurchase = (data) => {
        if(data.machinePrice) {
            data.machinePrice = Number(data.machinePrice.toString().replace(',', '.'))
        }
        if(!data.code)
            dispatch(evReducer.setPromoCode(null));
        if(!data.file)
            dispatch(evReducer.setProofOfPurchase(null));
        else
            dispatch(evReducer.setProofOfPurchase(data));
        history.push(stepNavigation.next());
    }

    const handleRemoveSerialNumberFile = () => {
        dispatch(evReducer.setMachine(
            machine?.machine ?? null,
            machine?.test_order ?? null,
            {
                serial_number: store.serialNumber?.serial_number,
            }
        ));
    }

    const handleChangePromoCode = (promoCode) => {
        if(!promoCode) {
            dispatch(evReducer.setPromoCode(''));
            return;
        }

        setPromoCodeLoading(true);
        setPromoCodeError(null);

        MainActions.getPromoCode(promoCode, store.machine?.id_machine).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                if(MOUNTED) {
                    setPromoCodeError(null);
                    // dispatch(evReducer.setProofOfPurchase(null));
                    // dispatch(evReducer.setProofOfPurchaseLogs(null));
                    dispatch(evReducer.setPromoCode(res.data));
                    // history.push(stepNavigation.next());
                }
            }
            else {
                if(res.messages[0]) {
                    if(MOUNTED) {
                        setPromoCodeError(t(`validation.${res.messages[0]}`));
                    }
                }
                else {
                    console.warn('Something gone wrong!', res)
                }
            }
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            if(MOUNTED) {
                setPromoCodeLoading(false);
            }
        })
    }

    const pushSerialNumberLog = (l) => {
        const arr = [...store.ocrLogs.filter(l => l.type === 'serial_number')];
        const last = [...arr].reverse()[0]
        if(last) {
            if(last.manually === l.manually && last.value === l.value && last.valid === l.valid) {
                return
            }
            if(l.manually && !last.manually && last.value === l.value) {
                arr.splice(arr.length - 1, 1)
                l.manually = 0
            }
        }
        arr.push({
            type: 'serial_number',
            date: new Date().toISOString(),
            ...l,
        })
        dispatch(evReducer.setOcrLogs([
            ...store.ocrLogs.filter(l => l.type !== 'serial_number'),
            ...arr,
        ]))
    }

    return (
        <MachineRegistrationStep
            user={store.user}
            promotion={props.promotion}
            machine={store.machine}
            validatedMachine={machine}
            serialNumber={store.serialNumber}
            serialNumberError={serialNumberError}
            serialNumberLoading={serialNumberLoading}
            proofOfPurchase={store.proofOfPurchase}
            proofOfPurchaseData={popData}
            proofOfPurchaseError={popError}
            proofOfPurchaseLoading={popLoading}
            shops={shops}
            promoCodeError={promoCodeError}
            promoCodeLoading={promoCodeLoading}
            validateSerialNumber={validateSerialNumber}
            onUploadSerialNumberFile={handleUploadSerialNumberFile}
            onUploadPopFile={handleUploadPopFile}
            onSubmitSerialNumber={handleSubmitSerialNumber}
            onSubmitProofOfPurchase={handleSubmitProofOfPurchase}
            onRemoveSerialNumberFile={handleRemoveSerialNumberFile}
            onChangePromoCode={handleChangePromoCode}
        />
    );
}


export default MachineRegistrationStepContainer;
