import React, {useEffect, useState} from "react";
import {Routes} from "../../utils/_index";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as ofReducer from '../../redux/ofReducer';
import {useNavigation, useOfStepNavigation} from "../../misc/Hooks";
import Summary from "../../components/order/Summary";
import * as orderActions from "../../actions/OrderActions";
import {mapOrderToCustomer, mapStackToOptions} from "../../misc/Helpers";
import * as OrderService from "../../services/OrderService";
import {useCivilityOptions} from "../../hooks";


let MOUNTED = false;

export const SummaryContainer = (props) => {
  const navigation = useNavigation();
  const history = useHistory();
  const stepNavigation = useOfStepNavigation();
  const dispatch = useDispatch();
  const store = useSelector(({main, of}) => ({
    country: main.country,
    civilities: main.civilities,
    finishOrder: of.finishOrder,
    finishOrderToken: of.finishOrderToken,
    finishOrderUser: of.finishOrderUser,
    uuid: of.uuid,
    order: of.order,
    customer: of.customer,
    nespressoMember: of.nespressoMember,
    products: of.products,
    addresses: of.addresses,
    cart: of.cart,
    orderAccount: of.orderAccount,
    orderAccountLinked: of.orderAccountLinked,
    cartQuotationData: of.cartQuotationData,
  }));
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorLog, setErrorLog] = useState(null);
  const [memberCredit, setMemberCredit] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const civilityOptions = useCivilityOptions()
  const [cartQuotationState, setCartQuotationState] = useState({loading: false});


    useEffect(() => {
        MOUNTED = true;

        if (!store.user && store.nespressoMember) {
            // checkMemberCredit();
        }

        if (store.order) {
            // makeOrderQuotation(store.order)
        } else {
            cartQuotation()
        }

        return () => {
            MOUNTED = false;
        }
    }, [])

    const getCustomerFormData = () => {
        const customer = mapOrderToCustomer(store.finishOrder, civilityOptions.options)

        const benefitsConfig = {
            marketing_email: store.nespressoMember?.consents?.contactByEmail ? 1 : 0,
            marketing_mobile: store.nespressoMember?.consents?.mobileNotification ? 1 : 0,
            marketing_phone: store.nespressoMember?.consents?.contactByPhone ? 1 : 0,
            marketing_post: store.nespressoMember?.consents?.contactByPost ? 1 : 0
        }

        if (store.customer.benefits?.email !== undefined)
            benefitsConfig.marketing_email = store.customer.benefits?.email ? 1 : 0
        if (store.customer.benefits?.mobile !== undefined)
            benefitsConfig.marketing_mobile = store.customer.benefits?.mobile ? 1 : 0
        if (store.customer.benefits?.phone !== undefined)
            benefitsConfig.marketing_phone = store.customer.benefits?.phone ? 1 : 0
        if (store.customer.benefits?.post !== undefined)
            benefitsConfig.marketing_post = store.customer.benefits?.post ? 1 : 0

        const data = {
            currency_prefix: store.country.country_currency_prefix,
            currency_suffix: store.country.country_currency_suffix,
            currency_name: store.country.country_currency_name,

            id_country: store.finishOrder.id_country,
            address_id: customer?.memberAddress?.value,
            delivery_is_company: customer.companyAddress ? 1 : 0,
            delivery_company_name: customer.companyName ?? '',
            delivery_company_tax_id: customer.taxId ?? '',
            delivery_company_irs: customer.irs ?? '',
            delivery_civility: customer.title ? Number(customer.title.value) : 0,
            delivery_firstname: customer.name ?? '',
            delivery_lastname: customer.surname ?? '',
            delivery_zipcode: customer.postcode ?? '',
            delivery_city: customer.city ?? '',
            delivery_address1: customer.address ?? '',
            delivery_address2: customer.address2 ?? '',
            delivery_country: customer.country?.value ?? '',
            delivery_phone: customer.phone ?? '',
            delivery_phone_mobile: customer?.phoneMobile ?? '',
            delivery_phone_home: customer?.phoneHome ?? '',
            delivery_email: customer.email ?? '',

            other_delivery: customer.withCustomAddress ? 1 : 0,
            other_delivery_is_company: customer.customCompanyAddress ? 1 : 0,
            other_delivery_company_name: customer.customCompanyName ?? '',
            other_delivery_civility: customer.customTitle ? Number(customer.customTitle.value) : 0,
            other_delivery_firstname: customer.customName ?? '',
            other_delivery_lastname: customer.customSurname ?? '',
            other_delivery_zipcode: customer.customPostcode ?? '',
            other_delivery_city: customer.customCity ?? '',
            other_delivery_address1: customer.customAddress ?? '',
            other_delivery_address2: customer.customAddress2 ?? '',
            other_delivery_country: customer.customCountry?.value ?? '',
            other_delivery_phone: customer.customPhone ?? '',
            other_delivery_phone_mobile: customer?.customPhoneMobile ?? '',
            other_delivery_phone_home: customer?.customPhoneHome ?? '',
            other_delivery_email: customer.customEmail ?? '',

            ...benefitsConfig,

            regulations: customer.termsAndConditions ? 1 : 0,
            privacy_policy: customer.privacyPolicy ? 1 : 0,

            uuid_finish: store.uuid,
            member_number: store.nespressoMember ? store.nespressoMember.member_id : (store.finishOrderUser?.member_id ?? store.finishOrder?.member_number),
            member_token: store.nespressoMember ? store.nespressoMember.token : (store.finishOrderUser?.token ?? store.finishOrder?.member_token),
            // member_password: store.finishOrderUser.password ?? store.finishOrder?.member_password,
        }

        if (customer.memberAddress) {
            data.delivery_civility = customer.title;
            data.delivery_country = customer.country;

            data.other_delivery_civility = customer.customTitle;
            data.other_delivery_country = customer.customCountry;
        }

        return data
    }

    const cartQuotation = async () => {
        const data = {
            customer: getCustomerFormData(),
            cart: store.cart,
        };

        setCartQuotationState({loading: true})
        setError(null)
        const res = await OrderService.cartQuotation(data)
            .catch(e => {
                setError(e.message || 'invalid_order')
                setErrorLog(JSON.stringify(e.data))
            })
        if (res) {
            setMemberCredit(res.finance?.amount?.credit)
            dispatch(ofReducer.setCartQuotationData(res))
        }
        setCartQuotationState({loading: false})
    }




    const handleSubmit = async () => {
        if (store.order) {
            history.push(stepNavigation.next())
        } else {
            const customer = getCustomerFormData()

            setLoading(true)
            setError(null)


            let account = store.nespressoMember ? {
                jwtToken: store.nespressoMember.token,
                member_number: store.nespressoMember.member_id,
            } : store.orderAccount
            let linked = store.finishOrder.webaccount_exist ? true : store.orderAccountLinked
            let order = store.order

            if (account && linked) {
                const data = {
                    customer: {
                        ...customer,
                        member_number: account.member_number ?? '',
                        member_token: account.jwtToken ?? '',
                    },
                    recaptcha_token: recaptchaToken,
                };

                await orderActions.updateOrder(store.finishOrderToken, data).then(async r => {
                    const res = r.data;
                    if (res.status === 'success') {
                        order = res.data
                        dispatch(ofReducer.setOrder(res.data));
                    }
                })
            }

            if (order) {
                if (order.hopLink) {
                    window.location.href = order.hopLink
                } else {
                    const data = await OrderService.makeQuotation(order.order.id_order)
                        .catch(err => {
                            setError('order_save_error')
                        })

                    if (data) {
                        dispatch(ofReducer.setOrder(data))

                        if (data.type === 'TYP') {
                            history.push(navigation(Routes.ORDER_FINALIZED, data?.order?.uuid))
                        } else if (data.type === 'HOP') {
                            window.location.href = data.url
                        } else if (data.type === 'PAYMENT') {
                            history.push(navigation(Routes.FINISH_ORDER_PAYMENT))
                        }
                    }
                }

            }

            setLoading(false)
        }
    }


    return (
        <Summary
            loading={loading}
            error={error}
            memberCredit={memberCredit}
            recaptchaToken={recaptchaToken}
            cartQuotationState={cartQuotationState}
            cartQuotationData={store.cartQuotationData}
            customer={store.customer ?? mapOrderToCustomer(store.finishOrder, civilityOptions.options)}
            onChangeRecaptchaToken={token => setRecaptchaToken(token)}
            onSubmit={handleSubmit}
        />
    )
}

